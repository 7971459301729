import moment from 'moment-timezone';
import React from 'react';
import { Body } from 'src/components';
import { usePractice } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { CoinIcon } from 'src/routes/stacks/LoyaltyTabNavigator/components/LoyaltyTabBar/style';
import { Weight } from 'src/theme';
import { toLocalDate } from 'src/utils';
import { CareCoinView, StyledRow } from '../style';

interface Props {
  expringAmount: number;
  expireDate?: Date;
}

const ExpiringCareCoinItem: React.FC<Props> = ({ expringAmount, expireDate }) => {
  const { data: practice } = usePractice();
  const { colors } = useAppTheme();

  return (
    <StyledRow>
      <Body fontWeight={Weight.BOLD}>{toLocalDate(moment(expireDate), practice?.timeZone)}</Body>
      <CareCoinView>
        <Body color={colors.onSurface}>{expringAmount}</Body>
        <CoinIcon />
      </CareCoinView>
    </StyledRow>
  );
};

export default ExpiringCareCoinItem;
