import { FormikErrors } from 'formik';
import { useMemo } from 'react';
import { LayoutAnimation } from 'react-native';
import { IS_IOS } from 'src/constants';
import { emailSchema, LoginForm } from './validation';

interface Props {
  values: LoginForm;
  errors: FormikErrors<LoginForm>;
  isFetched: boolean;
  debouncedEmail: string;
}
const useLoginComponentControl = ({ values, errors, isFetched, debouncedEmail }: Props) => {
  return useMemo(() => {
    const { availablePractices = [] } = values;
    if (IS_IOS) LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    const continueAllowed =
      isFetched &&
      emailSchema.isValidSync(debouncedEmail) &&
      !errors.email &&
      !errors.selectedPractices &&
      !errors.availablePractices &&
      !!values.availablePractices?.length;
    const primarySelectedPractice = values.selectedPractices?.[0];
    const loginTitle = primarySelectedPractice?.userRegistered ? 'login:signIn' : 'login:signUp';
    const longTitle = 'login:longTitle';
    return {
      showEmailActivity:
        emailSchema.isValidSync(values.email) &&
        (debouncedEmail !== values.email.toLowerCase() || !isFetched),
      showPractices:
        isFetched &&
        !errors.email &&
        !errors.availablePractices &&
        availablePractices.length > 1 &&
        !availablePractices.every((p) => !p.userRegistered),
      showPassword: continueAllowed,
      showRememberMe: continueAllowed,
      showConfirmPassword: continueAllowed && !primarySelectedPractice?.userRegistered,
      showForgotEmail: !continueAllowed,
      showForgotPassword: continueAllowed && primarySelectedPractice?.userRegistered,
      showLoginButton: continueAllowed,
      primarySelectedPractice,
      title: values.selectedPractices.length && !errors.email ? loginTitle : longTitle
    };
  }, [isFetched, debouncedEmail, errors, values]);
};

export default useLoginComponentControl;
