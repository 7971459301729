import { View } from 'react-native';
import { Mail } from 'react-native-feather';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

const MailSuper: React.FC = () => {
  const { colors } = useAppTheme();
  return (
    <PositionContainer>
      <Mail color={'none'} width={400} height={400} stroke={colors.primary} strokeWidth={0.125} />
    </PositionContainer>
  );
};

export default MailSuper;

const PositionContainer = styled.View`
  right: -25%;
  bottom: 10%;
  opacity: 0.5;
  align-items: center;
  transform: rotate(30deg);
`;
