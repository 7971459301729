import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import PawHero from './PawHero';

export enum STEPS {
  DRAWER,
  VIEW,
  FILTER,
  PET_ID,
  APPOINTMENTS,
  REMINDERS,
  PRESCRIPTIONS,
  TODOS,
  EDIT_INFO,
  PHOTOS,
  EDIT_BASIC_INFO,
  EDIT_ADDITIONAL_INFO,
  EDIT_COMMENTS
}

const PatientListTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: {
    id: STEPS.DRAWER,
    priority: 0
  },
  [STEPS.FILTER]: {
    id: STEPS.FILTER,
    priority: 1
  },
  [STEPS.VIEW]: {
    id: STEPS.VIEW,
    priority: 2
  },
  [STEPS.PET_ID]: {
    id: STEPS.PET_ID,
    priority: 3
  },
  [STEPS.APPOINTMENTS]: {
    id: STEPS.APPOINTMENTS,
    priority: 4
  },
  [STEPS.REMINDERS]: {
    id: STEPS.REMINDERS,
    priority: 5
  },
  [STEPS.PRESCRIPTIONS]: {
    id: STEPS.PRESCRIPTIONS,
    priority: 6
  },
  [STEPS.TODOS]: {
    id: STEPS.TODOS,
    priority: 7
  },
  [STEPS.EDIT_INFO]: {
    id: STEPS.EDIT_INFO,
    priority: 8
  },
  [STEPS.PHOTOS]: {
    id: STEPS.PHOTOS,
    priority: 9
  },
  [STEPS.EDIT_BASIC_INFO]: {
    id: STEPS.EDIT_BASIC_INFO,
    priority: 10
  },
  [STEPS.EDIT_ADDITIONAL_INFO]: {
    id: STEPS.EDIT_ADDITIONAL_INFO,
    priority: 11
  },
  [STEPS.EDIT_COMMENTS]: {
    id: STEPS.EDIT_COMMENTS,
    priority: 12
  }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'petList:tour.drawer.title',
    body: 'petList:tour.drawer.body',
    image: <PawHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.PETS,
  PatientListTourMap
);
