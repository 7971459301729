import { pick, map, startCase } from 'lodash';
import { Client } from 'src/interfaces';

export const prepareUserMetadata = (user?: Client) =>
  pick(user, [
    'clientId',
    'sourceId',
    'emailVerificationStatus',
    'primaryPractice',
    'petRecordStatus',
    'loyaltyToggle'
  ]);

type ObjectWithToString = {
  toString: () => string;
};

export const mapPropertiesToLabels = <T extends ObjectWithToString>(
  obj: Record<string, T | undefined | null>
) => map(obj, (value, key) => `${startCase(key)}: ${value?.toString() ?? 'undefined'}`).join('\n');
