import { PathConfig } from 'src/routes/types';
import { Screens } from '../screens';

export type TodoStackParamList = {
  [Screens.TO_DOS]?: {
    selectedPatientId?: string;
  };
  [Screens.ADD_TO_DO]?: {
    todoId?: number;
  };
};

export const TODO_STACK_PATHS: PathConfig<TodoStackParamList> = {
  initialRouteName: Screens.TO_DOS,
  path: 'to-dos',
  screens: {
    [Screens.TO_DOS]: '',
    [Screens.ADD_TO_DO]: 'add'
  }
};

export default TodoStackParamList;
