import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, ScrollView, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useQuery, useQueryClient } from 'react-query';
import { practiceSearch, QueryKeys } from 'src/api';
import { BottomButton, CPrimaryButton, HelperButton } from 'src/components';
import { ALLY_SUPPORT_EMAIL, IS_WEB, Size, ZIP_MASK } from 'src/constants';
import { useBoolean, useViewMode } from 'src/hooks';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import { layout } from 'src/theme/globalStyles';
import { Actions, examplePractice } from '../helpers/model';
import { ContentContainer, StyledTextInput } from '../style';
import { GuideElement, STEPS, useTourGuide } from '../tour';
import { practiceSearchValidationSchema as validationSchema } from '../validation';

interface Props {
  showAlert: () => void;
  setModalTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalText: React.Dispatch<React.SetStateAction<string>>;
  moveNext: () => void;
  updateData: (data: Actions['data']) => void;
}

const PracticeSearch: React.FC<Props> = ({
  showAlert,
  setModalTitle,
  setModalText,
  moveNext,
  updateData
}) => {
  const { horizontalInset } = useViewMode();
  const { t } = useTranslation('practiceClientSearch');
  const queryClient = useQueryClient();

  const { value: searchByZip, toggle: toggleSearchBy } = useBoolean(true);

  const { enabled } = useTourGuide();

  const { values, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues: {
      name: '',
      zip: '',
      city: '',
      state: ''
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async () => {
      void queryClient.invalidateQueries([QueryKeys.PRACTICE_SEARCH]);
      const response = await refetch();
      if (!response.data?.length) {
        setModalTitle(t('noPracticesFoundTitle'));
        setModalText(t('noPracticesFoundText', { email: ALLY_SUPPORT_EMAIL }));
        showAlert();
      } else {
        updateData({ practiceOptions: response.data });
        moveNext();
      }
    }
  });

  const { refetch, isFetching } = useQuery({
    queryFn: async () => await practiceSearch(values.name, values.zip, values.city, values.state),
    queryKey: [QueryKeys.PRACTICE_SEARCH, values.name, values.zip, values.city, values.state],
    enabled: false
  });

  useEffect(() => {
    if (searchByZip) {
      void setFieldValue('zip', '');
      setErrors({
        city: '',
        state: ''
      });
    } else {
      void setFieldValue('city', '');
      void setFieldValue('state', '');
      setErrors({
        zip: ''
      });
    }
  }, [searchByZip, setErrors, setFieldValue]);

  const debouncedSubmit = useMemo(() => _.debounce(() => handleSubmit()), [handleSubmit]);

  const zipRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);

  const continuePressed = () => {
    updateData({
      practiceOptions: [examplePractice]
    });
    moveNext();
  };

  const scrollRef = useRef<ScrollView>(null);

  return (
    <>
      <ContentContainer ref={scrollRef}>
        <ScrollRefProvider scrollRef={scrollRef}>
          <GuideElement id={STEPS.HOSPITAL_SEARCH} body={t('tour.hospitalSearch')}>
            <>
              <StyledTextInput
                addBottomGap={enabled}
                nextRef={searchByZip ? zipRef : cityRef}
                value={enabled ? 'John Doe' : values.name}
                onChangeText={async (name) => await setFieldValue('name', name)}
                label={t('practiceName')}
                error={!!errors.name}
                msg={errors.name}
              />
              {searchByZip && (
                <StyledTextInput
                  thisRef={zipRef}
                  value={enabled ? '01234' : values.zip}
                  onChangeText={async (zip) => await setFieldValue('zip', zip)}
                  label={t('postalCode')}
                  mask={ZIP_MASK}
                  error={!!errors.zip}
                  msg={errors.zip}
                  onSubmitEditing={() => handleSubmit()}
                />
              )}
            </>
          </GuideElement>
          <GuideElement
            id={STEPS.SEARCH_BY_CITY}
            body={t('tour.searchByCity')}
            onContinue={continuePressed}
          >
            {!searchByZip && (
              <>
                <StyledTextInput
                  addBottomGap={enabled}
                  thisRef={cityRef}
                  nextRef={stateRef}
                  value={values.city}
                  onChangeText={async (city) => await setFieldValue('city', city)}
                  label={t('informationUpdate:city')}
                  error={!!errors.city}
                  msg={errors.city}
                />
                <StyledTextInput
                  thisRef={stateRef}
                  value={values.state}
                  onChangeText={async (state) => await setFieldValue('state', state)}
                  label={t('informationUpdate:state')}
                  mask={'AA'}
                  error={!!errors.state}
                  msg={errors.state}
                  onSubmitEditing={() => handleSubmit()}
                />
              </>
            )}
          </GuideElement>

          <GuideElement id={STEPS.SEARCH_BY} body={t('tour.searchBy')} onContinue={toggleSearchBy}>
            <HelperButton
              onPress={() => {
                LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
                toggleSearchBy();
              }}
            >
              {searchByZip ? t('cityStateSearch') : t('zipSearch')}
            </HelperButton>
          </GuideElement>
        </ScrollRefProvider>
      </ContentContainer>
      {isFetching && <ActivityIndicator size='large' style={layout.flex1} />}
      <View
        style={{ marginHorizontal: horizontalInset, paddingBottom: IS_WEB ? Size.X3_S : undefined }}
      >
        {!IS_WEB && (
          <BottomButton onPress={debouncedSubmit} shade={0.65}>
            {t('next')}
          </BottomButton>
        )}
        {IS_WEB && <CPrimaryButton onPress={debouncedSubmit}>{t('next')}</CPrimaryButton>}
      </View>
    </>
  );
};

export default PracticeSearch;
