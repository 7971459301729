import React from 'react';
import { IconProps, Paw } from 'src/components/Icons';
import { Species } from 'src/constants';
import Bird from './Bird';
import Bunny from './Bunny';
import Cat from './Cat';
import Cow from './Cow';
import Dog from './Dog';
import Horse from './Horse';
import Rodent from './Rodent';
import Gecko from './Gecko';

interface Props extends IconProps {
  species?: string;
  color: string;
  size: number;
  strokeWidth?: number;
}

const AnimalIcon: React.FC<Props> = ({ species, color, size, strokeWidth }) => {
  if (!species) return <Paw size={size} color={color} />;

  switch (species.toLowerCase()) {
    case Species.AVIAN:
    case Species.BIRD: {
      return <Bird size={size} color={color} />;
    }
    case Species.CAT:
    case Species.FELINE: {
      return <Cat size={size} color={color} />;
    }
    case Species.CANINE:
    case Species.DOG: {
      return <Dog size={size} color={color} />;
    }
    case Species.EQUINE:
    case Species.HORSE: {
      return <Horse size={size} color={color} />;
    }
    case Species.BOVINE:
    case Species.COW: {
      return <Cow size={size} color={color} />;
    }
    case Species.RODENT: {
      return <Rodent size={size} color={color} />;
    }
    case Species.BUNNY:
    case Species.LAGOMORPH: {
      return <Bunny size={size} color={color} />;
    }
    case Species.GECKO:
      return <Gecko size={size} color={color} />;

    default:
      return <Paw size={size} color={color} strokeWidth={strokeWidth} />;
  }
};

export default AnimalIcon;
