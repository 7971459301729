import * as React from 'react';
import { useState } from 'react';
import { TextInput } from '../kit';
import { TextInputProps } from '../kit/TextInput';

const PasswordInput: React.FC<TextInputProps> = ({ ...props }) => {
  const [isSecureTextEntry, setIsSecureTextEntry] = useState(true);
  const onPressEye = (): void => {
    setIsSecureTextEntry((isSecureTextEntry) => !isSecureTextEntry);
  };

  return (
    <TextInput
      mode='outlined'
      numberOfLines={1}
      label={props.label}
      value={props.value}
      onChangeText={props.onChangeText}
      secureTextEntry={isSecureTextEntry}
      right={{ name: 'eye', onPress: onPressEye }}
      error={props.error}
      msg={props.msg}
      {...props}
    />
  );
};

export default PasswordInput;
