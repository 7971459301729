import { Moment, tz } from 'moment-timezone';
import React, { useMemo } from 'react';
import { Body, Caption } from 'src/components';
import { Margin } from 'src/constants';
import { toLocalTime } from 'src/utils';
import styled from 'styled-components/native';

interface Props {
  time: Moment;
  practiceTimeZone?: string;
}
const CustomLabel: React.FC<Props> = ({ time, practiceTimeZone }) => {
  const timeString = useMemo(() => toLocalTime(time, practiceTimeZone), [practiceTimeZone, time]);

  const isLocal = useMemo(() => {
    return tz.guess() === practiceTimeZone;
  }, [practiceTimeZone]);

  const localTimeString = useMemo(() => toLocalTime(time), [time]);

  return (
    <Container>
      <Body>{timeString}</Body>
      {!isLocal && <Caption>{localTimeString}</Caption>}
    </Container>
  );
};

export default CustomLabel;

const Container = styled.View`
  gap: ${Margin.Small}px;
  justify-content: center;
  flex-grow: 1;
`;
