import { Schedule } from 'src/interfaces';

export interface FieldDisplayOptions {
  minDays: Schedule['daysInAdvanceMin'];
  maxDays: Schedule['daysInAdvanceMax'];
  useAppointmentTypes: Schedule['useAppointmentTypes'];
  allowCategorySelection: Schedule['allowCategorySelection'];
  categoryType: Schedule['categoryType'] | undefined;
  requireComments: Schedule['requireComments'];
}

const extractFieldDisplayOptions = (schedule: Schedule): FieldDisplayOptions => ({
  minDays: schedule?.daysInAdvanceMin,
  maxDays: schedule?.daysInAdvanceMax,
  useAppointmentTypes: !!schedule?.useAppointmentTypes,
  allowCategorySelection: !!schedule?.allowCategorySelection,
  categoryType: schedule?.categoryType,
  requireComments: !!schedule?.requireComments
});

export default extractFieldDisplayOptions;
