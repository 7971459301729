import React, { useMemo } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'react-native-paper';
import { Caption, Headline, Row, Subheading } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import InfoUpdateItems from './InfoUpdateItems';
import styled from 'styled-components/native';
import { IconSize, Size } from 'src/constants';
import { Weight } from 'src/theme';
import { InfoUpdateItem } from 'src/interfaces/api/InfoUpdateRequest';
import { FormStateHook } from '../../hooks/useFormState';
import { ImageUpload } from 'src/components/UploadFiles';
import {
  contactRequiredFields,
  createAdditionalInfoItemArray,
  createBasicInfoRequestItemArray,
  isFieldDirty
} from '../../helpers';

interface Props {
  setContactMethod: React.Dispatch<React.SetStateAction<string>>;
  values: FormStateHook['values'];
  initialValues: FormStateHook['initialValues'];
}

const ConfirmChanges: React.FC<Props> = ({ setContactMethod, initialValues, values }) => {
  const { t } = useTranslation('patientProfile');
  const { colors } = useAppTheme();

  const basicInfo = useMemo(
    () => createBasicInfoRequestItemArray(values, initialValues),
    [values, initialValues]
  );

  const imageInfo = useMemo(() => {
    if (values.newPatientImageUri === null) return [];
    const patientImageInfoUpdate: InfoUpdateItem = {
      dataType: t('patientImage'),
      newValue: t(values.newPatientImageUri !== '' ? 'newImageUpload' : 'deleting')
    };
    return [patientImageInfoUpdate];
  }, [values, t]);

  const additionalInfo = useMemo(
    () => createAdditionalInfoItemArray(values, initialValues),
    [values, initialValues]
  );

  return (
    <>
      <Headline>{t('confirmYourChanges')}</Headline>
      {!!basicInfo?.length && (
        <>
          <Subheading>{t('basicInfo')}</Subheading>
          <Caption>{t('theseChangesAfter')}</Caption>
          <InfoUpdateItems infoUpdateItems={basicInfo} />
        </>
      )}
      {(!!additionalInfo?.length || !!imageInfo.length) && (
        <>
          <Subheading>{t('additionalInfo')}</Subheading>
          <Caption>{t('theseChangesImmediately')}</Caption>
          <InfoUpdateItems infoUpdateItems={[...imageInfo, ...additionalInfo]} />
        </>
      )}
      {(!!values.comments || !!Object.values(values.images).length) && (
        <Subheading>{t('commentsAndFiles')}</Subheading>
      )}
      {!!values.comments && (
        <>
          <Caption>{t('theseChangesSent')}</Caption>
          <CommentsAndFilesContainer>
            <Caption>{t('comments')}</Caption>
            <Caption fontWeight={Weight.MEDIUM}>{values.comments}</Caption>
          </CommentsAndFilesContainer>
        </>
      )}
      {!!Object.values(values.images).length && (
        <>
          <CommentsAndFilesContainer>
            <Caption>{t('files')}</Caption>
            <ImagesHolder>
              {Object.values(values.images).map((image: ImageUpload, i: number) => (
                <StyledImage key={i} source={{ uri: image.image.uri }} />
              ))}
            </ImagesHolder>
          </CommentsAndFilesContainer>
        </>
      )}
      {!!contactRequiredFields.some((field) => isFieldDirty(field, values, initialValues)) && (
        <>
          <Subheading>{t('preferredContactMethod')}</Subheading>
          <View>
            <Caption>{t('inTheEvent')}</Caption>
            <RadioButton.Group
              onValueChange={async (value) => await setContactMethod(value)}
              value={values.contactMethod}
            >
              <Row justify='center'>
                <RadioButton.Item
                  mode='android'
                  value='text'
                  label={t('common:text')}
                  color={colors.primary}
                />
                <RadioButton.Item
                  mode='android'
                  value='email'
                  label={t('common:email')}
                  color={colors.primary}
                />
                <RadioButton.Item
                  mode='android'
                  value='phone'
                  label={t('common:phone')}
                  color={colors.primary}
                />
              </Row>
            </RadioButton.Group>
          </View>
        </>
      )}
    </>
  );
};

export default ConfirmChanges;

const CommentsAndFilesContainer = styled.View`
  gap: ${Size.X3_S}px;
  padding-horizontal: ${Size.S}px;
`;

const StyledImage = styled.Image`
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.roundness}px;
  height: ${IconSize.XL}px;
  width: ${IconSize.XL}px;
`;

const ImagesHolder = styled.View`
  gap: ${Size.XS}px;
  flex-direction: row;
  flex-wrap: wrap;
`;
