import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import ProfileHero from './ProfileHero';

export enum STEPS {
  DRAWER,
  PHOTO,
  INFO,
  APP,
  COMMUNICATION,
  NOTIFICATION,
  PRIVACY,
  HELP,
  TERMS
}

const ProfileSettingsTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: {
    id: STEPS.DRAWER,
    priority: 0
  },
  [STEPS.PHOTO]: {
    id: STEPS.PHOTO,
    priority: 1
  },
  [STEPS.INFO]: {
    id: STEPS.INFO,
    priority: 2
  },
  [STEPS.APP]: {
    id: STEPS.APP,
    priority: 3
  },
  [STEPS.COMMUNICATION]: {
    id: STEPS.COMMUNICATION,
    priority: 4
  },
  [STEPS.NOTIFICATION]: {
    id: STEPS.NOTIFICATION,
    priority: 5
  },
  [STEPS.PRIVACY]: {
    id: STEPS.PRIVACY,
    priority: 6
  },
  [STEPS.TERMS]: {
    id: STEPS.TERMS,
    priority: 7
  },
  [STEPS.HELP]: {
    id: STEPS.HELP,
    priority: 8
  }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'profileRoot:tour.drawer.title',
    body: 'profileRoot:tour.drawer.body',
    image: <ProfileHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.PROFILE,
  ProfileSettingsTourMap
);
