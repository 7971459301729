import { Openings } from 'src/interfaces';
import moment from 'moment-timezone';

const enumerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format('YYYY-MM-DD'));
    currDate.add(1, 'days');
  }

  return dates;
};

export const findUnavailableDates = (
  openings: Openings | undefined,
  minDays: number | undefined,
  maxDays: number | undefined
) => {
  const allDates = enumerateDaysBetweenDates(
    moment().startOf('day').add(minDays, 'days').toDate(),
    moment().startOf('day').add(maxDays, 'days').toDate()
  );

  if (!openings) {
    return allDates.map((x) => moment(x).startOf('day').toDate());
  }

  const available = Object.keys(openings).map((d: any) =>
    moment(d).startOf('day').format('YYYY-MM-DD')
  );

  const result = allDates
    .filter((x) => !available.includes(x))
    .map((x) => moment(x).startOf('day').toDate());

  return result;
};

export const datePickerValueFromString = (date?: string): Date | undefined => {
  return date
    ? new Date(new Date(date).getTime() + Math.abs(new Date(date).getTimezoneOffset() * 60000))
    : undefined;
};
