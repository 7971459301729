import React from 'react';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import ProfileScreen from 'src/scenes/Profile';
import { Screens } from '../screens';
import ProfileClientPracticeConfiguration from 'src/scenes/ProfileClientPracticeConfiguration';
import ProfileUserInfo from 'src/scenes/ProfileUserInfo';
import ProfileAppSettings from 'src/scenes/ProfileAppSettings';
import Home from 'src/scenes/Home';
import { useTranslation } from 'react-i18next';
import { fontStyles } from 'src/theme/globalStyles';
import HomeStackParamsList from './ParamsList';
import { FocusAwareStatusBar } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import HelpCenter from 'src/scenes/HelpCenter';
import ArticleHub from 'src/scenes/ArticleHub';
import Article from 'src/scenes/Article';
import useDefaultModalScreenOptions from '../useDefaultModalScreenOptions';

const HomeStack = createStackNavigator<HomeStackParamsList>();

export const HomeStackNavigator: React.FC = () => {
  const {
    colors,
    viewMode: { horizontalInset }
  } = useAppTheme();
  const { t } = useTranslation();

  const mainThemedHeader: StackNavigationOptions = {
    headerShown: true,
    headerTitleAlign: 'center',
    headerTitleStyle: fontStyles.headline,
    headerTintColor: colors.onPrimary,
    headerBackTitleVisible: false,
    headerShadowVisible: false,
    headerStyle: { backgroundColor: colors.primary },
    cardStyle: { backgroundColor: colors.background },
    headerRightContainerStyle: {
      paddingRight: horizontalInset
    },
    headerLeftContainerStyle: {
      paddingLeft: horizontalInset
    }
  };

  const modalScreenOptions = useDefaultModalScreenOptions();

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <HomeStack.Navigator>
        <HomeStack.Group screenOptions={mainThemedHeader}>
          <HomeStack.Screen
            name={Screens.HOME}
            component={Home}
            options={{ title: t('home:title'), headerShown: false }}
          />
          <HomeStack.Screen
            name={Screens.PROFILE_ROOT}
            component={ProfileScreen}
            options={{ title: t('profileRoot:title') }}
          />
          <HomeStack.Screen
            name={Screens.PROFILE_USERINFO}
            component={ProfileUserInfo}
            options={{ title: t('userInfo:title') }}
          />
          <HomeStack.Screen
            name={Screens.PROFILE_APPSETTINGS}
            component={ProfileAppSettings}
            options={{ title: t('settings:title') }}
          />
          <HomeStack.Screen
            name={Screens.PROFILE_CLIENT_PRACTICE_CONFIGURATION}
            component={ProfileClientPracticeConfiguration}
            options={{ title: t('profileClientPracticeConfig:title') }}
          />
          <HomeStack.Screen
            name={Screens.HELP_CENTER}
            component={HelpCenter}
            options={{ title: t('helpCenter:title') }}
          />
          <HomeStack.Screen
            name={Screens.ARTICLES_HUB}
            component={ArticleHub}
            options={{ title: t('articleHub:title') }}
          />
          <HomeStack.Screen
            name={Screens.ARTICLE}
            component={Article}
            options={{
              ...modalScreenOptions,
              headerShown: false
            }}
          />
        </HomeStack.Group>
      </HomeStack.Navigator>
    </>
  );
};
