/**
 * Keys to access members of AsyncStorage
 */
enum AsyncStorageKeys {
  ANDROID_INITIAL_PN_PROMPT = 'ANDROID_INITIAL_PN_PROMPT',
  BIOMETRIC_AUTH_APP_SETTING = 'BIOMETRIC_AUTH_APP_SETTING',
  CALENDAR_APPOINTMENT = 'CALENDAR_APPOINTMENT',
  DEVICE_TOKEN = 'DEVICE_TOKEN',
  EMAIL_CONFIRMATION_TIMESTAMP = 'EMAIL_CONFIRMATION_TIMESTAMP',
  LAST_REVIEW_REQUEST_DATE = 'LAST_REVIEW_REQUEST_DATE',
  LOCALE_PERSISTENCE_KEY = 'app_locale',
  NOTIFICATION_DECIDED = 'NOTIFICATION_DECIDED',
  REMEMBER_ME = 'REMEMBER_ME',
  TOKEN = 'TOKEN',
  TUTORIALS = 'TUTORIALS',
  TUTORIALS_DISABLED = 'TUTORIALS_DISABLED',
  VISIT_TOKEN = 'VISIT',
  VISITOR_TOKEN = 'VISITOR',
  WHATS_NEW_VIEWED = 'WHATS_NEW_VIEWED'
}

export default AsyncStorageKeys;
