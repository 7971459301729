import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  BottomButton,
  Caption,
  FocusAwareStatusBar,
  Headline,
  PrimaryGradientBackground,
  TextInput
} from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useMutation } from 'react-query';
import { findUsers, impersonateUser } from 'src/api/admin';
import { Size } from 'src/constants';
import styled from 'styled-components/native';
import { FlatList, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import UserSearchResult from 'src/interfaces/api/UserSearchResult';
import { StyledHoverTextButton } from './AdminLogin';
import { Weight } from 'src/theme';
import UserSearchResultItem from './components/UserSearchResultItem';
import { useViewMode } from 'src/hooks';
import _ from 'lodash';

interface Props {
  endSession: () => void;
  token: string;
  startImpersonateSession: (userToken: string) => void;
}

const UserSearch: React.FC<Props> = ({ endSession, token, startImpersonateSession }) => {
  const { t } = useTranslation('impersonate');
  const { horizontalInset } = useViewMode();
  const { top } = useSafeAreaInsets();
  const { colors } = useAppTheme();

  const [petOwnerEmail, setPetOwnerEmail] = useState<string>();

  const { mutate: impersonate } = useMutation({
    mutationFn: async (searchResult: UserSearchResult) =>
      await impersonateUser(searchResult.id, token),
    mutationKey: ['impersonate', token],
    onSuccess: (data) => {
      startImpersonateSession(data.token);
    }
  });

  const {
    data: searchResults = [],
    mutateAsync: find,
    isSuccess
  } = useMutation({
    mutationKey: 'users-find',
    mutationFn: async () => {
      if (petOwnerEmail && token) {
        const response = await findUsers(petOwnerEmail, token);
        return response.data;
      }
      return [];
    }
  });

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <PrimaryGradientBackground />
      <View style={{ marginTop: top }}>
        <Headline textAlign='center' color={colors.onPrimary} style={{ marginTop: Size.S }}>
          {t('userSearch')}
        </Headline>
        <StyledTextInput
          label={t('petOwnerEmail')}
          value={petOwnerEmail}
          onChangeText={async (petOwnerEmail) => await setPetOwnerEmail(petOwnerEmail)}
          autoCapitalize='none'
          onSubmitEditing={async () => await find()}
        />
      </View>
      <FlatList<UserSearchResult>
        ListEmptyComponent={() =>
          _.isEmpty(searchResults) &&
          isSuccess && (
            <Body fontWeight={Weight.MEDIUM} textAlign='center' color={colors.onPrimary}>
              {t('noUsersFound')}
            </Body>
          )
        }
        data={searchResults}
        renderItem={({ item, index }) => (
          <UserSearchResultItem key={index} searchResult={item} impersonate={impersonate} />
        )}
        contentContainerStyle={[
          {
            marginHorizontal: horizontalInset + Size.X2_S,
            flexGrow: 1
          }
        ]}
      />
      <View style={{ marginHorizontal: horizontalInset }}>
        <StyledHoverTextButton mode='text' onPress={endSession} uppercase>
          <Caption fontWeight={Weight.MEDIUM} color={colors.onPrimary}>
            {t('endSession')}
          </Caption>
        </StyledHoverTextButton>
        <BottomButton onPress={async () => find()} labelStyle={{ color: colors.onPrimary }}>
          {t('findUsers')}
        </BottomButton>
      </View>
    </>
  );
};

export default UserSearch;

const StyledTextInput = styled(TextInput)`
  margin-vertical: ${Size.S}px;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset + Size.X2_S}px;
  padding-horizontal: ${Size.X2_S}px;
`;
