import { TextInput } from 'src/components';
import { Size } from 'src/constants';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

export const ContentContainer = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    marginHorizontal: theme.viewMode.horizontalInset,
    gap: Size.S
  }
}))`
  flex: 1;
  margin: ${Size.M}px;
`;

export const StyledTextInput = styled(TextInput)<{ addBottomGap?: boolean }>`
  background-color: ${({ theme: { dark } }) =>
    dark ? palette.BLACK_OPACITY_70 : palette.WHITE_OPACITY_70};
  margin-bottom: ${({ addBottomGap }) => (addBottomGap ? Size.S : 0)}px;
`;
