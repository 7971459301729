import Toast from 'react-native-toast-message';
import { t } from 'i18next';
import { ALLY_SUPPORT_EMAIL, VERSION_INFO } from 'src/constants';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import { detect } from 'detect-browser';
import { capitalize } from 'lodash';
import * as Linking from 'expo-linking';

const openEmailToAlly = async () => {
  const url = `mailto:${ALLY_SUPPORT_EMAIL}?body=${encodeURIComponent(
    t('contactAlly:contactUsEmailBody', {
      appVersion: VERSION_INFO,
      platform: Platform.OS,
      device: Device.modelName,
      os: getOSInfo(),
      browser: getBrowserInfo()
    }) as unknown as string
  )}`;
  try {
    if (await Linking.canOpenURL(url)) {
      return await Linking.openURL(url);
    } else {
      throw new Error('Unable to open email');
    }
  } catch {
    Toast.show({
      type: 'error',
      text1: t('unableToEmail', { ns: 'common' })
    });
  }
};

export default openEmailToAlly;

const getOSInfo = () => {
  const info = [Device.osName, Device.osVersion];
  return info.filter(Boolean).join(' ') || 'Unknown';
};

const getBrowserInfo = () => {
  if (window.navigator?.userAgent) {
    const detected = detect(window.navigator.userAgent);
    const info = [detected?.name, detected?.version];
    return capitalize(info.filter(Boolean).join(' ')) || 'Unknown';
  }
  return 'N/A';
};
