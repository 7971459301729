import React, { useCallback, useMemo, useRef, useState } from 'react';
import { RefreshControl, SectionList } from 'react-native';
import { useLoyaltyTransactions, useViewMode } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import LoyaltyTransaction from 'src/interfaces/api/LoyaltyTransaction';
import ActivityItem from './ActivityItem';
import { useTranslation } from 'react-i18next';
import { StyledSectionTitle } from './style';
import { Alert, AlertHandle } from 'src/components';
import { AlertOption } from 'src/components/Alert';
import { useMutation } from 'react-query';
import { queryClient } from 'src/utils';
import Toast from 'react-native-toast-message';
import { deleteLoyaltyTransaction, QueryKeys } from 'src/api';
import { GuideElement, STEPS, useTourGuide } from 'src/routes/stacks/LoyaltyTabNavigator/tour';
import { ActivityIndicator } from 'react-native-paper';
import { ActivityLineItem } from 'src/providers/LoyaltyTransactionProvider/types';
import { Margin } from 'src/constants';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';

interface Section {
  title: string | undefined;
  data: ActivityLineItem[];
}

export const tourExample: ActivityLineItem = {
  transactionId: 0,
  amount: 100,
  description: 'This is a sample transaction',
  date: new Date()
};

const LoyaltyActivity: React.FC = () => {
  const { t } = useTranslation('loyalty');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();
  const { enabled: isTouring, exitTour, pendingSteps } = useTourGuide();

  const [selectedTransaction, setSelectedTransaction] = useState<LoyaltyTransaction>();

  const { transactions, isFetched, isLoading, userCoinData } = useLoyaltyTransactions();

  const { mutate: cancelRedemption, isLoading: isCancelingRedemption } = useMutation(
    async (id: number) => await deleteLoyaltyTransaction(id),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([QueryKeys.LOYALTY_TRANSACTIONS]);
        Toast.show({ text1: t('txnDeleteSuccess') });
      },
      onError: (response: Error) => {
        Toast.show({ text1: response.message, type: 'error' });
      }
    }
  );

  const sectionData = useMemo(() => {
    return !isTouring
      ? userCoinData.sections
      : [{ index: 0, title: undefined, data: [tourExample] }];
  }, [isTouring, userCoinData.sections]);

  const onPressDelete = useCallback(
    (transactionId: number) => {
      setSelectedTransaction(transactions?.find((txn) => txn.id === transactionId));
      confirmModalRef.current?.alert();
    },
    [transactions]
  );

  const onPressConfirm = useCallback(() => {
    if (selectedTransaction) {
      cancelRedemption(selectedTransaction.id);
    }
  }, [cancelRedemption, selectedTransaction]);

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        label: t('common:cancel'),
        type: 'neutral'
      },

      {
        action: onPressConfirm,
        label: t('common:confirm')
      }
    ],
    [onPressConfirm, t]
  );

  const ref = useRef<SectionList<ActivityLineItem, Section>>(null);

  if (!isFetched) {
    return <ActivityIndicator color={colors.primary} size='large' style={{ flex: 1 }} />;
  }

  return (
    <>
      <ScrollRefProvider scrollRef={ref}>
        <SectionList
          ref={ref}
          refreshControl={
            <RefreshControl
              refreshing={isLoading || !isFetched || isCancelingRedemption}
              tintColor={colors.primary}
              onRefresh={async () =>
                await queryClient.invalidateQueries([QueryKeys.LOYALTY_TRANSACTIONS])
              }
            />
          }
          contentContainerStyle={{
            marginHorizontal: horizontalInset,
            marginVertical: Margin.Large
          }}
          renderSectionHeader={({ section }) =>
            section.data.length ? (
              <StyledSectionTitle>{section.title ?? t('pending')}</StyledSectionTitle>
            ) : (
              <></>
            )
          }
          sections={sectionData}
          renderItem={({ item, index }) => (
            <GuideElement
              id={STEPS.TRANSACTIONS}
              sectionIndex={index}
              itemIndex={index}
              body={t('tour.transactions')}
              disabled={!!index}
              onContinue={() => {
                if (!pendingSteps) exitTour();
              }}
              semiTransparentBg
            >
              <ActivityItem activityLineItem={item} onPressDelete={onPressDelete} />
            </GuideElement>
          )}
          stickySectionHeadersEnabled={false}
        />
      </ScrollRefProvider>
      <Alert
        ref={confirmModalRef}
        title={t('confirmCancelTitle')}
        options={confirmOptions}
        body={t('confirmDeleteDescription', {
          title: selectedTransaction?.description,
          amount: selectedTransaction?.amount
        })}
      />
    </>
  );
};

export default LoyaltyActivity;
