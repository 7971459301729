import { mask } from 'react-native-mask-text';
export interface Person {
  firstName?: string;
  lastName?: string;
  title?: string;
}

export const fullNameAndTitle = <T extends Person>(person?: T) => {
  if (!person) return;
  const fullName = [person.title, person.firstName, person.lastName].filter((v) => !!v).join(' ');
  return fullName.length ? fullName : undefined;
};

interface SimpleAddress {
  address: string;
  city: string;
  state: string;
  postalCode: string;
}
export const simpleAddressFormatter = <T extends SimpleAddress>(location?: T) =>
  location
    ? `${location.address} ${location.city}, ${location.state} ${location.postalCode}`
    : undefined;

export const plural = (v: number) => (Math.abs(v) > 1 ? 's' : '');

export const multiMask = (value: string, pattern: string | string[]) => {
  if (typeof pattern === 'string') return mask(value, pattern);

  // eslint-disable-next-line @typescript-eslint/no-for-in-array
  for (const p of pattern) {
    const masked = mask(value, p);
    if (masked) return masked;
  }

  return '';
};

export const template = (url: string, params: any) => {
  let urlResult = url;
  for (const key in params) {
    urlResult = urlResult.replace('<%= ' + key + ' %>', params[key]);
  }
  return urlResult;
};

export const durationFormatter = (durationInMinutes?: number) => {
  if (!durationInMinutes) return '';
  const value = durationInMinutes / 60;
  const hours = Math.floor(value);
  const minutes = Math.floor((value % 1) * 60);
  let result = '';
  if (hours > 0) {
    result = `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  }
  if (minutes > 0) {
    if (hours > 0) result += ', ';
    result += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
  }

  return result;
};
