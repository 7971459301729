export interface Todo extends NewTodo {
  id: number;
  createdAt: string; // ISO date string',
  updatedAt: string; // ISO date string'
  sourceId: string;
  clientId: string;
  practiceId: string;
  userId: string;
  todoAlertsAttributes: TodoAlert[];
}
export interface TodoAlert extends NewAlert {
  id: number;
  todoId: Todo['id'];
  createdAt: string; // ISO date string,
  updatedAt: string; // ISO date string;
  _destroy: boolean;
}

export interface NewAlert {
  alertByMinutesPrior: number;
}

export interface NewTodo {
  id?: number;
  notes?: string | null; // notes provided by user
  todoAlertsAttributes: NewAlert[];
  reason: TodoReason; // The reason will allow the user to perform a specific action in the app
  otherReason?: string | null; // user specified reason,
  allowNotify: boolean;
  isComplete: boolean;
  patientId: string;
  period?: number | null; // number of periods for calculating the next recurrence, must be greater than 0 for the todo to be repeated
  periodUnit?: PeriodUnit | null; // the unit of time for calculating the next recurrence calculating the next recurrence. (hour, day, week, month, year)
  dueDate?: string | null; // ISO date string. A null value indicates no notification will be made
  repeatUntilDate?: string | null; // ISO date string. A non null interval with a null repeated until date indicates the todo is repeated forever.
}

export interface EditTodo extends Omit<Todo, 'todoAlertsAttributes'> {
  todoAlertsAttributes: Array<TodoAlert | NewAlert>;
}

export enum TodoReason {
  GIVE_MEDICATION = 'GIVE_MEDICATION',
  REFILL_MEDICATION = 'REFILL_MEDICATION',
  VACCINATIONS = 'VACCINATIONS',
  ANNUAL_EXAM = 'ANNUAL_EXAM',
  GROOMING = 'GROOMING',
  OTHER = 'OTHER'
}

export enum PeriodUnit {
  YEAR = 'y',
  MONTH = 'M',
  WEEK = 'w',
  DAY = 'd',
  HOUR = 'h',
  MINUTE = 'm'
}

interface PeriodUnitMap {
  value: PeriodUnit;
  unitLabel: string;
  repeatLabel: string;
}
export const periodUnitMap: Record<PeriodUnit, PeriodUnitMap> = {
  [PeriodUnit.MINUTE]: {
    value: PeriodUnit.MINUTE,
    unitLabel: 'todos:frequency.minute',
    repeatLabel: 'todos:frequency.onceEveryMinute'
  },
  [PeriodUnit.HOUR]: {
    value: PeriodUnit.HOUR,
    unitLabel: 'todos:frequency.hour',
    repeatLabel: 'todos:frequency.onceEveryHour'
  },
  [PeriodUnit.DAY]: {
    value: PeriodUnit.DAY,
    unitLabel: 'todos:frequency.day',
    repeatLabel: 'todos:frequency.onceEveryDay'
  },
  [PeriodUnit.WEEK]: {
    value: PeriodUnit.WEEK,
    unitLabel: 'todos:frequency.week',
    repeatLabel: 'todos:frequency.onceEveryWeek'
  },
  [PeriodUnit.MONTH]: {
    value: PeriodUnit.MONTH,
    unitLabel: 'todos:frequency.month',
    repeatLabel: 'todos:frequency.onceEveryMonth'
  },
  [PeriodUnit.YEAR]: {
    value: PeriodUnit.YEAR,
    unitLabel: 'todos:frequency.year',
    repeatLabel: 'todos:frequency.onceEveryYear'
  }
};

export interface TodoData {
  pastDue: Todo[];
  today: Todo[];
  scheduled: Todo[];
  complete: Todo[];
}

export const isPeriodUnit = (unit: any): unit is PeriodUnit => {
  return Object.values(PeriodUnit).includes(unit as PeriodUnit);
};
