import React, { Suspense, useEffect, useState } from 'react';
import { Patient, Reminder } from 'src/interfaces';
import { useBoolean, useReminders } from 'src/hooks';
import moment, { duration } from 'moment-timezone';
import ItemDetail from './ItemDetail';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { useTranslation } from 'react-i18next';
import { CallToAction, CaughtUpText, FullActivityIndicator, ListContainer } from './shared';
import HeaderToggle from './HeaderToggle';
import { LayoutAnimation } from 'react-native';
import { AlarmBell } from 'src/components/kit/ABIcons';
import { HoverCard } from 'src/components';

const MAX_ITEMS = 3;
const DISPLAY_THRESHHOLD = duration(30, 'days');

interface Props {
  patientId?: Patient['patientId'];
  maxItems?: number;
}
const Reminders: React.FC<Props> = ({ patientId, maxItems = MAX_ITEMS }) => {
  const { i18n, t } = useTranslation('home');
  const { value: isOpen, toggle } = useBoolean(true);
  const { navigate } = useNavigation<NavigationProp<MainStackParamsList>>();
  const [count, setCount] = useState(0);
  const [reminders, setReminders] = useState<Reminder[]>();
  const { data, isFetching } = useReminders({
    patientId,
    suspense: false
  });

  useEffect(() => {
    const pastDue = data.filter(
      (v) =>
        moment(v.pastDueDate).subtract(DISPLAY_THRESHHOLD).isBefore() &&
        (!patientId || v.patientId === patientId)
    );
    setCount(pastDue.length);
    setReminders(pastDue.slice(0, maxItems));
  }, [data, maxItems, patientId]);

  return (
    <HoverCard>
      <HeaderToggle
        icon={({ size, color }) => <AlarmBell width={size} height={size} color={color} />}
        label={t('reminders')}
        toggle={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          toggle();
        }}
        count={count}
      />
      {isOpen && !isFetching && (
        <Suspense fallback={<FullActivityIndicator />}>
          <ListContainer>
            {reminders?.map((reminder) => (
              <ItemDetail
                patientId={reminder.patientId}
                description={reminder.description}
                date={moment(reminder.pastDueDate).locale(i18n.language).format('MM/DD/YYYY')}
                key={reminder.reminderId}
              />
            ))}
          </ListContainer>
        </Suspense>
      )}
      {isOpen && !isFetching && !reminders?.length && (
        <CaughtUpText>{t('noItems', { itemType: t('reminders').toLowerCase() })}</CaughtUpText>
      )}
      {isOpen && isFetching && <FullActivityIndicator />}
      {isOpen && (
        <CallToAction onPress={() => navigate(Screens.REMINDERS, { id: patientId })}>
          {t('viewAll')}
        </CallToAction>
      )}
    </HoverCard>
  );
};

export default Reminders;
