import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import { HeroImageContainer } from './style';
import LandingImage from 'assets/landing.jpg';
import { cleanUrl } from 'src/utils';
import BottomClip from './BottomClip';
import styled from 'styled-components/native';

interface Props {
  petSignInImage?: string | null;
  isNarrow: boolean;
}
const HeroImage: React.FC<Props> = ({ petSignInImage, isNarrow }) => {
  const [imageOkay, setImageOkay] = useState(true);

  useEffect(() => {
    if (petSignInImage) {
      Image.prefetch(petSignInImage)
        .then(() => setImageOkay(true))
        .catch(() => setImageOkay(false));
    } else {
      setImageOkay(false);
    }
  }, [petSignInImage]);
  return (
    <View>
      <HeroImageContainer
        isNarrow={isNarrow}
        source={petSignInImage && imageOkay ? { uri: cleanUrl(petSignInImage) } : LandingImage}
      />
      {isNarrow && (
        <BottomClipContainer>
          <BottomClip />
        </BottomClipContainer>
      )}
    </View>
  );
};

export default HeroImage;

const BottomClipContainer = styled.View`
  position: absolute;
  bottom: 0;
`;
