import React, { createContext, PropsWithChildren, useContext } from 'react';
import _useLoyaltyTransactions from './helpers';
import { Context } from './types';

const defaultContext: Context = {
  transactions: [],
  isLoading: false,
  isFetched: false,
  isRefetching: false,
  refetch: Promise.resolve,
  aggregatedExpirations: [],
  userCoinData: {
    sections: []
  },
  coinBalance: 0
};

const LoyaltyTransactionContext = createContext<Context>(defaultContext);

export const useLoyaltyTransactions = () => useContext(LoyaltyTransactionContext);

const LoyaltyTransactionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = _useLoyaltyTransactions();

  return (
    <LoyaltyTransactionContext.Provider value={value}>
      {children}
    </LoyaltyTransactionContext.Provider>
  );
};

export default LoyaltyTransactionProvider;
