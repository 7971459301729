import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import AppointmentStackParamsList from 'src/routes/stacks/AppointmentStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { AlertHandle, Caption, Row, Shimmer, TwoColorView } from 'src/components';

import { IconSize } from 'src/constants';
import {
  HeroContainer,
  BackgroundStrip,
  Content,
  StyledPatientAvatar,
  StyledConfirmationNote
} from './styled';
import { containers } from 'src/theme/globalStyles';
import { LayoutChangeEvent, RefreshControl, ScrollView } from 'react-native';
import AppointmentStatusCta from '../AppointmentsRoot/components/AppointmentStatusCta';
import LoadingDetails from './components/LoadingDetails';
import useAppointment from './hooks/useAppointment';
import Details from './components/Details';
import { queryClient } from 'src/utils';
import { QueryKeys } from 'src/api';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { checkIsSoon, checkIsExpired, checkCanConfirm, checkIsPast } from '../helper';
import { useTranslation } from 'react-i18next';
import { usePatient } from 'src/hooks/react-query';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Navigators } from 'src/routes/stacks/navigators';
import { requestCalendarPermissionsAsync } from 'expo-calendar';
import Alert, { AlertOption } from 'src/components/Alert';
import AddToCalendar from './components/AddToCalendar';
import { GuideElement, STEPS, TourGuide } from './tour';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';

type Props = NativeStackScreenProps<AppointmentStackParamsList, Screens.APPOINTMENT_DETAILS>;

const AppointmentDetails: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('appointments');
  const { id: appointmentId } = route.params;
  const { navigate } = useNavigation();

  const {
    data: appointment,
    isLoading,
    isRefetching
  } = useAppointment(appointmentId, { suspense: false });

  const { data: patient } = usePatient(appointment?.patientId, { suspense: false });

  const [{ isSoon, isExpired, canConfirm, isPast }, setState] = useState({
    isSoon: false,
    isExpired: false,
    canConfirm: false,
    isPast: false
  });

  useFocusEffect(
    useCallback(() => {
      setState({
        isSoon: checkIsSoon(appointment),
        isExpired: checkIsExpired(appointment),
        canConfirm: checkCanConfirm(appointment),
        isPast: checkIsPast(appointment)
      });
    }, [appointment])
  );

  const { colors } = useAppTheme();

  const refresh = () => {
    const queries = [
      [QueryKeys.APPOINTMENT, appointmentId],
      [QueryKeys.PATIENT],
      [QueryKeys.EMPLOYEES, appointment?.employeeId]
    ];
    queries.map(async (query) => await queryClient.invalidateQueries(query));
  };

  const [addToCalendarWidth, setAddToCalendarWidth] = useState(0);

  const onLayout = (event: LayoutChangeEvent) => {
    setAddToCalendarWidth(event.nativeEvent.layout.width);
  };

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        label: t('common:OK')
      }
    ],
    [t]
  );

  const goToAddToCalendar = async () => {
    const permissionStatus = await requestCalendarPermissionsAsync();

    if (permissionStatus?.granted) {
      navigate(Navigators.MAIN_STACK, {
        screen: Screens.ADD_TO_CALENDAR,
        params: { id: appointmentId, species: patient?.species }
      });
    } else {
      confirmModalRef.current?.alert();
    }
  };

  const scrollRef = useRef(null);

  if (!appointment) return null;
  return (
    <TourGuide.Provider disabled={isPast}>
      <ScrollRefProvider scrollRef={scrollRef}>
        <Alert
          ref={confirmModalRef}
          title={t('addToCalendar:permissionDenied')}
          body={t('addToCalendar:permissionDeniedMessage')}
          options={confirmOptions}
        />
        <TwoColorView topColor={colors.primary} bottomColor={colors.background}>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, backgroundColor: colors.background }}
            ref={scrollRef}
            refreshControl={
              <RefreshControl
                onRefresh={refresh}
                refreshing={isRefetching}
                tintColor={colors.onPrimary}
              />
            }
          >
            <HeroContainer>
              <BackgroundStrip />
              <StyledPatientAvatar
                size={IconSize.XXXL}
                patient={patient}
                style={containers.shadow}
              />
              <Shimmer isLoading={isLoading}>
                <AppointmentStatusCta
                  onLayout={onLayout}
                  {...{ appointment, isSoon, isExpired, canConfirm }}
                />
                <GuideElement id={STEPS.STATUS} body={t('detail-tour.status')} autoStart>
                  <>
                    {!isExpired &&
                      !canConfirm &&
                      !isPast &&
                      !appointment?.confirmed &&
                      !appointment?.confirmedAt && (
                        <StyledConfirmationNote>
                          <Caption textAlign={'center'}>{t('confirmIn3Days')}</Caption>
                        </StyledConfirmationNote>
                      )}
                  </>
                </GuideElement>
                <Row>
                  <AddToCalendar
                    appointment={appointment}
                    isPast={isPast}
                    width={addToCalendarWidth}
                    addToCalendarPressed={goToAddToCalendar}
                  />
                </Row>
              </Shimmer>
            </HeroContainer>
            <Content>
              <Suspense fallback={<LoadingDetails />}>
                <Details appointmentId={appointmentId} species={patient?.species} />
              </Suspense>
            </Content>
          </ScrollView>
        </TwoColorView>
      </ScrollRefProvider>
    </TourGuide.Provider>
  );
};

export default AppointmentDetails;
