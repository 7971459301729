import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Screens } from '../screens';
import { FocusAwareStatusBar } from 'src/components';
import { useTranslation } from 'react-i18next';
import PatientUpdateStackParamsList from './ParamsList';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import useDefaultStackOptions from '../useDefaultStackOptions';
import PatientProfile from 'src/scenes/PatientProfile';
import { NavigationContainer } from '@react-navigation/native';
import PatientSelector from 'src/scenes/PatientSelector';
import { Pressable } from 'react-native';
import { Margin } from 'src/constants';
import { X } from 'react-native-feather';
import useGoBack from 'src/hooks/useGoBack';
import ExitProvider from 'src/providers/ExitProvider';

const PatientUpdateStack = createStackNavigator<PatientUpdateStackParamsList>();

export const PatientUpdateStackNavigator: React.FC = () => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('requests');
  const goBack = useGoBack();

  const themedHeader = useDefaultStackOptions();

  return (
    <ExitProvider>
      <NavigationContainer independent>
        <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
        <PatientUpdateStack.Navigator>
          <PatientUpdateStack.Group screenOptions={themedHeader}>
            <PatientUpdateStack.Screen
              name={Screens.REQUESTS_PATIENT_SELECTOR}
              component={PatientSelector}
              options={{
                title: t('requests:wizard.whichPet'),
                headerLeft: () => (
                  <Pressable onPress={goBack} style={{ paddingLeft: Margin.Medium }}>
                    <X color={colors.onPrimary} />
                  </Pressable>
                )
              }}
            />
            <PatientUpdateStack.Screen
              name={Screens.PATIENT_PROFILE}
              component={PatientProfile}
              options={{ title: t('requests:wizard.whichPet'), headerShown: false }}
            />
          </PatientUpdateStack.Group>
        </PatientUpdateStack.Navigator>
      </NavigationContainer>
    </ExitProvider>
  );
};
