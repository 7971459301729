import { StyleSheet } from 'react-native';
import { Size, TABLET_WIDTH_LANDSCAPE_MAX } from 'src/constants/general';
import { palette } from 'src/theme';

export const style = StyleSheet.create({
  sectionWeb: {
    marginVertical: Size.X2_S
  },
  section: {
    marginTop: 0,
    marginBottom: Size.S
  },
  dark: {
    color: palette.AB_BRIGHT_WHITE,
    backgroundColor: palette.BLACK
  },
  largeContainer: {
    alignSelf: 'center',
    width: TABLET_WIDTH_LANDSCAPE_MAX,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  switch: {
    color: palette.AB_ELECTRIC_BLUE,
    alignSelf: 'center',
    marginRight: Size.XS,
    marginLeft: Size.L
  },
  errorText: {
    marginTop: Size.XS,
    marginHorizontal: Size.XS
  }
});
