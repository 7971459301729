import { ErrorMsg, ErrorName } from 'src/constants';
import isDefined from './isDefined';

const isDefinedOrThrowServerError = <T>(value: T, isT?: (v: unknown) => boolean) => {
  if (isDefined(value)) {
    if (!isT || isT(value)) {
      return value;
    }
  }
  const parseError = new Error(ErrorMsg.INVALID_RESPONSE);
  parseError.name = ErrorName.SERVER_ERROR;
  throw parseError;
};

export default isDefinedOrThrowServerError;
