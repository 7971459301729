import { useFormik } from 'formik';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  Alert,
  AlertHandle,
  BottomButton,
  Caption,
  FocusAwareStatusBar,
  Headline,
  HoverButton,
  PrimaryGradientBackground,
  SecureTextInput,
  TextInput
} from 'src/components';
import { Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';
import _ from 'lodash';
import { object, string } from 'yup';
import { emailSchema } from 'src/scenes/Landing/helpers/validation';
import { useMutation } from 'react-query';
import { ActivityIndicator } from 'react-native-paper';
import { layout } from 'src/theme/globalStyles';
import { Weight } from 'src/theme';
import { AlertOption } from 'src/components/Alert';
import { adminSignIn } from 'src/api/admin';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useViewMode } from 'src/hooks';
import { View } from 'react-native';

interface AdminSignInRequest {
  email: string;
  password: string;
}

interface Props {
  openUserSearch: (token: string) => void;
  endSession: () => void;
}

const validationSchema = object({
  adminUserEmail: emailSchema,
  password: string().required()
});

const AdminLogin: React.FC<Props> = ({ openUserSearch, endSession }) => {
  const { t } = useTranslation('impersonate');
  const { horizontalInset } = useViewMode();
  const { colors } = useAppTheme();
  const { top } = useSafeAreaInsets();
  const { mutateAsync: logIn, isLoading } = useMutation({
    mutationKey: ['auth-admin'],
    mutationFn: async (signInRequest: AdminSignInRequest) =>
      adminSignIn(signInRequest.email, signInRequest.password),
    onSuccess: (v) => openUserSearch(v.token)
  });

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      adminUserEmail: '',
      password: ''
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: async () => {
      await logIn({ email: values.adminUserEmail, password: values.password }).catch(async () => {
        modalRef.current?.alert();
      });
    }
  });

  const modalRef = useRef<AlertHandle>(null);
  const options: AlertOption[] = useMemo(
    () => [
      {
        label: t('common:OK')
      }
    ],
    [t]
  );

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <PrimaryGradientBackground />
      <Headline
        alignSelf='center'
        textAlign='center'
        color={colors.onPrimary}
        top={top}
        style={{ marginTop: Size.S }}
      >
        {t('adminLogin')}
      </Headline>
      {isLoading && (
        <ActivityIndicator size={'large'} style={layout.flex1} color={colors.onPrimary} />
      )}
      {!isLoading && (
        <InputContainer>
          <TextInput
            label={t('adminUserEmail')}
            value={values.adminUserEmail}
            error={!!errors.adminUserEmail}
            msg={t(`login:${errors.adminUserEmail ?? ''}`)}
            onChangeText={async (adminUserEmail) =>
              await setFieldValue('adminUserEmail', adminUserEmail)
            }
            autoCapitalize='none'
          />
          <SecureTextInput
            label={t('common:password')}
            value={values.password}
            error={!!errors.password}
            onChangeText={async (password) => await setFieldValue('password', password)}
            autoCapitalize='none'
          />
        </InputContainer>
      )}
      <View style={{ marginHorizontal: horizontalInset }}>
        <StyledHoverTextButton mode='text' onPress={endSession} uppercase>
          <Caption fontWeight={Weight.MEDIUM} color={colors.onPrimary}>
            {t('exit')}
          </Caption>
        </StyledHoverTextButton>
        <BottomButton
          onPress={async () => handleSubmit()}
          disabled={!_.isEmpty(errors) || isLoading}
          labelStyle={{ color: colors.onPrimary }}
          uppercase
        >
          {t('login')}
        </BottomButton>
      </View>
      <Alert
        ref={modalRef}
        title={t('loginFailed')}
        body={t('loginFailedMessage')}
        options={options}
      />
    </>
  );
};

export default AdminLogin;

const InputContainer = styled(KeyboardAwareScrollView).attrs(() => ({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'center',
    gap: Size.X2_S,
    padding: Size.X2_S
  }
}))`
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
`;

export const StyledHoverButton = styled(HoverButton)`
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset + Size.X2_S}px;
  margin-bottom: ${Size.XS}px;
`;

export const StyledHoverTextButton = styled(HoverButton)`
  padding: ${Size.M}px;
`;
