import { endpoint } from 'src/constants';
import { IUserSignInResponse } from 'src/interfaces';
import UserSearchResult from 'src/interfaces/api/UserSearchResult';
import { isDefinedOrThrowServerError, template } from 'src/utils';
import { impersonateApiClient } from 'src/utils/axios';

export const impersonateUser = async (
  userId: number,
  token: string
): Promise<IUserSignInResponse> => {
  const response = await impersonateApiClient.get<IUserSignInResponse>(
    template('/admin/impersonate/user/<%= id %>', { id: userId }),
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return isDefinedOrThrowServerError(response.data);
};

export const adminSignIn = async (email: string, password: string) => {
  const response = await impersonateApiClient.post<IUserSignInResponse>(endpoint('USERS_SIGN_IN'), {
    user: {
      email,
      password
    }
  });
  return response.data;
};

interface FindUsersResponse {
  data: UserSearchResult[];
}

export const findUsers = async (search: string, token: string) => {
  if (!search) {
    throw new Error('Email is required');
  }

  const response = await impersonateApiClient.get<FindUsersResponse>('/users.json', {
    headers: { Authorization: `Bearer ${token}` },
    params: { search, type: 'pet', server_managed: true }
  });
  return isDefinedOrThrowServerError(response.data);
};
