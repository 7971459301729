import { noop } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { Animated, Pressable, StyleSheet } from 'react-native';
import { Portal } from 'react-native-paper';
import { IS_WEB } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import {
  GuideElementHandle,
  STATE,
  TourGuideContext,
  TourGuideHandle,
  TourGuideProviderProps,
  TourMap,
  TourStep
} from './model';
import { isDefined, queryClient, storage } from 'src/utils';
import _GuideElement, { GuideElementProps } from './component/_GuideElement';
import { useTourGuideMaster } from './TourGuideMaster';
import { useStorage } from 'src/hooks';
import { AsyncStorageKeys } from 'src/api';
import _SliderElement, { SliderElementProps } from './component/_SliderElement';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';

const DEFAULT_CONTEXT = {
  startTour: noop,
  exitTour: noop,
  enabled: false,
  state: STATE.IDLE,
  tourId: '',
  toursEnabled: false,
  registerStep: noop,
  unregisterStep: noop,
  hasViewedTutorial: true,
  shouldUpdate: false,
  pendingSteps: false,
  updateScrollPositions: noop
};

export default function createTourGuideContext<T extends string | number>(
  tourId: string,
  steps: TourMap<T>
) {
  const _TourGuide = createContext<TourGuideContext<T>>(DEFAULT_CONTEXT);
  const useTourGuide = (): TourGuideContext<T> => useContext(_TourGuide);
  const tourSymbol = Symbol(tourId);
  const storageId: [AsyncStorageKeys, ...Array<string | number>] = [
    AsyncStorageKeys.TUTORIALS,
    tourId
  ];
  const Provider = React.forwardRef<TourGuideHandle, TourGuideProviderProps>(
    ({ disabled, children }, ref) => {
      const [hasViewedTutorial, setHasViewedTutorial] = useStorage<boolean>(storageId, true);

      const { colors } = useAppTheme();
      const { queueTour, dequeueTour, activeTour, toursEnabled, registerTour, unregisterTour } =
        useTourGuideMaster();

      const [stepQueue, setStepQueue] = useState<Array<TourStep<T>>>([]);

      const opacity = useRef(new Animated.Value(0));

      const startTour: TourGuideContext<T>['startTour'] = useCallback(async () => {
        const hasViewedTutorial = await storage.getTypedItem<boolean>(storageId.join(','));
        if (hasViewedTutorial) return;
        queueTour(tourSymbol);
        setHasViewedTutorial(true);
      }, [queueTour, setHasViewedTutorial]);

      const exitTour = useCallback(() => {
        const animation = Animated.timing(opacity.current, {
          toValue: 0,
          duration: 100,
          useNativeDriver: !IS_WEB
        });
        animation.start(() => dequeueTour(tourSymbol));
        return animation.stop;
      }, [dequeueTour]);

      const visited = useRef<Partial<Record<T, boolean>>>({});
      const isTouring = activeTour?.tourId === tourSymbol && !disabled;
      const focusedId: keyof TourMap<T> | undefined = disabled ? undefined : stepQueue[0]?.id;

      useEffect(() => {
        if (isTouring && isDefined(focusedId)) {
          visited.current[focusedId] = true;
        }
      }, [focusedId, isTouring]);

      useEffect(() => {
        const animation = Animated.timing(opacity.current, {
          toValue: isTouring && isDefined(focusedId) ? 1 : 0,
          duration: 100,
          useNativeDriver: !IS_WEB
        });
        animation.start();
        return animation.stop;
      }, [focusedId, isTouring]);

      useEffect(() => {
        return () => dequeueTour(tourSymbol);
      }, [dequeueTour]);

      const registerStep: TourGuideContext<T>['registerStep'] = useRef(
        (stepId: T, handle?: React.RefObject<GuideElementHandle>) => {
          setStepQueue((queue) => {
            if (!queue.find((step) => step?.id === stepId) && !visited.current[stepId]) {
              const newStep = { ...steps[stepId], handle };
              const priority = newStep.priority ?? Number.MAX_SAFE_INTEGER;
              if (priority !== Number.MAX_SAFE_INTEGER) {
                const insertPosition: number = queue.findIndex(
                  (step) => (step.priority ?? Number.MAX_SAFE_INTEGER) > priority
                );
                if (insertPosition !== -1) {
                  const beg = queue.slice(0, insertPosition);
                  const end = queue.slice(insertPosition);
                  return [...beg, newStep, ...end];
                }
              }
              return [...queue, newStep];
            }
            return queue;
          });
        }
      ).current;

      const updateScrollPositions = useCallback(() => {
        stepQueue.forEach((step) => {
          step?.handle?.current?.updateScrollPosition();
        });
      }, [stepQueue]);

      const [shouldUpdate, forceUpdate] = useState(false);
      const reset = useCallback(async () => {
        visited.current = {};
        setStepQueue([]);
        forceUpdate(true);
        await storage.removeItem(storageId.join(','));
        await queryClient.invalidateQueries(storageId);
        forceUpdate(false);
      }, []);

      const handle = useMemo(
        () => ({
          startTour,
          exitTour,
          updateScrollPositions,
          reset,
          removeStorageVisited: async () => storage.removeItem(storageId.join(','))
        }),
        [exitTour, reset, startTour, updateScrollPositions]
      );
      const innerRef = useRef<TourGuideHandle>(handle);
      useImperativeHandle<TourGuideHandle, TourGuideHandle>(ref, () => handle, [handle]);

      useEffect(() => {
        innerRef.current = handle;
      }, [handle]);

      useFocusEffect(
        useCallback(() => {
          registerTour(tourSymbol, innerRef);
          return () => unregisterTour(tourSymbol);
        }, [innerRef, registerTour, unregisterTour])
      );

      const unregisterStep: TourGuideContext<T>['unregisterStep'] = useRef((stepId: T) => {
        setStepQueue((queue) => [...queue.filter((step) => step.id !== stepId)]);
      }).current;

      const enabled = activeTour?.tourId === tourSymbol && !disabled && !shouldUpdate;

      const context: TourGuideContext<T> = useMemo(
        () => ({
          tourId,
          focusedId: activeTour?.tourId === tourSymbol ? focusedId : undefined,
          startTour,
          exitTour,
          enabled,
          state: STATE.IDLE,
          toursEnabled,
          registerStep,
          unregisterStep,
          hasViewedTutorial,
          shouldUpdate,
          pendingSteps: activeTour?.tourId === tourSymbol && stepQueue.length > 1,
          updateScrollPositions
        }),
        [
          activeTour?.tourId,
          focusedId,
          startTour,
          exitTour,
          enabled,
          toursEnabled,
          registerStep,
          unregisterStep,
          hasViewedTutorial,
          shouldUpdate,
          stepQueue.length,
          updateScrollPositions
        ]
      );

      return (
        <_TourGuide.Provider value={context}>
          <Portal>
            <Animated.View
              pointerEvents={isTouring && isDefined(focusedId) ? 'auto' : 'none'}
              style={[
                StyleSheet.absoluteFill,
                { backgroundColor: colors.backdrop, opacity: opacity.current }
              ]}
            >
              <Pressable onPress={exitTour} style={[StyleSheet.absoluteFill]} />
            </Animated.View>
          </Portal>
          {children}
        </_TourGuide.Provider>
      );
    }
  );

  const GuideElement = React.forwardRef<GuideElementHandle, GuideElementProps<T>>(
    ({ disabled = false, children, ...props }, ref) => {
      const tourGuide = useTourGuide();
      const { registerStep, unregisterStep, startTour, shouldUpdate } = tourGuide;
      const focused = useIsFocused();
      const shouldDisplay = !disabled && !shouldUpdate && focused;
      useEffect(() => {
        if (shouldDisplay) {
          registerStep(props.id);
          if (props.autoStart) {
            startTour();
          }
          return () => unregisterStep(props.id);
        }
      }, [props.id, registerStep, unregisterStep, props.autoStart, startTour, shouldDisplay]);

      if (disabled || !focused || !tourGuide.enabled) return <>{children}</>;
      return (
        <_GuideElement
          thisRef={ref}
          {...tourGuide}
          {...props}
          visible={tourGuide.focusedId === props.id}
        >
          <>{children}</>
        </_GuideElement>
      );
    }
  );

  const SliderElement: React.FC<SliderElementProps<T>> = ({ disabled, ...props }) => {
    const tourGuide = useTourGuide();
    const { registerStep, unregisterStep, startTour, shouldUpdate, focusedId } = tourGuide;
    const focused = useIsFocused();
    const shouldDisplay = !disabled && !shouldUpdate && focused;

    useEffect(() => {
      if (shouldDisplay) {
        registerStep(props.id);
        if (props.autoStart) {
          startTour();
        }
        return () => unregisterStep(props.id);
      }
    }, [props.id, registerStep, unregisterStep, props.autoStart, startTour, shouldDisplay]);

    if (disabled || !focused || !tourGuide.enabled || focusedId !== props.id) return null;
    return <_SliderElement {...tourGuide} {...props} />;
  };

  return {
    useTourGuide,
    TourGuide: { Provider, Consumer: _TourGuide.Consumer },
    GuideElement,
    SliderElement
  };
}
