import React from 'react';
import { SlideItem } from './Slide';
import { Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';
import { Animated, FlatList } from 'react-native';

interface Props {
  item: SlideItem;
  index: number;
  sliderWidth: number;
  focusedIndex: number;
  scrollX: Animated.Value;
  slideRef: React.RefObject<FlatList<SlideItem>>;
}

const Dot: React.FC<Props> = ({ item, index, scrollX, sliderWidth, focusedIndex, slideRef }) => {
  const { colors } = useAppTheme();
  const inputRange = [(index - 1) * sliderWidth, index * sliderWidth, (index + 1) * sliderWidth];
  const dotWidth = scrollX.interpolate({
    inputRange,
    outputRange: [Size.X2_S, Size.S, Size.X2_S],
    extrapolate: 'clamp'
  });

  const dotXCenter = scrollX.interpolate({
    inputRange,
    outputRange: [0, -Size.X3_S, 0],
    extrapolate: 'clamp'
  });

  const dotColor = scrollX.interpolate({
    inputRange,
    outputRange: [colors.disabled, colors.placeholder, colors.disabled],
    extrapolate: 'clamp'
  });

  const disabled = index === focusedIndex;

  return (
    <DotTouchable
      key={`dot-${index}-${item.title ?? ''}`}
      disabled={disabled}
      onPress={() => {
        slideRef.current?.scrollToIndex({ index, animated: true });
      }}
    >
      <DotView
        style={{
          width: dotWidth,
          backgroundColor: dotColor,
          transform: [{ translateX: dotXCenter }]
        }}
      />
    </DotTouchable>
  );
};

const DotTouchable = styled.Pressable.attrs({
  hitSlop: { top: Size.XS, right: Size.XS, bottom: Size.XS, left: Size.XS }
})`
  width: ${Size.X2_S}px;
  height: ${Size.X2_S}px;
  border-radius: ${Size.X2_S}px;
`;

const DotView = styled(Animated.View)`
  width: ${Size.X2_S}px;
  height: ${Size.X2_S}px;
  border-radius: ${Size.X2_S}px;
  background-color: ${({ theme }) => theme.colors.placeholder};
`;

export default Dot;
