import React from 'react';
import { ColorValue } from 'react-native';
import Svg, { G, NumberProp, Path } from 'react-native-svg';

interface Props {
  fill: ColorValue;
  width: NumberProp;
  height: NumberProp;
}

const HeroBottom: React.FC<Props> = ({ fill, ...props }) => {
  return (
    <Svg {...props} viewBox='0 0 390 21' preserveAspectRatio='none'>
      <G clipPath='url(#clip0_274_1868)'>
        <Path
          d='M0 0L195 0L390 0L390 0.5L390 1C390 1 303 21 195 21C87 21 0 1 0 1L0 0.5L0 0Z'
          fill={fill}
        />
      </G>
    </Svg>
  );
};

export default HeroBottom;
