import { isEmpty } from 'lodash';
import { endpoint } from 'src/constants';
import { Client } from 'src/interfaces';
import { apiClient } from '../axios';
import { UTMParams, Visit } from './model';
import { getVisitorToken, getVisitToken } from './tokens';

/**
 * Tracks a visit with the given UTM parameters. This will only track the visit if there are UTM parameters.
 * @param utmParams
 * @param path
 * @param sessionInfo
 * @returns
 */
export const trackVisit = async (
  utmParams: UTMParams,
  path: string | undefined,
  sessionInfo: Partial<Pick<Client, 'practiceId' | 'sourceId' | 'clientId'>>
) => {
  if (isEmpty(utmParams)) {
    return;
  }
  const visitorToken = await getVisitorToken();
  const visitToken = await getVisitToken();
  return await apiClient.post<Visit>(endpoint('AHOY_VISITS'), {
    visit_token: visitToken?.uuid,
    visitor_token: visitorToken?.uuid,
    landing_page: path?.replace(/^\//, ''),
    practice_id: sessionInfo?.practiceId,
    source_id: sessionInfo?.sourceId,
    client_id: sessionInfo?.clientId ? parseInt(sessionInfo.clientId) : undefined,
    ...utmParams
  });
};
