import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const House: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M5.813 13.455v6.187h4.5v-4.5a1.125 1.125 0 0 1 1.124-1.124h1.126a1.125 1.125 0 0 1 1.124 1.124v4.5h4.5v-6.187M3.563 12.33l7.643-7.643a1.117 1.117 0 0 1 1.59 0l7.643 7.643m-5.063-5.063v-2.19h2.813v5.003'
    />
  </Svg>
);
export default House;
