import { ViewProps, LayoutChangeEvent, View } from 'react-native';
import { IS_WEB } from 'src/constants';

const FixCellOffsetOnWeb: React.FC<ViewProps & { index: number; width?: number }> = (props) => {
  const { onLayout, index, width, ...other } = props;

  if (IS_WEB && width) {
    const fixOffsetOnLayout = (e: LayoutChangeEvent) => {
      if (onLayout && width) {
        onLayout({
          ...e,
          nativeEvent: {
            ...e.nativeEvent,
            layout: {
              ...e.nativeEvent.layout,
              // workaround: override the x offset
              x: index * width
            }
          }
        });
      }
    };

    return <View {...other} onLayout={fixOffsetOnLayout} />;
  }

  return <View {...props} />;
};

export default FixCellOffsetOnWeb;
