import React from 'react';
import { Caption, HoverCard, Row } from 'src/components';
import HeaderToggle from './HeaderToggle';
import { LayoutAnimation } from 'react-native';
import { useBoolean } from 'src/hooks';
import { IconProps } from 'src/components/Icons';
import { Weight } from 'src/theme';
import styled from 'styled-components/native';
import { Size } from 'src/constants';

interface Props {
  title: string;
  icon: (props: IconProps) => React.ReactElement;
  items: Array<{ name: string; value: string }>;
}

const InfoCard: React.FC<Props> = ({ title, icon, items }) => {
  const { value: isOpen, toggle } = useBoolean(true);
  return (
    <HoverCard>
      <HeaderToggle
        label={title}
        toggle={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          toggle();
        }}
        isOpen={isOpen}
        icon={icon}
      />
      {isOpen && (
        <CardContent>
          {items.map((item, index) => (
            <Row key={index} justify='space-between'>
              <Caption>{item.name}</Caption>
              <Caption fontWeight={Weight.MEDIUM} textAlign='right'>
                {item.value}
              </Caption>
            </Row>
          ))}
        </CardContent>
      )}
    </HoverCard>
  );
};

export default InfoCard;

const CardContent = styled.View`
  padding-horizontal: ${Size.L}px;
  padding-bottom: ${Size.L}px;
  gap: ${Size.X2_S}px;
`;
