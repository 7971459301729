import moment from 'moment';
import { mask } from 'react-native-mask-text';
import { BookingFormData, TransformFunc } from '../model';
import { PHONE_MASK, INTL_PHONE_MASK } from 'src/constants';
import { toLocalTime, toLocalDate } from 'src/utils';
import { ApiPracticeInfo } from 'src/interfaces';
import ImageHolderSection from '../../components/ImageHolderSection';
import { capitalize } from 'lodash';
import { t } from 'i18next';

const VALUE_TRANSFORMS: Record<string, TransformFunc> = {
  name: ({ values }) =>
    `${values.patientInfo?.name ?? ''} ${
      values.patientInfo.isNewPatient ? t('onlineBooking:form.newPatient') : ''
    }`,
  phone: ({ values }) => mask(values.clientInfo?.phoneNumber ?? '', [PHONE_MASK, INTL_PHONE_MASK]),
  preferredTime: ({ values, practiceInfo }) =>
    toLocalTime(makeMoment(values, practiceInfo), practiceInfo.timeZone),
  preferredDate: ({ values, practiceInfo }) =>
    toLocalDate(makeMoment(values, practiceInfo), practiceInfo.timeZone),
  appointmentOption: ({ values: { appointmentInfo } }) =>
    appointmentInfo?.appointmentOption?.label ?? '',
  columnOption: ({ values: { appointmentInfo } }) => appointmentInfo?.columnOption?.label ?? '',
  breed: ({ values: { patientInfo } }) => capitalize(patientInfo?.breed),
  preferredContactMethod: ({ values: { notificationSettings } }) =>
    `form.${notificationSettings?.preferredContactMethod ?? ''}`,
  smsOptIn: ({ values: { notificationSettings } }) =>
    notificationSettings?.smsOptIn ? 'form.smsTrue' : 'form.smsFalse',
  images: ImageHolderSection
};

export default VALUE_TRANSFORMS;

const makeMoment = ({ appointmentInfo }: Partial<BookingFormData>, { timeZone }: ApiPracticeInfo) =>
  moment.tz(
    `${appointmentInfo?.preferredDate ?? ''} ${appointmentInfo?.preferredTime ?? ''}`,
    timeZone
  );
