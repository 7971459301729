import React from 'react';
import { useTranslation } from 'react-i18next';
import { Caption, Column, Headline, TextInput } from 'src/components';
import { View } from 'react-native';
import { Size } from 'src/constants';
import { StyledRow } from './BasicInfo';
import { GuideElement, STEPS } from 'src/scenes/PatientList/tour';
import { FormSection } from '../../style';
import { FormStateHook } from '../../hooks/useFormState';

interface Props {
  values: FormStateHook['values'];
  setFieldValue: FormStateHook['setFieldValue'];
  errors: FormStateHook['errors'];
}

const AdditionalInfo: React.FC<Props> = ({ values, setFieldValue, errors }) => {
  const { t } = useTranslation('patientProfile');

  return (
    <GuideElement body={t('tour.additionalInfo')} id={STEPS.EDIT_ADDITIONAL_INFO} semiTransparentBg>
      <FormSection>
        <Headline>{t('additionalInfo')}</Headline>
        <Caption>{t('theseChangesImmediately')}</Caption>
        <View style={{ gap: Size.X3_S }}>
          <StyledRow>
            <Column>
              <TextInput
                label={t('insuranceProvider')}
                value={values.insuranceProvider ?? ''}
                onChangeText={async (insuranceProvider) =>
                  await setFieldValue('insuranceProvider', insuranceProvider)
                }
                error={!!errors.insuranceProvider}
                msg={errors.insuranceProvider && t(errors.insuranceProvider)}
              />
            </Column>
            <Column>
              <TextInput
                label={t('policyNumber')}
                value={values.policyNumber ?? ''}
                onChangeText={async (policyNumber) =>
                  await setFieldValue('policyNumber', policyNumber)
                }
                error={!!errors.policyNumber}
                msg={errors.policyNumber && t(errors.policyNumber)}
              />
            </Column>
          </StyledRow>
          <TextInput
            label={t('diet')}
            value={values.diet ?? ''}
            onChangeText={async (diet) => await setFieldValue('diet', diet)}
            error={!!errors.diet}
            msg={errors.diet && t(errors.diet)}
            multiline
          />
          <TextInput
            label={t('medicalConditions')}
            value={values.medicalConditions ?? ''}
            onChangeText={async (medicalConditions) =>
              await setFieldValue('medicalConditions', medicalConditions)
            }
            error={!!errors.medicalConditions}
            msg={errors.medicalConditions && t(errors.medicalConditions)}
            multiline
          />
        </View>
      </FormSection>
    </GuideElement>
  );
};

export default AdditionalInfo;
