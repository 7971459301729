import { RefObject, useReducer } from 'react';
import { ACTIONS, Actions, FormState, ReducerState, STATES } from '../model';
import { LayoutAnimation, Platform } from 'react-native';
import { AlertHandle } from 'src/components';
import { Client, Patient } from 'src/interfaces';
import { Request, RequestType } from 'src/interfaces/api/Request';
import { useFormik } from 'formik';
import {
  FormikData,
  createAdditionalInfoItemArray,
  createBasicInfoRequestItemArray,
  initialValuesBuilder,
  validationSchema
} from '../helpers';
import { UseMutateAsyncFunction, UseMutateFunction } from 'react-query';
import { InfoUpdateRequest } from 'src/interfaces/api/InfoUpdateRequest';
import { pick } from 'lodash';
import { ImageUpload } from 'src/components/UploadFiles';
import { isDefined, queryClient } from 'src/utils';
import Toast from 'react-native-toast-message';
import { t } from 'i18next';
import { QueryKeys } from 'src/api';

export type FormStateHook = ReturnType<typeof useFormState>;

const useFormState = (
  patient: Patient | undefined,
  user: Client,
  confirmModalRef: RefObject<AlertHandle>,
  goBack: () => void,
  submitAdditionalInfoUpdate: UseMutateAsyncFunction<Patient, unknown, Patient, unknown>,
  submitInfoUpdateRequest: UseMutateAsyncFunction<Request, Error, InfoUpdateRequest, unknown>,
  uploadPhoto: UseMutateFunction<
    Patient,
    Error,
    {
      patient: Patient;
      uri: string;
    },
    unknown
  >,
  deletePhoto: () => void,
  dismissModal: () => void,
  isFromRequestWizard?: boolean
) => {
  const initValues = validationSchema.cast(initialValuesBuilder(patient));
  const {
    values,
    errors,
    initialValues,
    setFieldValue,
    dirty,
    isValid,
    handleSubmit,
    resetForm,
    isSubmitting
  } = useFormik<FormikData>({
    initialValues: initValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const promises = [];

      if (!patient) {
        throw new Error('Patient is not defined');
      }
      if (values.newPatientImageUri) {
        const upload = uploadPhoto({ patient, uri: values.newPatientImageUri });
        promises.push(upload);
      } else if (values.newPatientImageUri === '') {
        const deleteP = deletePhoto();
        promises.push(deleteP);
      }
      const basicInfo = createBasicInfoRequestItemArray(values, initialValues);
      const additionalInfo = createAdditionalInfoItemArray(values, initialValues);
      const comments = values.comments ? [{ dataType: 'comments', newValue: values.comments }] : [];
      const requestImageIds = Object.values(values.images)
        .map((imageUpload: ImageUpload) => imageUpload.id)
        .filter(isDefined);

      if (basicInfo.length || comments.length || requestImageIds.length || additionalInfo.length) {
        const request = submitInfoUpdateRequest({
          practiceId: user.practiceId,
          patientId: patient.patientId,
          clientId: user.clientId,
          sourceId: user.sourceId,
          content: {
            informationUpdates: [...basicInfo, ...additionalInfo, ...comments],
            preferredContactMethod: values.contactMethod ?? '',
            origin: Platform.OS
          },
          requestImageIds,
          type: RequestType.InfoUpdate
        });
        promises.push(request);
      }

      if (additionalInfo.length) {
        const additionInfoUpdate = submitAdditionalInfoUpdate({
          ...patient,
          insuranceProvider: values.insuranceProvider ?? '',
          policyNumber: values.policyNumber ?? '',
          diet: values.diet ?? '',
          medicalConditions: values.medicalConditions ?? ''
        });
        promises.push(additionInfoUpdate);
      }

      await Promise.all(promises);
      await queryClient.invalidateQueries([QueryKeys.REQUESTS]);

      resetForm({
        values: {
          ...initialValuesBuilder(patient),
          ...pick(values, 'insuranceProvider', 'policyNumber', 'diet', 'medicalConditions')
        }
      });
      Toast.show({
        type: 'success',
        text1: t('updateSuccess', { ns: 'patientProfile' })
      });

      if (isFromRequestWizard) {
        dismissModal();
      } else {
        formAdvance();
      }
    }
  });

  const [state, dispatch] = useReducer(
    (state: ReducerState, { action }: Actions) => {
      const nextState = (state.state + 1) as FormState;
      const prevState = (state.state - 1) as FormState;
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

      switch (action) {
        case ACTIONS.ADVANCE:
          if (!(nextState in FormState)) {
            return { ...STATES[0] };
          }
          return { ...STATES[nextState] };
        case ACTIONS.GO_BACK:
          if (state.state === FormState.EDIT_INFO && dirty) {
            confirmModalRef.current?.alert();
            return state;
          }
          if (
            !(prevState in FormState) ||
            (state.state === FormState.EDIT_INFO && isFromRequestWizard)
          ) {
            goBack();
            return state;
          }
          return { ...STATES[prevState] };
      }
    },
    { ...STATES[isFromRequestWizard ? FormState.EDIT_INFO : FormState.VIEW_PROFILE] }
  );

  const formAdvance = () => dispatch({ action: ACTIONS.ADVANCE });
  const formGoBack = () => dispatch({ action: ACTIONS.GO_BACK });

  return {
    state,
    formAdvance,
    formGoBack,
    values,
    errors,
    initialValues,
    setFieldValue,
    dirty,
    isValid,
    handleSubmit,
    resetForm,
    isSubmitting
  };
};

export default useFormState;
