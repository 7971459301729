import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'react-native-paper';
import { Caption, Headline, Row, Subheading } from 'src/components';
import { Size } from 'src/constants';
import { InfoUpdateItem } from 'src/interfaces/api/InfoUpdateRequest';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

interface Props {
  infoUpdates: InfoUpdateItem[];
  preferredContactMethod: string;
  setPreferredContactMethod: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmChanges: React.FC<Props> = ({
  infoUpdates,
  preferredContactMethod,
  setPreferredContactMethod
}) => {
  const { t } = useTranslation('requests:wizard');
  const { colors } = useAppTheme();

  return (
    <ConfirmChangesContainer>
      <Headline>{t('patientProfile:confirmYourChanges')}</Headline>
      <ItemContainer>
        {infoUpdates.map((infoUpdateItem, i) => (
          <Row key={i} justify='space-between'>
            <Caption style={layout.flex2}>{infoUpdateItem.dataType}</Caption>
            <Caption style={layout.flex3} fontWeight={Weight.MEDIUM} textAlign='right'>
              {infoUpdateItem.newValue}
            </Caption>
          </Row>
        ))}
      </ItemContainer>
      <ContactMethodContainer>
        <Subheading>{t('patientProfile:preferredContactMethod')}</Subheading>
        <Caption>{t('patientProfile:inTheEvent')}</Caption>
        <RadioButton.Group
          onValueChange={async (value) => await setPreferredContactMethod(value)}
          value={preferredContactMethod}
        >
          <Row justify='center'>
            <RadioButton.Item
              mode='android'
              value='text'
              label={t('common:text')}
              color={colors.primary}
            />
            <RadioButton.Item
              mode='android'
              value='email'
              label={t('common:email')}
              color={colors.primary}
            />
            <RadioButton.Item
              mode='android'
              value='phone'
              label={t('common:phone')}
              color={colors.primary}
            />
          </Row>
        </RadioButton.Group>
      </ContactMethodContainer>
    </ConfirmChangesContainer>
  );
};

export default ConfirmChanges;

const ConfirmChangesContainer = styled.ScrollView`
  padding: ${Size.S}px;
  gap: ${Size.S}px;
`;

const ItemContainer = styled.View`
  padding: ${Size.S}px;
  gap: ${Size.X2_S}px;
`;

const ContactMethodContainer = styled.View`
  margin-top: ${Size.S}px;
  gap: ${Size.X2_S}px;
`;
