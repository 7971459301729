import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { LayoutRectangle, View } from 'react-native';
import { IS_ANDROID } from 'src/constants';
import { Scrollable } from 'src/providers/ScrollableRefProvider';
import { SCROLL_FOCUS_DELAY, Dimensions } from './helper';

interface Args {
  visible: boolean;
  focused: boolean;
  clearScrollTimeout: React.MutableRefObject<() => void>;
  applyScrollCoords: (l: LayoutRectangle, h: Dimensions) => () => void;
  scrollRef?: React.RefObject<Scrollable> | null;
  viewRef: React.MutableRefObject<View | null>;
  helperBox: Dimensions;
}

function useUpdateScrollPosition({
  visible,
  focused,
  clearScrollTimeout,
  applyScrollCoords,
  helperBox,
  scrollRef,
  viewRef
}: Args) {
  const state = useRef<Args>({
    visible,
    focused,
    clearScrollTimeout,
    applyScrollCoords,
    helperBox,
    scrollRef,
    viewRef
  });

  useEffect(() => {
    state.current = {
      visible,
      focused,
      clearScrollTimeout,
      applyScrollCoords,
      helperBox,
      scrollRef,
      viewRef
    };
  }, [visible, focused, clearScrollTimeout, applyScrollCoords, helperBox, scrollRef, viewRef]);

  const updateScrollPosition = useMemo(
    () =>
      debounce(
        () => {
          const {
            visible,
            focused,
            clearScrollTimeout,
            applyScrollCoords,
            helperBox,
            scrollRef,
            viewRef
          } = state.current;
          if (!visible || !focused || !scrollRef?.current) {
            return;
          }
          clearScrollTimeout.current();
          if (IS_ANDROID) {
            viewRef.current?.measure((x, y, width, height, pageX, pageY) => {
              const l: LayoutRectangle = { x: x + pageX, y: y + pageY, width, height };
              return applyScrollCoords(l, helperBox);
            });
          } else {
            viewRef.current?.measureInWindow((x, y, width, height) => {
              const l: LayoutRectangle = { x, y, width, height };
              return applyScrollCoords(l, helperBox);
            });
          }
        },
        SCROLL_FOCUS_DELAY,
        { leading: false, trailing: true }
      ),
    []
  );

  return updateScrollPosition;
}

export default useUpdateScrollPosition;
