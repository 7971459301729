import { PathConfig } from 'src/routes/types';
import { Screens } from '../screens';

export type LoyaltyTabParamsList = {
  [Screens.LOYALTY_REWARDS]?: undefined;
  [Screens.LOYALTY_ACTIVITY]?: undefined;
};

export const LOYALTY_TAB_PATHS: PathConfig<LoyaltyTabParamsList> = {
  initialRouteName: Screens.LOYALTY_REWARDS,
  path: 'loyalty',
  screens: {
    [Screens.LOYALTY_REWARDS]: 'rewards',
    [Screens.LOYALTY_ACTIVITY]: 'activity'
  }
};

export default LoyaltyTabParamsList;
