import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { endpoint } from 'src/constants';
import { Request, MixRequest } from 'src/interfaces/api/Request';

import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import createImageFormData, { PictureType } from 'src/utils/createImageFormData';

export const postRequest = async (request: MixRequest): Promise<Request> => {
  const response = await apiClient.post<Request>(endpoint('REQUESTS'), {
    request
  });
  return isDefinedOrThrowServerError(response.data);
};

export const uploadRequestPhoto = async (
  uri: string,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  abortController?: AbortController
): Promise<{ id: number }> => {
  const config: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    signal: abortController?.signal,
    onUploadProgress
  };

  const formData = await createImageFormData(uri, PictureType.REQUEST);
  return (await apiClient.post(endpoint('REQUEST_IMAGE'), formData, config)).data;
};

export const getRequests = async (): Promise<Request[]> => {
  const response = await apiClient.get<Request[]>(endpoint('REQUESTS'));
  return isDefinedOrThrowServerError(response.data);
};

export const getRequest = async (id: Request['id']): Promise<Request> => {
  const response = await apiClient.get<Request>(endpoint(`REQUEST`, { id }));
  return isDefinedOrThrowServerError(response.data);
};
