import { endpoint } from 'src/constants';
import Practice from 'src/interfaces/api/Practice';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const practiceSearch = async (
  name: string,
  postalCode: string,
  city: string,
  state: string
) => {
  const response = await apiClient.get<Practice[]>(
    endpoint('PRACTICE_SEARCH', { name, postalCode, city, state })
  );
  return isDefinedOrThrowServerError(response.data);
};
