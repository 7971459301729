import React from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { StyledModal, StyledCard } from 'src/components/common';
import TextInput, { TextInputProps } from 'src/components/kit/TextInput';
import { Margin, ZIP_MASK } from 'src/constants';
import { FormData } from './helpers';
import { useTranslation } from 'react-i18next';
import { CPrimaryButton, Headline } from 'src/components';
import { layout } from 'src/theme/globalStyles';

interface Props {
  hide: () => void;
  visible: boolean;
  onContinue?: () => void;
  propBuilder: (
    thisInput: keyof FormData,
    nextInput?: keyof FormData
  ) => Omit<TextInputProps, 'mode' | 'theme'>;
}

const AddressChangeModal: React.FC<Props> = ({ hide, visible, onContinue, propBuilder }) => {
  const { t } = useTranslation('informationUpdate');
  return (
    <StyledModal isVisible={visible} onDismiss={hide}>
      <StyledCard>
        <KeyboardAwareScrollView
          bounces={false}
          contentContainerStyle={{
            gap: Margin.Small
          }}
        >
          <Headline style={[layout.alignSelfCenter, { padding: Margin.Medium }]}>
            {t('updateAddress')}
          </Headline>
          <TextInput {...propBuilder('addressLine1', 'addressLine2')} />
          <TextInput {...propBuilder('addressLine2', 'city')} />
          <TextInput {...propBuilder('city', 'state')} />
          <TextInput {...propBuilder('state', 'postalCode')} mask={'AA'} />
          <TextInput {...propBuilder('postalCode')} mask={ZIP_MASK} />
          <CPrimaryButton
            onPress={() => {
              hide();
              onContinue?.();
            }}
          >
            {t('continue', { ns: 'common' })}
          </CPrimaryButton>
        </KeyboardAwareScrollView>
      </StyledCard>
    </StyledModal>
  );
};

export default AddressChangeModal;
