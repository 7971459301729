import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AlarmClock: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='m7.5 20.438 1.04-2.079m.917-5.797H12V8.626M4.125 5.812l2.813-2.25M16.5 20.438l-1.04-2.079m4.415-12.546-2.813-2.25m-11.812 9a6.75 6.75 0 1 0 13.5 0 6.75 6.75 0 0 0-13.5 0Z'
    />
  </Svg>
);

export default AlarmClock;
