import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { QueryKeys } from 'src/api';
import { getPaginatedReminders } from 'src/api/reminders/getPaginatedReminders';
import { PAGE_LENGTH, PATIENT_STATUSES } from 'src/constants';
import { Patient, Reminder } from 'src/interfaces';
import { getNextPageParam, PaginationRS } from 'src/utils';

interface Options
  extends Omit<
    UseInfiniteQueryOptions<
      PaginationRS<Reminder>,
      unknown,
      PaginationRS<Reminder>,
      PaginationRS<Reminder>,
      QueryKeys[]
    >,
    'queryFn' | 'queryKey'
  > {
  limit?: number;
  selectedPatientId: Patient['patientId'] | undefined;
}

export const remindersOptions: (
  limit?: number
) => UseInfiniteQueryOptions<
  PaginationRS<Reminder>,
  unknown,
  PaginationRS<Reminder>,
  PaginationRS<Reminder>,
  QueryKeys[]
> = (limit = PAGE_LENGTH) => ({
  queryKey: [QueryKeys.PAGINATED_REMINDERS],
  queryFn: async ({ pageParam = 0 }) => {
    return getPaginatedReminders({
      start: pageParam * limit,
      length: PAGE_LENGTH,
      status: PATIENT_STATUSES.ACTIVE,
      order: 'asc',
      orderBy: 'pastDueDate'
    });
  },
  getNextPageParam: (...options) => getNextPageParam(...options, limit),
  suspense: true
});

const usePaginatedReminders = ({ selectedPatientId, limit = PAGE_LENGTH, ...options }: Options) => {
  const {
    data: rawData,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    ...rest
  } = useInfiniteQuery({
    ...remindersOptions(limit),
    ...options
  });

  const reminders = useMemo(() => {
    const data =
      rawData?.pages?.reduce<Reminder[]>((prev, current) => [...prev, ...current.data], []) ?? [];
    return data.filter(({ patientId }) => !selectedPatientId || selectedPatientId === patientId);
  }, [rawData, selectedPatientId]);

  return {
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    data: reminders,
    fetchNextPage,
    refetch,
    ...rest
  };
};

export default usePaginatedReminders;
