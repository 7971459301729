import { object, string } from 'yup';

export const validationSchema = object({
  currentPassword: string().required('common:required'),
  newPassword: string().min(8, 'changePassword:minLength').required('common:required'),
  confirmPassword: string()
    .test({
      test: (_, context) => {
        return !!(context.parent.newPassword === context.parent.confirmPassword);
      },
      message: 'changePassword:dontMatch'
    })
    .required('common:required')
});

export const fieldNames = ['currentPassword', 'newPassword', 'confirmPassword'] as const;
