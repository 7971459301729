import {
  cacheDirectory as cacheDir,
  deleteAsync,
  documentDirectory,
  downloadAsync,
  FileInfo,
  getInfoAsync,
  makeDirectoryAsync,
  moveAsync,
  readDirectoryAsync
} from 'expo-file-system';
import _, { uniqueId } from 'lodash';
import moment from 'moment-timezone';
import {
  DEBUG_CACHE,
  IS_WEB,
  IMAGE_CACHE_VERSION,
  IMAGES_DIRECTORY,
  OLD_IMAGES_DIRECTORY
} from 'src/constants';
import cleanUrl from '../cleanUrl';
import { encodeKeyFromPackedImageName } from '../extractImageName';

/**
 * The state of a file when it exists
 */
interface FileExists {
  exists: true;
  uri: string;
  size: number;
  isDirectory: boolean;
  modificationTime: number;
  md5?: string;
}

/**
 *  Creates a directory if it does not exist
 * @param path The path to the directory to create
 */
const tryCreateDirectory = async (path: string) => {
  if (documentDirectory) {
    const dir = `${documentDirectory}${path}`;
    await getInfoAsync(dir).then(async (value) => {
      if (!value.exists) {
        await makeDirectoryAsync(dir);
      }
    });
  }
};

/**
 *  Downloads a patient image into the patient image directory and returns the path to the image
 * @param patient
 * @returns
 */
export const downloadImageAsync = async (key: string, uri: string) => {
  if (IS_WEB) return uri;
  const docDir = documentDirectory;
  if (!docDir) throw new Error('Document directory not found');
  if (!cacheDir) throw new Error('Cache directory not found');

  const tempUri = `${cacheDir}${uniqueId()}`;
  const targetUri = buildPath(docDir, key);
  const download = await downloadAsync(cleanUrl(uri), tempUri);

  if (download.status < 200 || download.status >= 300) throw new Error('Failed to download image');

  try {
    await moveAsync({
      from: tempUri,
      to: targetUri
    });
  } catch (e) {
    throw new Error('Failed to store image');
  }
  return targetUri;
};

/**
 * Builds a record of all the images that have been downloaded at boot time
 * @returns {Promise<Record<string, Promise<string>>>}
 */
export const buildRecords = async () => {
  if (IS_WEB) return {};
  const docDir = documentDirectory;

  logCache('The document directory is', docDir);
  logCache('The cache directory is', cacheDir);

  if (!docDir) throw new Error('Document directory not found');
  await updateDirectories();

  const imageRecords: Record<string, Promise<string>> = {};
  const images = await readDirectoryAsync(buildPath(docDir));
  const imagesInfo: FileInfo[] = await Promise.all(images.map(resolveImageInfoState));
  const [imagesToDelete, imagesToRecord] = _(imagesInfo)
    .filter((image: FileInfo): image is FileExists => image.exists)
    .filter((image) => !image.uri.includes('.DS_Store'))
    .partition((image) => moment.unix(image.modificationTime).add(30, 'days').isBefore(moment()))
    .value();

  imagesToDelete.forEach(async (image) => {
    void deleteAsync(image.uri);
  });

  imagesToRecord.forEach(async (image) => {
    imageRecords[encodeKeyFromPackedImageName(image.uri)] = Promise.resolve(image.uri);
  });

  logCache('Built image records', Object.keys(imageRecords));
  return imageRecords;
};

/**
 *  Checks if an image exists and if it does, returns the state of the image
 * @param image  The image to check
 * @returns  The state of the image
 */
const resolveImageInfoState = async (image: string): Promise<FileInfo> => {
  if (!documentDirectory) throw new Error('Document directory not found');
  const path = buildPath(documentDirectory, image);
  try {
    return await getInfoAsync(path);
  } catch {
    await deleteAsync(path);
    return {
      exists: false,
      isDirectory: false,
      uri: ''
    };
  }
};

/**
 * Logs to the console if the DEBUG_CACHE flag is set to true
 * @param consoleParams
 */
export const logCache = (...consoleParams: any[]) => {
  if (DEBUG_CACHE) {
    console.debug(`[DEBUG_CACHE] ${IMAGE_CACHE_VERSION}:`, ...consoleParams);
  }
};

/**
 * Builds a path to the patient image directory
 * @param directory The directory to build the path from
 * @param image The image to append to the path
 * @returns {string} The path to the patient image directory
 */

const buildPath = (directory: string, image?: string) =>
  `${directory}${IMAGES_DIRECTORY}/${IMAGE_CACHE_VERSION}${image ? `/${image}` : ''}`;

/**
 * Updates the patient image directory to the latest version and deletes old versions
 */
const updateDirectories = async () => {
  const docDir = documentDirectory;

  if (!docDir) throw new Error('Document directory not found');

  await tryCreateDirectory(IMAGES_DIRECTORY);
  await deleteOldVersions();
  await tryCreateDirectory(`${IMAGES_DIRECTORY}/${IMAGE_CACHE_VERSION}`);

  const dir = await readDirectoryAsync(`${docDir}${IMAGES_DIRECTORY}`);
  const oldDirs = dir.filter((path) => path !== IMAGE_CACHE_VERSION);

  if (oldDirs.length > 0) {
    await Promise.all(
      oldDirs.map(async (path) => deleteAsync(`${docDir}${IMAGES_DIRECTORY}/${path}`))
    );
  }
};

/**
 * Deletes old versions of the patient image directory
 */
const deleteOldVersions = async () => {
  const docDir = documentDirectory;

  if (!docDir) throw new Error('Document directory not found');

  const folders = await readDirectoryAsync(`${docDir}${IMAGES_DIRECTORY}`);
  const oldFolders = folders.filter((folder) => folder !== IMAGE_CACHE_VERSION);
  await Promise.all(
    oldFolders.map(async (folder) => deleteAsync(`${docDir}${IMAGES_DIRECTORY}/${folder}`))
  );
  // delete the old images directory
  try {
    await deleteAsync(`${docDir}${OLD_IMAGES_DIRECTORY}`);
  } catch {
    // do nothing
  }
};
