import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import TrophyHero from './components/TrophyHero';
import AwardHero from './components/AwardHero';

export enum STEPS {
  DRAWER,
  BALANCE,
  REWARDS,
  EXPIRING,
  TRANSACTIONS,
  BAR
}

const LoyaltyTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: {
    id: STEPS.DRAWER,
    priority: 0
  },
  [STEPS.BALANCE]: {
    id: STEPS.BALANCE,
    priority: 1
  },
  [STEPS.REWARDS]: {
    id: STEPS.REWARDS,
    priority: 2
  },
  [STEPS.EXPIRING]: {
    id: STEPS.EXPIRING,
    priority: 3
  },
  [STEPS.TRANSACTIONS]: {
    id: STEPS.TRANSACTIONS,
    priority: 4
  },
  [STEPS.BAR]: {
    id: STEPS.BAR,
    priority: 5
  }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'loyalty:tour.slide0.title',
    body: 'loyalty:tour.slide0.body',
    image: <TrophyHero />
  },
  {
    title: 'loyalty:tour.slide1.title',
    body: 'loyalty:tour.slide1.body',
    image: <AwardHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.LOYALTY,
  LoyaltyTourMap
);
