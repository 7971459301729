import Paws from 'assets/customizations/onlineBooking/backgroundPattern/paws.png';
import HorseShoes from 'assets/customizations/onlineBooking/backgroundPattern/horse_shoes.png';
import PawsAndHorseshoes from 'assets/customizations/onlineBooking/backgroundPattern/paws_and_horseshoes.png';
import PawsAndLizards from 'assets/customizations/onlineBooking/backgroundPattern/paws_and_lizards.png';
import { BackgroundPatternCustomization } from 'src/interfaces/api/PracticeTheme';

const getOnlineBookingBackground = (backgroundPattern?: BackgroundPatternCustomization) => {
  switch (backgroundPattern) {
    case BackgroundPatternCustomization.HORSE_SHOES:
      return HorseShoes;
    case BackgroundPatternCustomization.PAWS:
      return Paws;
    case BackgroundPatternCustomization.PAWS_AND_HORSESHOES:
      return PawsAndHorseshoes;
    case BackgroundPatternCustomization.PAWS_AND_LIZARDS:
      return PawsAndLizards;
    default:
      return Paws;
  }
};

export default getOnlineBookingBackground;
