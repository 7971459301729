import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Subheading } from 'src/components';
import PatientRowItem from 'src/components/PatientRowItem';
import RefillModal, { RefillModalHandle } from 'src/components/RefillModal';
import { IS_WEB, Margin } from 'src/constants';
import { useViewMode } from 'src/hooks';
import { useRequestSetting } from 'src/hooks/react-query';
import usePaginatedPrescriptions from 'src/hooks/react-query/usePaginatedPrescriptions';
import useFetchNextPage from 'src/hooks/useFetchNextPage';
import { Patient } from 'src/interfaces';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useSelectedPatient } from 'src/providers/SelectedPatientProvider';
import { layout } from 'src/theme/globalStyles';
import { isPatientActive, isPrescriptionValid } from 'src/utils';
import styled from 'styled-components/native';

const FilteredPrescriptions: React.FC = () => {
  const { t } = useTranslation('patientProfile');
  const { horizontalInset, windowHeight } = useViewMode();
  const { colors } = useAppTheme();

  const { selectedPatient } = useSelectedPatient();

  const {
    data: prescriptions,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = usePaginatedPrescriptions({
    selectedPatientId: selectedPatient?.patientId,
    suspense: false
  });

  const refillModalRef = useRef<RefillModalHandle>(null);

  const { data: requestSettings } = useRequestSetting();

  const actionCondition = (p?: Patient) =>
    isPatientActive(p) && !requestSettings?.refillRequestsDisabled;

  const { setToggle } = useFetchNextPage(hasNextPage, isFetched, fetchNextPage);

  if (!isRefetching && isFetched && !prescriptions.length) {
    return selectedPatient ? (
      <View style={[layout.flex1, layout.justifyCenter, layout.alignItemsCenter]}>
        <Subheading alignSelf='center'>
          {t('noPrescriptionsFor', { petName: selectedPatient?.name })}
        </Subheading>
      </View>
    ) : (
      <View style={[layout.flex1, layout.alignItemsCenter, layout.justifyCenter]}>
        <Subheading>{t('noPrescriptions')}</Subheading>
      </View>
    );
  }

  return (
    <>
      <FlatList
        style={{ maxHeight: IS_WEB ? windowHeight - 100 : undefined }}
        contentContainerStyle={{ marginHorizontal: horizontalInset }}
        data={prescriptions}
        refreshControl={
          <RefreshControl
            refreshing={isRefetching}
            onRefresh={refetch}
            tintColor={colors.primary}
          />
        }
        ListEmptyComponent={<ActivityIndicator size='large' style={layout.flex1} />}
        renderItem={({ item }) => (
          <PatientRowItem
            key={item.prescriptionId}
            patientId={item.patientId}
            date={item.prescribedAt}
            sourceId={item.prescriptionId}
            description={item.description}
            dateLabel={t('rxDate')}
            actionCondition={actionCondition}
            action={
              isPrescriptionValid(item) && {
                label: t('refill'),
                onPress: () => refillModalRef.current?.request?.(item)
              }
            }
          />
        )}
        onEndReached={() => setToggle((prev) => !prev)}
        ListFooterComponent={
          <ListFooterContainer>
            {isFetchingNextPage && <ActivityIndicator />}
            {!isLoading && !hasNextPage && !isFetchingNextPage && (
              <Subheading textAlign='center' color={colors.onSurface}>
                {selectedPatient
                  ? t('noPrescriptionsFor', { petName: selectedPatient?.name })
                  : t('noMorePrescriptions')}
              </Subheading>
            )}
          </ListFooterContainer>
        }
      />
      <RefillModal ref={refillModalRef} />
    </>
  );
};

export default FilteredPrescriptions;

const ListFooterContainer = styled.View`
  margin: ${Margin.Large}px;
`;
