import noop from 'lodash/noop';
import { defaultLanguage } from './languageConfig';
import { LanguageDetectorAsyncModule } from 'i18next';
import { storage } from 'src/utils';
import { AsyncStorageKeys } from 'src/api';

const RNLanguageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: async (cb: (detectedLocale: string) => void): Promise<void> => {
    const persistedLocale = await storage.getItem(AsyncStorageKeys.LOCALE_PERSISTENCE_KEY);
    return cb(persistedLocale ?? defaultLanguage);
  },
  init: noop,
  cacheUserLanguage: (locale: string): void => {
    void storage.setItem(AsyncStorageKeys.LOCALE_PERSISTENCE_KEY, locale);
  }
};

export default RNLanguageDetector;
