import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { Screens } from 'src/routes/stacks/screens';
import TodoStackParamList from 'src/routes/stacks/ToDoStackNavigator/ParamsList';
import TodoListContent from './components/TodoListContent';
import { TourGuide } from './components/tour';

type Props = NativeStackScreenProps<TodoStackParamList, Screens.TO_DOS>;

const TodoList: React.FC<Props> = ({ navigation }) => {
  return (
    <TourGuide.Provider>
      <TodoListContent navigation={navigation} />
    </TourGuide.Provider>
  );
};

export default TodoList;
