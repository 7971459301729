import { Client, Patient } from 'src/interfaces';
import URI from 'urijs';

/**
 * Extracts the image name from the original image url
 * @param url
 * @returns
 */
export const extractNameFromOriginalImageUrl = (url: string) => {
  return new URI(url).filename().replace(/\?.*/, '') + (url.match(/\..{3,4}$/)?.[0] ?? '.jpg');
};

/**
 * Encodes a key from a patient object to access the image in the image cache
 * @param patient
 * @returns
 */
export const encodeKeyFromPatient = (patient?: Patient) => {
  if (!patient) return undefined;
  return `${patient.practiceId},${patient.patientId},${extractNameFromOriginalImageUrl(
    patient.photo.urls.original
  )}`;
};

/**
 * Encode a key from the user object to access the image in the image cache
 */
export const encodeKeyFromUser = (user: Client) =>
  `${user.practiceId},${user.clientId},${extractNameFromOriginalImageUrl(
    user.photo.urls.original
  )}`;

/**
 * Packs the patient image name into a key that can be used as a key for the image record cache.
 * @param patient
 * @returns
 */
export const packImageName = (patient: Patient) =>
  [
    patient.practiceId,
    patient.patientId,
    extractNameFromOriginalImageUrl(patient.photo.urls.original)
  ]
    .map(encodeURIComponent)
    .join('__') + (patient.photo.urls.original.match(/\..{3,4}$/)?.[0] ?? '.jpg');

/**
 * Unpacks the image name from the file system that can be used as a key for the image record cache.
 * @param name
 * @returns
 * @example
 */
export const unpackImageName = (name: string) => {
  const [practiceId, patientId, imageName] = new URI(name)
    .filename()
    .replace(/.((jpg)|(png)|(jpeg))/, '')
    .split('__');
  return {
    practiceId: decodeURIComponent(practiceId),
    patientId: decodeURIComponent(patientId),
    imageName: decodeURIComponent(imageName)
  };
};

/**
 *  Encodes a key from the patient image directory file name to access the image in the patient image cache.
 * @param name
 * @returns
 */
export const encodeKeyFromPackedImageName = (name: string) => {
  const { practiceId, patientId, imageName } = unpackImageName(name);
  return `${practiceId},${patientId},${imageName}`;
};
