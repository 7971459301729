import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { getEmergencyContacts, QueryKeys } from 'src/api';
import { Body, FocusAwareStatusBar, Subheading } from 'src/components';
import { Margin } from 'src/constants';
import { useViewMode } from 'src/hooks';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import CollapsibleSection from './CollapsibleSection';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const AfterHours: React.FC = () => {
  const { t } = useTranslation('afterHours');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();

  const { data: emergencyContacts, isFetching } = useQuery([QueryKeys.EMERGENCY_CONTACTS], {
    queryFn: getEmergencyContacts
  });

  if (isFetching) return <ActivityIndicator size='large' style={layout.flex1} />;

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <SafeAreaView
        style={[layout.flex1, { backgroundColor: colors.background }]}
        edges={['bottom', 'left', 'right']}
      >
        {!emergencyContacts?.length && (
          <View style={[layout.flex1, layout.alignItemsCenter, layout.justifyCenter]}>
            <Subheading textAlign='center'>{t('none')}</Subheading>
          </View>
        )}
        {!!emergencyContacts?.length && (
          <Container contentContainerStyle={{ marginHorizontal: horizontalInset }}>
            <Header>{t('header')}</Header>
            {emergencyContacts?.map((practice) => {
              return <CollapsibleSection key={practice.id} practice={practice} />;
            })}
          </Container>
        )}
      </SafeAreaView>
    </>
  );
};

export default AfterHours;

const Container = styled(ScrollView)`
  flex: 1;
  padding: ${Margin.Small}px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Header = styled(Body)`
  text-align: center;
  margin-vertical: ${Margin.Large}px;
`;
