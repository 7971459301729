import { t } from 'i18next';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { deleteUser } from 'src/api';
import { useBoolean } from 'src/hooks';
import { useAuth } from 'src/providers/AuthProvider';

export interface ControlConfirmationModalHook {
  doDelete: () => void;
  promptDelete: () => void;
  close: () => void;
  isVisible: boolean;
}
export const useControlConfirmationModal = (): ControlConfirmationModalHook => {
  const { logOut } = useAuth();
  const { mutate: deleteAccount } = useMutation(async () => {
    await deleteUser();
    Toast.show({
      type: 'success',
      text1: t('manageAccount.accountDeleted', { ns: 'settings' })
    });
    void logOut(undefined);
  });

  const { value: isVisible, toTrue: promptDelete, toFalse: close } = useBoolean();

  const doDelete = (): void => {
    deleteAccount();
    close();
  };

  return {
    doDelete,
    promptDelete,
    close,
    isVisible
  };
};
