import { useQuery, UseQueryOptions } from 'react-query';
import { getAnnouncements, QueryKeys } from 'src/api';
import Announcement from 'src/interfaces/api/Announcement';

export const announcementOptions: UseQueryOptions<Announcement[]> = {
  queryKey: [QueryKeys.ANNOUNCEMENTS],
  queryFn: async () => await getAnnouncements(),
  refetchInterval: 60000,
  refetchIntervalInBackground: false
};
const useAnnouncements = () => useQuery(announcementOptions);

export default useAnnouncements;
