import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Body, BottomButton, FocusAwareStatusBar, Row, Subheading } from 'src/components';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import styled from 'styled-components/native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { IS_WEB, Margin, Size } from 'src/constants';
import { palette, Weight } from 'src/theme';
import { toLocalShortDate } from 'src/utils';
import moment from 'moment';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useLoyaltyTransactions } from 'src/hooks';

const WEB_IMAGE_WIDTH = 400;

type Props = NativeStackScreenProps<MainStackParamsList, Screens.TRANSACTION_DETAILS>;

const TransactionDetails: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('loyalty');
  const { colors } = useAppTheme();
  const navigation = useNavigation<NavigationProp<MainStackParamsList>>();
  const { transactions } = useLoyaltyTransactions();

  const { id: transactionId, isExpiredItem, expireAmount } = route.params;

  const transaction = useMemo(() => {
    return transactions.find((t) => t.id === transactionId);
  }, [transactionId, transactions]);

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <Container>
        <InnerView style={{ alignSelf: IS_WEB ? 'center' : undefined }}>
          <TextContainer>
            <Subheading color={colors.onSurface}>{t('transactionDetailsTitle')}</Subheading>
            <StyledRow>
              <Label fontWeight={Weight.BOLD}>{t('type')}</Label>
              <Value>{!isExpiredItem ? transaction?.type : t('expiration')}</Value>
            </StyledRow>
            {!isExpiredItem && (
              <StyledRow>
                <Label fontWeight={Weight.BOLD}>{t('description')}</Label>
                <Value>{transaction?.description}</Value>
              </StyledRow>
            )}
            <StyledRow>
              <Label fontWeight={Weight.BOLD}>{t('amountHeader')}</Label>
              <Value>
                {!isExpiredItem ? Math.abs(transaction?.amount ?? 0) : expireAmount}{' '}
                {t('careCoins')}
              </Value>
            </StyledRow>
            <StyledRow>
              <Label fontWeight={Weight.BOLD}>
                {!isExpiredItem ? t('requested') : t('expireDate')}
              </Label>
              <Value>
                {toLocalShortDate(
                  moment(!isExpiredItem ? transaction?.createdAt : transaction?.expiresAt)
                )}
              </Value>
            </StyledRow>
            {!isExpiredItem && transaction?.fulfilledAt && (
              <StyledRow>
                <Label fontWeight={Weight.BOLD}>{t('fulfilled')}</Label>
                <Value>{toLocalShortDate(moment(transaction?.fulfilledAt))}</Value>
              </StyledRow>
            )}
          </TextContainer>
          <BottomButton onPress={() => navigation.goBack()}>
            <Body color={colors.onPrimary} fontWeight={Weight.MEDIUM}>
              {t('common:OK')}
            </Body>
          </BottomButton>
        </InnerView>
      </Container>
    </>
  );
};

export default TransactionDetails;

const Label = styled(Body)`
  font-weight: ${Weight.BOLD};
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const Value = styled(Body)`
  flex-shrink: 1;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const Container = styled.View`
  flex: 1;
  justify-content: ${({ theme }) => (theme.viewMode.isMobile ? 'flex-end' : 'center')};
`;

const InnerView = styled.View`
  flex-shrink: 1;
  width: ${({ theme }) => (theme.viewMode.isMobile ? '100%' : `${WEB_IMAGE_WIDTH}px`)};
  background-color: ${({ theme }) => theme.colors.surface};
  max-height: 50%;
  border-radius: ${Size.M}px;
  overflow: hidden;
`;

const StyledRow = styled(Row)`
  justify-content: space-between;
  padding: ${Margin.Medium}px;
  gap: ${Margin.Large}px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
  align-items: flex-start;
`;

const TextContainer = styled.View`
  gap: ${Margin.Large}px;
  padding: ${Margin.ExtraLarge}px;
`;
