import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { MutationKeys, QueryKeys } from 'src/api';
import { endpoint } from 'src/constants';
import { Appointment } from 'src/interfaces';
import { useUser } from 'src/providers/ClientProvider';
import { queryClient } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import rateUs from 'src/utils/rateUs';

const useAppointmentConfirmation = (appointmentId?: Appointment['appointmentId']) => {
  const { t } = useTranslation('appointments');
  const { user } = useUser();
  const { mutate: confirmAppointment, isLoading: isConfirming } = useMutation({
    mutationKey: [MutationKeys.CONFIRMING_APPOINTMENT, appointmentId],
    mutationFn: async () => {
      if (!appointmentId) {
        return;
      }
      await apiClient.patch(
        endpoint('APPOINTMENT_CONFIRMATION', {
          appointmentId,
          sourceId: user.sourceId
        })
      );
    },
    onSuccess: () => {
      Toast.show({
        text1: t('success', { ns: 'common' }),
        text2: t('confirmed')
      });
      void Promise.all([
        queryClient.invalidateQueries([QueryKeys.APPOINTMENT, appointmentId]),
        queryClient.invalidateQueries([QueryKeys.APPOINTMENTS])
      ]);

      void rateUs();
    },
    onError: () => {
      Toast.show({
        type: 'error',
        text1: t('uhOh', { ns: 'common' }),
        text2: t('error', { ns: 'common' })
      });
    }
  });

  return { confirmAppointment, isConfirming };
};

export default useAppointmentConfirmation;
