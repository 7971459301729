import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getRequestSetting, QueryKeys } from 'src/api';
import { Patient } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';
import RequestSetting from 'src/interfaces/api/RequestSetting';
import { useUser } from 'src/providers/ClientProvider';
import { Screens } from 'src/routes/stacks/screens';
import { openWebBrowser } from 'src/utils';
import usePracticeInfo from './usePracticeInfo';
import usePractice from './usePractice';

export const requestSettingsOptions = (
  practiceId: Practice['id']
): UseQueryOptions<RequestSetting, unknown, RequestSetting, any> => ({
  queryKey: [QueryKeys.REQUEST_SETTING, practiceId],
  queryFn: async () => await getRequestSetting(practiceId)
});

const useRequestSetting = () => {
  const { user } = useUser();
  const { navigate } = useNavigation();
  const practiceQuery = usePractice();
  const practiceInfoQuery = usePracticeInfo(practiceQuery.data?.shortName);

  const queryResults = useQuery(requestSettingsOptions(user.practiceId));

  const requestAppointment = useCallback(
    (patientId?: Patient['patientId']) => {
      if (!queryResults.data || queryResults.data?.appointmentRequestsDisabled) {
        return;
      }
      if (
        queryResults.data.appointmentRequestOverride &&
        queryResults.data.appointmentRequestOverrideUrl
      ) {
        openWebBrowser(queryResults.data.appointmentRequestOverrideUrl);
      } else {
        navigate(Screens.ONLINE_BOOKING, {
          patientId
        });
      }
    },
    [navigate, queryResults.data]
  );

  const requestRefill = useCallback(
    (callback: () => void) => {
      if (!queryResults.data || queryResults.data?.refillRequestsDisabled) {
        return;
      }
      if (queryResults.data.refillRequestOverride && queryResults.data.refillRequestOverrideUrl) {
        openWebBrowser(queryResults.data.refillRequestOverrideUrl);
      } else {
        callback();
      }
    },
    [queryResults.data]
  );

  return {
    ...queryResults,
    requestAppointment,
    requestRefill,
    onlineBookingEnabled: practiceInfoQuery.data?.onlineBooking,
    isFetching: queryResults.isFetching || practiceQuery.isFetching || practiceInfoQuery.isFetching,
    isLoading: queryResults.isLoading || practiceQuery.isLoading || practiceInfoQuery.isLoading,
    isFetched: queryResults.isFetched && practiceQuery.isFetched && practiceInfoQuery.isFetched
  };
};

export default useRequestSetting;
