import _, { isFunction } from 'lodash';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, ButtonProps } from 'react-native-paper';
import { IconSize, Margin, Size } from 'src/constants';
import { palette, Weight } from 'src/theme';
import { isDarkColor } from 'src/utils';
import IconProps from '../Icons/IconProps';
import { Caption } from '../Text';
import HoverCard from './HoverCard';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { interpolateName } from 'src/utils/sentry';

export interface HoverButtonProps extends Omit<ButtonProps, 'icon' | 'children' | 'theme'> {
  icon?: React.ReactNode | ((props: IconProps) => React.JSX.Element);
  isLoading?: boolean;
  children?: ButtonProps['children'];
  numberOfLines?: number;
  sentryName?: string;
  onPress?: () => void;
}

const HoverButton: React.FC<HoverButtonProps> = ({
  buttonColor: parentColor,
  dark,
  mode = 'text',
  style,
  contentStyle,
  labelStyle,
  children,
  uppercase = true,
  icon: Icon,
  compact,
  numberOfLines,
  sentryName,
  isLoading,
  ...props
}) => {
  const theme = useAppTheme();
  const color = props.disabled ? theme.colors.disabled : parentColor ?? theme.colors.primary;
  const contentColor = useMemo(() => {
    const labelStyles = StyleSheet.flatten(labelStyle);
    if (labelStyle && labelStyles.color) {
      return labelStyles.color.toString();
    }
    if (color === theme.colors.primary) {
      return theme.colors.onPrimary;
    } else if (color === theme.colors.surface) {
      return theme.colors.onSurface;
    }
    return dark ?? isDarkColor(color) ? palette.AB_BRIGHT_WHITE : palette.AB_STONE_GRAY;
  }, [color, dark, theme, labelStyle]);

  const [backgroundColor, hoverColor] = useMemo(() => {
    if (mode === 'contained') {
      return [color, undefined];
    } else {
      return [palette.TRANSPARENT, palette.BLACK_OPACITY_10];
    }
  }, [mode, color]);

  const flattenedStyle = useMemo(() => StyleSheet.flatten(style), [style]);
  const sentryLabel = sentryName ?? interpolateName('HoverButton', children);
  return (
    <HoverCard
      sentryName={sentryLabel}
      {...props}
      style={[
        {
          backgroundColor,
          borderColor: color,
          padding: compact ? Size.X3_S : Size.XS,
          borderRadius: theme.roundness,
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          flexDirection: 'row',
          gap: Margin.Medium
        },
        mode === 'outlined' && {
          borderWidth: 2
        },
        flattenedStyle && _.omit(flattenedStyle, 'backgroundColor')
      ]}
      hoverColor={hoverColor}
      hideShadow
    >
      {!!Icon &&
        !isLoading &&
        (isFunction(Icon) ? (
          <Icon size={IconSize.M} color={mode === 'contained' ? contentColor : color} />
        ) : (
          Icon
        ))}

      {isLoading && <ActivityIndicator color={contentColor} size={IconSize.XS} />}

      <Caption
        color={mode === 'contained' ? contentColor : color}
        numberOfLines={numberOfLines}
        style={[
          {
            alignSelf: 'center',
            fontWeight: Weight.MEDIUM,
            letterSpacing: 1,
            textAlignVertical: 'bottom'
          },
          labelStyle
        ]}
      >
        {typeof children === 'string' && uppercase ? children.toLocaleUpperCase() : children}
      </Caption>

      {!!props.loading && <ActivityIndicator color={contentColor} />}
    </HoverCard>
  );
};

export default HoverButton;
