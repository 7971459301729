import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, Subheading } from 'src/components';
import QRPlayStore from 'src/components/QRPlayStore';
import QRAppStore from 'src/components/QRAppStore';
import { PETPAGE_APP_STORE, PETPAGE_PLAY_STORE, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Pressable } from 'react-native';
import { openWebBrowser } from 'src/utils';
import {
  DownloadOurAppContainer,
  AppStoreBadgeContainer,
  AppStoreContentInnerContainer,
  AppStoreContentContainer
} from '../style';
import DownloadAppStore from './DownloadAppStore';
import DownloadPlayStore from './DownloadPlayStore';
import DeviceInfo from 'react-native-device-info';

const PLAY_STORE_BADGE_WIDTH = 160;
const BADGE_WIDTH = 142;

interface Props {
  isNarrow: boolean;
}

const DownloadOurApp: React.FC<Props> = ({ isNarrow }) => {
  const { t } = useTranslation('login');
  const { colors } = useAppTheme();

  const [baseOs, setBaseOs] = useState<string>();

  useEffect(() => {
    if (!baseOs) {
      void DeviceInfo.getBaseOs().then((os) => {
        setBaseOs(os);
      });
    }
  }, [baseOs]);

  if (!baseOs) return null;

  const hideAppStoreBadge = isNarrow && baseOs === 'Android';
  const hidePlayStoreBadge = isNarrow && baseOs !== 'Android';

  return (
    <DownloadOurAppContainer>
      <Headline color={!isNarrow ? colors.onSurface : colors.onPrimary} textAlign='center'>
        {t('downloadTitle')}
      </Headline>
      {!isNarrow && (
        <Subheading color={colors.onSurface} textAlign='center'>
          {t('downloadSubtitle')}
        </Subheading>
      )}
      <AppStoreContentContainer isNarrow={isNarrow}>
        {!hideAppStoreBadge && (
          <AppStoreContentInnerContainer>
            {!isNarrow && <QRAppStore size={BADGE_WIDTH} />}
            <AppStoreBadgeContainer>
              <Pressable onPress={() => openWebBrowser(PETPAGE_APP_STORE)}>
                <DownloadAppStore width={Size.X5_L} />
              </Pressable>
            </AppStoreBadgeContainer>
          </AppStoreContentInnerContainer>
        )}
        {!hidePlayStoreBadge && (
          <AppStoreContentInnerContainer>
            {!isNarrow && <QRPlayStore size={BADGE_WIDTH} />}

            <Pressable onPress={() => openWebBrowser(PETPAGE_PLAY_STORE)}>
              <DownloadPlayStore width={PLAY_STORE_BADGE_WIDTH} />
            </Pressable>
          </AppStoreContentInnerContainer>
        )}
      </AppStoreContentContainer>
    </DownloadOurAppContainer>
  );
};

export default DownloadOurApp;
