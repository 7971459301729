import { NavigatorScreenParams } from '@react-navigation/native';
import { PathConfig } from 'src/routes/types';
import AppointmentStackParamsList, {
  APPOINTMENT_STACK_PATHS
} from '../AppointmentStackNavigator/ParamsList';
import HomeStackParamsList, { HOME_STACK_PATHS } from '../HomeStackNavigator/ParamsList';
import LoyaltyTabParamsList, { LOYALTY_TAB_PATHS } from '../LoyaltyTabNavigator/ParamsList';
import { Navigators } from '../navigators';
import PatientStackParamsList, { PET_STACK_PATHS } from '../PatientStackNavigator/ParamsList';
import TodoStackParamList, { TODO_STACK_PATHS } from '../ToDoStackNavigator/ParamsList';
import RequestStackParamList, { REQUEST_STACK_PATHS } from '../RequestStackNavigator/ParamsList';

type BottomTabParamsList = {
  [Navigators.HOME_STACK]: NavigatorScreenParams<HomeStackParamsList>;
  [Navigators.LOYALTY_STACK]: NavigatorScreenParams<LoyaltyTabParamsList>;
  [Navigators.APPOINTMENTS_STACK]: NavigatorScreenParams<AppointmentStackParamsList>;
  [Navigators.PET_STACK]: NavigatorScreenParams<PatientStackParamsList>;
  [Navigators.TODO_STACK]: NavigatorScreenParams<TodoStackParamList>;
  [Navigators.REQUEST_STACK]: NavigatorScreenParams<RequestStackParamList>;
};

export const BOTTOM_TAB_PATHS: PathConfig<BottomTabParamsList> = {
  initialRouteName: Navigators.HOME_STACK,
  screens: {
    [Navigators.HOME_STACK]: HOME_STACK_PATHS,
    [Navigators.LOYALTY_STACK]: LOYALTY_TAB_PATHS,
    [Navigators.APPOINTMENTS_STACK]: APPOINTMENT_STACK_PATHS,
    [Navigators.PET_STACK]: PET_STACK_PATHS,
    [Navigators.TODO_STACK]: TODO_STACK_PATHS,
    [Navigators.REQUEST_STACK]: REQUEST_STACK_PATHS
  }
};

export default BottomTabParamsList;
