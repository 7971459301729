import React, { useMemo } from 'react';
import { Animated, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { IS_WEB } from 'src/constants';
import useHoverEffect from 'src/hooks/useHoverEffect';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { containers } from 'src/theme/globalStyles';
import { colorShade, extractStyles, mergeRefs } from 'src/utils';
import sentry from 'src/utils/sentry';

interface Props extends ViewProps {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  disabled?: boolean;
  hoverColor?: string;
  hideShadow?: boolean;
  sentryName?: string;
}

const HoverCard = React.forwardRef<View, Props>(
  (
    {
      children,
      style,
      onPress: parentOnPress,
      disabled,
      hoverColor,
      hideShadow = false,
      sentryName = 'HoverCard',
      ...props
    },
    ref
  ) => {
    const { colors, roundness, dark } = useAppTheme();

    const { innerStyle, outerStyle } = useMemo(() => {
      const combinedStyle = StyleSheet.flatten([
        {
          backgroundColor: colors.surface,
          borderRadius: roundness
        },
        !hideShadow && {
          ...containers.shadow
        },
        style
      ]);
      return extractStyles(combinedStyle);
    }, [colors.surface, hideShadow, roundness, style]);

    const [innerRef, hoverAnimationValue] = useHoverEffect({
      disabled: !parentOnPress || disabled
    });
    const backgroundColor = hoverAnimationValue.interpolate({
      inputRange: [0, 1],
      outputRange: [
        (innerStyle.backgroundColor as string) ?? colors.surface,
        hoverColor ??
          colorShade((innerStyle.backgroundColor as string) ?? colors.surface, dark ? 1.2 : 0.8)
      ]
    });

    const onPress = useMemo(
      () =>
        parentOnPress
          ? () => {
              sentry.addBreadcrumb({ type: 'Interaction', message: `${sentryName} was pressed` });
              parentOnPress?.();
            }
          : undefined,
      [parentOnPress, sentryName]
    );

    return (
      <TouchableRipple
        sentry-label={sentryName}
        disabled={disabled}
        onPress={onPress}
        pointerEvents={disableTouches({ disabled, onPress })}
        style={outerStyle}
      >
        <Animated.View
          style={[innerStyle, { backgroundColor }]}
          ref={mergeRefs(innerRef, ref)}
          {...props}
        >
          {children}
        </Animated.View>
      </TouchableRipple>
    );
  }
);

export default HoverCard;

interface DisableTouchesArgs {
  disabled?: boolean;
  onPress?: () => void;
}

const disableTouches = ({ disabled, onPress }: DisableTouchesArgs) => {
  if (!onPress || disabled) {
    return IS_WEB ? 'none' : 'box-none';
  } else return 'auto';
};
