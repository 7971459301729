import * as React from 'react';
import { TextStyle } from 'react-native';
import useStateRef from 'react-usestateref';
import { Subheading } from '../Text';

const STEPS_DEFAULT = 17;
const TIME_DEFAULT = 19;

/**
 * Animates a number object so that it visually changes to its new value
 * @param formatter function to format value
 * @param steps pace at which value will move
 * @param time milliseconds per step
 * @param value
 */
interface AnimatedNumberProps {
  formatter?: (value: number) => string;
  steps?: number;
  time?: number;
  value: number;
  style?: TextStyle;
}

const formatFn = (value: number) => {
  return value.toString();
};

const updateValueInSteps = (
  timerRef: React.MutableRefObject<NodeJS.Timeout | undefined>,
  setViewValue: React.Dispatch<React.SetStateAction<number>>,
  viewValueRef: { readonly current: number },
  valuePerStep: number,
  minimumStep: number,
  value: number,
  stopAnimation: () => void,
  time: number
) => {
  // Clamping is required to correct for overstepping
  const clampValue =
    minimumStep === 1 ? Math.min.bind(undefined, value) : Math.max.bind(undefined, value);

  timerRef.current = setInterval(() => {
    setViewValue(Math.floor(clampValue(viewValueRef.current + valuePerStep)));

    if (
      (minimumStep === 1 && viewValueRef.current >= value) ||
      (minimumStep === -1 && viewValueRef.current <= value)
    ) {
      stopAnimation();
    }
  }, time);
};

const AnimatedNumber = ({
  formatter = formatFn,
  steps = STEPS_DEFAULT,
  time = TIME_DEFAULT,
  value,
  ...props
}: AnimatedNumberProps) => {
  const [viewValue, setViewValue, viewValueRef] = useStateRef<number>(value);
  const timerRef = React.useRef<NodeJS.Timeout>();

  const stopAnimation = () => {
    if (undefined !== timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }
  };

  React.useEffect(() => {
    return () => stopAnimation();
  }, []);

  React.useEffect(() => {
    if (viewValueRef.current === value) return;

    const minimumStep = value - viewValueRef.current > 0 ? 1 : -1;
    const stepSize = Math.floor((value - viewValueRef.current) / steps);

    const valuePerStep =
      minimumStep > 0 ? Math.max(stepSize, minimumStep) : Math.min(stepSize, minimumStep);

    updateValueInSteps(
      timerRef,
      setViewValue,
      viewValueRef,
      valuePerStep,
      minimumStep,
      value,
      stopAnimation,
      time
    );

    return () => stopAnimation();
  }, [formatter, setViewValue, steps, time, value, viewValueRef]);

  return <Subheading {...props}>{formatter(viewValue)}</Subheading>;
};

export default AnimatedNumber;
