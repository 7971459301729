import React, { useEffect, useMemo, useRef } from 'react';
import { ScrollView } from 'react-native';
import { usePatient, useViewMode } from 'src/hooks';
import { layout } from 'src/theme/globalStyles';
import { ActivityIndicator } from 'react-native-paper';
import { QueryKeys } from 'src/api';
import { Alert, AlertHandle, BottomButton, HoverButton, MasterView } from 'src/components';
import { QueryKey, useQueries } from 'react-query';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { TourGuideHandle } from 'src/module/TourGuide/model';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import ProfileView from './components/ViewProfile';
import EditInfo from './components/EditInfo';
import ConfirmChanges from './components/ConfirmChanges';
import PatientProfileAvatar from './components/PatientProfileAvatar';
import { useUser } from 'src/providers/ClientProvider';
import { FormState } from './model';
import { AlertOption } from 'src/components/Alert';
import styled from 'styled-components/native';
import { IS_ANDROID, Size } from 'src/constants';
import useUploadPatientPhoto from 'src/hooks/react-query/useUploadPatientPhoto';
import { ChevronLeft } from 'react-native-feather';
import useFormState from './hooks/useFormState';
import usePatientData from './hooks/usePatientData';
import { TourGuide } from '../PatientList/tour';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StyledFAB } from './style';
import { contactRequiredFields, isFieldDirty } from './helpers';
import _ from 'lodash';
import useGoBack from 'src/hooks/useGoBack';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Screens } from 'src/routes/stacks/screens';
import { PseudoStackParams } from './patientProfileParams';
import { useExitProvider } from 'src/providers/ExitProvider';

const queries: QueryKey[] = [QueryKeys.PATIENT, QueryKeys.PATIENTS];

function PatientProfile({
  route,
  navigation
}: NativeStackScreenProps<PseudoStackParams, Screens.PATIENT_PROFILE>) {
  const { t } = useTranslation('patientProfile');
  const { user } = useUser();
  const patientId = route.params?.id;
  const isFromRequestWizard = route.params?.isFromRequestWizard;
  const { isMobile } = useViewMode();
  const exitProvider = useExitProvider();

  const { data: patient, isFetched } = usePatient(patientId);

  const { isMutating } = useUploadPatientPhoto(patient);
  const confirmModalRef = useRef<AlertHandle>(null);

  const { submitAdditionalInfoUpdate, submitInfoUpdateRequest, uploadPhoto, deletePhoto } =
    usePatientData(patient);

  const goBack = useGoBack();

  const dismissModal = () => {
    exitProvider.closeModal();
  };

  const {
    state,
    formAdvance,
    formGoBack,
    values,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    resetForm,
    isSubmitting,
    initialValues
  } = useFormState(
    patient,
    user,
    confirmModalRef,
    goBack,
    submitAdditionalInfoUpdate,
    submitInfoUpdateRequest,
    uploadPhoto,
    deletePhoto,
    dismissModal,
    isFromRequestWizard
  );

  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        action: () => {
          resetForm();
          formGoBack();
        },
        label: t('discardChanges:goBack'),
        type: 'neutral'
      },

      {
        label: t('discardChanges:keepEditing')
      }
    ],
    [formGoBack, resetForm, t]
  );

  useEffect(() => {
    if (!patientId || (isFetched && !patient)) {
      Toast.show({
        text1: t('petNotFound'),
        type: 'error'
      });
      const timeout = setTimeout(navigation.goBack, 500);
      return () => clearTimeout(timeout);
    }
  }, [patientId, isFetched, patient, navigation, t]);

  const bottomButtonPressed = () => {
    if (state.state === FormState.EDIT_INFO) {
      formAdvance();
    } else {
      handleSubmit();
    }
  };

  const results = useQueries(queries.map((key) => ({ queryKey: [key] })));
  const isRefreshing = results.some((query) => query.isRefetching && !query.isStale);

  const scrollRef = useRef<ScrollView>(null);
  const providerRef = useRef<TourGuideHandle>(null);

  const submitDisabled = useMemo(() => {
    const needsContactMethod =
      state.state === FormState.CONFIRM_CHANGES &&
      contactRequiredFields.some((field) => isFieldDirty(field, values, initialValues)) &&
      !values.contactMethod;
    const submitDisabled =
      _.isEqual(values, initialValues) || !isValid || !!errors.images || needsContactMethod;
    return submitDisabled;
  }, [errors.images, initialValues, isValid, state.state, values]);

  const patientIsMutating = isMutating || isSubmitting;

  const { top, bottom } = useSafeAreaInsets();

  if (!patient) return <ActivityIndicator size='large' style={layout.flex1} />;

  return (
    <TourGuide.Provider ref={providerRef} disabled={isRefreshing}>
      <TourGuide.Consumer>
        {({ enabled }) => (
          <>
            <BackFabContainer top={top}>
              <StyledFAB
                icon={({ color, size }) => <ChevronLeft color={color} width={size} height={size} />}
                onPress={() => goBack()}
                disabled={patientIsMutating}
              />
            </BackFabContainer>
            <ScrollRefProvider scrollRef={scrollRef}>
              <Container
                bounces={false}
                ref={scrollRef}
                scrollEventThrottle={1}
                scrollsToTop={!enabled}
                onScrollEndDrag={() => {
                  providerRef.current?.updateScrollPositions();
                }}
                contentInset={{ bottom }}
              >
                <PatientProfileAvatar
                  state={state}
                  patient={patient}
                  newPatientImageUri={values.newPatientImageUri}
                  setFieldValue={setFieldValue}
                  patientIsMutating={patientIsMutating}
                />
                <MasterView>
                  {state.state === FormState.VIEW_PROFILE && (
                    <ProfileView patient={patient} advance={() => formAdvance()} />
                  )}
                  {state.state === FormState.EDIT_INFO && (
                    <EditInfo values={values} errors={errors} setFieldValue={setFieldValue} />
                  )}
                  {state.state === FormState.CONFIRM_CHANGES && (
                    <ConfirmChanges
                      initialValues={initialValues}
                      values={values}
                      setContactMethod={async (value) => setFieldValue('contactMethod', value)}
                    />
                  )}
                </MasterView>
              </Container>
              {state.state !== FormState.VIEW_PROFILE && state.buttonText && (
                <BottomButtonContainer>
                  {isMobile && (
                    <BottomButton disabled={submitDisabled} onPress={() => bottomButtonPressed()}>
                      {t(state.buttonText)}
                    </BottomButton>
                  )}
                  {!isMobile && (
                    <HoverButton
                      mode='contained'
                      disabled={submitDisabled}
                      onPress={() => bottomButtonPressed()}
                    >
                      {t(state.buttonText)}
                    </HoverButton>
                  )}
                </BottomButtonContainer>
              )}
              <Alert
                ref={confirmModalRef}
                title={t('discardChanges:areYouSure')}
                options={confirmOptions}
              />
            </ScrollRefProvider>
          </>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
}

export default PatientProfile;

const Container = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    backgroundColor: theme.colors.background,
    flexGrow: 1,
    justifyContent: 'center'
  }
}))`
  flex: 1;
`;

const BottomButtonContainer = styled.View`
  margin-horizontal: ${({ theme }) =>
    !theme.viewMode.isMobile ? theme.viewMode.horizontalInset : 0}px;
  margin-bottom: ${({ theme }) => (!theme.viewMode.isMobile ? Size.S : 0)}px;
  padding-horizontal: ${({ theme }) => (!theme.viewMode.isMobile ? Size.S : 0)}px;
`;

const BackFabContainer = styled.View<{ top: number }>`
  position: absolute;
  z-index: 1;
  left: ${({ theme }) => (theme.viewMode.isMobile ? Size.XS : Size.X2_S)}px;
  top: ${({ theme }) => (theme.viewMode.isMobile ? Size.XS : Size.L)}px;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  margin-top: ${({ top }) => (IS_ANDROID ? top : 0)}px;
`;
