import React from 'react';
import { Body } from 'src/components';
import { Paw } from 'src/components/Icons';
import { IconSize, Size } from 'src/constants';
import { transparent } from 'src/utils';
import styled from 'styled-components/native';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  message: string;
}
const PatientIconMessage: React.FC<Props> = ({ message }) => {
  const { colors } = useAppTheme();
  return (
    <Container>
      <IconContainer>
        <Paw size={IconSize.S} color={colors.primary} />
      </IconContainer>
      <Body>{message}</Body>
    </Container>
  );
};

export default PatientIconMessage;

const Container = styled.View`
  margin: ${Size.M}px;
  align-items: center;
`;

const IconContainer = styled.View`
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.15)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.XS}px;
  margin: ${Size.M}px;
`;
