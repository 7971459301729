import { RefillRequestContent } from 'src/interfaces/api/RefillRequest';
import { DetailItem } from './model';

const generateRefillData = (content: RefillRequestContent): DetailItem[] => [
  {
    title: 'requestFields.refill',
    value: content.refill ?? '',
    icon: 'prescription-bottle-alt'
  },
  {
    title: 'requestFields.quantity',
    value: content.quantity.toString() ?? '',
    icon: 'hashtag'
  },
  {
    title: 'requestFields.comments',
    value: content.comments ?? '',
    icon: 'comments'
  }
];

export default generateRefillData;
