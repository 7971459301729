import { LinearGradient } from 'expo-linear-gradient';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import ReactNativeModal from 'react-native-modal';
import { Card, RadioButton } from 'react-native-paper';
import { Size, MOBILE_WIDTH_MAX, Margin } from 'src/constants';
import { Weight, palette } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';
import { colorShade } from 'src/utils';
import styled from 'styled-components/native';
import { HoverButton } from './kit';
import Shimmer from './Shimmer';
import color from 'color';
import { Body } from './Text';

export const MasterView = styled.View`
  flex-grow: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  gap: ${Size.S}px;
  padding: ${Size.S}px;
`;

export const StyledMasterView = styled.View`
  flex-grow: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  padding: ${Size.S}px;
  gap: ${Size.S}px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  align-self: center;
  padding: ${Margin.Medium}px;
  max-width: ${({ theme }) => (theme.viewMode.isMobile ? 'auto' : `${MOBILE_WIDTH_MAX}px`)};
`;

export const Row = styled.View<{
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
}>`
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justify = 'center' }) => justify};
  width: auto;
`;

export const Column = styled.View`
  flex-direction: column;
  flex: 1;
`;

export const FlexColumn = styled.View<{
  numCols?: number;
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
}>`
  flex-direction: column;
  align: ${({ align = 'center' }) => align};
  flex: ${({ numCols = 1 }) => numCols};
`;

export const StyledModal = styled(ReactNativeModal)`
  flex: 1;
  margin: ${Margin.Large}px;
`;

export const StyledRadioButton = styled(RadioButton.Item).attrs({
  mode: 'android',
  labelStyle: fontStyles.caption
})`
  flex: 1;
`;

export const StyledKeyboardAwareScrollView = styled(KeyboardAwareScrollView).attrs((props) => ({
  contentContainerStyle: [
    {
      flexGrow: 1,
      alignSelf: 'center',
      width: props.theme.viewMode.maxContentWidth,
      gap: Margin.Small,
      padding: Margin.Small
    },
    props.contentContainerStyle
  ]
}))`
  width: 100%;
  flex: 1;
  align-self: center;
  margin-horizontal: auto;
`;

export const StyledSectionHeader = styled(View)`
  padding-top: ${Size.L}px;
  padding-left: ${Margin.Small}px;
`;

export const StyledResponsiveView = styled(View)`
  padding-horizontal: ${Margin.Small}px;
`;

export const BottomButtonView = styled(View)`
  flex-shrink: 0;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  padding: ${Margin.Small}px
  justify-content: ${({ theme }) => (theme.viewMode.isWeb ? 'flex-start' : 'flex-end')};
`;

export const BottomButtonViewForScrollView = styled(View)`
  justify-content: ${({ theme }) => (theme.viewMode.isWeb ? 'flex-start' : 'flex-end')};
  margin-horizontal: ${Margin.Small}px;
  padding-bottom: ${Margin.Small}px;
  padding-top: ${Margin.Medium}px;
`;

export const FullSizeShimmer = styled(Shimmer)`
  width: 100%;
  height: 100%;
  border-radius: 0px;
`;

export const HelperButton = styled(HoverButton).attrs(({ theme: { colors } }) => ({
  mode: 'text',
  uppercase: false,
  buttonColor: colors.onPrimary
}))``;

export const PrimaryGradientBackground = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.colors.primary, colorShade(theme.colors.primary, 0.8)]
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const GeneralView = styled(View)`
  padding: ${Size.M}px ${Size.X2_S}px ${Size.X3_S}px ${Size.XS}px;
  background-color: ${({ theme }) =>
    theme.dark
      ? color(theme.colors.background).lighten(0.24).rgb().string()
      : color(theme.colors.background).darken(0.06).rgb().string()};
  color: ${({ theme }) => (theme.dark ? palette.WHITE_OPACITY_54 : palette.BLACK_OPACITY_54)};
  border-bottom-width: ${Size.X4_S}px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
`;

export const Link = styled(Body)`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.onSurface};
  color: ${({ theme }) => theme.colors.onSurface};
  font-weight: ${Weight.MEDIUM};
`;

export const ChipContainer = styled.ScrollView.attrs(({ theme }) => ({
  horizontal: !theme.viewMode.isWeb,
  showsHorizontalScrollIndicator: false,
  contentInset: { left: Size.S, right: Size.S },
  contentOffset: { x: -Size.S, y: 0 },
  contentContainerStyle: [
    theme.viewMode.isWeb
      ? { flexDirection: 'row', flexWrap: 'wrap' }
      : { paddingHorizontal: Size.S },
    { gap: Size.XS }
  ]
}))`
  ${({ theme }) => (theme.viewMode.isWeb ? `margin-horizontal: ${Size.M}px;` : '')}
`;
