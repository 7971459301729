import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from '../IconProps';

const Bird: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 36 33' fill='none'>
      <Path
        d='M30 12V24L25.5 25.5L20.865 17.25C20.475 16.56 19.38 16.905 19.5 17.715L21 31.5L6 25.5L7.725 13.41C8.07968 10.9374 9.31377 8.67544 11.201 7.03894C13.0882 5.40243 15.5021 4.50103 18 4.5H30L27.63 8.055C29.04 8.82 30 10.29 30 12Z'
        fill={color}
      />
    </Svg>
  );
};

export default Bird;
