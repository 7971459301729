import { TimestampTrigger, Trigger, TriggerType } from '@notifee/react-native';
import { t } from 'i18next';
import moment, { Moment } from 'moment-timezone';
import { Patient, Todo, TodoAlert } from 'src/interfaces';
import { ReasonMap } from 'src/scenes/ToDos/ToDoList/components/model';
import { toLocalDateTime } from 'src/utils/date';

const isTimestampTrigger = (trigger?: Trigger): trigger is TimestampTrigger =>
  trigger?.type === TriggerType.TIMESTAMP;

interface ShadowAlert {
  alertByMinutesPrior: number;
}

type ShadowTodo = Pick<Todo, 'periodUnit' | 'period' | 'notes' | 'dueDate'>;

export const todoAlertId = (todo: TodoAlert) => `todo-${todo.id}`;
export const todoTitle = (todo: Todo, patientName?: Patient['name']) =>
  [
    patientName,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    String(todo.otherReason || t(ReasonMap[todo.reason].label, { ns: 'todos' }) || todo.reason)
  ]
    .filter((v) => !!v)
    .join(' - ');

export const todoBody = <ST extends ShadowTodo, SA extends ShadowAlert>(
  todo: ST,
  alert: SA,
  trigger: Trigger
) => {
  if (!isTimestampTrigger(trigger)) return;
  let useDate: Moment;
  if (!todo.periodUnit || !todo.period) {
    useDate = moment(todo.dueDate).utc();
  } else {
    useDate = calculateUpcomingDueDate(todo, alert, trigger);
  }
  return [toLocalDateTime(useDate), todo.notes].join('\n').trim();
};

/**
 * Calculates the next alert for a repeating todo.
 * Note, this has issues repeating alerts at an interval of once every minute.
 *
 * @param dueDate
 * @param periodUnit
 * @param period
 * @param alertByMinutesPrior
 * @returns
 */
export const calculateUpcomingDueDate = <ST extends ShadowTodo, SA extends ShadowAlert>(
  todo: ST,
  alert: SA,
  trigger: TimestampTrigger
) => {
  if (moment().isBefore(todo.dueDate)) {
    return moment(todo.dueDate).utc();
  }

  const triggerAlert = moment(trigger.timestamp);
  return triggerAlert.add(alert.alertByMinutesPrior, 'm');
};
