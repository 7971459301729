import { Dispatch, SetStateAction } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { ExtendedSignInRequest, SignUpRequest, SwitchPracticeResponse } from 'src/api';
import { IUserSignInResponse, Client } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';

export enum AuthStatus {
  AUTHENTICATED,
  NEEDS_TERMS,
  NEEDS_PASSWORD_RESET,
  NEEDS_UPGRADE,
  LOGGED_OUT,
  FORBIDDEN,
  NEEDS_BIOMETRIC,
  SWITCHING_PRACTICE
}

export interface Context {
  token: string | undefined;
  ready: boolean;
  logIn: UseMutateAsyncFunction<IUserSignInResponse, unknown, ExtendedSignInRequest, unknown>;
  logOut: (switchPractice?: SwitchPracticeResponse) => Promise<void>;
  signUp: UseMutateAsyncFunction<void, unknown, SignUpRequest, unknown>;
  resetError: () => void;
  isError: boolean;
  error: Error | undefined;
  setError: Dispatch<SetStateAction<Error | undefined>>;
  /**
   * This user is not guaranteed to be defined. useUser where possible.
   */
  _user?: Client | undefined;
  authStatus: AuthStatus | undefined;
  isLoggingOut: boolean;
  setAuthStatus: Dispatch<SetStateAction<AuthStatus | undefined>>;
  isLoggedIn: boolean;
  practice: Practice | undefined;
}
