import { CommonActions, useNavigation } from '@react-navigation/native';
import { NavigationRoute } from '@sentry/react-native/dist/js/tracing/reactnavigation';
import { useCallback } from 'react';
import sentry from 'src/utils/sentry';

interface ExtendedNavigationRoute extends NavigationRoute {
  state?: Route;
}
type Route =
  | Readonly<{
      key: string;
      index: number;
      routeNames: string[];
      history?: unknown[] | undefined;
      routes: ExtendedNavigationRoute[];
      type: string;
      stale: false;
    }>
  | undefined;

interface State {
  name: keyof ReactNavigation.RootParamList;
  params: ReactNavigation.RootParamList;
}

interface AvailableBackRoutes {
  parent: State | undefined;
  back: State | undefined;
  focusedName: keyof ReactNavigation.RootParamList | undefined;
  fallback: State | undefined;
}

const getAvailableRoutes = <T extends Route>(s: T): AvailableBackRoutes => {
  let state: Route = s;
  let parent: State | undefined;
  let back: State | undefined;
  const fallback = {
    name: state?.routeNames[state.index],
    params: state?.routes[state.index].params as unknown as ReactNavigation.RootParamList
  };
  let focusedName: string | undefined;
  while (state) {
    const index = state.index ?? 0;
    if (state.routes[index].state) {
      parent = {
        name: state.routes[index]?.name as keyof ReactNavigation.RootParamList,
        params: state.routes[index]?.params as unknown as ReactNavigation.RootParamList
      };
      state = state.routes[index].state;
    } else {
      if (index > 0 && index < state.routes.length) {
        back = {
          name: state.routes[index - 1].name as keyof ReactNavigation.RootParamList,
          params: state.routes[index - 1].params as unknown as ReactNavigation.RootParamList
        };
      }
      focusedName = state.routes[index]?.name;
      break;
    }
  }
  return {
    parent,
    back,
    focusedName,
    fallback
  } as unknown as AvailableBackRoutes;
};

type GoBackFn = () => void;
/**
 * Attempts to go back to the previous screen, if there is no previous screen it will go to the parent screen, if there
 * is no parent screen it will go to the first route available in the top-level stack, if there is no route it will log
 * an error.
 *
 * This is needed because goBack does not always work as expected, especially when using modals.
 * @returns {GoBackFn} A function to go back to the previous screen, parent screen or fallback route.
 */
const useGoBack = (): GoBackFn => {
  const { navigate, getState, dispatch } = useNavigation();
  return useCallback(() => {
    const { parent, back, fallback } = getAvailableRoutes(getState() as Route);
    if (back) {
      // using any here because the navigation hierarchy affects the typing
      navigate(back.name as any, back.params);
    } else if (parent) {
      dispatch(
        CommonActions.reset({ index: 0, routes: [{ name: parent.name, params: parent.params }] })
      );
    } else if (fallback) {
      dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: fallback.name, params: fallback.params }]
        })
      );
    } else {
      sentry.captureMessage('No route to go back to');
    }
  }, [getState, navigate, dispatch]);
};

export default useGoBack;
