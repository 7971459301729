import { useQuery } from 'react-query';
import { QueryKeys, getPatients, getRequests } from 'src/api';
import { ActivityIndicator } from 'react-native';
import { useTranslation } from 'react-i18next';

import { isDefined, queryClient } from 'src/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RequestFilterCriteria, filterRequests } from './helper/requestFilter';
import RequestsFilterDrawer from './components/RequestsFilterDrawer';
import { ModalDrawerHandle } from 'src/components/ModalDrawer';
import { useNavigation } from '@react-navigation/native';
import { HoverButton } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import partitionRequests from './helper/partitionRequests';
import type { RequestSectionList, RequestWithPatient } from './helper/model';
import RequestWizardFAB from '../RequestWizardFAB';
import { Patient } from 'src/interfaces';
import { GuideElement, SliderElement, SLIDES, STEPS, TourGuide } from './tour';
import RequestList from './components/RequestList';
import { DummyFAB, FabContainer } from './styles';
import { Plus } from 'react-native-feather';
import _ from 'lodash';

const FALLBACK_DATA: RequestSectionList[] = [];
const Requests: React.FC = () => {
  const { t } = useTranslation('requests');
  const { colors } = useAppTheme();
  const [filters, setFilters] = useState<RequestFilterCriteria>({});
  const [open, setOpen] = useState<boolean>(false);

  const {
    data = FALLBACK_DATA,
    isRefetching,
    refetch,
    isFetched
  } = useQuery({
    queryKey: [QueryKeys.REQUESTS, filters],
    queryFn: async () => {
      const data = await getRequests();
      const patients =
        (await queryClient.getQueryData<Patient[]>([QueryKeys.PATIENTS])) ?? (await getPatients());
      const prepData: RequestWithPatient[] = data.map((request) => {
        const patient = patients.find((patient) => patient.patientId === request.patientId);
        if (patient) {
          return { ...request, patient, notOwner: false };
        }
        return { ...request, notOwner: true };
      });
      if (Object.values(filters).filter(isDefined).length > 0) {
        return [{ title: 'sectionTitles.results', data: filterRequests(prepData, filters) }];
      } else {
        return partitionRequests(prepData);
      }
    }
  });

  const filterCount = Object.values(filters).filter(isDefined).length;
  const { setOptions } = useNavigation();
  const filterRef = useRef<ModalDrawerHandle>(null);
  const showFilter = useCallback(() => filterRef.current?.show(), []);
  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HoverButton uppercase={false} buttonColor={colors.onPrimary} onPress={showFilter}>
          {t('common:filters')}
          {!!filterCount && ` (${filterCount})`}
        </HoverButton>
      )
    });
  }, [setOptions, t, colors, filters, filterCount, showFilter]);

  if (!isFetched) {
    return <ActivityIndicator color={colors.primary} size='large' style={{ flex: 1 }} />;
  }

  return (
    <TourGuide.Provider>
      <TourGuide.Consumer>
        {({ exitTour, enabled }) => (
          <>
            <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
            <RequestList
              data={data}
              isFetched={isFetched}
              isRefetching={isRefetching}
              refetch={refetch}
              setOpen={setOpen}
              setFilters={setFilters}
              filterCount={filterCount}
            />
            <RequestsFilterDrawer ref={filterRef} filters={filters} setFilters={setFilters} />
            {!enabled && <RequestWizardFAB open={open} setOpen={setOpen} />}
            {enabled && (
              <FabContainer>
                <GuideElement id={STEPS.PLUS} body={t('tour.plusSign')} onContinue={exitTour}>
                  <DummyFAB
                    enabled={true}
                    icon={({ color, size }) => <Plus color={color} width={size} height={size} />}
                    label={''}
                    onPress={() => _.noop}
                  />
                </GuideElement>
              </FabContainer>
            )}
          </>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
};

export default Requests;
