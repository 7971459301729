import React from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

const AppointmentCardLoading: React.FC = () => {
  const { colors } = useAppTheme();
  return (
    <StyledActivityIndicatorContainer>
      <ActivityIndicator color={colors.onPrimary} />
    </StyledActivityIndicatorContainer>
  );
};

export default AppointmentCardLoading;

const StyledActivityIndicatorContainer = styled.View`
  width: ${Size.X2_L}px;
  height: ${Size.X2_L}px;
  border-radius: ${Size.X2_L}px;
  background-color: ${({ theme }) => theme.colors.primary};
  align-self: center;
  align-content: center;
  justify-content: center;
  margin: ${Size.X3_L}px;
`;
