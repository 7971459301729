import React from 'react';
import { TouchableOpacity } from 'react-native';
import {
  ContinuitySegmentContainer,
  LineContainer,
  TopLineOne,
  TopLineTwo,
  CircleIndicator,
  BottomLine
} from '../style';

interface Props {
  availableCoins: number;
  rewardPrice: number;
  isStart: boolean;
  isEnd: boolean;
  onPress: () => void;
}

const ContinuitySegment: React.FC<Props> = ({
  availableCoins,
  rewardPrice,
  isStart,
  isEnd,
  onPress
}) => {
  return (
    <ContinuitySegmentContainer>
      <LineContainer>
        <TopLineOne isVisible={!isStart} isPartialFill={availableCoins < rewardPrice} />
        <TopLineTwo isFilled={availableCoins >= rewardPrice} isStart={isStart} />
      </LineContainer>
      <TouchableOpacity onPress={onPress}>
        <CircleIndicator isFilled={availableCoins >= rewardPrice} />
      </TouchableOpacity>
      <LineContainer>
        <BottomLine isVisible={!isEnd} isFilled={availableCoins > rewardPrice} />
      </LineContainer>
    </ContinuitySegmentContainer>
  );
};

export default ContinuitySegment;
