import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from '../IconProps';

const Bird: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 36 36' fill='none'>
      <Path
        d='M27 6C24.435 6 22.875 6.495 21.975 6.915C20.82 6.345 19.5 6 18 6C16.5 6 15.18 6.345 14.025 6.915C13.125 6.495 11.565 6 9 6C4.5 6 1.5 18 1.5 21C1.5 22.245 3.48 23.385 6.21 23.85C7.17 27.21 11.7 29.775 17.25 30V23.58C16.365 23.025 15 22.02 15 21C15 19.5 18 19.5 18 19.5C18 19.5 21 19.5 21 21C21 22.02 19.635 23.025 18.75 23.58V30C24.3 29.775 28.83 27.21 29.79 23.85C32.52 23.385 34.5 22.245 34.5 21C34.5 18 31.5 6 27 6ZM6.225 20.805C5.475 20.625 4.89 20.415 4.5 20.25C4.875 16.095 7.8 9.6 9.075 9C9.885 9 10.5 9.09 11.055 9.165C7.905 12.63 6.66 18.06 6.225 20.805ZM13.5 18C13.1022 18 12.7206 17.842 12.4393 17.5607C12.158 17.2794 12 16.8978 12 16.5C12 15.69 12.675 15 13.5 15C13.8978 15 14.2794 15.158 14.5607 15.4393C14.842 15.7206 15 16.1022 15 16.5C15 17.34 14.325 18 13.5 18ZM22.5 18C22.1022 18 21.7206 17.842 21.4393 17.5607C21.158 17.2794 21 16.8978 21 16.5C21 15.69 21.675 15 22.5 15C22.8978 15 23.2794 15.158 23.5607 15.4393C23.842 15.7206 24 16.1022 24 16.5C24 17.34 23.325 18 22.5 18ZM29.775 20.805C29.34 18.06 28.095 12.63 24.945 9.165C25.5 9.09 26.115 9 26.925 9C28.2 9.6 31.125 16.095 31.5 20.25C31.125 20.415 30.54 20.625 29.775 20.805Z'
        fill={color}
      />
    </Svg>
  );
};

export default Bird;
