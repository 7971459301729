import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { times } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FlatList, LayoutAnimation, RefreshControl } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys, getRequest } from 'src/api';
import { Body, Shimmer, Caption } from 'src/components';
import { Margin, Size } from 'src/constants';
import { usePractice, useViewMode } from 'src/hooks';

import RequestStackParamList from 'src/routes/stacks/RequestStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { fontStyles } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import {
  normalizeRequestData,
  labelShimmerWidth,
  valueShimmerWidth,
  PLACEHOLDER_COUNT
} from './helper';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5';

type Props = NativeStackScreenProps<RequestStackParamList, Screens.REQUEST_DETAILS>;

const RequestDetails: React.FC<Props> = ({ route }) => {
  const { requestId = 0 } = route.params ?? {};
  const { horizontalInset } = useViewMode();
  const { t } = useTranslation('requests');
  const {
    data: { requests = [], attachments = [] } = {},
    isLoading,
    isFetched,
    refetch,
    isError
  } = useQuery({
    queryKey: [QueryKeys.REQUESTS, requestId],
    queryFn: async () => {
      const data = await getRequest(requestId);
      const requests = normalizeRequestData(data);
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return { requests, attachments: data.images };
    },
    suspense: false,
    enabled: !!requestId
  });

  const { data: practice } = usePractice();

  if (!isFetched) {
    return (
      <PlaceholderView>
        {times(PLACEHOLDER_COUNT, (index) => (
          <DetailContainer key={index.toString()}>
            <Shimmer width={labelShimmerWidth(index)} height={fontStyles.caption.lineHeight} />
            <Shimmer width={valueShimmerWidth(index)} height={fontStyles.body.lineHeight} />
          </DetailContainer>
        ))}
      </PlaceholderView>
    );
  }
  return (
    <FlatList
      refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refetch} />}
      data={requests}
      contentContainerStyle={{
        marginHorizontal: horizontalInset,
        gap: Margin.Large,
        padding: Margin.Large
      }}
      renderItem={({ item: { icon, altIcon: AltIcon = FontAwesome5Icon, ...item } }) => (
        <DetailContainer key={item.title}>
          <Caption>{t(item.title)}</Caption>
          <IndentedValue>
            {icon && (
              <Body>
                <AltIcon name={icon} size={fontStyles.body.lineHeight} />
              </Body>
            )}
            <Body>{t(item.value === null ? 'requestFields.removed' : item.value)}</Body>
          </IndentedValue>
        </DetailContainer>
      )}
      ListFooterComponent={() => {
        if (!attachments.length) {
          return null;
        }
        return (
          <>
            <Caption>{t('requestFields.attachments')}</Caption>
            {attachments.map((attachment) => (
              <ImageHolder key={attachment.id}>
                <Image src={attachment.urls.thumbnail} />
              </ImageHolder>
            ))}
          </>
        );
      }}
      ListEmptyComponent={() =>
        isError && (
          <PlaceholderView>
            <CenteredText>{t('requestDetailError', { practiceName: practice?.name })}</CenteredText>
          </PlaceholderView>
        )
      }
    />
  );
};

export default RequestDetails;

const PlaceholderView = styled.View`
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px
  padding: ${Margin.Large}px;
  gap: ${Margin.Large}px;
`;

const DetailContainer = styled.View`
  gap: ${Margin.Large}px;
`;

const IndentedValue = styled.View`
  flex-direction: row;
  margin-left: ${Margin.Large}px;
  gap: ${Margin.Medium}px;
`;

const CenteredText = styled(Body)`
  text-align: center;
  vertical-align: center;
`;

const ImageHolder = styled.View`
  width: 33%;
  padding: ${Size.X2_S}px;
  aspect-ratio: 1;
`;

const Image = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.roundness}px;
`;
