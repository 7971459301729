import i18next, { t } from 'i18next';
import moment, { Moment, tz } from 'moment-timezone';

export const toLocalDateTime = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone
  }).format(m.toDate());

export const toLocalShortDateTime = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    day: 'numeric',
    year: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone
  }).format(m.toDate());

export const timeReference = (m: Moment, today: Moment = moment()) => {
  const date = moment(m);
  if (moment(today).isAfter(date)) {
    return t('wasOn', {
      ns: 'timeReference',
      day: date.format('dddd'),
      time: date.format('h:mm A')
    });
  }
  if (moment(today).isSame(date, 'day')) {
    return t('todayAt', {
      ns: 'timeReference',
      day: date.format('dddd'),
      time: date.format('h:mm A')
    });
  }
  if (moment(today).add(1, 'day').isSame(date, 'day')) {
    return t('tomorrowAt', {
      ns: 'timeReference',
      day: date.format('dddd'),
      time: date.format('h:mm A')
    });
  }
  if (moment(date).diff(today, 'week') < 1) {
    return t('thisWeekAt', {
      ns: 'timeReference',
      day: date.format('dddd'),
      time: date.format('h:mm A')
    });
  }
  if (moment(date).diff(today, 'week') < 2) {
    return t('nextWeekAt', {
      ns: 'timeReference',
      day: date.format('dddd'),
      time: date.format('h:mm A')
    });
  }
  return t('onXDate', {
    ns: 'timeReference',
    day: date.format(t('shortDate')),
    time: date.format('h:mm A')
  });
};

export const toLocalDate = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    timeZone
  }).format(m.toDate());

export const toLocalTime = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone
  }).format(m.toDate());

export const toLocalShortDate = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    day: 'numeric',
    year: 'numeric',
    month: 'numeric',
    timeZone
  }).format(m.toDate());

export const toLocalMonthYear = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    year: 'numeric',
    month: 'long',
    timeZone
  }).format(m.toDate());

export const toLocalMonthDayTime = (m: Moment, timeZone = tz.guess()) =>
  Intl.DateTimeFormat(i18next.language, {
    day: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone
  }).format(m.toDate());

export const isWithin30days = (m: Moment) => {
  const diff = moment(m).diff(moment(), 'days');
  return diff <= 30 && diff > -1;
};
