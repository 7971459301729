const palette = {
  AB_ELECTRIC_BLUE: '#00B4E6',
  ALLY_BLUE_DARK: '#00789A',
  ALLY_BLACK: '#121212',
  ALLY_SURFACE_BLACK: '#1E1E1E',
  WARN_YELLOW: '#E5A300',
  AB_BRIGHT_WHITE: '#FFF',
  EGG_WHITE: '#FFFDFB',
  BLACK: '#000',
  TRANSPARENT: '#FFFFFF00',
  PURPLE: '#9747FF',
  GREY_SHADOW_80: '#D8D8D8CC',

  WHITE_OPACITY_10: '#FFFFFF1A',
  WHITE_OPACITY_20: '#FFFFFF33',
  WHITE_OPACITY_30: '#FFFFFF4D',
  WHITE_OPACITY_38: '#FFFFFF61',
  WHITE_OPACITY_54: '#FFFFFF8A',
  WHITE_OPACITY_70: '#FFFFFFB3',

  BLACK_OPACITY_10: '#0000001A',
  BLACK_OPACITY_20: '#00000033',
  BLACK_OPACITY_26: '#00000042',
  BLACK_OPACITY_30: '#0000004D',
  BLACK_OPACITY_38: '#00000061',
  BLACK_OPACITY_54: '#0000008A',
  BLACK_OPACITY_70: '#000000B3',

  LIGHT_GREY: '#E7E7E7',
  DARK_GREY: '#161616',
  GREEN_ACCENT: '#00CD93',
  GREEN_ACCENT_DARK: '#009A6E',
  GOLD: '#A38A00',
  GOLD_DARK: '#FFE55C',
  SOLID_GREY: '#C4C4C4',
  SURFACE_GREY: '#313131',
  RED_WARNING: '#c22800',
  AB_SOLAR_YELLOW: '#FAEB1E',
  AB_STONE_GRAY: '#464646',
  AB_STONE_GREY_10: '#ECECEC',
  AB_MUSHROOM: '#796E65',
  AB_MUSHROOJM_50: '#BCB7B2'
};

export default palette;
