import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, SectionList, View } from 'react-native';
import { Plus } from 'react-native-feather';
import { ActivityIndicator, Card, Divider } from 'react-native-paper';
import { Alert, AnimatedFAB, Body, HoverButton, Row, Subheading } from 'src/components';
import { ModalDrawerHandle } from 'src/components/ModalDrawer';
import { DESCENDING, IconSize, IS_WEB, Margin, PAGE_LENGTH, Size } from 'src/constants';
import { useBoolean, useMutateTodos, useTodos, useViewMode } from 'src/hooks';
import { UseBooleanHook } from 'src/hooks/useBoolean';
import { Patient, Todo, TodoData, TodoReason } from 'src/interfaces';
import { Screens } from 'src/routes/stacks/screens';
import styled from 'styled-components/native';
import { IconContainer } from 'src/scenes/Appointments/AppointmentDetails/styled';
import { getAllTodos, QueryKeys } from 'src/api';
import { useQueryClient } from 'react-query';
import moment, { Moment } from 'moment-timezone';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import usePaginatedTodos from 'src/hooks/react-query/todos/usePaginatedTodos';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import { GuideElement, SliderElement, SLIDES, STEPS, useTourGuide } from './tour';
import { propSections, Section } from './model';
import TodoItem from './TodoItem';
import TodoItemFallback from './TodoItemFallback';
import Confetti from './Confetti';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import TodoStackParamList from 'src/routes/stacks/ToDoStackNavigator/ParamsList';
import { todoData } from './helpers';
import AccordionSectionHeader from './AccordionHeader';
import FiltersDrawer from './FiltersDrawer';
import { AlertHandle, AlertOption } from 'src/components/Alert';
import { Toast } from 'react-native-toast-message/lib/src/Toast';

const DUEDATE = 'due_date';

interface Props {
  navigation: NativeStackNavigationProp<TodoStackParamList, Screens.TO_DOS, undefined>;
}

const TodoListContent: React.FC<Props> = ({ navigation }) => {
  const { setOptions } = useNavigation();
  const { t } = useTranslation('todos');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();
  const { exitTour, enabled, focusedId, updateScrollPositions } = useTourGuide();

  const drawerRef = useRef<ModalDrawerHandle>(null);
  const queryClient = useQueryClient();

  const [filters, setFilters] = useState<{
    patientId: Patient['patientId'] | undefined;
    reason: TodoReason | undefined;
  }>({ patientId: undefined, reason: undefined });

  const filterCount = useMemo(() => Object.values(filters).filter((v) => !!v).length, [filters]);

  useEffect(() => {
    setOptions({
      title: t('list.title'),
      headerRight: () => (
        <HoverButton
          uppercase={false}
          buttonColor={colors.onPrimary}
          onPress={drawerRef.current?.show}
        >
          {t('filters', { ns: 'common' })}
          {!!filterCount && ` (${filterCount})`}
        </HoverButton>
      )
    });
  }, [setOptions, drawerRef, t, filters, filterCount, colors]);

  const { todos } = useTodos({
    isComplete: false,
    patientId: filters.patientId,
    reason: filters.reason
  });

  const {
    data: completedTodos,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isFetched,
    isRefetching,
    hasNextPage
  } = usePaginatedTodos({
    isComplete: true,
    column: DUEDATE,
    dir: DESCENDING,
    patientId: filters.patientId,
    reason: filters.reason,
    suspense: false
  });

  const controls: { [k in keyof TodoData]: UseBooleanHook } = {
    pastDue: useBoolean(true),
    today: useBoolean(true),
    scheduled: useBoolean(true),
    complete: useBoolean(true)
  };

  const [now, setNow] = useState<Moment>(moment());
  useFocusEffect(
    useCallback(() => {
      setNow(moment());
    }, [])
  );

  const { value: placeholderIsVisible, toTrue: showPlaceholder } = useBoolean(true);

  const sectionData = useMemo(() => {
    const sections: TodoData = {
      pastDue: [],
      today: [],
      scheduled: [],
      complete: []
    };

    todos?.forEach((todo) => {
      if (!todo.dueDate || moment(todo.dueDate).isSame(now, 'day')) {
        sections.today.push(todo);
      } else if (moment(todo.dueDate).isBefore(now)) {
        sections.pastDue.push(todo);
      } else {
        sections.scheduled.push(todo);
      }
    });

    sections.complete = completedTodos;

    return todoData(
      enabled,
      controls.pastDue,
      controls.today,
      controls.scheduled,
      controls.complete,
      sections
    );
  }, [
    completedTodos,
    controls.complete,
    controls.pastDue,
    controls.scheduled,
    controls.today,
    enabled,
    now,
    todos
  ]);

  const noTodos = !todos?.length && !completedTodos.length && !enabled;

  const ref = useRef<SectionList<Todo, Section>>(null);

  const { deleteTodo } = useMutateTodos();

  const { value: isProcessingBatchDelete, toTrue, toFalse } = useBoolean(false);

  const deleteAllCompleted = useCallback(async () => {
    try {
      toTrue();
      const allCompleted = (await getAllTodos(undefined, undefined, true)).data;
      await Promise.all(allCompleted.map(async (todo) => deleteTodo(todo)));
    } catch {
      Toast.show({
        type: 'error',
        text1: t('common:error'),
        text2: t('list.errorDeletingTodos')
      });
    } finally {
      await queryClient.invalidateQueries([QueryKeys.PAGED_TODOS]);
      toFalse();
    }
  }, [deleteTodo, queryClient, t, toFalse, toTrue]);

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        type: 'neutral',
        label: t('cancel')
      },

      {
        action: deleteAllCompleted,
        label: t('list.yesDelete')
      }
    ],
    [deleteAllCompleted, t]
  );

  return (
    <>
      {noTodos && <StyledConfetti />}
      {noTodos && placeholderIsVisible && (
        <Empty>
          <View>
            <StyledPropCard>
              <Subheading color={colors.onSurface}>{t('list.createTo')}:</Subheading>
              {propSections.map(({ description, icon: Icon, color }) => (
                <StyledRow key={description} justify='flex-start'>
                  <IconContainer>
                    <Icon color={colors[color]} size={IconSize.XS} />
                  </IconContainer>
                  <Body color={colors.onSurface}>{t(description)}</Body>
                </StyledRow>
              ))}
            </StyledPropCard>
            {!!filterCount && (
              <>
                <Body textAlign='center'>{t('list.noTodosFound')}</Body>
                <HoverButton
                  onPress={() => setFilters({ patientId: undefined, reason: undefined })}
                >
                  {t('clearFilters', { ns: 'common' })}
                </HoverButton>
              </>
            )}
          </View>
        </Empty>
      )}
      <ScrollRefProvider scrollRef={ref}>
        <SectionList
          ref={ref}
          sections={sectionData}
          contentContainerStyle={{ flexGrow: 1, marginHorizontal: horizontalInset }}
          contentInset={{ bottom: Size.X3_L }}
          onMomentumScrollEnd={updateScrollPositions}
          refreshControl={
            <RefreshControl
              refreshing={isRefetching || isProcessingBatchDelete}
              onRefresh={async () => await queryClient.invalidateQueries([QueryKeys.TODOS])}
            />
          }
          renderSectionHeader={({ section }) => {
            return (
              <>
                <GuideElement id={section.step} body={t(section.guideText)} autoStart={isFetched}>
                  <AccordionSectionHeader
                    length={section.data.length}
                    title={section.title}
                    boolHook={section.expanded}
                    fallback={section.fallback}
                    completed={section.completed}
                  />
                </GuideElement>
                {section.completed && section.expanded.value && !!completedTodos.length && (
                  <HoverButton mode='text' onPress={() => confirmModalRef.current?.alert()}>
                    {t('list.deleteAll')}
                  </HoverButton>
                )}
              </>
            );
          }}
          renderItem={({ item, section }) =>
            section.expanded.value ? (
              <Suspense key={item.id} fallback={<TodoItemFallback />}>
                <TodoItem todo={item} tourEnabled={enabled} />
              </Suspense>
            ) : null
          }
          renderSectionFooter={({ section }) =>
            section.data?.length || section.fallback?.shouldDisplayPlaceholder() ? (
              <StyledDivider expanded={section.expanded.value} />
            ) : null
          }
          initialNumToRender={!IS_WEB ? PAGE_LENGTH + (todos?.length ?? 0) : undefined}
          onEndReachedThreshold={!IS_WEB ? 0 : undefined}
          onEndReached={() => {
            if (hasNextPage && !isFetchingNextPage) {
              void fetchNextPage();
            }
          }}
          stickySectionHeadersEnabled={false}
          ListFooterComponent={
            <>
              {isFetchingNextPage && <LoadingNextIndicator />}
              {!hasNextPage && !isLoading && controls.complete.value && !noTodos && (
                <NoMoreText>{t('common:noMore')}</NoMoreText>
              )}
            </>
          }
        />
      </ScrollRefProvider>
      <FiltersDrawer
        ref={drawerRef}
        {...filters}
        onSelectFilters={(filters) => setFilters(filters)}
      />
      <CreateToDoFabContainer>
        <GuideElement
          id={STEPS.ADD}
          body={t('todos:list.tour.add')}
          alignHorizontally
          onContinue={() => {
            if (noTodos) {
              showPlaceholder();
            }
            exitTour();
          }}
        >
          <CreateTodoFAB
            enabled={focusedId === STEPS.ADD}
            icon={({ color, size }) => <Plus color={color} width={size} height={size} />}
            label={t('list.add')}
            onPress={() => navigation.navigate(Screens.ADD_TO_DO)}
          />
        </GuideElement>
      </CreateToDoFabContainer>
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
      <Alert
        ref={confirmModalRef}
        title={t('common:areYouSure')}
        body={t('list.confirmDeleteDescription')}
        options={confirmOptions}
      />
    </>
  );
};

export default TodoListContent;

const StyledConfetti = styled(Confetti).attrs({
  preserveAspectRatio: 'xMidYMax',
  width: '100%',
  height: '75%'
})`
  position: absolute;
  bottom: 0;
`;

const StyledDivider = styled(Divider)<{ expanded: boolean }>`
  margin-top: ${({ expanded }) => (expanded ? Margin.Large : 0)}px;
`;

const CreateTodoFAB = styled(AnimatedFAB).attrs(({ theme }) => ({
  color: theme.colors.onPrimary,
  iconAnchor: 'right'
}))<{ enabled: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const StyledRow = styled(Row)`
  margin: ${Size.XS}px;
  gap: ${Size.XS}px;
`;

const StyledPropCard = styled(Card)`
  margin: ${Margin.Large}px;
  border-radius: ${Size.X2_S}px;
  padding: ${Margin.Large}px;
`;

const Empty = styled.View`
  justify-content: space-between;
  flex: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
`;

const NoMoreText = styled(Body)`
  margin: ${Size.M}px;
  text-align: center;
`;

const LoadingNextIndicator = styled(ActivityIndicator)`
  margin: ${Size.M}px;
`;

export const CreateToDoFabContainer = styled.View`
  position: absolute;
  right: ${({ theme }) => theme.viewMode.horizontalInset + Number(Margin.Large)}px;
  bottom: ${Margin.Large}px;
`;
