import React, { useEffect, useState, useMemo } from 'react';
import { View } from 'react-native';
import { List, Switch } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { style } from './style';
import { ClientPracticeConfiguration } from 'src/interfaces/api';
import { isDefined } from 'src/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getClientPracticeConfiguration,
  patchClientPracticeConfiguration,
  QueryKeys
} from 'src/api';
import Toast from 'react-native-toast-message';
import _ from 'lodash';
import ClientPracticeConfigurationSection from './ClientPracticeConfigurationSection';
import { CONFIG_STRUCTURE } from './ConfigStructure';
import { StyledSettingsView } from '../ProfileAppSettings/style';
import {
  BottomButtonViewForScrollView,
  Caption,
  StyledKeyboardAwareScrollView,
  StyledResponsiveView,
  StyledSectionHeader,
  Subheading
} from 'src/components';
import { layout } from 'src/theme/globalStyles';
import CPrimaryButton from 'src/components/CPrimaryButton';
import { useUser } from 'src/providers/ClientProvider';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { Margin, PHONE_MASK, SMS_MASK } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { usePractice } from 'src/hooks';
import { mask } from 'react-native-mask-text';

const ProfileClientPracticeConfiguration: React.FC<
  NativeStackScreenProps<HomeStackParamsList, Screens.PROFILE_CLIENT_PRACTICE_CONFIGURATION>
> = ({ navigation }) => {
  const { user } = useUser();
  const [clientPracticeConfiguration, setClientPracticeConfiguration] =
    useState<ClientPracticeConfiguration>();
  const { data: practice } = usePractice();

  const { t } = useTranslation('profileClientPracticeConfig');
  const { colors } = useAppTheme();
  const queryClient = useQueryClient();

  const { data: remoteClientPracticeConfiguration } = useQuery(
    [QueryKeys.CLIENT_PRACTICE_CONFIGURATION, user],
    {
      queryFn: async () => await getClientPracticeConfiguration(user.sourceId, user.clientId)
    }
  );

  const updatePracticeConfig = useMutation(
    async (practiceConfiguration: ClientPracticeConfiguration) =>
      await patchClientPracticeConfiguration(practiceConfiguration),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([QueryKeys.CLIENT_PRACTICE_CONFIGURATION, user], data);
        Toast.show({
          type: 'success',
          text1: t('successToast')
        });
      },
      onError: () => {
        Toast.show({
          type: 'error',
          text1: t('errorToast')
        });
      }
    }
  );

  const onSubmit = (clientPracticeConfiguration: ClientPracticeConfiguration): void => {
    void updatePracticeConfig.mutate(clientPracticeConfiguration);
  };

  useEffect(() => {
    navigation.setOptions({ title: t('title') });
  }, [navigation, t]);

  useEffect(() => {
    if (isDefined(remoteClientPracticeConfiguration)) {
      setClientPracticeConfiguration(remoteClientPracticeConfiguration);
    }
  }, [remoteClientPracticeConfiguration]);

  const disabled = useMemo(
    () => _.isEqual(remoteClientPracticeConfiguration, clientPracticeConfiguration),
    [remoteClientPracticeConfiguration, clientPracticeConfiguration]
  );

  if (!isDefined(clientPracticeConfiguration)) {
    return <></>;
  }

  const onToggle = (field: string, value: boolean): void => {
    setClientPracticeConfiguration((prev) => {
      if (!prev) {
        return prev;
      }
      return { ...prev, [field]: value };
    });
  };

  return (
    <StyledSettingsView>
      <StyledKeyboardAwareScrollView>
        {CONFIG_STRUCTURE.map((section: ClientPracticeConfigurationSection) => (
          <View key={section.subheader} style={{ marginBottom: Margin.Medium }}>
            <StyledSectionHeader>
              <Subheading>{t(section.subheader)}</Subheading>
            </StyledSectionHeader>
            {section.disabledText &&
              practice &&
              section.data.some(
                (item) => item.disabledField && clientPracticeConfiguration[item.disabledField]
              ) && (
                <Caption
                  color={colors.error}
                  style={style.errorText}
                  onPress={() => section.disabledAction?.(practice)}
                >
                  {t(section.disabledText, {
                    smsNumber: mask(practice.smsNumber, [SMS_MASK, PHONE_MASK])
                  })}
                </Caption>
              )}
            {section.data.map((item) => (
              <StyledResponsiveView key={section.subheader + item.title}>
                <List.Item
                  title={t(item.title)}
                  description={t(item.description)}
                  descriptionNumberOfLines={10}
                  style={layout.paddingH0}
                  right={() => (
                    <Switch
                      color={colors.primary}
                      style={style.switch}
                      value={!clientPracticeConfiguration[item.field]}
                      onValueChange={(value) => onToggle(item.field, !value)}
                      disabled={
                        item.disabledField && clientPracticeConfiguration[item.disabledField]
                      }
                    />
                  )}
                />
              </StyledResponsiveView>
            ))}
          </View>
        ))}
      </StyledKeyboardAwareScrollView>
      <BottomButtonViewForScrollView>
        <CPrimaryButton onPress={() => onSubmit(clientPracticeConfiguration)} disabled={disabled}>
          {t('save')}
        </CPrimaryButton>
      </BottomButtonViewForScrollView>
    </StyledSettingsView>
  );
};

export default ProfileClientPracticeConfiguration;
