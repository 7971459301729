import * as React from 'react';
import { Pressable } from 'react-native';
import { Subheading, TextProps } from '../Text';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface ILink extends TextProps {
  onPress: any;
  disabled?: boolean;
}

const CLink: React.FC<React.PropsWithChildren<ILink>> = ({
  children,
  onPress,
  disabled = false,
  ...props
}) => {
  const { colors } = useAppTheme();
  return (
    <Pressable onPress={onPress} disabled={disabled}>
      <Subheading color={colors.primary} {...props}>
        {children}
      </Subheading>
    </Pressable>
  );
};

export default CLink;
