import React, { useCallback } from 'react';
import { IS_WEB, Size } from 'src/constants';
import styled from 'styled-components/native';
import { HoverButton, Row } from 'src/components';
import takeImage from 'src/utils/takeImage';
import pickImage from 'src/utils/pickImage';
import { ImagePickerAsset } from 'expo-image-picker';
import { Camera, Image as ImageIcon } from 'react-native-feather';
import { LayoutAnimation, StyleProp, View, ViewStyle } from 'react-native';
import _ from 'lodash';
import FileItem from './FileItem';
import { useTranslation } from 'react-i18next';
import { ImageUpload } from './model';

interface Props {
  images: Record<string, ImageUpload>;
  onImageChange: React.Dispatch<React.SetStateAction<Record<string, ImageUpload>>>;
  buttonMode?: 'contained' | 'outlined';
  style?: StyleProp<ViewStyle>;
}

const UploadFiles: React.FC<Props> = ({
  onImageChange,
  images,
  buttonMode = 'contained',
  style
}) => {
  const { t } = useTranslation('common');

  const modifyImage = useCallback(
    (key: string, value: Partial<ImageUpload> | undefined) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      onImageChange((prev) => {
        if (value) {
          return { ...prev, [key]: { ...prev[key], ...value } };
        } else {
          return { ..._.omit(prev, key) };
        }
      });
    },
    [onImageChange]
  );

  const selectImages = useCallback(
    async (camera: boolean) => {
      const imagePickerResult = camera ? await takeImage(true) : await pickImage(true);

      if (imagePickerResult && !imagePickerResult?.canceled) {
        imagePickerResult.assets.forEach(async (image) => {
          const key = getFileNameKey(image);
          if (!images[key]) {
            modifyImage(key, { image });
          }
        });
      }
    },
    [images, modifyImage]
  );

  return (
    <PlaceholderView style={style}>
      {!!Object.keys(images).length && (
        <ImagesHolder>
          {Object.entries(images).map(([key, value]) => (
            <View
              key={key}
              style={{
                width: '33%',
                padding: Size.X2_S
              }}
            >
              <FileItem item={value} modifyImage={(v) => modifyImage(key, v)} />
            </View>
          ))}
        </ImagesHolder>
      )}
      <Row>
        <HoverButton
          mode={buttonMode}
          onPress={() => {
            void selectImages(false);
          }}
          icon={ImageIcon}
          style={{ marginRight: Size.XS, flex: 1 }}
        >
          {t('photos', { ns: 'common' })}
        </HoverButton>
        {!IS_WEB && (
          <HoverButton
            mode={buttonMode}
            onPress={() => {
              void selectImages(true);
            }}
            icon={Camera}
            style={{ flex: 1 }}
          >
            {t('camera', { ns: 'common' })}
          </HoverButton>
        )}
      </Row>
    </PlaceholderView>
  );
};

export default UploadFiles;

const PlaceholderView = styled.View`
  padding-vertical: ${Size.M}px;
`;

const getFileNameKey = (image: ImagePickerAsset) =>
  image.uri.substring(image.uri.lastIndexOf('/') + 1, image.uri.length);

const ImagesHolder = styled.View`
  margin-bottom: ${Size.M}px;
  flex-direction: row;
  flex-wrap: wrap;
`;
