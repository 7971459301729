import { PathConfig } from 'src/routes/types';
import { Screens } from '../screens';

export type RequestStackParamList = {
  [Screens.REQUEST]?: {
    selectedPatientId?: string;
  };
  [Screens.REQUEST_DETAILS]?: {
    requestId?: number;
  };
};

export const REQUEST_STACK_PATHS: PathConfig<RequestStackParamList> = {
  initialRouteName: Screens.REQUEST,
  path: 'requests',
  screens: {
    [Screens.REQUEST]: '',
    [Screens.REQUEST_DETAILS]: 'detail'
  }
};

export default RequestStackParamList;
