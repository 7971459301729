import { Body, Caption } from 'src/components';
import { Margin, Size } from 'src/constants';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

export const PET_ID_IMAGE_SIZE = 185;

export const PageHeader = styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-width: 0.5px;
  border-color: ${palette.GREY_SHADOW_80};
  padding-bottom: ${Size.X2_S}px;
`;

export const ImageContainer = styled.View`
  height: ${PET_ID_IMAGE_SIZE}px;
  width: ${PET_ID_IMAGE_SIZE}px;
  align-items: center;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.opaquePlaceholder};
  justify-content: center;
  margin-right: ${Size.X2_S}px;
`;

export const PatientImage = styled.Image`
  width: ${PET_ID_IMAGE_SIZE}px;
  height: ${PET_ID_IMAGE_SIZE}px;
`;

export const FieldValueContainer = styled.View`
  justify-content: space-between;
  flex: 1;
  margin-left: ${Size.X2_S}px;
`;

export const SectionHeader = styled(Caption)`
  margin-bottom: ${Margin.ExtraSmall}px;
`;

export const PracticeLogo = styled.Image`
  height: ${Size.X4_L}px;
  width: ${Size.X5_L}px;
`;

export const SectionOne = styled.View`
  flex-direction: row;
  border-bottom-width: 0.5px;
  border-color: ${palette.GREY_SHADOW_80};
  padding-vertical: ${Size.S}px;
`;

export const SectionTwo = styled.View`
  margin-top: ${Size.X3_S}px;
  border-bottom-width: 0.5px;
  border-color: ${palette.GREY_SHADOW_80};
  padding-vertical: ${Size.X2_S}px;
`;

export const LogoContainer = styled.View`
  flex: 1;
`;

export const Container = styled.View`
  margin-vertical: ${Margin.Small}px;
  padding-bottom: ${Size.X2_S}px;
`;

export const TableRow = styled.View`
  flex-direction: row;
  margin-top: ${Margin.Small}px;
  justify-content: space-between;
`;

export const RowDescription = styled(Body)`
  flex: 1;
  margin-right: ${Size.X3_S}px;
`;
