import { RequestType, type Request } from 'src/interfaces';
import type { DetailItem } from './model';
import generateInfoUpdateData from './generateInfoUpdateData';

import generateAppointmentData from './generateAppointmentData';
import generateRefillData from './generateRefillData';
import generateStandardData from './generateStandardData';

const normalizeRequestData = (data: Request): DetailItem[] => {
  const details: DetailItem[] = [];
  details.push(...generateStandardData(data));
  switch (data.type) {
    case RequestType.Appointment:
      details.push(...generateAppointmentData(data.content));
      break;
    case RequestType.Refill:
      details.push(...generateRefillData(data.content));
      break;
    case RequestType.InfoUpdate:
      details.push(...generateInfoUpdateData(data));
      break;
  }
  return details.filter((detail) => !!detail.value);
};

export default normalizeRequestData;
