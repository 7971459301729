import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import CLink from 'src/components/CLink';
import { ALLY_SUPPORT_EMAIL } from 'src/constants';
import { FontSize } from 'src/theme/typography';
import { style } from './style';
import { layout } from 'src/theme/globalStyles';
import React from 'react';
import { Caption } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import openEmailToAlly from 'src/utils/openEmailToAlly';

const CContactAlly: React.FC = () => {
  const { t } = useTranslation('contactAlly');
  const { colors } = useAppTheme();

  return (
    <View style={layout.flexRow}>
      <Caption style={[style.pleaseContactText, { color: colors.error }]}>
        {t('pleaseContact')}
      </Caption>
      <CLink onPress={openEmailToAlly} fontSize={FontSize.BODY}>
        {ALLY_SUPPORT_EMAIL}
      </CLink>
      <Caption style={[style.verifyText, { color: colors.error }]}>{t('toVerify')}</Caption>
    </View>
  );
};

export default CContactAlly;
