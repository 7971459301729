import { AxiosError } from 'axios';
import { Platform } from 'react-native';
import { endpoint } from 'src/constants/api';
import environment from 'src/environment';
import { Client } from 'src/interfaces/api';
import Practice from 'src/interfaces/api/Practice';
import NotificationCategory from 'src/providers/NotificationProvider/NotificationCategory';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import { getToken } from 'src/utils/firebase';
import generateGenericHeaders from 'src/utils/generateGenericHeaders';
import { NotificationEntry, NotificationStatus } from 'src/utils/remoteNotifications/types';
import sentry from 'src/utils/sentry';

interface PostRegisterRS {
  message: string;
  data: PushNotificationTokenEntry;
}

interface PushNotificationTokenEntry {
  clientId: Client['clientId'];
  token: string;
  platform: Platform['OS'];
  createdAt: string;
  practiceId: Client['practiceId'];
}

interface PostRegisterRQ {
  deviceToken: string;
  platform: Platform['OS'];
  authToken: string;
  practiceId: Client['practiceId'];
  sourceId: Practice['sourceId'];
}

export const postRegister = async ({
  deviceToken,
  platform,
  authToken,
  practiceId,
  sourceId
}: PostRegisterRQ): Promise<PostRegisterRS> => {
  const response = await apiClient.post<PostRegisterRS>(
    endpoint('PUSH_REGISTER'),
    { platform, deviceToken, practiceId, sourceId },
    {
      baseURL: environment.PUSH_URL,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return isDefinedOrThrowServerError(response.data);
};

export interface PostStatusRQ {
  id: string;
  status: NotificationStatus;
  sourceId: Practice['sourceId'];
  practiceId: Client['practiceId'];
}

export interface PostStatusRS {
  data: NotificationEntry;
}

/**
 * Posts the status of a notification to the server. Uses fetch instead of axios to due to lack of react context.
 * @param args
 * @param config
 * @returns
 */
export const postStatus = async (args: PostStatusRQ) => {
  const headers = await generateGenericHeaders();
  const response = await fetch(`${environment.PUSH_URL}${endpoint('PUSH_STATUS')}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(args)
  });
  const data = await response.json();
  return data as PostStatusRS;
};

interface PostPushRQ {
  type: NotificationCategory;
  sourceId: Practice['sourceId'];
  practiceId: Client['practiceId'];
}
interface PostPushRS {
  notification?: {
    title: string;
    body: string;
    image?: string;
    icon?: string;
  };
  data?: Record<string, string>;
  sourceId: Practice['sourceId'];
  practiceId: Client['practiceId'];
  pushNotificationId: string;
  audience: [number] | [];
  unavailableAudience: [number] | [];
}

export const postSelfPush = async ({ type, practiceId, sourceId }: PostPushRQ) => {
  let sourceToken: string | undefined;
  let response;
  try {
    sourceToken = await getToken();
  } finally {
    response = apiClient.post<PostPushRS>(
      endpoint('PUSH'),
      {
        practiceId,
        sourceId,
        data: {
          type
        }
      },
      { baseURL: environment.PUSH_URL, headers: { 'source-token': sourceToken } }
    );
  }
  let data;
  try {
    data = (await response)?.data;
  } catch (e) {
    const error = e as AxiosError<{ message: string }>;
    if (error.response?.data?.message.includes('no audience')) {
      sentry.addBreadcrumb({
        message: 'User has no additional devices to send a push notification to',
        data: { type, practiceId, sourceId },
        category: 'info',
        level: 'info'
      });
    } else {
      throw error;
    }
  }
  return data;
};
