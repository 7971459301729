import React, { PropsWithChildren, useEffect } from 'react';
import { Pressable, View } from 'react-native';
import { Menu } from 'react-native-paper';
import { Margin } from 'src/constants';
import { useBoolean } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { fontStyles } from 'src/theme/globalStyles';
import sentry, { interpolateName } from 'src/utils/sentry';
import styled from 'styled-components/native';

interface Props extends PropsWithChildren {
  tooltipText: React.ReactNode;
  disabled?: boolean;
}

const Tooltip: React.FC<Props> = ({ tooltipText, children, disabled }) => {
  const { toTrue: openTooltip, toFalse: closeTooltip, value: isTooltipOpen } = useBoolean();
  const { colors } = useAppTheme();

  const sentryLabel = interpolateName('Tooltip', tooltipText);

  useEffect(() => {
    sentry.addBreadcrumb({
      type: 'Interaction',
      message: `${sentryLabel} was ${isTooltipOpen ? 'opened' : 'closed'}`
    });
  }, [isTooltipOpen, sentryLabel]);

  return (
    <Menu
      sentry-label={sentryLabel}
      anchor={
        <View
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Pressable onPress={openTooltip} disabled={disabled}>
            {children}
          </Pressable>
        </View>
      }
      onDismiss={closeTooltip}
      visible={isTooltipOpen && !disabled}
      contentStyle={{ backgroundColor: colors.onSurface }}
    >
      <StyledItem title={tooltipText} titleStyle={[{ color: colors.surface }]} />
    </Menu>
  );
};

export default Tooltip;

const StyledItem = styled(Menu.Item).attrs((props) => ({
  titleStyle: [{ ...fontStyles.caption, alignSelf: 'center' }, props.titleStyle]
}))`
  padding: ${Margin.Small}px;
  height: auto;
`;
