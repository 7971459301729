import React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

interface Props {
  size: number;
}

const QRAppStore: React.FC<Props> = ({ size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 128 128' fill='none'>
      <G clipPath='url(#clip0_61_680)'>
        <Path d='M128 0H0V128H128V0Z' fill='white' />
        <Path d='M11.3939 8H8V11.3939H11.3939V8Z' fill='black' />
        <Path d='M14.7879 8H11.3939V11.3939H14.7879V8Z' fill='black' />
        <Path d='M18.1818 8H14.7879V11.3939H18.1818V8Z' fill='black' />
        <Path d='M21.5758 8H18.1818V11.3939H21.5758V8Z' fill='black' />
        <Path d='M24.9697 8H21.5758V11.3939H24.9697V8Z' fill='black' />
        <Path d='M28.3636 8H24.9697V11.3939H28.3636V8Z' fill='black' />
        <Path d='M31.7576 8H28.3636V11.3939H31.7576V8Z' fill='black' />
        <Path d='M38.5455 8H35.1515V11.3939H38.5455V8Z' fill='black' />
        <Path d='M41.9394 8H38.5455V11.3939H41.9394V8Z' fill='black' />
        <Path d='M45.3333 8H41.9394V11.3939H45.3333V8Z' fill='black' />
        <Path d='M52.1212 8H48.7273V11.3939H52.1212V8Z' fill='black' />
        <Path d='M55.5152 8H52.1212V11.3939H55.5152V8Z' fill='black' />
        <Path d='M58.9091 8H55.5152V11.3939H58.9091V8Z' fill='black' />
        <Path d='M62.303 8H58.9091V11.3939H62.303V8Z' fill='black' />
        <Path d='M65.697 8H62.303V11.3939H65.697V8Z' fill='black' />
        <Path d='M75.8788 8H72.4849V11.3939H75.8788V8Z' fill='black' />
        <Path d='M79.2727 8H75.8788V11.3939H79.2727V8Z' fill='black' />
        <Path d='M82.6667 8H79.2727V11.3939H82.6667V8Z' fill='black' />
        <Path d='M86.0606 8H82.6667V11.3939H86.0606V8Z' fill='black' />
        <Path d='M89.4546 8H86.0606V11.3939H89.4546V8Z' fill='black' />
        <Path d='M92.8485 8H89.4546V11.3939H92.8485V8Z' fill='black' />
        <Path d='M99.6364 8H96.2424V11.3939H99.6364V8Z' fill='black' />
        <Path d='M103.03 8H99.6364V11.3939H103.03V8Z' fill='black' />
        <Path d='M106.424 8H103.03V11.3939H106.424V8Z' fill='black' />
        <Path d='M109.818 8H106.424V11.3939H109.818V8Z' fill='black' />
        <Path d='M113.212 8H109.818V11.3939H113.212V8Z' fill='black' />
        <Path d='M116.606 8H113.212V11.3939H116.606V8Z' fill='black' />
        <Path d='M120 8H116.606V11.3939H120V8Z' fill='black' />
        <Path d='M11.3939 11.3939H8V14.7879H11.3939V11.3939Z' fill='black' />
        <Path d='M31.7576 11.3939H28.3636V14.7879H31.7576V11.3939Z' fill='black' />
        <Path d='M62.303 11.3939H58.9091V14.7879H62.303V11.3939Z' fill='black' />
        <Path d='M65.697 11.3939H62.303V14.7879H65.697V11.3939Z' fill='black' />
        <Path d='M72.4849 11.3939H69.0909V14.7879H72.4849L72.4849 11.3939Z' fill='black' />
        <Path d='M99.6364 11.3939H96.2424V14.7879H99.6364V11.3939Z' fill='black' />
        <Path d='M120 11.3939H116.606V14.7879H120V11.3939Z' fill='black' />
        <Path d='M11.3939 14.7879H8V18.1818H11.3939V14.7879Z' fill='black' />
        <Path d='M18.1818 14.7879H14.7879V18.1818H18.1818V14.7879Z' fill='black' />
        <Path d='M21.5758 14.7879H18.1818V18.1818H21.5758V14.7879Z' fill='black' />
        <Path d='M24.9697 14.7879H21.5758V18.1818H24.9697V14.7879Z' fill='black' />
        <Path d='M31.7576 14.7879H28.3636V18.1818H31.7576V14.7879Z' fill='black' />
        <Path d='M38.5455 14.7879H35.1515V18.1818H38.5455V14.7879Z' fill='black' />
        <Path d='M45.3333 14.7879H41.9394V18.1818H45.3333V14.7879Z' fill='black' />
        <Path d='M48.7273 14.7879H45.3333V18.1818H48.7273V14.7879Z' fill='black' />
        <Path d='M52.1212 14.7879H48.7273V18.1818H52.1212V14.7879Z' fill='black' />
        <Path d='M58.9091 14.7879H55.5152V18.1818H58.9091V14.7879Z' fill='black' />
        <Path d='M62.303 14.7879H58.9091V18.1818H62.303V14.7879Z' fill='black' />
        <Path d='M65.697 14.7879H62.303V18.1818H65.697V14.7879Z' fill='black' />
        <Path d='M69.0909 14.7879H65.697V18.1818H69.0909V14.7879Z' fill='black' />
        <Path d='M79.2727 14.7879H75.8788V18.1818H79.2727V14.7879Z' fill='black' />
        <Path d='M86.0606 14.7879H82.6667V18.1818H86.0606V14.7879Z' fill='black' />
        <Path d='M99.6364 14.7879H96.2424V18.1818H99.6364V14.7879Z' fill='black' />
        <Path d='M106.424 14.7879H103.03V18.1818H106.424V14.7879Z' fill='black' />
        <Path d='M109.818 14.7879H106.424V18.1818H109.818V14.7879Z' fill='black' />
        <Path d='M113.212 14.7879H109.818V18.1818H113.212V14.7879Z' fill='black' />
        <Path d='M120 14.7879H116.606V18.1818H120V14.7879Z' fill='black' />
        <Path d='M11.3939 18.1818H8V21.5758H11.3939V18.1818Z' fill='black' />
        <Path d='M18.1818 18.1818H14.7879V21.5758H18.1818V18.1818Z' fill='black' />
        <Path d='M21.5758 18.1818H18.1818V21.5758H21.5758V18.1818Z' fill='black' />
        <Path d='M24.9697 18.1818H21.5758V21.5758H24.9697V18.1818Z' fill='black' />
        <Path d='M31.7576 18.1818H28.3636V21.5758H31.7576V18.1818Z' fill='black' />
        <Path d='M38.5455 18.1818H35.1515V21.5758H38.5455V18.1818Z' fill='black' />
        <Path d='M45.3333 18.1818H41.9394V21.5758H45.3333V18.1818Z' fill='black' />
        <Path d='M48.7273 18.1818H45.3333V21.5758H48.7273V18.1818Z' fill='black' />
        <Path d='M55.5152 18.1818H52.1212V21.5758H55.5152V18.1818Z' fill='black' />
        <Path d='M58.9091 18.1818H55.5152V21.5758H58.9091V18.1818Z' fill='black' />
        <Path d='M75.8788 18.1818H72.4849V21.5758H75.8788V18.1818Z' fill='black' />
        <Path d='M79.2727 18.1818H75.8788V21.5758H79.2727V18.1818Z' fill='black' />
        <Path d='M92.8485 18.1818H89.4546V21.5758H92.8485V18.1818Z' fill='black' />
        <Path d='M99.6364 18.1818H96.2424V21.5758H99.6364V18.1818Z' fill='black' />
        <Path d='M106.424 18.1818H103.03V21.5758H106.424V18.1818Z' fill='black' />
        <Path d='M109.818 18.1818H106.424V21.5758H109.818V18.1818Z' fill='black' />
        <Path d='M113.212 18.1818H109.818V21.5758H113.212V18.1818Z' fill='black' />
        <Path d='M120 18.1818H116.606V21.5758H120V18.1818Z' fill='black' />
        <Path d='M11.3939 21.5758H8V24.9697H11.3939V21.5758Z' fill='black' />
        <Path d='M18.1818 21.5758H14.7879V24.9697H18.1818V21.5758Z' fill='black' />
        <Path d='M21.5758 21.5758H18.1818V24.9697H21.5758V21.5758Z' fill='black' />
        <Path d='M24.9697 21.5758H21.5758V24.9697H24.9697V21.5758Z' fill='black' />
        <Path d='M31.7576 21.5758H28.3636V24.9697H31.7576V21.5758Z' fill='black' />
        <Path d='M38.5455 21.5758H35.1515V24.9697H38.5455V21.5758Z' fill='black' />
        <Path d='M48.7273 21.5758H45.3333V24.9697H48.7273V21.5758Z' fill='black' />
        <Path d='M52.1212 21.5758H48.7273V24.9697H52.1212V21.5758Z' fill='black' />
        <Path d='M55.5152 21.5758H52.1212V24.9697H55.5152V21.5758Z' fill='black' />
        <Path d='M65.697 21.5758H62.303V24.9697H65.697V21.5758Z' fill='black' />
        <Path d='M72.4849 21.5758H69.0909V24.9697H72.4849L72.4849 21.5758Z' fill='black' />
        <Path d='M89.4546 21.5758H86.0606V24.9697H89.4546V21.5758Z' fill='black' />
        <Path d='M99.6364 21.5758H96.2424V24.9697H99.6364V21.5758Z' fill='black' />
        <Path d='M106.424 21.5758H103.03V24.9697H106.424V21.5758Z' fill='black' />
        <Path d='M109.818 21.5758H106.424V24.9697H109.818V21.5758Z' fill='black' />
        <Path d='M113.212 21.5758H109.818V24.9697H113.212V21.5758Z' fill='black' />
        <Path d='M120 21.5758H116.606V24.9697H120V21.5758Z' fill='black' />
        <Path d='M11.3939 24.9697H8V28.3636H11.3939V24.9697Z' fill='black' />
        <Path d='M31.7576 24.9697H28.3636V28.3636H31.7576V24.9697Z' fill='black' />
        <Path d='M38.5455 24.9697H35.1515V28.3636H38.5455V24.9697Z' fill='black' />
        <Path d='M62.303 24.9697H58.9091V28.3636H62.303V24.9697Z' fill='black' />
        <Path d='M69.0909 24.9697H65.697V28.3636H69.0909V24.9697Z' fill='black' />
        <Path d='M79.2727 24.9697H75.8788V28.3636H79.2727V24.9697Z' fill='black' />
        <Path d='M82.6667 24.9697H79.2727V28.3636H82.6667V24.9697Z' fill='black' />
        <Path d='M92.8485 24.9697H89.4546V28.3636H92.8485V24.9697Z' fill='black' />
        <Path d='M99.6364 24.9697H96.2424V28.3636H99.6364V24.9697Z' fill='black' />
        <Path d='M120 24.9697H116.606V28.3636H120V24.9697Z' fill='black' />
        <Path d='M11.3939 28.3636H8V31.7576H11.3939V28.3636Z' fill='black' />
        <Path d='M14.7879 28.3636H11.3939V31.7576H14.7879V28.3636Z' fill='black' />
        <Path d='M18.1818 28.3636H14.7879V31.7576H18.1818V28.3636Z' fill='black' />
        <Path d='M21.5758 28.3636H18.1818V31.7576H21.5758V28.3636Z' fill='black' />
        <Path d='M24.9697 28.3636H21.5758V31.7576H24.9697V28.3636Z' fill='black' />
        <Path d='M28.3636 28.3636H24.9697V31.7576H28.3636V28.3636Z' fill='black' />
        <Path d='M31.7576 28.3636H28.3636V31.7576H31.7576V28.3636Z' fill='black' />
        <Path d='M38.5455 28.3636H35.1515V31.7576H38.5455V28.3636Z' fill='black' />
        <Path d='M45.3333 28.3636H41.9394V31.7576H45.3333V28.3636Z' fill='black' />
        <Path d='M52.1212 28.3636H48.7273V31.7576H52.1212V28.3636Z' fill='black' />
        <Path d='M58.9091 28.3636H55.5152V31.7576H58.9091V28.3636Z' fill='black' />
        <Path d='M65.697 28.3636H62.303V31.7576H65.697V28.3636Z' fill='black' />
        <Path d='M72.4849 28.3636H69.0909V31.7576H72.4849V28.3636Z' fill='black' />
        <Path d='M79.2727 28.3636H75.8788V31.7576H79.2727V28.3636Z' fill='black' />
        <Path d='M86.0606 28.3636H82.6667V31.7576H86.0606V28.3636Z' fill='black' />
        <Path d='M92.8485 28.3636H89.4546V31.7576H92.8485V28.3636Z' fill='black' />
        <Path d='M99.6364 28.3636H96.2424V31.7576H99.6364V28.3636Z' fill='black' />
        <Path d='M103.03 28.3636H99.6364V31.7576H103.03V28.3636Z' fill='black' />
        <Path d='M106.424 28.3636H103.03V31.7576H106.424V28.3636Z' fill='black' />
        <Path d='M109.818 28.3636H106.424V31.7576H109.818V28.3636Z' fill='black' />
        <Path d='M113.212 28.3636H109.818V31.7576H113.212V28.3636Z' fill='black' />
        <Path d='M116.606 28.3636H113.212V31.7576H116.606V28.3636Z' fill='black' />
        <Path d='M120 28.3636H116.606V31.7576H120V28.3636Z' fill='black' />
        <Path d='M41.9394 31.7576H38.5455V35.1515H41.9394V31.7576Z' fill='black' />
        <Path d='M52.1212 31.7576H48.7273V35.1515H52.1212V31.7576Z' fill='black' />
        <Path d='M58.9091 31.7576H55.5152V35.1515H58.9091V31.7576Z' fill='black' />
        <Path d='M72.4849 31.7576H69.0909V35.1515H72.4849V31.7576Z' fill='black' />
        <Path d='M82.6667 31.7576H79.2727V35.1515H82.6667V31.7576Z' fill='black' />
        <Path d='M89.4546 31.7576H86.0606V35.1515H89.4546V31.7576Z' fill='black' />
        <Path d='M18.1818 35.1515H14.7879V38.5455H18.1818V35.1515Z' fill='black' />
        <Path d='M21.5758 35.1515H18.1818V38.5455H21.5758V35.1515Z' fill='black' />
        <Path d='M24.9697 35.1515H21.5758V38.5455H24.9697V35.1515Z' fill='black' />
        <Path d='M28.3636 35.1515H24.9697V38.5455H28.3636V35.1515Z' fill='black' />
        <Path d='M31.7576 35.1515H28.3636V38.5455H31.7576V35.1515Z' fill='black' />
        <Path d='M35.1515 35.1515H31.7576V38.5455H35.1515V35.1515Z' fill='black' />
        <Path d='M41.9394 35.1515H38.5455V38.5455H41.9394V35.1515Z' fill='black' />
        <Path d='M52.1212 35.1515H48.7273V38.5455H52.1212V35.1515Z' fill='black' />
        <Path d='M58.9091 35.1515H55.5152V38.5455H58.9091V35.1515Z' fill='black' />
        <Path d='M65.697 35.1515H62.303V38.5455H65.697V35.1515Z' fill='black' />
        <Path d='M79.2727 35.1515H75.8788V38.5455H79.2727V35.1515Z' fill='black' />
        <Path d='M86.0606 35.1515H82.6667V38.5455H86.0606V35.1515Z' fill='black' />
        <Path d='M89.4546 35.1515H86.0606V38.5455H89.4546V35.1515Z' fill='black' />
        <Path d='M92.8485 35.1515H89.4546V38.5455H92.8485V35.1515Z' fill='black' />
        <Path d='M96.2424 35.1515H92.8485V38.5455H96.2424V35.1515Z' fill='black' />
        <Path d='M103.03 35.1515H99.6364V38.5455H103.03V35.1515Z' fill='black' />
        <Path d='M106.424 35.1515H103.03V38.5455H106.424V35.1515Z' fill='black' />
        <Path d='M109.818 35.1515H106.424V38.5455H109.818V35.1515Z' fill='black' />
        <Path d='M113.212 35.1515H109.818V38.5455H113.212V35.1515Z' fill='black' />
        <Path d='M120 35.1515H116.606V38.5455H120V35.1515Z' fill='black' />
        <Path d='M11.3939 38.5455H8V41.9394H11.3939V38.5455Z' fill='black' />
        <Path d='M14.7879 38.5455H11.3939V41.9394H14.7879V38.5455Z' fill='black' />
        <Path d='M18.1818 38.5455H14.7879V41.9394H18.1818V38.5455Z' fill='black' />
        <Path d='M24.9697 38.5455H21.5758V41.9394H24.9697V38.5455Z' fill='black' />
        <Path d='M28.3636 38.5455H24.9697V41.9394H28.3636V38.5455Z' fill='black' />
        <Path d='M38.5455 38.5455H35.1515V41.9394H38.5455V38.5455Z' fill='black' />
        <Path d='M41.9394 38.5455H38.5455V41.9394H41.9394V38.5455Z' fill='black' />
        <Path d='M45.3333 38.5455H41.9394V41.9394H45.3333V38.5455Z' fill='black' />
        <Path d='M52.1212 38.5455H48.7273V41.9394H52.1212V38.5455Z' fill='black' />
        <Path d='M55.5152 38.5455H52.1212V41.9394H55.5152V38.5455Z' fill='black' />
        <Path d='M58.9091 38.5455H55.5152V41.9394H58.9091V38.5455Z' fill='black' />
        <Path d='M79.2727 38.5455H75.8788V41.9394H79.2727V38.5455Z' fill='black' />
        <Path d='M82.6667 38.5455H79.2727V41.9394H82.6667V38.5455Z' fill='black' />
        <Path d='M86.0606 38.5455H82.6667V41.9394H86.0606V38.5455Z' fill='black' />
        <Path d='M99.6364 38.5455H96.2424V41.9394H99.6364V38.5455Z' fill='black' />
        <Path d='M106.424 38.5455H103.03V41.9394H106.424V38.5455Z' fill='black' />
        <Path d='M109.818 38.5455H106.424V41.9394H109.818V38.5455Z' fill='black' />
        <Path d='M116.606 38.5455H113.212V41.9394H116.606V38.5455Z' fill='black' />
        <Path d='M21.5758 41.9394H18.1818V45.3333H21.5758V41.9394Z' fill='black' />
        <Path d='M28.3636 41.9394H24.9697V45.3333H28.3636V41.9394Z' fill='black' />
        <Path d='M31.7576 41.9394H28.3636V45.3333H31.7576V41.9394Z' fill='black' />
        <Path d='M38.5455 41.9394H35.1515V45.3333H38.5455V41.9394Z' fill='black' />
        <Path d='M45.3333 41.9394H41.9394V45.3333H45.3333V41.9394Z' fill='black' />
        <Path d='M55.5152 41.9394H52.1212V45.3333H55.5152V41.9394Z' fill='black' />
        <Path d='M58.9091 41.9394H55.5152V45.3333H58.9091V41.9394Z' fill='black' />
        <Path d='M65.697 41.9394H62.303V45.3333H65.697V41.9394Z' fill='black' />
        <Path d='M75.8788 41.9394H72.4849V45.3333H75.8788V41.9394Z' fill='black' />
        <Path d='M82.6667 41.9394H79.2727V45.3333H82.6667V41.9394Z' fill='black' />
        <Path d='M99.6364 41.9394H96.2424V45.3333H99.6364V41.9394Z' fill='black' />
        <Path d='M103.03 41.9394H99.6364V45.3333H103.03V41.9394Z' fill='black' />
        <Path d='M106.424 41.9394H103.03V45.3333H106.424V41.9394Z' fill='black' />
        <Path d='M109.818 41.9394H106.424V45.3333H109.818V41.9394Z' fill='black' />
        <Path d='M113.212 41.9394H109.818V45.3333H113.212V41.9394Z' fill='black' />
        <Path d='M24.9697 45.3333H21.5758V48.7273H24.9697V45.3333Z' fill='black' />
        <Path d='M28.3636 45.3333H24.9697V48.7273H28.3636V45.3333Z' fill='black' />
        <Path d='M86.0606 45.3333H82.6667V48.7273H86.0606V45.3333Z' fill='black' />
        <Path d='M89.4546 45.3333H86.0606V48.7273H89.4546V45.3333Z' fill='black' />
        <Path d='M103.03 45.3333H99.6364V48.7273H103.03V45.3333Z' fill='black' />
        <Path d='M109.818 45.3333H106.424V48.7273H109.818V45.3333Z' fill='black' />
        <Path d='M113.212 45.3333H109.818V48.7273H113.212V45.3333Z' fill='black' />
        <Path d='M116.606 45.3333H113.212V48.7273H116.606V45.3333Z' fill='black' />
        <Path d='M120 45.3333H116.606V48.7273H120V45.3333Z' fill='black' />
        <Path d='M11.3939 48.7273H8V52.1212H11.3939V48.7273Z' fill='black' />
        <Path d='M18.1818 48.7273H14.7879V52.1212H18.1818V48.7273Z' fill='black' />
        <Path d='M21.5758 48.7273H18.1818V52.1212H21.5758V48.7273Z' fill='black' />
        <Path d='M24.9697 48.7273H21.5758V52.1212H24.9697V48.7273Z' fill='black' />
        <Path d='M28.3636 48.7273H24.9697V52.1212H28.3636V48.7273Z' fill='black' />
        <Path d='M31.7576 48.7273H28.3636V52.1212H31.7576V48.7273Z' fill='black' />
        <Path d='M38.5455 48.7273H35.1515V52.1212H38.5455V48.7273Z' fill='black' />
        <Path d='M52.1212 48.7273H48.7273V52.1212H52.1212V48.7273Z' fill='black' />
        <Path d='M58.9091 48.7273H55.5152V52.1212H58.9091V48.7273Z' fill='black' />
        <Path d='M62.303 48.7273H58.9091V52.1212H62.303V48.7273Z' fill='black' />
        <Path d='M69.0909 48.7273H65.697V52.1212H69.0909V48.7273Z' fill='black' />
        <Path d='M75.8788 48.7273H72.4849V52.1212H75.8788V48.7273Z' fill='black' />
        <Path d='M79.2727 48.7273H75.8788V52.1212H79.2727V48.7273Z' fill='black' />
        <Path d='M82.6667 48.7273H79.2727V52.1212H82.6667V48.7273Z' fill='black' />
        <Path d='M86.0606 48.7273H82.6667V52.1212H86.0606V48.7273Z' fill='black' />
        <Path d='M96.2424 48.7273H92.8485V52.1212H96.2424V48.7273Z' fill='black' />
        <Path d='M99.6364 48.7273H96.2424V52.1212H99.6364V48.7273Z' fill='black' />
        <Path d='M103.03 48.7273H99.6364V52.1212H103.03V48.7273Z' fill='black' />
        <Path d='M113.212 48.7273H109.818V52.1212H113.212V48.7273Z' fill='black' />
        <Path d='M116.606 48.7273H113.212V52.1212H116.606V48.7273Z' fill='black' />
        <Path d='M120 48.7273H116.606V52.1212H120V48.7273Z' fill='black' />
        <Path d='M11.3939 52.1212H8V55.5152H11.3939V52.1212Z' fill='black' />
        <Path d='M14.7879 52.1212H11.3939V55.5152H14.7879V52.1212Z' fill='black' />
        <Path d='M18.1818 52.1212H14.7879V55.5152H18.1818V52.1212Z' fill='black' />
        <Path d='M24.9697 52.1212H21.5758V55.5152H24.9697V52.1212Z' fill='black' />
        <Path d='M28.3636 52.1212H24.9697V55.5152H28.3636V52.1212Z' fill='black' />
        <Path d='M38.5455 52.1212H35.1515V55.5152H38.5455V52.1212Z' fill='black' />
        <Path d='M55.5152 52.1212H52.1212V55.5152H55.5152V52.1212Z' fill='black' />
        <Path d='M62.303 52.1212H58.9091V55.5152H62.303V52.1212Z' fill='black' />
        <Path d='M65.697 52.1212H62.303V55.5152H65.697V52.1212Z' fill='black' />
        <Path d='M69.0909 52.1212H65.697V55.5152H69.0909V52.1212Z' fill='black' />
        <Path d='M79.2727 52.1212H75.8788V55.5152H79.2727V52.1212Z' fill='black' />
        <Path d='M82.6667 52.1212H79.2727V55.5152H82.6667V52.1212Z' fill='black' />
        <Path d='M96.2424 52.1212H92.8485V55.5152H96.2424V52.1212Z' fill='black' />
        <Path d='M103.03 52.1212H99.6364V55.5152H103.03V52.1212Z' fill='black' />
        <Path d='M106.424 52.1212H103.03V55.5152H106.424V52.1212Z' fill='black' />
        <Path d='M113.212 52.1212H109.818V55.5152H113.212V52.1212Z' fill='black' />
        <Path d='M116.606 52.1212H113.212V55.5152H116.606V52.1212Z' fill='black' />
        <Path d='M120 52.1212H116.606V55.5152H120V52.1212Z' fill='black' />
        <Path d='M14.7879 55.5152H11.3939V58.9091H14.7879V55.5152Z' fill='black' />
        <Path d='M21.5758 55.5152H18.1818V58.9091H21.5758V55.5152Z' fill='black' />
        <Path d='M28.3636 55.5152H24.9697V58.9091H28.3636V55.5152Z' fill='black' />
        <Path d='M31.7576 55.5152H28.3636V58.9091H31.7576V55.5152Z' fill='black' />
        <Path d='M35.1515 55.5152H31.7576V58.9091H35.1515V55.5152Z' fill='black' />
        <Path d='M38.5455 55.5152H35.1515V58.9091H38.5455V55.5152Z' fill='black' />
        <Path d='M41.9394 55.5152H38.5455V58.9091H41.9394V55.5152Z' fill='black' />
        <Path d='M48.7273 55.5152H45.3333V58.9091H48.7273V55.5152Z' fill='black' />
        <Path d='M55.5152 55.5152H52.1212V58.9091H55.5152V55.5152Z' fill='black' />
        <Path d='M58.9091 55.5152H55.5152V58.9091H58.9091V55.5152Z' fill='black' />
        <Path d='M65.697 55.5152H62.303V58.9091H65.697V55.5152Z' fill='black' />
        <Path d='M82.6667 55.5152H79.2727V58.9091H82.6667V55.5152Z' fill='black' />
        <Path d='M89.4546 55.5152H86.0606V58.9091H89.4546V55.5152Z' fill='black' />
        <Path d='M99.6364 55.5152H96.2424V58.9091H99.6364V55.5152Z' fill='black' />
        <Path d='M103.03 55.5152H99.6364V58.9091H103.03V55.5152Z' fill='black' />
        <Path d='M109.818 55.5152H106.424V58.9091H109.818V55.5152Z' fill='black' />
        <Path d='M116.606 55.5152H113.212V58.9091H116.606V55.5152Z' fill='black' />
        <Path d='M14.7879 58.9091H11.3939V62.303H14.7879V58.9091Z' fill='black' />
        <Path d='M18.1818 58.9091H14.7879V62.303H18.1818V58.9091Z' fill='black' />
        <Path d='M21.5758 58.9091H18.1818V62.303H21.5758V58.9091Z' fill='black' />
        <Path d='M24.9697 58.9091H21.5758V62.303H24.9697V58.9091Z' fill='black' />
        <Path d='M28.3636 58.9091H24.9697V62.303H28.3636V58.9091Z' fill='black' />
        <Path d='M35.1515 58.9091H31.7576V62.303H35.1515V58.9091Z' fill='black' />
        <Path d='M38.5455 58.9091H35.1515V62.303H38.5455V58.9091Z' fill='black' />
        <Path d='M45.3333 58.9091H41.9394V62.303H45.3333V58.9091Z' fill='black' />
        <Path d='M48.7273 58.9091H45.3333V62.303H48.7273V58.9091Z' fill='black' />
        <Path d='M52.1212 58.9091H48.7273V62.303H52.1212V58.9091Z' fill='black' />
        <Path d='M55.5152 58.9091H52.1212V62.303H55.5152V58.9091Z' fill='black' />
        <Path d='M72.4849 58.9091H69.0909V62.303H72.4849V58.9091Z' fill='black' />
        <Path d='M75.8788 58.9091H72.4849V62.303H75.8788V58.9091Z' fill='black' />
        <Path d='M79.2727 58.9091H75.8788V62.303H79.2727V58.9091Z' fill='black' />
        <Path d='M82.6667 58.9091H79.2727V62.303H82.6667V58.9091Z' fill='black' />
        <Path d='M86.0606 58.9091H82.6667V62.303H86.0606V58.9091Z' fill='black' />
        <Path d='M89.4546 58.9091H86.0606V62.303H89.4546V58.9091Z' fill='black' />
        <Path d='M92.8485 58.9091H89.4546V62.303H92.8485V58.9091Z' fill='black' />
        <Path d='M99.6364 58.9091H96.2424V62.303H99.6364V58.9091Z' fill='black' />
        <Path d='M109.818 58.9091H106.424V62.303H109.818V58.9091Z' fill='black' />
        <Path d='M21.5758 62.303H18.1818V65.697H21.5758V62.303Z' fill='black' />
        <Path d='M28.3636 62.303H24.9697V65.697H28.3636V62.303Z' fill='black' />
        <Path d='M31.7576 62.303H28.3636V65.697H31.7576V62.303Z' fill='black' />
        <Path d='M38.5455 62.303H35.1515V65.697H38.5455V62.303Z' fill='black' />
        <Path d='M41.9394 62.303H38.5455V65.697H41.9394V62.303Z' fill='black' />
        <Path d='M55.5152 62.303H52.1212V65.697H55.5152V62.303Z' fill='black' />
        <Path d='M62.303 62.303H58.9091V65.697H62.303V62.303Z' fill='black' />
        <Path d='M65.697 62.303H62.303V65.697H65.697V62.303Z' fill='black' />
        <Path d='M69.0909 62.303H65.697V65.697H69.0909V62.303Z' fill='black' />
        <Path d='M72.4849 62.303H69.0909V65.697H72.4849V62.303Z' fill='black' />
        <Path d='M79.2727 62.303H75.8788V65.697H79.2727V62.303Z' fill='black' />
        <Path d='M86.0606 62.303H82.6667V65.697H86.0606V62.303Z' fill='black' />
        <Path d='M96.2424 62.303H92.8485V65.697H96.2424V62.303Z' fill='black' />
        <Path d='M99.6364 62.303H96.2424V65.697H99.6364V62.303Z' fill='black' />
        <Path d='M103.03 62.303H99.6364V65.697H103.03V62.303Z' fill='black' />
        <Path d='M109.818 62.303H106.424V65.697H109.818V62.303Z' fill='black' />
        <Path d='M116.606 62.303H113.212V65.697H116.606V62.303Z' fill='black' />
        <Path d='M11.3939 65.697H8V69.0909H11.3939V65.697Z' fill='black' />
        <Path d='M14.7879 65.697H11.3939V69.0909H14.7879V65.697Z' fill='black' />
        <Path d='M18.1818 65.697H14.7879V69.0909H18.1818V65.697Z' fill='black' />
        <Path d='M24.9697 65.697H21.5758V69.0909H24.9697V65.697Z' fill='black' />
        <Path d='M38.5455 65.697H35.1515V69.0909H38.5455V65.697Z' fill='black' />
        <Path d='M55.5152 65.697H52.1212V69.0909H55.5152V65.697Z' fill='black' />
        <Path d='M58.9091 65.697H55.5152V69.0909H58.9091V65.697Z' fill='black' />
        <Path d='M65.697 65.697H62.303V69.0909H65.697V65.697Z' fill='black' />
        <Path d='M69.0909 65.697H65.697V69.0909H69.0909V65.697Z' fill='black' />
        <Path d='M72.4849 65.697H69.0909V69.0909H72.4849V65.697Z' fill='black' />
        <Path d='M75.8788 65.697H72.4849V69.0909H75.8788V65.697Z' fill='black' />
        <Path d='M92.8485 65.697H89.4546V69.0909H92.8485V65.697Z' fill='black' />
        <Path d='M113.212 65.697H109.818V69.0909H113.212V65.697Z' fill='black' />
        <Path d='M120 65.697H116.606V69.0909H120V65.697Z' fill='black' />
        <Path d='M11.3939 69.0909H8V72.4849H11.3939V69.0909Z' fill='black' />
        <Path d='M18.1818 69.0909H14.7879V72.4849H18.1818V69.0909Z' fill='black' />
        <Path d='M24.9697 69.0909H21.5758V72.4849H24.9697V69.0909Z' fill='black' />
        <Path d='M31.7576 69.0909H28.3636V72.4849H31.7576V69.0909Z' fill='black' />
        <Path d='M38.5455 69.0909H35.1515V72.4849H38.5455V69.0909Z' fill='black' />
        <Path d='M41.9394 69.0909H38.5455V72.4849H41.9394V69.0909Z' fill='black' />
        <Path d='M45.3333 69.0909H41.9394V72.4849H45.3333V69.0909Z' fill='black' />
        <Path d='M52.1212 69.0909H48.7273V72.4849H52.1212V69.0909Z' fill='black' />
        <Path d='M75.8788 69.0909H72.4849L72.4849 72.4849H75.8788V69.0909Z' fill='black' />
        <Path d='M82.6667 69.0909H79.2727V72.4849H82.6667V69.0909Z' fill='black' />
        <Path d='M99.6364 69.0909H96.2424V72.4849H99.6364V69.0909Z' fill='black' />
        <Path d='M106.424 69.0909H103.03V72.4849H106.424V69.0909Z' fill='black' />
        <Path d='M113.212 69.0909H109.818V72.4849H113.212V69.0909Z' fill='black' />
        <Path d='M120 69.0909H116.606V72.4849H120V69.0909Z' fill='black' />
        <Path d='M11.3939 72.4849H8V75.8788H11.3939V72.4849Z' fill='black' />
        <Path d='M18.1818 72.4849H14.7879V75.8788H18.1818V72.4849Z' fill='black' />
        <Path d='M21.5758 72.4849H18.1818V75.8788H21.5758V72.4849Z' fill='black' />
        <Path d='M41.9394 72.4849H38.5455V75.8788H41.9394V72.4849Z' fill='black' />
        <Path d='M48.7273 72.4849H45.3333V75.8788H48.7273V72.4849Z' fill='black' />
        <Path d='M58.9091 72.4849H55.5152V75.8788H58.9091V72.4849Z' fill='black' />
        <Path d='M62.303 72.4849H58.9091V75.8788H62.303V72.4849Z' fill='black' />
        <Path d='M65.697 72.4849H62.303V75.8788H65.697V72.4849Z' fill='black' />
        <Path d='M69.0909 72.4849H65.697V75.8788H69.0909V72.4849Z' fill='black' />
        <Path d='M75.8788 72.4849H72.4849V75.8788H75.8788V72.4849Z' fill='black' />
        <Path d='M79.2727 72.4849H75.8788V75.8788H79.2727V72.4849Z' fill='black' />
        <Path d='M96.2424 72.4849L92.8485 72.4849V75.8788H96.2424V72.4849Z' fill='black' />
        <Path d='M109.818 72.4849H106.424V75.8788H109.818V72.4849Z' fill='black' />
        <Path d='M116.606 72.4849H113.212V75.8788H116.606V72.4849Z' fill='black' />
        <Path d='M11.3939 75.8788H8V79.2727H11.3939V75.8788Z' fill='black' />
        <Path d='M14.7879 75.8788H11.3939V79.2727H14.7879V75.8788Z' fill='black' />
        <Path d='M18.1818 75.8788H14.7879V79.2727H18.1818V75.8788Z' fill='black' />
        <Path d='M21.5758 75.8788H18.1818V79.2727H21.5758V75.8788Z' fill='black' />
        <Path d='M28.3636 75.8788H24.9697V79.2727H28.3636V75.8788Z' fill='black' />
        <Path d='M31.7576 75.8788H28.3636V79.2727H31.7576V75.8788Z' fill='black' />
        <Path d='M41.9394 75.8788H38.5455V79.2727H41.9394V75.8788Z' fill='black' />
        <Path d='M45.3333 75.8788H41.9394V79.2727H45.3333V75.8788Z' fill='black' />
        <Path d='M55.5152 75.8788H52.1212V79.2727H55.5152V75.8788Z' fill='black' />
        <Path d='M62.303 75.8788H58.9091V79.2727H62.303V75.8788Z' fill='black' />
        <Path d='M69.0909 75.8788H65.697V79.2727H69.0909V75.8788Z' fill='black' />
        <Path d='M75.8788 75.8788H72.4849V79.2727H75.8788V75.8788Z' fill='black' />
        <Path d='M89.4546 75.8788H86.0606V79.2727H89.4546V75.8788Z' fill='black' />
        <Path d='M99.6364 75.8788H96.2424V79.2727H99.6364V75.8788Z' fill='black' />
        <Path d='M103.03 75.8788H99.6364V79.2727H103.03V75.8788Z' fill='black' />
        <Path d='M106.424 75.8788H103.03V79.2727H106.424V75.8788Z' fill='black' />
        <Path d='M116.606 75.8788H113.212V79.2727H116.606V75.8788Z' fill='black' />
        <Path d='M11.3939 79.2727H8V82.6667H11.3939V79.2727Z' fill='black' />
        <Path d='M14.7879 79.2727H11.3939V82.6667H14.7879V79.2727Z' fill='black' />
        <Path d='M18.1818 79.2727H14.7879V82.6667H18.1818V79.2727Z' fill='black' />
        <Path d='M24.9697 79.2727H21.5758V82.6667H24.9697V79.2727Z' fill='black' />
        <Path d='M28.3636 79.2727H24.9697V82.6667H28.3636V79.2727Z' fill='black' />
        <Path d='M35.1515 79.2727H31.7576V82.6667H35.1515V79.2727Z' fill='black' />
        <Path d='M41.9394 79.2727H38.5455V82.6667H41.9394V79.2727Z' fill='black' />
        <Path d='M48.7273 79.2727H45.3333V82.6667H48.7273V79.2727Z' fill='black' />
        <Path d='M55.5152 79.2727H52.1212V82.6667H55.5152V79.2727Z' fill='black' />
        <Path d='M58.9091 79.2727H55.5152V82.6667H58.9091V79.2727Z' fill='black' />
        <Path d='M62.303 79.2727H58.9091V82.6667H62.303V79.2727Z' fill='black' />
        <Path d='M72.4849 79.2727H69.0909V82.6667H72.4849L72.4849 79.2727Z' fill='black' />
        <Path d='M75.8788 79.2727H72.4849L72.4849 82.6667H75.8788V79.2727Z' fill='black' />
        <Path d='M82.6667 79.2727H79.2727V82.6667H82.6667V79.2727Z' fill='black' />
        <Path d='M86.0606 79.2727H82.6667V82.6667H86.0606V79.2727Z' fill='black' />
        <Path d='M89.4546 79.2727H86.0606V82.6667H89.4546V79.2727Z' fill='black' />
        <Path d='M96.2424 79.2727H92.8485V82.6667H96.2424V79.2727Z' fill='black' />
        <Path d='M103.03 79.2727H99.6364V82.6667H103.03V79.2727Z' fill='black' />
        <Path d='M113.212 79.2727H109.818V82.6667H113.212V79.2727Z' fill='black' />
        <Path d='M116.606 79.2727H113.212V82.6667H116.606V79.2727Z' fill='black' />
        <Path d='M14.7879 82.6667H11.3939V86.0606H14.7879V82.6667Z' fill='black' />
        <Path d='M18.1818 82.6667H14.7879V86.0606H18.1818V82.6667Z' fill='black' />
        <Path d='M24.9697 82.6667H21.5758V86.0606H24.9697V82.6667Z' fill='black' />
        <Path d='M31.7576 82.6667H28.3636V86.0606H31.7576V82.6667Z' fill='black' />
        <Path d='M35.1515 82.6667H31.7576V86.0606H35.1515V82.6667Z' fill='black' />
        <Path d='M38.5455 82.6667H35.1515V86.0606H38.5455V82.6667Z' fill='black' />
        <Path d='M41.9394 82.6667H38.5455V86.0606H41.9394V82.6667Z' fill='black' />
        <Path d='M45.3333 82.6667H41.9394V86.0606H45.3333V82.6667Z' fill='black' />
        <Path d='M55.5152 82.6667H52.1212V86.0606H55.5152V82.6667Z' fill='black' />
        <Path d='M65.697 82.6667H62.303V86.0606H65.697V82.6667Z' fill='black' />
        <Path d='M69.0909 82.6667H65.697V86.0606H69.0909V82.6667Z' fill='black' />
        <Path d='M82.6667 82.6667H79.2727V86.0606H82.6667V82.6667Z' fill='black' />
        <Path d='M92.8485 82.6667H89.4546V86.0606H92.8485V82.6667Z' fill='black' />
        <Path d='M109.818 82.6667H106.424V86.0606H109.818V82.6667Z' fill='black' />
        <Path d='M113.212 82.6667H109.818V86.0606H113.212V82.6667Z' fill='black' />
        <Path d='M120 82.6667H116.606V86.0606H120V82.6667Z' fill='black' />
        <Path d='M11.3939 86.0606H8V89.4546H11.3939V86.0606Z' fill='black' />
        <Path d='M18.1818 86.0606H14.7879V89.4546H18.1818V86.0606Z' fill='black' />
        <Path d='M21.5758 86.0606H18.1818V89.4546H21.5758V86.0606Z' fill='black' />
        <Path d='M24.9697 86.0606H21.5758V89.4546H24.9697V86.0606Z' fill='black' />
        <Path d='M35.1515 86.0606H31.7576V89.4546H35.1515V86.0606Z' fill='black' />
        <Path d='M41.9394 86.0606H38.5455V89.4546H41.9394V86.0606Z' fill='black' />
        <Path d='M48.7273 86.0606H45.3333V89.4546H48.7273V86.0606Z' fill='black' />
        <Path d='M55.5152 86.0606H52.1212V89.4546H55.5152V86.0606Z' fill='black' />
        <Path d='M69.0909 86.0606H65.697V89.4546H69.0909V86.0606Z' fill='black' />
        <Path d='M82.6667 86.0606H79.2727V89.4546H82.6667V86.0606Z' fill='black' />
        <Path d='M96.2424 86.0606H92.8485V89.4546H96.2424V86.0606Z' fill='black' />
        <Path d='M99.6364 86.0606H96.2424V89.4546H99.6364V86.0606Z' fill='black' />
        <Path d='M103.03 86.0606H99.6364V89.4546H103.03V86.0606Z' fill='black' />
        <Path d='M116.606 86.0606H113.212V89.4546H116.606V86.0606Z' fill='black' />
        <Path d='M14.7879 89.4546H11.3939V92.8485H14.7879V89.4546Z' fill='black' />
        <Path d='M24.9697 89.4546H21.5758V92.8485H24.9697V89.4546Z' fill='black' />
        <Path d='M31.7576 89.4546H28.3636V92.8485H31.7576V89.4546Z' fill='black' />
        <Path d='M35.1515 89.4546H31.7576V92.8485H35.1515V89.4546Z' fill='black' />
        <Path d='M38.5455 89.4546H35.1515V92.8485H38.5455V89.4546Z' fill='black' />
        <Path d='M41.9394 89.4546H38.5455V92.8485H41.9394V89.4546Z' fill='black' />
        <Path d='M48.7273 89.4546H45.3333V92.8485H48.7273V89.4546Z' fill='black' />
        <Path d='M55.5152 89.4546H52.1212V92.8485H55.5152V89.4546Z' fill='black' />
        <Path d='M58.9091 89.4546H55.5152V92.8485H58.9091V89.4546Z' fill='black' />
        <Path d='M72.4849 89.4546H69.0909V92.8485H72.4849V89.4546Z' fill='black' />
        <Path d='M75.8788 89.4546H72.4849V92.8485H75.8788V89.4546Z' fill='black' />
        <Path d='M82.6667 89.4546H79.2727V92.8485H82.6667V89.4546Z' fill='black' />
        <Path d='M86.0606 89.4546H82.6667V92.8485H86.0606V89.4546Z' fill='black' />
        <Path d='M92.8485 89.4546H89.4546V92.8485H92.8485V89.4546Z' fill='black' />
        <Path d='M96.2424 89.4546H92.8485V92.8485H96.2424V89.4546Z' fill='black' />
        <Path d='M99.6364 89.4546H96.2424V92.8485H99.6364V89.4546Z' fill='black' />
        <Path d='M103.03 89.4546H99.6364V92.8485H103.03V89.4546Z' fill='black' />
        <Path d='M106.424 89.4546H103.03V92.8485H106.424V89.4546Z' fill='black' />
        <Path d='M109.818 89.4546H106.424V92.8485H109.818V89.4546Z' fill='black' />
        <Path d='M113.212 89.4546H109.818V92.8485H113.212V89.4546Z' fill='black' />
        <Path d='M120 89.4546H116.606V92.8485H120V89.4546Z' fill='black' />
        <Path d='M48.7273 92.8485H45.3333V96.2424H48.7273V92.8485Z' fill='black' />
        <Path d='M52.1212 92.8485H48.7273V96.2424H52.1212V92.8485Z' fill='black' />
        <Path d='M58.9091 92.8485H55.5152V96.2424H58.9091V92.8485Z' fill='black' />
        <Path d='M62.303 92.8485H58.9091V96.2424H62.303V92.8485Z' fill='black' />
        <Path d='M65.697 92.8485H62.303V96.2424H65.697V92.8485Z' fill='black' />
        <Path d='M72.4849 92.8485H69.0909V96.2424H72.4849V92.8485Z' fill='black' />
        <Path d='M82.6667 92.8485H79.2727V96.2424H82.6667V92.8485Z' fill='black' />
        <Path d='M89.4546 92.8485H86.0606V96.2424H89.4546V92.8485Z' fill='black' />
        <Path d='M92.8485 92.8485H89.4546V96.2424H92.8485V92.8485Z' fill='black' />
        <Path d='M106.424 92.8485H103.03V96.2424H106.424V92.8485Z' fill='black' />
        <Path d='M109.818 92.8485H106.424V96.2424H109.818V92.8485Z' fill='black' />
        <Path d='M113.212 92.8485H109.818V96.2424H113.212V92.8485Z' fill='black' />
        <Path d='M116.606 92.8485H113.212V96.2424H116.606V92.8485Z' fill='black' />
        <Path d='M11.3939 96.2424H8V99.6364H11.3939V96.2424Z' fill='black' />
        <Path d='M14.7879 96.2424H11.3939V99.6364H14.7879V96.2424Z' fill='black' />
        <Path d='M18.1818 96.2424H14.7879V99.6364H18.1818V96.2424Z' fill='black' />
        <Path d='M21.5758 96.2424H18.1818V99.6364H21.5758V96.2424Z' fill='black' />
        <Path d='M24.9697 96.2424H21.5758V99.6364H24.9697V96.2424Z' fill='black' />
        <Path d='M28.3636 96.2424H24.9697V99.6364H28.3636V96.2424Z' fill='black' />
        <Path d='M31.7576 96.2424H28.3636V99.6364H31.7576V96.2424Z' fill='black' />
        <Path d='M38.5455 96.2424H35.1515V99.6364H38.5455V96.2424Z' fill='black' />
        <Path d='M41.9394 96.2424H38.5455V99.6364H41.9394V96.2424Z' fill='black' />
        <Path d='M45.3333 96.2424H41.9394V99.6364H45.3333V96.2424Z' fill='black' />
        <Path d='M55.5152 96.2424H52.1212V99.6364H55.5152V96.2424Z' fill='black' />
        <Path d='M58.9091 96.2424H55.5152V99.6364H58.9091V96.2424Z' fill='black' />
        <Path d='M62.303 96.2424H58.9091V99.6364H62.303V96.2424Z' fill='black' />
        <Path d='M69.0909 96.2424H65.697V99.6364H69.0909V96.2424Z' fill='black' />
        <Path d='M82.6667 96.2424H79.2727V99.6364H82.6667V96.2424Z' fill='black' />
        <Path d='M92.8485 96.2424H89.4546V99.6364H92.8485V96.2424Z' fill='black' />
        <Path d='M99.6364 96.2424H96.2424V99.6364H99.6364V96.2424Z' fill='black' />
        <Path d='M106.424 96.2424H103.03V99.6364H106.424V96.2424Z' fill='black' />
        <Path d='M113.212 96.2424H109.818V99.6364H113.212V96.2424Z' fill='black' />
        <Path d='M120 96.2424H116.606V99.6364H120V96.2424Z' fill='black' />
        <Path d='M11.3939 99.6364H8V103.03H11.3939V99.6364Z' fill='black' />
        <Path d='M31.7576 99.6364H28.3636V103.03H31.7576V99.6364Z' fill='black' />
        <Path d='M38.5455 99.6364H35.1515V103.03H38.5455V99.6364Z' fill='black' />
        <Path d='M41.9394 99.6364H38.5455V103.03H41.9394V99.6364Z' fill='black' />
        <Path d='M52.1212 99.6364H48.7273V103.03H52.1212V99.6364Z' fill='black' />
        <Path d='M65.697 99.6364H62.303V103.03H65.697V99.6364Z' fill='black' />
        <Path d='M69.0909 99.6364H65.697V103.03H69.0909V99.6364Z' fill='black' />
        <Path d='M72.4849 99.6364H69.0909V103.03H72.4849V99.6364Z' fill='black' />
        <Path d='M75.8788 99.6364H72.4849V103.03H75.8788V99.6364Z' fill='black' />
        <Path d='M79.2727 99.6364H75.8788V103.03H79.2727V99.6364Z' fill='black' />
        <Path d='M82.6667 99.6364H79.2727V103.03H82.6667V99.6364Z' fill='black' />
        <Path d='M92.8485 99.6364H89.4546V103.03H92.8485V99.6364Z' fill='black' />
        <Path d='M106.424 99.6364H103.03V103.03H106.424V99.6364Z' fill='black' />
        <Path d='M113.212 99.6364H109.818V103.03H113.212V99.6364Z' fill='black' />
        <Path d='M120 99.6364H116.606V103.03H120V99.6364Z' fill='black' />
        <Path d='M11.3939 103.03H8V106.424H11.3939V103.03Z' fill='black' />
        <Path d='M18.1818 103.03H14.7879V106.424H18.1818V103.03Z' fill='black' />
        <Path d='M21.5758 103.03H18.1818V106.424H21.5758V103.03Z' fill='black' />
        <Path d='M24.9697 103.03H21.5758V106.424H24.9697V103.03Z' fill='black' />
        <Path d='M31.7576 103.03H28.3636V106.424H31.7576V103.03Z' fill='black' />
        <Path d='M38.5455 103.03H35.1515V106.424H38.5455V103.03Z' fill='black' />
        <Path d='M45.3333 103.03H41.9394V106.424H45.3333V103.03Z' fill='black' />
        <Path d='M48.7273 103.03H45.3333V106.424H48.7273V103.03Z' fill='black' />
        <Path d='M52.1212 103.03H48.7273V106.424H52.1212V103.03Z' fill='black' />
        <Path d='M58.9091 103.03H55.5152V106.424H58.9091V103.03Z' fill='black' />
        <Path d='M62.303 103.03H58.9091V106.424H62.303V103.03Z' fill='black' />
        <Path d='M65.697 103.03H62.303V106.424H65.697V103.03Z' fill='black' />
        <Path d='M72.4849 103.03H69.0909V106.424H72.4849V103.03Z' fill='black' />
        <Path d='M75.8788 103.03H72.4849V106.424H75.8788V103.03Z' fill='black' />
        <Path d='M79.2727 103.03H75.8788V106.424H79.2727V103.03Z' fill='black' />
        <Path d='M92.8485 103.03H89.4546V106.424H92.8485V103.03Z' fill='black' />
        <Path d='M96.2424 103.03H92.8485V106.424H96.2424V103.03Z' fill='black' />
        <Path d='M99.6364 103.03H96.2424V106.424H99.6364V103.03Z' fill='black' />
        <Path d='M103.03 103.03H99.6364V106.424H103.03V103.03Z' fill='black' />
        <Path d='M106.424 103.03H103.03V106.424H106.424V103.03Z' fill='black' />
        <Path d='M109.818 103.03H106.424V106.424H109.818V103.03Z' fill='black' />
        <Path d='M116.606 103.03H113.212V106.424H116.606V103.03Z' fill='black' />
        <Path d='M11.3939 106.424H8V109.818H11.3939V106.424Z' fill='black' />
        <Path d='M18.1818 106.424H14.7879V109.818H18.1818V106.424Z' fill='black' />
        <Path d='M21.5758 106.424H18.1818V109.818H21.5758V106.424Z' fill='black' />
        <Path d='M24.9697 106.424H21.5758V109.818H24.9697V106.424Z' fill='black' />
        <Path d='M31.7576 106.424H28.3636V109.818H31.7576V106.424Z' fill='black' />
        <Path d='M38.5455 106.424H35.1515V109.818H38.5455V106.424Z' fill='black' />
        <Path d='M41.9394 106.424H38.5455V109.818H41.9394V106.424Z' fill='black' />
        <Path d='M48.7273 106.424H45.3333V109.818H48.7273V106.424Z' fill='black' />
        <Path d='M52.1212 106.424H48.7273V109.818H52.1212V106.424Z' fill='black' />
        <Path d='M62.303 106.424H58.9091V109.818H62.303V106.424Z' fill='black' />
        <Path d='M69.0909 106.424H65.697V109.818H69.0909V106.424Z' fill='black' />
        <Path d='M75.8788 106.424H72.4849L72.4849 109.818H75.8788V106.424Z' fill='black' />
        <Path d='M79.2727 106.424H75.8788V109.818H79.2727V106.424Z' fill='black' />
        <Path d='M86.0606 106.424H82.6667V109.818H86.0606V106.424Z' fill='black' />
        <Path d='M89.4546 106.424H86.0606V109.818H89.4546V106.424Z' fill='black' />
        <Path d='M103.03 106.424H99.6364V109.818H103.03V106.424Z' fill='black' />
        <Path d='M106.424 106.424H103.03V109.818H106.424V106.424Z' fill='black' />
        <Path d='M109.818 106.424H106.424V109.818H109.818V106.424Z' fill='black' />
        <Path d='M113.212 106.424H109.818V109.818H113.212V106.424Z' fill='black' />
        <Path d='M116.606 106.424H113.212V109.818H116.606V106.424Z' fill='black' />
        <Path d='M11.3939 109.818H8V113.212H11.3939V109.818Z' fill='black' />
        <Path d='M18.1818 109.818H14.7879V113.212H18.1818V109.818Z' fill='black' />
        <Path d='M21.5758 109.818H18.1818V113.212H21.5758V109.818Z' fill='black' />
        <Path d='M24.9697 109.818H21.5758V113.212H24.9697V109.818Z' fill='black' />
        <Path d='M31.7576 109.818H28.3636V113.212H31.7576V109.818Z' fill='black' />
        <Path d='M38.5455 109.818H35.1515V113.212H38.5455V109.818Z' fill='black' />
        <Path d='M41.9394 109.818H38.5455V113.212H41.9394V109.818Z' fill='black' />
        <Path d='M45.3333 109.818H41.9394V113.212H45.3333V109.818Z' fill='black' />
        <Path d='M48.7273 109.818H45.3333V113.212H48.7273V109.818Z' fill='black' />
        <Path d='M55.5152 109.818H52.1212V113.212H55.5152V109.818Z' fill='black' />
        <Path d='M58.9091 109.818H55.5152V113.212H58.9091V109.818Z' fill='black' />
        <Path d='M62.303 109.818H58.9091V113.212H62.303V109.818Z' fill='black' />
        <Path d='M69.0909 109.818H65.697V113.212H69.0909V109.818Z' fill='black' />
        <Path d='M72.4849 109.818H69.0909V113.212H72.4849L72.4849 109.818Z' fill='black' />
        <Path d='M75.8788 109.818H72.4849L72.4849 113.212H75.8788V109.818Z' fill='black' />
        <Path d='M82.6667 109.818H79.2727V113.212H82.6667V109.818Z' fill='black' />
        <Path d='M86.0606 109.818H82.6667V113.212H86.0606V109.818Z' fill='black' />
        <Path d='M89.4546 109.818H86.0606V113.212H89.4546V109.818Z' fill='black' />
        <Path d='M96.2424 109.818H92.8485V113.212H96.2424V109.818Z' fill='black' />
        <Path d='M99.6364 109.818H96.2424V113.212H99.6364V109.818Z' fill='black' />
        <Path d='M103.03 109.818H99.6364V113.212H103.03V109.818Z' fill='black' />
        <Path d='M109.818 109.818H106.424V113.212H109.818V109.818Z' fill='black' />
        <Path d='M116.606 109.818H113.212V113.212H116.606V109.818Z' fill='black' />
        <Path d='M11.3939 113.212H8V116.606H11.3939V113.212Z' fill='black' />
        <Path d='M31.7576 113.212H28.3636V116.606H31.7576V113.212Z' fill='black' />
        <Path d='M41.9394 113.212H38.5455V116.606H41.9394V113.212Z' fill='black' />
        <Path d='M45.3333 113.212H41.9394V116.606H45.3333V113.212Z' fill='black' />
        <Path d='M52.1212 113.212H48.7273V116.606H52.1212V113.212Z' fill='black' />
        <Path d='M55.5152 113.212H52.1212V116.606H55.5152V113.212Z' fill='black' />
        <Path d='M58.9091 113.212H55.5152V116.606H58.9091V113.212Z' fill='black' />
        <Path d='M69.0909 113.212H65.697V116.606H69.0909V113.212Z' fill='black' />
        <Path d='M72.4849 113.212H69.0909V116.606H72.4849V113.212Z' fill='black' />
        <Path d='M75.8788 113.212H72.4849V116.606H75.8788V113.212Z' fill='black' />
        <Path d='M79.2727 113.212H75.8788V116.606H79.2727V113.212Z' fill='black' />
        <Path d='M86.0606 113.212H82.6667V116.606H86.0606V113.212Z' fill='black' />
        <Path d='M99.6364 113.212H96.2424V116.606H99.6364V113.212Z' fill='black' />
        <Path d='M109.818 113.212H106.424V116.606H109.818V113.212Z' fill='black' />
        <Path d='M120 113.212H116.606V116.606H120V113.212Z' fill='black' />
        <Path d='M11.3939 116.606H8V120H11.3939V116.606Z' fill='black' />
        <Path d='M14.7879 116.606H11.3939V120H14.7879V116.606Z' fill='black' />
        <Path d='M18.1818 116.606H14.7879V120H18.1818V116.606Z' fill='black' />
        <Path d='M21.5758 116.606H18.1818V120H21.5758V116.606Z' fill='black' />
        <Path d='M24.9697 116.606H21.5758V120H24.9697V116.606Z' fill='black' />
        <Path d='M28.3636 116.606H24.9697V120H28.3636V116.606Z' fill='black' />
        <Path d='M31.7576 116.606H28.3636V120H31.7576V116.606Z' fill='black' />
        <Path d='M41.9394 116.606H38.5455V120H41.9394V116.606Z' fill='black' />
        <Path d='M55.5152 116.606H52.1212V120H55.5152V116.606Z' fill='black' />
        <Path d='M65.697 116.606H62.303V120H65.697V116.606Z' fill='black' />
        <Path d='M69.0909 116.606H65.697V120H69.0909V116.606Z' fill='black' />
        <Path d='M82.6667 116.606H79.2727V120H82.6667V116.606Z' fill='black' />
        <Path d='M89.4546 116.606H86.0606V120H89.4546V116.606Z' fill='black' />
        <Path d='M96.2424 116.606H92.8485V120H96.2424V116.606Z' fill='black' />
        <Path d='M109.818 116.606H106.424V120H109.818V116.606Z' fill='black' />
      </G>
      <Defs>
        <ClipPath id='clip0_61_680'>
          <rect width='128' height='128' rx='16' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default QRAppStore;
