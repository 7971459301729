import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Body, Caption, Subheading } from 'src/components';
import { Size } from 'src/constants';
import { usePrescriptions } from 'src/hooks';
import { Patient } from 'src/interfaces';
import { Weight } from 'src/theme';
import { layout } from 'src/theme/globalStyles';
import { Container, RowDescription, TableRow } from './style';

interface Props {
  patientId: Patient['patientId'];
}
const CurrentPrescriptions: React.FC<Props> = ({ patientId }) => {
  const { i18n, t } = useTranslation('petID');
  const { data } = usePrescriptions({ selectedPatientId: patientId });

  const filteredPrescriptions = useMemo(() => {
    const aYearAgo = moment().subtract(1, 'year');
    return data.filter((p) => moment(p.prescribedAt).isAfter(aYearAgo));
  }, [data]);

  return (
    <Container>
      <Subheading style={{ marginTop: Size.X2_S, marginBottom: Size.X3_S }}>
        {t('currentPrescriptions')}
      </Subheading>
      {!filteredPrescriptions.length && <Caption fontSize={12}>{t('none')}</Caption>}
      {!!filteredPrescriptions?.length && (
        <TableRow>
          <Body fontWeight={Weight.MEDIUM} style={layout.flex1}>
            {t('description', { ns: 'patientProfile' })}
          </Body>
          <Body fontWeight={Weight.MEDIUM}>{t('rxDate')}</Body>
        </TableRow>
      )}
      {!!filteredPrescriptions?.length &&
        filteredPrescriptions.map((rx, i) => {
          return (
            <TableRow key={i}>
              <RowDescription numberOfLines={1}>{rx.description}</RowDescription>
              <Body>{moment(rx.prescribedAt).locale(i18n.language).format('L')}</Body>
            </TableRow>
          );
        })}
    </Container>
  );
};

export default CurrentPrescriptions;
