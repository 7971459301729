import _ from 'lodash';
import { NewTodo, PeriodUnit, Todo, TodoReason } from 'src/interfaces';
import { AnySchema, boolean, date, mixed, number, object, string } from 'yup';

export interface FormTodo extends NewTodo {
  repeatType: RepeatType;
}

export type FieldNames = keyof FormTodo;

export enum AlternateRepeatTypes {
  'custom' = 'custom',
  'none' = 'none'
}

const repeatTypes = {
  ..._.omit(PeriodUnit, PeriodUnit.MINUTE),
  ...AlternateRepeatTypes
};

export type RepeatType = AlternateRepeatTypes | PeriodUnit;

export const validationSchema = object<Record<FieldNames, AnySchema>>({
  id: number().optional(),
  patientId: string().required(),
  reason: mixed<TodoReason>().oneOf(Object.values(TodoReason)).required(),
  otherReason: string()
    .nullable()
    .when('reason', {
      is: (reason: TodoReason) => reason === TodoReason.OTHER,
      then: string().required()
    }),
  notes: string().optional(),
  dueDate: date().optional().nullable(),
  repeatType: mixed<RepeatType>().oneOf([
    AlternateRepeatTypes.custom,
    AlternateRepeatTypes.none,
    PeriodUnit.MINUTE,
    PeriodUnit.HOUR,
    PeriodUnit.DAY,
    PeriodUnit.WEEK,
    PeriodUnit.MONTH,
    PeriodUnit.YEAR
  ]),
  period: number().when('repeatType', {
    is: (type: RepeatType) => type === repeatTypes.custom,
    then: number().min(1).required()
  }),
  periodUnit: mixed<PeriodUnit>().oneOf(Object.values(PeriodUnit)),
  repeatUntilDate: date().optional().nullable(),
  allowNotify: string(),
  todoAlertsAttributes: mixed<number[]>(),
  isComplete: boolean().optional()
});

export const defaultData = {
  patientId: '',
  reason: TodoReason.GIVE_MEDICATION,
  otherReason: '',
  notes: '',
  dueDate: undefined,
  period: undefined,
  allowNotify: true,
  todoAlertsAttributes: [{ alertByMinutesPrior: 0 }],
  repeatType: AlternateRepeatTypes.none,
  isComplete: false
};

export const mapTodoToEdit = (todo: Todo): FormTodo => {
  let repeatType: RepeatType;
  if (todo.period === 1 && todo.periodUnit) {
    repeatType = todo.periodUnit;
  } else if (todo.period ?? 0 > 1) {
    repeatType = AlternateRepeatTypes.custom;
  } else {
    repeatType = AlternateRepeatTypes.none;
    todo.periodUnit = undefined;
    todo.period = undefined;
  }
  const todoAlertsAttributes = todo.todoAlertsAttributes.map((t) =>
    _.pick(t, 'alertByMinutesPrior')
  );
  return {
    ..._.pick(todo, [
      'id',
      'patientId',
      'reason',
      'otherReason',
      'allowNotify',
      'isComplete',
      'notes',
      'repeatUntilDate',
      'dueDate',
      'periodUnit',
      'period'
    ]),
    repeatType,
    todoAlertsAttributes
  };
};
