import React from 'react';
import { SIDEBAR_WIDTH, SIDE_BAR_LOGO_SIZE } from '../constants';
import styled from 'styled-components/native';
import { INTL_PHONE_MASK, IconSize, Margin, PHONE_MASK } from 'src/constants';
import { useBookingState } from '../BookingProvider';
import { Body, Headline, HoverButton } from 'src/components';
import { mask } from 'react-native-mask-text';
import { BOOKING_STEPS, STEP_INFO, STEP_STATUS } from '../BookingProvider/helpers';
import { useTranslation } from 'react-i18next';
import { Weight } from 'src/theme';
import { Check } from 'react-native-feather';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { transparent } from 'src/utils';
import { getStepState, switchColors, switchTextColors } from './helper';
import { omit } from 'lodash';

const MARKER_SIZE = IconSize.S;

interface Props {
  disableNav: boolean;
}

const LeftNavBar: React.FC<Props> = ({ disableNav }) => {
  const { values, practiceInfo, bookingState, goToStep } = useBookingState();
  const { colors } = useAppTheme();
  const { t } = useTranslation('onlineBooking');
  return (
    <SidebarContainer>
      <PracticeInfoContainer>
        {!!practiceInfo.petPageLogo && <PracticeLogo source={{ uri: practiceInfo.petPageLogo }} />}
        <CenteredText as={Headline}>{practiceInfo.name} </CenteredText>
        <CenteredText>{mask(practiceInfo.phoneNumber, [PHONE_MASK, INTL_PHONE_MASK])}</CenteredText>
      </PracticeInfoContainer>
      <NavigationIndicatorContainer>
        {Object.values(omit(STEP_INFO, BOOKING_STEPS.STATUS)).map((step) => {
          const status = getStepState(values, bookingState, step.key);
          return (
            <IndicatorRow
              disabled={
                bookingState.current === BOOKING_STEPS.STATUS ||
                !bookingState.visited[step.key] ||
                step.key === bookingState.current ||
                disableNav
              }
              onPress={() => goToStep(step.key)}
              key={step.key}
              icon={
                <IconContainer status={status}>
                  {status === STEP_STATUS.COMPLETE ? (
                    <Check color={colors.surface} width={IconSize.XS} height={IconSize.XS} />
                  ) : (
                    <IconText status={status}>{step.key + 1}</IconText>
                  )}
                  {!!STEP_INFO[step.key].field && <Marker />}
                </IconContainer>
              }
            >
              <SectionTitle isActive={status === STEP_STATUS.ACTIVE}>{t(step.title)}</SectionTitle>
            </IndicatorRow>
          );
        })}
      </NavigationIndicatorContainer>
    </SidebarContainer>
  );
};

export default LeftNavBar;

const NavigationIndicatorContainer = styled.View`
  padding: ${Margin.Large}px;
`;

const SidebarContainer = styled.View`
  align-self: stretch;
  flex-basis: ${SIDEBAR_WIDTH}px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const PracticeInfoContainer = styled.View`
  gap: ${Margin.Medium}px;
  padding: ${Margin.ExtraLarge}px;
`;

const PracticeLogo = styled.Image.attrs({
  resizeMode: 'contain'
})`
  height: ${SIDE_BAR_LOGO_SIZE}px;
`;

const CenteredText = styled(Body)`
  text-align: center;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const SectionTitle = styled(Body)<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? Weight.BOLD : Weight.REGULAR)};
  color: ${({ theme }) => theme.colors.onSurface};
`;

const IndicatorRow = styled(HoverButton).attrs({
  labelStyle: {
    lineHeight: MARKER_SIZE,
    letterSpacing: 0.5
  },
  numberOfLines: 1
})`
  cursor: pointer;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
  gap: ${Margin.Medium}px;
  padding: ${MARKER_SIZE / 2}px;
  overflow: visible;
`;

const IconContainer = styled.View<{ status: STEP_STATUS }>`
  align-items: center;
  justify-content: center;
  width: ${MARKER_SIZE}px;
  height: ${MARKER_SIZE}px;
  border-radius: ${MARKER_SIZE}px;
  background-color: ${({ theme, status }) => switchColors(status, theme.colors)};
`;

const IconText = styled(Body)<{ status: STEP_STATUS }>`
  text-align: center;
  color: ${({ theme, status }) => switchTextColors(status, theme.colors)};
`;

const Marker = styled.View`
  width: 2px;
  height: ${MARKER_SIZE}px;
  background-color: ${({ theme }) => transparent(theme.colors.disabled, 0.1)};
  position: absolute;
  bottom: ${-MARKER_SIZE}px;
`;
