import { AndroidChannel, IOSNotificationCategoryAction } from '@notifee/react-native';
import { NotificationCategory } from './NotificationCategory';

export const CHANNELS: { [k in NotificationCategory]: AndroidChannel } = {
  [NotificationCategory.TODO]: {
    id: NotificationCategory.TODO,
    name: 'To Do reminders',
    sound: 'default',
    vibration: true
  },
  [NotificationCategory.GENERAL]: {
    id: NotificationCategory.GENERAL,
    name: 'General notifications',
    sound: 'default',
    vibration: true
  },
  [NotificationCategory.CONFIRMATION_UPDATE]: {
    id: NotificationCategory.CONFIRMATION_UPDATE,
    name: 'Confirmation status updates',
    sound: 'default',
    vibration: true
  },
  [NotificationCategory.TO_DO_UPDATE]: {
    id: NotificationCategory.TO_DO_UPDATE,
    name: 'To Do Updates',
    sound: 'default',
    vibration: true
  }
};

export const CATEGORIES: IOSNotificationCategoryAction[] = [
  {
    id: NotificationCategory.TODO,
    title: 'View todo',
    foreground: true,
    authenticationRequired: true
  },
  {
    id: NotificationCategory.GENERAL,
    title: 'Notification',
    foreground: true,
    authenticationRequired: true
  },
  {
    id: NotificationCategory.CONFIRMATION_UPDATE,
    title: 'Receive confirmation email updates',
    foreground: true,
    authenticationRequired: true
  },
  {
    id: NotificationCategory.TO_DO_UPDATE,
    title: 'Receive todo notification updates',
    foreground: true,
    authenticationRequired: true
  }
];

export const notificationCategoryOrDefault = (category: string | undefined) => {
  return category && Object.values(NotificationCategory).includes(category as NotificationCategory)
    ? (category as NotificationCategory)
    : NotificationCategory.GENERAL;
};
