import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Screens } from '../screens';
import { FocusAwareStatusBar } from 'src/components';
import { useTranslation } from 'react-i18next';
import PatientListRoot from 'src/scenes/PatientList';
import PatientStackParamsList from './ParamsList';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import useDefaultStackOptions from '../useDefaultStackOptions';

const PetStack = createStackNavigator<PatientStackParamsList>();

export const PatientStackNavigator: React.FC = () => {
  const { colors } = useAppTheme();
  const { t } = useTranslation([
    'petList',
    'patientProfile',
    'petInfoUpdate',
    'additionalInfoUpdate'
  ]);

  const themedHeader = useDefaultStackOptions();

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <PetStack.Navigator>
        <PetStack.Group screenOptions={themedHeader}>
          <PetStack.Screen
            name={Screens.PATIENT_LIST}
            component={PatientListRoot}
            options={{ title: t('title', { ns: 'petList' }) }}
          />
        </PetStack.Group>
      </PetStack.Navigator>
    </>
  );
};
