import { endpoint } from 'src/constants';
import { Schedule, Openings } from 'src/interfaces';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getSchedule = async (practiceId: number): Promise<Schedule> => {
  const url = endpoint('SCHEDULE', {
    practiceId
  });

  const response = await apiClient.get<Schedule>(url);
  return isDefinedOrThrowServerError(response.data);
};

interface OpeningsParams {
  practiceId: number;
  appointmentTypeId?: number;
  categoryId?: number;
}

export const getOpenings = async ({
  practiceId,
  appointmentTypeId,
  categoryId
}: OpeningsParams): Promise<Openings> => {
  const url = endpoint('SCHEDULE_OPENINGS', {
    practiceId
  });

  const params = {
    appointment_type_id: appointmentTypeId,
    category_id: categoryId
  };

  const response = await apiClient.get<Openings>(url, { params });

  return isDefinedOrThrowServerError(response.data);
};
