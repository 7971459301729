import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AlarmBell: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M10.5 19.313a1.566 1.566 0 0 0 3.004 0M12 5.25V3.562m0 1.688a5.625 5.625 0 0 1 5.625 5.625c0 5.285 1.125 6.188 1.125 6.188H5.25s1.125-1.437 1.125-6.188A5.625 5.625 0 0 1 12 5.25Z'
    />
  </Svg>
);

export default AlarmBell;
