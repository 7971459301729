import { template } from 'src/utils/strings';

export const ENDPOINTS = {
  AHOY_VISITS: '/ahoy/visits',
  AHOY_EVENTS: '/ahoy/events',
  USERS: '/users',
  USERS_SIGN_IN: '/users/sign_in',
  USERS_SIGN_OUT: '/users/sign_out',
  USERS_FIND: '/users.json',
  PET_SIGN_UP: '/pet/sign_up',
  USERS_CURRENT: '/users/current',
  CLIENTS: '/clients',
  TERMS: '/terms',
  EULA: 'terms/end-user-license-agreement',
  GET_CLIENT_PRACTICE_CONFIG:
    '/clients/<%= sourceId %>,<%= clientId %>/client_practice_configuration',
  PATCH_CLIENT_PRACTICE_CONFIG: '/client_practice_configurations/<%= id %>',
  GET_PRACTICE: '/practices/<%= id %>',
  GET_PRACTICES: '/practices',
  LOYALTY_COIN_BALANCE_TOTAL: '/loyalty_transactions/loyalty_coin_balance_total',
  LOYALTY_TRANSACTIONS: '/loyalty_transactions',
  REWARDS: '/rewards',
  GET_REWARD: '/rewards/<%= id %>',
  ABILITY: '/ability',
  ANNOUNCEMENTS: '/announcements',
  APPOINTMENTS: '/appointments?start=<%= start %>&length=<%= length %>',
  APPOINTMENT: '/appointments/<%= sourceId %>,<%= appointmentId %>',
  AUTO_FILL_TOKEN: '/auto_fill_token_verifications/<%= token %>',
  PATIENTS: '/patients',
  PATIENT: '/patients/<%= sourceId %>,<%= patientId %>',
  GET_PATIENT_APPOINTMENTS: '/patients/<%= sourceId %>,<%= patientId %>/appointments',
  GET_PATIENT_PRESCRIPTIONS: '/patients/<%= sourceId %>,<%= patientId %>/prescriptions',
  GET_PATIENT_REMINDERS: '/patients/<%= sourceId %>,<%= patientId %>/reminders',
  GET_PRACTICE_CONFIG: '/practices/<%= practiceId %>/practice_configuration',
  POST_CLIENT_PHOTO: '/clients/<%= sourceId %>,<%= clientId %>/photo',
  CLIENT_PHOTO_DELETE: '/clients/<%= sourceId %>,<%= clientId %>/destroy_photo',
  POST_PATIENT_PHOTO: '/patients/<%= sourceId %>,<%= patientId %>/photo',
  PATIENT_PHOTO_DELETE: '/patients/<%= sourceId %>,<%= patientId %>/destroy_photo',
  EMPLOYEES: '/employees',
  DELETE_LOYALTY_TRANSACTION: '/loyalty_transactions/<%= id %>',
  TODOS: '/todos',
  TODO: '/todos/<%= id %>',
  SCHEDULE: '/practice/<%= practiceId %>/schedule',
  SCHEDULE_OPENINGS: '/practice/<%= practiceId %>/schedule/openings',
  REQUESTS: '/requests',
  REQUEST: '/requests/<%= id %>',
  GET_REQUEST_SETTING: '/practices/<%= practiceId %>/request_setting',
  REMINDERS: '/reminders',
  PRESCRIPTIONS: '/prescriptions',
  EMERGENCY_CONTACTS: '/emergency_contacts',
  ACTIVE_CLIENT_LIST: '/active_client_list',
  USERS_PASSWORD: '/users/password',
  CONFIRMATION: '/pet/<%= practiceShortName %>/confirmation',
  EMAIL_CONFIRMATION: '/users/confirmation',
  SWITCH_PRACTICE: '/switch_practice',
  APPOINTMENT_CONFIRMATION: '/appointments/<%= sourceId %>,<%= appointmentId %>/confirm',
  CUSTOM_THEME: '/practice/<%= practiceId %>/mobile_app_setting',
  SESSION_RENEW: '/session_renew/',
  PRACTICE_INFO: '/practice_info/<%= shortName %>',
  REQUEST_IMAGE: '/request_images',
  PRACTICE_SEARCH:
    '/practice_search?name=<%= name %>&postal_code=<%= postalCode %>&city=<%= city %>&state=<%= state %>',
  CLIENT_SEARCH:
    '/client_search?first_name=<%= firstName %>&last_name=<%= lastName %>&phone_number=<%= phoneNumber %>&practice_id=<%= practiceId %>',
  CLIENT_UNAUTHENTICATED_SEARCH: '/client_unauthenticated_search',
  PUSH_REGISTER: '/register',
  PUSH_STATUS: '/status',
  PUSH: '/push',
  GET_LIFELEARN_ARTICLES: '/lifelearn/articles',
  POST_LIFELEARN_FEEDBACK: '/lifelearn/feedback',
  ON_DEMAND_SYNC: '/on_demand/sync/<%= practiceId %>',
  ON_DEMAND_STATUS: '/on_demand/status/<%= practiceId %>',
  REQUEST_STATUS: '/request_status'
};

export const endpoint = (
  route: keyof typeof ENDPOINTS,
  variables?: { [key: string]: string | number | boolean | undefined }
): string => {
  const records = { ...variables };
  if (variables) {
    Object.entries(records).forEach(([key, value]) => {
      if (value) records[key] = encodeURIComponent(value);
    });
  }
  return template(ENDPOINTS[route], records);
};
