import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'react-native-paper';
import { Caption, Headline, Row, Subheading } from 'src/components';
import { ImageUpload } from 'src/components/UploadFiles';
import { IconSize, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

interface Props {
  infoUpdates: Array<{ label: string; value?: string }>;
  images: Record<string, ImageUpload>;
  preferredContactMethod: string;
  setFieldValue: (name: string, value: string) => void;
}

const ConfirmChanges: React.FC<Props> = ({
  infoUpdates,
  images,
  preferredContactMethod,
  setFieldValue
}) => {
  const { t } = useTranslation('requests');
  const { colors } = useAppTheme();

  return (
    <ConfirmChangesContainer>
      <Headline>{t('patientProfile:confirmYourChanges')}</Headline>
      <ItemContainer>
        {infoUpdates.map((infoUpdateItem, i) => (
          <Row key={i} justify='space-between'>
            <Caption style={layout.flex2}>{infoUpdateItem.label}</Caption>
            <Caption style={layout.flex3} fontWeight={Weight.MEDIUM} textAlign='right'>
              {infoUpdateItem.value}
            </Caption>
          </Row>
        ))}
      </ItemContainer>
      {!!Object.values(images).length && (
        <CommentsAndFilesContainer>
          <Caption>{t('wizard.files')}</Caption>
          <ImagesHolder>
            {Object.values(images).map((image: ImageUpload, i: number) => (
              <StyledImage key={i} source={{ uri: image.image.uri }} />
            ))}
          </ImagesHolder>
        </CommentsAndFilesContainer>
      )}
      <ContactMethodContainer>
        <Subheading>{t('patientProfile:preferredContactMethod')}</Subheading>
        <Caption>{t('patientProfile:inTheEvent')}</Caption>
        <RadioButton.Group
          onValueChange={async (value) => await setFieldValue('preferredContactMethod', value)}
          value={preferredContactMethod}
        >
          <Row justify='center'>
            <RadioButton.Item
              mode='android'
              value='text'
              label={t('common:text')}
              color={colors.primary}
            />
            <RadioButton.Item
              mode='android'
              value='email'
              label={t('common:email')}
              color={colors.primary}
            />
            <RadioButton.Item
              mode='android'
              value='phone'
              label={t('common:phone')}
              color={colors.primary}
            />
          </Row>
        </RadioButton.Group>
      </ContactMethodContainer>
    </ConfirmChangesContainer>
  );
};

export default ConfirmChanges;

const ConfirmChangesContainer = styled.ScrollView`
  padding: ${Size.S}px;
  gap: ${Size.S}px;
`;

const ItemContainer = styled.View`
  padding: ${Size.S}px;
  gap: ${Size.X2_S}px;
`;

const CommentsAndFilesContainer = styled.View`
  gap: ${Size.X3_S}px;
  padding-horizontal: ${Size.S}px;
`;

const ContactMethodContainer = styled.View`
  margin-top: ${Size.S}px;
  gap: ${Size.X2_S}px;
`;

const ImagesHolder = styled.View`
  gap: ${Size.XS}px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledImage = styled.Image`
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.roundness}px;
  height: ${IconSize.XL}px;
  width: ${IconSize.XL}px;
`;
