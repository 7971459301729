import axios from 'axios';
import { Platform } from 'react-native';
import packageConfig from '../../../package.json';
import config from 'src/environment';
export const unauthenticatedApiClient = axios.create({
  baseURL: config.API_URL
});

unauthenticatedApiClient.defaults.headers.common = {};
unauthenticatedApiClient.defaults.headers.common.Accept = 'application/json';
unauthenticatedApiClient.defaults.headers.common['Content-Type'] = 'application/json';
unauthenticatedApiClient.defaults.headers.Authorization = null;
unauthenticatedApiClient.defaults.headers['X-App-Version'] = packageConfig.version;
unauthenticatedApiClient.defaults.headers['X-App-Platform'] = Platform.OS;
