/**
 * These values will remain teh same for the session of the application.
 * For these values to persist across sessions, they must be stored in local storage.
 */

import moment from 'moment';
import uuid from '../uuid';
import { ExpiringToken } from './model';

/**
 * Default visit token that expires in 4 hours. This is used to track a visit.
 * @type {ExpiringToken}
 */
export const defaultVisit: ExpiringToken = {
  uuid: uuid(),
  expires: moment().add(4, 'hours').toDate()
};

/**
 * Default visitor token that expires in 2 years. This is used to track a visitor.
 * @type {ExpiringToken}
 */
export const defaultVisitor: ExpiringToken = {
  uuid: uuid(),
  expires: moment().add(2, 'years').toDate()
};
