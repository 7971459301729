export const CLIENT_UPDATE_KNOWN_FIELDS = [
  'name',
  'address',
  'cellPhone',
  'workPhone',
  'homePhone',
  'email'
] as const;

export const PET_UPDATE_KNOWN_FIELDS = [
  'name',
  'birthdate',
  'breed',
  'microchip',
  'rabies',
  'sex',
  'weight',
  'insuranceProvider',
  'policyNumber',
  'diet',
  'medicalConditions',
  'comments'
] as const;

export const KNOWN_FIELDS = [...CLIENT_UPDATE_KNOWN_FIELDS, ...PET_UPDATE_KNOWN_FIELDS] as const;
