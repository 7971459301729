import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Information: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M13.688 15.375h-.563A1.125 1.125 0 0 1 12 14.25v-2.813a.562.562 0 0 0-.563-.562h-.562m.844-2.813a.281.281 0 1 0 .281.279.28.28 0 0 0-.281-.281M3.563 12a8.437 8.437 0 1 0 16.874 0 8.437 8.437 0 0 0-16.875 0Z'
    />
  </Svg>
);
export default Information;
