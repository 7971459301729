import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { FocusAwareStatusBar } from 'src/components';
import { Screens } from '../screens';
import RequestStackParamList from './ParamsList';
import { useTranslation } from 'react-i18next';
import Requests from 'src/scenes/Requests';
import RequestDetails from 'src/scenes/RequestDetails';
import useDefaultStackOptions from '../useDefaultStackOptions';

const RequestStack = createStackNavigator<RequestStackParamList>();

const RequestStackNavigator: React.FC = () => {
  const { t } = useTranslation('requests');
  const themedHeader = useDefaultStackOptions();
  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} />
      <RequestStack.Navigator screenOptions={themedHeader}>
        <RequestStack.Screen
          name={Screens.REQUEST}
          component={Requests}
          options={{ title: t('requestsTitle') }}
        />
        <RequestStack.Screen
          name={Screens.REQUEST_DETAILS}
          component={RequestDetails}
          options={(props) => ({
            title: t('requestDetailTitle')
          })}
        />
      </RequestStack.Navigator>
    </>
  );
};

export default RequestStackNavigator;
