import { Client } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';

export enum FormState {
  PRACTICE_SEARCH,
  PRACTICE_SEARCH_RESULTS,
  CLIENT_SEARCH,
  ACCOUNT_LOCATED
}

export interface FormData {
  practiceOptions?: Practice[];
  selectedPractice?: Practice;
  email?: Client['email'];
  clientId?: Client['clientId'];
}

export interface ReducerState<T extends FormState = FormState> {
  state: T;
  title: string;
  data: FormData;
  showModal?: boolean;
  exit?: boolean;
}

export enum ACTIONS {
  ADVANCE,
  GO_BACK,
  SHOW_MODAL,
  UPDATE_DATA
}

export interface Actions {
  action: ACTIONS;
  data?: Partial<FormData>;
}

export const STATES: { [state in FormState]: Omit<ReducerState<state>, 'data'> } = {
  [FormState.PRACTICE_SEARCH]: {
    state: FormState.PRACTICE_SEARCH,
    title: 'practiceClientSearch:practiceSearchTitle'
  },
  [FormState.PRACTICE_SEARCH_RESULTS]: {
    state: FormState.PRACTICE_SEARCH_RESULTS,
    title: 'practiceClientSearch:searchResultsTitle'
  },
  [FormState.CLIENT_SEARCH]: {
    state: FormState.CLIENT_SEARCH,
    title: 'practiceClientSearch:clientSearchTitle'
  },
  [FormState.ACCOUNT_LOCATED]: {
    state: FormState.ACCOUNT_LOCATED,
    title: 'practiceClientSearch:accountLocatedTitle'
  }
};

export const examplePractice: Practice = {
  id: 0,
  address: '1234 Park Place',
  city: 'New York',
  state: 'NY',
  postalCode: '10001',
  name: 'Ally Example Veterinarian',
  sourceId: 0,
  fingerprint: 0,
  firstSyncedAt: new Date(),
  lastSyncedAt: new Date(),
  createdAt: new Date(),
  email: '',
  phoneNumber: '2128675309',
  faxNumber: '',
  shortName: '',
  website: '',
  country: '',
  businessContactName: '',
  businessContactEmail: '',
  timeZone: '',
  selectedFeatures: [],
  features: '',
  googletagManagerToken: '',
  status: '',
  syncStatus: '',
  visitable: false,
  pms: '',
  smsNumber: ''
};
