import { View } from 'react-native';
import Logo from 'src/components/Icons/Logo';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

const LogoSuper: React.FC = () => {
  const { colors } = useAppTheme();
  return (
    <PositionContainer>
      <Logo color={'none'} size={450} stroke={colors.primary} strokeWidth={0.5} />
    </PositionContainer>
  );
};

export default LogoSuper;

const PositionContainer = styled.View`
  position: relative;
  left: 75px;
  bottom: 75px;
  opacity: 0.5;
`;
