import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Screens } from '../screens';
import { FocusAwareStatusBar } from 'src/components';
import AppointmentsRoot from 'src/scenes/Appointments/AppointmentsRoot';
import AppointmentDetails from 'src/scenes/Appointments/AppointmentDetails';
import AppointmentStackParamsList from './ParamsList';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import useDefaultStackOptions from '../useDefaultStackOptions';

const AppointmentsStack = createStackNavigator<AppointmentStackParamsList>();

export const AppointmentsStackNavigator: React.FC = () => {
  const { colors } = useAppTheme();
  const { t } = useTranslation();

  const themedHeader = useDefaultStackOptions();

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <AppointmentsStack.Navigator initialRouteName={Screens.APPOINTMENTS}>
        <AppointmentsStack.Group screenOptions={themedHeader}>
          <AppointmentsStack.Screen
            name={Screens.APPOINTMENTS}
            component={AppointmentsRoot}
            options={{ title: t('title', { ns: 'appointments' }) }}
          />
          <AppointmentsStack.Screen
            name={Screens.APPOINTMENT_DETAILS}
            component={AppointmentDetails}
            options={{
              headerBackTitleVisible: false,
              title: t('detailTitle', { ns: 'appointments' })
            }}
          />
        </AppointmentsStack.Group>
      </AppointmentsStack.Navigator>
    </>
  );
};
