import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TouchableRipple } from 'react-native-paper';
import { TimePickerModal } from 'react-native-paper-dates';
import { useBoolean } from 'src/hooks';
import TextInput, { TextInputProps } from './TextInput';
import { extractStyles, toLocalTime } from 'src/utils';
import sentry, { interpolateName } from 'src/utils/sentry';

interface TimePickerValue {
  hours: number;
  minutes: number;
}

interface Props
  extends Omit<
    TextInputProps,
    | 'value'
    | 'pointerEvents'
    | 'editable'
    | 'showSoftInputOnFocus'
    | 'focusable'
    | 'caretHidden'
    | 'theme'
  > {
  value?: Date;
  onConfirm: (v: TimePickerValue | undefined) => void;
}

const TimePicker: React.FC<Props> = ({ value, onConfirm, style, ...inputProps }) => {
  const { i18n } = useTranslation();
  const {
    value: timePickerVisible,
    toTrue: openTimePicker,
    toFalse: closeTimePicker
  } = useBoolean();

  const sentryLabel = interpolateName('TimePicker', inputProps.label);

  const onPress = useCallback(() => {
    sentry.addBreadcrumb({ type: 'Interaction', message: `${sentryLabel} was opened` });
    openTimePicker();
  }, [sentryLabel, openTimePicker]);

  const { innerStyle, outerStyle } = useMemo(() => {
    const flatStyle = StyleSheet.flatten(style);
    return extractStyles(flatStyle);
  }, [style]);

  return (
    <>
      <TouchableRipple onPress={onPress} style={outerStyle} sentry-label={sentryLabel}>
        <TextInput
          pointerEvents='none'
          editable={false}
          value={value ? toLocalTime(moment(value)) : undefined}
          showSoftInputOnFocus={false}
          focusable={false}
          caretHidden
          style={innerStyle}
          {...inputProps}
        />
      </TouchableRipple>
      <TimePickerModal
        hours={moment(value).hours()}
        minutes={moment(value).minutes()}
        locale={i18n.language}
        visible={timePickerVisible}
        onConfirm={(value) => {
          sentry.addBreadcrumb({
            type: 'Interaction',
            message: `${sentryLabel}: Time ${value?.hours}:${value?.minutes} was chosen`
          });
          onConfirm(value);
          closeTimePicker();
        }}
        onDismiss={closeTimePicker}
      />
    </>
  );
};

export default TimePicker;
