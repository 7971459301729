import { AxiosRequestConfig } from 'axios';
import { endpoint } from 'src/constants';
import { Appointment, Patient } from 'src/interfaces';
import Prescription from 'src/interfaces/api/Prescription';
import Reminder from 'src/interfaces/api/Reminder';
import { isDefinedOrThrowServerError, hasPhoto } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import createImageFormData, { PictureType } from 'src/utils/createImageFormData';

export { getPaginatedPatients } from './getPaginatedPatients';

export const getPatients = async (): Promise<Patient[]> => {
  const response = await apiClient.get<Patient[]>(endpoint('PATIENTS'));
  const patients = isDefinedOrThrowServerError(response.data, Array.isArray);
  return patients.map((p) => ({ ...p, hasPhoto: hasPhoto(p) }));
};

export const getPatient = async (sourceId: number, patientId: string): Promise<Patient> => {
  const url = endpoint('PATIENT', {
    sourceId,
    patientId
  });

  const response = await apiClient.get<Patient>(url);
  const patient = isDefinedOrThrowServerError(response.data);
  return {
    ...patient,
    hasPhoto: hasPhoto(patient),
    insuranceProvider: patient.insuranceProvider ?? '',
    policyNumber: patient.policyNumber ?? '',
    medicalConditions: patient.medicalConditions ?? '',
    diet: patient.diet ?? ''
  };
};

export const getPatientAppointments = async (
  sourceId: Patient['sourceId'],
  patientId: Patient['patientId']
): Promise<Appointment[]> => {
  const response = await apiClient.get<Appointment[]>(
    endpoint('GET_PATIENT_APPOINTMENTS', { sourceId, patientId })
  );
  return isDefinedOrThrowServerError(response.data);
};

export const getPatientPrescriptions = async (
  sourceId: Patient['sourceId'],
  patientId: Patient['patientId']
): Promise<Prescription[]> => {
  const response = await apiClient.get<Prescription[]>(
    endpoint('GET_PATIENT_PRESCRIPTIONS', { sourceId, patientId })
  );
  return isDefinedOrThrowServerError(response.data);
};

export const getPatientReminders = async (
  sourceId: Patient['sourceId'],
  patientId: Patient['patientId']
): Promise<Reminder[]> => {
  const response = await apiClient.get<Reminder[]>(
    endpoint('GET_PATIENT_REMINDERS', { sourceId, patientId })
  );
  return isDefinedOrThrowServerError(response.data);
};

export const postPatientPhoto = async (
  sourceId: Patient['sourceId'],
  patientId: Patient['patientId'],
  uri: string
): Promise<Patient> => {
  const config: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  const formData = await createImageFormData(uri, PictureType.PATIENT);

  const response = await apiClient.post<Patient>(
    endpoint('POST_PATIENT_PHOTO', { sourceId, patientId }),
    formData,
    config
  );

  return isDefinedOrThrowServerError(response.data);
};

export const patchPatient = async (patient: Patient): Promise<Patient> => {
  const response = await apiClient.patch<Patient>(
    endpoint('PATIENT', { sourceId: patient.sourceId, patientId: patient.patientId }),
    {
      patient
    }
  );
  return isDefinedOrThrowServerError(response.data);
};

export const deletePatientPhoto = async (
  sourceId: Patient['sourceId'],
  patientId: Patient['patientId']
): Promise<void> => {
  await apiClient.delete<undefined>(endpoint('PATIENT_PHOTO_DELETE', { sourceId, patientId }));
};
