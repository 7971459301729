import * as Linking from 'expo-linking';
import { t } from 'i18next';
import Toast from 'react-native-toast-message';

const draftSms = async (sms: string, message: string) => {
  if (!sms || !message) {
    Toast.show({
      type: 'error',
      text1: 'Error',
      text2: 'Please provide a valid phone number and message'
    });
    return;
  }
  const url = `sms:${sms}?body=${encodeURI(message)}`;
  try {
    if (await Linking.canOpenURL(url)) {
      await Linking.openURL(url);
    } else {
      throw new Error('Unable to open');
    }
  } catch {
    Toast.show({
      type: 'error',
      text1: t('unableToOpen', { ns: 'common' })
    });
  }
};

export default draftSms;
