import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body, BottomButton, HoverButton, Row, Subheading } from 'src/components';
import { Size } from 'src/constants';
import { fontStyles } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import NotificationHero from './asset/NotificationHero';
import { Screens } from 'src/routes/stacks/screens';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ScrollView } from 'react-native';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { useNotificationPrompt } from 'src/providers/NotificationPromptProvider';
import useGoBack from 'src/hooks/useGoBack';

const PushNotificationsOptIn: React.FC<
  NativeStackScreenProps<RootStackParamList, Screens.PUSH_NOTIFICATIONS_OPT_IN>
> = ({ navigation }) => {
  const { t } = useTranslation('pushNotifications');
  const { requestNotificationPermission, setHasPrompted } = useNotificationPrompt();

  const goBack = useGoBack();

  const promptUser = async () => {
    await requestNotificationPermission();
    goBack();
  };
  const notNowPushed = () => {
    setHasPrompted(true);
    goBack();
  };

  return (
    <>
      <ScrollView style={{ flex: 1 }}>
        <HeroContainer>
          <NotificationHero />
        </HeroContainer>
        <Content>
          <StyledSubheading>{t('enableTo')}</StyledSubheading>
          <BulletItem justify='flex-start'>
            <Bullet />
            <Body>{t('point1')}</Body>
          </BulletItem>
          <BulletItem justify='flex-start'>
            <Bullet />
            <Body>{t('point2')}</Body>
          </BulletItem>
          <BulletItem justify='flex-start'>
            <Bullet />
            <Body>{t('point3')}</Body>
          </BulletItem>
        </Content>
      </ScrollView>
      <HoverButton onPress={notNowPushed}>{t('common:notNow')}</HoverButton>
      <BottomButton
        onPress={() => {
          void promptUser();
        }}
      >
        {t('enableNotifications')}
      </BottomButton>
    </>
  );
};

export default PushNotificationsOptIn;

const HeroContainer = styled.View`
  align-self: center;
  margin-vertical: ${Size.M}px;
`;

const Content = styled.View`
  ${({ theme }) => (theme.viewMode.isMobile ? 'flex: 1;' : '')}
  margin: ${Size.M}px;
`;

const BulletItem = styled(Row)`
  margin: ${Size.X3_S}px;
`;

const StyledSubheading = styled(Subheading)`
  margin-bottom: ${Size.XS}px;
`;

const Bullet = styled.View`
  width: ${Size.X3_S}px;
  height: ${Size.X3_S}px;
  border-radius: ${Size.X3_S}px;
  background-color: ${({ theme }) => theme.colors.text};
  align-self: flex-start;
  margin-top: ${fontStyles.body.lineHeight / 2 - Size.X4_S}px;
  margin-right: ${Size.XS}px;
`;
