import { StyleSheet } from 'react-native';
import { Size } from 'src/constants';

const style = StyleSheet.create({
  round: { borderRadius: Size.L },
  closeIcon: {
    marginLeft: Size.X3_S
  },
  drawerCardStyle: { paddingTop: Size.M, paddingBottom: Size.S },
  drawerContentContainerStyle: { gap: Size.S }
});

export default style;
