import styled from 'styled-components/native';
import { IconSize, Size } from 'src/constants';
import { SvgProps } from 'react-native-svg';
import { transparent } from 'src/utils';
import { ColorValue, StyleProp, ViewStyle } from 'react-native';

interface Props {
  icon: (props: SvgProps) => React.JSX.Element;
  color: ColorValue;
  style?: StyleProp<ViewStyle>;
}

const MiniHeroIcon: React.FC<Props> = ({ icon: Icon, color, style }) => (
  <IconContainer color={color as string} style={style}>
    <InnerContainer>
      <Icon width={IconSize.S} height={IconSize.S} color={color as string} />
    </InnerContainer>
  </IconContainer>
);

const InnerContainer = styled.View`
  width: ${IconSize.S}px;
  height: ${IconSize.S}px;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.View<{ color: string }>`
  background-color: ${({ color }) => transparent(color, 0.2)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.XS}px;
  align-self: center;
`;

export default MiniHeroIcon;
