import { camelCase, mapKeys } from 'lodash';
import type {
  InfoUpdateItem,
  InfoUpdateRequest,
  InfoUpdateRequestContent
} from 'src/interfaces/api/InfoUpdateRequest';
import { DetailItem, KnownField } from './model';
import { KNOWN_FIELDS } from './constants';
import { Icon } from 'react-native-vector-icons/Icon';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const isInfoUpdateItem = <T extends InfoUpdateItem>(
  item: T | InfoUpdateRequestContent
): item is T => {
  return (item as T).dataType !== undefined;
};

const generateInfoUpdateData = (request: InfoUpdateRequest): DetailItem[] => {
  const updateContent: InfoUpdateItem[] = [];

  if (isInfoUpdateItem(request.content)) {
    updateContent.push({
      dataType: request.content.dataType,
      newValue: request.content.newValue || 'requestFields.removed'
    });
  }

  request.content.informationUpdates?.forEach((e) => {
    const entry = mapKeys(e, (value, key) => camelCase(key)) as unknown as InfoUpdateItem;
    if (!entry.dataType) return;
    updateContent.push({
      dataType: entry.dataType,
      newValue: entry.newValue || 'requestFields.removed'
    });
  });

  return updateContent.map((update) => {
    const camelized = camelCase(update.dataType);
    const knownFieldIndex = KNOWN_FIELDS.indexOf(camelized as KnownField);
    if (knownFieldIndex !== -1) {
      return {
        title: `requestFields.${camelized}` ?? '',
        value: update.newValue,
        ...KNOWN_FIELD_MAP[KNOWN_FIELDS[knownFieldIndex]]
      };
    }
    return {
      title: update.dataType ?? '',
      value: update.newValue,
      icon: undefined
    };
  });
};

export default generateInfoUpdateData;

export const KNOWN_FIELD_MAP: Record<
  KnownField,
  { icon: string; altIcon?: Omit<typeof Icon, 'getFontFamily' | 'getRawGlyphMap'> }
> = {
  name: { icon: 'user' },
  address: { icon: 'home' },
  cellPhone: { icon: 'mobile-alt' },
  workPhone: { icon: 'monitor-cellphone', altIcon: MaterialCommunityIcons },
  homePhone: { icon: 'phone-classic', altIcon: MaterialCommunityIcons },
  email: { icon: 'envelope' },
  birthdate: { icon: 'birthday-cake' },
  breed: { icon: 'certificate' },
  microchip: { icon: 'id-badge', altIcon: FontAwesome6 },
  rabies: { icon: 'shield-virus' },
  sex: { icon: 'venus-mars' },
  weight: { icon: 'weight-scale', altIcon: FontAwesome6 },
  insuranceProvider: { icon: 'file-medical' },
  policyNumber: { icon: 'file-alt' },
  diet: { icon: 'utensils' },
  medicalConditions: { icon: 'stethoscope' },
  comments: { icon: 'comment' }
};
