import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import CheckCircleHero from './CheckCircleHero';

export enum STEPS {
  DRAWER,
  PASTDUE,
  TODAY,
  SCHEDULED,
  COMPLETED,
  ADD
}

const ToDoListTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: { id: STEPS.DRAWER, priority: 0 },
  [STEPS.PASTDUE]: { id: STEPS.PASTDUE, priority: 1 },
  [STEPS.TODAY]: { id: STEPS.TODAY, priority: 2 },
  [STEPS.SCHEDULED]: { id: STEPS.SCHEDULED, priority: 3 },
  [STEPS.COMPLETED]: { id: STEPS.COMPLETED, priority: 4 },
  [STEPS.ADD]: { id: STEPS.ADD, priority: 5 }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'todos:list.tour.slide0.title',
    body: 'todos:list.tour.slide0.body',
    image: <CheckCircleHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.TO_DOS,
  ToDoListTourMap
);
