import React from 'react';
import Svg, { G, Rect, Circle, Path, Defs, ClipPath, NumberProp } from 'react-native-svg';

const CONFETTI_IMAGE_HEIGHT = 271;
const CONFETTI_IMAGE_WIDTH = 393;

const Confetti: React.FC<{
  width?: NumberProp;
  height?: NumberProp;
  preserveAspectRatio?: string;
}> = (props) => (
  <Svg
    width={CONFETTI_IMAGE_WIDTH}
    height={CONFETTI_IMAGE_HEIGHT}
    {...props}
    viewBox='0 0 393 271'
    fill='none'
  >
    <G clipPath='url(#clip0_152_1735)'>
      <Rect
        x='64.7974'
        y='234.332'
        width='4.933'
        height='5'
        transform='rotate(-178 64.7974 234.332)'
        fill='#00B4E6'
        fillOpacity='0.6'
      />
      <Rect
        x='154.708'
        y='138.675'
        width='7.933'
        height='3'
        transform='rotate(103 154.708 138.675)'
        fill='#00CD93'
        fillOpacity='0.75'
      />
      <Rect
        x='110.797'
        y='240.332'
        width='2.933'
        height='6'
        transform='rotate(-85 110.797 240.332)'
        fill='#9747FF'
        fillOpacity='0.9'
      />
      <Rect
        x='52.7974'
        y='237.332'
        width='6.933'
        height='3'
        transform='rotate(-105 52.7974 237.332)'
        fill='#00B4E6'
        fillOpacity='0.35'
      />
      <Rect
        x='18'
        y='79'
        width='9.933'
        height='3'
        transform='rotate(121 18 79)'
        fill='#E5A300'
        fillOpacity='0.6'
      />
      <Rect
        x='365.038'
        y='114.189'
        width='14.933'
        height='6'
        transform='rotate(95 365.038 114.189)'
        fill='#00B4E6'
        fillOpacity='0.85'
      />
      <Rect
        x='150'
        y='215'
        width='12.933'
        height='3'
        transform='rotate(146 150 215)'
        fill='#00B4E6'
        fillOpacity='0.45'
      />
      <Rect
        x='378'
        y='166'
        width='3.933'
        height='6'
        transform='rotate(166 378 166)'
        fill='#E5A300'
        fillOpacity='0.45'
      />
      <Rect
        x='289'
        y='238'
        width='9.933'
        height='5'
        transform='rotate(-171 289 238)'
        fill='#F50057'
        fillOpacity='0.45'
      />
      <Rect
        x='19'
        y='98'
        width='3.933'
        height='3'
        transform='rotate(165 19 98)'
        fill='#00B4E6'
        fillOpacity='0.9'
      />
      <Rect
        x='89.3456'
        y='256'
        width='16.933'
        height='5'
        transform='rotate(42 89.3456 256)'
        fill='#9747FF'
        fillOpacity='0.65'
      />
      <Rect
        x='243'
        y='255'
        width='14.933'
        height='5'
        transform='rotate(15 243 255)'
        fill='#E5A300'
        fillOpacity='0.9'
      />
      <Rect
        x='180'
        y='188'
        width='8.933'
        height='6'
        transform='rotate(-163 180 188)'
        fill='#00B4E6'
        fillOpacity='0.6'
      />
      <Rect
        x='250.973'
        y='140'
        width='14.933'
        height='4'
        transform='rotate(48 250.973 140)'
        fill='#F50057'
        fillOpacity='0.35'
      />
      <Rect
        x='168'
        y='192'
        width='3.933'
        height='3'
        transform='rotate(-51 168 192)'
        fill='#9747FF'
        fillOpacity='0.6'
      />
      <Rect
        x='225'
        y='247'
        width='4.933'
        height='3'
        transform='rotate(-16 225 247)'
        fill='#F50057'
        fillOpacity='0.65'
      />
      <Rect
        x='77'
        y='187'
        width='9.933'
        height='6'
        transform='rotate(-179 77 187)'
        fill='#9747FF'
      />
      <Circle cx='343.047' cy='117.048' r='2.0475' fill='#9747FF' fillOpacity='0.75' />
      <Circle cx='309.047' cy='164.053' r='3.0475' fill='#00B4E6' fillOpacity='0.85' />
      <Circle cx='10.0475' cy='57.0475' r='2.0475' fill='#F50057' fillOpacity='0.9' />
      <Circle cx='101.548' cy='194.548' r='2.5475' fill='#F50057' fillOpacity='0.75' />
      <Circle cx='379.047' cy='87.0475' r='2.0475' fill='#F50057' fillOpacity='0.75' />
      <Circle cx='281.547' cy='170.548' r='1.5475' fill='#E5A300' fillOpacity='0.6' />
      <Circle cx='75.0475' cy='89.0475' r='1.0475' fill='#E5A300' fillOpacity='0.45' />
      <Circle cx='326.547' cy='172.553' r='1.5475' fill='#00CD93' />
      <Circle cx='24.8449' cy='197.379' r='1.0475' fill='#F50057' fillOpacity='0.45' />
      <Circle cx='232.048' cy='182.048' r='2.0475' fill='#00B4E6' fillOpacity='0.45' />
      <Circle cx='267.547' cy='201.548' r='2.5475' fill='#00CD93' fillOpacity='0.35' />
      <Circle cx='28.3449' cy='194.879' r='2.5475' fill='#9747FF' fillOpacity='0.45' />
      <Circle cx='32.5475' cy='249.548' r='1.5475' fill='#E5A300' fillOpacity='0.65' />
      <Circle cx='131.548' cy='169.548' r='1.5475' fill='#E5A300' fillOpacity='0.85' />
      <Circle cx='172.048' cy='259.047' r='1.0475' fill='#E5A300' fillOpacity='0.35' />
      <Circle cx='162.048' cy='216.048' r='3.0475' fill='#00CD93' fillOpacity='0.85' />
      <Circle cx='364.047' cy='159.048' r='1.0475' fill='#00B4E6' />
      <Circle cx='372.586' cy='212.737' r='2.5475' fill='#00CD93' />
      <Circle cx='303.047' cy='143.048' r='3.0475' fill='#F50057' fillOpacity='0.45' />
      <Circle cx='332.047' cy='197.048' r='1.0475' fill='#00CD93' fillOpacity='0.45' />
      <Path
        d='M134.473 228.93L136.084 234.945L130.07 233.333L134.473 228.93Z'
        fill='#00CD93'
        fillOpacity='0.65'
      />
      <Path
        d='M318.137 202.472L312.817 201.818L316.043 197.537L318.137 202.472Z'
        fill='#F50057'
        fillOpacity='0.65'
      />
      <Path
        d='M387.103 235.62L389.72 238.148L388.125 241.418L384.522 240.911L383.891 237.328L387.103 235.62Z'
        fill='#00CD93'
        fillOpacity='0.65'
      />
      <Path
        d='M110.594 223.028L109.16 219.65L112.538 218.216L113.972 221.594L110.594 223.028Z'
        fill='#F50057'
        fillOpacity='0.35'
      />
      <Path
        d='M370.409 237.864L368.038 235.944L369.131 233.096L372.177 233.256L372.967 236.203L370.409 237.864Z'
        fill='#E5A300'
        fillOpacity='0.6'
      />
      <Path
        d='M361.086 153.727L362.92 158.764L357.64 157.833L361.086 153.727Z'
        fill='#00CD93'
        fillOpacity='0.45'
      />
      <Path
        d='M252.064 188.501L256.333 194.165L249.293 195.03L252.064 188.501Z'
        fill='#9747FF'
        fillOpacity='0.45'
      />
      <Path
        d='M353.407 239.313L352.501 245.033L346.781 244.127L347.687 238.407L353.407 239.313Z'
        fill='#00B4E6'
        fillOpacity='0.9'
      />
      <Path
        d='M43.1109 233.422L37.3338 233.826L36.9298 228.049L42.7069 227.645L43.1109 233.422Z'
        fill='#9747FF'
        fillOpacity='0.65'
      />
      <Path
        d='M315.497 120.814L311.607 119.163L311.975 114.953L316.093 114.002L318.27 117.625L315.497 120.814Z'
        fill='#9747FF'
        fillOpacity='0.35'
      />
      <Path
        d='M2.89871 200.446L2.58245 196.831L5.87112 198.365L2.89871 200.446Z'
        fill='#E5A300'
        fillOpacity='0.75'
      />
      <Path
        d='M192.027 185.643L194.462 188.347L192.643 191.498L189.084 190.742L188.704 187.123L192.027 185.643Z'
        fill='#00B4E6'
        fillOpacity='0.6'
      />
      <Path
        d='M385.031 6.32773L386.772 8.06921L385.654 10.2636L383.222 9.87833L382.837 7.44583L385.031 6.32773Z'
        fill='#F50057'
        fillOpacity='0.75'
      />
      <Path
        d='M67.4303 162.639L65.2358 160.52L66.5731 157.778L69.594 158.202L70.1238 161.207L67.4303 162.639Z'
        fill='#E5A300'
        fillOpacity='0.6'
      />
      <Path
        d='M355.037 73.0947L356.116 71.2989L358.21 71.3354L359.226 73.1678L358.147 74.9635L356.052 74.927L355.037 73.0947Z'
        fill='#00B4E6'
      />
      <Path
        d='M115.318 174.428L121.207 177.175L118.461 183.064L112.572 180.318L115.318 174.428Z'
        fill='#E5A300'
        fillOpacity='0.45'
      />
      <Path
        d='M201.689 207.246L203.073 205.031L201.394 203.03L203.929 203.662L205.313 201.447L205.495 204.053L208.029 204.684L205.608 205.663L205.79 208.268L204.111 206.268L201.689 207.246Z'
        fill='#00CD93'
        fillOpacity='0.85'
      />
      <Path
        d='M88.5392 197.812L88.8576 195.22L86.4904 194.116L89.0543 193.618L89.3726 191.025L90.6389 193.31L93.2028 192.811L91.4215 194.722L92.6878 197.006L90.3206 195.902L88.5392 197.812Z'
        fill='#00CD93'
        fillOpacity='0.35'
      />
      <Path
        d='M76.4383 92.0725L74.7531 91.4232L74.0496 93.0864L73.7693 91.3024L71.9771 91.5247L73.382 90.39L72.2934 88.949L73.9785 89.5983L74.6821 87.9351L74.9624 89.7191L76.7545 89.4968L75.3496 90.6315L76.4383 92.0725Z'
        fill='#9747FF'
        fillOpacity='0.65'
      />
      <Path
        d='M43.6321 68.7968L46.4109 69.123L48.4674 67.2258L48.1412 70.0046L50.0384 72.061L47.2596 71.7349L45.2032 73.6321L45.5293 70.8533L43.6321 68.7968Z'
        fill='#F50057'
        fillOpacity='0.75'
      />
      <Path
        d='M49.7968 245.632L48.0929 243.8L46.2068 245.444L46.9412 243.052L44.5747 242.241L47.0131 241.681L46.5326 239.226L48.2366 241.058L50.1227 239.414L49.3882 241.806L51.7548 242.617L49.3164 243.177L49.7968 245.632Z'
        fill='#9747FF'
        fillOpacity='0.6'
      />
      <Path
        d='M90.9449 145.938L92.8872 147.515L94.5262 145.625L94.1317 148.095L96.5882 148.57L94.2514 149.463L95.0689 151.828L93.1265 150.251L91.4875 152.141L91.882 149.67L89.4255 149.196L91.7623 148.303L90.9449 145.938Z'
        fill='#9747FF'
        fillOpacity='0.45'
      />
      <Path
        d='M344.581 109.313L345.32 112.012L347.849 113.208L345.151 113.947L343.955 116.476L343.216 113.777L340.687 112.581L343.385 111.843L344.581 109.313Z'
        fill='#9747FF'
        fillOpacity='0.6'
      />
      <Path
        d='M341.757 149.859L343.064 150.833L344.655 150.475L343.681 151.782L344.039 153.373L342.732 152.399L341.141 152.757L342.115 151.45L341.757 149.859Z'
        fill='#00CD93'
        fillOpacity='0.35'
      />
      <Path
        d='M178.87 253.841L180.491 255.622L182.899 255.551L181.117 257.172L181.188 259.58L179.567 257.798L177.159 257.87L178.941 256.248L178.87 253.841Z'
        fill='#00CD93'
        fillOpacity='0.6'
      />
      <Path
        d='M384.066 157.546L385.074 159.449L387.009 158.502L385.865 160.327L387.653 161.53L385.5 161.452L385.353 163.601L384.344 161.698L382.409 162.644L383.553 160.819L381.766 159.617L383.918 159.695L384.066 157.546Z'
        fill='#9747FF'
        fillOpacity='0.35'
      />
      <Path
        d='M290.969 266.336L288.9 265.457L287.424 267.154L287.62 264.914L285.55 264.036L287.741 263.53L287.937 261.29L289.096 263.217L291.287 262.711L289.811 264.408L290.969 266.336Z'
        fill='#F50057'
        fillOpacity='0.65'
      />
      <Path
        d='M286.582 58.7814L286.827 61.6206L289.673 61.4679L287.337 63.0996L288.892 65.4877L286.31 64.2801L285.02 66.8209L284.775 63.9816L281.929 64.1343L284.266 62.5027L282.711 60.1146L285.292 61.3222L286.582 58.7814Z'
        fill='#9747FF'
        fillOpacity='0.45'
      />
      <Path
        d='M266.549 175.151L264.737 174.632L263.682 176.195L263.616 174.31L261.804 173.791L263.576 173.146L263.51 171.262L264.671 172.747L266.442 172.103L265.388 173.666L266.549 175.151Z'
        fill='#E5A300'
        fillOpacity='0.45'
      />
      <Path
        d='M101.726 183.237L104.452 182.408L103.711 179.656L105.793 181.602L107.806 179.584L107.161 182.36L109.915 183.094L107.188 183.924L107.929 186.676L105.848 184.73L103.835 186.747L104.48 183.971L101.726 183.237Z'
        fill='#F50057'
        fillOpacity='0.45'
      />
      <Path
        d='M316.126 124.517L317.786 125.667L319.74 125.155L318.59 126.815L319.103 128.769L317.442 127.619L315.488 128.131L316.638 126.471L316.126 124.517Z'
        fill='#00B4E6'
        fillOpacity='0.65'
      />
      <Path
        d='M344.981 65.3179L345.007 63.7961L343.568 63.3005L345.024 62.8555L345.05 61.3336L345.923 62.5805L347.379 62.1354L346.463 63.351L347.336 64.5979L345.897 64.1023L344.981 65.3179Z'
        fill='#E5A300'
        fillOpacity='0.75'
      />
      <Path
        d='M66.0672 190.248L68.3905 189.613L69.4201 187.435L70.056 189.758L72.2336 190.788L69.9103 191.424L68.8806 193.601L68.2448 191.278L66.0672 190.248Z'
        fill='#9747FF'
        fillOpacity='0.65'
      />
      <Path
        d='M11.6293 161.945L11.8829 160.156L10.104 159.845L11.7793 159.171L11.1595 157.474L12.5812 158.588L13.7403 157.203L13.4867 158.991L15.2656 159.303L13.5903 159.977L14.2101 161.673L12.7884 160.56L11.6293 161.945Z'
        fill='#00CD93'
        fillOpacity='0.45'
      />
      <Path
        d='M55.0632 147.364L55.275 148.871L56.774 149.135L55.4059 149.803L55.6178 151.31L54.5604 150.215L53.1924 150.882L53.9069 149.538L52.8496 148.443L54.3486 148.708L55.0632 147.364Z'
        fill='#00CD93'
        fillOpacity='0.65'
      />
    </G>
    <Defs>
      <ClipPath id='clip0_152_1735'>
        <Rect width='393' height='271' fill='white' />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Confetti;
