import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { LayoutAnimation, TouchableWithoutFeedback } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Body, Caption, FocusAwareStatusBar, PrimaryGradientBackground } from 'src/components';
import { ALLY_SUPPORT_EMAIL, IconSize, IS_ANDROID, IS_WEB, VERSION_INFO } from 'src/constants';
import { useAvailablePractices, useBoolean, usePracticeInfo } from 'src/hooks';
import { useQueryClient } from 'react-query';
import { AsyncStorageKeys, QueryKeys } from 'src/api';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import _ from 'lodash';
import { emailSchema, LoginForm, validationSchema } from './helpers/validation';
import { AvailablePractice, UserConfirmationStatus } from 'src/interfaces/api/AvailablePractices';
import { useAuth } from 'src/providers/AuthProvider';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Trans, useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import LogoHeader from './components/LogoHeader';
import {
  PrimaryColorBackground,
  Container,
  ContentContainer,
  FootNoteContainer,
  FormContainer,
  InnerContainer,
  LoadingContainer,
  SectionTitle,
  Link,
  DebugContainer,
  RefreshTextLink,
  NOT_MOBILE_WIDTH
} from './components/style';
import LoginFormComponent from './components/LoginForm';
import MultiplePractices from './components/MultiplePractices';
import HeroImage from './components/HeroImage';
import { CheckCircle } from 'react-native-feather';
import FormState from './helpers/formState';
import { storage } from 'src/utils';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { TourGuide } from './tour';
import IntroDrawer from './components/IntroDrawer';
import openEmailToAlly from 'src/utils/openEmailToAlly';
import DownloadOurApp from './components/DownloadOurApp/DownloadOurApp';
import sentry from 'src/utils/sentry';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useImpersonation } from 'src/providers/ImpersonationProvider/ImpersonationProvider';

type Props = NativeStackScreenProps<RootStackParamList, Screens.LANDING>;

const Landing: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('login');
  const { colors, viewMode } = useAppTheme();
  const { value: formFocused, toTrue: focusForm } = useBoolean();
  const { logIn, error, signUp, setError } = useAuth();

  const queryClient = useQueryClient();

  const desiredPracticeName = route.params?.practice;

  const formik = useFormik({
    validationSchema,
    initialValues: validationSchema.cast({}),
    initialStatus: {
      state: FormState.LOG_IN
    },
    onSubmit: async (v, ctx) => {
      if (v.selectedPractices[0].userRegistered) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        try {
          ctx.setStatus({ state: FormState.LOGGING_IN });
          await storage.setTypedItem(AsyncStorageKeys.REMEMBER_ME, v.rememberMe);
          await logIn({
            email: v.email,
            password: v.password,
            practiceId: v.selectedPractices[0].practiceId,
            id: v.selectedPractices[0].userId
          }).catch(async (e: AxiosError) => {
            if (e.response?.status === 401) {
              ctx.setErrors({ password: 'error.invalidPassword' });
            } else {
              ctx.setErrors({ password: 'error.genericLoginError' });
            }
          });
        } finally {
          sentry.setUser({ clientId: v.selectedPractices[0].clientId });
          ctx.setStatus({ state: FormState.LOG_IN });
        }
      } else {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
        const unregisteredPractices =
          v.availablePractices?.filter((practice: AvailablePractice) => !practice.userRegistered) ??
          [];
        if (unregisteredPractices.length > 1 && !status?.practicesSelected) {
          ctx.setStatus({ state: FormState.SELECT_PRACTICES });
        } else {
          try {
            ctx.setStatus({ state: FormState.CREATING_ACCOUNTS });
            await signUp({
              email: values.email,
              password: values.password,
              confirmPassword: values.confirmPassword,
              practices: values.selectedPractices
            });
            ctx.setStatus({ state: FormState.ACCOUNTS_CREATED });
            await queryClient.invalidateQueries([
              QueryKeys.CHECK_EMAIL,
              v.email.toLocaleLowerCase()
            ]);
            setTimeout(() => {
              ctx.resetForm();
              navigation.setParams({ practice: v.selectedPractices[0].shortName });
            }, moment.duration(3, 'seconds').asMilliseconds());
          } catch {
            ctx.setStatus({ state: FormState.LOG_IN });
          }
        }
      }
    }
  });

  const { previewThemeSet, removePreview } = useAppTheme();
  const [displayName, setDisplayName] = useState<string>('');
  const [canShowCustomContent, setCanShowCustomContent] = useState<boolean>();

  const { data: practiceCustomization } = usePracticeInfo(
    formik.values?.selectedPractices?.[0]?.shortName ?? desiredPracticeName,
    {
      suspense: !!desiredPracticeName && !formik.values?.selectedPractices?.[0],
      enabled: canShowCustomContent,
      onError: () => {
        removePreview();
        navigation.setParams({ practice: undefined });
      }
    }
  );

  useEffect(() => {
    setCanShowCustomContent(
      !!desiredPracticeName ||
        formik.values?.availablePractices?.length === 1 ||
        formik.values?.availablePractices?.some((practice) => practice.userRegistered)
    );

    if (
      canShowCustomContent &&
      practiceCustomization?.theme.custom &&
      practiceCustomization?.theme.useCustomTheme
    ) {
      previewThemeSet(practiceCustomization.theme.custom);
    } else {
      removePreview();
    }

    setDisplayName(
      canShowCustomContent && practiceCustomization ? practiceCustomization?.name : t('appName')
    );
  }, [
    canShowCustomContent,
    desiredPracticeName,
    formik.values?.availablePractices,
    formik.values.selectedPractices,
    practiceCustomization,
    previewThemeSet,
    removePreview,
    t
  ]);

  const { values, setValues, setFieldTouched, handleSubmit, status, setStatus } = formik;

  useEffect(() => {
    if (route.params?.email) {
      void setFieldTouched('email').then(async () =>
        setValues((v) => ({ ...v, email: route.params?.email ?? '' }))
      );
      focusForm();
    }
  }, [setValues, route.params?.email, setFieldTouched, focusForm]);

  const { value: isSelected, toTrue: doSelect, toFalse: unselect } = useBoolean(false);
  const [debouncedEmail, setDebouncedEmail] = useState('');
  const checkEmail = useMemo(
    () =>
      _.debounce(
        (email: string) => {
          if (email.toLowerCase() === debouncedEmail) return;
          void setValues((v) => ({ ...v, availablePractices: undefined, selectedPractices: [] }));
          setDebouncedEmail(email.toLowerCase());
        },
        moment.duration(1, 'seconds').asMilliseconds(),
        {
          leading: false,
          trailing: true
        }
      ),
    [debouncedEmail, setValues]
  );

  useEffect(() => {
    checkEmail(values.email);
  }, [checkEmail, values.email]);

  const {
    data,
    isFetched: rawIsFetched,
    isFetching
  } = useAvailablePractices(debouncedEmail, {
    beforeFetch: unselect,
    onError: (e: AxiosError) => {
      if (e.response?.status === 404) {
        setError(t('error.emailNotFound', { ns: 'login' }));
      }
    },
    onSuccess: () => {
      setError(undefined);
    },
    enabled: emailSchema.isValidSync(debouncedEmail)
  });

  // The default selected practice is set in order of priority to the desired practice (if provided),
  // and if not, the first available user confirmed practice, followed by the first available registered
  // practice.
  const updatePractices = useCallback(
    async (availablePractices: AvailablePractice[] = []) => {
      const desiredPractice = availablePractices?.find(
        (practice) => desiredPracticeName === practice.shortName
      );
      const confirmedPractice = availablePractices?.find(
        (practice) => practice.userConfirmationStatus === UserConfirmationStatus.CONFIRMED
      );
      const registeredPractice = availablePractices?.find((practice) => practice.userRegistered);

      const selectedPractices = [
        desiredPractice ?? confirmedPractice ?? registeredPractice ?? availablePractices?.[0]
      ];

      await setFieldTouched('selectedPractices');
      await setFieldTouched('availablePractices');
      await setValues((v) => ({ ...v, availablePractices, selectedPractices }));
      doSelect();
    },
    [desiredPracticeName, doSelect, setValues, setFieldTouched]
  );

  const isFetched = isSelected && rawIsFetched && !isFetching;
  const triggerUpdate = rawIsFetched && !isFetching;
  useEffect(() => {
    if (triggerUpdate) {
      void updatePractices(data);
    }
  }, [data, triggerUpdate, updatePractices, debouncedEmail]);

  useEffect(() => () => LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut), []);

  const submitMultiplePracticeRegistration = (selectedPractices: AvailablePractice[]) => {
    void setValues((v: LoginForm) => ({
      ...v,
      selectedPractices
    }))
      .then(() =>
        setStatus({
          practicesSelected: true
        })
      )
      .then(() => handleSubmit());
  };

  useEffect(() => {
    const selectedPractice: AvailablePractice | undefined = values.selectedPractices?.[0];
    if (selectedPractice && canShowCustomContent) {
      navigation.setParams({ practice: selectedPractice?.shortName });
    }
  }, [canShowCustomContent, navigation, values.email, values.selectedPractices]);

  const [isNarrow, setIsNarrow] = useState<boolean>(viewMode.windowWidth < NOT_MOBILE_WIDTH);
  useLayoutEffect(() => {
    setIsNarrow(viewMode.windowWidth < NOT_MOBILE_WIDTH);
  }, [viewMode.windowWidth]);

  const { top } = useSafeAreaInsets();

  const { openAdminLogin } = useImpersonation();

  return (
    <TourGuide.Provider>
      <PrimaryColorBackground />
      <Container bounces={false}>
        <InnerContainer isNarrow={isNarrow}>
          <FocusAwareStatusBar barStyle={formFocused ? 'light-content' : 'dark-content'} />

          {!formFocused && (
            <HeroImage
              isNarrow={isNarrow}
              petSignInImage={
                canShowCustomContent ? practiceCustomization?.petSignInImage : undefined
              }
            />
          )}
          <ContentContainer isNarrow={isNarrow} isImageShowing={!formFocused}>
            <PrimaryGradientBackground />
            <KeyboardAwareScrollView
              contentContainerStyle={{
                flexGrow: 1,
                marginTop: IS_ANDROID && isNarrow && formFocused ? top : 0
              }}
              keyboardShouldPersistTaps='always'
            >
              <FormContainer isNarrow={isNarrow}>
                <LogoHeader displayName={displayName} />
                {status.state === FormState.LOG_IN && (
                  <LoginFormComponent
                    {...{
                      ...formik,
                      isFetched,
                      focusForm,
                      error,
                      debouncedEmail,
                      practicesFetched: rawIsFetched
                    }}
                  />
                )}
                {status.state === FormState.SELECT_PRACTICES && (
                  <MultiplePractices
                    availablePractices={values.availablePractices}
                    selectedPractices={values.selectedPractices}
                    onSubmit={submitMultiplePracticeRegistration}
                  />
                )}
                {status.state === FormState.ACCOUNTS_CREATED && (
                  <LoadingContainer>
                    <SectionTitle color={colors.onPrimary}>{t('allDone')}</SectionTitle>
                    <CheckCircle height={IconSize.L} width={IconSize.L} color={colors.onPrimary} />
                    <FootNoteContainer>
                      <Body color={colors.onPrimary} textAlign={'center'}>
                        {t('accountsCreated')}
                      </Body>
                    </FootNoteContainer>
                  </LoadingContainer>
                )}
                {status.state === FormState.CREATING_ACCOUNTS && (
                  <>
                    <SectionTitle color={colors.onPrimary}>
                      {t('creatingYourAccounts')}
                    </SectionTitle>
                    <LoadingContainer>
                      <ActivityIndicator size='large' color={colors.onPrimary} />
                      <FootNoteContainer>
                        <Body color={colors.onPrimary}>{t('justAFewMoments')}</Body>
                      </FootNoteContainer>
                    </LoadingContainer>
                  </>
                )}
                {status.state === FormState.LOGGING_IN && (
                  <>
                    <SectionTitle color={colors.onPrimary}>{t('loggingIn')}</SectionTitle>
                    <LoadingContainer>
                      <ActivityIndicator size='large' color={colors.onPrimary} />
                      <FootNoteContainer>
                        <Body color={colors.onPrimary}>{t('justAFewMoments')}</Body>
                      </FootNoteContainer>
                    </LoadingContainer>
                  </>
                )}
              </FormContainer>
              <FootNoteContainer>
                <Caption color={colors.onPrimary}>
                  <Trans
                    t={t}
                    i18nKey={
                      status.state === FormState.SELECT_PRACTICES
                        ? t('practiceNotListed')
                        : t('footNote')
                    }
                    values={{ ourEmail: ALLY_SUPPORT_EMAIL }}
                    components={{
                      contactUs: (
                        <Link
                          onPress={() => {
                            void openEmailToAlly();
                          }}
                        />
                      )
                    }}
                  />
                </Caption>
              </FootNoteContainer>
              <DebugContainer>
                <RefreshTextLink>{t('refreshApp')}</RefreshTextLink>
                <TouchableWithoutFeedback onLongPress={() => openAdminLogin()}>
                  <Caption textAlign='center' color={colors.onPrimary}>
                    {VERSION_INFO}
                  </Caption>
                </TouchableWithoutFeedback>
              </DebugContainer>
              {isNarrow && IS_WEB && <DownloadOurApp isNarrow={isNarrow} />}
            </KeyboardAwareScrollView>
          </ContentContainer>
        </InnerContainer>
        {!isNarrow && IS_WEB && <DownloadOurApp isNarrow={isNarrow} />}
      </Container>
      <IntroDrawer />
    </TourGuide.Provider>
  );
};

export default Landing;
