import { endpoint } from 'src/constants';
import { EditTodo, NewTodo, Todo } from 'src/interfaces';
import { isDefinedOrThrowServerError, PaginationRS } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getAllTodos = async (
  start?: number,
  length?: number,
  isComplete?: boolean,
  dir?: 'asc' | 'desc',
  column?: string
): Promise<PaginationRS<Todo>> => {
  const response = await apiClient.get<PaginationRS<Todo>>(endpoint('TODOS'), {
    params: { start, length, todo: { isComplete }, order: { dir, column } }
  });
  const todoRS = isDefinedOrThrowServerError(response.data);
  return todoRS;
};

export const getTodo = async (id: Todo['id']) =>
  isDefinedOrThrowServerError((await apiClient.get<Todo>(endpoint('TODO', { id }))).data);

export const postTodo = async (todo: NewTodo) =>
  isDefinedOrThrowServerError(
    isDefinedOrThrowServerError((await apiClient.post<Todo>(endpoint('TODOS'), todo)).data)
  );

export const patchTodo = async (todo: EditTodo) =>
  isDefinedOrThrowServerError(
    await (
      await apiClient.patch<Todo>(endpoint('TODO', { id: todo.id }), { todo })
    ).data
  );

export const deleteTodo = async (todo: Todo) =>
  (await apiClient.delete(endpoint('TODO', { id: todo.id }))).data;
