import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, View } from 'react-native';
import { IconSize, Size } from 'src/constants';
import styled from 'styled-components/native';
import { X } from 'react-native-feather';
import palette from 'src/theme/palette';
import { ActivityIndicator, Caption, TouchableRipple } from 'react-native-paper';
import _ from 'lodash';
import { uploadRequestPhoto } from 'src/api';
import { AxiosError, AxiosProgressEvent } from 'axios';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import { ImageUpload } from './model';
import useFileItemAnimation from './useFileItemAnimation';
import { Buffer } from 'buffer';

const MB5 = 5 * 1024 * 1024;
interface Props {
  item: ImageUpload;
  modifyImage: (value: Partial<ImageUpload> | undefined) => void;
}

enum Status {
  UPLOADING,
  UPLOADED,
  TOO_LARGE,
  OTHER_ERROR,
  INVALID_FILE
}

const FileItem: React.FC<Props> = ({ item, modifyImage }) => {
  const { t } = useTranslation('common');
  const abortRef = useRef<AbortController>(new AbortController());
  const [progress, setProgress] = useState<number>(0);
  const [status, setStatus] = useState<Status>(item.id ? Status.UPLOADED : Status.UPLOADING);

  const upload = useCallback(async () => {
    // @ts-expect-error fileSize is not available on android, addressed in future sdk https://github.com/expo/expo/pull/27293
    let fileSize = item.image.fileSize ?? item.image.filesize;
    if (!fileSize) {
      const base64str = item.image.uri.split('base64,')[1];
      if (base64str) {
        const decoded = Buffer.from(base64str, 'base64');
        fileSize = decoded.length;
      } else {
        setStatus(Status.INVALID_FILE);
        modifyImage({ error: true });
        return;
      }
    }
    if (fileSize > MB5) {
      setStatus(Status.TOO_LARGE);
      modifyImage({ error: true });
      return;
    }
    try {
      const { id } = await uploadRequestPhoto(
        item.image.uri,
        _.throttle(({ progress }: AxiosProgressEvent) => setProgress(progress ?? 0), 250, {
          leading: true,
          trailing: true
        }),
        abortRef.current
      );
      modifyImage({ id, error: false });
      setStatus(Status.UPLOADED);
    } catch (e: unknown) {
      const err = e as AxiosError;
      if (err.status === 422) {
        modifyImage({ error: true });
        setStatus(Status.INVALID_FILE);
      } else if (err.code !== AxiosError.ERR_CANCELED) {
        Toast.show({
          type: 'error',
          text1: t('uhOh'),
          text2: err.message ?? t('uploadFailed')
        });
        modifyImage({ error: true });
        setStatus(Status.OTHER_ERROR);
      }
    }
    // modifyImage and t should not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.image.uri]);

  useEffect(() => {
    if (!item.id) {
      void upload();
    }
  }, [item.id, upload]);

  useEffect(
    () => () => {
      if (!abortRef.current.signal.aborted) abortRef.current.abort();
    },
    []
  );

  const { backgroundColor, opacity, hidden, buttonColor, ref, width } = useFileItemAnimation(
    item,
    progress
  );

  const hasError = [Status.INVALID_FILE, Status.OTHER_ERROR, Status.TOO_LARGE].includes(status);

  if (!item.image) {
    return null;
  }

  return (
    <>
      <ImageContainer style={{ backgroundColor }} hasError={hasError}>
        <ActionContainer
          disabled={status !== Status.OTHER_ERROR}
          onPress={() => {
            setStatus(Status.UPLOADING);
            void upload();
          }}
        >
          <AnimatedImage
            source={{ uri: item.image.uri }}
            style={{
              opacity: !item.id ? 0.5 : opacity
            }}
          />
          {status === Status.UPLOADING && !hidden && (
            <ProgressContainer>
              <ProgressFill percent={width ?? 0} />
            </ProgressContainer>
          )}
          {status === Status.UPLOADING && !hidden && <Activity />}

          <DeleteBadge ref={ref} style={{ backgroundColor: buttonColor }}>
            <TouchableRipple
              onPress={() => {
                abortRef.current.abort();
                modifyImage(undefined);
              }}
              hitSlop={{ left: Size.XS, top: Size.XS, right: Size.XS, bottom: Size.XS }}
            >
              <X width={Size.XS} height={Size.XS} color={palette.AB_BRIGHT_WHITE} />
            </TouchableRipple>
          </DeleteBadge>
        </ActionContainer>
      </ImageContainer>
      <View>
        {status === Status.INVALID_FILE && <ErrorCaption>{t('invalidFile')}</ErrorCaption>}
        {status === Status.OTHER_ERROR && <ErrorCaption>{t('uploadFailed')}</ErrorCaption>}
        {status === Status.TOO_LARGE && <ErrorCaption>{t('fileTooLarge')}</ErrorCaption>}
      </View>
    </>
  );
};

export default FileItem;

const ErrorCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;

const ProgressContainer = styled.View`
  margin: ${Size.X2_S}px;
  border-radius: ${({ theme }) => theme.roundness}px;
  height: ${Size.XS}px;
  background-color: ${({ theme }) => theme.colors.placeholder};
  overflow: hidden;
`;

const ProgressFill = styled.View<{ percent: number }>`
  width: ${({ percent }) => percent * 100}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const DeleteBadge = styled(Animated.View)`
  position: absolute;
  top: ${-Size.X2_S}px;
  right: ${-Size.X2_S}px;
  width: ${IconSize.XS}px;
  height: ${IconSize.XS}px;
  background-color: ${({ theme }) => theme.colors.error};
  border-radius: ${IconSize.XS}px;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled(Animated.View)<{ hasError: boolean }>`
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.roundness}px;
  ${({ hasError, theme }) => (hasError ? `border: 1.5px solid ${theme.colors.error};` : '')};
`;

const ActionContainer = styled.TouchableOpacity`
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`;

const AnimatedImage = styled(Animated.Image).attrs({ resizeMode: 'contain' })`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Activity = styled(ActivityIndicator).attrs({
  size: 'large'
})`
  position: absolute;
  align-self: center;
  width: 100%;
  height: 100%;
`;
