import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

interface Props {
  color: string;
}

const PatientID: React.FC<Props> = ({ color }) => {
  return (
    <Svg fill='none' viewBox='0 -3 28 25'>
      <Rect width='24' height='15' x='1' y='1' stroke={color} strokeWidth='1.2' rx='1' />
      <Path stroke={color} d='M14 5.5h8M14 7.5h8M14 9.5h8M14 11.5h4' />
      <Path
        fill={color}
        d='M5.827 4.375c.541-.078 1.114.513 1.279 1.33.165.81-.133 1.535-.674 1.617-.536.083-1.114-.509-1.283-1.324-.17-.812.137-1.536.678-1.623Zm3.277 0c.546.087.848.811.688 1.623-.174.815-.747 1.407-1.288 1.324-.546-.082-.844-.807-.674-1.618.165-.816.738-1.407 1.274-1.329ZM3.375 6.483c.523-.224 1.233.184 1.604.894.349.724.23 1.485-.289 1.71-.517.224-1.223-.179-1.585-.899-.362-.72-.234-1.485.27-1.705Zm8.25 0c.504.22.633.986.27 1.705-.362.72-1.067 1.123-1.585.899-.518-.225-.637-.986-.29-1.71.372-.71 1.082-1.118 1.605-.894Zm-.765 4.941c.018.431-.312.908-.706 1.087-.82.375-1.792-.404-2.704-.404-.912 0-1.893.811-2.7.404-.458-.225-.775-.82-.715-1.316.083-.683.903-1.05 1.389-1.55C6.07 9 6.528 7.786 7.45 7.786c.916 0 1.402 1.196 2.02 1.86.51.56 1.357 1.032 1.39 1.78Z'
      />
    </Svg>
  );
};

export default PatientID;
