import React from 'react';
import { Body, Shimmer, Subheading } from 'src/components';
import useReward from 'src/hooks/react-query/useReward';
import { colorShade, isDefined } from 'src/utils';
import cleanUrl from 'src/utils/cleanUrl';
import { Weight } from 'src/theme';
import { Trophy } from 'src/components/kit/ABIcons';
import { CoinIcon } from 'src/routes/stacks/LoyaltyTabNavigator/components/LoyaltyTabBar/style';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import {
  CareCoinView,
  NullImageView,
  RewardContentView,
  RewardImage,
  RewardOfferCardContainer,
  RewardOfferInnerContainer,
  RewardTitleView
} from '../style';
import { IconSize, Size } from 'src/constants';

interface Props {
  rewardId: number;
  onPress: () => void;
}

const OfferCard: React.FC<Props> = ({ rewardId, onPress }) => {
  const { colors, roundness, dark } = useAppTheme();

  const { data: reward, isLoading } = useReward(rewardId);

  const imageURL = isDefined(reward?.imageId) ? cleanUrl(reward?.image.urls.thumbnail) : undefined;

  return (
    <RewardOfferCardContainer onPress={onPress}>
      <RewardOfferInnerContainer>
        <Shimmer
          height={Size.X4_L}
          width={'100%'}
          style={{ borderRadius: roundness }}
          isLoading={isLoading}
        >
          {isDefined(imageURL) && <RewardImage source={{ uri: imageURL }} />}
          {!isDefined(imageURL) && (
            <NullImageView>
              <Trophy
                stroke={dark ? colors.primary : colorShade(colors.primary, 0.8)}
                height={IconSize.L}
                width={IconSize.L}
              />
            </NullImageView>
          )}
          <RewardContentView>
            <RewardTitleView>
              <Subheading fontWeight={Weight.LIGHT} numberOfLines={2} color={colors.onSurface}>
                {reward?.title}
              </Subheading>
            </RewardTitleView>
          </RewardContentView>
          <CareCoinView>
            <Body color={colors.onSurface}>{reward?.price}</Body>
            <CoinIcon />
          </CareCoinView>
        </Shimmer>
      </RewardOfferInnerContainer>
    </RewardOfferCardContainer>
  );
};

export default OfferCard;
