import * as mime from 'mime';
import moment from 'moment-timezone';
import { IS_WEB } from 'src/constants';

export enum PictureType {
  CLIENT = 'client',
  PATIENT = 'patient',
  REQUEST = 'request_image'
}

const keyMap: { [k in PictureType]: string } = {
  [PictureType.CLIENT]: 'photo',
  [PictureType.PATIENT]: 'photo',
  [PictureType.REQUEST]: 'image'
};

const createImageFormData = async (uri: string, pictureType: PictureType): Promise<FormData> => {
  const dateTimeStamp = moment().unix().toString();
  const formData = new FormData();
  if (IS_WEB) {
    const photoBlob = await fetch(uri).then(async (i) => await i.blob());
    formData.append(`${pictureType}[${keyMap[pictureType]}]`, photoBlob, dateTimeStamp);
  } else {
    formData.append(`${pictureType}[${keyMap[pictureType]}]`, {
      uri,
      name: dateTimeStamp,
      type: mime.getType(uri)
    } as any);
  }

  return formData;
};

export default createImageFormData;
