import React, { PropsWithChildren } from 'react';
import defaultContext from './defaultContext';

const BiometricsPromptContext = React.createContext(defaultContext);
export const useBiometricsPrompt = () => React.useContext(BiometricsPromptContext);

const BiometricsPromptProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export default BiometricsPromptProvider;
