import React, { useCallback, useMemo } from 'react';
import { Animated, GestureResponderEvent } from 'react-native';
import { ButtonProps, TouchableRipple } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IS_ANDROID, IS_IOS, Margin } from 'src/constants';
import useHoverEffect from 'src/hooks/useHoverEffect';
import { colorShade, transparent } from 'src/utils/colorValues';
import styled from 'styled-components/native';
import { Subheading } from './Text';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import useKeyboardStatus from 'src/hooks/useKeyboardStatus';
import sentry, { interpolateName } from 'src/utils/sentry';

interface Props extends Omit<ButtonProps, 'theme' | 'mode'> {
  shade?: number;
  sentryName?: string;
}
const BottomButton: React.FC<Props> = ({
  children,
  disabled,
  shade = 0.8,
  sentryName,
  onPress: parentOnPress,
  ...props
}) => {
  const { colors } = useAppTheme();
  const { bottom } = useSafeAreaInsets();
  const [ref, hoverAnimationValue] = useHoverEffect({ disabled });
  const color = disabled ? colors.disabled : colors.primary;
  const textColor = disabled ? transparent(colors.onPrimary, 0.5) : colors.onPrimary;
  const backgroundColor = hoverAnimationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [color, transparent(color, shade)]
  });

  const { status, height } = useKeyboardStatus();

  const iOSKeyboardCompensation = IS_IOS && status ? height : 0;

  const sentryLabel = useMemo(
    () => sentryName ?? interpolateName('BottomButton', children),
    [children, sentryName]
  );

  const onPress = useCallback(
    (e: GestureResponderEvent) => {
      sentry.addBreadcrumb({ type: 'Interaction', message: `${sentryLabel} was pressed` });
      parentOnPress?.(e);
    },
    [parentOnPress, sentryLabel]
  );

  return (
    <ButtonContainer style={{ paddingBottom: iOSKeyboardCompensation }}>
      <TouchableRipple onPress={onPress} disabled={disabled} sentry-label={sentryLabel}>
        <Animated.View
          ref={ref}
          style={{
            backgroundColor,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            padding: Margin.Large
          }}
        >
          <Subheading color={textColor} transform={!IS_ANDROID ? 'uppercase' : undefined}>
            {children}
          </Subheading>
        </Animated.View>
      </TouchableRipple>
      {!status && <DarkPrimaryStrip height={bottom} disabled={disabled} shade={shade} />}
    </ButtonContainer>
  );
};

export default BottomButton;

const ButtonContainer = styled.View`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.surface};
`;
const DarkPrimaryStrip = styled.View<{ height: number; disabled?: boolean; shade: number }>`
  height: ${({ height }) => height}px;
  background-color: ${({ theme, disabled, shade }) =>
    disabled ? theme.colors.placeholder : colorShade(theme.colors.primary, shade)};
`;
