import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, InteractionManager } from 'react-native';
import { AsyncStorageKeys } from 'src/api';
import { Body, HoverButton, ModalDrawer, Title } from 'src/components';
import { ModalDrawerHandle } from 'src/components/ModalDrawer';
import { Margin, Size } from 'src/constants';
import { useInteractionFocusEffect, usePractice, useStorage } from 'src/hooks';
import { useTourGuideMaster } from 'src/module/TourGuide/TourGuideMaster';
import { TOUR } from 'src/module/TourGuide/model';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import PawHero from 'src/scenes/PatientList/PawHero';
import styled from 'styled-components/native';

const WHATS_NEW_SLIDE = {
  title: 'home:whatsNew.title',
  body: 'home:whatsNew.body',
  image: <PawHero />,
  navigator: Navigators.PET_STACK,
  screen: Screens.PATIENT_LIST,
  screenName: 'petList:title',
  feature: undefined,
  targetTours: [TOUR.PETS]
};

const CURRENT_WHATS_NEW_VERSION = 2;

const WhatsNew: React.FC = () => {
  const { title, body, navigator, screen, screenName, image, feature, targetTours } =
    WHATS_NEW_SLIDE;
  const { viewMode, colors } = useAppTheme();
  const { t } = useTranslation('home');
  const { isActivelyTouring, resetTour } = useTourGuideMaster();
  const navigation = useNavigation();
  const { data: practice } = usePractice();

  const modalRef = useRef<ModalDrawerHandle>(null);
  const exit = useRef(() => {
    modalRef.current?.dismiss();
  }).current;

  const [hasSeen, setWhatsNewViewed, isFetched] = useStorage<number>(
    AsyncStorageKeys.WHATS_NEW_VIEWED
  );

  const navigateToNewTourScreen = useCallback(async () => {
    await modalRef.current?.dismiss();
    await Promise.all(targetTours.map((tour) => resetTour(tour)));
    void InteractionManager.runAfterInteractions(() => {
      navigation.getParent()?.navigate(navigator, { screen });
    });
  }, [navigation, navigator, resetTour, screen, targetTours]);

  const notReady = isActivelyTouring || !isFetched;
  const canShow = useMemo(() => {
    if (notReady) return false;
    if (hasSeen === undefined || typeof hasSeen !== 'number') {
      setWhatsNewViewed(CURRENT_WHATS_NEW_VERSION);
      return false;
    }
    if (hasSeen < CURRENT_WHATS_NEW_VERSION)
      return !feature || !!practice?.selectedFeatures?.some((f) => f === feature);
    return false;
  }, [hasSeen, notReady, feature, practice?.selectedFeatures, setWhatsNewViewed]);

  useInteractionFocusEffect(() => {
    if (canShow) {
      modalRef.current?.show();
    }
  }, [isActivelyTouring, canShow, modalRef]);

  return (
    <ModalDrawer
      ref={modalRef}
      cardStyle={{ maxWidth: viewMode.narrowMaxContentWidth }}
      bottomButton={{
        onPress: navigateToNewTourScreen,
        label: t('whatsNew.goTo', { screenName: t(screenName) }),
        dismiss: true
      }}
      onOpen={() => setWhatsNewViewed(CURRENT_WHATS_NEW_VERSION)}
    >
      <Container>
        <Container>
          <StyledImageContainer pointerEvents={'box-none'}>{image}</StyledImageContainer>
          <TextContainer hasImage={!!image}>
            <StyledText as={Title}>{t(title)}</StyledText>
            <StyledText>{t(body)}</StyledText>
          </TextContainer>
        </Container>
      </Container>
      <StyledSkipButton buttonColor={colors.onSurface} onPress={exit} compact>
        {t('skip')}
      </StyledSkipButton>
    </ModalDrawer>
  );
};

export default WhatsNew;

const Container = styled.View`
  gap: ${Margin.ExtraLarge}px;
  margin-bottom: ${Margin.Large}px;
`;

const StyledImageContainer = styled(Animated.View)`
  flex-basis: ${({ theme }) => theme.viewMode.narrowMaxContentWidth}px;
  justify-content: center;
  align-content: center;
`;

const StyledText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;

const TextContainer = styled.View<{ hasImage: boolean }>`
  margin-top: ${({ hasImage }) => (hasImage ? 0 : Size.X3_L)}px;
  position: ${({ hasImage }) => (hasImage ? 'absolute' : 'relative')};
  padding-horizontal: ${Margin.Large}px;
  justify-content: flex-end;
  gap: ${Margin.Large}px;
  bottom: 0;
  width: 100%;
`;

const StyledSkipButton = styled(HoverButton)`
  position: absolute;
  top: ${Margin.ExtraLarge}px;
  left: ${Margin.ExtraLarge}px;
`;
