import { APP_ENV } from 'src/constants';
import { Config } from './Config';
import { debugConfig } from './config.debug';
import { prodConfig } from './config.production';
import { qaConfig } from './config.qa';

let config: Config;

if (APP_ENV === 'PROD') {
  config = prodConfig;
} else if (APP_ENV === 'DEV') {
  config = qaConfig;
} else {
  config = debugConfig;
}

if (__DEV__) {
  console.debug({ APP_ENV, config });
}

export default config;
