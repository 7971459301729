import _ from 'lodash';
import moment from 'moment-timezone';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Animated, Easing, StyleProp, ViewStyle } from 'react-native';
import { IS_WEB, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { isDefined } from 'src/utils';
import styled from 'styled-components/native';

interface BaseProps {
  isLoading?: boolean;
  width?: number | `${number}%`;
  height?: number | `${number}%`;
  borderRadius?: number;
  style?: StyleProp<ViewStyle>;
  children?: Exclude<ReactNode, 'string'>;
}

interface CircularProps extends BaseProps {
  size: number;
}

type Props = CircularProps | BaseProps;

const isCircular = (props: Props): props is CircularProps => {
  const tempProps = props as CircularProps;
  return isDefined(tempProps.size);
};

const Shimmer: React.FC<CircularProps | BaseProps> = ({
  isLoading = true,
  children,
  style,
  borderRadius: _borderRadius = Size.M,
  ...sizeProps
}) => {
  const { colors } = useAppTheme();
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    opacity.setValue(1);
    if (isLoading) {
      const animation = Animated.loop(
        Animated.sequence([
          Animated.timing(opacity, {
            toValue: 0.5,
            easing: Easing.quad,
            duration: moment.duration(0.5, 'second').asMilliseconds(),
            useNativeDriver: !IS_WEB
          }),
          Animated.timing(opacity, {
            toValue: 1,
            easing: Easing.quad,
            duration: moment.duration(0.5, 'second').asMilliseconds(),
            useNativeDriver: !IS_WEB
          })
        ])
      );
      animation.start();
      return () => {
        animation.stop();
      };
    }
  }, [opacity, isLoading]);

  const [width, height, borderRadius] = useMemo(() => {
    if (isCircular(sizeProps)) {
      return _.times(3, () => sizeProps.size);
    }
    return [sizeProps.width, sizeProps.height, _borderRadius];
  }, [_borderRadius, sizeProps]);

  if (isLoading) {
    return (
      <Animated.View
        style={[
          {
            width,
            height,
            borderRadius,
            backgroundColor: colors.placeholder,
            opacity
          },
          style
        ]}
        pointerEvents='none'
      >
        <Invisible>{children}</Invisible>
      </Animated.View>
    );
  }
  return <>{children}</>;
};

export default Shimmer;

const Invisible = styled.View`
  opacity: 0;
`;
