/**
 * This file is used to mock the firebase messaging module on the web.
 */

import { FirebaseApp, initializeApp, getApps } from 'firebase/app';
import {
  onMessage as _onMessage,
  getMessaging,
  Messaging,
  getToken as _getToken,
  Observer,
  MessagePayload,
  isSupported
} from 'firebase/messaging';
import { noop } from 'lodash';
import config from 'src/environment';

let firebase: FirebaseApp;
let messaging: Messaging;

const initialize = () => {
  if (getApps().length) return;
  void isSupported().then((supported) => {
    if (supported) {
      firebase = initializeApp(config.FIREBASE);
      messaging = getMessaging(firebase);
    }
  });
};

initialize();

export const getToken = async () => {
  const supported = await isSupported();
  return supported ? _getToken(messaging) : noop;
};

export const onMessage = (listener: Observer<MessagePayload>) => {
  void isSupported().then((supported) => {
    return supported ? _onMessage(messaging, listener) : noop;
  });
};
