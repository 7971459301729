import { useMemo } from 'react';
import { StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { Pressable } from 'react-native';
import { X } from 'react-native-feather';
import { Margin, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { fontStyles } from 'src/theme/globalStyles';
import useGoBack from 'src/hooks/useGoBack';

const useDefaultModalScreenOptions = (): StackNavigationOptions => {
  const { colors, viewMode } = useAppTheme();
  const goBack = useGoBack();
  return useMemo(
    () => ({
      headerShown: true,
      headerTitleAlign: 'center',
      headerTitleStyle: fontStyles.headline,
      headerTintColor: colors.onPrimary,
      headerStyle: { backgroundColor: colors.primary },
      headerShadowVisible: false,
      headerLeftContainerStyle: {
        paddingLeft: viewMode.horizontalInset
      },
      headerLeft: () => (
        <Pressable onPress={() => goBack()} style={{ paddingLeft: Margin.Medium }}>
          <X width={Size.L} color={colors.onPrimary} />
        </Pressable>
      ),
      headerRightContainerStyle: {
        paddingRight: viewMode.horizontalInset
      },
      cardStyle: {
        backgroundColor: colors.background
      },
      ...TransitionPresets.ModalTransition
    }),
    [colors, goBack, viewMode.horizontalInset]
  );
};

export default useDefaultModalScreenOptions;
