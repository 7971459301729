import { NavigatorScreenParams } from '@react-navigation/native';
import { Client } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';
import { PathConfig } from 'src/routes/types';
import MainStackParamsList, { MAIN_STACK_PATHS } from '../MainStack/ParamsList';
import { Navigators } from '../navigators';
import { Screens } from '../screens';

export type RootStackParamList = {
  [Screens.LANDING]?: {
    practice?: Practice['shortName'];
    email?: Client['email'];
  };
  [Screens.ACCEPT_TERMS]?: undefined;
  [Screens.RESET_PASSWORD_REQUIRED]?: undefined;
  [Navigators.MAIN_STACK]: NavigatorScreenParams<MainStackParamsList>;
  [Screens.PET_PAGE_DISABLED]: undefined;
  [Screens.PRACTICE_CLIENT_SEARCH]: undefined;
  [Screens.EMAIL_CONFIRMATION]?: {
    token?: string;
    multipleAccounts?: boolean;
    email?: string;
  };
  [Screens.BIOMETRIC_LOGIN_AUTH]?: undefined;
  [Screens.TOKEN_PASSWORD_RESET]?: {
    token: string;
  };
  [Screens.PUSH_NOTIFICATIONS_OPT_IN]?: undefined;
  [Screens.ONLINE_BOOKING]?: {
    practice?: string;
    token?: string;
    patientId?: string;
  };
  [Screens.SANDBOX]?: undefined;
};

export const ROOT_STACK_PATHS: PathConfig<RootStackParamList> = {
  screens: {
    [Navigators.MAIN_STACK]: MAIN_STACK_PATHS,
    [Screens.LANDING]: '',
    [Screens.ACCEPT_TERMS]: 'terms',
    [Screens.RESET_PASSWORD_REQUIRED]: 'reset-password-required',
    [Screens.PET_PAGE_DISABLED]: 'no-access',
    [Screens.PRACTICE_CLIENT_SEARCH]: 'practice-client-search',
    [Screens.EMAIL_CONFIRMATION]: 'email-confirmation',
    [Screens.BIOMETRIC_LOGIN_AUTH]: 'biometric-login',
    [Screens.TOKEN_PASSWORD_RESET]: 'password-reset',
    [Screens.PUSH_NOTIFICATIONS_OPT_IN]: 'notifications',
    [Screens.ONLINE_BOOKING]: 'online-booking',
    [Screens.SANDBOX]: 'sandbox'
  }
};

export default RootStackParamList;
