import React from 'react';
import { Title } from 'src/components';
import Logo from 'src/components/Icons/Logo';
import { IconSize, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

interface Props {
  displayName: string;
}
const LogoHeader: React.FC<Props> = ({ displayName }) => {
  const { colors } = useAppTheme();

  return (
    <LogoAndNameContainer>
      <Logo size={IconSize.L} color={colors.onPrimary} />
      <TextContainer>
        <Title verticalAlign='middle' color={colors.onPrimary}>
          {displayName}
        </Title>
      </TextContainer>
    </LogoAndNameContainer>
  );
};

export default LogoHeader;

const TextContainer = styled.View`
  margin-left: ${Size.X2_S}px;
  flex-shrink: 1;
  justify-content: center;
`;

const LogoAndNameContainer = styled.View`
  margin-bottom: ${Size.M}px;
  flex-direction: row;
  justify-content: center;
`;
