import React, { Suspense, useState } from 'react';
import { Shimmer, HeroBottom } from 'src/components';
import PatientSelector from 'src/components/PatientSelector';
import { Margin, Size } from 'src/constants';
import { fontStyles } from 'src/theme/globalStyles';
import NextAppointmentCard from './NextAppointmentCard';
import AppointmentCardLoading from './NextAppointmentCard/AppointmentCardLoading';
import RequestAppointmentCta from './RequestAppointmentCta';
import { StyledHeadline, StyledCard } from './shared';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from '../../tour';
import AllAppointmentsLoading from './AllAppointmentsLoading';
import useAppointments from '../hooks/useAppointments';
import { useSelectedPatient } from 'src/providers/SelectedPatientProvider';
import { useTourGuideMaster } from 'src/module/TourGuide/TourGuideMaster';
import { LayoutRectangle, View } from 'react-native';

const HEADER_INDEX = 0;

const HeroSection: React.FC = () => {
  const { t } = useTranslation('appointments');
  const { isActivelyTouring } = useTourGuideMaster();
  const { colors } = useAppTheme();
  const { selectedPatient } = useSelectedPatient();
  const { isLoading } = useAppointments({
    selectedPatient: selectedPatient?.patientId,
    suspense: false,
    enabled: !isActivelyTouring
  });

  const [layout, setLayout] = useState<LayoutRectangle | null>(null);

  return (
    <View onLayout={(e) => setLayout(e.nativeEvent.layout)}>
      <HeroContainer>
        <StyledView>
          <GuideElement
            id={STEPS.REQUEST}
            body={t('list-tour.request')}
            itemIndex={HEADER_INDEX}
            sectionIndex={HEADER_INDEX}
            viewOffset={layout?.height}
          >
            <RequestAppointmentCta />
          </GuideElement>
          <StyledHeadline color={colors.onPrimary}>{t('nextAppointment')}</StyledHeadline>
          <Suspense
            fallback={
              <StyledCard>
                <AppointmentCardLoading />
              </StyledCard>
            }
          >
            <GuideElement
              id={STEPS.NEXT}
              body={t('list-tour.next')}
              itemIndex={HEADER_INDEX}
              sectionIndex={HEADER_INDEX}
              viewOffset={layout?.height}
            >
              <NextAppointmentCard />
            </GuideElement>
          </Suspense>
          <PetSelectSuspenseContainer>
            <Suspense fallback={<Shimmer height={fontStyles.body.lineHeight} />}>
              <PatientSelector typeName={t('title')} />
            </Suspense>
          </PetSelectSuspenseContainer>
        </StyledView>
      </HeroContainer>
      <HeroBottom fill={colors.primary} width='100%' height={Size.M} />
      {isLoading && <AllAppointmentsLoading />}
    </View>
  );
};

export default HeroSection;

const HeroContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.primary};
  padding-vertical: ${Margin.Small}px;
  border-radius: 0px;
`;

const StyledView = styled.View`
  flex-grow: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  padding-vertical: ${Size.S}px;
  padding-horizontal: ${Size.X3_S}px;
  gap: ${Size.S}px;
`;

const PetSelectSuspenseContainer = styled.View`
  margin-top: ${Margin.Medium}px;
`;
