import { NavigationContainer } from '@react-navigation/native';
import React from 'react';
import { View, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Title, BottomButton, MasterView, TextInput, Body } from 'src/components';
import { FallBackProps } from 'src/components/ErrorBoundary';
import sentry from 'src/utils/sentry';
import { AsyncStorageKeys, QueryKeys } from 'src/api';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useTranslation } from 'react-i18next';
import {} from 'react-native-paper';
import { fullNameAndTitle, queryClient, storage } from 'src/utils';
import { Client } from 'src/interfaces';
import { prepareUserMetadata, mapPropertiesToLabels } from './helper';
const AppError: React.FC<FallBackProps> = ({ resetError, eventId }) => {
  const { top } = useSafeAreaInsets();
  const { colors } = useAppTheme();
  const { t } = useTranslation('appError');

  const [comments, setComments] = React.useState('');

  return (
    <NavigationContainer>
      <View style={{ flex: 1, backgroundColor: colors.background }}>
        <ScrollView style={{ marginTop: top, flex: 1 }}>
          <MasterView>
            <Title>{t('title')}</Title>
            <Body>{t('headline')}</Body>
            <TextInput
              label={t('feedbackLabel')}
              multiline
              value={comments}
              onChangeText={setComments}
            />
          </MasterView>
        </ScrollView>
        <BottomButton
          onPress={async () => {
            resetError();
            if (!comments.trim().length) return;
            const token = await storage.getTypedItem<string>(AsyncStorageKeys.TOKEN);
            const user = queryClient.getQueryData<Client>([QueryKeys.CLIENT, token]);
            const selectUserProperties = prepareUserMetadata(user);
            const message = mapPropertiesToLabels({ comments, ...selectUserProperties });
            sentry.captureUserFeedback({
              event_id: eventId,
              comments: message,
              email: user?.email,
              name: fullNameAndTitle(user) ?? 'Anonymous'
            });
          }}
        >
          {t(!comments.length ? 'reset' : 'sendAndReset')}
        </BottomButton>
      </View>
    </NavigationContainer>
  );
};

export default AppError;
