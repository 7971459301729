import { HoverButton, HoverCard, Row, Subheading } from 'src/components';
import { Margin, Size } from 'src/constants';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

export const StyledRow = styled(Row)`
  margin-vertical: ${Margin.Medium}px;
  margin-horizontal: ${Margin.Large}px;
  padding-vertical: ${Size.XS}px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
  justify-content: space-between;
`;

export const TextContainer = styled.View`
  flex: 1;
  gap: ${Margin.Medium}px;
  margin-right: ${Margin.Small}px;
`;

export const StyledHoverButton = styled(HoverButton)`
  max-height: ${Size.X2_L}px;
  margin-left: ${Margin.Medium}px;
  padding-vertical: ${Size.XS}px;
  padding-left: ${Margin.Large}px;
  padding-right: ${Margin.Medium}px;
`;

export const StyledSectionTitle = styled(Subheading)`
  padding-horizontal: ${Margin.Large}px;
  margin-top: ${Margin.Medium}px;
`;

export const StyledHoverCard = styled(HoverCard).attrs({
  hideShadow: true
})`
  background-color: ${({ theme }) => theme.colors.background};
`;
