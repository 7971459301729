import { AxiosError } from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useRef } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getActiveClientList, QueryKeys } from 'src/api';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';

interface Options
  extends Omit<
    UseQueryOptions<AvailablePractice[], AxiosError, AvailablePractice[], QueryKey>,
    'queryFn' | 'queryKey'
  > {
  beforeFetch?: () => void;
}

export const availablePracticesOptions = (email: string, options?: Options) => ({
  queryKey: [QueryKeys.CHECK_EMAIL, email],
  queryFn: async ({ signal }: { signal?: AbortSignal }) => {
    try {
      options?.beforeFetch?.();
      return getActiveClientList({ email, signal });
    } catch {
      return [];
    }
  },
  select: (d: AvailablePractice[]) => _.sortBy(d, 'practiceName'),
  staleTime: moment.duration(5, 'seconds').asMilliseconds(),
  cacheTime: Infinity
});

const useAvailablePractices = (email: string, options?: Options) => {
  const optionRef = useRef(options?.beforeFetch);
  useEffect(() => {
    optionRef.current = options?.beforeFetch;
  }, [options?.beforeFetch]);
  return useQuery<AvailablePractice[], AxiosError, AvailablePractice[], QueryKey>({
    ...availablePracticesOptions(email, {
      ...options,
      beforeFetch: () => {
        optionRef.current?.();
      }
    }),
    ...options
  });
};

export default useAvailablePractices;
