import { useQuery } from 'react-query';
import { QueryKeys, getPracticeInfo } from 'src/api';
import Practice from 'src/interfaces/api/Practice';
import type { Options } from './types';
import { ApiPracticeInfo } from 'src/interfaces';

const usePracticeInfo = (practiceShortName?: Practice['shortName'], options?: Options) => {
  return useQuery({
    queryKey: [QueryKeys.PRACTICE_INFO, practiceShortName],
    queryFn: async (): Promise<ApiPracticeInfo | undefined> => {
      if (!practiceShortName) {
        return;
      }
      return await getPracticeInfo(practiceShortName);
    },
    enabled: !!practiceShortName,
    ...options
  });
};

export default usePracticeInfo;
