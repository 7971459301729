import { downloadImageAsync, logCache } from './expoStorage';
import { documentDirectory } from 'expo-file-system';

export const imageCache: Record<string, Promise<string>> = {};

/**
 * Checks if an image has been downloaded, if not, it initiates the download process.
 * @param {string} url
 * @returns {Promise<string>} If the image has been stored or successfully downloaded
 */

const getImage = async (key: string, uri: string) => {
  if (!documentDirectory) throw new Error('Document directory not found');

  if (imageCache[key] === undefined) {
    logCache('Downloading image', `did not find ${key} in`, Object.keys(imageCache));
    imageCache[key] = downloadImageAsync(key, uri);
  } else {
    logCache(`Image ${key} already downloaded`);
  }

  return imageCache[key];
};

export default getImage;
