import { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components/native';
import LeftNavBar from './LeftNavBar';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useBookingState } from '../BookingProvider';
import { Margin, Size } from 'src/constants';
import { LEFT_HERO_HEIGHT, LEFT_HERO_WIDTH, MAX_CARD_HEIGHT, MAX_CARD_WIDTH } from '../constants';
import { containers } from 'src/theme/globalStyles';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { BottomButton, CPrimaryButton, HoverButton } from 'src/components';
import { Divider } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { BOOKING_STEPS, STEP_INFO } from '../BookingProvider/helpers';
import { ErrorText } from './shared';
import useGoBack from 'src/hooks/useGoBack';
import { StackNavigationProp } from '@react-navigation/stack';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { ChevronLeft, X } from 'react-native-feather';
import { LayoutAnimation, Pressable } from 'react-native';
import TopNavBar from './TopNavBar';
import getOnlineBookingHero from 'src/utils/theme/getOnlineBookingHero';
import getOnlineBookingBackground from 'src/utils/theme/getOnlineBookingBackground';
import { useAuth } from 'src/providers/AuthProvider';

const LayoutController: React.FC<PropsWithChildren> = ({ children }) => {
  const exit = useGoBack();
  const { setOptions } =
    useNavigation<StackNavigationProp<RootStackParamList, Screens.ONLINE_BOOKING>>();
  const { t } = useTranslation('common');
  const {
    practiceInfo,
    values,
    bookingState: { current: state, visited },
    advance,
    goBack,
    goToStep,
    errors,
    isSubmitting
  } = useBookingState();
  const { isLoggedIn } = useAuth();

  const hasCompleted = !!visited[BOOKING_STEPS.CONFIRMATION];
  const {
    previewThemeSet,
    removePreview,
    colors,
    viewMode: { isMobile, isWeb }
  } = useAppTheme();
  useFocusEffect(
    useCallback(() => {
      if (practiceInfo.theme.custom) {
        previewThemeSet(practiceInfo.theme.custom);
        return removePreview;
      }
    }, [practiceInfo.theme.custom, previewThemeSet, removePreview])
  );

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [state]);

  const activeState = useMemo(() => STEP_INFO[state], [state]);

  const disableTabNav =
    !!errors.additionalInfo?.images || isSubmitting || state === BOOKING_STEPS.STATUS;
  const disableNav = !activeState.validator(values) || disableTabNav;

  const showBackButton = ![BOOKING_STEPS.STATUS, BOOKING_STEPS.CLIENT_INFO].includes(state);
  const showReturnToConfirmation = hasCompleted && state !== BOOKING_STEPS.CONFIRMATION;

  useEffect(() => {
    if (isMobile) {
      setOptions({
        headerLeft: () =>
          state === BOOKING_STEPS.CLIENT_INFO || state === BOOKING_STEPS.STATUS ? (
            <Pressable onPress={exit} style={{ paddingLeft: Margin.Medium }}>
              <X width={Size.L} color={colors.onPrimary} />
            </Pressable>
          ) : (
            <Pressable
              onPress={goBack}
              style={{ paddingLeft: Margin.Medium }}
              disabled={disableTabNav}
            >
              <ChevronLeft
                width={Size.L}
                color={disableTabNav ? colors.disabled : colors.onPrimary}
              />
            </Pressable>
          ),
        title: t(`onlineBooking:${activeState.title}`)
      });
    }
  }, [
    disableTabNav,
    goBack,
    state,
    setOptions,
    isMobile,
    showBackButton,
    exit,
    colors,
    t,
    activeState.title
  ]);

  const goToConfirmation = () => {
    goToStep(BOOKING_STEPS.CONFIRMATION);
  };
  if (isMobile) {
    return (
      <>
        <TopNavBar disableNav={disableTabNav} />
        {children}
        <BottomButton
          disabled={disableNav && state !== BOOKING_STEPS.STATUS}
          onPress={
            state === BOOKING_STEPS.STATUS
              ? exit
              : state === BOOKING_STEPS.CONFIRMATION || !hasCompleted
              ? advance
              : goToConfirmation
          }
        >
          {state === BOOKING_STEPS.STATUS
            ? t('onlineBooking:returnToPetPage')
            : state === BOOKING_STEPS.CONFIRMATION
            ? t('submit')
            : hasCompleted
            ? t('onlineBooking:confirmation.return')
            : t('continue')}
        </BottomButton>
      </>
    );
  } else {
    return (
      <>
        <FullScreen>
          <BackgroundPattern
            source={getOnlineBookingBackground(practiceInfo.appointmentBooking?.backgroundPattern)}
          />
          <LeftNavBar disableNav={disableTabNav} />
          <Container>
            <ContentCard style={[containers.shadow]}>
              {/* Todo: replace default with practiceConfig */}
              {isWeb && (
                <LeftHero
                  source={getOnlineBookingHero(practiceInfo.appointmentBooking?.leftImage)}
                />
              )}
              <OuterContainer>
                {children}
                <Divider />
                <BottomNavigation>
                  {showBackButton && (
                    <CPrimaryButton mode='outlined' onPress={goBack}>
                      {t('back')}
                    </CPrimaryButton>
                  )}

                  {state === BOOKING_STEPS.STATUS && !!isLoggedIn && (
                    <CPrimaryButton disabled={isSubmitting} onPress={exit}>
                      {t('onlineBooking:returnToPetPage')}
                    </CPrimaryButton>
                  )}
                  {state !== BOOKING_STEPS.STATUS && (
                    <CPrimaryButton disabled={disableNav} onPress={advance}>
                      {state === BOOKING_STEPS.CONFIRMATION ? t('submit') : t('continue')}
                    </CPrimaryButton>
                  )}

                  {!!errors.clientInfo?.acceptConditions && (
                    <ErrorText>
                      {t(`onlineBooking:${errors.clientInfo?.acceptConditions}`)}
                    </ErrorText>
                  )}
                  {!!errors.additionalInfo?.images && (
                    <ErrorText>{errors.additionalInfo?.images}</ErrorText>
                  )}
                  {showReturnToConfirmation && state !== BOOKING_STEPS.STATUS && (
                    <HoverButton
                      disabled={disableNav}
                      style={{ marginLeft: 'auto' }}
                      labelStyle={{ textAlign: 'center' }}
                      onPress={goToConfirmation}
                    >
                      {t('onlineBooking:confirmation.returnNewline')}
                    </HoverButton>
                  )}
                </BottomNavigation>
              </OuterContainer>
            </ContentCard>
          </Container>
        </FullScreen>
      </>
    );
  }
};

export default LayoutController;

const BackgroundPattern = styled.ImageBackground.attrs({
  resizeMode: 'repeat'
})`
  opacity: 0.2;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LeftHero = styled.Image`
  width: ${LEFT_HERO_WIDTH}px;
  height: ${LEFT_HERO_HEIGHT}px;
`;

const FullScreen = styled.View`
  flex: 1;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const OuterContainer = styled.View`
  flex: 1;
  align-self: stretch;
  max-width: ${MAX_CARD_HEIGHT}px;
`;

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${Size.X3_L}px;
`;

const ContentCard = styled.View`
  width: 100%;
  max-width: ${({ theme }) =>
    theme.viewMode.isWeb ? MAX_CARD_WIDTH : MAX_CARD_WIDTH - LEFT_HERO_WIDTH}px;
  height: 100%;
  ${({ theme: { viewMode } }) =>
    viewMode.isTablet && !viewMode.isLandscape ? '' : `max-height: ${MAX_CARD_HEIGHT}px;`}
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.roundness}px;
  overflow: hidden;
`;

const BottomNavigation = styled.View`
  flex-direction: row;
  align-items: center;
  padding: ${Margin.ExtraLarge}px;
  gap: ${Margin.Medium}px;
`;
