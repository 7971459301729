import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Patient, Prescription } from 'src/interfaces';
import { useBoolean, usePrescriptions, useRequestSetting } from 'src/hooks';
import moment from 'moment-timezone';
import ItemDetail from './ItemDetail';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { useTranslation } from 'react-i18next';
import { CallToAction, CaughtUpText, FullActivityIndicator, ListContainer } from './shared';
import HeaderToggle from './HeaderToggle';
import { LayoutAnimation } from 'react-native';
import RefillModal, { RefillModalHandle } from 'src/components/RefillModal';
import { HoverButton, HoverCard } from 'src/components';
import { isPatientActive, isPrescriptionValid } from 'src/utils';
import { Prescriptions } from 'src/components/kit/ABIcons';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import usePaginatedPatients from 'src/hooks/react-query/usePaginatedPatients';

const MAX_ITEMS = 3;

interface Props {
  patientId?: Patient['patientId'];
  maxItems?: number;
}
const PrescriptionsCard: React.FC<Props> = ({ patientId, maxItems = MAX_ITEMS }) => {
  const { i18n, t } = useTranslation('home');
  const { colors } = useAppTheme();
  const { value: isOpen, toggle } = useBoolean(true);
  const { navigate } = useNavigation<NavigationProp<MainStackParamsList>>();
  const [count, setCount] = useState(0);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>();
  const { data, isLoading: prescriptionsLoading } = usePrescriptions({
    selectedPatientId: patientId,
    suspense: false
  });

  const { data: patients } = usePaginatedPatients({});

  const { data: requestSetting, isLoading: requestLoading } = useRequestSetting();

  const isLoading = prescriptionsLoading || requestLoading;

  useEffect(() => {
    const current = data.filter(
      (v) =>
        isPrescriptionValid(v) &&
        (!patientId || patientId === v.patientId) &&
        patients.some((p) => p.patientId === v.patientId && isPatientActive(p))
    );
    setCount(current.length);
    setPrescriptions([...current.slice(0, maxItems)]);
  }, [data, patientId, maxItems, patients]);

  const refillModalRef = useRef<RefillModalHandle>(null);

  return (
    <>
      <HoverCard>
        <HeaderToggle
          icon={({ size, color }) => <Prescriptions color={color} width={size} height={size} />}
          label={t('prescriptions')}
          toggle={() => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            toggle();
          }}
          count={count}
        />
        {isOpen && !isLoading && (
          <Suspense fallback={<FullActivityIndicator />}>
            <ListContainer>
              {prescriptions?.map((prescriptions) => (
                <ItemDetail
                  patientId={prescriptions.patientId}
                  description={prescriptions.description}
                  date={t('rxDate', {
                    date: moment
                      .utc(prescriptions.prescribedAt)
                      .locale(i18n.language)
                      .format('MM/DD/YYYY')
                  })}
                  key={prescriptions.prescriptionId}
                  action={
                    <>
                      {!requestSetting?.refillRequestsDisabled && (
                        <HoverButton
                          uppercase={false}
                          mode='outlined'
                          buttonColor={colors.onSurface}
                          onPress={() => refillModalRef.current?.request?.(prescriptions)}
                        >
                          {t('refill')}
                        </HoverButton>
                      )}
                    </>
                  }
                  actionCondition={isPatientActive}
                />
              ))}
            </ListContainer>
          </Suspense>
        )}
        {isOpen && !isLoading && !prescriptions?.length && (
          <CaughtUpText>
            {t('noItems', { itemType: t('prescriptions').toLowerCase() })}
          </CaughtUpText>
        )}
        {isOpen && isLoading && <FullActivityIndicator />}
        {isOpen && (
          <CallToAction onPress={() => navigate(Screens.PRESCRIPTIONS, { id: patientId })}>
            {t('viewAll')}
          </CallToAction>
        )}
      </HoverCard>
      <RefillModal ref={refillModalRef} />
    </>
  );
};

export default PrescriptionsCard;
