import {
  configureFonts,
  MD2DarkTheme as DarkTheme,
  MD2LightTheme as LightTheme
} from 'react-native-paper';
import { Size } from 'src/constants';
import { viewModeDefaults } from 'src/hooks/useViewMode';
import { colorShade } from 'src/utils/colorValues';
import palette from './palette';
import { themeFontsConfig } from './typography';
import { AppTheme } from 'src/types/paper';

export const defaultLightTheme: AppTheme = {
  ...LightTheme,
  roundness: Size.X3_S,
  colors: {
    ...LightTheme.colors,
    primary: palette.AB_ELECTRIC_BLUE,
    onPrimary: palette.AB_BRIGHT_WHITE,
    warn: palette.WARN_YELLOW,
    confirmation: palette.GREEN_ACCENT,
    minorAccent: palette.PURPLE,
    text: palette.ALLY_BLACK,
    opaquePlaceholder: palette.SOLID_GREY,
    disabled: palette.BLACK_OPACITY_26,
    placeholder: palette.BLACK_OPACITY_54,
    error: palette.RED_WARNING,
    onSurface: palette.ALLY_BLACK
  },
  fonts: configureFonts({
    config: {
      ios: themeFontsConfig,
      android: themeFontsConfig,
      web: themeFontsConfig
    },
    isV3: false
  }),
  viewMode: viewModeDefaults
};

export const defaultDarkTheme: AppTheme = {
  ...DarkTheme,
  roundness: Size.X3_S,
  colors: {
    ...DarkTheme.colors,
    surface: palette.ALLY_SURFACE_BLACK,
    primary: colorShade(palette.AB_ELECTRIC_BLUE, 0.5),
    onPrimary: palette.AB_BRIGHT_WHITE,
    warn: palette.WARN_YELLOW,
    confirmation: palette.GREEN_ACCENT_DARK,
    minorAccent: palette.PURPLE,
    opaquePlaceholder: palette.SOLID_GREY,
    disabled: palette.WHITE_OPACITY_38,
    placeholder: palette.WHITE_OPACITY_54
  },
  fonts: configureFonts({
    config: {
      ios: themeFontsConfig,
      android: themeFontsConfig,
      web: themeFontsConfig
    },
    isV3: false
  }),
  viewMode: viewModeDefaults
};
