import React from 'react';
import { Body } from 'src/components';
import { StyledHeader, HeadingIconContainer, StyledHeading, Badge } from './shared';
import { IconProps } from 'src/components/Icons';
import { IconSize } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  icon: (props: IconProps) => React.ReactElement;
  toggle: () => void;
  count: number | undefined;
  label: string;
}
const HeaderToggle: React.FC<Props> = ({ toggle, count = 0, label, icon: Icon }) => {
  const { colors } = useAppTheme();
  return (
    <StyledHeader onPress={toggle}>
      <>
        <HeadingIconContainer>
          <Icon color={colors.onSurface} size={IconSize.S} />
        </HeadingIconContainer>
        <StyledHeading>{label}</StyledHeading>
        {!!count && (
          <Badge>
            <Body color={colors.onSurface}>{count}</Body>
          </Badge>
        )}
      </>
    </StyledHeader>
  );
};

export default HeaderToggle;
