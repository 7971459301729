import React, { PropsWithChildren } from 'react';
import { View, ViewProps } from 'react-native';
import styled from 'styled-components/native';

interface Props extends PropsWithChildren, ViewProps {
  topColor: string;
  bottomColor: string;
}

const TwoColorView: React.FC<Props> = ({ topColor, bottomColor, children, ...props }) => (
  <FullContainer>
    {children}
    <AbsoluteContainer>
      <View style={{ flex: 1, backgroundColor: topColor }} />
      <View style={{ flex: 1, backgroundColor: bottomColor }} />
    </AbsoluteContainer>
  </FullContainer>
);

export default TwoColorView;

const FullContainer = styled.View`
  flex: 1;
  align-self: center;
  width: 100%;
  margin-horizontal: auto;
  position: relative;
`;

const AbsoluteContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
