import { FAB } from 'react-native-paper';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

export const StyledFAB = styled(FAB).attrs(({ theme }) => ({
  color: theme.colors.onPrimary,
  size: 'small'
}))`
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const FormSection = styled.View`
  gap: ${Size.S}px;
`;
