import { t } from 'i18next';
import moment from 'moment';
import i18n from 'src/i18n';
import { Patient } from 'src/interfaces';

export const basicInformation = (patient: Patient): Array<{ name: string; value: string }> => [
  { name: t('patientProfile:status'), value: patient.status || t('unknown', { ns: 'common' }) },
  { name: t('petID:breed'), value: patient.breed || t('unknown', { ns: 'common' }) },
  { name: t('petID:sex'), value: patient.sex || t('unknown', { ns: 'common' }) },
  {
    name: t('petID:dob'),
    value: patient.birthdate
      ? moment(patient.birthdate).locale(i18n.language).format('MM/DD/YYYY')
      : t('unknown', { ns: 'common' })
  },
  {
    name: t('petID:weight'),
    value: patient.weight
      ? `${patient.weight} ${patient.weightUnit || t('pounds')}`
      : t('unknown', { ns: 'common' })
  },
  {
    name: t('patientProfile:microchipTag'),
    value: patient.microchipNumber || t('unknown', { ns: 'common' })
  },
  {
    name: t('patientProfile:rabiesTag'),
    value: patient.rabiesTag || t('unknown', { ns: 'common' })
  }
];

export const additionalInformation = (patient: Patient): Array<{ name: string; value: string }> => [
  {
    name: t('patientProfile:insuranceProvider'),
    value: patient.insuranceProvider || t('unknown', { ns: 'common' })
  },
  {
    name: t('patientProfile:policyNumber'),
    value: patient.policyNumber || t('unknown', { ns: 'common' })
  },
  { name: t('patientProfile:diet'), value: patient.diet || t('unknown', { ns: 'common' }) },
  {
    name: t('patientProfile:medicalConditions'),
    value: patient.medicalConditions || t('unknown', { ns: 'common' })
  }
];
