export enum BOOKING_STEPS {
  CLIENT_INFO,
  PATIENT_INFO,
  NOTIFICATION_SETTINGS,
  ADDITIONAL_INFO,
  SELECT_APPOINTMENT,
  CONFIRMATION,
  STATUS
}

export enum STEP_STATUS {
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR'
}

export enum SUBMISSION_STATE {
  SUBMITTING = 'SUBMITTING',

  MANUAL = 'MANUAL',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',

  UNAVAILABLE = 'UNAVAILABLE',
  INVALID = 'INVALID'
}
