import React, { useMemo } from 'react';
import { Image } from 'react-native';
import { Body, Headline, HoverCard } from 'src/components';
import { useBookingState } from '../BookingProvider';
import { ContentContainer, MainText } from './shared';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { IconSize, IS_WEB, Margin } from 'src/constants';
import AnimalIcon from 'src/components/Icons/Animal';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { FlatList } from 'react-native-gesture-handler';
import { PatientInfo } from '../BookingProvider/model';
import Color from 'color';
import { partition } from 'lodash';
import { cleanUrl } from 'src/utils';

const NUM_COLS = 2;
const SelectPatient: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const { colors, viewMode } = useAppTheme();
  const {
    values: { patientInfo },
    clientPatientQuery,
    setFieldValue,
    setFieldError,
    initialValues
  } = useBookingState();

  const reorderedPatientList = useMemo(() => {
    if (!clientPatientQuery.data) return [];
    const [initial, rest] = partition(
      clientPatientQuery.data.patients,
      (p) => p.patientId === initialValues.patientInfo?.patientId
    );
    return [newPatient, ...initial, ...rest];
  }, [clientPatientQuery.data, initialValues.patientInfo?.patientId]);

  return (
    <>
      <FlatList<PatientInfo>
        ListHeaderComponent={
          <ContentContainer>
            <MainText as={Headline}>{t('selectPet.title')}</MainText>
            <MainText>{t('selectPet.description')}</MainText>
          </ContentContainer>
        }
        contentContainerStyle={{
          height: IS_WEB ? 0 : undefined,
          paddingVertical: Margin.ExtraLarge,
          paddingHorizontal: viewMode.isMobile ? Margin.Medium : Margin.ExtraLarge
        }}
        ListHeaderComponentStyle={{ marginBottom: Margin.ExtraLarge }}
        data={reorderedPatientList}
        numColumns={NUM_COLS}
        renderItem={({ item: patient }) => {
          const isSelected =
            !!patientInfo?.patientId && patientInfo?.patientId === patient.patientId;

          return (
            <CardContainer key={patient.name}>
              <Card
                disabled={isSelected}
                isSelected={isSelected}
                onPress={async () => {
                  await setFieldValue('patientInfo', patient);
                  setFieldError('patientInfo', undefined);
                }}
              >
                <IconContainer>
                  {patient?.hasPhoto ? (
                    <Image
                      source={{ uri: cleanUrl(patient.photo.urls.original) }}
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    <AnimalIcon
                      species={patient.species}
                      color={colors.primary}
                      size={isSelected ? IconSize.XXL : IconSize.XL}
                    />
                  )}
                </IconContainer>
                <PatientLabelContainer>
                  <PatientName>
                    {patient.isNewPatient ? t('selectPet.addNewPet') : patient.name}
                  </PatientName>
                </PatientLabelContainer>
              </Card>
            </CardContainer>
          );
        }}
      />
    </>
  );
};

const newPatient: PatientInfo = {
  name: '',
  species: '',
  breed: '',
  age: '',
  sex: '',
  isNewPatient: true,
  hasPhoto: undefined
};

export default SelectPatient;

const Card = styled(HoverCard)<{ isSelected?: boolean }>`
  aspect-ratio: ${({ theme }) => (theme.viewMode.isMobile ? 1 : 1.18)};
  border: ${Margin.Small}px solid ${({ isSelected, theme }) =>
  isSelected ? theme.colors.primary : 'transparent'}};
  background-color: ${({ theme }) => theme.colors.surface}};
`;

const CardContainer = styled.View`
  flex-basis: 50%;
  padding: ${Margin.Medium}px;
`;

const IconContainer = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${({ theme }) => Color(theme.colors.primary).alpha(0.1).hexa()};
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
`;

const PatientLabelContainer = styled.View`
  flex-direction: row;
  gap: ${Margin.Small}px;
  padding: ${Margin.Medium}px ${Margin.Large}px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-bottom-left-radius: ${({ theme }) => theme.roundness}px;
  border-bottom-right-radius: ${({ theme }) => theme.roundness}px;
`;

const PatientName = styled(Body).attrs({ numberOfLines: 1 })`
  flex: 1;
  color: ${({ theme }) => theme.colors.onSurface};
`;
