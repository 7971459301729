import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HoverButton, Subheading } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Container, ContentContainer, StyledButtonRow } from './style';
import * as LocalAuthentication from 'expo-local-authentication';
import { secureStoreGetSignInCredentials } from 'src/providers/AuthProvider/helper';
import { AuthStatus, useAuth } from 'src/providers/AuthProvider';
import { ActivityIndicator } from 'react-native-paper';
import { layout } from 'src/theme/globalStyles';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { IconSize } from 'src/constants';
import FaceRecognition from 'src/components/Icons/FaceRecognition';
import useBiometrics from 'src/hooks/useBiometrics';
import Toast from 'react-native-toast-message';
import { useNavigation } from '@react-navigation/native';

const MAX_RETRY_ATTEMPTS = 2;

const BiometricLogin: React.FC = () => {
  const { t } = useTranslation('biometricLogin');
  const { colors } = useAppTheme();
  const { canGoBack, goBack } = useNavigation();

  const { logIn, logOut, setError, setAuthStatus, isLoggedIn } = useAuth();
  const [retryAttempts, setRetryAttempts] = useState(0);

  const { supportsFaceRecognition } = useBiometrics();

  const login = useRef(
    useCallback(async () => {
      const credentials = await secureStoreGetSignInCredentials();
      try {
        if (credentials) {
          const result = await LocalAuthentication.authenticateAsync();
          if (result.success) {
            if (!isLoggedIn) {
              const loginResult = await logIn(credentials);
              if (!!loginResult && loginResult.error) {
                throw new Error(loginResult.error);
              }
            } else if (canGoBack()) goBack();
            setAuthStatus(AuthStatus.AUTHENTICATED);
          } else {
            setRetryAttempts((i) => i + 1);
            if (retryAttempts === MAX_RETRY_ATTEMPTS) {
              throw new Error(t('maxAttemptsReached'));
            }
          }
        } else {
          throw new Error(t('loginError'));
        }
      } catch (e) {
        Toast.show({
          type: 'error',
          text1: t('loginError')
        });
        await logOut(undefined);
        setError(e as Error);
      }
    }, [canGoBack, goBack, isLoggedIn, logIn, logOut, retryAttempts, setAuthStatus, setError, t])
  );

  useEffect(() => {
    if (retryAttempts === 0) void login.current();
  }, [retryAttempts]);

  if (retryAttempts === 0)
    return <ActivityIndicator color={colors.onPrimary} style={layout.flex1} size='large' />;

  return (
    <Container>
      <ContentContainer>
        {supportsFaceRecognition ? (
          <FaceRecognition size={IconSize.L} color={colors.onPrimary} />
        ) : (
          <Icon name='fingerprint' size={IconSize.L} color={colors.onPrimary} />
        )}
        <Subheading color={colors.onPrimary}>{t('title')}</Subheading>
      </ContentContainer>
      <StyledButtonRow justify='space-between'>
        <HoverButton mode='contained' onPress={async () => logOut(undefined)}>
          {t('common:logout')}
        </HoverButton>
        <HoverButton mode='contained' onPress={login.current}>
          {t('tryAgain')}
        </HoverButton>
      </StyledButtonRow>
    </Container>
  );
};

export default BiometricLogin;
