import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Body } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { StyledTitle, HelpCenterHoverCard, Link, StyledHoverButton } from '../style';
import { View } from 'react-native';
import { ALLY_SUPPORT_EMAIL, IconSize, Margin } from 'src/constants';
import ANT from 'react-native-vector-icons/AntDesign';
import FA5 from 'react-native-vector-icons/FontAwesome5';
import { useNavigation } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import { Navigators } from 'src/routes/stacks/navigators';
import openEmailToAlly from 'src/utils/openEmailToAlly';
import { Weight } from 'src/theme';
interface Props {
  title?: string;
  body?: string;
  showButtons?: boolean;
}
const HelpCenterCard: React.FC<Props> = ({ title, body, showButtons }) => {
  const { t } = useTranslation('helpCenter');
  const { colors } = useAppTheme();
  const { navigate } = useNavigation();

  return (
    <>
      <HelpCenterHoverCard>
        {!!title && (
          <StyledTitle>
            <Trans
              i18nKey={title}
              components={{
                bold: <Body color={colors.onSurface} fontWeight={Weight.BOLD} />
              }}
            />
          </StyledTitle>
        )}
        {!!body && (
          <StyledTitle>
            <Trans
              i18nKey={body}
              values={{ ourEmail: ALLY_SUPPORT_EMAIL, helpCenter: t('helpCenter') }}
              components={{
                contactUs: <Link onPress={async () => await openEmailToAlly()} />
              }}
            />
          </StyledTitle>
        )}
        {showButtons && (
          <>
            <View style={{ gap: Margin.Medium }}>
              <StyledHoverButton
                onPress={async () =>
                  navigate(Navigators.MAIN_STACK, { screen: Screens.CONTACT_US })
                }
                icon={<FA5 name='hospital' color={colors.onPrimary} size={IconSize.S} />}
              >
                <Trans i18nKey={t('contactMyVetText')} />
              </StyledHoverButton>
            </View>
            <View style={{ gap: Margin.Medium }}>
              <StyledHoverButton
                onPress={async () => await openEmailToAlly()}
                icon={<ANT name='customerservice' color={colors.onPrimary} size={IconSize.S} />}
              >
                <Trans i18nKey={t('contactPetPageSupportText')} />
              </StyledHoverButton>
            </View>
          </>
        )}
      </HelpCenterHoverCard>
    </>
  );
};

export default HelpCenterCard;
