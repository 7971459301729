import isDefined from '../isDefined';

const formatPhoneNumber = (phoneNumber: string): string | null => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (isDefined(match)) {
    const intlCode = isDefined(match[1]) ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export default formatPhoneNumber;
