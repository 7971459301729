import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { QueryKeys, postRequest } from 'src/api';
import { Margin } from 'src/constants';
import useRequestSetting from 'src/hooks/react-query/useRequestSetting';
import { Prescription, RequestType } from 'src/interfaces';
import RefillRequest from 'src/interfaces/api/RefillRequest';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useUser } from 'src/providers/ClientProvider';
import { ForwardRefFC } from 'src/types';
import styled from 'styled-components/native';
import Alert, { AlertHandle, AlertOption } from './Alert';
import { TextInput } from './kit';
import { Body } from './Text';
import { queryClient } from 'src/utils';

export interface RefillModalHandle {
  request?: (prescription: Prescription) => void;
}

const RefillModal: ForwardRefFC<RefillModalHandle> = React.forwardRef<RefillModalHandle>(
  (props, ref) => {
    const { user } = useUser();
    const { t } = useTranslation();
    const { colors } = useAppTheme();

    const { requestRefill, data: requestSetting } = useRequestSetting();

    const [comments, setComments] = useState<string>();
    const [prescription, setPrescription] = useState<Prescription>();

    const { dialogTitle, message, cancelText } = useMemo(() => {
      return {
        dialogTitle:
          requestSetting?.refillRequestHeaderTextSanitized || t('patientProfile:refillModalTitle'),
        message: t('patientProfile:refillModalDescription', {
          quantity: prescription?.quantity,
          refill: prescription?.description,
          petName: prescription?.patientName
        }),
        cancelText: t('common:cancel')
      };
    }, [
      prescription?.description,
      prescription?.patientName,
      prescription?.quantity,
      requestSetting?.refillRequestHeaderTextSanitized,
      t
    ]);

    const { mutate: submitRequest } = useMutation(
      async (request: RefillRequest) => await postRequest(request),
      {
        onSuccess: () => {
          void queryClient.invalidateQueries([QueryKeys.REQUESTS]);
          confirmedModalRef.current?.alert();
        },
        onError: (error: Error) => {
          Toast.show({
            type: 'error',
            text1: t('refillError', { ns: 'prescriptions' }),
            text2: error.name
          });
        }
      }
    );

    const refillConfirmed = useCallback(() => {
      if (prescription) {
        submitRequest({
          sourceId: user.sourceId,
          clientId: user.clientId,
          patientId: prescription.patientId,
          type: RequestType.Refill,
          content: {
            petName: prescription.patientName,
            refill: prescription.description,
            quantity: prescription.quantity,
            origin: Platform.OS,
            comments
          }
        });
      }
    }, [comments, prescription, submitRequest, user.clientId, user.sourceId]);

    const onDismiss = useCallback(() => {
      setComments('');
    }, []);

    const request = useCallback(
      (prescription: Prescription) => {
        requestRefill(() => {
          setPrescription(prescription);
        });

        if (!requestSetting?.refillRequestOverride) confirmModalRef.current?.alert();
      },
      [requestRefill, requestSetting?.refillRequestOverride]
    );

    useImperativeHandle<RefillModalHandle, RefillModalHandle>(
      ref,
      () => ({
        request: !requestSetting || requestSetting?.refillRequestsDisabled ? undefined : request
      }),
      [request, requestSetting]
    );

    const confirmModalRef = useRef<AlertHandle>(null);
    const confirmOptions: AlertOption[] = useMemo(
      () => [
        {
          label: cancelText,
          type: 'neutral'
        },

        {
          action: refillConfirmed,
          label: t('patientProfile:sendRequest')
        }
      ],
      [cancelText, refillConfirmed, t]
    );

    const confirmedModalRef = useRef<AlertHandle>(null);
    const confirmedOptions: AlertOption[] = useMemo(
      () => [
        {
          label: t('common:OK'),
          type: 'destructive',
          action: onDismiss
        }
      ],
      [onDismiss, t]
    );

    return (
      <>
        <Alert
          ref={confirmModalRef}
          title={dialogTitle}
          options={confirmOptions}
          dismissOnBackdropPress
        >
          <>
            <AlertBodyContent>
              <Body color={colors.onSurface} textAlign='center'>
                {message}
              </Body>
            </AlertBodyContent>
            <TextInput
              label={t('common:comments')}
              mode='outlined'
              value={comments}
              onChangeText={setComments}
            />
          </>
        </Alert>
        <Alert
          ref={confirmedModalRef}
          title={t('patientProfile:requestSent')}
          options={confirmedOptions}
          body={
            <>
              {requestSetting?.refillRequestConfirmationTextSanitized ??
                t('patientProfile:refillSent')}
            </>
          }
        />
      </>
    );
  }
);

export default RefillModal;

export const AlertBodyContent = styled.View`
  margin: ${Margin.Medium}px;
`;
