import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { QueryKeys } from 'src/api';
import { getPaginatedPrescriptions } from 'src/api/prescriptions/getPaginatedPrescriptions';
import { PAGE_LENGTH, PATIENT_STATUSES } from 'src/constants';
import { Patient, Prescription } from 'src/interfaces';
import { getNextPageParam, PaginationRS } from 'src/utils';

interface Options
  extends Omit<
    UseInfiniteQueryOptions<
      PaginationRS<Prescription>,
      unknown,
      PaginationRS<Prescription>,
      PaginationRS<Prescription>,
      QueryKeys[]
    >,
    'queryFn' | 'queryKey'
  > {
  limit?: number;
  selectedPatientId?: Patient['patientId'];
}

export const prescriptionsOptions: (
  limit?: number
) => UseInfiniteQueryOptions<
  PaginationRS<Prescription>,
  unknown,
  PaginationRS<Prescription>,
  PaginationRS<Prescription>,
  QueryKeys[]
> = (limit = PAGE_LENGTH) => ({
  queryKey: [QueryKeys.PAGINATED_PRESCRIPTIONS],
  queryFn: async ({ pageParam = 0 }) => {
    return getPaginatedPrescriptions({
      start: pageParam * limit,
      length: PAGE_LENGTH,
      status: PATIENT_STATUSES.ACTIVE,
      order: 'desc',
      orderBy: 'prescribedAt'
    });
  },
  getNextPageParam: (...options) => getNextPageParam(...options, limit),
  suspense: true
});

const usePaginatedPrescriptions = ({
  selectedPatientId,
  limit = PAGE_LENGTH,
  ...options
}: Options) => {
  const {
    data: rawData,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    ...rest
  } = useInfiniteQuery({
    ...prescriptionsOptions(limit),
    ...options
  });

  const prescriptions = useMemo(() => {
    const data =
      rawData?.pages?.reduce<Prescription[]>((prev, current) => [...prev, ...current.data], []) ??
      [];
    return data.filter(({ patientId }) => !selectedPatientId || selectedPatientId === patientId);
  }, [rawData, selectedPatientId]);

  return {
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    data: prescriptions,
    fetchNextPage,
    refetch,
    ...rest
  };
};

export default usePaginatedPrescriptions;
