import { DebouncedFunc } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../style';
import HelpCenterCard from './HelpCenterCard';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  tourGuideHasResults: boolean;
  faqHasResults: boolean;
  searchText: string | null;
  setSearchText: DebouncedFunc<(searchText: string) => void>;
}

const Header: React.FC<Props> = ({
  tourGuideHasResults,
  faqHasResults,
  searchText,
  setSearchText
}) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('helpCenter');
  return (
    <>
      <SearchInput
        label={t('searchLabel')}
        autoCapitalize='none'
        onChangeText={async (text) => setSearchText(text)}
        textColor={colors.onSurface}
      />
      {!searchText && <HelpCenterCard title={t('needHelp')} showButtons />}
      {!tourGuideHasResults && !faqHasResults && (
        <HelpCenterCard title={t('noResults')} body={t('noResultsText')} />
      )}
    </>
  );
};

export default Header;
