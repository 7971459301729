import { Linking } from 'react-native';
import { SCHEME } from 'src/constants';

export const navigateToPath = async (path: string) => {
  await Linking.openURL(`${SCHEME}://${path}`);
};

export const navigateToTodos = () => {
  void navigateToPath('/to-dos');
};
