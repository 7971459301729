import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { forgotPassword } from 'src/api';
import Practice from 'src/interfaces/api/Practice';

const useForgotPassword = () => {
  const { t } = useTranslation('login');
  const { mutate, isLoading: passwordResetSending } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: (data, vars) =>
      Toast.show({
        text1: t('common:success'),
        text2: t('passwordResetSent', { email: vars.email })
      }),
    onError: () =>
      Toast.show({
        text1: t('uhOh', { ns: 'common' }),
        text2: t('error', { ns: 'common' }),
        type: 'error'
      })
  });

  const requestPasswordReset = useMemo(
    () => _.debounce((email: string, practiceId: Practice['id']) => mutate({ email, practiceId })),
    [mutate]
  );

  return { requestPasswordReset, passwordResetSending };
};

export default useForgotPassword;
