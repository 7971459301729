import { GENDER } from 'src/constants';
import { t } from 'i18next';
import moment from 'moment-timezone';
import { InfoUpdateItem } from 'src/interfaces/api/InfoUpdateRequest';
import { InferType, date, object, string } from 'yup';
import { Patient } from 'src/interfaces';
import _ from 'lodash';
import { ImageUpload } from 'src/components/UploadFiles';

const fieldNamesFromPatient: Array<keyof FormikData> = [
  'name',
  'birthdate',
  'breed',
  'microchipNumber',
  'rabiesTag',
  'weight',
  'sex',
  'insuranceProvider',
  'policyNumber',
  'diet',
  'medicalConditions'
] as const;
export type FormikData = InferType<typeof validationSchema>;

export const validationSchema = object({
  name: string().default(null).max(80, t('patientProfile:maxLengthName')).nullable(),
  birthdate: date().default(null).nullable(),
  breed: string().default(null).max(255, t('patientProfile:maxLengthBreed')).nullable(),
  microchipNumber: string()
    .default(null)
    .max(50, t('patientProfile:maxLengthMicrochip'))
    .nullable(),
  rabiesTag: string().default(null).max(50, t('patientProfile:maxLengthRabies')).nullable(),
  weight: string().default(null).max(10, t('patientProfile:maxLengthWeight')).nullable(),
  sex: string().default(null).max(10, t('patientProfile:maxLengthSex')).nullable(),
  comments: string().default(null).max(255, t('patientProfile:maxLengthComments')).nullable(),
  insuranceProvider: string().default(undefined).max(255, t('patientProfile:maxLengthProvider')),
  policyNumber: string().default(undefined).max(255, t('patientProfile:maxLengthPolicyNum')),
  diet: string().default(undefined).max(255, t('patientProfile:maxLengthDiet')),
  medicalConditions: string().default(undefined).max(255, t('patientProfile:maxLengthMedical')),
  images: object()
    .test({
      test: (imagesDict: Record<string, ImageUpload>): boolean =>
        Object.values(imagesDict).every((image: ImageUpload) => !image?.error),
      message: t('patientProfile:deleteBeforeProceeding'),
      name: 'deleteBeforeProceeding'
    })
    .test({
      test: (imagesDict: Record<string, ImageUpload>): boolean =>
        Object.values(imagesDict).every((image: ImageUpload) => !!image?.id),
      message: t('common:imagesNotReady'),
      name: 'imagesNotReady'
    })
    .default({}),
  newPatientImageUri: string().default(null).nullable(),
  contactMethod: string().default('')
});

export const contactRequiredFields: Array<keyof FormikData> = [
  'name',
  'birthdate',
  'breed',
  'microchipNumber',
  'rabiesTag',
  'weight',
  'sex',
  'comments',
  'images'
] as const;

export const isFieldDirty = (
  field: keyof FormikData,
  values: FormikData,
  initialValues: FormikData
): boolean => !_.isEqual(values[field], initialValues[field]);

export const createBasicInfoRequestItemArray = (values: FormikData, initialValues: FormikData) => {
  const contentPayloadItems: InfoUpdateItem[] = [];

  if (isFieldDirty('name', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('petID:name'),
      newValue: values.name
    });
  }

  if (isFieldDirty('birthdate', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('petID:dob'),
      newValue: moment(values.birthdate).format('M/D/YYYY') ?? ''
    });
  }

  if (isFieldDirty('breed', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('petID:breed'),
      newValue: values.breed
    });
  }

  if (isFieldDirty('microchipNumber', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('patientProfile:microchipTag'),
      newValue: values.microchipNumber
    });
  }

  if (isFieldDirty('rabiesTag', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('patientProfile:rabiesTag'),
      newValue: values.rabiesTag
    });
  }

  if (isFieldDirty('sex', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('petID:sex'),
      newValue: values.sex
    });
  }

  if (isFieldDirty('weight', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('petID:weight'),
      newValue: values.weight
    });
  }

  return contentPayloadItems;
};

export const createAdditionalInfoItemArray = (values: FormikData, initialValues: FormikData) => {
  const contentPayloadItems: InfoUpdateItem[] = [];

  if (isFieldDirty('insuranceProvider', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('patientProfile:insuranceProvider'),
      newValue: values.insuranceProvider ?? ''
    });
  }

  if (isFieldDirty('policyNumber', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('patientProfile:policyNumber'),
      newValue: values.policyNumber ?? ''
    });
  }

  if (isFieldDirty('diet', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('patientProfile:diet'),
      newValue: values.diet ?? ''
    });
  }

  if (isFieldDirty('medicalConditions', values, initialValues)) {
    contentPayloadItems.push({
      dataType: t('patientProfile:medicalConditions'),
      newValue: values.medicalConditions ?? ''
    });
  }

  return contentPayloadItems;
};

export const isAdditionalInfoDirty = (values: FormikData, initialValues: FormikData) => {
  return (
    isFieldDirty('insuranceProvider', values, initialValues) ||
    isFieldDirty('policyNumber', values, initialValues) ||
    isFieldDirty('diet', values, initialValues) ||
    isFieldDirty('medicalConditions', values, initialValues)
  );
};

export const populateGenderIconName = (gender: string) => {
  if (!gender) return 'gender-male-female';

  switch (gender) {
    case GENDER.MALE:
      return 'gender-male';
    case GENDER.FEMALE:
      return 'gender-female';
    default:
      return 'gender-male-female';
  }
};

export const initialValuesBuilder = (patient?: Patient): FormikData => {
  const values: FormikData = _.mergeWith(
    {
      name: patient?.name || '',
      birthdate: patient?.birthdate || null,
      breed: patient?.breed || '',
      microchipNumber: patient?.microchipNumber || '',
      rabiesTag: patient?.rabiesTag || '',
      sex: patient?.sex || '',
      weight: patient?.weight || '',
      insuranceProvider: patient?.insuranceProvider || '',
      policyNumber: patient?.policyNumber || '',
      diet: patient?.diet || '',
      medicalConditions: patient?.medicalConditions || '',
      newPatientImageUri: null,
      images: {}
    },
    _.pick(patient, fieldNamesFromPatient),
    (objValue, srcValue) => (typeof srcValue === 'string' ? srcValue : objValue)
  ) as FormikData;
  return values;
};
