import { MoreHorizontal } from 'react-native-feather';
import { AutoRenew, HairDryer, IconProps, MedicalBag, Needle, Pill } from 'src/components/Icons';
import { UseBooleanHook } from 'src/hooks/useBoolean';
import { Patient, Todo, TodoReason } from 'src/interfaces';
import { AppColors } from 'src/types/paper';
import { STEPS } from './tour';

export interface TodoData {
  pastDue: Todo[];
  today: Todo[];
  scheduled: Todo[];
  complete: Todo[];
}

interface PropSection {
  icon: React.FC<IconProps>;
  description: string;
  color: keyof AppColors;
}

export const exampleToDo: Todo = {
  id: 0,
  reason: TodoReason.GIVE_MEDICATION,
  allowNotify: false,
  isComplete: false,
  patientId: '',
  createdAt: '',
  updatedAt: '',
  sourceId: '',
  clientId: '',
  practiceId: '',
  userId: '',
  todoAlertsAttributes: []
};

export const dummyPatient: Patient = {
  sourceId: 0,
  patientId: '',
  practiceId: 0,
  name: 'Rover',
  diet: '',
  displayId: null,
  birthdate: null,
  deceasedDate: null,
  insuranceProvider: '',
  status: '',
  species: 'dog',
  breed: '',
  sex: '',
  photoUrl: '',
  photo: {
    urls: {
      small: '',
      original: '',
      large: ''
    }
  },
  policyNumber: '',
  hasPhoto: false,
  weight: '',
  weightUnit: '',
  medicalConditions: '',
  rabiesTag: '',
  microchipNumber: ''
};

export interface Section {
  title: string;
  step: STEPS;
  guideText: string;
  expanded: UseBooleanHook;
  data: Todo[];
  fallback?: {
    shouldDisplayPlaceholder: () => boolean;
    emptyPlaceholder: React.JSX.Element;
  };
  completed?: boolean;
}

export const propSections: PropSection[] = [
  {
    icon: Pill,
    description: 'todos:list.medication',
    color: 'accent'
  },
  {
    icon: Needle,
    description: 'todos:list.vaccine',
    color: 'warn'
  },
  {
    icon: HairDryer,
    description: 'todos:list.grooming',
    color: 'notification'
  },
  {
    icon: MoreHorizontal,
    description: 'todos:list.more',
    color: 'text'
  }
];

export interface ReasonItem {
  label: string;
  value: TodoReason;
  icon: React.FC<IconProps>;
  color: keyof AppColors;
}

export const ReasonMap: {
  [k in TodoReason]: ReasonItem;
} = {
  [TodoReason.GIVE_MEDICATION]: {
    label: 'todos:reasons.giveMedication',
    value: TodoReason.GIVE_MEDICATION,
    icon: Pill,
    color: 'accent'
  },
  [TodoReason.REFILL_MEDICATION]: {
    label: 'todos:reasons.refillMedication',
    value: TodoReason.REFILL_MEDICATION,
    icon: AutoRenew,
    color: 'error'
  },
  [TodoReason.VACCINATIONS]: {
    label: 'todos:reasons.vaccinations',
    value: TodoReason.VACCINATIONS,
    icon: Needle,
    color: 'warn'
  },
  [TodoReason.ANNUAL_EXAM]: {
    label: 'todos:reasons.annualExam',
    value: TodoReason.ANNUAL_EXAM,
    icon: MedicalBag,
    color: 'minorAccent'
  },
  [TodoReason.GROOMING]: {
    label: 'todos:reasons.grooming',
    value: TodoReason.GROOMING,
    icon: HairDryer,
    color: 'notification'
  },
  [TodoReason.OTHER]: {
    label: 'todos:reasons.other',
    value: TodoReason.OTHER,
    icon: MoreHorizontal,
    color: 'text'
  }
};
