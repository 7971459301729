import moment from 'moment';
import { Patient, Request } from 'src/interfaces';
import _ from 'lodash';
import { RequestWithPatient } from './model';

export interface RequestFilterCriteria {
  patientId?: Patient['patientId'];
  fromDate?: string;
  toDate?: string;
  status?: RequestWithPatient['status'];
  type?: RequestWithPatient['type'];
  sortBy?: keyof Request;
  sortOrder?: SortOrder;
}
export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

type Filter = (requests: RequestWithPatient[]) => RequestWithPatient[];

export const filterRequests = (
  requests: RequestWithPatient[],
  criteria: RequestFilterCriteria = { sortBy: 'createdAt', sortOrder: SortOrder.DESC }
): RequestWithPatient[] => {
  const filters: Filter[] = [];

  if (criteria.patientId) {
    filters.push((requests) => _.filter(requests, { patientId: criteria.patientId }));
  }
  if (criteria.fromDate) {
    filters.push((requests) =>
      _.filter(requests, (request) => moment(request.createdAt).isSameOrAfter(criteria.fromDate))
    );
  }
  if (criteria.toDate) {
    filters.push((requests) =>
      _.filter(requests, (request) => moment(request.createdAt).isSameOrBefore(criteria.toDate))
    );
  }
  if (criteria.status) {
    filters.push((requests) => _.filter(requests, { status: criteria.status }));
  }
  if (criteria.type) {
    filters.push((requests) => _.filter(requests, { type: criteria.type }));
  }

  if (criteria.sortBy) {
    filters.push((requests) => _.orderBy(requests, criteria.sortBy, criteria.sortOrder));
  }

  return filters.reduce((requests, filter) => filter(requests), [...requests]);
};
