import _ from 'lodash';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPatients, QueryKeys } from 'src/api';
import { Patient } from 'src/interfaces';
import { isPatientActive } from 'src/utils';

interface Context {
  selectedPatient?: Patient;
  setSelectedPatient: React.Dispatch<React.SetStateAction<Patient | undefined>>;
  patients: Patient[];
}

const defaultContext: Context = {
  selectedPatient: undefined,
  setSelectedPatient: _.noop,
  patients: []
};

const SelectedPatientContext = createContext(defaultContext);

export const useSelectedPatient = (): Context => useContext(SelectedPatientContext);

interface Props extends PropsWithChildren {
  selectedPatientId?: Patient['patientId'];
}

export const SelectedPatientProvider: React.FC<Props> = ({ selectedPatientId, children }) => {
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>();

  const { data: patients = [] } = useQuery({
    queryFn: getPatients,
    queryKey: [QueryKeys.PATIENTS],
    select: (ps) => ps.filter(isPatientActive)
  });

  useEffect(() => {
    setSelectedPatient(patients.find((p) => p.patientId === selectedPatientId));
  }, [selectedPatientId, patients]);

  return (
    <SelectedPatientContext.Provider value={{ selectedPatient, setSelectedPatient, patients }}>
      {children}
    </SelectedPatientContext.Provider>
  );
};
