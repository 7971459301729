import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';

export enum STEPS {
  DRAWER = 'DRAWER',
  EMAIL = 'EMAIL',
  MULTIPLE_PRACTICES_DROPDOWN = 'MULTIPLE_PRACTICES_DROPDOWN',
  MULTIPLE_PRACTICES_AVAILABLE = 'MULTIPLE_PRACTICES_AVAILABLE',
  PRACTICES_REGISTERED = 'PRACTICES_REGISTERED'
}

const LandingTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: {
    id: STEPS.DRAWER,
    priority: 0
  },
  [STEPS.EMAIL]: {
    id: STEPS.EMAIL,
    priority: 1
  },
  [STEPS.MULTIPLE_PRACTICES_DROPDOWN]: {
    id: STEPS.MULTIPLE_PRACTICES_DROPDOWN,
    priority: 2
  },
  [STEPS.MULTIPLE_PRACTICES_AVAILABLE]: {
    id: STEPS.MULTIPLE_PRACTICES_AVAILABLE,
    priority: 3
  },
  [STEPS.PRACTICES_REGISTERED]: {
    id: STEPS.PRACTICES_REGISTERED,
    priority: 4
  }
};

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.LANDING,
  LandingTourMap
);
