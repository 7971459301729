import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Body, BottomButton, CPrimaryButton, Headline, Title } from 'src/components';
import { ALLY_SUPPORT_EMAIL, Size } from 'src/constants';
import { useViewMode } from 'src/hooks';
import { useAuth } from 'src/providers/AuthProvider';
import { Weight } from 'src/theme';
import openEmailToAlly from 'src/utils/openEmailToAlly';
import styled from 'styled-components/native';

const PetPageDisabled: React.FC = () => {
  const { isWeb } = useViewMode();
  const { logOut } = useAuth();
  const { t } = useTranslation('petPageDisabled');
  return (
    <>
      <Container>
        <Title>{t('heading')}</Title>
        <StyledHeadline>{t('message')}</StyledHeadline>
        <StyledBody>{t('otherAccounts')}</StyledBody>
        <StyledBody>
          <Trans
            i18nKey={'error'}
            ns={'petPageDisabled'}
            values={{
              ourEmail: ALLY_SUPPORT_EMAIL
            }}
            components={{
              contactUs: (
                <EmailLink
                  style={{ textDecorationLine: 'underline' }}
                  onPress={async () => await openEmailToAlly()}
                />
              )
            }}
          />
        </StyledBody>
        {isWeb && <WebButton onPress={async () => logOut(undefined)}>{t('logOut')}</WebButton>}
      </Container>
      {!isWeb && <BottomButton onPress={async () => logOut(undefined)}>{t('logOut')}</BottomButton>}
    </>
  );
};

export default PetPageDisabled;

const Container = styled.View`
  flex: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset + Size.M}px;
  align-items: center;
  justify-content: center;
  margin-top: ${Size.M}px;
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  margin-top: ${Size.S}px;
`;

const WebButton = styled(CPrimaryButton)`
  margin-top: ${Size.M}px;
`;

const StyledBody = styled(Body)`
  margin-top: ${Size.S}px;
`;

const EmailLink = styled(Body)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${Weight.MEDIUM};
`;
