import React from 'react';
import { mask } from 'react-native-mask-text';
import { Caption, Subheading } from 'src/components';
import { HoverCard } from 'src/components/kit';
import { PHONE_MASK, Size } from 'src/constants';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

interface Props {
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  onPress: () => void;
}

const PracticeSearchResult: React.FC<Props> = ({
  name,
  address,
  city,
  state,
  postalCode,
  phoneNumber,
  onPress
}) => {
  return (
    <StyledCard onPress={onPress}>
      <Subheading>{name}</Subheading>
      <Caption>{address}</Caption>
      <Caption>{`${city}, ${state} ${postalCode}`}</Caption>
      <Caption>{mask(phoneNumber, PHONE_MASK)}</Caption>
    </StyledCard>
  );
};

export default PracticeSearchResult;

export const StyledCard = styled(HoverCard)`
  align-items: center;
  padding: ${Size.S}px;
  background-color: ${({ theme }) =>
    theme.dark ? palette.BLACK_OPACITY_70 : palette.WHITE_OPACITY_70};
  gap: ${Size.X2_S}px;
`;
