import moment from 'moment';
import { AsyncStorageKeys } from 'src/api';
import storage from '../storage';
import { defaultVisitor, defaultVisit } from './constants';
import { IS_WEB } from 'src/constants';

/**
 * Gets a token from storage or returns a default value.
 * @param key
 * @param defaultValue
 * @returns
 */
const getTokenOrDefault = async <T>(key: AsyncStorageKeys, defaultValue: T): Promise<T> => {
  try {
    const raw = (await storage.getItem(key))!;
    const decoded = IS_WEB ? atob(raw) : raw;
    const token = JSON.parse(decoded);
    if (!token || moment().isAfter(token.expires)) {
      throw new Error('Token expired');
    }
    return token;
  } catch (e) {
    const token = JSON.stringify(defaultValue);
    const encoded = IS_WEB ? btoa(token) : token;
    await storage.setItem(key, encoded);
    return defaultValue;
  }
};

/**
 * Gets the visit token from storage or returns a default value.
 * @returns
 */
export const getVisitToken = async () => {
  return getTokenOrDefault(AsyncStorageKeys.VISIT_TOKEN, defaultVisit);
};

/**
 * Gets the visitor token from storage or returns a default value.
 * @returns
 */
export const getVisitorToken = async () => {
  return getTokenOrDefault(AsyncStorageKeys.VISITOR_TOKEN, defaultVisitor);
};
