import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TouchableRipple } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import {
  SingleChange,
  ValidRangeType
} from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { useBoolean } from 'src/hooks';
import sentry, { interpolateName } from 'src/utils/sentry';
import TextInput, { TextInputProps } from './TextInput';
import { extractStyles } from 'src/utils';

interface DatePickerDate {
  date: number;
  month: number;
  year: number;
}

interface Props
  extends Omit<
    TextInputProps,
    'value' | 'pointerEvents' | 'editable' | 'showSoftInputOnFocus' | 'focusable' | 'caretHidden'
  > {
  value?: Date;
  validRange?: ValidRangeType;
  onConfirm: (v: DatePickerDate | undefined) => void;
  startYear?: number;
  endYear?: number;
}
const DatePicker: React.FC<Props> = ({
  value,
  onConfirm,
  startYear,
  endYear,
  validRange,
  style,
  disabled,
  ...textInputProps
}) => {
  const { i18n } = useTranslation();
  const {
    value: datePickerVisible,
    toTrue: openDatePicker,
    toFalse: closeDatePicker
  } = useBoolean();

  const buttonSentryLabel = interpolateName('DatePickerButton', textInputProps.label);
  const modalSentryLabel = interpolateName('DatePickerModal', textInputProps.label);

  const onOpenDatePicker = useCallback(() => {
    sentry.addBreadcrumb({ type: 'Interaction', message: `${buttonSentryLabel} was pressed` });
    openDatePicker();
  }, [buttonSentryLabel, openDatePicker]);

  const onChooseDate: SingleChange = useCallback(
    ({ date }) => {
      if (date) {
        sentry.addBreadcrumb({
          type: 'Interaction',
          message: `${modalSentryLabel}: Date ${date?.toDateString()} was chosen`
        });
        onConfirm({
          date: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear()
        });
      } else {
        sentry.addBreadcrumb({
          type: 'Interaction',
          message: `${modalSentryLabel}: Date was cleared`
        });
        onConfirm(undefined);
      }
      closeDatePicker();
    },
    [closeDatePicker, modalSentryLabel, onConfirm]
  );

  const { innerStyle, outerStyle } = useMemo(() => {
    const flatStyle = StyleSheet.flatten(style);
    return extractStyles(flatStyle);
  }, [style]);

  return (
    <>
      <TouchableRipple
        onPress={onOpenDatePicker}
        style={outerStyle}
        sentry-label={buttonSentryLabel}
        disabled={disabled}
      >
        <TextInput
          onFocus={onOpenDatePicker}
          value={
            value
              ? moment(value).toDate().toLocaleDateString([i18n.language], {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })
              : undefined
          }
          showSoftInputOnFocus={false}
          focusable={false}
          caretHidden
          style={innerStyle}
          disabled={disabled}
          {...textInputProps}
        />
      </TouchableRipple>
      <DatePickerModal
        sentry-label={modalSentryLabel}
        locale={i18n.language}
        mode={'single'}
        visible={datePickerVisible}
        validRange={validRange}
        date={value ? moment(value).toDate() : undefined}
        startYear={startYear}
        endYear={endYear}
        onConfirm={onChooseDate}
        onDismiss={closeDatePicker}
        dateMode='start'
      />
    </>
  );
};

export default DatePicker;
