import { Headline, HoverCard, Row } from 'src/components';
import AnimatedNumber from 'src/components/AnimatedNumber';
import { Margin, Size } from 'src/constants';
import CareCoin from 'assets/care-coin.png';
import { palette, Weight } from 'src/theme';
import styled from 'styled-components/native';

export const COIN_HEIGHT_DEFAULT = 20;
export const COIN_WIDTH_DEFAULT = 19;

export const TabViewContainer = styled.View`
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;

  flex-direction: row;
  justify-content: space-between;
  margin-top: ${Margin.Medium}px;
  border-bottom-width: ${Size.X4_S}px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
`;

export const TabViewInnerContainer = styled.View`
  gap: ${Margin.Large}px;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const ExtendedHeader = styled.View<{ topMargin: number }>`
  padding-top: ${({ topMargin }) => topMargin || Size.M}px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const TopTab = styled(HoverCard).attrs({
  hideShadow: true
})`
  justify-content: center;
  align-items: center;
  padding: ${Margin.Medium}px;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const CoinBalanceContainer = styled(Row)`
  margin-right: ${Margin.Large}px;
  margin-bottom: ${Margin.Small}px;
`;

export const CoinBalanceAmountText = styled(AnimatedNumber)`
  font-weight: ${Weight.BOLD};
  margin-right: ${Margin.ExtraSmall}px;
  margin-left: ${Margin.Medium}px;
  text-align: right;
  text-align-vertical: bottom;
  margin-top: ${Margin.ExtraSmall}px;
  align-self: center;
`;

export const CoinIcon = styled.Image.attrs({ source: CareCoin })<{
  height?: number;
  width?: number;
}>`
  height: ${COIN_HEIGHT_DEFAULT}px;
  width: ${COIN_WIDTH_DEFAULT}px;
  margin-left: ${Margin.Small}px;
  align-self: center;
`;

export const Header = styled(Headline)`
  color: ${({ theme }) => theme.colors.onPrimary};
  text-align: center;
  margin-bottom: ${Size.S}px;
`;
