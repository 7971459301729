import { AlertTriangle } from 'react-native-feather';
import { MiniHeroIcon, HoverButton, BottomButton, Headline, Body } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Margin, MOBILE_WIDTH_MAX } from 'src/constants';
import styled from 'styled-components/native';
import useGoBack from 'src/hooks/useGoBack';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

const PracticeNotFound: React.FC = () => {
  const { setOptions } = useNavigation();
  const { t } = useTranslation('onlineBooking');

  const {
    colors,
    viewMode: { isMobile }
  } = useAppTheme();
  const exit = useGoBack();

  useEffect(() => {
    setOptions({
      title: 'Online Booking Unavailable'
    });
  }, [setOptions]);

  return (
    <>
      <ErrorContainer>
        <MiniHeroIcon icon={AlertTriangle} color={colors.error} />
        <CenteredText as={Headline}>{t('practiceUnavailable.title')}</CenteredText>
        <CenteredText>{t('practiceUnavailable.description')}</CenteredText>
        {!isMobile && <HoverButton onPress={exit}>{t('returnToPetPage')}</HoverButton>}
      </ErrorContainer>
      {isMobile && <BottomButton onPress={exit}>{t('returnToPetPage')}</BottomButton>}
    </>
  );
};

export default PracticeNotFound;

const ErrorContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: ${Margin.Medium}px;
  max-width: ${MOBILE_WIDTH_MAX}px;
  padding: ${Margin.ExtraLarge}px;
  align-self: center;
`;

const CenteredText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
  text-align: center;
`;
