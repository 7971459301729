import { AxiosError } from 'axios';
import { endpoint } from 'src/constants/api';
import { ChangePasswordPayload, ChangePasswordError, Client } from 'src/interfaces/api';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

interface PasswordError {
  errors: {
    current_password: string[];
    password: string[];
  };
}

type ChangePasswordResponse = Pick<Client, 'email' | 'practiceId' | 'id'>;

export const changePassword = async (
  user: ChangePasswordPayload
): Promise<ChangePasswordResponse> => {
  try {
    const response = await apiClient.put<ChangePasswordResponse>(endpoint('USERS'), { user });
    return isDefinedOrThrowServerError(response.data);
  } catch (e: unknown) {
    const response = isDefinedOrThrowServerError((e as AxiosError<PasswordError>).response?.data);
    const passwordError = new ChangePasswordError({
      current_password: [],
      password: [],
      password_confirmation: []
    });

    passwordError.errors.current_password = response.errors.current_password;
    passwordError.errors.password = response.errors.password;

    throw passwordError;
  }
};
