import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CheckCircle } from 'react-native-feather';
import { mask } from 'react-native-mask-text';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Body, Headline, HelperButton, Subheading, Title } from 'src/components';
import { IconSize, PHONE_MASK, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import styled from 'styled-components/native';
import { ReducerState } from '../helpers/model';
import { makePhoneCall } from 'src/utils';

interface Props {
  state: ReducerState;
  moveNext: () => void;
}

const AccountLocated: React.FC<Props> = ({ state, moveNext }) => {
  const { t } = useTranslation('practiceClientSearch');
  const { colors } = useAppTheme();

  const maskedPracticePhoneNumber = state.data.selectedPractice?.phoneNumber
    ? mask(state.data.selectedPractice?.phoneNumber, PHONE_MASK)
    : undefined;

  return (
    <SafeAreaView edges={['bottom']} style={{ flex: 1 }}>
      <StyledView>
        <Title color={colors.onPrimary}>{t('allDone')}</Title>
        <CheckCircle height={IconSize.L} width={IconSize.L} color={colors.onPrimary} />

        <Subheading textAlign='center' color={colors.onPrimary}>
          {t('recoveryEmailSent')}
        </Subheading>
        <Headline fontWeight={Weight.BOLD} color={colors.onPrimary}>
          {state.data.email}
        </Headline>
        <Body textAlign='center' color={colors.onPrimary}>
          <Trans
            components={{
              phone: (
                <Link onPress={() => makePhoneCall(state.data.selectedPractice?.phoneNumber)} />
              )
            }}
            t={t}
            i18nKey={'followInstructions'}
            values={{ phone: maskedPracticePhoneNumber }}
          />
        </Body>
      </StyledView>
      <HelperButton onPress={moveNext}>{t('backToLogin')}</HelperButton>
    </SafeAreaView>
  );
};

export default AccountLocated;

const StyledView = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: ${Size.S}px;
`;

const Link = styled(Body)`
  color: ${({ theme }) => theme.colors.onPrimary};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.onPrimary};
  font-weight: ${Weight.BOLD};
`;
