import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { PATIENT_STATUSES, Size } from 'src/constants';
import styled from 'styled-components/native';
import PatientListContent from './PatientListContent';
import PatientStatusSelector from './PatientStatusSelector';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, SliderElement, SLIDES, STEPS, TourGuide } from './tour';
import { TourGuideHandle } from 'src/module/TourGuide/model';
import { QueryKey, useQueries } from 'react-query';
import { QueryKeys } from 'src/api';
import { useTranslation } from 'react-i18next';

const queries: QueryKey[] = [QueryKeys.PAGINATED_PATIENTS, QueryKeys.PATIENTS];

const PatientListRoot: React.FC = () => {
  const { t } = useTranslation();
  const { setOptions } = useNavigation();
  const { colors } = useAppTheme();

  const [selectedStatus, setSelectedStatus] = useState<PATIENT_STATUSES | undefined>(
    PATIENT_STATUSES.ACTIVE
  );

  useEffect(() => {
    setOptions({
      headerShown: true,
      headerStyle: {
        backgroundColor: colors.primary
      }
    });
  }, [colors.primary, setOptions]);

  const results = useQueries(queries.map((key) => ({ queryKey: [key] })));
  const isRefreshing = results.some((query) => query.isRefetching && !query.isStale);

  const providerRef = useRef<TourGuideHandle>(null);

  return (
    <TourGuide.Provider ref={providerRef} disabled={isRefreshing}>
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
      <HeroContainer>
        <GuideElement body={t('petList:tour.filter')} id={STEPS.FILTER}>
          <PatientStatusSelector
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
        </GuideElement>
      </HeroContainer>
      <PatientListContent patientStatus={selectedStatus} />
    </TourGuide.Provider>
  );
};

export default PatientListRoot;

const HeroContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  padding-bottom: ${Size.XS}px;
`;
