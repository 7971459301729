import { Platform, Linking } from 'react-native';

const openMaps = async (address?: string, label?: string) => {
  if (!address) {
    return;
  }
  const scheme =
    Platform.select({
      ios: 'maps://0,0?q=',
      android: 'geo:0,0?q=',
      web: 'https://www.google.com/maps/dir/'
    }) ?? '';
  const url =
    Platform.select({
      ios: `${scheme}${label ?? ''}&address=${address}`,
      android: `${scheme}${address}(${label ?? ''})`,
      web: `${scheme}?api=1&destination=${address}`
    }) ?? '';

  if (await Linking.canOpenURL(url)) {
    await Linking.openURL(url);
  }
};

export default openMaps;
