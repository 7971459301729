import { StyleSheet } from 'react-native';
import { Size, TABLET_WIDTH_LANDSCAPE_MAX } from 'src/constants/general';

export const PROFILE_IMAGE_PREVIEW_SIZE = 125;

const style = StyleSheet.create({
  title: { margin: Size.S, textAlign: 'center' },
  largeContainer: { alignSelf: 'center', width: TABLET_WIDTH_LANDSCAPE_MAX },

  addPhotoButton: {
    alignSelf: 'center',
    marginBottom: Size.L
  },
  profilePicture: {
    alignSelf: 'center',
    marginTop: Size.S,
    marginBottom: Size.X2_S,
    width: PROFILE_IMAGE_PREVIEW_SIZE,
    height: PROFILE_IMAGE_PREVIEW_SIZE,
    borderRadius: PROFILE_IMAGE_PREVIEW_SIZE
  },
  profileIcon: {
    fontSize: PROFILE_IMAGE_PREVIEW_SIZE
  },
  navContainer: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  navIcon: { padding: Size.S },
  warnIcon: {
    fontSize: Size.L,
    paddingLeft: Size.S
  }
});

export default style;
