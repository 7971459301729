import React from 'react';
import useAppointments from '../../hooks/useAppointments';
import AppointmentCardNext from './AppointmentCardNext';
import AppointmentCardNone from './AppointmentCardNone';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import AppointmentStackParamsList from 'src/routes/stacks/AppointmentStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { StyledCard } from '../shared';
import { useSelectedPatient } from 'src/providers/SelectedPatientProvider';
import { View, ViewProps } from 'react-native';

interface Props extends ViewProps {
  viewRef?: React.RefObject<View>;
}
const NextAppointmentCard: React.FC<Props> = ({ viewRef, ...props }) => {
  const { selectedPatient } = useSelectedPatient();
  const { data: appointments } = useAppointments({
    selectedPatient: selectedPatient?.patientId
  });

  const { navigate } = useNavigation<NavigationProp<AppointmentStackParamsList>>();

  if (appointments?.next)
    return (
      <StyledCard
        ref={viewRef}
        {...props}
        onPress={() =>
          navigate(Screens.APPOINTMENT_DETAILS, {
            id: appointments.next.appointmentId
          })
        }
      >
        <AppointmentCardNext appointment={appointments.next} />
      </StyledCard>
    );
  return (
    <StyledCard ref={viewRef} {...props}>
      <AppointmentCardNone />
    </StyledCard>
  );
};

export default NextAppointmentCard;
