import { fontStyles } from 'src/theme/globalStyles';
import { HoverButton } from '../kit';
import { HoverButtonProps } from '../kit/HoverButton';
import style from './style';

const CPrimaryButton: React.FC<Omit<HoverButtonProps, 'theme'>> = ({
  mode = 'contained',
  children,
  style: parentStyle,
  ...props
}) => {
  return (
    <HoverButton
      mode={mode}
      uppercase={false}
      style={[style.button, parentStyle]}
      labelStyle={fontStyles.subheading}
      {...props}
    >
      {children}
    </HoverButton>
  );
};

export default CPrimaryButton;
