import { endpoint } from 'src/constants';
import { Client } from 'src/interfaces';
import Appointment from 'src/interfaces/api/Appointment';
import { PaginationRS, isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getAppointments = async (
  start?: number,
  length?: number
): Promise<PaginationRS<Appointment>> => {
  const response = await apiClient.get<PaginationRS<Appointment>>(
    endpoint('APPOINTMENTS', { start, length })
  );
  const data = isDefinedOrThrowServerError(response.data);
  return data;
};

export const getAppointment = async (
  sourceId: Client['sourceId'],
  appointmentId: Appointment['appointmentId']
) => {
  const response = await apiClient.get<Appointment>(
    endpoint('APPOINTMENT', { appointmentId, sourceId })
  );
  const data = isDefinedOrThrowServerError(response.data);
  return data;
};
