import _ from 'lodash';
import { InfiniteData } from 'react-query';
import { Appointment, Patient } from 'src/interfaces';
import { PaginationRS } from 'src/utils';

export const extractAppointments = (data: InfiniteData<PaginationRS<Appointment>> | undefined) =>
  data?.pages?.reduce<Appointment[]>((prev, curr) => [...prev, ...curr.data], []) ?? [];

export const filterAppointments = (
  data: Appointment[],
  selectedPatientId: Patient['patientId'] | undefined
) =>
  _(data)
    .filter(({ patientId }) => !selectedPatientId || selectedPatientId === patientId)
    .uniqBy('appointmentId')
    .value();
