import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { Body } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import { Weight } from 'src/theme';
import styled from 'styled-components/native';
import { Actions, examplePractice, ReducerState } from '../helpers/model';
import { ContentContainer } from '../style';
import { GuideElement, STEPS, useTourGuide } from '../tour';
import PracticeSearchResult from './PracticeSearchResult';

interface Props {
  state: ReducerState;
  moveNext: () => void;
  updateData: (data: Actions['data']) => void;
}

const PracticeSearchResults: React.FC<Props> = ({ state, updateData, moveNext }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('practiceClientSearch');

  const { enabled } = useTourGuide();
  const scrollRef = useRef<ScrollView>(null);

  return (
    <>
      <Header>
        <Body fontWeight={Weight.MEDIUM} color={colors.onPrimary}>
          {!!state.data.practiceOptions &&
            `${state.data.practiceOptions.length} ${t('hospitalsFound')}`}
        </Body>
        <Body color={colors.onPrimary}>{t('tapToSelect')}</Body>
      </Header>
      <ContentContainer ref={scrollRef}>
        <ScrollRefProvider scrollRef={scrollRef}>
          {!!state.data.practiceOptions &&
            state.data.practiceOptions.map((practice) => {
              return (
                <GuideElement
                  key={enabled ? examplePractice.id : practice.id}
                  id={STEPS.HOSPITAL_SEARCH_RESULTS}
                  body={t('tour.hospitalSearchResults')}
                  onContinue={() => {
                    updateData({ selectedPractice: examplePractice });
                    moveNext();
                  }}
                >
                  <PracticeSearchResult
                    name={enabled ? examplePractice.name : practice.name}
                    address={enabled ? examplePractice.address : practice.address}
                    city={enabled ? examplePractice.city : practice.city}
                    state={enabled ? examplePractice.state : practice.state}
                    postalCode={enabled ? examplePractice.postalCode : practice.postalCode}
                    phoneNumber={enabled ? examplePractice.phoneNumber : practice.phoneNumber}
                    onPress={() => {
                      updateData({ selectedPractice: practice });
                      moveNext();
                    }}
                  />
                </GuideElement>
              );
            })}
        </ScrollRefProvider>
      </ContentContainer>
    </>
  );
};

export default PracticeSearchResults;

export const Header = styled.View`
  align-items: center;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
`;
