import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { GuideData } from '../model';
import { GuideElement, useTourGuide } from '../tour';

interface Props extends PropsWithChildren {
  guideData?: GuideData;
}

const GuideElementWrapper: React.FC<Props> = ({ guideData: guide, children }) => {
  const { t } = useTranslation('appointments');
  const { pendingSteps, exitTour } = useTourGuide();
  if (!guide) return <>{children}</>;
  return (
    <GuideElement
      id={guide.id}
      body={t(guide.body)}
      semiTransparentBg
      onContinue={() => {
        if (!pendingSteps) {
          exitTour();
        }
      }}
    >
      <>{children}</>
    </GuideElement>
  );
};

export default GuideElementWrapper;
