import { Platform } from 'react-native';
import { AsyncStorageKeys } from 'src/api/keys';
import storage from './storage';
import packageJson from 'package.json';
import { getToken } from './firebase';
import { AxiosError } from 'axios';

const generateGenericHeaders = async () => {
  const token = (await storage.getTypedItem<string>(AsyncStorageKeys.TOKEN)) ?? '';
  let sourceToken;
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-App-Platform': Platform.OS,
    'X-App-Version': packageJson.version
  });
  if (!token) {
    const error = new AxiosError('Authentication token is missing', '401');
    error.name = 'Unauthorized';
    error.status = 401;
    throw error;
  }
  try {
    sourceToken = await getToken();
  } finally {
    if (sourceToken) {
      headers.append('Source-Token', sourceToken);
    }
  }
  return headers;
};

export default generateGenericHeaders;
