import { endpoint } from 'src/constants/api';
import { apiClient } from 'src/utils/axios';

export const logout = async (): Promise<void> => {
  await apiClient.get(endpoint('USERS_SIGN_OUT'));
};

export const deleteUser = async (): Promise<void> => {
  await apiClient.delete(endpoint('USERS'));
};
