import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CUserInfo from './CUserInfo';
import { isDefined } from 'src/utils';
import formatPhoneNumber from 'src/utils/formatPhoneNumber';
import { emailBouncedOrMarkedSpam, emailHasError } from 'src/utils/validators/email';
import { Screens } from 'src/routes/stacks/screens';
import { StyledMasterView, BottomButtonView } from 'src/components';
import CPrimaryButton from 'src/components/CPrimaryButton';
import { useUser } from 'src/providers/ClientProvider';
import { StyledHeader } from './style';
import { ScrollView } from 'react-native';
import { GuideElement, STEPS, TourGuide, useTourGuide } from './tour';
import { TourGuideHandle } from 'src/module/TourGuide/model';
import { Navigators } from 'src/routes/stacks/navigators';
import { useNavigation } from '@react-navigation/native';

const ProfileUserInfo = () => {
  const { t } = useTranslation('userInfo');
  const { navigate } = useNavigation();

  const onPressRequestInfoUpdate = (): void => {
    navigate(Navigators.MAIN_STACK, { screen: Screens.PROFILE_INFORMATION_UPDATE });
  };

  const { exitTour } = useTourGuide();
  const providerRef = useRef<TourGuideHandle>(null);

  const { user } = useUser();
  if (!isDefined(user)) {
    return null;
  }

  const fullName = `${user.firstName} ${user.lastName}`;
  const addressLine2 =
    user.address && user.city && user.state && user.postalCode
      ? `${user.city}, ${user.state} ${user.postalCode}`
      : '';

  const isEmailBouncedOrMarkedSpam = emailBouncedOrMarkedSpam(user.emailVerificationStatus);

  const emailError = emailHasError(user.emailVerificationStatus) ? t('updateInfo') : undefined;

  const mobilePhoneError =
    !isDefined(user.mobilePhone) || user.mobilePhone === '' ? t('updateInfo') : undefined;

  const addressError = user.badPhysicalAddress || !user.address ? t('updateInfo') : undefined;

  return (
    <TourGuide.Provider ref={providerRef}>
      <ScrollView style={{ flex: 1 }}>
        <StyledMasterView>
          <StyledHeader>{t('header')}</StyledHeader>
          <CUserInfo label={t('name')} line1={fullName} />
          <CUserInfo
            label={t('email')}
            line1={user.email}
            error={emailError}
            contactUsVisible={isEmailBouncedOrMarkedSpam}
          />
          <CUserInfo
            label={t('address')}
            line1={user.address}
            line2={addressLine2}
            error={addressError}
          />
          <CUserInfo
            label={t('mobilePhone')}
            line1={formatPhoneNumber(user.mobilePhone)}
            error={mobilePhoneError}
          />
          <CUserInfo label={t('homePhone')} line1={formatPhoneNumber(user.homePhone)} />
          <CUserInfo label={t('workPhone')} line1={formatPhoneNumber(user.workPhone)} />
        </StyledMasterView>
      </ScrollView>
      <BottomButtonView>
        <GuideElement
          id={STEPS.INFO_UPDATE}
          body={t('userInfo:tour.update')}
          onContinue={exitTour}
          autoStart
        >
          <CPrimaryButton onPress={onPressRequestInfoUpdate}>
            {t('infoUpdateRequest')}
          </CPrimaryButton>
        </GuideElement>
      </BottomButtonView>
    </TourGuide.Provider>
  );
};

export default ProfileUserInfo;
