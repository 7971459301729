export default {
  landing: {
    email: 'email',
    password: 'password',
    submit: 'submit'
  },
  home: {
    container: 'homeScreen'
  }
};
