import React, { useMemo } from 'react';
import { X } from 'react-native-feather';
import { ChipProps, TouchableRipple } from 'react-native-paper';
import { IconSize, Size } from 'src/constants';
import style from '../scenes/ToDos/ToDoList/components/style';
import { useHoverEffect } from 'src/hooks';
import { Animated, View } from 'react-native';
import { Body } from 'src/components';
import { colorShade } from 'src/utils';
import { palette } from 'src/theme';
import { isFunction } from 'lodash';
import { IconProps } from 'src/components/Icons';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

interface Props extends Omit<ChipProps, 'children' | 'icon' | 'theme'> {
  label: string;
  isSelected: boolean;
  icon?: React.ReactNode | ((props: IconProps) => React.JSX.Element);
}
const FilterChip: React.FC<Props> = ({ isSelected, icon: Icon, label, onPress, onClose }) => {
  const { colors } = useAppTheme();
  const [ref, animation] = useHoverEffect();
  const backgroundColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [
      isSelected ? colors.primary : 'transparent',
      isSelected ? colorShade(colors.primary, 0.8) : palette.BLACK_OPACITY_20
    ]
  });

  const renderIcon = useMemo(() => {
    if (isFunction(Icon)) {
      return <Icon color={isSelected ? colors.onPrimary : colors.onSurface} size={IconSize.XS} />;
    } else {
      return Icon;
    }
  }, [Icon, colors, isSelected]);

  return (
    <StyledTouchable onPress={isSelected ? onClose : onPress} style={style.round}>
      <AnimatedView
        ref={ref}
        style={{
          borderColor: isSelected ? colors.primary : colors.placeholder,
          backgroundColor
        }}
      >
        {!!renderIcon && <View>{renderIcon}</View>}
        <Body numberOfLines={1} color={isSelected ? colors.onPrimary : colors.onSurface}>
          {label}
        </Body>

        {isSelected && (
          <View>
            <X width={Size.S} height={Size.S} color={colors.onPrimary} style={style.closeIcon} />
          </View>
        )}
      </AnimatedView>
    </StyledTouchable>
  );
};

export default FilterChip;

const StyledTouchable = styled(TouchableRipple)`
  border-radius: ${Size.L}px;
  overflow: hidden;
  pointer: cursor;
`;

const AnimatedView = styled(Animated.View)`
  border-width: 0.25px;
  padding-vertical: ${Size.XS}px;
  padding-horizontal: ${Size.S}px;
  border-radius: ${Size.L}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${Size.XS}px;
`;
