/**
 * Query keys for react-query useQuery
 */

enum QueryKeys {
  ABILITY = 'ability',
  ANNOUNCEMENTS = 'announcements',
  APPOINTMENT = 'appointment',
  APPOINTMENTS = 'appointments',
  CLIENT = 'Client',
  CLIENT_PRACTICE_CONFIGURATION = 'client_practice_configuration',
  EMERGENCY_CONTACTS = 'emergency_contacts',
  EMPLOYEES = 'employees',
  LIFELEARN_ARTICLES = 'lifelearn_articles',
  LOYALTY_COIN_BALANCE = 'loyalty_coin_balance',
  LOYALTY_TRANSACTIONS = 'loyalty_transactions',
  PATIENT = 'patient',
  PATIENT_APPOINTMENTS = 'patient_appointments',
  PATIENT_PHOTO = 'patient_photo',
  PATIENT_PRESCRIPTIONS = 'patient_prescriptions',
  PATIENT_REMINDERS = 'patient_reminders',
  PATIENTS = 'patients',
  PAGINATED_PATIENTS = 'paginated_patients',
  PAGINATED_PRESCRIPTIONS = 'paginated_prescriptions',
  PAGINATED_REMINDERS = 'paginated_reminders',
  PRACTICE = 'practice',
  PRACTICE_INFO = 'practice_info',
  PRACTICE_SEARCH = 'practice_search',
  PRACTICES = 'practices',
  PRACTICE_CONFIGURATION = 'practice_configuration',
  PRESCRIPTIONS = 'prescriptions',
  REMINDERS = 'reminders',
  REQUEST_SETTING = 'request_setting',
  REQUESTS = 'requests',
  REWARD = 'reward',
  REWARDS = 'rewards',
  SCHEDULE = 'schedule',
  SCHEDULE_OPENINGS = 'schedule_openings',
  TODOS = 'todos',
  PAGED_TODOS = 'paged-todos',
  TODO = 'todo',
  CHECK_EMAIL = 'active_client_list',
  CUSTOM_THEME = 'custom_theme',
  CLIENT_SEARCH = 'client_search',
  ON_DEMAND_SYNC = 'on_demand_sync',
  ON_DEMAND_STATUS = 'on_demand_status',
  WRITEBACK_STATUS = 'writeback_status'
}

export default QueryKeys;
