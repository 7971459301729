import React, { PropsWithChildren } from 'react';
import { ScrollView, FlatList, SectionList } from 'react-native';

// using <any, any> because we don't care about the contents of the list
export type Scrollable = ScrollView | FlatList | SectionList<any, any>;
interface Props<T extends Scrollable> extends PropsWithChildren {
  scrollRef: React.RefObject<T>;
}

const ScrollRefContext = React.createContext<React.RefObject<Scrollable> | null>(null);

export const useScrollRef = () => React.useContext(ScrollRefContext);

export const ScrollRefProvider = function <T extends Scrollable>({
  children,
  scrollRef
}: Props<T>): ReturnType<React.FC<Props<T>>> {
  return <ScrollRefContext.Provider value={scrollRef}>{children}</ScrollRefContext.Provider>;
};
