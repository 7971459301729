import { useQuery } from 'react-query';
import { QueryKeys, getSchedule } from 'src/api';
import Practice from 'src/interfaces/api/Practice';
import extractAppointmentOptions, { AppointmentOption } from './extractAppointmentOptions';
import extractColumnOptions, { ColumnOption } from './extractColumnOptions';
import extractFieldDisplayOptions, { FieldDisplayOptions } from './extractFieldDisplaySettings';

export type AppointmentOptions = {
  appointment: AppointmentOption[];
  fieldDisplay: FieldDisplayOptions;
  columns: ColumnOption[];
};

const useAppointmentOptions = (practiceId: Practice['id']) => {
  return useQuery({
    queryFn: async (): Promise<AppointmentOptions> => {
      const schedule = await getSchedule(practiceId);
      const appointment = extractAppointmentOptions(schedule);
      const fieldDisplay = extractFieldDisplayOptions(schedule);
      const columns = extractColumnOptions(schedule);
      return {
        appointment,
        fieldDisplay,
        columns
      };
    },
    queryKey: [QueryKeys.SCHEDULE, practiceId]
  });
};

export default useAppointmentOptions;

export type { AppointmentOption, ColumnOption, FieldDisplayOptions };
