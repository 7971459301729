import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { DropDown, StyledSectionHeader, Subheading } from 'src/components';
import { style } from './style';
import { Language, Margin } from 'src/constants';
import styled from 'styled-components/native';

interface Props {
  language: string;
  changeLanguage: Dispatch<SetStateAction<string>>;
}

const CChangeLanguage: React.FC<Props> = ({ language, changeLanguage }) => {
  const { t } = useTranslation('settings');

  return (
    <View style={style.languageView}>
      <StyledSectionHeader>
        <Subheading>{t('language')}</Subheading>
      </StyledSectionHeader>
      <StyledView>
        <View style={style.languagePickerView}>
          <DropDown
            mode={'outlined'}
            label={t('chooseLanguage')}
            options={[
              { label: t('englishOption'), value: Language.en },
              { label: t('spanishOption'), value: Language.es }
            ]}
            value={language}
            onChange={(value) => {
              changeLanguage(value.toString());
            }}
          />
        </View>
      </StyledView>
    </View>
  );
};

export default CChangeLanguage;

export const StyledView = styled.View`
  padding-horizontal: ${Margin.Small}px;
  margin-bottom: ${Margin.Medium}px;
`;
