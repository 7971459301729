import { useFormik } from 'formik';
import React, { SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertHandle,
  Body,
  BottomButton,
  Caption,
  DropDown,
  HoverButton,
  TextInput
} from 'src/components';
import usePaginatedPatients from 'src/hooks/react-query/usePaginatedPatients';
import { FormikData, initialValues, populateConfirmationArray, validationSchema } from './shared';
import styled from 'styled-components/native';
import { useBoolean, useRequestSetting } from 'src/hooks';
import { LayoutAnimation, Platform, View } from 'react-native';
import { Size } from 'src/constants';
import { Weight, palette } from 'src/theme';
import color from 'color';
import UploadFiles, { ImageUpload } from 'src/components/UploadFiles';
import { useUser } from 'src/providers/ClientProvider';
import { useMutation } from 'react-query';
import { QueryKeys, postRequest } from 'src/api';
import Toast from 'react-native-toast-message';
import { RefillRequest, RequestType } from 'src/interfaces';
import { isDefined, queryClient } from 'src/utils';
import useGoBack from 'src/hooks/useGoBack';
import ConfirmChanges from './ConfirmChanges';
import Alert, { AlertOption } from 'src/components/Alert';

const ProductRequest: React.FC = () => {
  const { t } = useTranslation('requests');
  const goBack = useGoBack();
  const { user } = useUser();
  const { data: patients } = usePaginatedPatients({});

  const [infoUpdates, setInfoUpdates] = useState<Array<{ label: string; value?: string }>>([]);

  const { data: requestSetting } = useRequestSetting();

  const { mutateAsync: submitProductRequest } = useMutation(
    async (request: RefillRequest) => await postRequest(request),
    {
      onSuccess: () => {
        if (requestSetting?.refillRequestConfirmationTextSanitized) {
          confirmedModalRef.current?.alert();
        } else {
          Toast.show({
            type: 'success',
            text1: t('common:success')
          });
          goBack();
        }
      },
      onError: (error: Error) => {
        Toast.show({
          type: 'error',
          text1: t('appointmentRequest:errorToast'),
          text2: error.name
        });
      }
    }
  );

  const {
    value: formIsSubmitted,
    toTrue: showConfirmScreen,
    toFalse: cancelSubmit
  } = useBoolean(false);

  const patientOptions = useMemo(
    () =>
      patients
        .map((patient) => ({
          label: patient.name,
          value: patient.patientId
        }))
        .concat({
          label: t('requestFields.newPet'),
          value: 'New Pet'
        }),
    [patients, t]
  );

  const { values, errors, setFieldValue, handleSubmit, dirty, validateForm } =
    useFormik<FormikData>({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        const requestImageIds = Object.values(values.images)
          .map((imageUpload: ImageUpload) => imageUpload.id)
          .filter(isDefined);
        await submitProductRequest({
          type: RequestType.Refill,
          practiceId: user.practiceId,
          patientId: values.patientId,
          clientId: user.clientId,
          sourceId: user.sourceId,
          content: {
            preferredContactMethod: values.preferredContactMethod,
            origin: Platform.OS,
            petName: values.petName,
            refill: values.itemDetails,
            quantity: parseInt(values.quantity ?? '0'),
            comments: values.comments
          },
          requestImageIds
        });

        void queryClient.invalidateQueries([QueryKeys.REQUESTS]);
      }
    });

  const [images, setImages] = useState<Record<string, ImageUpload>>(values.images ?? {});
  const onImageChange = useCallback(
    async (action: SetStateAction<Record<string, ImageUpload>>) => {
      setImages((prev) => {
        const value = typeof action === 'function' ? action(prev) : action;
        void setFieldValue('images', value);
        return value;
      });
    },
    [setFieldValue]
  );

  const bottomButtonPressed = async () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    if (!formIsSubmitted && values.patientId) {
      const errors = await validateForm();
      if (Object.keys(errors).length) return;

      infoUpdates.push({
        label: t('wizard.petName'),
        value: values.petName?.length
          ? values.petName
          : patientOptions.find((p) => p.value === values.patientId)?.label
      });
      populateConfirmationArray(values, initialValues, infoUpdates);
      showConfirmScreen();
    } else handleSubmit();
  };

  const cancelPressed = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setInfoUpdates([]);
    cancelSubmit();
  };

  const confirmedModalRef = useRef<AlertHandle>(null);
  const confirmedOptions: AlertOption[] = useMemo(
    () => [
      {
        label: t('common:OK'),
        type: 'destructive',
        action: () => goBack()
      }
    ],
    [goBack, t]
  );

  const submitIsDisabled = !dirty || !!errors.images;

  if (formIsSubmitted) {
    return (
      <>
        <Container>
          <ConfirmChanges
            infoUpdates={infoUpdates}
            images={images}
            preferredContactMethod={values.preferredContactMethod}
            setFieldValue={setFieldValue}
          />
          <View>
            <HoverButton mode='text' onPress={() => cancelPressed()}>
              {t('common:cancel')}
            </HoverButton>
            <BottomButton onPress={bottomButtonPressed} disabled={submitIsDisabled}>
              {t('requests:wizard.looksGood')}
            </BottomButton>
          </View>
        </Container>
        <Alert
          ref={confirmedModalRef}
          title={t('patientProfile:requestSent')}
          options={confirmedOptions}
          body={requestSetting?.refillRequestConfirmationTextSanitized}
        />
      </>
    );
  }

  return (
    <Container>
      <FormContainer>
        {requestSetting?.refillRequestHeaderTextSanitized && (
          <RefillRequestHeader>
            {requestSetting.refillRequestHeaderTextSanitized}
          </RefillRequestHeader>
        )}
        <DropDown
          label={t('wizard.selectPatient')}
          options={patientOptions}
          value={values.patientId}
          onChange={async (value) => await setFieldValue('patientId', value)}
          error={!!errors?.patientId}
          msg={errors?.patientId}
        />
        {values.patientId === 'New Pet' && (
          <TextInput
            label={t('wizard.petName')}
            onChangeText={async (value) => await setFieldValue('petName', value)}
            value={values.petName}
            error={!!errors?.petName}
            msg={errors?.petName}
          />
        )}
        <TextInput
          label={t('wizard.itemDetails')}
          onChangeText={async (value) => await setFieldValue('itemDetails', value)}
          value={values.itemDetails}
          error={!!errors?.itemDetails}
          msg={errors?.itemDetails}
        />
        <TextInput
          label={t('wizard.quantity')}
          keyboardType='numeric'
          onChangeText={async (value) => await setFieldValue('quantity', value)}
          value={values.quantity}
          error={!!errors?.quantity}
          msg={errors?.quantity}
        />
        <TextInput
          label={t('wizard.comments')}
          onChangeText={async (value) => await setFieldValue('comments', value)}
          value={values.comments}
          error={!!errors?.comments}
          msg={errors?.comments}
        />
        <UploadContainer>
          <Label error={!!errors.images} hasData={!!Object.keys(values.images).length}>
            {t('common:addFiles')}
          </Label>
          <UploadFiles onImageChange={onImageChange} images={images} />
        </UploadContainer>
        {typeof errors.images === 'string' && <ErrorCaption>{errors.images}</ErrorCaption>}
      </FormContainer>
      <View>
        <BottomButton onPress={bottomButtonPressed} disabled={submitIsDisabled}>
          {t('wizard.submit')}
        </BottomButton>
      </View>
    </Container>
  );
};

export default ProductRequest;

const Container = styled.View`
  flex: 1;
  justify-content: space-between;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
`;

const FormContainer = styled.ScrollView.attrs({
  contentContainerStyle: { gap: Size.X3_S }
})`
  padding: ${Size.X3_S}px;
`;

const Label = styled(Body)<{ error: boolean; hasData: boolean }>`
  color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.placeholder)};
  font-size: ${({ hasData }) => (hasData ? Size.XS : Size.S)}px;
  margin-bottom: ${({ hasData }) => (hasData ? -Size.S : 0)}px;
  margin-top: ${({ hasData }) => (hasData ? -Size.X3_S : Size.X3_S)}px;
  margin-left: ${-Size.X4_S}px;
  font-weight: ${Weight.REGULAR};
`;

const UploadContainer = styled.View`
  padding-top: ${Size.S}px;
  padding-horizontal: ${Size.S}px;
  background-color: ${({ theme }) =>
    theme.dark
      ? color(theme.colors.background).lighten(0.24).rgb().string()
      : color(theme.colors.background).darken(0.06).rgb().string()};
  color: ${({ theme }) => (theme.dark ? palette.WHITE_OPACITY_54 : palette.BLACK_OPACITY_54)};
  border-bottom-width: ${Size.X4_S}px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
`;

const ErrorCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.error};
`;

const RefillRequestHeader = styled(Body)`
  padding: ${Size.S}px;
`;
