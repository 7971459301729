import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Body, BottomButton, HoverButton, Row, Subheading, Caption } from 'src/components';
import { IconSize, Size } from 'src/constants';
import { fontStyles, layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import { Screens } from 'src/routes/stacks/screens';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { View } from 'react-native';
import useGoBack from 'src/hooks/useGoBack';
import FaceRecognition from 'src/components/Icons/FaceRecognition';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { useBiometricsPrompt } from 'src/providers/BiometricsPromptProvider';
import { Weight } from 'src/theme';
import useBiometrics from 'src/hooks/useBiometrics';
import * as LocalAuthentication from 'expo-local-authentication';
import Toast from 'react-native-toast-message';
import { useAuth } from 'src/providers/AuthProvider';
import { useLinkTo } from '@react-navigation/native';
import { useUser } from 'src/providers/ClientProvider';
import { usePractice } from 'src/hooks';

const BiometricLoginOptIn: React.FC<
  NativeStackScreenProps<MainStackParamsList, Screens.BIOMETRIC_LOGIN_OPT_IN>
> = ({ navigation }) => {
  const { t } = useTranslation('biometricLogin');
  const { colors } = useAppTheme();
  const { setNeedsPrompt, setBiometricAuthAppSetting, hasCredentials } = useBiometricsPrompt();
  const { logOut } = useAuth();
  const { user } = useUser();
  const { data: practice } = usePractice();

  const linkTo = useLinkTo();

  const { supportsFaceRecognition } = useBiometrics();

  const goBack = useGoBack();

  const enableBiometrics = async () => {
    if (!hasCredentials) {
      await logOut();
      setBiometricAuthAppSetting(undefined);
      linkTo(`/?email=${user.email}&practice=${practice?.shortName ?? ''}`);
      return;
    }
    try {
      const result = await LocalAuthentication.authenticateAsync();
      if (result.success) {
        setBiometricAuthAppSetting(true);
        setNeedsPrompt(false);
        goBack();
      } else {
        throw new Error('Biometric authentication failed');
      }
    } catch {
      Toast.show({
        type: 'error',
        text1: t(supportsFaceRecognition ? 'faceRecognitionFailed' : 'fingerprintRecognitionFailed')
      });
    }
  };

  const disableBiometrics = () => {
    setBiometricAuthAppSetting(false);
    setNeedsPrompt(false);
    goBack();
  };

  return (
    <>
      <View style={layout.flex1}>
        <HeroContainer>
          {supportsFaceRecognition ? (
            <FaceRecognition size={IconSize.XXL} color={colors.primary} />
          ) : (
            <Icon name='fingerprint' size={IconSize.XXL} color={colors.primary} />
          )}
        </HeroContainer>
        <Content style={layout.flex1}>
          <Subheading>
            {supportsFaceRecognition ? t('enableToFaceId') : t('enableToFingerprint')}
          </Subheading>
          <Row justify='flex-start'>
            <Bullet />
            <Body>
              <Trans
                i18nKey={t('point1')}
                components={{ strong: <Body fontWeight={Weight.BOLD} /> }}
              />
            </Body>
          </Row>
          <Row justify='flex-start'>
            <Bullet />
            <Body>
              <Trans
                i18nKey={t('point2')}
                components={{ strong: <Body fontWeight={Weight.BOLD} /> }}
              />
            </Body>
          </Row>
          {!hasCredentials && <Caption>{t('logoutRequired')}</Caption>}
        </Content>
      </View>
      <HoverButton onPress={disableBiometrics}>{t('notNow')}</HoverButton>
      <BottomButton onPress={enableBiometrics}>{t('enableBiometricLogin')}</BottomButton>
    </>
  );
};

export default BiometricLoginOptIn;

const HeroContainer = styled.View`
  align-self: center;
  margin-vertical: ${Size.X2_L}px;
`;

const Content = styled.View`
  ${({ theme }) => (theme.viewMode.isMobile ? 'flex: 1;' : '')}
  margin: ${Size.M}px;
  gap: ${Size.S}px;
`;

const Bullet = styled.View`
  width: ${Size.X3_S}px;
  height: ${Size.X3_S}px;
  border-radius: ${Size.X3_S}px;
  background-color: ${({ theme }) => theme.colors.text};
  align-self: flex-start;
  margin-top: ${fontStyles.body.lineHeight / 2 - Size.X4_S}px;
  margin-right: ${Size.XS}px;
`;
