import { useMemo } from 'react';
import { LifelearnArticle } from 'src/interfaces';

const useSearchResults = (searchText: string, articles: LifelearnArticle[] | undefined) => {
  return useMemo(() => {
    if (!articles) return undefined;
    return articles.filter(
      (article) =>
        !searchText ||
        article.title.toLowerCase().includes(searchText.toLowerCase()) ||
        article.summary.toLowerCase().includes(searchText.toLowerCase()) ||
        article.content.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [articles, searchText]);
};

export default useSearchResults;
