export enum Species {
  AVIAN = 'avian',
  BIRD = 'bird',
  BOVINE = 'bovine',
  BUNNY = 'bunny',
  CANINE = 'canine',
  CAT = 'cat',
  COW = 'cow',
  DOG = 'dog',
  EQUINE = 'equine',
  FELINE = 'feline',
  GECKO = 'gecko',
  HORSE = 'horse',
  LAGOMORPH = 'lagomorph',
  OTHER = 'other',
  RODENT = 'rodent'
}
