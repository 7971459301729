import _ from 'lodash';
import { unMask } from 'react-native-mask-text';
import { ZIP_MASK } from 'src/constants';
import { Client } from 'src/interfaces/api';
import { InfoUpdateItem } from 'src/interfaces/api/InfoUpdateRequest';
import { multiMask } from 'src/utils';
import { AnySchema, object, string } from 'yup';

export const validationSchema = object<Record<keyof (FormData & SupplementalData), AnySchema>>({
  firstName: string().required('common:required'),
  lastName: string().required('common:required'),
  email: string().email('informationUpdate:invalidEmail').required('common:required'),
  city: string().required('common:required').nullable(),
  state: string().required('common:required').nullable(),
  postalCode: string()
    .matches(/^(\d{5}|\d{9}|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d)$/, 'common:invalid')
    .required('common:required')
    .nullable(),
  mobilePhone: string().length(10, 'common:invalid').nullable(),
  workPhone: string().length(10, 'common:invalid').nullable(),
  homePhone: string().length(10, 'common:invalid').nullable(),
  phone: string().test({
    test: (_, context) => {
      return !!(context.parent.homePhone ?? context.parent.mobilePhone ?? context.parent.workPhone);
    },
    message: 'informationUpdate:onePhoneRequired'
  }),
  addressLine1: string().required().nullable(),
  addressLine2: string()
});

export const fieldNames = [
  'firstName',
  'lastName',
  'email',
  'city',
  'state',
  'postalCode',
  'homePhone',
  'workPhone',
  'mobilePhone'
] as const;

export type ProfileInfoFormFields = (typeof fieldNames)[number];

export type FormData = Pick<Client, ProfileInfoFormFields> & {
  addressLine1: string;
  addressLine2: string;
};

export interface SupplementalData {
  phone: string;
  addressLine1: string;
  addressLine2: string;
}

export type CompleteData = FormData & SupplementalData;

export const isFieldDirty = (
  field: keyof CompleteData,
  values: CompleteData,
  initialValues: CompleteData
): boolean => values[field] !== initialValues[field];

export const addressFormatter = ({
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode
}: FormData & SupplementalData): string => {
  if (!addressLine1 || !city || !state || !postalCode) return '';
  return `${addressLine1}${addressLine2 && `\n${addressLine2}`}\n${city}, ${state} ${multiMask(
    postalCode,
    ZIP_MASK
  ).toUpperCase()}`;
};

export const addressFields: Array<keyof CompleteData> = [
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'postalCode'
];
export const nameFields: Array<keyof CompleteData> = ['firstName', 'lastName'];

export const initialValuesBuilder = (user: Client): FormData => {
  const address = user.address ? user.address.split('\n') : '';
  const values: FormData = _.defaults(
    {
      addressLine1: address[0] ?? '',
      addressLine2: address[1] ?? '',
      homePhone: unMask(user.homePhone ?? ''),
      mobilePhone: unMask(user.mobilePhone ?? ''),
      workPhone: unMask(user.workPhone ?? ''),
      postalCode: unMask(user.postalCode ?? '')
    },
    _.pick(user, fieldNames)
  ) as FormData;
  return values;
};

export const createRequestItemArray = (values: CompleteData, initialValues: CompleteData) => {
  const contentPayloadItems: InfoUpdateItem[] = [];

  if (nameFields.some((f) => isFieldDirty(f, values, initialValues))) {
    contentPayloadItems.push({
      dataType: 'Name',
      newValue: `${values.firstName} ${values.lastName}`
    });
  }

  if (addressFields.some((f) => isFieldDirty(f, values, initialValues))) {
    const fullAddressText = addressFormatter(values);
    contentPayloadItems.push({
      dataType: 'Address',
      newValue: fullAddressText
    });
  }

  if (isFieldDirty('mobilePhone', values, initialValues)) {
    contentPayloadItems.push({
      dataType: 'Cell Phone',
      newValue: values.mobilePhone
    });
  }

  if (isFieldDirty('workPhone', values, initialValues)) {
    contentPayloadItems.push({
      dataType: 'Work Phone',
      newValue: values.workPhone
    });
  }

  if (isFieldDirty('homePhone', values, initialValues)) {
    contentPayloadItems.push({
      dataType: 'Home Phone',
      newValue: values.homePhone
    });
  }

  if (isFieldDirty('email', values, initialValues)) {
    contentPayloadItems.push({
      dataType: 'Email',
      newValue: values.email
    });
  }

  return contentPayloadItems;
};
