import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPatients, QueryKeys } from 'src/api';
import { ModalDrawer, Headline, ChipContainer } from 'src/components';
import { ModalDrawerHandle } from 'src/components/ModalDrawer';
import PatientAvatar from 'src/components/PatientAvatar';
import { Size } from 'src/constants';
import { Patient, TodoReason } from 'src/interfaces';
import { ForwardRefFC } from 'src/types';
import styled from 'styled-components/native';
import Chip from '../../../../components/Chip';
import style from './style';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { isPatientActive } from 'src/utils';
import { ReasonMap } from './model';

interface Props {
  patientId: Patient['patientId'] | undefined;
  reason: TodoReason | undefined;
  onSelectFilters: (filters: {
    patientId: Patient['patientId'] | undefined;
    reason: TodoReason | undefined;
  }) => void;
}

const FiltersDrawer: ForwardRefFC<ModalDrawerHandle, Props> = React.forwardRef<
  ModalDrawerHandle,
  Props
>(({ onSelectFilters, patientId: selectedPatient, reason: selectedReason }, ref) => {
  const { t } = useTranslation('todos');
  const { colors } = useAppTheme();

  const { data: patients = [] } = useQuery({
    queryFn: getPatients,
    queryKey: [QueryKeys.PATIENTS],
    select: (ps) => ps.filter(isPatientActive)
  });

  const [pet, setPet] = useState<Patient['patientId'] | undefined>();
  const [reason, setReason] = useState<TodoReason | undefined>();

  useEffect(() => {
    setPet(selectedPatient);
  }, [selectedPatient]);

  useEffect(() => {
    setReason(selectedReason);
  }, [selectedReason]);

  return (
    <ModalDrawer
      ref={ref}
      onClose={() => {
        setPet(selectedPatient);
        setReason(selectedReason);
      }}
      bottomButton={{
        onPress: () => onSelectFilters?.({ patientId: pet, reason }),
        label: t('applyFilters', { ns: 'common' })
      }}
      cardStyle={style.drawerCardStyle}
      contentContainerStyle={style.drawerContentContainerStyle}
    >
      <StyledHeadline>{t('pet', { ns: 'common' })}</StyledHeadline>
      <ChipContainer>
        {patients.map((patient) => (
          <Chip
            icon={(props) => <PatientAvatar patient={patient} {...props} />}
            key={patient.patientId}
            label={patient.name}
            isSelected={patient.patientId === pet}
            onClose={() => setPet(undefined)}
            onPress={() => setPet(patient.patientId)}
          />
        ))}
      </ChipContainer>
      <StyledHeadline>{t('list.reason')}</StyledHeadline>
      <ChipContainer>
        {Object.values(ReasonMap).map(({ value, label, color, icon: Icon }) => (
          <Chip
            key={value}
            label={t(label)}
            icon={(props: { size: number }) => (
              <Icon {...props} color={reason === value ? colors.onPrimary : colors[color]} />
            )}
            isSelected={reason === value}
            onClose={() => setReason(undefined)}
            onPress={() => setReason(value)}
          />
        ))}
      </ChipContainer>
    </ModalDrawer>
  );
});

export default FiltersDrawer;

const StyledHeadline = styled(Headline)`
  margin-horizontal: ${Size.M}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;
