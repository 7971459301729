const colorToValue = (color: string): number => {
  const rgbMatch = color.match(/^rgba?\((\d+), ?(\d+), ?(\d+),? ?(\d+?(\.\d+)?)?\)$/);

  if (rgbMatch) {
    const [, red, green, blue] = rgbMatch;
    return rgbToNumber(parseInt(red), parseInt(green), parseInt(blue));
  }

  const hexMatch = color.match(
    /^#([\da-fA-F]{1,2})([\da-fA-F]{1,2})([\da-fA-F]{1,2})([\da-fA-F]{1,2})?$/
  );
  if (hexMatch) {
    const [, red, green, blue] = hexMatch;
    return rgbToNumber(parseInt(red, 16), parseInt(green, 16), parseInt(blue, 16));
  }
  throw new Error(`${color} is not a valid color`);
};

const rgbToNumber = (red: number, green: number, blue: number): number =>
  red * 0.299 + green * 0.587 + blue * 0.114;

export const isDarkColor = (value: string): boolean => colorToValue(value) < 186;
export default colorToValue;

export const colorShade = (color: string, percentage: number) => {
  color = color.replace(/^#/, '');
  if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];

  const match = color.match(/.{2}/g);
  if (match) {
    let [_r, _g, _b, _a] = match;

    const [r, g, b] = [
      Math.floor(parseInt(_r, 16) * percentage),
      Math.floor(parseInt(_g, 16) * percentage),
      Math.floor(parseInt(_b, 16) * percentage)
    ];

    _r = Math.max(Math.min(255, r), 0).toString(16);
    _g = Math.max(Math.min(255, g), 0).toString(16);
    _b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (_r.length < 2 ? '0' : '') + _r;
    const gg = (_g.length < 2 ? '0' : '') + _g;
    const bb = (_b.length < 2 ? '0' : '') + _b;

    return `#${rr}${gg}${bb}${_a ?? ''}`;
  }

  return color;
};

export const transparent = (color: string, percentage: number) => {
  color = color.replace(/^#/, '');
  if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];

  const match = color.match(/.{2}/g);
  if (match) {
    const [r, g, b] = match;
    const a = Math.max(Math.min(255, Math.floor(percentage * 255)), 0)
      .toString(16)
      .padStart(2, '0');
    color = `#${r}${g}${b}${a}`;
  }
  return color;
};
