import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { QueryKeys, getLifelearnArticles } from 'src/api';
import { LifelearnArticle, LifelearnArticlesResponse } from 'src/interfaces';

const PLACEHOLDER: LifelearnArticlesResponse = {
  articles: []
};

interface Options
  extends UseQueryOptions<
    LifelearnArticlesResponse,
    unknown,
    LifelearnArticlesResponse,
    QueryKeys[]
  > {
  articleId?: LifelearnArticle['id'];
}

export const lifelearnOptions: UseQueryOptions<
  LifelearnArticlesResponse,
  unknown,
  LifelearnArticlesResponse,
  QueryKeys[]
> = {
  queryKey: [QueryKeys.LIFELEARN_ARTICLES],
  queryFn: getLifelearnArticles
};

const useLifelearnArticles = ({ articleId, ...options }: Options) => {
  const { data = PLACEHOLDER, ...rest } = useQuery({
    ...lifelearnOptions,
    ...options
  });

  const article = useMemo(() => {
    return articleId ? data?.articles?.find((a) => a.id === articleId) : undefined;
  }, [articleId, data?.articles]);

  return { articles: data?.articles, article, ...rest };
};

export default useLifelearnArticles;
