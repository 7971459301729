import Color from 'color';
import { cloneDeep } from 'lodash';
import { AnimationObject } from 'lottie-react-native';

export type ColorParam = Color | string | ArrayLike<number> | number | { [key: string]: any };

export const lottieWholeColorReplace = (lottieData: AnimationObject, color: ColorParam) => {
  const cloned = cloneDeep(lottieData);
  cloned.assets.forEach((asset) => {
    asset.layers.forEach((layer: { shapes: any[] }) => {
      layer.shapes.forEach((shape) => {
        shape.it.forEach((item: { c: { k: number[] } }) => {
          if (item.c) {
            item.c.k = Color(color)
              .rgb()
              .array()
              .map((c) => c / 255);
          }
        });
      });
    });
  });
  return cloned;
};
