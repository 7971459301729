import { NewRequest, RequestType } from './Request';

export interface AppointmentRequest extends Omit<NewRequest, 'content' | 'type'> {
  type: RequestType.Appointment;
  content: AppointmentRequestContent;
  newClient?: boolean;
}

export interface AppointmentRequestContent {
  preferredDate?: string; // YYYY-MM-DD e.g. "2023-02-21"
  preferredTime?: string; // HH:mm e.g. "11:00 AM"
  preferredContactMethod?: PreferredContactMethod;
  appointmentLength?: number; // unclear on when this is sent vs length
  appointmentType?: string;
  appointmentTypeId?: number;
  categoryId?: number;
  categoryType?: string;
  column?: string; // "No Preference" or from With whom / What resource
  comments?: string;
  emailAddress?: string;
  fullName?: string;
  length?: number; // unclear on when this is sent vs appointmentLength
  phoneNumber?: string;
  smsOptIn?: boolean;
  smsOptOut?: boolean;
  useAppointmentTypes?: boolean;
  petName?: string; // only sent if it's a new pet
  origin?: string; // added so we can more easily track where request is from
  isNewPatient?: boolean;
  petAge?: string;
  breed?: string;
  petSex?: string;
}

export enum PreferredContactMethod {
  Email = 'email',
  Phone = 'phone',
  Text = 'text'
}
