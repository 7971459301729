import React from 'react';
import { ChevronDown } from 'react-native-feather';
import { Menu } from 'react-native-paper';
import styled from 'styled-components/native';
import { Body, HoverButton } from 'src/components';
import { Margin, PATIENT_STATUSES } from 'src/constants';
import { useBoolean } from 'src/hooks';
import { Weight, palette } from 'src/theme';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { fontStyles } from 'src/theme/globalStyles';

interface Props {
  selectedStatus?: PATIENT_STATUSES;
  setSelectedStatus: (patientStatus?: PATIENT_STATUSES) => void;
}

const PatientStatusSelector: React.FC<Props> = ({ selectedStatus, setSelectedStatus }) => {
  const { toTrue: openMenu, toFalse: closeMenu, value: menuOpen } = useBoolean();
  const { t } = useTranslation('petList');

  const selectAndClose = (patientStatus?: PATIENT_STATUSES) => {
    setSelectedStatus(patientStatus);
    closeMenu();
  };

  return (
    <>
      <Menu
        visible={menuOpen}
        onDismiss={closeMenu}
        anchor={
          <Selector onPress={openMenu} icon={ChevronDown}>
            {t('viewingPets', {
              status: selectedStatus ? t(selectedStatus, { count: 0 }) : t('all')
            })}
          </Selector>
        }
      >
        {(Object.keys(PATIENT_STATUSES) as Array<keyof typeof PATIENT_STATUSES>).map((key) => {
          return (
            <SelectorOption key={key} onPress={() => selectAndClose(PATIENT_STATUSES[key])}>
              <Body>{_.capitalize(t(PATIENT_STATUSES[key], { count: 0 }))}</Body>
            </SelectorOption>
          );
        })}
        <SelectorOption onPress={() => selectAndClose(undefined)}>
          <Body>{_.capitalize(t('all', { ns: 'common' }))}</Body>
        </SelectorOption>
      </Menu>
    </>
  );
};

export default PatientStatusSelector;

const Selector = styled(HoverButton).attrs(({ theme }) => ({
  hideShadow: true,
  hoverColor: palette.BLACK_OPACITY_20,
  buttonColor: theme.colors.onPrimary,
  compact: true,
  labelStyle: {
    ...fontStyles.body,
    fontWeight: Weight.BOLD
  },
  uppercase: false
}))`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  align-self: center;
  padding: 0px;
`;

const SelectorOption = styled.Pressable<{ backgroundColor?: string }>`
  ${({ backgroundColor }) =>
    `
  flex-direction: row;
  align-items: center;
  padding-vertical: ${Margin.Medium}px;
  padding-left: ${Margin.Large}px;
  padding-right: ${Margin.ExtraLarge}px;
  background-color: ${backgroundColor ?? palette.TRANSPARENT};
  `}
`;
