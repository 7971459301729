import React, { Suspense, useEffect, useState } from 'react';
import { Appointment, Patient } from 'src/interfaces';
import { useBoolean, usePractice, useRequestSetting } from 'src/hooks';
import moment from 'moment-timezone';
import ItemDetail from './ItemDetail';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import { CallToAction, FullActivityIndicator, ListContainer } from './shared';
import HeaderToggle from './HeaderToggle';
import { LayoutAnimation } from 'react-native';
import useAppointments from 'src/scenes/Appointments/AppointmentsRoot/hooks/useAppointments';
import { toLocalDateTime } from 'src/utils';
import AppointmentCardNone from 'src/scenes/Appointments/AppointmentsRoot/components/NextAppointmentCard/AppointmentCardNone';
import BottomTabParamsList from 'src/routes/stacks/BottomTabNavigator/ParamsList';
import { Navigators } from 'src/routes/stacks/navigators';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'src/components/kit/ABIcons';
import { HoverCard } from 'src/components';

const MAX_ITEMS = 3;

interface Props {
  patientId?: Patient['patientId'];
  maxItems?: number;
}
const AppointmentsCard: React.FC<Props> = ({ patientId, maxItems = MAX_ITEMS }) => {
  const { t } = useTranslation('home');
  const { value: isOpen, toggle } = useBoolean(true);
  const { navigate } = useNavigation<NavigationProp<BottomTabParamsList>>();
  const [count, setCount] = useState(0);
  const [appointments, setAppointments] = useState<Appointment[]>();
  const { data: requestSettings, isLoading: requestLoading } = useRequestSetting();
  const { data, isLoading: appointmentsLoading } = useAppointments({
    selectedPatient: patientId,
    suspense: false
  });
  const { data: practice } = usePractice();

  const isLoading = appointmentsLoading || requestLoading;

  useEffect(() => {
    const upcoming = data.future.filter((v) => !patientId || patientId === v.patientId);
    setCount(upcoming.length);
    setAppointments([...upcoming.slice(0, maxItems)]);
  }, [data, maxItems, patientId]);

  const navToAppointmentDetail = (id: Appointment['appointmentId']) =>
    navigate(Navigators.APPOINTMENTS_STACK, {
      screen: Screens.APPOINTMENT_DETAILS,
      params: { id },
      initial: false
    });

  return (
    <HoverCard>
      <HeaderToggle
        icon={({ color, size }) => <Calendar color={color} width={size} height={size} />}
        label={t('appointments')}
        toggle={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          toggle();
        }}
        count={count}
      />
      {isOpen && !isLoading && (
        <Suspense fallback={<FullActivityIndicator />}>
          <ListContainer>
            {appointments?.map((appointment) => (
              <ItemDetail
                onPress={() => navToAppointmentDetail(appointment.appointmentId)}
                patientId={appointment.patientId}
                date={toLocalDateTime(moment.utc(appointment.startsAt), practice?.timeZone)}
                key={appointment.appointmentId}
              />
            ))}
          </ListContainer>
        </Suspense>
      )}
      {isOpen && !isLoading && !appointments?.length && (
        <AppointmentCardNone patientId={patientId} />
      )}
      {isOpen && isLoading && <FullActivityIndicator />}
      {isOpen &&
        (isLoading || !!appointments?.length || requestSettings?.appointmentRequestsDisabled) && (
          <CallToAction
            onPress={() =>
              navigate(Navigators.APPOINTMENTS_STACK, {
                screen: Screens.APPOINTMENTS,
                params: { id: patientId }
              })
            }
          >
            {t('viewAll')}
          </CallToAction>
        )}
    </HoverCard>
  );
};

export default AppointmentsCard;
