import React, { useEffect, useState } from 'react';
import { endpoint, Margin } from 'src/constants';
import { Body, Row, StyledKeyboardAwareScrollView, StyledResponsiveView } from 'src/components';
import { Checkbox } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { layout } from 'src/theme/globalStyles';
import CPrimaryButton from 'src/components/CPrimaryButton';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import TermsHtml from './TermsHtml';
import { useNavigation } from '@react-navigation/native';
import { useMutation } from 'react-query';
import { apiClient } from 'src/utils/axios';
import { useAuth } from 'src/providers/AuthProvider';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const TermsAndConditions: React.FC = () => {
  const { resetError } = useAuth();
  const { setOptions } = useNavigation();
  const { t } = useTranslation('eula');
  const [agree, setAgree] = useState(false);
  const { colors } = useAppTheme();

  useEffect(() => {
    setOptions({
      title: t('title'),
      headerShadowVisible: false
    });
  }, [setOptions, t]);

  const { mutate } = useMutation({
    mutationFn: async (accept: boolean) =>
      await apiClient.post(endpoint('TERMS'), {
        terms: {
          accept,
          signature: ''
        }
      }),
    onSuccess: resetError
  });

  return (
    <SafeAreaView edges={['bottom']} style={layout.flex1}>
      <ContentContainer>
        <Body textAlign='center'>{t('pleaseReadTheTerms')}</Body>
      </ContentContainer>
      <StyledKeyboardAwareScrollView
        style={{
          paddingTop: Margin.ExtraLarge,
          paddingHorizontal: Margin.ExtraLarge,
          marginBottom: Margin.Medium
        }}
      >
        <TermsHtml />
      </StyledKeyboardAwareScrollView>
      <StyledResponsiveView>
        <ContentContainer>
          <Row justify={'flex-start'}>
            <Checkbox.Android
              status={agree ? 'checked' : 'unchecked'}
              onPress={() => setAgree((prev) => !prev)}
              color={colors.primary}
            />
            <Body>{t('iAccept')}</Body>
          </Row>
          <Body>{t('byCheckingThisBox')}</Body>
        </ContentContainer>
      </StyledResponsiveView>
      <ContentContainer>
        <StyledPrimaryButton disabled={!agree} onPress={() => mutate(agree)}>
          {t('continue', { ns: 'common' })}
        </StyledPrimaryButton>
      </ContentContainer>
    </SafeAreaView>
  );
};

const StyledPrimaryButton = styled(CPrimaryButton)`
  margin: ${Margin.Large}px;
`;

const ContentContainer = styled.View`
  padding-horizontal: ${Margin.Medium}px;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
`;

export default TermsAndConditions;
