import { LinearGradient } from 'expo-linear-gradient';
import { ActivityIndicator, TouchableRipple } from 'react-native-paper';
import { Body, HoverButton, Title } from 'src/components';
import CPrimaryButton from 'src/components/CPrimaryButton';
import { MOBILE_WIDTH_MAX, Margin, Size, IS_WEB } from 'src/constants';
import { Weight } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';
import { colorShade } from 'src/utils';
import styled from 'styled-components/native';

export const InnerContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    maxWidth: MOBILE_WIDTH_MAX,
    marginHorizontal: IS_WEB ? undefined : Size.X2_S,
    alignSelf: IS_WEB ? 'center' : 'stretch',
    flexGrow: 1
  }
})`
  flex-grow: 1;
  align-self: stretch;
`;

export const AllyBlueGradient = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.colors.primary, colorShade(theme.colors.primary, 0.8)]
}))`
  flex: 1;
  padding-horizontal: ${Margin.Large}px;
`;

export const ScreenTitle = styled(Title)`
  align-self: center;
`;

export const StyledTouchable = styled(TouchableRipple)`
  margin-top: ${Margin.Medium}px;
`;
export const PracticeRow = styled(HoverButton).attrs(({ disabled, theme, mode }) => ({
  mode: mode ?? 'outlined',
  buttonColor: disabled ? theme.colors.disabled : theme.colors.onPrimary,
  labelStyle: { ...fontStyles.subheading, flex: 1 },
  numberOfLines: 1,
  contentStyle: {
    alignSelf: 'stretch',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  }
}))<{ selected?: boolean }>`
  margin-top: ${Margin.Medium}px;
  max-width: 100%;
  ${({ disabled, theme }) => (disabled ? theme.colors.disabled : theme.colors.onPrimary)};
`;

export const UnselectableRow = styled(HoverButton).attrs(({ theme }) => ({
  mode: 'outlined',
  buttonColor: theme.colors.onPrimary,
  labelStyle: fontStyles.subheading,
  disabled: true,
  contentStyle: {
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  }
}))`
  margin-top: ${Margin.Medium}px;
`;

export const ButtonView = styled.View`
  margin-top: ${Margin.ExtraLarge}px;
`;

export const ConfirmButton = styled(CPrimaryButton)`
  border-radius: ${Size.X2_S}px;
  margin-top: ${Margin.Large}px;
`;

export const LoadingIndicator = styled(ActivityIndicator).attrs(({ theme }) => ({
  size: 'large',
  color: theme.colors.onPrimary
}))`
  flex: 1;
`;

export const Section = styled.View`
  margin-top: ${Size.M}px;
`;

export const StyledBody = styled(Body)`
  margin-top: ${Margin.ExtraLarge}px;
`;

export const ResendContainer = styled.View`
  align-items: center;
`;

export const Link = styled(Body)`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${Weight.MEDIUM};
`;
