import { NavigationProp, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { postLoyaltyTransaction } from 'src/api/loyaltyTransactions';
import {
  Alert,
  AlertHandle,
  Body,
  BottomButton,
  FocusAwareStatusBar,
  HoverButton,
  Row,
  Subheading,
  Title
} from 'src/components';
import { IS_WEB, Margin, Size } from 'src/constants';
import { useLoyaltyTransactions, usePractice } from 'src/hooks';
import useReward from 'src/hooks/react-query/useReward';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { layout } from 'src/theme/globalStyles';
import { isDefined } from 'src/utils';
import cleanUrl from 'src/utils/cleanUrl';
import styled from 'styled-components/native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { AlertOption } from 'src/components/Alert';
import { CareCoinView } from '../LoyaltyRewards/style';
import { Weight } from 'src/theme';
import { CoinIcon } from 'src/routes/stacks/LoyaltyTabNavigator/components/LoyaltyTabBar/style';

const WEB_IMAGE_WIDTH = 400;

type Props = NativeStackScreenProps<MainStackParamsList, Screens.LOYALTY_REWARD>;

const LoyaltyReward: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation<NavigationProp<MainStackParamsList>>();
  const { t } = useTranslation('loyalty');
  const { colors } = useAppTheme();

  const { id: rewardId } = route.params;
  const { data: reward, isFetched } = useReward(rewardId);
  const { data: practice } = usePractice();
  const { coinBalance } = useLoyaltyTransactions();
  const [hasInsufficientCoins, setHasInsufficientCoins] = useState<boolean>(true);

  const { mutate: postTransaction, isLoading } = useMutation(
    async () => await postLoyaltyTransaction(rewardId),
    {
      onSuccess: () => {
        Toast.show({ text1: t('redemptionSuccess') });
        navigation.goBack();
      },
      onError: (error: Error) => {
        Toast.show({
          type: 'error',
          text1: error.name,
          text2: error.message
        });
      }
    }
  );

  const onPressRedeem = () => {
    confirmModalRef.current?.alert();
  };

  const confirmTransaction = useCallback(() => {
    postTransaction();
  }, [postTransaction]);

  useEffect(() => {
    if (isDefined(reward) && coinBalance) {
      setHasInsufficientCoins(coinBalance < reward.price);
    }
  }, [reward, coinBalance]);

  const imageURL = cleanUrl(reward?.image?.urls?.original);

  const [ratio, setRatio] = useState(0);

  useEffect(() => {
    if (imageURL) {
      void Image.prefetch(imageURL).then(() => {
        Image.getSize(imageURL, (width, height) => setRatio(width / height));
      });
    }
  }, [imageURL]);

  useEffect(() => {
    if (!rewardId || (isFetched && !reward)) {
      Toast.show({
        text1: t('rewardNotFound'),
        type: 'error'
      });
      const timeout = setTimeout(() => {
        navigation.goBack();
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [isFetched, navigation, reward, rewardId, t]);

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        label: t('cancel', { ns: 'common' }),
        type: 'neutral'
      },

      {
        action: confirmTransaction,
        label: t('redeem')
      }
    ],
    [confirmTransaction, t]
  );

  if (!reward) return <ActivityIndicator size='large' style={layout.flex1} />;

  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <Container>
        <InnerView style={{ alignSelf: IS_WEB ? 'center' : undefined }}>
          <StyledScrollView>
            {!!imageURL && ratio > 0 && <OfferImage ratio={ratio} source={{ uri: imageURL }} />}
            <TextContainer>
              <Row justify='space-between' style={{ gap: Margin.Large }}>
                <Subheading color={colors.onSurface}>{t('rewardScreenTitle')}</Subheading>
                <CareCoinView>
                  <BodyText>{reward?.price}</BodyText>
                  <CoinIcon />
                </CareCoinView>
              </Row>
              <TitleText>{reward?.title}</TitleText>
              <BodyText>{reward?.description}</BodyText>
            </TextContainer>
          </StyledScrollView>
          <HoverButton mode='text' onPress={() => navigation.goBack()}>
            {t('common:cancel')}
          </HoverButton>
          <BottomButton onPress={onPressRedeem} disabled={hasInsufficientCoins}>
            <Body color={colors.onPrimary} fontWeight={Weight.MEDIUM}>
              {!hasInsufficientCoins ? t('redeem') : t('insufficientCoinsTitle')}
            </Body>
          </BottomButton>
        </InnerView>
      </Container>
      <Alert
        ref={confirmModalRef}
        title={t('confirmTitle')}
        options={confirmOptions}
        body={t('confirmDescription', {
          provider: practice?.name,
          amount: reward.price,
          rewardTitle: reward.title
        })}
      />
      {isLoading && (
        <ActivityIndicator
          style={layout.activityIndicator}
          animating={isLoading}
          size='large'
          color={colors.primary}
        />
      )}
    </>
  );
};

export default LoyaltyReward;

const Container = styled.View`
  flex: 1;
  justify-content: ${({ theme }) => (theme.viewMode.isMobile ? 'flex-end' : 'center')};
`;

const StyledScrollView = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    gap: Size.S
  }
}))`
  flex-shrink: 1;
  border-radius: ${Size.M}px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const InnerView = styled.View`
  flex-shrink: 1;
  width: ${({ theme }) => (theme.viewMode.isMobile ? '100%' : `${WEB_IMAGE_WIDTH}px`)};
  background-color: ${({ theme }) => theme.colors.surface};
  ${({ theme }) => (theme.viewMode.isMobile ? 'min-height: 50%;' : '')}
  max-height: 95%;
  border-radius: ${Size.M}px;
  overflow: hidden;
`;

const TextContainer = styled.View`
  padding: ${Margin.ExtraLarge}px;
  gap: ${Margin.Large}px;
`;

const OfferImage = styled.Image.attrs({ resizeMode: 'cover' })<{ ratio: number }>`
  align-self: center;
  width: 100%;
  aspect-ratio: ${({ ratio }) => ratio};
`;

const TitleText = styled(Title)`
  margin-bottom: ${Margin.Medium}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const BodyText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;
