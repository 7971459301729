import _ from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys, getPatients } from 'src/api';
import { alertOptions as alertOptionsArray } from 'src/constants';
import { periodUnitMap, PeriodUnit } from 'src/interfaces';
import { isPatientActive, plural } from 'src/utils';
import { ReasonMap } from '../ToDoList/components/model';
import { AlternateRepeatTypes, FormTodo } from './helpers';

const useAddTodoOptions = (values: FormTodo) => {
  const { t, i18n } = useTranslation('todos');
  const { data: patients = [] } = useQuery([QueryKeys.PATIENTS], {
    queryFn: getPatients,
    select: (ps) => ps.filter(isPatientActive)
  });

  const patientOptions = useMemo(
    () =>
      patients.map((patient) => ({
        label: patient.name,
        value: patient.patientId
      })),
    [patients]
  );

  const reasonOptions = useMemo(
    () =>
      Object.values(ReasonMap).map((reason) => ({
        label: t(reason.label),
        value: reason.value
      })),
    [t]
  );

  const repeatOptions = useMemo(
    () => [
      {
        value: AlternateRepeatTypes.none,
        label: t('frequency.doNotRepeat')
      },
      ...Object.values(_.omit(periodUnitMap, PeriodUnit.MINUTE)).map((v) => ({
        value: v.value,
        label: t(v.repeatLabel)
      })),
      {
        value: AlternateRepeatTypes.custom,
        label: t('frequency.personalize')
      }
    ],
    [t]
  );

  const customRepeatOptions = useMemo(
    () =>
      Object.values(periodUnitMap).map((v) => ({
        value: v.value,
        label: `${t(v.unitLabel)}${plural(values.period ?? 0)}`
      })),
    [values, t]
  );

  const alertOptions = useMemo(
    () =>
      alertOptionsArray.map((option) => ({
        value: { alertByMinutesPrior: option },
        label: t('createTodo.humanizedBefore', {
          humanizedMoment: moment.duration(option, 'minutes').locale(i18n.language).humanize()
        })
      })),
    [t, i18n]
  );

  return {
    alertOptions,
    customRepeatOptions,
    repeatOptions,
    reasonOptions,
    patientOptions
  };
};

export default useAddTodoOptions;
