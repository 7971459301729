import { endpoint } from 'src/constants';
import { Employee } from 'src/interfaces';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getEmployees = async (): Promise<Employee[]> => {
  const url = endpoint('EMPLOYEES');
  const response = await apiClient.get<Employee[]>(url);
  return isDefinedOrThrowServerError(response.data, Array.isArray);
};
