import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import InboxHero from './components/InboxHero';

export enum STEPS {
  DRAWER,
  NEW_AND_IN_PROGRESS,
  ARCHIVED,
  PLUS
}

const RequestsTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: { id: STEPS.DRAWER, priority: 0 },
  [STEPS.NEW_AND_IN_PROGRESS]: { id: STEPS.NEW_AND_IN_PROGRESS, priority: 1 },
  [STEPS.ARCHIVED]: { id: STEPS.ARCHIVED, priority: 2 },
  [STEPS.PLUS]: { id: STEPS.PLUS, priority: 3 }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'requests:tour.slide.title',
    body: 'requests:tour.slide.body',
    image: <InboxHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.REQUESTS,
  RequestsTourMap
);
