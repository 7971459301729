import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { Keyboard } from 'react-native';
import { IS_ANDROID } from 'src/constants';

const useKeyboardStatus = () => {
  const [status, setStatus] = useState(false);
  const [height, setHeight] = useState(0);
  useFocusEffect(
    useCallback(() => {
      const willHideListener = Keyboard.addListener(
        IS_ANDROID ? 'keyboardDidHide' : 'keyboardWillHide',
        (e) => {
          setStatus(false);
          setHeight(e.endCoordinates.height);
        }
      );
      const willShowListener = Keyboard.addListener(
        IS_ANDROID ? 'keyboardDidShow' : 'keyboardWillShow',
        (e) => {
          setStatus(true);
          setHeight(e.endCoordinates.height);
        }
      );
      return () => {
        willHideListener.remove();
        willShowListener.remove();
      };
    }, [])
  );

  return { status, height };
};

export default useKeyboardStatus;
