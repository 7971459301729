import _, { pick } from 'lodash';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
  useEffect
} from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  AsyncStorageKeys,
  MutationKeys,
  QueryKeys,
  getClient,
  register,
  signIn,
  renewSession,
  SwitchPracticeResponse,
  SwitchPracticeRequest,
  switchPractice,
  ExtendedSignInRequest
} from 'src/api';
import { isDefined, queryClient, storage } from 'src/utils';
import {
  asyncSetToken,
  captureAxiosException,
  isAppReady,
  prefetchQueries,
  secureStoreGetSignInCredentials,
  secureStoreSaveSignInCredentials,
  useLoginState
} from './helper';
import { apiClient, responseFailureInterceptor } from 'src/utils/axios';
import { Context, AuthStatus } from './model';
import usePracticeTheme from 'src/hooks/react-query/usePracticeTheme';
import useDeeplink from '../../hooks/useDeeplink';
import { useAppTheme } from '../AppThemeProvider';
import { useTranslation } from 'react-i18next';
import sentry from 'src/utils/sentry';
import { IUserSignInResponse } from 'src/interfaces';
import { AxiosError } from 'axios';
import { endpoint } from 'src/constants';
import jwtDecode from 'jwt-decode';
import { TokenPayload } from './token/type';
import { useNotificationPrompt } from '../NotificationPromptProvider';
import useBiometrics from 'src/hooks/useBiometrics';
import { practiceOptions } from 'src/hooks/react-query/usePractice';

export * from './model';

const defaultContext: Context = {
  token: undefined,
  ready: false,
  logIn: Promise.resolve,
  logOut: Promise.resolve,
  signUp: Promise.resolve,
  setError: _.noop,
  resetError: _.noop,
  isError: false,
  _user: undefined,
  authStatus: undefined,
  isLoggingOut: false,
  error: undefined,
  setAuthStatus: _.noop,
  isLoggedIn: false,
  practice: undefined
};

const AuthContext = createContext(defaultContext);

export const useAuth = (): Context => useContext(AuthContext);

interface AuthProviderProps extends PropsWithChildren {
  isNavReady: boolean;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, isNavReady: navReady }) => {
  const { t } = useTranslation();
  const state = useLoginState();
  const [error, setError] = useState<Error>();
  const [authStatus, setAuthStatus] = useState<AuthStatus>();
  const { canUseBiometrics } = useBiometrics();

  const prepareAuthenticatedState = useCallback(async (data: IUserSignInResponse) => {
    await asyncSetToken(data.token);
    setError(undefined);
  }, []);

  const { mutateAsync: logIn, isError: isLoginError } = useMutation(MutationKeys.AUTHENTICATION, {
    mutationFn: async (signInRequest: ExtendedSignInRequest) => {
      setError(undefined);
      sentry.setUser({
        id: signInRequest.id.toString(),
        email: signInRequest.email,
        practiceId: signInRequest.practiceId
      });
      try {
        const data = await signIn(pick(signInRequest, ['email', 'password', 'practiceId']));
        await prepareAuthenticatedState(data);

        if (signInRequest.id !== data.id) {
          const request: SwitchPracticeRequest = { userId: signInRequest.id };
          const data = await switchPractice(request);
          await logOut(data);
        }

        if (canUseBiometrics) {
          const storedCredentials = await secureStoreGetSignInCredentials();
          if (!storedCredentials || storedCredentials.password !== signInRequest.password) {
            await secureStoreSaveSignInCredentials(signInRequest);
          }
        }
        return data;
      } catch (e) {
        if (signInRequest.isBiometrics) {
          const error = new Error(t('login:error.biometricLoginError'));
          setError(error);
          throw error;
        } else {
          throw e as AxiosError;
        }
      }
    }
  });

  const { mutate: renew } = useMutation({
    mutationKey: MutationKeys.SESSION_RENEW,
    mutationFn: async () => {
      if (!state.token) {
        sentry.addBreadcrumb({
          message: 'Session renewal called without token.',
          category: 'auth',
          level: 'info'
        });
        setAuthStatus(AuthStatus.LOGGED_OUT);
        return;
      }
      const decodedToken = jwtDecode<TokenPayload>(state.token);
      sentry.setUser({
        id: decodedToken.user_id.toString()
      });
      const data = await renewSession();
      sentry.setUser({
        id: decodedToken.user_id.toString(),
        email: data.email,
        clientId: data.id,
        practiceId: data.practiceId
      });
      await prepareAuthenticatedState(data);
    }
  });

  const { mutateAsync: signUp } = useMutation(MutationKeys.SIGN_UP, {
    mutationFn: register
  });

  const biometricNotRequired =
    (isDefined(state.biometricAuthAppSetting) && !state.biometricAuthAppSetting) ||
    (isDefined(authStatus) && authStatus !== AuthStatus.NEEDS_BIOMETRIC);

  const {
    data: user,
    refetch: refetchUser,
    isFetched: userFetched
  } = useQuery([QueryKeys.CLIENT, state.token], {
    queryFn: async () => {
      if (!state.token) {
        sentry.addBreadcrumb({
          message: 'Client endpoint called without token.',
          category: 'auth',
          level: 'info'
        });
        setAuthStatus(AuthStatus.LOGGED_OUT);
        return;
      }
      const decodedToken = jwtDecode<TokenPayload>(state.token);
      sentry.setUser({
        id: decodedToken.user_id.toString()
      });
      const _user = await getClient();
      sentry.setUser({
        email: _user.email,
        id: decodedToken.user_id.toString(),
        clientId: _user.clientId,
        practiceId: _user.practiceId
      });
      await prefetchQueries(_user);
      setAuthStatus(AuthStatus.AUTHENTICATED);
      return _user;
    },
    enabled:
      state.isLoggedIn && !state.shouldRefresh && !state.userIsMutating && biometricNotRequired
  });

  const { setThemeSet, applyDefaultTheme } = useAppTheme();
  const { isFetched: themeFetched } = usePracticeTheme(user?.practiceId, {
    onSuccess: (themes) => {
      if (themes?.custom && themes?.useCustomTheme) {
        setThemeSet(themes.custom);
      } else {
        applyDefaultTheme();
      }
    },
    onError: applyDefaultTheme,
    enabled: state.isLoggedIn
  });

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logOut = useCallback(async (data?: SwitchPracticeResponse) => {
    setIsLoggingOut(true);
    setAuthStatus(data?.token ? AuthStatus.SWITCHING_PRACTICE : AuthStatus.LOGGED_OUT);
    try {
      await asyncSetToken(data?.token);
      const storageKeys = await storage.getAllKeys();
      const clearKeys = storageKeys.filter(
        (k) =>
          ![AsyncStorageKeys.TUTORIALS, AsyncStorageKeys.LOCALE_PERSISTENCE_KEY].map(
            (key) => !k.includes(key)
          )
      );
      await Promise.all(clearKeys.map(async (key) => storage.removeItem(key)));
      await queryClient.clear();
    } finally {
      setIsLoggingOut(false);
    }
  }, []);

  const ready = isAppReady({
    themeFetched,
    authStatus,
    hasError: !!error,
    navReady,
    userFetched,
    ...state
  });

  const { setIsReady } = useNotificationPrompt();
  const expireSession = useCallback(() => {
    void logOut(undefined);
    const error = new Error(t('sessionExpired', { ns: 'onboardingErrors' }));
    setError(error);
    sentry.addBreadcrumb({
      message: 'Session expired',
      category: 'auth',
      level: 'warning'
    });
  }, [logOut, t]);

  const resetError = useCallback(async () => {
    setAuthStatus(undefined);
    setError(undefined);
    void refetchUser();
  }, [refetchUser]);

  /**
   * Handle refresh token
   */
  useEffect(() => {
    if (state.shouldRefresh) {
      renew();
    }
  }, [state.shouldRefresh, renew]);

  const needsInitialState = ready && !isDefined(authStatus);
  const isExpiredToken = state.hasToken && !state.isLoggedIn;
  const appCanBeReady =
    ready && (authStatus === AuthStatus.AUTHENTICATED || authStatus === AuthStatus.LOGGED_OUT);

  /**
   * Handles initial state
   */
  useEffect(() => {
    if (state.userIsMutating || authStatus === AuthStatus.NEEDS_BIOMETRIC) {
      return;
    }
    if (authStatus === undefined) {
      if (state.hasToken && state.biometricAuthAppSetting) {
        setAuthStatus(AuthStatus.NEEDS_BIOMETRIC);
      } else {
        setAuthStatus(state.isLoggedIn ? AuthStatus.AUTHENTICATED : AuthStatus.LOGGED_OUT);
      }
    } else if (isExpiredToken) {
      if (state.biometricAuthAppSetting) {
        setAuthStatus(AuthStatus.NEEDS_BIOMETRIC);
      } else expireSession();
    } else if (appCanBeReady) {
      setIsReady(true);
    }
  }, [
    state.userIsMutating,
    state.isLoggedIn,
    state.biometricAuthAppSetting,
    isExpiredToken,
    needsInitialState,
    appCanBeReady,
    expireSession,
    setIsReady,
    authStatus,
    ready,
    state.hasToken
  ]);

  /**
   * Intercept all requests and add the token to the header
   */
  useEffect(() => {
    const responseInterceptor = apiClient.interceptors.response.use(
      undefined,
      (e: AxiosError<any, any>) => {
        captureAxiosException(e);
        const nextStatus = responseFailureInterceptor(e);
        if (nextStatus) setAuthStatus(nextStatus);
        if (e.response?.config.url !== endpoint('USERS_PASSWORD')) setError(e);
        throw e;
      }
    );
    const requestInterceptor = apiClient.interceptors.request.use(async (config) => {
      if (config.url !== endpoint('USERS_SIGN_IN')) {
        const token = await storage.getTypedItem<string>(AsyncStorageKeys.TOKEN);
        if (token) config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    return () => {
      apiClient.interceptors.response.eject(responseInterceptor);
      apiClient.interceptors.request.eject(requestInterceptor);
    };
  }, [setAuthStatus]);

  const { data: practice } = useQuery({
    ...practiceOptions(user?.practiceId),
    enabled: !!user
  });

  useDeeplink({
    logOut,
    ready,
    authStatus
  });

  const context: Context = {
    isLoggingOut,
    token: state.token,
    ready: ready && isDefined(authStatus) && !isLoggingOut,
    logIn,
    logOut,
    signUp,
    setError,
    resetError,
    isError: isLoginError,
    error,
    _user: user,
    authStatus,
    setAuthStatus,
    practice,
    isLoggedIn: !!state.isLoggedIn
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
