import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from '../IconProps';

const Bird: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 36 36' fill='none'>
      <Path
        d='M18 12L16.005 12.135C14.715 10.605 11.1 6.75 7.5 6.75C7.5 6.75 4.545 11.19 7.44 17.115C6.615 18.36 6.105 19.005 6 20.49L3.105 20.925L3.42 22.395L6.06 22.005L6.27 23.07L3.915 24.48L4.62 25.815L6.795 24.48C8.52 28.14 12.885 30 18 30C23.115 30 27.48 28.14 29.205 24.48L31.38 25.815L32.085 24.48L29.73 23.07L29.94 22.005L32.58 22.395L32.895 20.925L30 20.49C29.895 19.005 29.385 18.36 28.56 17.115C31.455 11.19 28.5 6.75 28.5 6.75C24.9 6.75 21.285 10.605 19.995 12.135L18 12ZM13.5 16.5C13.8978 16.5 14.2794 16.658 14.5607 16.9393C14.842 17.2206 15 17.6022 15 18C15 18.3978 14.842 18.7794 14.5607 19.0607C14.2794 19.342 13.8978 19.5 13.5 19.5C13.1022 19.5 12.7206 19.342 12.4393 19.0607C12.158 18.7794 12 18.3978 12 18C12 17.6022 12.158 17.2206 12.4393 16.9393C12.7206 16.658 13.1022 16.5 13.5 16.5ZM22.5 16.5C22.8978 16.5 23.2794 16.658 23.5607 16.9393C23.842 17.2206 24 17.6022 24 18C24 18.3978 23.842 18.7794 23.5607 19.0607C23.2794 19.342 22.8978 19.5 22.5 19.5C22.1022 19.5 21.7206 19.342 21.4393 19.0607C21.158 18.7794 21 18.3978 21 18C21 17.6022 21.158 17.2206 21.4393 16.9393C21.7206 16.658 22.1022 16.5 22.5 16.5ZM16.5 21H19.5L18.45 23.085C18.75 24.045 19.59 24.75 20.625 24.75C21.2217 24.75 21.794 24.5129 22.216 24.091C22.6379 23.669 22.875 23.0967 22.875 22.5H23.625C23.625 23.2956 23.3089 24.0587 22.7463 24.6213C22.1837 25.1839 21.4206 25.5 20.625 25.5C19.5 25.5 18.525 24.885 18 24C17.475 24.885 16.5 25.5 15.375 25.5C14.5794 25.5 13.8163 25.1839 13.2537 24.6213C12.6911 24.0587 12.375 23.2956 12.375 22.5H13.125C13.125 23.0967 13.3621 23.669 13.784 24.091C14.206 24.5129 14.7783 24.75 15.375 24.75C16.41 24.75 17.25 24.045 17.55 23.085L16.5 21Z'
        fill={color}
      />
    </Svg>
  );
};

export default Bird;
