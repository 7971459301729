import { StyleSheet } from 'react-native';
import CPrimaryButton from 'src/components/CPrimaryButton';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

export const style = StyleSheet.create({
  languageView: {
    justifyContent: 'flex-start'
  },
  marginVerticalSmall: {
    marginVertical: Size.X2_S
  },
  languagePickerView: {
    justifyContent: 'flex-start',
    marginVertical: 10
  },
  height140: {
    height: 140
  },
  pickerLabel: {
    paddingBottom: Size.X3_S
  },
  buttonView: { marginTop: Size.S, marginHorizontal: Size.X3_S },
  z99: { zIndex: 99 }
});

export const StyledPrimaryButton = styled(CPrimaryButton)`
  margin-top: ${Size.XS}px;
  margin-bottom: ${Size.X2_S}px;
`;

export const StyledSettingsView = styled.View`
  flex: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  margin-top: ${({ theme }) => (theme.viewMode.isWeb ? `${Size.X2_S}` : '0')}px;
`;
