import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Body, Caption } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import ClientAvatar from 'src/components/ClientAvatar';
import { usePractice } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import RequestStackParamList from 'src/routes/stacks/RequestStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import {
  DataContainer,
  CaptionContainer
} from 'src/scenes/Appointments/AppointmentsRoot/components';
import { toLocalDateTime } from 'src/utils';
import { StatusContainer, Container } from './style';
import { ICON_SIZE } from '../constants';
import { getRequestTargetName } from 'src/utils/requests';
import { RequestWithPatient } from '../helper/model';

interface Props {
  request: RequestWithPatient;
}

const RequestItem: React.FC<Props> = ({ request }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('requests');
  const { navigate } = useNavigation<NavigationProp<RequestStackParamList>>();

  const isForPatient = !!request.patient;
  const patientInfo = request.notOwner ? undefined : request.patient;

  const targetName = t(getRequestTargetName(request));

  const { data: practice } = usePractice();

  return (
    <Container onPress={() => navigate(Screens.REQUEST_DETAILS, { requestId: request.id })}>
      {isForPatient && <PatientAvatar patient={patientInfo} size={ICON_SIZE} />}
      {!isForPatient && <ClientAvatar size={ICON_SIZE} />}
      <DataContainer>
        <CaptionContainer>
          <Body color={colors.onSurface}>{targetName}</Body>
        </CaptionContainer>
        <CaptionContainer>
          <Caption color={colors.onSurface}>{t(`type.${request.type}`)}</Caption>
        </CaptionContainer>
        <CaptionContainer>
          <Caption color={colors.onSurface}>
            {toLocalDateTime(moment(request.createdAt), practice?.timeZone)}
          </Caption>
        </CaptionContainer>
      </DataContainer>
      <StatusContainer>
        <Caption>{t(`status.${request.status}`)}</Caption>
      </StatusContainer>
    </Container>
  );
};

export default RequestItem;
