import React from 'react';
import { BOOKING_STEPS } from '../BookingProvider/helpers';
import styled from 'styled-components/native';
import { Edit2 } from 'react-native-feather';
import { useBookingState } from '../BookingProvider';
import { TouchableRipple } from 'react-native-paper';
import { Margin } from 'src/constants';

interface Props {
  section: BOOKING_STEPS;
}
const EditButton: React.FC<Props> = ({ section }) => {
  const { setBookingState } = useBookingState();
  return (
    <StyleButton
      onPress={() => {
        setBookingState((prev) => ({
          ...prev,
          current: section
        }));
      }}
    >
      <Edit />
    </StyleButton>
  );
};

export default EditButton;

const Edit = styled(Edit2)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyleButton = styled(TouchableRipple)`
  padding: ${Margin.ExtraSmall}px;
  border-radius: ${({ theme }) => theme.roundness}px;
`;
