import moment from 'moment-timezone';
import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: moment.duration(1, 'minutes').asMilliseconds(),
      cacheTime: moment.duration(24, 'hours').asMilliseconds(),
      retry: false // if a query is failing, we do not want to bombard the server
    }
  }
});

export default queryClient;
