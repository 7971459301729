import { ErrorMsg, ErrorName } from 'src/constants/api';

class ChangePasswordError extends Error {
  errors: {
    current_password: string[];
    password: string[];
    password_confirmation: string[];
  };

  constructor(errors: {
    current_password: string[];
    password: string[];
    password_confirmation: string[];
  }) {
    super(ErrorMsg.INVALID_RESPONSE);
    this.errors = errors;
    this.name = ErrorName.CHANGE_PASSWORD_ERROR;
  }
}

export default ChangePasswordError;
