import React, { useEffect, useState } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { IconSize, IS_WEB } from 'src/constants';
import styled from 'styled-components/native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import getImage from 'src/utils/checkImageCache';
import { cleanUrl, colorShade } from 'src/utils';
import { layout } from 'src/theme/globalStyles';

interface Props {
  uri?: string;
  id?: string;
  size: number;
  style?: StyleProp<ViewStyle>;
  aspectRatio?: number;
  isMutating?: boolean;
  isFetching?: boolean;
  EmptyComponent: React.FC<EmptyProps>;
  iconSize?: number;
  iconColor?: string;
}

interface EmptyProps {
  size: number;
  color: string;
}

const Avatar: React.FC<Props> = ({
  uri: parentUri,
  id,
  size,
  style,
  aspectRatio,
  isMutating,
  isFetching,
  EmptyComponent,
  iconSize,
  iconColor
}) => {
  const { colors } = useAppTheme();

  const [uri, setUri] = useState<string>();

  const [isFetched, setFetched] = useState<boolean | undefined>(undefined);

  const isReady = !isFetching;

  useEffect(() => {
    if (isMutating) {
      setFetched(undefined);
    } else if (!isFetching) {
      if (!parentUri || !id) {
        setFetched(false);
      } else {
        if (IS_WEB) {
          setUri(cleanUrl(parentUri));
          setFetched(true);
        } else {
          void getImage(id, parentUri)
            .then((uri) => {
              setUri(cleanUrl(uri));
              setFetched(true);
            })
            .catch(() => {
              setFetched(false);
            });
        }
      }
    }
  }, [isMutating, parentUri, id, isReady, isFetching]);

  return (
    <PictureContainer size={size} aspectRatio={aspectRatio} style={style}>
      {isFetched === undefined && (
        <ActivityIndicator color={colors.disabled} size={size * 0.7} style={layout.flex1} />
      )}
      {isFetched === true && (
        <StyledImage
          size={size}
          aspectRatio={aspectRatio}
          source={{ uri }}
          onError={() => setFetched(false)}
        />
      )}
      {isFetched === false && (
        <View style={layout.alignSelfCenter}>
          <EmptyComponent size={iconSize ?? size * 0.7} color={iconColor ?? colors.disabled} />
        </View>
      )}
    </PictureContainer>
  );
};

export default Avatar;

const StyledImage = styled.Image<{
  size: IconSize;
  aspectRatio?: number;
}>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || 1};
  width: ${({ size }) => size}px;
  height: ${({ size, aspectRatio }) => (aspectRatio ? '' : `${size}px`)};
`;
const PictureContainer = styled.View<{
  size: IconSize;
  aspectRatio?: number;
}>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || 1};
  width: ${({ size }) => size}px;
  height:  ${({ size, aspectRatio }) => (aspectRatio ? '' : `${size}px`)}
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.roundness}px;
  background-color: ${({ theme }) => colorShade(theme.colors.surface, 0.8)};
  overflow: hidden;
`;
