export enum ErrorName {
  CONNECTION_ERROR = 'Connection Error',
  SERVER_ERROR = 'Server Error',
  REQUEST_ERROR = 'Request Error',
  LOGIN_ERROR = 'Login Error',
  CHANGE_PASSWORD_ERROR = 'Change Password Error'
}

export enum ErrorMsg {
  INVALID_RESPONSE = 'Invalid Response'
}
