import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { TokenPayload } from './type';

const isTokenValid = (token?: string): token is string => {
  if (!token?.length) return false;
  try {
    const decoded = jwtDecode<TokenPayload>(token);
    const expiresLater = moment.utc(decoded.expires_at, 'YYYY-MM-DD HH:mm:ss').isAfter();
    return !!decoded.expires_at && !!decoded.user_id && expiresLater;
  } catch {
    return false;
  }
};

export default isTokenValid;
