import React, { useEffect, useMemo, useState } from 'react';
import { useBookingState } from '../../BookingProvider';
import { ContentContainer, InputContainer, MainText, Row, StyledScrollView } from '../shared';
import { DatePicker, DropDown, Headline, HoverButton } from 'src/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { datePickerValueFromString, findUnavailableDates } from './helpers';
import { isDefined, toLocalTime } from 'src/utils';
import CustomLabel from 'src/scenes/AppointmentRequest/CustomLabel';
import Toast from 'react-native-toast-message';
import { omit } from 'lodash';
import { AppointmentOption, ColumnOption } from 'src/hooks/useAppointmentOptions';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { ActivityIndicator } from 'react-native-paper';
import AppointmentLoader from '../AppointmentLoader';

const SelectAppointment: React.FC = () => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('onlineBooking');
  const {
    values,
    practiceInfo: { timeZone },
    appointmentOptions: { data: { appointment, fieldDisplay } = {} },
    openingsQuery: { data: openings, isFetched, isFetching },
    errors,
    setFieldValue,
    validateField
  } = useBookingState();
  const unavailableDates = useMemo(
    () => findUnavailableDates(openings, fieldDisplay?.minDays, fieldDisplay?.maxDays),
    [openings, fieldDisplay]
  );
  const openingsAllowed = isFetched && !!openings;

  return (
    <StyledScrollView>
      <ContentContainer>
        <MainText as={Headline}>{t('selectAppointment.title')}</MainText>
        <MainText>{t('selectAppointment.description')}</MainText>
      </ContentContainer>

      {(values.appointmentInfo.useAppointmentTypes ||
        values.appointmentInfo.allowCategorySelection) && (
        <Row>
          {values.appointmentInfo.useAppointmentTypes && (
            <InputContainer halfSize={values.appointmentInfo?.allowCategorySelection}>
              <DropDown<Number, AppointmentOption>
                label={t('form.appointmentOption')}
                labelTransform={(option) => t(option.label)}
                options={appointment ?? []}
                value={values.appointmentInfo.appointmentOption?.value}
                onChange={async (selected) => {
                  const appointmentOption = appointment?.find(
                    (a: AppointmentOption) => a.value === selected
                  );
                  await setFieldValue('appointmentInfo.appointmentOption', appointmentOption);
                  if (values.appointmentInfo?.columnOption) {
                    void validateField('appointmentInfo.columnOption');
                  }
                  if (values.appointmentInfo?.preferredDate) {
                    void validateField('appointmentInfo.preferredDate');
                  }
                  if (values.appointmentInfo?.preferredTime) {
                    void validateField('appointmentInfo.preferredTime');
                  }
                }}
                error={!!errors.appointmentInfo?.appointmentOption}
                msg={t(errors.appointmentInfo?.appointmentOption ?? '')}
              />
            </InputContainer>
          )}
          {values.appointmentInfo.allowCategorySelection && (
            <InputContainer halfSize={values.appointmentInfo?.useAppointmentTypes}>
              <DropDown<String, ColumnOption>
                label={t('form.columnOption')}
                options={values.appointmentInfo?.availableColumns ?? []}
                disabled={
                  !!errors.appointmentInfo?.appointmentOption ||
                  (!values.appointmentInfo?.appointmentOption &&
                    values.appointmentInfo.useAppointmentTypes)
                }
                value={values.appointmentInfo.columnOption?.value}
                labelTransform={(option) => t(option.label)}
                onChange={async (option) => {
                  if (!values.appointmentInfo?.availableColumns) {
                    return;
                  }
                  const column = values.appointmentInfo?.availableColumns.find(
                    (c: ColumnOption) => c.value === option
                  );
                  if (column?.value === 'No Preference') {
                    await setFieldValue('appointmentInfo.columnOption', omit(column, 'categoryId'));
                  } else if (values.appointmentInfo?.useAppointmentTypes) {
                    await setFieldValue(
                      'appointmentInfo.columnOption',
                      omit(column, 'appointmentLength')
                    );
                  } else {
                    await setFieldValue('appointmentInfo.columnOption', column);
                  }
                  await validateField('appointmentInfo.columnOption');
                  if (values.appointmentInfo?.preferredDate) {
                    void validateField('appointmentInfo.preferredDate');
                  }
                  if (values.appointmentInfo?.preferredTime) {
                    void validateField('appointmentInfo.preferredTime');
                  }
                }}
                error={!!errors?.appointmentInfo?.columnOption}
                msg={t(errors?.appointmentInfo?.columnOption ?? '', {
                  type: values.appointmentInfo?.appointmentOption?.label,
                  category: t(values.appointmentInfo.columnOption?.label ?? '')
                })}
              />
            </InputContainer>
          )}
        </Row>
      )}
      <Row>
        <InputContainer>
          <HoverButton
            disabled={
              !openingsAllowed ||
              (values.appointmentInfo.allowCategorySelection &&
                !values.appointmentInfo.columnOption) ||
              !!errors.appointmentInfo?.columnOption
            }
            mode='outlined'
            onPress={async () => {
              if (!openings) {
                return;
              }
              const firstDay = Object.keys(openings).sort()?.[0];
              const firstTime = openings[firstDay]?.[0];
              if (
                firstDay === values.appointmentInfo.preferredDate &&
                firstTime === values.appointmentInfo.preferredTime
              ) {
                Toast.show({
                  type: 'info',
                  text1: t('selectAppointment.nextAlreadySelected')
                });
              } else if (firstDay && firstTime) {
                Toast.show({
                  type: 'success',
                  text1: t('selectAppointment.nextSelected')
                });
                await setFieldValue('appointmentInfo.preferredDate', firstDay);
                await setFieldValue('appointmentInfo.preferredTime', firstTime);
              }
            }}
          >
            {t('selectAppointment.nextAvailable')}
          </HoverButton>
        </InputContainer>
        <InputContainer halfSize>
          <DatePicker
            disabled={
              !openingsAllowed ||
              (values.appointmentInfo.allowCategorySelection &&
                !values.appointmentInfo.columnOption) ||
              !!errors.appointmentInfo?.columnOption
            }
            label={t('form.preferredDate')}
            validRange={{
              startDate: moment().startOf('day').add(fieldDisplay?.minDays, 'days').toDate(),
              endDate: moment().startOf('day').add(fieldDisplay?.maxDays, 'days').toDate(),
              disabledDates: unavailableDates
            }}
            value={datePickerValueFromString(values.appointmentInfo?.preferredDate)}
            error={!!errors?.appointmentInfo?.preferredDate}
            msg={t(errors.appointmentInfo?.preferredDate ?? '')}
            startYear={moment().year()}
            endYear={moment().add(fieldDisplay?.maxDays, 'days').year()}
            onConfirm={(date) => {
              if (!date) {
                void setFieldValue('appointmentInfo.preferredDate', undefined);
              } else {
                void setFieldValue(
                  'appointmentInfo.preferredDate',
                  moment(date).format('YYYY-MM-DD')
                );
              }
            }}
          />
        </InputContainer>
        <InputContainer halfSize>
          <DropDown
            disabled={
              !openingsAllowed ||
              (values.appointmentInfo.allowCategorySelection &&
                !values.appointmentInfo.columnOption) ||
              !!errors.appointmentInfo?.columnOption ||
              !!errors.appointmentInfo?.preferredDate ||
              !values.appointmentInfo.preferredDate
            }
            label={t('form.preferredTime')}
            options={
              values.appointmentInfo.availableTimes
                ?.map((value) => {
                  if (!value || !values.appointmentInfo.preferredDate) {
                    return undefined;
                  }
                  const date = moment.tz(
                    `${values.appointmentInfo.preferredDate} ${value}`,
                    timeZone
                  );
                  return {
                    value,
                    label: toLocalTime(date, timeZone),
                    custom: <CustomLabel time={date} practiceTimeZone={timeZone} />
                  };
                })
                .filter(isDefined) ?? []
            }
            value={values.appointmentInfo.preferredTime}
            onChange={async (preferredTime) =>
              setFieldValue('appointmentInfo.preferredTime', preferredTime)
            }
            error={!!errors?.appointmentInfo?.preferredTime}
            msg={t(errors.appointmentInfo?.preferredTime ?? '')}
          />
        </InputContainer>
        {isFetching && (
          <ActivityIndicator
            color={colors.primary}
            size='large'
            style={{ position: 'absolute', top: 0, right: 0, width: '100%', height: '100%' }}
          />
        )}
      </Row>
    </StyledScrollView>
  );
};
const SelectAppointmentWrapper: React.FC = () => {
  const {
    onDemandReady,
    appointmentOptions,
    openingsQuery: { isFetched }
  } = useBookingState();

  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  useEffect(() => {
    if (isFetched) {
      setHasLoadedOnce(true);
    }
  }, [isFetched]);

  if (!hasLoadedOnce || !onDemandReady || !appointmentOptions) {
    return <AppointmentLoader />;
  }

  return <SelectAppointment />;
};

export default SelectAppointmentWrapper;
