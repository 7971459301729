import { StyleSheet } from 'react-native';
import { MD2DarkTheme as DarkTheme } from 'react-native-paper';
import { MOBILE_WIDTH_MAX, TABLET_WIDTH_LANDSCAPE_MAX, Size, Margin } from 'src/constants';
import palette from './palette';
import { defaultDarkTheme, defaultLightTheme } from './themes';
import { fonts, FontSize, Weight } from './typography';

/**
 * Global container styling
 */
export const containers = StyleSheet.create({
  default: {
    flex: 1,
    margin: 4
  },
  webOrTablet: {
    width: MOBILE_WIDTH_MAX,
    alignSelf: 'center'
  },
  shadow: {
    shadowColor: palette.BLACK_OPACITY_30,
    shadowRadius: Size.X2_S,
    shadowOpacity: 0.4,
    shadowOffset: {
      height: Size.X3_S,
      width: 0
    },
    elevation: Size.X2_S
  },
  shadowDarker: {
    shadowColor: palette.BLACK,
    shadowRadius: Size.XS,
    elevation: Size.X2_S
  },
  shadowSharper: {
    shadowColor: palette.BLACK_OPACITY_30,
    shadowRadius: Size.X4_S,
    shadowOpacity: 0.65,
    shadowOffset: {
      height: Size.X4_S,
      width: Size.X4_S
    },
    elevation: Size.X4_S
  },
  mobileBottomButton: {
    flex: 1,
    justifyContent: 'flex-end'
  }
});

/**
 * Convenience single use styles for layout and positioning
 */
export const layout = StyleSheet.create({
  smallGap: {
    gap: Margin.Small
  },
  flexRow: {
    flexDirection: 'row'
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  flexRowWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  flex3: {
    flex: 3
  },
  flex4: {
    flex: 4
  },
  flex5: {
    flex: 5
  },
  flexGrow1: {
    flexGrow: 1
  },
  padding0: {
    padding: 0
  },
  paddingV0: {
    paddingVertical: 0
  },
  paddingH0: {
    paddingHorizontal: 0
  },
  alignSelfCenter: {
    alignSelf: 'center'
  },
  alignSelfEnd: {
    alignSelf: 'flex-end'
  },
  alignItemsCenter: { alignItems: 'center' },
  justifyStart: {
    justifyContent: 'flex-start'
  },
  textAlignCenter: {
    textAlign: 'center',
    textAlignVertical: 'center'
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  justifyAround: {
    justifyContent: 'space-around'
  },
  justifyBetween: {
    justifyContent: 'space-between'
  },
  justifyEnd: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  activityIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mobileWidth: {
    width: MOBILE_WIDTH_MAX
  },
  tabletWidth: {
    width: TABLET_WIDTH_LANDSCAPE_MAX
  }
});

/**
 * Convenience single use styles for colors
 */
export const colors = StyleSheet.create({
  whiteBackground: { backgroundColor: defaultLightTheme.colors.background },
  blackBackground: { backgroundColor: defaultDarkTheme.colors.background },
  errorLight: {
    color: defaultLightTheme.colors.error
  },
  errorDark: {
    color: DarkTheme.colors.error
  }
});

export const fontStyles = StyleSheet.create({
  body: {
    letterSpacing: 0.2,
    fontSize: FontSize.BODY,
    fontWeight: Weight.REGULAR,
    fontFamily: fonts.roboto.regular,
    lineHeight: 16.5
  },
  caption: {
    fontSize: FontSize.CAPTION,
    fontWeight: Weight.LIGHT,
    fontFamily: fonts.roboto.light,
    lineHeight: 14
  },
  subheading: {
    fontSize: FontSize.SUBHEADING,
    fontWeight: Weight.MEDIUM,
    fontFamily: fonts.roboto.medium,
    lineHeight: 19
  },
  headline: {
    fontSize: FontSize.HEADLINE,
    fontWeight: Weight.MEDIUM,
    fontFamily: fonts.roboto.medium,
    lineHeight: 23.5
  },
  title: {
    fontSize: FontSize.TITLE,
    fontWeight: Weight.BOLD,
    fontFamily: fonts.roboto.bold,
    lineHeight: 28.5
  },
  h1: {
    fontSize: 24,
    fontWeight: Weight.BOLD,
    fontFamily: fonts.roboto.bold,
    lineHeight: 28.5,
    marginTop: 20,
    marginBottom: 20
  },
  h2: {
    fontSize: 20,
    fontWeight: Weight.BOLD,
    lineHeight: 23.5
  },
  h3: {
    fontSize: 17,
    fontWeight: Weight.BOLD,
    lineHeight: 19
  },
  h4: {
    fontSize: 14,
    fontWeight: Weight.BOLD,
    lineHeight: 16.5
  },
  h5: {
    fontSize: 12,
    fontWeight: Weight.BOLD,
    lineHeight: 14
  },
  h6: {
    fontSize: 10,
    fontWeight: Weight.BOLD,
    lineHeight: 12
  },
  p: {
    fontSize: FontSize.BODY,
    lineHeight: 18
  }
});
