import {
  getMediaLibraryPermissionsAsync,
  launchImageLibraryAsync,
  MediaTypeOptions,
  requestMediaLibraryPermissionsAsync
} from 'expo-image-picker';
import { t } from 'i18next';
import Toast from 'react-native-toast-message';

const pickImage = async (allowsMultipleSelection = false) => {
  const permissions = await getMediaLibraryPermissionsAsync();
  if (!(permissions.granted ?? false)) {
    const permissionResponse = await requestMediaLibraryPermissionsAsync();

    if (!permissionResponse.granted) {
      Toast.show({
        text1: t('mediaLibrary.accessRequired', { ns: 'permissions' }),
        text2: t('mediaLibrary.request', { ns: 'permissions' }),
        type: 'error'
      });
      return null;
    }
  }

  return await launchImageLibraryAsync({
    mediaTypes: MediaTypeOptions.Images,
    allowsEditing: !allowsMultipleSelection,
    allowsMultipleSelection
  });
};

export default pickImage;
