import styled from 'styled-components/native';
import { SlideItem } from './Slide';
import { Margin } from 'src/constants';
import { Animated, FlatList } from 'react-native';
import Dot from './Dot';

interface Props {
  slides: SlideItem[];
  scrollX: Animated.Value;
  slideRef: React.RefObject<FlatList<SlideItem>>;
  sliderWidth: number;
  index: number;
}

const Pagination: React.FC<Props> = ({
  slides,
  scrollX,
  slideRef,
  index: focusedIndex,
  sliderWidth
}) => {
  return (
    <Container>
      {slides.map((item, index) => (
        <Dot
          key={`${item.title ?? ''}-${index}`}
          item={item}
          index={index}
          scrollX={scrollX}
          focusedIndex={focusedIndex}
          sliderWidth={sliderWidth}
          slideRef={slideRef}
        />
      ))}
    </Container>
  );
};
export default Pagination;

const Container = styled(Animated.View)`
  flex-direction: row;
  gap: ${Margin.Large}px;
  align-self: center;
  justify-content: center;
`;
