import { NavigationProp, useNavigation } from '@react-navigation/native';
import React from 'react';
import { Row } from 'src/components';
import { Reward } from 'src/interfaces';
import LoyaltyTabParamsList from 'src/routes/stacks/LoyaltyTabNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import ContinuitySegment from './ContinuitySegment';
import OfferCard from './OfferCard';

interface Props {
  isStart: boolean;
  isEnd: boolean;
  reward: Reward;
  coinBalance: number;
}

const RewardRow: React.FC<Props> = ({ reward, isStart, isEnd, coinBalance }) => {
  const navigation = useNavigation<NavigationProp<LoyaltyTabParamsList>>();
  const onPress = () => {
    navigation.getParent()?.navigate(Screens.LOYALTY_REWARD, { id: reward.id });
  };

  return (
    <Row>
      <ContinuitySegment
        isStart={isStart}
        isEnd={isEnd}
        availableCoins={coinBalance}
        rewardPrice={reward.price}
        onPress={onPress}
      />
      <OfferCard rewardId={reward.id} onPress={onPress} />
    </Row>
  );
};

export default RewardRow;
