import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Body, Title } from 'src/components/Text';
import { Margin, Size } from 'src/constants';
import styled from 'styled-components/native';
import BulletText from './BulletText';
import { isArray } from 'lodash';

export interface SlideItem {
  image?: React.ReactNode;
  title?: string;
  body?: React.ReactNode;
}

export interface SlideProps<T extends SlideItem = SlideItem> {
  item: T;
  width?: number;
  scrollX: Animated.Value;
  sliderWidth: number;
  index: number;
  titleStyle?: StyleProp<TextStyle>;
  bodyStyle?: StyleProp<TextStyle>;
  imageStyle?: StyleProp<ViewStyle>;
}

export const Slide: React.FC<SlideProps<SlideItem>> = ({
  item,
  width,
  index,
  sliderWidth,
  scrollX,
  titleStyle,
  bodyStyle,
  imageStyle
}) => {
  const { t } = useTranslation();
  const inputRange = [(index - 1) * sliderWidth, index * sliderWidth, (index + 1) * sliderWidth];
  const scale = scrollX.interpolate({
    inputRange,
    outputRange: [0.8, 1, 0.8],
    extrapolate: 'clamp'
  });

  const body = useMemo(() => {
    if (typeof item.body === 'string') {
      const obj = t(item.body, { returnObjects: true });
      if (typeof obj === 'string' || isArray(obj)) {
        return obj;
      }
    }
    return item.body;
  }, [item.body, t]);

  return (
    <Container width={width}>
      {!!item.image && (
        <StyledImageContainer
          pointerEvents={'box-none'}
          style={[{ transform: [{ scale }] }, imageStyle]}
        >
          {item.image}
        </StyledImageContainer>
      )}
      <TextContainer hasImage={!!item.image}>
        {!!item.title && (
          <StyledText as={Title} style={titleStyle}>
            {t(item.title)}
          </StyledText>
        )}
        {typeof body === 'string' && <StyledText style={bodyStyle}>{body}</StyledText>}
        {Array.isArray(body) && (
          <ListContainer>
            {body.map((item) => (
              <BulletText style={bodyStyle} key={item}>
                {item}
              </BulletText>
            ))}
          </ListContainer>
        )}
      </TextContainer>
    </Container>
  );
};

const Container = styled.View<{ width?: number }>`
  ${({ width }) => (width ? `width: ${width}px;` : '')}
  gap: ${Margin.Large}px;
`;

const StyledImageContainer = styled(Animated.View)`
  flex-basis: ${({ theme }) => theme.viewMode.narrowMaxContentWidth}px;
  justify-content: center;
  align-content: center;
`;

const StyledText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;

const TextContainer = styled.View<{ hasImage: boolean }>`
  margin-top: ${({ hasImage }) => (hasImage ? 0 : Size.X3_L)}px;
  position: ${({ hasImage }) => (hasImage ? 'absolute' : 'relative')};
  padding-horizontal: ${Margin.Large}px;
  justify-content: flex-end;
  gap: ${Margin.Large}px;
  bottom: 0;
  width: 100%;
`;

const ListContainer = styled.View`
  gap: ${Margin.Small}px;
`;
