import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Animated } from 'react-native';
import { AlertTriangle, Check, RefreshCcw } from 'react-native-feather';
import { Alert, AlertHandle, Caption, Row } from 'src/components';
import { ALLY_SUPPORT_EMAIL, IS_WEB, Margin, Size } from 'src/constants';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';
import { useUser } from 'src/providers/ClientProvider';
import { openEmail } from 'src/utils';
import openEmailToAlly from 'src/utils/openEmailToAlly';
import { emailBouncedOrMarkedSpam, isErroredEmail } from 'src/utils/validators/email';
import useResendConfirmationEmail from '../Landing/helpers/useResendConfirmationEmail';
import { Link, PracticeRow } from './styled';

interface Props {
  practice: AvailablePractice;
}

const UnconfirmedPractice: React.FC<Props> = ({ practice }) => {
  const { user } = useUser();
  const { t } = useTranslation('selectPractice');

  const { sendConfirmationEmail, isSending, hasSentRecently } = useResendConfirmationEmail(
    user.email,
    practice
  );

  const animatedValue = useRef(new Animated.Value(0));
  const loopValue = animatedValue.current.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: ['0deg', '360deg', '720deg']
  });

  useEffect(() => {
    if (isSending) {
      const aV = animatedValue.current;
      const animation = Animated.loop(
        Animated.timing(aV, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: !IS_WEB
        })
      );
      animation.start(() => aV.setValue(0));
      return () => {
        const a = Animated.timing(aV, {
          toValue: 0,
          duration: 500,
          useNativeDriver: !IS_WEB
        });
        a.start();
      };
    }
  }, [isSending]);

  const alertRef = useRef<AlertHandle>(null);

  if (isErroredEmail(practice.emailVerificationStatus)) {
    return (
      <>
        <PracticeRow
          onPress={alertRef.current?.alert}
          icon={(props) => (
            <Row style={{ gap: Margin.Medium }}>
              <Caption color={props.color}>{t('common:error')}</Caption>
              <AlertTriangle color={props.color} width={Size.S} height={Size.S} />
            </Row>
          )}
        >
          {practice.practiceName}
        </PracticeRow>
        <Alert
          ref={alertRef}
          title={t('emailErrorTitle')}
          body={
            <Trans
              t={t}
              i18nKey={
                emailBouncedOrMarkedSpam(practice.emailVerificationStatus)
                  ? 'emailErrorContactUs'
                  : 'emailErrorContactPractice'
              }
              values={{
                ourEmail: ALLY_SUPPORT_EMAIL,
                practiceEmail: practice.practiceEmail,
                practiceName: practice.practiceName
              }}
              components={{
                contactUs: <Link onPress={async () => await openEmailToAlly()} />,
                contactPractice: (
                  <Link onPress={async () => await openEmail(practice?.practiceEmail)} />
                )
              }}
            />
          }
        />
      </>
    );
  }

  if (hasSentRecently) {
    return (
      <PracticeRow
        disabled
        icon={(props) => (
          <Row style={{ gap: Margin.Medium }}>
            <Caption color={props.color}>{t('sent')}</Caption>
            <Check color={props.color} width={Size.S} height={Size.S} />
          </Row>
        )}
      >
        {practice.practiceName}
      </PracticeRow>
    );
  }

  return (
    <PracticeRow
      onPress={sendConfirmationEmail}
      disabled={isSending}
      icon={(props) => (
        <Row style={{ gap: Margin.Medium }}>
          <Caption color={props.color}>{t('resend')}</Caption>
          <Animated.View style={{ transform: [{ rotate: loopValue }, { scaleX: -1 }] }}>
            <RefreshCcw color={props.color} width={Size.S} height={Size.S} />
          </Animated.View>
        </Row>
      )}
    >
      {practice.practiceName}
    </PracticeRow>
  );
};

export default UnconfirmedPractice;
