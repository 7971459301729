import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { FocusAwareStatusBar } from 'src/components';
import TodoList from 'src/scenes/ToDos/ToDoList';
import AddTodoScreen from 'src/scenes/ToDos/AddTodo';
import { Screens } from '../screens';
import TodoStackParamList from './ParamsList';
import { useTranslation } from 'react-i18next';
import useDefaultStackOptions from '../useDefaultStackOptions';

const ToDoStack = createStackNavigator<TodoStackParamList>();

const TodoStackNavigator: React.FC = () => {
  const { t } = useTranslation('todos');
  const themedHeader = useDefaultStackOptions();
  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} />
      <ToDoStack.Navigator screenOptions={themedHeader}>
        <ToDoStack.Screen
          name={Screens.TO_DOS}
          component={TodoList}
          options={{ title: t('list.title') }}
        />
        <ToDoStack.Screen
          name={Screens.ADD_TO_DO}
          component={AddTodoScreen}
          options={(props) => ({
            title: t(props.route.params?.todoId ? 'createTodo.titleEdit' : 'createTodo.titleCreate')
          })}
        />
      </ToDoStack.Navigator>
    </>
  );
};

export default TodoStackNavigator;
