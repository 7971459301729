import { FlatList, LayoutRectangle, ScrollView, SectionList, View } from 'react-native';
import { IS_ANDROID, Size } from 'src/constants';
import { Scrollable } from 'src/providers/ScrollableRefProvider';
import { isDefined } from 'src/utils';

export const SCROLL_FOCUS_DELAY = 250;
const animated = true;
interface Layout extends Dimensions {
  x: number;
  y: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

const VERTICAL_CARET_ADJUSTMENT = Size.X2_S;
const HORIZONTAL_CARET_ADJUSTMENT = Size.M;
const CARET_SIZE = Size.S;

export const calculatePosition = (
  currLayout: Layout,
  helperLayout: Dimensions,
  window: Dimensions,
  horizontal: boolean
) => {
  if (horizontal) {
    return alignHorizontally(currLayout, helperLayout, window);
  } else {
    return alignVertically(currLayout, helperLayout, window);
  }
};

const alignVertically = (currLayout: Layout, helperLayout: Dimensions, window: Dimensions) => {
  const computedGuidePosition: Layout = { x: 0, y: 0, width: 0, height: 0 };
  const computedCaretPosition: Layout = { x: 0, y: 0, width: 0, height: 0 };
  let computedCaretRotation = 0;
  if (
    currLayout.height + currLayout.y + helperLayout.height <= window.height ||
    currLayout.y - helperLayout.height < 0
  ) {
    // place below node
    Object.assign(computedGuidePosition, {
      y: Math.min(
        currLayout.y + currLayout.height + VERTICAL_CARET_ADJUSTMENT * 2,
        window.height - helperLayout.height - VERTICAL_CARET_ADJUSTMENT * 2
      ),
      height: helperLayout.height
    });
    computedCaretPosition.y = -VERTICAL_CARET_ADJUSTMENT;
    computedCaretRotation = 0;
  } else {
    // place above node
    Object.assign(computedGuidePosition, {
      y: Math.max(
        VERTICAL_CARET_ADJUSTMENT,
        currLayout.y - helperLayout.height - VERTICAL_CARET_ADJUSTMENT * 2
      ),
      height: helperLayout.height
    });
    computedCaretPosition.y = helperLayout.height - VERTICAL_CARET_ADJUSTMENT;
    computedCaretRotation = 2;
  }
  if (
    currLayout.x + (currLayout.width + helperLayout.width) / 2 <= window.width &&
    currLayout.x + (currLayout.width - helperLayout.width) / 2 > 0
  ) {
    // align to center of node
    Object.assign(computedGuidePosition, {
      x: currLayout.x + currLayout.width / 2 - helperLayout.width / 2,
      width: helperLayout.width
    });
    computedCaretPosition.x = (helperLayout.width - CARET_SIZE) / 2;
  } else if (currLayout.x + helperLayout.width <= window.width) {
    // align to right of node
    Object.assign(computedGuidePosition, { x: currLayout.x, width: helperLayout.width });
    computedCaretPosition.x = HORIZONTAL_CARET_ADJUSTMENT;
  } else if (currLayout.x - helperLayout.width > 0) {
    // align to left of node
    Object.assign(computedGuidePosition, {
      x: currLayout.x + currLayout.width - helperLayout.width,
      width: helperLayout.width
    });
    computedCaretPosition.x = helperLayout.width - HORIZONTAL_CARET_ADJUSTMENT;
  } else {
    // align to center of screen
    const xPos = window.width / 2 - helperLayout.width / 2;
    Object.assign(computedGuidePosition, {
      x: xPos,
      width: helperLayout.width
    });
    computedCaretPosition.x = currLayout.x - xPos + CARET_SIZE;
  }
  return { computedGuidePosition, computedCaretPosition, computedCaretRotation };
};

export const focusView = (
  x: number,
  y: number,
  scrollRef: React.RefObject<Scrollable>,
  itemIndex?: number,
  sectionIndex?: number,
  viewOffset?: number
) => {
  const scrollView = scrollRef.current as Partial<ScrollView>;
  if (scrollView.scrollTo) {
    return scrollView.scrollTo({ x: x - Size.S, y: y - Size.S, animated });
  }
  const flatList = scrollRef.current as Partial<FlatList>;
  if (flatList.scrollToOffset) {
    return flatList.scrollToOffset({
      offset: (flatList.props?.horizontal ? x : y) - Size.S,
      animated
    });
  }
  const sectionList = scrollRef.current as Partial<SectionList>;
  const scrollableNode = sectionList.getScrollableNode?.() as any as ScrollView;
  if (scrollableNode?.scrollTo) {
    return scrollableNode.scrollTo({ x: x - Size.S, y: y - Size.S, animated });
  }
  if (sectionList.scrollToLocation && isDefined(itemIndex) && isDefined(sectionIndex)) {
    return sectionList.scrollToLocation({
      itemIndex,
      sectionIndex,
      viewOffset,
      animated
    });
  }
};

export const measureRefWithoutScrollView: (
  ref: React.RefObject<View>,
  guideDimensions: Dimensions,
  callback: (l: LayoutRectangle, h: Dimensions) => void
) => void = (viewRef, guideDimensions, callback) => {
  if (IS_ANDROID) {
    viewRef.current?.measure((x, y, width, height, pageX, pageY) => {
      const l = { x: x + pageX, y: y + pageY, width, height };
      callback(l, { width: guideDimensions.width, height: guideDimensions.height });
    });
  } else {
    viewRef.current?.measureInWindow((x, y, width, height) => {
      const l = { x, y, width, height };
      callback(l, { width: guideDimensions.width, height: guideDimensions.height });
    });
  }
};

/**
 * Transforms a layout object from portrait to landscape and vice versa
 * @param layout
 * @returns
 */
const invertLayout = (layout: Layout) => {
  return { x: layout.y, y: layout.x, width: layout.height, height: layout.width };
};

/**
 * Transforms a dimensions object from portrait to landscape and vice versa
 * @param dimensions
 * @returns
 */
const invertDimensions = (dimensions: Dimensions) => {
  return { width: dimensions.height, height: dimensions.width };
};

/**
 * Aligns the helper horizontally by inverting the layout and dimensions, aligning vertically, and then inverting the result
 * @param currLayout
 * @param helperLayout
 * @param window
 * @returns
 */
const alignHorizontally = (currLayout: Layout, helperLayout: Dimensions, window: Dimensions) => {
  const invertedCurrLayout = invertLayout(currLayout);
  const invertedHelperLayout = invertDimensions(helperLayout);
  const invertedWindow = invertDimensions(window);

  const {
    computedGuidePosition: _guidePosition,
    computedCaretPosition: _caretPosition,
    computedCaretRotation: _caretRotation
  } = alignVertically(invertedCurrLayout, invertedHelperLayout, invertedWindow);

  const computedGuidePosition = invertLayout(_guidePosition);
  const computedCaretPosition = invertLayout(_caretPosition);
  const computedCaretRotation = _caretRotation - 1;

  return { computedGuidePosition, computedCaretPosition, computedCaretRotation };
};

/**
 * Normalizes x to ensure elements are horizontally postioned inside viewable area
 * @param x
 * @param windowWidth
 * @returns
 */
export const normalizeX = (x: number, windowWidth: number) => {
  return x % windowWidth;
};
