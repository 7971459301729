import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';

export enum STEPS {
  INFO_UPDATE
}

const UserInfoTourMap: TourMap<STEPS> = {
  [STEPS.INFO_UPDATE]: {
    id: STEPS.INFO_UPDATE,
    priority: 0
  }
};

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.USER_INFO,
  UserInfoTourMap
);
