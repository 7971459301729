import { Request, RequestType } from 'src/interfaces';

/*
 * Get the name of the individual that this request is targeted for
 * @param request - Request
 * @returns the name of the request's target
 */
export const getRequestTargetName = (request: Request) =>
  request.type === RequestType.InfoUpdate
    ? request?.patient?.name ?? 'requests:requestFields:myself'
    : request.patient?.name ?? request.content?.petName ?? 'requests:requestFields:unknown';
