import React, { useCallback } from 'react';
import { Headline, Subheading, TextInput } from 'src/components';
import { IconSize, PHONE_MASK } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useBookingState } from '../BookingProvider';
import {
  ContentContainer,
  InputContainer,
  MainText,
  NoticeContainer,
  NoticeIcon,
  NoticeSection,
  NoticeText,
  Row,
  StyledScrollView
} from './shared';
import { Info } from 'react-native-feather';
import { useTranslation } from 'react-i18next';
import { mask, unMask } from 'react-native-mask-text';
import { clientSchema } from '../BookingProvider/validation';
import { ValidationError } from 'yup';
import TermsCheckbox from './TermsCheckbox';
import RichTextRenderer from 'src/components/PracticeTextRenderer';
const ClientInformation: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const {
    requestSetting,
    practiceInfo,
    values: { clientInfo },
    setFieldValue,
    validateField,
    errors,
    clientPatientQuery: { mutateAsync }
  } = useBookingState();
  const { colors, viewMode } = useAppTheme();

  const checkForTerms = useCallback(async () => {
    try {
      await clientSchema.validate(clientInfo, { abortEarly: false });
    } catch (e) {
      const { errors } = e as ValidationError;
      if (errors.length === 1 && errors[0] === 'errors.acceptConditionsRequired') {
        await validateField('clientInfo.acceptConditions');
      }
    }
  }, [clientInfo, validateField]);

  return (
    <StyledScrollView>
      <ContentContainer>
        <MainText as={Headline}>{practiceInfo.name}</MainText>
        <RichTextRenderer baseStyle={{ color: viewMode.isMobile ? colors.text : colors.onSurface }}>
          {requestSetting.headerText}
        </RichTextRenderer>
      </ContentContainer>
      <NoticeContainer>
        <NoticeSection>
          <NoticeIcon>
            <Info color={colors.primary} width={IconSize.XS} height={IconSize.XS} />
          </NoticeIcon>
          <NoticeText>{t('clientInfo.disclaimer.notScheduled')}</NoticeText>
        </NoticeSection>
        <TermsCheckbox />
      </NoticeContainer>
      <ContentContainer>
        <MainText as={Subheading}>{t('clientInfo.fillInformation')}</MainText>
        <MainText>{t('clientInfo.useEmail')}</MainText>
      </ContentContainer>
      <Row>
        <InputContainer halfSize>
          <TextInput
            textContentType='name'
            placeholder={t('form.fullName')}
            value={clientInfo?.fullName}
            onChangeText={async (value) => setFieldValue('clientInfo.fullName', value, false)}
            onBlur={async () => {
              await validateField('clientInfo.fullName');
              await checkForTerms();
            }}
            error={!!errors.clientInfo?.fullName}
            msg={t(errors.clientInfo?.fullName ?? '')}
          />
        </InputContainer>
        <InputContainer halfSize>
          <TextInput
            textContentType='emailAddress'
            placeholder={t('form.email')}
            autoCapitalize='none'
            value={clientInfo?.emailAddress}
            onChangeText={async (value) => setFieldValue('clientInfo.emailAddress', value, false)}
            onBlur={async (e) => {
              const email = e.nativeEvent.text;
              await validateField('clientInfo.emailAddress').then(async () => {
                void checkForTerms();
                if (!email) return;
                await mutateAsync({
                  email: clientInfo.emailAddress,
                  practiceId: practiceInfo.practiceId,
                  phoneNumber: clientInfo.phoneNumber
                });
              });
            }}
            error={!!errors.clientInfo?.emailAddress}
            msg={t(errors.clientInfo?.emailAddress ?? '')}
          />
        </InputContainer>
        <InputContainer halfSize>
          <TextInput
            textContentType='telephoneNumber'
            placeholder={t('form.phone')}
            value={mask(clientInfo?.phoneNumber ?? '', PHONE_MASK)}
            onChangeText={async (value) => {
              const unmasked = unMask(value);
              await setFieldValue('clientInfo.phoneNumber', unmasked, false);
            }}
            onBlur={async (e) => {
              await validateField('clientInfo.phoneNumber');
              await checkForTerms();
              await mutateAsync({
                email: clientInfo.emailAddress,
                phoneNumber: unMask(e.nativeEvent.text),
                practiceId: practiceInfo.practiceId
              });
            }}
            error={!!errors.clientInfo?.phoneNumber}
            msg={t(errors.clientInfo?.phoneNumber ?? '')}
          />
        </InputContainer>
      </Row>
    </StyledScrollView>
  );
};

export default ClientInformation;
