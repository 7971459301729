import React from 'react';
import PatientListContent from '../PatientList/PatientListContent';
import { PATIENT_STATUSES } from 'src/constants';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import PatientUpdateStackParamsList from 'src/routes/stacks/PatientUpdateStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';

const PatientSelector: React.FC<
  NativeStackScreenProps<PatientUpdateStackParamsList, Screens.REQUESTS_PATIENT_SELECTOR>
> = ({ route }) => {
  if (route?.params?.dismiss) {
    // Navigate to requests home
  }

  return <PatientListContent patientStatus={PATIENT_STATUSES.ACTIVE} isFromRequestWizard={true} />;
};

export default PatientSelector;
