import { Appointment, Patient } from 'src/interfaces';
import { PathConfig } from 'src/routes/types';
import { Screens } from '../screens';

export type AppointmentStackParamsList = {
  [Screens.APPOINTMENTS]?: {
    id?: Patient['patientId'];
  };
  [Screens.APPOINTMENT_DETAILS]: {
    id: Appointment['appointmentId'];
  };
};

export const APPOINTMENT_STACK_PATHS: PathConfig<AppointmentStackParamsList> = {
  initialRouteName: Screens.APPOINTMENTS,
  path: 'appointment',
  screens: {
    [Screens.APPOINTMENTS]: '',
    [Screens.APPOINTMENT_DETAILS]: 'details'
  }
};

export default AppointmentStackParamsList;
