import { useEffect, useState } from 'react';
import * as LocalAuthentication from 'expo-local-authentication';

export interface UseBiometricsHook {
  canUseBiometrics: boolean | undefined;
  supportsFaceRecognition: boolean;
}
const useBiometrics = (): UseBiometricsHook => {
  const [canUseBiometrics, setCanUseBiometrics] = useState<boolean>();
  const [supportsFaceRecognition, setSupportsFaceRecognition] = useState<boolean>(false);

  useEffect(() => {
    void LocalAuthentication.hasHardwareAsync().then((hasHardware) => {
      if (hasHardware) {
        void LocalAuthentication.isEnrolledAsync().then((isEnrolled) => {
          setCanUseBiometrics(isEnrolled);
        });
      }
    });

    void LocalAuthentication.supportedAuthenticationTypesAsync().then((localAuthTypes) => {
      setSupportsFaceRecognition(
        !!localAuthTypes?.includes(LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION)
      );
    });
  }, []);

  return {
    canUseBiometrics,
    supportsFaceRecognition
  };
};

export default useBiometrics;
