import React, { PropsWithChildren } from 'react';
import defaultContext from './defaultContext';

const NotificationPromptContext = React.createContext(defaultContext);
export const useNotificationPrompt = () => React.useContext(NotificationPromptContext);

const NotificationPromptProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export default NotificationPromptProvider;
