import React from 'react';
import { Column, Row, Shimmer } from 'src/components';
import { IconSize, MOBILE_WIDTH_MAX, Size } from 'src/constants';
import { fontStyles } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

const TodoItemFallback: React.FC = () => (
  <StyledItem>
    <AvatarContainer>
      <Shimmer width={IconSize.L} height={IconSize.L} borderRadius={Size.X3_S} />
    </AvatarContainer>
    <Column>
      <Shimmer width={MOBILE_WIDTH_MAX / 4} height={fontStyles.body.fontSize} />
      <Shimmer width={MOBILE_WIDTH_MAX / 2} height={fontStyles.caption.fontSize} />
      <Shimmer width={MOBILE_WIDTH_MAX / 3} height={fontStyles.caption.fontSize} />
    </Column>
    <Shimmer
      width={IconSize.XS}
      height={IconSize.XS}
      borderRadius={Size.X3_S}
      style={{ marginTop: Size.S }}
    />
  </StyledItem>
);

export default TodoItemFallback;

const AvatarContainer = styled.View`
  align-self: center;
  margin-right: ${Size.S}px;
`;

const StyledItem = styled(Row)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${Size.X2_S}px;
`;
