import EmailVerificationStatus from './EmailVerificationStatus';

export interface AvailablePractice {
  clientId: string;
  sourceId: number;
  practiceId: number;
  practiceName: string;
  practiceEmail: string;
  shortName: string;
  userId: number;
  userConfirmationStatus: UserConfirmationStatus;
  emailVerificationStatus: EmailVerificationStatus;
  userRegistered: boolean;
}

export enum UserConfirmationStatus {
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  CONFIRMED = 'confirmed'
}
