import * as Linking from 'expo-linking';
import Toast from 'react-native-toast-message';
import { t } from 'i18next';

const openEmail = async (email: string | undefined) => {
  if (!email)
    return Toast.show({
      type: 'error',
      text1: t('noEmailProvided', { ns: 'common' })
    });
  const url = `mailto:${email}`;
  try {
    if (await Linking.canOpenURL(url)) {
      return await Linking.openURL(url);
    }
  } catch {}
  Toast.show({
    type: 'error',
    text1: t('unableToEmail', { ns: 'common' })
  });
};

export default openEmail;
