import React from 'react';
import { Image, View } from 'react-native';
import styled from 'styled-components/native';
import AnimalIcon from '../../../components/Icons/Animal';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { colorShade } from 'src/utils';
import { IconSize } from 'src/constants';
import { layout } from 'src/theme/globalStyles';
import { ActivityIndicator } from 'react-native-paper';

interface Props {
  uri: string;
  species: string;
  width: number;
  aspectRatio: number;
  patientIsMutating: boolean;
}

const TempImageAvatar: React.FC<Props> = ({
  uri,
  species,
  aspectRatio,
  width,
  patientIsMutating
}) => {
  const { colors } = useAppTheme();

  return (
    <PictureContainer aspectRatio={aspectRatio} width={width}>
      {patientIsMutating && (
        <ActivityIndicator size={IconSize.XXXL} color={colors.disabled} style={layout.flex1} />
      )}
      {!patientIsMutating && !!uri && <Image source={{ uri }} style={{ aspectRatio }} />}
      {!patientIsMutating && !uri && (
        <View style={layout.alignSelfCenter}>
          <AnimalIcon color={colors.disabled} species={species} size={IconSize.XXXL} />
        </View>
      )}
    </PictureContainer>
  );
};

export default TempImageAvatar;

const PictureContainer = styled.View<{
  aspectRatio: number;
  width: number;
}>`
  justify-content: center;
  width: ${({ width }) => width}px;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  border-radius: ${({ theme }) => theme.roundness}px;
  background-color: ${({ theme }) => colorShade(theme.colors.surface, 0.8)};
`;
