import { Moment } from 'moment-timezone';

/**
 * Gets the offset time for a todo alert. If no such offset exists, it creates one
 */
export const getOffsetTime = (offsetMap: Map<number, number>, alarmTime: Moment) => {
  const secondsOffset = (offsetMap.get(alarmTime.valueOf()) ?? -1) + 1;
  offsetMap.set(alarmTime.valueOf(), secondsOffset);
  return secondsOffset;
};
