import Practice from 'src/interfaces/api/Practice';
import ClientPracticeConfigurationSection from './ClientPracticeConfigurationSection';
import draftSms from 'src/utils/draftSms';

const START_MESSAGE = 'START';

export const CONFIG_STRUCTURE: ClientPracticeConfigurationSection[] = [
  {
    subheader: 'profileClientPracticeConfig:textSectionHeader',
    disabledText: 'profileClientPracticeConfig:clientSmsDisabled',
    disabledAction: async (practice: Practice) => draftSms(practice.smsNumber, START_MESSAGE),
    data: [
      {
        title: 'profileClientPracticeConfig:remindersTitle',
        description: 'profileClientPracticeConfig:remindersDescription',
        field: 'automaticSmsUnsubscribed',
        disabledField: 'clientAutomaticSmsUnsubscribed'
      },
      {
        title: 'profileClientPracticeConfig:directTitle',
        description: 'profileClientPracticeConfig:textDirectDescription',
        field: 'directSmsUnsubscribed',
        disabledField: 'clientDirectSmsUnsubscribed'
      }
    ]
  },
  {
    subheader: 'profileClientPracticeConfig:emailSectionHeader',
    data: [
      {
        title: 'profileClientPracticeConfig:remindersTitle',
        description: 'profileClientPracticeConfig:remindersDescription',
        field: 'automaticEmailUnsubscribed'
      },
      {
        title: 'profileClientPracticeConfig:newsletterTitle',
        description: 'profileClientPracticeConfig:newsletterDescription',
        field: 'massEmailUnsubscribed'
      },
      {
        title: 'profileClientPracticeConfig:directTitle',
        description: 'profileClientPracticeConfig:emailDirectDescription',
        field: 'directEmailUnsubscribed'
      }
    ]
  },
  {
    subheader: 'profileClientPracticeConfig:mailSectionHeader',
    data: [
      {
        title: 'profileClientPracticeConfig:postcardTitle',
        description: 'profileClientPracticeConfig:postcardDescription',
        field: 'automaticPostcardUnsubscribed'
      }
    ]
  }
];
