import { BOOKING_STEPS } from './enums';
import { BookingFormData } from '../model';
import {
  additionalInfoSchema,
  appointmentSchema,
  clientSchema,
  notificationSchema,
  patientSchema,
  validationSchema
} from '../validation';

const STEP_INFO: {
  [key in BOOKING_STEPS]: {
    title: `section.${string}`;
    field: keyof BookingFormData | null;
    validator: (data: Partial<BookingFormData>) => boolean;
    key: key;
  };
} = {
  [BOOKING_STEPS.CLIENT_INFO]: {
    title: 'section.clientInfo',
    field: 'clientInfo',
    validator: (data) => clientSchema.isValidSync(data.clientInfo),
    key: BOOKING_STEPS.CLIENT_INFO
  },
  [BOOKING_STEPS.PATIENT_INFO]: {
    title: 'section.patientInfo',
    field: 'patientInfo',
    validator: (data) => patientSchema.isValidSync(data.patientInfo),
    key: BOOKING_STEPS.PATIENT_INFO
  },
  [BOOKING_STEPS.NOTIFICATION_SETTINGS]: {
    title: 'section.notificationSettings',
    field: 'notificationSettings',
    validator: (data) => notificationSchema.isValidSync(data.notificationSettings),
    key: BOOKING_STEPS.NOTIFICATION_SETTINGS
  },
  [BOOKING_STEPS.ADDITIONAL_INFO]: {
    title: 'section.additionalInfo',
    field: 'additionalInfo',
    validator: (data) => additionalInfoSchema.isValidSync(data.additionalInfo),
    key: BOOKING_STEPS.ADDITIONAL_INFO
  },
  [BOOKING_STEPS.SELECT_APPOINTMENT]: {
    title: 'section.selectAppointment',
    field: 'appointmentInfo',
    validator: (data) => appointmentSchema.isValidSync(data.appointmentInfo),
    key: BOOKING_STEPS.SELECT_APPOINTMENT
  },
  [BOOKING_STEPS.CONFIRMATION]: {
    title: 'section.confirmation',
    field: null,
    validator: (data) => validationSchema.isValidSync(data),
    key: BOOKING_STEPS.CONFIRMATION
  },
  [BOOKING_STEPS.STATUS]: {
    title: 'section.status',
    field: null,
    validator: () => true,
    key: BOOKING_STEPS.STATUS
  }
};

export default STEP_INFO;
