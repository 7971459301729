import _ from 'lodash';
import React from 'react';
import { Shimmer, Column } from 'src/components';
import { Size } from 'src/constants';
import { StyledRow, IconContainer, StyledShimmerHeading, StyledShimmerBody } from '../styled';

const NUM_DUMMY_FIELDS = 7;

const LoadingDetails: React.FC = () => (
  <>
    {_.times(NUM_DUMMY_FIELDS, (i) => (
      <StyledRow key={`dummy-${i.toString()}`}>
        <IconContainer>
          <Shimmer size={Size.M} />
        </IconContainer>
        <Column>
          <StyledShimmerHeading />
          <StyledShimmerBody />
        </Column>
      </StyledRow>
    ))}
  </>
);

export default LoadingDetails;
