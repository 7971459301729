import { noop } from 'lodash';
import { PropsWithChildren, createContext, useCallback, useContext, useRef, useState } from 'react';
import { AsyncStorageKeys } from 'src/api';
import useStorage from 'src/hooks/useStorage';
import { TOUR, TourGuideHandle } from './model';
import { queryClient, storage } from 'src/utils';

interface TourDetail {
  tourId: symbol;
  /** The heigher the number, the lower priority. */
  priority: number;
}
interface Context {
  activeTour?: TourDetail;
  /**
   * Takes a tour ID and enters it into a priority queue.
   * @param tourId A unique identifier for the tour
   * @param priority The smaller the number, the higher the priority. If no priority is specified, it is given the lowest priority.
   */
  isActivelyTouring: boolean;
  queueTour: (tourId: symbol, priority?: number) => void;
  dequeueTour: (tourId: symbol) => void;
  registerTour: (tourId: symbol, ref: React.RefObject<TourGuideHandle>) => void;
  unregisterTour: (tourId: symbol) => void;
  toursEnabled: boolean;
  toursInFocus: TourDetailWithRef[];
  resetTour: (tourId: TOUR) => void;
  stopTours: () => Promise<void>;
}

export interface TourDetailWithRef extends Omit<TourDetail, 'priority'> {
  ref: React.RefObject<TourGuideHandle>;
}

const DEFAULT_CONTEXT: Context = {
  isActivelyTouring: false,
  toursEnabled: false,
  queueTour: noop,
  dequeueTour: noop,
  registerTour: noop,
  unregisterTour: noop,
  resetTour: noop,
  stopTours: Promise.resolve,
  toursInFocus: []
};

const TourGuideMasterContext = createContext<Context>(DEFAULT_CONTEXT);

export const TourGuideMasterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tourQueue, setTourQueue] = useState<TourDetail[]>([]);
  const [tutorialsDisabled] = useStorage<boolean>(AsyncStorageKeys.TUTORIALS_DISABLED);

  const [toursInFocus, setToursInFocus] = useState<TourDetailWithRef[]>([]);

  const registerTour: Context['registerTour'] = useRef(
    (tourId: symbol, ref: React.RefObject<TourGuideHandle>) => {
      setToursInFocus((prev) => {
        if (!prev.find((tour) => tour?.tourId === tourId)) {
          return [...prev, { tourId, ref }];
        }
        return prev;
      });
    }
  ).current;

  const unregisterTour: Context['unregisterTour'] = useRef((tourId: symbol) => {
    setToursInFocus((prev) => prev.filter((v) => v.tourId !== tourId));
  }).current;

  const queueTour: Context['queueTour'] = useRef(
    (tourId: symbol, priority = Number.MAX_SAFE_INTEGER) => {
      setTourQueue((queue) => {
        if (!queue.find((tour) => tour?.tourId === tourId)) {
          const newTour: TourDetail = {
            priority: priority ?? Number.MAX_SAFE_INTEGER,
            tourId
          };
          if (priority !== Number.MAX_SAFE_INTEGER) {
            const insertPosition: number = queue.findIndex((tour) => tour.priority > priority);
            if (insertPosition !== -1) {
              const beg = queue.slice(0, insertPosition);
              const end = queue.slice(insertPosition);
              return [...beg, newTour, ...end];
            }
          }
          return [...queue, newTour];
        }
        return queue;
      });
    }
  ).current;

  const dequeueTour: Context['dequeueTour'] = useRef((tourId: symbol) => {
    setTourQueue((prev) => prev.filter((v) => v.tourId !== tourId));
  }).current;

  const activeTour = tourQueue[0];
  const isActivelyTouring = !!tourQueue[0];

  const resetTour = async (tourId: TOUR) => {
    const tourController = toursInFocus.find((tour) => tour.tourId.description === tourId);
    await storage.removeItem([AsyncStorageKeys.TUTORIALS, tourId].join(','));
    await queryClient.invalidateQueries([AsyncStorageKeys.TUTORIALS, tourId]);
    await tourController?.ref.current?.reset();
  };

  const stopTours = useCallback(async () => {
    await Promise.all(
      Object.values(TOUR).map(async (tourId) => {
        await storage.setItem([AsyncStorageKeys.TUTORIALS, tourId].join(','), 'true');
        await queryClient.invalidateQueries([AsyncStorageKeys.TUTORIALS, tourId]);
      })
    );
  }, []);
  return (
    <TourGuideMasterContext.Provider
      value={{
        activeTour,
        isActivelyTouring,
        queueTour,
        dequeueTour,
        toursEnabled: !tutorialsDisabled,
        stopTours,
        toursInFocus,
        registerTour,
        unregisterTour,
        resetTour
      }}
    >
      {children}
    </TourGuideMasterContext.Provider>
  );
};

export const useTourGuideMaster = () => useContext(TourGuideMasterContext);
