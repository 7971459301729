import { endpoint } from 'src/constants';
import { ApiPracticeInfo } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';
import PracticeConfiguration from 'src/interfaces/api/PracticeConfiguration';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';

export const getPractice = async (practiceId?: number): Promise<Practice> => {
  if (!practiceId) {
    throw new Error('Practice ID is required');
  }
  const response = await apiClient.get(endpoint('GET_PRACTICE', { id: practiceId }));
  return isDefinedOrThrowServerError(response.data);
};

export const getPractices = async (): Promise<Practice[]> => {
  const response = await apiClient.get<Practice[]>(endpoint('GET_PRACTICES'));
  return isDefinedOrThrowServerError(response.data, Array.isArray);
};

export const getPracticeConfiguration = async (
  practiceId: number
): Promise<PracticeConfiguration> => {
  const response = await apiClient.get(endpoint('GET_PRACTICE_CONFIG', { practiceId }));
  return isDefinedOrThrowServerError(response.data);
};

export const getPracticeInfo = async (shortName?: string): Promise<ApiPracticeInfo> => {
  if (!shortName) {
    throw new Error('Practice short name is required');
  }
  const response = await unauthenticatedApiClient.get<ApiPracticeInfo>(
    endpoint('PRACTICE_INFO', {
      shortName
    })
  );
  return isDefinedOrThrowServerError(response.data);
};
