import React, { SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Body, Caption, Headline, TextInput } from 'src/components';
import { Size } from 'src/constants';
import UploadFiles, { ImageUpload } from 'src/components/UploadFiles';
import styled from 'styled-components/native';
import { Weight, palette } from 'src/theme';
import { GuideElement, STEPS, useTourGuide } from 'src/scenes/PatientList/tour';
import useGoBack from 'src/hooks/useGoBack';
import color from 'color';
import { FormSection } from '../../style';
import { FormStateHook } from '../../hooks/useFormState';

interface Props {
  values: FormStateHook['values'];
  setFieldValue: FormStateHook['setFieldValue'];
  errors: FormStateHook['errors'];
}

const CommentsAndFiles: React.FC<Props> = ({ values, setFieldValue, errors }) => {
  const { t } = useTranslation('patientProfile');
  const [images, setImages] = useState<Record<string, ImageUpload>>(values.images ?? {});
  const onImageChange = useCallback(
    async (action: SetStateAction<Record<string, ImageUpload>>) => {
      setImages((prev) => {
        const value = typeof action === 'function' ? action(prev) : action;
        void setFieldValue('images', value);
        return value;
      });
    },
    [setFieldValue]
  );

  const { exitTour } = useTourGuide();
  const goBack = useGoBack();
  const exitTourAndGoBackToMyPets = useCallback(async () => {
    await exitTour();
    goBack();
  }, [exitTour, goBack]);

  return (
    <GuideElement
      body={t('tour.commentsAndFiles')}
      id={STEPS.EDIT_COMMENTS}
      onContinue={async () => exitTourAndGoBackToMyPets()}
      semiTransparentBg
    >
      <FormSection>
        <Headline>{t('commentsAndFiles')}</Headline>

        <Caption>{t('needToUpdate')}</Caption>
        <View style={{ gap: Size.X3_S }}>
          <TextInput
            label={t('comments')}
            value={values.comments ?? ''}
            onChangeText={async (comments) => await setFieldValue('comments', comments)}
            error={!!errors.comments}
            msg={errors.comments && t(errors.comments)}
            multiline
          />
          <UploadContainer>
            <Label error={!!errors.images} hasData={!!Object.keys(values.images).length}>
              {t('common:addFiles')}
            </Label>
            <UploadFiles onImageChange={onImageChange} images={images} />
          </UploadContainer>
          {typeof errors.images === 'string' && <ErrorCaption>{errors.images}</ErrorCaption>}
        </View>
      </FormSection>
    </GuideElement>
  );
};

export default CommentsAndFiles;

const Label = styled(Body)<{ error: boolean; hasData: boolean }>`
  color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.placeholder)};
  font-size: ${({ hasData }) => (hasData ? Size.XS : Size.S)}px;
  margin-bottom: ${({ hasData }) => (hasData ? -Size.S : 0)}px;
  margin-top: ${({ hasData }) => (hasData ? -Size.X3_S : Size.X3_S)}px;
  margin-left: ${-Size.X4_S}px;
  font-weight: ${Weight.REGULAR};
`;

const ErrorCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.error};
`;

const UploadContainer = styled.View`
  padding-top: ${Size.S}px;
  padding-horizontal: ${Size.S}px;
  background-color: ${({ theme }) =>
    theme.dark
      ? color(theme.colors.background).lighten(0.24).rgb().string()
      : color(theme.colors.background).darken(0.06).rgb().string()};
  color: ${({ theme }) => (theme.dark ? palette.WHITE_OPACITY_54 : palette.BLACK_OPACITY_54)};
  border-bottom-width: ${Size.X4_S}px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
`;
