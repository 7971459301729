import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ChevronRight } from 'react-native-feather';
import { Body } from 'src/components';
import { IconSize } from 'src/constants';
import { TOUR } from 'src/module/TourGuide/model';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import { layout } from 'src/theme/globalStyles';
import { StyledHoverCardRow, ToggleIconContainer } from '../style';
import { useTourGuideMaster } from 'src/module/TourGuide/TourGuideMaster';

interface Props {
  label?: string;
  stack?: Navigators;
  screenName?: Screens;
  tours?: TOUR[];
}
const TourGuideCard: React.FC<Props> = ({ label, stack, screenName, tours }) => {
  const navigation = useNavigation();
  const { colors } = useAppTheme();
  const { resetTour } = useTourGuideMaster();

  const onPress = async () => {
    if (stack && screenName) {
      await Promise.all(tours?.map((tour) => resetTour(tour)) ?? []);
      navigation.getParent()?.navigate(stack, { screen: screenName });
    }
  };

  if (!label || !stack || !screenName || !tours?.length) return null;
  return (
    <StyledHoverCardRow onPress={onPress}>
      <Body style={layout.flex1} color={colors.onSurface}>
        {label}
      </Body>
      <ToggleIconContainer>
        <ChevronRight width={IconSize.XS} height={IconSize.XS} color={colors.onSurface} />
      </ToggleIconContainer>
    </StyledHoverCardRow>
  );
};

export default TourGuideCard;
