import React, { memo } from 'react';
import { AlertCircle, Clock } from 'react-native-feather';
import { SUBMISSION_STATE } from '../BookingProvider/helpers';
import { SvgProps } from 'react-native-svg';
import { Calendar } from 'src/components/kit/ABIcons';
import { ActivityIndicator } from 'react-native-paper';

interface Props extends SvgProps {
  state: SUBMISSION_STATE;
}

const BookingStateIcon: React.FC<Props> = ({ state, ...props }) => {
  switch (state) {
    case SUBMISSION_STATE.SUBMITTING:
    case SUBMISSION_STATE.PENDING:
      return <ActivityIndicator {...props} color={props.color as string} />;
    case SUBMISSION_STATE.UNAVAILABLE:
    case SUBMISSION_STATE.INVALID:
      return <AlertCircle {...props} />;
    case SUBMISSION_STATE.SCHEDULED:
      return <Calendar {...props} />;
    case SUBMISSION_STATE.MANUAL:
      return <Clock {...props} />;
  }
};

export default memo(BookingStateIcon);
