import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import { mask } from 'react-native-mask-text';
import { Body, HoverCard, Row } from 'src/components';
import { IconSize, Margin, PHONE_MASK, Size } from 'src/constants';
import { cleanUrl, openMaps } from 'src/utils';
import styled from 'styled-components/native';
import makePhoneCall from 'src/utils/makePhoneCall';
import { MapPin, Phone } from 'react-native-feather';
import { Weight } from 'src/theme';
import EmergencyContact from 'src/interfaces/api/EmergencyContact';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  practice: EmergencyContact;
}

const AfterHoursContactCard: React.FC<Props> = ({ practice }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('afterHours');
  const [aspectRatio, setAspectRatio] = useState<number>(0);

  const url = useMemo(() => {
    return practice.image?.urls?.original ? cleanUrl(practice.image.urls.original) : undefined;
  }, [practice]);

  useEffect(() => {
    if (url) {
      Image.getSize(url, (width, height) => {
        setAspectRatio(width / height);
      });
    }
  }, [url]);

  return (
    <Container>
      {!!url && (
        <PracticeImage aspectRatio={aspectRatio} source={{ uri: url }} resizeMode='contain' />
      )}
      <ActionButton onPress={() => makePhoneCall(practice.phone)}>
        <StyledRow>
          <IconContainer>
            <Phone color={colors.onSurface} />
          </IconContainer>
          <Column>
            <SectionTitle fontWeight={Weight.MEDIUM}>{t('callNow')}</SectionTitle>
            <SectionValue>{mask(practice.phone, PHONE_MASK)}</SectionValue>
          </Column>
        </StyledRow>
      </ActionButton>
      <ActionButton onPress={async () => await openMaps(practice.address, practice.name)}>
        <StyledRow>
          <IconContainer>
            <MapPin color={colors.onSurface} />
          </IconContainer>
          <Column>
            <SectionTitle fontWeight={Weight.MEDIUM}>{t('getDirections')}</SectionTitle>
            <SectionValue numberOfLines={2}>{practice.address}</SectionValue>
          </Column>
        </StyledRow>
      </ActionButton>
      <Instructions>{practice.instructions}</Instructions>
    </Container>
  );
};

export default AfterHoursContactCard;

const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.surface};
`;

const PracticeImage = styled.Image<{ aspectRatio: number }>`
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

const ActionButton = styled(HoverCard).attrs({ hideShadow: true })`
  padding-horizontal: ${Size.X2_S}px;
  padding-vertical: ${Size.S}px;
`;

const Column = styled.View`
  flex-direction: column;
  flex: 1;
`;

const SectionTitle = styled(Body)`
  margin-bottom: ${Size.X3_S}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const SectionValue = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;

const StyledRow = styled(Row)`
  align-items: flex-start;
`;

const IconContainer = styled.View`
  width: ${IconSize.XS}px
  height: ${IconSize.XS}px
  align-items: center;
  justify-content: center;
  margin-top: ${Size.X4_S}px;
  margin-right: ${Size.XS}px;
`;

const Instructions = styled(Body)`
  margin-vertical: ${Margin.Large}px;
  margin-horizontal: ${Margin.Large}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;
