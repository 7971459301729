import { Divider } from 'react-native-paper';
import { Margin, Size } from 'src/constants/general';
import styled from 'styled-components/native';
import { Body, Row } from 'src/components';

export const StyledDivider = styled(Divider)`
  margin-vertical: ${Size.S}px;
  height: ${Size.X5_S}px;
`;

export const StyledRow = styled(Row)`
  gap: ${Margin.Small}px;
`;
export const ConfirmChangesSection = styled.View`
  margin-bottom: ${Size.S}px;
  margin-horizontal: ${Size.S}px;
`;

export const RadioButtonGroupText = styled(Body)`
  margin-horizontal: ${Margin.ExtraLarge}px;
  margin-bottom: ${Margin.Large}px;
  align-self: center;
  text-align: center;
`;
