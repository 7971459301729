import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body, Caption, Subheading } from 'src/components';
import { Size } from 'src/constants';
import { useReminders } from 'src/hooks';
import { Weight } from 'src/theme';
import { layout } from 'src/theme/globalStyles';
import { Container, RowDescription, TableRow } from './style';

interface Props {
  patientId: string;
}

const MedicalReminders: React.FC<Props> = ({ patientId }) => {
  const { t, i18n } = useTranslation('petID');
  const { data: reminders } = useReminders();

  const filteredReminders = reminders.filter((r) => r.patientId === patientId);

  return (
    <Container>
      <Subheading style={{ marginTop: Size.X2_S, marginBottom: Size.X3_S }}>
        {t('medicalReminders')}
      </Subheading>
      {!filteredReminders.length && <Caption fontSize={12}>{t('none')}</Caption>}
      {!!filteredReminders?.length && (
        <TableRow>
          <Body fontWeight={Weight.MEDIUM} style={layout.flex1}>
            {t('description', { ns: 'patientProfile' })}
          </Body>
          <Body fontWeight={Weight.MEDIUM}>{t('dueDate')}</Body>
        </TableRow>
      )}
      {!!filteredReminders?.length &&
        filteredReminders.map((reminder, i) => {
          return (
            <TableRow key={i}>
              <RowDescription numberOfLines={1}>{reminder.description}</RowDescription>
              <Body>{moment(reminder.pastDueDate).locale(i18n.language).format('L')}</Body>
            </TableRow>
          );
        })}
    </Container>
  );
};

export default MedicalReminders;
