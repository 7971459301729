import React, { useEffect, useMemo } from 'react';
import { LayoutAnimation, SafeAreaView } from 'react-native';
import { Caption, Headline } from 'src/components';
import { useTranslation } from 'react-i18next';
import {
  InnerContainer,
  AllyBlueGradient,
  ScreenTitle,
  PracticeRow,
  LoadingIndicator,
  Section,
  UnselectableRow,
  StyledBody
} from './styled';
import LogoHeader from '../Landing/components/LogoHeader';
import { useAvailablePractices } from 'src/hooks';
import { useUser } from 'src/providers/ClientProvider';
import { useMutation } from 'react-query';
import { MutationKeys, switchPractice, SwitchPracticeRequest } from 'src/api';
import { useAuth } from 'src/providers/AuthProvider';
import { IS_WEB, MOBILE_WIDTH_MAX, Size } from 'src/constants';
import { useNavigation } from '@react-navigation/native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { AvailablePractice, UserConfirmationStatus } from 'src/interfaces/api/AvailablePractices';
import UnconfirmedPractice from './UnconfirmedPractice';
import * as SecureStore from 'expo-secure-store';

const SelectPractice: React.FC = () => {
  const { t } = useTranslation('selectPractice');
  const { colors } = useAppTheme();
  const { user } = useUser();
  const { logOut } = useAuth();

  const { setOptions } = useNavigation();

  useEffect(() => {
    setOptions({ headerTitle: '' });
  });

  const { data, isFetching } = useAvailablePractices(user.email);

  const { available, unconfirmed, unregistered } = useMemo(() => {
    // take in the data and return the available, unconfirmed, and unregistered practices
    const available: AvailablePractice[] = [];
    const unconfirmed: AvailablePractice[] = [];
    const unregistered: AvailablePractice[] = [];
    data?.forEach((practice) => {
      if (!practice.userRegistered) {
        unregistered.push(practice);
      } else if (practice.userConfirmationStatus === UserConfirmationStatus.PENDING) {
        unconfirmed.push(practice);
      } else {
        available.push(practice);
      }
    });
    return {
      available,
      unconfirmed,
      unregistered
    };
  }, [data]);

  const { mutate, isLoading: isSwitching } = useMutation({
    mutationKey: [MutationKeys.SWITCH_PRACTICE],
    mutationFn: async (user: SwitchPracticeRequest) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      return await switchPractice(user);
    },
    onSuccess: async (data) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      if (!IS_WEB) {
        await SecureStore.setItemAsync('id', data.id.toString());
      }
      await logOut(data);
    }
  });
  return (
    <AllyBlueGradient>
      <LogoHeader displayName={t('login:appName')} />
      <SafeAreaView style={{ flex: 1 }}>
        <ScreenTitle color={colors.onPrimary}>{t('title')}</ScreenTitle>
        {(isFetching || isSwitching) && (
          <LoadingIndicator size={'large'} color={colors.onPrimary} />
        )}

        {!isFetching && !isSwitching && (
          <InnerContainer>
            <Section>
              <Headline color={colors.onPrimary}>{t('availablePractices')}</Headline>
              {available?.map((practice) => (
                <PracticeRow
                  disabled={practice.practiceId === user.practiceId}
                  key={practice.practiceId}
                  onPress={() => mutate({ userId: practice.userId })}
                >
                  {practice.practiceName}
                  {practice.practiceId === user.practiceId && t('active')}
                </PracticeRow>
              ))}
              {!!unconfirmed?.length && (
                <StyledBody color={colors.onPrimary}>
                  {t('selectPractice:associatedButUnconfirmed')}
                </StyledBody>
              )}
              {unconfirmed?.map((practice) => (
                <UnconfirmedPractice key={practice.practiceId} practice={practice} />
              ))}
              {!!unregistered?.length && (
                <StyledBody color={colors.onPrimary}>
                  {t('selectPractice:associatedButUnregistered')}
                </StyledBody>
              )}
              {unregistered?.map((practice) => (
                <UnselectableRow key={practice.practiceId}>{practice.practiceName}</UnselectableRow>
              ))}
            </Section>
          </InnerContainer>
        )}
        <Section
          style={{
            maxWidth: MOBILE_WIDTH_MAX,
            alignSelf: 'center',
            marginBottom: IS_WEB ? Size.M : 0
          }}
        >
          <Caption color={colors.onPrimary}>{t('practiceNotListed', { ns: 'login' })}</Caption>
        </Section>
      </SafeAreaView>
    </AllyBlueGradient>
  );
};

export default SelectPractice;
