import { Row } from 'src/components';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

export const Container = styled.SafeAreaView`
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: ${Size.S}px;
`;

export const ContentContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: ${Size.S}px;
`;

export const StyledButtonRow = styled(Row)`
  align-self: stretch;
  padding: ${Size.S}px;
`;
