import { useEffect } from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { PaginationRS } from 'src/utils';
import useBoolean from './useBoolean';

const useFetchNextPage = (
  hasNextPage: boolean | undefined,
  isFetched: boolean,
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<PaginationRS<object>, unknown>>
) => {
  const { value: toggle, setValue: setToggle } = useBoolean();

  useEffect(() => {
    if (hasNextPage && isFetched) {
      void fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetched, toggle]);

  return { setToggle };
};

export default useFetchNextPage;
