export enum PATIENT_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DECEASED = 'deceased'
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female'
}
