import { useHeaderHeight } from '@react-navigation/elements';
import { StackActions, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AxiosError } from 'axios';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import { AlertTriangle, CheckCircle, X } from 'react-native-feather';
import { ActivityIndicator } from 'react-native-paper';
import { useMutation } from 'react-query';
import {
  ConfirmationError,
  ConfirmationSuccess,
  ConfirmationVariables,
  confirmEmail,
  MutationKeys,
  QueryKeys
} from 'src/api';
import { PrimaryGradientBackground } from 'src/components';
import { IconSize, Margin } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useAuth } from 'src/providers/AuthProvider';
import { Navigators } from 'src/routes/stacks/navigators';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { paramToBoolean, queryClient } from 'src/utils';
import sentry from 'src/utils/sentry';
import { LoadingContainer, SectionTitle } from '../Landing/components/style';
import { Container, StyledBody } from './styled';

type Props = NativeStackScreenProps<RootStackParamList, Screens.EMAIL_CONFIRMATION>;

const DISPLAY_MESSAGE_DURATION = moment.duration(5, 'seconds').asMilliseconds();

const EmailConfirmation: React.FC<Props> = ({ route }) => {
  const token = useRef(route.params?.token);
  const email = useRef(route.params?.email);
  const multipleAccounts = useRef(paramToBoolean(route.params?.multipleAccounts));

  const { t } = useTranslation('emailConfirmation');
  const { setOptions, canGoBack, goBack, dispatch } = useNavigation();
  const { colors } = useAppTheme();
  const { _user } = useAuth();

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    sentry.addBreadcrumb({
      type: 'debug',
      message: 'Email Confirmation Data',
      data: {
        email: email.current,
        token: token.current,
        multipleAccounts: multipleAccounts.current
      }
    });
  }, []);

  const { mutate, isLoading, error, isSuccess } = useMutation<
    ConfirmationSuccess,
    AxiosError<ConfirmationError>,
    ConfirmationVariables,
    MutationKeys.CONFIRM_EMAIL
  >({
    mutationKey: MutationKeys.CONFIRM_EMAIL,
    mutationFn: async (vars) => {
      sentry.addBreadcrumb({
        type: 'debug',
        message: 'Confirmation Request Sent'
      });
      return confirmEmail(vars);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.CHECK_EMAIL]);
    },
    onSettled: (data, error) => {
      timeout.current = setTimeout(exit, DISPLAY_MESSAGE_DURATION);
      sentry.addBreadcrumb({
        type: 'debug',
        message: 'Confirmation Request Settled',
        data: { data, error }
      });
    }
  });

  const exit = useCallback(() => {
    if (canGoBack()) {
      goBack();
    } else {
      const action = StackActions.replace(_user ? Navigators.MAIN_STACK : Screens.LANDING);
      dispatch(action);
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [_user, canGoBack, goBack, dispatch]);

  useEffect(() => {
    if (!token.current) {
      const timeout = setTimeout(exit, DISPLAY_MESSAGE_DURATION);
      return () => clearTimeout(timeout);
    } else {
      mutate({
        token: token.current,
        email: email.current,
        multipleAccounts: multipleAccounts.current
      });
    }
  }, [mutate, exit]);

  useEffect(() => {
    setOptions({
      headerTitle: t('title'),
      headerLeft: () => (
        <Pressable onPress={exit} style={{ paddingLeft: Margin.Medium }}>
          <X color={colors.onPrimary} />
        </Pressable>
      )
    });
  }, [colors.onPrimary, exit, setOptions, t]);

  const headerHeight = useHeaderHeight();

  if (isLoading || headerHeight === 0) {
    return (
      <LoadingContainer>
        <Container bottom={headerHeight}>
          <ActivityIndicator color={colors.onPrimary} size={'large'} style={{ flex: 1 }} />
        </Container>
        <PrimaryGradientBackground />
      </LoadingContainer>
    );
  }

  if (isSuccess) {
    return (
      <LoadingContainer>
        <Container bottom={headerHeight}>
          <SectionTitle color={colors.onPrimary}>{t('successHeading')}</SectionTitle>
          <CheckCircle height={IconSize.L} width={IconSize.L} color={colors.onPrimary} />
          <StyledBody>{t('success')}</StyledBody>
          <StyledBody>{t('redirecting')}</StyledBody>
        </Container>
        <PrimaryGradientBackground />
      </LoadingContainer>
    );
  }

  const { confirmation_token: confirmationToken, email: emailError } =
    error?.response?.data?.errors || {};

  return (
    <LoadingContainer>
      <Container bottom={headerHeight}>
        <SectionTitle color={colors.onPrimary}>{t('errorHeading')}</SectionTitle>
        <AlertTriangle height={IconSize.L} width={IconSize.L} color={colors.onPrimary} />
        {!!confirmationToken && <StyledBody>{t('invalidToken')}</StyledBody>}
        {!!emailError && <StyledBody>{t('emailAlreadyConfirmed')}</StyledBody>}
        {!confirmationToken && !emailError && <StyledBody>{t('unknownError')}</StyledBody>}
        <StyledBody>{t('redirecting')}</StyledBody>
      </Container>
      <PrimaryGradientBackground />
    </LoadingContainer>
  );
};
export default EmailConfirmation;
