import { FieldMetaProps, FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LayoutAnimation, Pressable, TextInput as TI } from 'react-native';
import { ActivityIndicator, Checkbox } from 'react-native-paper';
import {
  SecureTextInput,
  Alert,
  TextInput,
  AlertHandle,
  Body,
  HoverButton,
  Row,
  DropDown,
  HelperButton,
  Caption
} from 'src/components';
import { IconSize, Size } from 'src/constants';
import { AvailablePractice, UserConfirmationStatus } from 'src/interfaces/api/AvailablePractices';
import { palette, Weight } from 'src/theme';
import styled, { css } from 'styled-components/native';
import { LoginForm } from '../helpers/validation';
import { SectionTitle } from './style';
import AuthErrorMessage from './ErrorMessages';
import useForgotPassword from '../helpers/useForgotPassword';
import useLoginComponentControl from '../helpers/useLoginComponentControl';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from '../tour';
import { useNavigation } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import * as LocalAuthentication from 'expo-local-authentication';
import { isDevice } from 'expo-device';
import ErrorMessage from './ErrorMessage';
import { AlertTriangle } from 'react-native-feather';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { layout } from 'src/theme/globalStyles';
import FaceRecognition from 'src/components/Icons/FaceRecognition';
import { secureStoreGetSignInCredentials } from 'src/providers/AuthProvider/helper';
import { AsyncStorageKeys } from 'src/api';
import { useAuth } from 'src/providers/AuthProvider';
import { useStorage } from 'src/hooks';
import useBiometrics from 'src/hooks/useBiometrics';
import { isDefined } from 'src/utils';

interface Props {
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<LoginForm>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<LoginForm>>;
  values: LoginForm;
  errors: FormikErrors<LoginForm>;
  isFetched: boolean;
  touched: FormikTouched<LoginForm>;
  focusForm: () => void;
  setValues: (
    values: React.SetStateAction<LoginForm>
  ) => Promise<void> | Promise<FormikErrors<LoginForm>>;
  getFieldMeta: (name: string) => FieldMetaProps<LoginForm>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  debouncedEmail: string;
  practicesFetched: boolean;
}

const LoginFormComponent: React.FC<Props> = ({
  setFieldTouched,
  setFieldValue,
  values,
  errors,
  isFetched,
  touched,
  focusForm,
  setValues,
  handleSubmit,
  debouncedEmail,
  practicesFetched
}) => {
  const { t } = useTranslation('login');
  const { navigate } = useNavigation();
  const { viewMode, colors, dark } = useAppTheme();
  const {
    title,
    primarySelectedPractice,
    showEmailActivity,
    showPassword,
    showConfirmPassword,
    showPractices,
    showForgotEmail,
    showForgotPassword,
    showRememberMe,
    showLoginButton
  } = useLoginComponentControl({ values, errors, isFetched, debouncedEmail });
  const { requestPasswordReset } = useForgotPassword();
  const forgotPasswordRef = useRef<AlertHandle>(null);

  const { logIn } = useAuth();
  const [, setBiometricAuthAppSetting] = useStorage<boolean>([
    AsyncStorageKeys.BIOMETRIC_AUTH_APP_SETTING
  ]);

  const { canUseBiometrics, supportsFaceRecognition } = useBiometrics();

  const [biometricAuthAllowed, setBiometricAuthAllowed] = useState(false);

  useEffect(() => {
    void secureStoreGetSignInCredentials().then((credentials) => {
      if (isDefined(canUseBiometrics)) setBiometricAuthAllowed(canUseBiometrics && !!credentials);
    });
  }, [canUseBiometrics]);

  const loginWithBiometrics = async () => {
    const result = await LocalAuthentication.authenticateAsync();
    if (result.success) {
      setBiometricAuthAppSetting(true);
      const credentials = await secureStoreGetSignInCredentials();
      if (credentials) {
        await logIn(credentials);
      }
    }
  };

  const emailRef = useRef<TI>(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const emailMessage =
    (debouncedEmail.length > 4 && errors.email && practicesFetched) ||
    errors.availablePractices ||
    (!showPractices && errors.selectedPractices);

  return (
    <Container>
      <SectionTitle color={colors.onPrimary}>{t(title)}</SectionTitle>
      <GuideElement
        id={STEPS.EMAIL}
        onPress={emailRef.current?.focus}
        body={t('tour.email')}
        autoStart
      >
        <StyledTextInput
          nextRef={passwordRef}
          onChangeText={async (text) => setFieldValue('email', text)}
          value={values.email}
          onFocus={() => {
            if (viewMode.isMobile) {
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              focusForm();
            }
          }}
          onBlur={async () => await setFieldTouched('email', true)}
          label={t('email', { ns: 'common' })}
          keyboardType={'email-address'}
          autoCapitalize='none'
          error={!!emailMessage}
          right={
            showEmailActivity && {
              name: () => <ActivityIndicator />
            }
          }
          autoComplete={'username'}
          msg={
            typeof emailMessage === 'string' && (
              <ErrorMessage
                error={emailMessage}
                selectedPractice={primarySelectedPractice}
                email={values.email}
              />
            )
          }
        />
      </GuideElement>
      {showPractices && (
        <GuideElement
          body={t('tour.multiplePracticesDropdown')}
          id={STEPS.MULTIPLE_PRACTICES_DROPDOWN}
        >
          <DropDown<AvailablePractice>
            style={{
              backgroundColor: dark ? palette.BLACK_OPACITY_70 : palette.WHITE_OPACITY_70
            }}
            label={t('veterinaryPractice')}
            onChange={async (selectedPractice) => {
              await setFieldTouched('password', false);
              await setFieldTouched('confirmPassword', false);
              await setValues((v) => ({
                ...v,
                selectedPractices: [selectedPractice],
                password: '',
                confirmPassword: ''
              }));
            }}
            value={primarySelectedPractice}
            options={
              values.availablePractices?.map((practice: AvailablePractice) => ({
                label: practice.practiceName,
                value: practice,
                trailingIcon: !practice.userRegistered
                  ? () => <Caption>{t('newAccount')}</Caption>
                  : practice.userConfirmationStatus === UserConfirmationStatus.PENDING
                  ? AlertTriangle
                  : undefined
              })) ?? []
            }
            error={touched.selectedPractices && !!errors.selectedPractices}
            msg={
              typeof errors.selectedPractices === 'string' && (
                <ErrorMessage
                  error={errors.selectedPractices}
                  selectedPractice={primarySelectedPractice}
                  email={values.email}
                />
              )
            }
          />
        </GuideElement>
      )}
      {showPassword && (
        <StyledSecureInput
          thisRef={passwordRef}
          nextRef={showConfirmPassword ? confirmPasswordRef : undefined}
          label={t(showConfirmPassword ? 'newPassword' : 'password')}
          value={values.password}
          onChangeText={async (v) => await setFieldValue('password', v)}
          autoComplete={!isDevice ? 'off' : showConfirmPassword ? 'password-new' : 'password'}
          onBlur={async () => await setFieldTouched('password')}
          error={touched.password && !!errors.password}
          autoCapitalize='none'
          onSubmitEditing={showConfirmPassword ? undefined : () => handleSubmit()}
          msg={touched.password && errors.password && t(errors.password)}
        />
      )}
      {showConfirmPassword && (
        <StyledSecureInput
          thisRef={confirmPasswordRef}
          label={t('confirmPassword')}
          value={values.confirmPassword}
          onChangeText={async (v) => await setFieldValue('confirmPassword', v)}
          autoComplete={!isDevice ? 'off' : 'password-new'}
          onBlur={async () => await setFieldTouched('confirmPassword')}
          error={touched.confirmPassword && !!errors.confirmPassword}
          autoCapitalize='none'
          onSubmitEditing={() => handleSubmit()}
          msg={touched.confirmPassword && errors.confirmPassword && t(errors.confirmPassword)}
        />
      )}
      {showForgotEmail && (
        <HelperButton onPress={() => navigate(Screens.PRACTICE_CLIENT_SEARCH)}>
          {t('forgotEmail')}
        </HelperButton>
      )}
      {showForgotPassword && (
        <HelperButton onPress={forgotPasswordRef.current?.alert}>
          {t('forgotPassword')}
        </HelperButton>
      )}

      {showLoginButton && (
        <HoverButton
          mode={'contained'}
          onPress={handleSubmit}
          labelStyle={{ color: colors.onPrimary }}
          disabled={!_.isEmpty(_.omit(errors, 'email'))}
          style={{ marginTop: Size.S }}
        >
          {primarySelectedPractice?.userRegistered ? t('login') : t('signUp')}
        </HoverButton>
      )}
      <Row>
        {showRememberMe && (
          <Row justify='flex-start' style={layout.flex1}>
            <Checkbox.Android
              color={colors.onPrimary}
              status={values.rememberMe ? 'checked' : 'unchecked'}
              onPress={async () => setFieldValue('rememberMe', !values.rememberMe)}
            />
            <Body color={colors.onPrimary}>{t('rememberMe')}</Body>
          </Row>
        )}
        {biometricAuthAllowed && (
          <Row style={layout.flex1}>
            <Pressable onPress={loginWithBiometrics}>
              <Row style={{ gap: Size.X2_S }}>
                {supportsFaceRecognition ? (
                  <FaceRecognition size={IconSize.XS} color={colors.onPrimary} />
                ) : (
                  <Icon name='fingerprint' size={IconSize.XS} color={colors.onPrimary} />
                )}
                <Body color={colors.onPrimary}>{t('biometricLogin')}</Body>
              </Row>
            </Pressable>
          </Row>
        )}
      </Row>
      <AuthErrorMessage isFetched={isFetched} />
      <Alert
        ref={forgotPasswordRef}
        title={t('forgotPassword')}
        dismissOnBackdropPress
        options={[
          {
            label: t('cancel'),
            type: 'neutral'
          },
          {
            label: t('sendIt'),
            action: async () => {
              if (values.email && primarySelectedPractice) {
                requestPasswordReset(values.email, primarySelectedPractice.practiceId);
              }
            }
          }
        ]}
        body={
          <Trans
            i18nKey={t('sendEmail')}
            values={{ email: values.email }}
            components={{ strong: <Body fontWeight={Weight.BOLD} /> }}
          />
        }
      />
    </Container>
  );
};
export default LoginFormComponent;

const inputStyle = css`
  background-color: ${({ theme: { dark } }) =>
    dark ? palette.BLACK_OPACITY_70 : palette.WHITE_OPACITY_70};
`;
const StyledTextInput = styled(TextInput)`
  ${inputStyle}
`;

const StyledSecureInput = styled(SecureTextInput)`
  ${inputStyle}
`;

const Container = styled.View`
  gap: ${Size.S}px;
  flex-grow: 1;
`;
