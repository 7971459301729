import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';

export enum STEPS {
  BASIC,
  DUE_DATE,
  NOTIFY,
  ALERT
}

const AddToDoTourMap: TourMap<STEPS> = {
  [STEPS.BASIC]: { id: STEPS.BASIC, priority: 0 },
  [STEPS.DUE_DATE]: { id: STEPS.DUE_DATE, priority: 1 },
  [STEPS.NOTIFY]: { id: STEPS.NOTIFY, priority: 2 },
  [STEPS.ALERT]: { id: STEPS.ALERT, priority: 3 }
};

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.ADD_TO_DO,
  AddToDoTourMap
);
