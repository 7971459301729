import { t } from 'i18next';
import moment from 'moment-timezone';
import React from 'react';
import { ActivityIndicator, Checkbox } from 'react-native-paper';
import { Todo } from 'src/interfaces';
import { toLocalDateTime } from 'src/utils';
import ItemDetail from './ItemDetail';
import { useBoolean } from 'src/hooks';
import styled from 'styled-components/native';
import { Margin } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { ReasonMap } from 'src/scenes/ToDos/ToDoList/components/model';

interface Props {
  todo: Todo;
  navToEditToDo: () => void;
  onComplete: () => void;
}
const TodoCardItem: React.FC<Props> = ({ todo, navToEditToDo, onComplete }) => {
  const { colors } = useAppTheme();
  const { value: willComplete, toTrue: complete } = useBoolean(todo.isComplete);

  if (todo.isComplete) return null;
  return (
    <ItemDetail
      disabled={willComplete}
      onPress={navToEditToDo}
      patientId={todo.patientId}
      tag={
        todo.otherReason?.length
          ? todo.otherReason
          : t(ReasonMap[todo.reason].label, { ns: 'todos' })
      }
      description={todo.notes ? todo.notes : undefined}
      date={todo.dueDate ? toLocalDateTime(moment(todo.dueDate).utc()) : undefined}
      isLate={moment.utc(todo.dueDate).isBefore()}
      key={todo.id}
      action={
        willComplete ? (
          <OffsetActivityIndicator />
        ) : (
          <Checkbox.Android
            color={colors.primary}
            status={todo.isComplete ? 'checked' : 'unchecked'}
            onPress={() => {
              complete();
              onComplete();
            }}
          />
        )
      }
    />
  );
};

export default TodoCardItem;

const OffsetActivityIndicator = styled(ActivityIndicator).attrs({ size: 'small' })`
  margin-right: ${Margin.Small};
`;
