import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { getLifelearnArticles } from 'src/api/lifelearn';
import ArticleCard from './ArticleCard';
import styled from 'styled-components/native';
import { ActivityIndicator, TextInput } from 'react-native-paper';
import { Size } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { layout } from 'src/theme/globalStyles';
import { FlatList, LayoutAnimation } from 'react-native';
import useSearchResults from './useSearchResults';
import _ from 'lodash';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useViewMode } from 'src/hooks';

const ArticleHub: React.FC = () => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('articleHub');
  const { isMobile } = useViewMode();
  const [searchText, setSearchText] = useState<string>('');

  const setSearchValue = useMemo(
    () =>
      _.debounce((searchText: string) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setSearchText(searchText);
      }),
    [setSearchText]
  );

  const { data, isFetching, isRefetching } = useQuery([QueryKeys.LIFELEARN_ARTICLES], {
    queryFn: getLifelearnArticles
  });

  const articles = useSearchResults(searchText, data?.articles);

  if (!articles) return null;

  const numCols = (articles?.length ?? 0) > 1 && !isMobile ? 2 : 1;
  if (isFetching) return <ActivityIndicator size='large' style={layout.flex1} />;

  return (
    <Container>
      <SearchInput
        label={t('searchLabel')}
        autoCapitalize='none'
        onChangeText={async (text) => setSearchValue(text)}
        textColor={colors.onSurface}
      />
      <FlatList
        key={numCols}
        data={articles}
        refreshing={isRefetching}
        numColumns={numCols}
        renderItem={({ item }) => <ArticleCard key={item.id} article={item} numCols={numCols} />}
      />
    </Container>
  );
};

export default ArticleHub;

const Container = styled.ScrollView.attrs(() => ({
  contentContainerStyle: { alignSelf: 'center' }
}))`
  padding-vertical: ${Size.S}px;
`;

const SearchInput = styled(TextInput)`
  background-color: ${({ theme }) => theme.colors.surface};
  margin-bottom: ${Size.S}px;
  margin-horizontal: ${Size.X2_S}px;
`;
