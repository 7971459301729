import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { TABLET_WIDTH_PORTRAIT_MIN } from 'src/constants';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import AppointmentsList from './components/AppointmentsList';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import AppointmentStackParamsList from 'src/routes/stacks/AppointmentStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { SelectedPatientProvider } from 'src/providers/SelectedPatientProvider';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { SliderElement, SLIDES, STEPS, TourGuide } from '../tour';

type Props = NativeStackScreenProps<AppointmentStackParamsList, Screens.APPOINTMENTS>;

const AppointmentsRoot: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation();

  const { setOptions } = useNavigation();
  const { colors } = useAppTheme();
  useEffect(() => {
    setOptions({
      headerShown: false,
      headerStyle: {
        backgroundColor: colors.primary
      }
    });
  }, [colors.primary, setOptions, t]);

  const { top } = useSafeAreaInsets();

  return (
    <TourGuide.Provider>
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
      <SelectedPatientProvider selectedPatientId={route.params?.id}>
        <StatusbarOffset style={{ height: top }} />
        <AppointmentsList />
      </SelectedPatientProvider>
    </TourGuide.Provider>
  );
};

export default AppointmentsRoot;

const StatusbarOffset = styled.View`
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const AppointmentsContainer = styled.View`
  flex-grow: 1;
  align-self: ${({ theme }) => (theme.viewMode.isWeb ? 'center' : 'stretch')};
  width: ${({ theme }) => (theme.viewMode.isWeb ? `${TABLET_WIDTH_PORTRAIT_MIN}px` : '100%')};
`;
