import * as React from 'react';
import { useState } from 'react';
import TextInput, { TextInputProps } from '../kit/TextInput';

const SecureTextInput: React.FC<TextInputProps> = ({ disabled = false, ...props }) => {
  const [isSecureTextEntry, setIsSecureTextEntry] = useState(true);

  return (
    <TextInput
      disabled={disabled}
      secureTextEntry={isSecureTextEntry}
      right={{
        name: isSecureTextEntry ? 'eye' : 'eye-off',
        onPress: () => {
          setIsSecureTextEntry((prev) => !prev);
        }
      }}
      {...props}
    />
  );
};

export default SecureTextInput;
