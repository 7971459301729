import * as Linking from 'expo-linking';
import Toast from 'react-native-toast-message';
import { t } from 'i18next';

const openSMS = (phone: string) => {
  Linking.canOpenURL(`sms:${phone}`)
    .then((supported) => {
      if (!supported) {
        Toast.show({
          type: 'error',
          text1: t('phoneUnavailable', { ns: 'common' })
        });
      } else {
        return Linking.openURL(`sms:${phone}`);
      }
    })
    .catch((err) => console.error(err));
};

export default openSMS;
