import { useQuery, UseQueryOptions } from 'react-query';
import { getAppointment, QueryKeys } from 'src/api';
import { Appointment } from 'src/interfaces';
import { useUser } from 'src/providers/ClientProvider';

const useAppointment = (
  appointmentId: Appointment['appointmentId'],
  options?: UseQueryOptions<Appointment | undefined, unknown, Appointment>
) => {
  const { user } = useUser();
  return useQuery({
    queryFn: async () => {
      if (appointmentId && user?.sourceId) {
        return await getAppointment(user.sourceId, appointmentId);
      }
    },
    queryKey: [QueryKeys.APPOINTMENT, appointmentId, user?.sourceId],
    enabled: !!(appointmentId && user?.sourceId),
    suspense: true,
    ...options
  });
};

export default useAppointment;
