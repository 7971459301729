import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Patient } from 'src/interfaces';
import AnimalIcon from './Icons/Animal';
import { useIsFetching, useIsMutating } from 'react-query';
import { MutationKeys, QueryKeys } from 'src/api';
import { encodeKeyFromPatient } from 'src/utils/extractImageName';
import Avatar from './Avatar';

interface Props {
  patient?: Patient;
  size: number;
  style?: StyleProp<ViewStyle>;
  aspectRatio?: number;
}

const PatientAvatar: React.FC<Props> = ({ patient, ...props }) => {
  const isMutating = !!useIsMutating([MutationKeys.PATIENT_PHOTO_UPLOAD, patient?.patientId]);
  const isFetching = !!useIsFetching({
    queryKey: [QueryKeys.PATIENT, patient?.patientId],
    predicate: (query) => !query.state.isInvalidated
  });

  return (
    <Avatar
      uri={patient?.hasPhoto ? patient?.photo.urls.original : undefined}
      id={encodeKeyFromPatient(patient)}
      isFetching={isFetching}
      isMutating={isMutating}
      {...props}
      EmptyComponent={(props) => <AnimalIcon species={patient?.species} {...props} />}
    />
  );
};

export default PatientAvatar;
