import { useMemo } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { getAllTodos, QueryKeys } from 'src/api';
import { Patient, Todo } from 'src/interfaces';
import { PAGE_LENGTH } from 'src/constants';
import _ from 'lodash';
import { getNextPageParam, PaginationRS } from 'src/utils';

interface Options
  extends Omit<
    UseInfiniteQueryOptions<
      PaginationRS<Todo>,
      unknown,
      PaginationRS<Todo>,
      PaginationRS<Todo>,
      Array<string | number | boolean | undefined>
    >,
    'queryFn' | 'queryKey'
  > {
  length?: number;
  isComplete?: boolean;
  column?: string;
  dir?: 'asc' | 'desc';
  patientId?: Patient['patientId'];
  reason?: string;
}
const usePaginatedTodos = ({
  length = PAGE_LENGTH,
  isComplete,
  column,
  dir,
  patientId,
  reason,
  ...options
}: Options) => {
  const {
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    ...rest
  } = useInfiniteQuery({
    queryKey: [QueryKeys.PAGED_TODOS, length, isComplete, dir, column],
    queryFn: async ({ pageParam = 0 }) => {
      return getAllTodos(pageParam * length, length, isComplete, dir, column);
    },

    getNextPageParam: (...options) => getNextPageParam(...options, length),
    suspense: true,
    ...options
  });

  const todos = useMemo(() => {
    const _todos = data?.pages?.reduce<Todo[]>((prev, curr) => [...prev, ...curr.data], []) ?? [];
    return filterTodos(_todos, patientId, reason);
  }, [data?.pages, patientId, reason]);

  return {
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    data: todos,
    fetchNextPage,
    refetch,
    ...rest
  };
};

export default usePaginatedTodos;

export const filterTodos = (
  data: Todo[],
  _patientId: Patient['patientId'] | undefined,
  _reason: string | undefined
) =>
  _(data)
    .filter(
      ({ patientId, reason }) =>
        (!_patientId || patientId === _patientId) && (!_reason || reason === _reason)
    )
    .uniqBy('id')
    .value();
