import { endpoint, PATIENT_STATUSES } from 'src/constants';
import { Prescription } from 'src/interfaces';
import { isDefinedOrThrowServerError, PaginationRS } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export interface PaginatedPrescriptionsParams {
  length?: number;
  start?: number;
  order?: 'asc' | 'desc';
  orderBy?: keyof Pick<Prescription, 'description' | 'prescribedAt' | 'expirationDate'>;
  status?: PATIENT_STATUSES;
}

export const getPaginatedPrescriptions = async ({
  status,
  order,
  orderBy,
  ...params
}: PaginatedPrescriptionsParams): Promise<PaginationRS<Prescription>> => {
  const response = await apiClient.get<PaginationRS<Prescription>>(endpoint('PRESCRIPTIONS'), {
    params: {
      ...params,
      status_filter: status ?? Object.values(PATIENT_STATUSES),
      order: {
        column: orderBy,
        dir: order
      },
      server_managed: true
    }
  });
  return isDefinedOrThrowServerError(response.data);
};
