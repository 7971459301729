import { Row, Body, Shimmer, HoverCard } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import { Size, IconSize, Margin } from 'src/constants';
import { fontStyles } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

export const HeroContainer = styled.View`
  align-items: center;
  position: relative;
`;

export const BackgroundStrip = styled.View`
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  height: ${IconSize.XL}px;
  width: 100%;
`;

export const Content = styled.View`
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  padding-horizontal: ${Margin.Large}px;
  gap: ${Size.X2_S}px;
`;

export const IconContainer = styled.View`
  width: ${IconSize.XS}px
  height: ${IconSize.XS}px
  align-items: center;
  justify-content: center;
`;

export const StyledRow = styled(Row)`
  align-items: flex-start;
  align-self: flex-start;
  padding-vertical: ${Size.X2_S}px;
  margin-bottom: ${Size.X2_S}px;
  gap: ${Size.S}px;
`;

export const StyledBody = styled(Body)`
  margin-top: ${Size.X3_S}px;
`;

export const StyledShimmerBody = styled(Shimmer)`
  margin-top: ${Size.X3_S}px;
  height: ${fontStyles.body.lineHeight}px;
  width: 50%;
`;

export const StyledShimmerHeading = styled(Shimmer)`
  height: ${fontStyles.subheading.lineHeight}px;
  width: 30%;
`;

export const StyledPatientAvatar = styled(PatientAvatar)`
  margin-bottom: ${Size.S}px;
`;

export const StyledConfirmationNote = styled(Row)`
  padding-top: ${Size.X2_S}px;
  padding-horizontal: ${Size.M}px;
`;

export const StyledPressable = styled(HoverCard).attrs({ hideShadow: true })`
  padding: ${Margin.Small}px
  background-color: ${({ theme }) => theme.colors.background};
  cursor: ${({ onPress }) => (onPress ? 'pointer' : 'default')};
`;
