import React from 'react';
import { Caption } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  text: string;
}

const CHelperText: React.FC<Props> = ({ text }) => {
  const { colors } = useAppTheme();
  return <Caption color={colors.error}>{text}</Caption>;
};

export default CHelperText;
