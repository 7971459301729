import { useNavigation, NavigationProp, ParamListBase } from '@react-navigation/native';
import React from 'react';
import { ChevronDown } from 'react-native-feather';
import { useUser } from 'src/providers/ClientProvider';
import { Screens } from 'src/routes/stacks/screens';
import { HoverButton, HoverCard, Row } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Size } from 'src/constants';
import styled from 'styled-components/native';
import { useAvailablePractices, usePractice } from 'src/hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Navigators } from 'src/routes/stacks/navigators';
import { fontStyles } from 'src/theme/globalStyles';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from '../tour';
import ClientAvatar from 'src/components/ClientAvatar';

const AVATAR_SIZE = 44;

interface Props {
  isFetched: boolean;
}

const HomeHeader: React.FC<Props> = ({ isFetched }) => {
  const { navigate } = useNavigation<NavigationProp<ParamListBase>>();
  const { user } = useUser();
  const { data: practice } = usePractice();
  const { t } = useTranslation('home');
  const { colors, viewMode } = useAppTheme();
  const { top } = useSafeAreaInsets();
  const { data: availablePractices = [] } = useAvailablePractices(user.email, {
    select: (d) => d.filter((v) => v.userRegistered)
  });

  const hasMultiplePractices = availablePractices.length > 1;

  return (
    <Row
      style={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: top,
        paddingLeft: viewMode.horizontalInset,
        paddingRight: viewMode.horizontalInset,
        backgroundColor: colors.primary
      }}
    >
      <Container onPress={() => navigate(Screens.PROFILE_ROOT)}>
        <StyledUserAvatar />
      </Container>
      <GuideElement
        id={STEPS.SWITCH_PRACTICE}
        body={t('tour.switchPractice')}
        disabled={!hasMultiplePractices}
        autoStart={isFetched}
      >
        <StyledHoverButton
          labelStyle={fontStyles.subheading}
          onPress={
            !hasMultiplePractices
              ? undefined
              : () => navigate(Navigators.MAIN_STACK, { screen: Screens.SELECT_PRACTICE })
          }
          icon={hasMultiplePractices && <ChevronDown color={colors.onPrimary} />}
        >
          {practice?.name}
        </StyledHoverButton>
      </GuideElement>
    </Row>
  );
};

export default HomeHeader;

const Container = styled(HoverCard)`
  margin: ${Size.X2_S}px;
  border-radius: ${AVATAR_SIZE / 2}px;
`;

const StyledHoverButton = styled(HoverButton).attrs(({ theme: { colors } }) => ({
  uppercase: false,
  buttonColor: colors.onPrimary
}))`
  flex: 1;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const StyledUserAvatar = styled(ClientAvatar).attrs(({ theme: { colors } }) => ({
  size: AVATAR_SIZE,
  iconSize: Size.M,
  iconColor: colors.onPrimary
}))`
  border-radius: ${AVATAR_SIZE / 2}px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.onPrimary};
  border-width: ${Size.X4_S}px;
`;
