import React from 'react';
import { Body, Caption, Subheading } from 'src/components';
import { useViewMode } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { CoinIcon } from 'src/routes/stacks/LoyaltyTabNavigator/components/LoyaltyTabBar/style';
import { Weight } from 'src/theme';
import { CareCoinView } from '../LoyaltyRewards/style';
import { Trash } from 'react-native-feather';
import { useTranslation } from 'react-i18next';
import { toLocalShortDate } from 'src/utils';
import moment from 'moment';
import { StyledHoverButton, StyledHoverCard, StyledRow, TextContainer } from './style';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import LoyaltyTabParamsList from 'src/routes/stacks/LoyaltyTabNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { ActivityLineItem } from 'src/providers/LoyaltyTransactionProvider/types';

interface Props {
  activityLineItem: ActivityLineItem;
  onPressDelete: (transactionId: number) => void;
}

const ActivityItem: React.FC<Props> = ({ activityLineItem, onPressDelete }) => {
  const { t } = useTranslation('loyalty');
  const { isMobile } = useViewMode();
  const { colors } = useAppTheme();

  const navigation = useNavigation<NavigationProp<LoyaltyTabParamsList>>();
  const onPress = () => {
    navigation.getParent()?.navigate(Screens.TRANSACTION_DETAILS, {
      id: activityLineItem.transactionId,
      isExpiredItem: activityLineItem.description === t('expired'),
      expireAmount: activityLineItem.amount,
      expireDate: activityLineItem.date
    });
  };

  return (
    <StyledHoverCard onPress={onPress}>
      <StyledRow>
        <TextContainer>
          <Body numberOfLines={2}>{activityLineItem.description}</Body>
          <Caption>
            {activityLineItem.isPending
              ? t('pendingRedemption')
              : toLocalShortDate(moment(activityLineItem.date))}
          </Caption>
        </TextContainer>
        <CareCoinView>
          {isMobile ? (
            <Body color={colors.onSurface}>{activityLineItem.amount}</Body>
          ) : (
            <Subheading fontWeight={Weight.REGULAR}>{activityLineItem.amount}</Subheading>
          )}
          <CoinIcon />
        </CareCoinView>

        {activityLineItem.isPending && (
          <StyledHoverButton
            compact
            mode='contained'
            buttonColor={colors.error}
            icon={Trash}
            onPress={() => onPressDelete(activityLineItem.transactionId)}
          />
        )}
      </StyledRow>
    </StyledHoverCard>
  );
};

export default ActivityItem;
