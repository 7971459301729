export enum FormState {
  VIEW_PROFILE,
  EDIT_INFO,
  CONFIRM_CHANGES
}

export interface ReducerState<T extends FormState = FormState> {
  state: T;
  buttonText?: string;
}

export enum ACTIONS {
  ADVANCE,
  GO_BACK
}

export interface Actions {
  action: ACTIONS;
}

export const STATES: { [state in FormState]: Omit<ReducerState<state>, 'data'> } = {
  [FormState.VIEW_PROFILE]: {
    state: FormState.VIEW_PROFILE
  },
  [FormState.EDIT_INFO]: {
    state: FormState.EDIT_INFO,
    buttonText: 'patientProfile:saveChanges'
  },
  [FormState.CONFIRM_CHANGES]: {
    state: FormState.CONFIRM_CHANGES,
    buttonText: 'patientProfile:looksGood'
  }
};
