import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getAbility, QueryKeys } from 'src/api';
import Ability from 'src/interfaces/api/Ability';
import { isDefined } from 'src/utils';

export const abilityOptions = {
  queryKey: [QueryKeys.ABILITY],
  queryFn: getAbility
};
const useAbility = (
  options: Omit<
    UseQueryOptions<Ability, unknown, Ability, QueryKeys[]>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const query = useQuery({
    ...abilityOptions,
    ...options
  });

  const userCanViewLoyalty = useMemo(
    () =>
      !!(
        isDefined(query.data) &&
        isDefined(query.data.rules?.loyaltyTransaction) &&
        query.data.rules.loyaltyTransaction.find(
          (lt) => lt.can && lt.actions.find((a) => a === 'read')
        )
      ),
    [query.data]
  );

  return {
    ...query,
    userCanViewLoyalty
  };
};

export default useAbility;
