import { capitalize } from 'lodash';
import { AppointmentRequestContent, PreferredContactMethod } from 'src/interfaces';
import { toLocalDate } from 'src/utils';
import { DetailItem } from './model';
import moment from 'moment';

const generateAppointmentData = (content: AppointmentRequestContent): DetailItem[] => [
  {
    title: 'requestFields.appointmentType',
    value: content.appointmentType ?? '',
    icon: 'list-alt'
  },
  {
    title: 'requestFields.preferredDate',
    value: content.preferredDate ? toLocalDate(moment(content.preferredDate)) : '',
    icon: 'calendar-check'
  },
  {
    title: 'requestFields.preferredTime',
    value: content.preferredTime ?? '',
    icon: 'clock'
  },
  {
    title: 'requestFields.withWhom',
    value: content.column ?? '',
    icon: 'user'
  },
  {
    title: 'requestFields.preferredContactMethod',
    value: capitalize(content.preferredContactMethod ?? ''),
    icon: contactMethodIcon(content.preferredContactMethod)
  }
];

export default generateAppointmentData;

const contactMethodIcon = (method?: PreferredContactMethod) => {
  switch (method) {
    case PreferredContactMethod.Email:
      return 'envelope';
    case PreferredContactMethod.Phone:
      return 'phone';
    case PreferredContactMethod.Text:
      return 'sms';
    default:
      return 'question';
  }
};
