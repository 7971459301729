import MaskedView from '@react-native-masked-view/masked-view';
import React, { PropsWithChildren } from 'react';
import { IS_WEB, Size } from 'src/constants';
import styled from 'styled-components/native';

interface Props extends PropsWithChildren {
  width: number;
  aspectRatio: number;
}

const AvatarMaskedView: React.FC<Props> = ({ width, aspectRatio, children }) => {
  if (IS_WEB) return <>{children}</>;
  return (
    <MaskedView
      style={{ aspectRatio, width }}
      maskElement={
        <>
          <Rectangle width={width} aspectRatio={aspectRatio} />
          <Cutout width={width} style={{ transform: [{ translateX: Size.X2_L / 2 }] }} />
        </>
      }
    >
      {children}
    </MaskedView>
  );
};

export default AvatarMaskedView;

const Rectangle = styled.View<{ width: number; aspectRatio: number }>`
  position: absolute;
  height: ${({ width, aspectRatio }) => width / aspectRatio - Size.S}px;
  width: ${({ width }) => width}px;
  background-color: black;
`;

const Cutout = styled.View<{ width: number }>`
  height: ${Size.X2_L}px;
  width: ${Size.X2_L}px;
  border-radius: ${Size.X2_L}px;
  bottom: 0px;
  right: ${({ width }) => width / 2}px;
  position: absolute;
  background-color: black;
`;
