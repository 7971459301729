/**
 * Mutation keys for react-query useMutation.
 */
enum MutationKeys {
  AUTHENTICATION = 'AUTHENTICATION',
  CONFIRMING_APPOINTMENT = 'CONFIRMING_APPOINTMENT',
  DEAUTHENTICATION = 'DEAUTHENTICATION',
  EDITING_TODOS = 'EDITING_TODOS',
  SESSION_RENEW = 'SESSION_RENEW',
  SIGN_UP = 'SIGN_UP',
  PATIENT_PHOTO_UPLOAD = 'PATIENT_PHOTO_UPLOAD',
  SWITCH_PRACTICE = 'SWITCH_PRACTICE',
  LOGGING_OUT = 'LOGGING_OUT',
  CLIENT_SEARCH = 'CLIENT_SEARCH',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  TOKEN_PASSWORD_RESET = 'TOKEN_PASSWORD_RESET',
  PROFILE_PHOTO_UPLOAD = 'PROFILE_PHOTO_UPLOAD',
  PROFILE_PHOTO_DELETE = 'PROFILE_PHOTO_DELETE'
}

export default MutationKeys;
