import { deleteClientPhoto, MutationKeys, postClientPhoto, QueryKeys } from 'src/api';
import { Action, manipulateAsync } from 'expo-image-manipulator';
import pickImage from 'src/utils/pickImage';
import { useMutation, useQueryClient } from 'react-query';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import takeImage from 'src/utils/takeImage';
import { useUser } from 'src/providers/ClientProvider';
import { useCallback } from 'react';
import { useAuth } from 'src/providers/AuthProvider';

interface UploadPhotoHook {
  uploadPhoto: (camera: boolean) => Promise<void>;
  deletePhoto: () => void;
  isMutating: boolean;
}
interface Options {
  /**
   * Series of actions to manipulate the photo before uploading.
   */
  manipulate?: Action[];
}

const useUploadProfilePhoto: (options?: Options) => UploadPhotoHook = ({
  manipulate = []
} = {}): UploadPhotoHook => {
  const { t } = useTranslation('profileRoot');

  const { user } = useUser();
  const { token } = useAuth();

  const queryClient = useQueryClient();

  const { mutate: upload, isLoading: isUploading } = useMutation(
    async (uri: string) => await postClientPhoto(user.sourceId, user.clientId, uri),
    {
      mutationKey: [MutationKeys.PROFILE_PHOTO_UPLOAD, token, manipulate],
      onSuccess: () => {
        void queryClient.invalidateQueries([QueryKeys.CLIENT, token]);
        Toast.show({ text1: t('uploadSuccess') });
      },
      onError: (error: Error) => {
        Toast.show({
          type: 'error',
          text1: error.name,
          text2: error.message
        });
      }
    }
  );

  const { mutate: deletePhoto, isLoading: isDeleting } = useMutation(
    async () => await deleteClientPhoto(user.sourceId, user.clientId),
    {
      mutationKey: [MutationKeys.PROFILE_PHOTO_DELETE, token],
      onSuccess: () => {
        void queryClient.invalidateQueries([QueryKeys.CLIENT, token]);
        Toast.show({ text1: t('deletePhotoSuccess') });
      },
      onError: (error: Error) => {
        Toast.show({
          type: 'error',
          text1: error.name,
          text2: error.message
        });
      }
    }
  );

  const uploadPhoto = useCallback(
    async (camera: boolean): Promise<void> => {
      const imagePickerResult = camera ? await takeImage() : await pickImage();
      if (imagePickerResult && !imagePickerResult?.canceled) {
        const processed = await manipulateAsync(imagePickerResult.assets[0].uri, manipulate);
        upload(processed.uri);
      }
    },
    [manipulate, upload]
  );

  return { uploadPhoto, deletePhoto, isMutating: isUploading || isDeleting };
};

export default useUploadProfilePhoto;
