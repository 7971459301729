import React from 'react';
import { Award } from 'react-native-feather';
import { Margin } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

const AwardHero: React.FC = () => {
  const { colors } = useAppTheme();

  return (
    <PositionContainer>
      <Award width={300} height={300} stroke={colors.primary} strokeWidth={0.2} />
    </PositionContainer>
  );
};

export default AwardHero;

const PositionContainer = styled.View`
  padding: ${Margin.ExtraLarge}px;
  opacity: 0.5;
  left: 25%;
  align-items: center;
  transform: rotate(35deg);
`;
