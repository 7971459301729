import { useEffect, useState } from 'react';
import { AppState } from 'react-native';

/**
 * A hook that listens to the app state and returns the current state.
 */
const useAppState = () => {
  const [appState, setAppState] = useState(AppState.currentState);
  useEffect(() => {
    const listener = AppState.addEventListener('change', setAppState);
    return listener.remove;
  }, []);
  return appState;
};

export default useAppState;
