import { HoverCard } from 'src/components';
import { Margin } from 'src/constants';
import styled from 'styled-components/native';

export const Container = styled(HoverCard)`
  padding: ${Margin.Medium}px;
  flex-direction: row;
  gap: ${Margin.Medium}px;
`;

export const StatusContainer = styled.View`
  flex: 0 1 auto
  align-items: flex-end;
  justify-content: center;
`;

export const FabContainer = styled.View`
  position: absolute;
  right: ${({ theme }) => theme.viewMode.horizontalInset + Number(Margin.Large)}px;
  bottom: ${Margin.Large}px;
`;
