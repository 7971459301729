import { NavigatorScreenParams } from '@react-navigation/native';
import { t } from 'i18next';
import { Size } from 'src/constants';
import { Patient, RequestSetting } from 'src/interfaces/api';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import { FontSize } from 'src/theme';

interface ActionStyle {
  actionStyle: {
    backgroundColor: string;
    marginRight: number;
  };
  labelStyle: {
    color: string;
    fontSize: FontSize;
    alignSelf: 'center';
    paddingVertical: Size;
  };
  containerStyle: {
    backgroundColor: string;
    borderRadius: Size;
    width: number;
  };
}

export const requestActions = (
  requestSettings: RequestSetting | undefined,
  navigate: (
    ...args:
      | [screen: Navigators.MAIN_STACK]
      | [screen: Navigators.MAIN_STACK, params: NavigatorScreenParams<MainStackParamsList>]
  ) => void,
  requestAppointment: (patientId?: Patient['patientId']) => void,
  style: ActionStyle,
  color: string
) => {
  if (!requestSettings) return [];
  const baseAction = {
    style: style.actionStyle,
    labelStyle: style.labelStyle,
    containerStyle: style.containerStyle,
    color
  };
  const actions = [
    {
      ...baseAction,
      icon: 'human',
      label: t('requests:wizard.updateMyInfo'),
      onPress: () => navigate(Navigators.MAIN_STACK, { screen: Screens.PROFILE_INFORMATION_UPDATE })
    },
    {
      ...baseAction,
      icon: 'paw',
      label: t('requests:wizard.updatePetInfo'),
      onPress: () =>
        navigate(Navigators.MAIN_STACK, {
          screen: Navigators.PATIENT_UPDATE_STACK_NAVIGATOR
        })
    }
  ];

  if (!requestSettings?.appointmentRequestsDisabled) {
    actions.push({
      ...baseAction,
      icon: 'calendar',
      label: t('requests:wizard.newAppointment'),
      onPress: () => requestAppointment()
    });
  }

  if (!requestSettings?.refillRequestsDisabled) {
    actions.push({
      ...baseAction,
      icon: 'package',
      label: t('requests:wizard.productRequest'),
      onPress: () => navigate(Navigators.MAIN_STACK, { screen: Screens.REQUESTS_PRODUCT })
    });
  }

  return actions;
};
