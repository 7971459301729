import { NavigationProp, useNavigation } from '@react-navigation/native';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ChevronRight } from 'react-native-feather';
import { useIsMutating } from 'react-query';
import { MutationKeys } from 'src/api';
import { Caption, Row, Shimmer, Tooltip } from 'src/components';
import { CalendarConfirmed, CalendarPending } from 'src/components/Icons';
import { IconSize } from 'src/constants';
import { usePatient, usePractice } from 'src/hooks';
import { Appointment } from 'src/interfaces';
import AppointmentStackParamsList from 'src/routes/stacks/AppointmentStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { layout } from 'src/theme/globalStyles';
import { toLocalDateTime } from 'src/utils';
import {
  Avatar,
  CaptionContainer,
  Container,
  DataContainer,
  PaperCard,
  RightIconContainer
} from './shared';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  appointment: Appointment;
}
const AppointmentListItem: React.FC<Props> = ({ appointment }) => {
  const { navigate } = useNavigation<NavigationProp<AppointmentStackParamsList>>();
  const { colors } = useAppTheme();
  const { t, i18n } = useTranslation('appointments');
  const { data: patient, isLoading } = usePatient(appointment.patientId);
  const { data: practice } = usePractice();

  const startTime = moment(appointment.startsAt).locale(i18n.language);
  const appointmentTimeNotPassed = !moment(appointment.startsAt).isBefore();
  const isConfirming = !!useIsMutating([
    MutationKeys.CONFIRMING_APPOINTMENT,
    appointment.appointmentId
  ]);
  const shouldDisable = isConfirming || isLoading;

  return (
    <PaperCard
      onPress={() => {
        navigate(Screens.APPOINTMENT_DETAILS, {
          id: appointment.appointmentId
        });
      }}
    >
      <Container>
        <Avatar patient={patient} size={IconSize.L} />
        <DataContainer>
          <CaptionContainer>
            <Shimmer width={'50%'} isLoading={isLoading}>
              <Caption color={colors.onSurface}>{appointment.patientDisplay}</Caption>
            </Shimmer>
          </CaptionContainer>
          <CaptionContainer>
            <Shimmer isLoading={isLoading}>
              <Caption color={colors.onSurface}>
                {toLocalDateTime(startTime, practice?.timeZone)}
              </Caption>
            </Shimmer>
          </CaptionContainer>
        </DataContainer>
        {appointmentTimeNotPassed ? (
          <Row style={[layout.justifyEnd, { flex: 0 }]}>
            <RightIconContainer>
              <Shimmer isLoading={shouldDisable} size={IconSize.XS}>
                {appointment.confirmed ? (
                  <Tooltip tooltipText={t('confirmed')} disabled={shouldDisable}>
                    <CalendarConfirmed size={IconSize.XS} color={colors.confirmation} />
                  </Tooltip>
                ) : (
                  <Tooltip tooltipText={t('scheduled')} disabled={shouldDisable}>
                    <CalendarPending size={IconSize.XS} color={colors.warn} />
                  </Tooltip>
                )}
              </Shimmer>
            </RightIconContainer>
            <RightIconContainer style={{ alignItems: 'flex-end' }}>
              <Shimmer isLoading={isLoading} size={IconSize.XS}>
                <ChevronRight color={colors.onSurface} />
              </Shimmer>
            </RightIconContainer>
          </Row>
        ) : (
          <View style={layout.justifyCenter}>
            <RightIconContainer style={{ alignItems: 'flex-end' }}>
              <Shimmer isLoading={isLoading} size={IconSize.XS}>
                <ChevronRight color={colors.onSurface} />
              </Shimmer>
            </RightIconContainer>
          </View>
        )}
      </Container>
    </PaperCard>
  );
};

export default AppointmentListItem;
