import { Body, HoverCard, Row, Subheading, Title } from 'src/components';
import { Size, Margin } from 'src/constants';
import { palette } from 'src/theme';
import { transparent } from 'src/utils';
import styled from 'styled-components/native';

export const ExpiringCoinsHeader = styled(Body)`
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.error};
  border-radius: ${Size.X2_S}px;
  padding: ${Size.S}px;
`;

export const CareCoinView = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: ${Size.X4_S}px;
  padding-right: ${Size.X2_S}px;
`;

export const StyledRow = styled(Row)`
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_20 : palette.BLACK_OPACITY_10};
  padding: ${Margin.Medium}px;

  justify-content: space-between;
`;

export const Container = styled.View`
  padding: ${Margin.ExtraLarge}px;
`;

export const TitleText = styled(Title)`
  margin-vertical: ${Margin.Large}px;
`;

export const RewardOfferCardContainer = styled(HoverCard)`
  height: ${Size.X4_L}px;
  margin-left: ${Margin.Large}px;
  margin-vertical: ${Margin.Medium}px;
  flex-grow: 1;
`;

export const RewardOfferInnerContainer = styled.View`
  flex-direction: row;
  flex-grow: 1;
`;

export const RewardContentView = styled.View`
  flex: 1;
  justify-content: center;
`;

export const RewardTitleView = styled.View`
  padding-left: ${Margin.Medium}px;
  padding-vertical: ${Margin.Small}px;
`;

export const NullImageView = styled.View`
  width: ${Size.X4_L}px;
  height: ${Size.X4_L}px;
  border-bottom-left-radius: ${({ theme }) => `${theme.roundness}px`};
  border-top-left-radius: ${({ theme }) => `${theme.roundness}px`};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.15)};
`;

export const RewardImage = styled.Image`
  width: ${Size.X4_L}px;
  height: ${Size.X4_L}px;
  border-top-left-radius: ${({ theme }) => `${theme.roundness}`}px;
  border-bottom-left-radius: ${({ theme }) => `${theme.roundness}`}px;
`;

export const StyledSectionHeader = styled(Subheading)`
  margin-vertical: ${Margin.Large}px;
`;

export const ContinuitySegmentContainer = styled.View`
  width: ${Size.S}px;
  height: 100%;
`;

export const LineContainer = styled.View`
  z-index: 0;
  flex: 1;
  width: ${Size.X3_S}px;
  align-self: center;
`;

export const TopLineOne = styled.View<{ isVisible: boolean; isPartialFill: boolean }>`
  flex: 2;
  background-color: ${({ isVisible, isPartialFill, theme }) =>
    isVisible
      ? isPartialFill
        ? theme.colors.placeholder
        : theme.colors.primary
      : theme.colors.background};
  width: ${Size.X3_S}px;
  align-self: center;
`;

export const TopLineTwo = styled.View<{ isFilled: boolean; isStart?: boolean }>`
  flex: 1;
  border-radius: ${({ isStart }) => (isStart ? Size.S : 0)}px;
  background-color: ${({ isFilled, theme }) =>
    isFilled ? theme.colors.primary : theme.colors.placeholder};
  width: ${Size.X3_S}px;
  align-self: center;
  margin-bottom: -1px;
`;

export const CircleIndicator = styled.View<{ isFilled: boolean }>`
  z-index: 1;
  background-color: ${({ isFilled, theme }) =>
    isFilled ? theme.colors.primary : theme.colors.background};
  border-color: ${({ isFilled, theme }) =>
    isFilled ? theme.colors.primary : theme.colors.placeholder};
  border-width: ${Size.X3_S}px;
  width: ${Size.S}px;
  height: ${Size.S}px;
  border-radius: ${Size.S}px;
  align-self: center;
`;

export const BottomLine = styled.View<{ isVisible: boolean; isFilled: boolean }>`
  flex: 2;
  background-color: ${({ isVisible, isFilled, theme }) =>
    isVisible
      ? isFilled
        ? theme.colors.primary
        : theme.colors.placeholder
      : theme.colors.background};
  width: ${Size.X3_S}px;
  align-self: center;
`;
