import { IconSize, Size } from 'src/constants';

export const SIDEBAR_WIDTH = 300;
export const SIDEBAR_PADDING = Size.M;
export const SIDE_BAR_LOGO_SIZE = IconSize.XXL;
export const LEFT_HERO_WIDTH = 362;
export const LEFT_HERO_HEIGHT = 768;

export const MAX_CARD_WIDTH = 900;
export const MAX_CARD_HEIGHT = 768;
