import React, { LegacyRef, useCallback, useRef, useState } from 'react';
import Pagination from './Pagination';
import {
  Animated,
  FlatList,
  FlatListProps,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent
} from 'react-native';
import FixCellOffsetOnWeb from './FixCellOffsetOnWeb';
import { Slide, SlideItem, SlideProps } from './Slide';
import { useFocusEffect } from '@react-navigation/native';
import { isDefined } from 'src/utils';
import { useBoolean } from 'src/hooks';
import useStateRef from 'react-usestateref';

const viewabilityConfig = {
  itemVisiblePercentThreshold: 50
};

interface Props<T extends SlideItem = SlideItem>
  extends Omit<FlatListProps<T>, 'renderItem' | 'viewabilityConfig'> {
  data: T[];
  SlideComponent?: React.FC<SlideProps<T>>;
  ref?: LegacyRef<FlatList<T>>;
  isAutoPlay?: boolean;
}
interface SliderWithForwardedRef extends React.ForwardRefExoticComponent<Props<SlideItem>> {
  <T extends SlideItem>(props: Props<T>): ReturnType<React.FC<Props<T>>>;
}
const Slider: SliderWithForwardedRef = React.forwardRef(
  (
    {
      data,
      onScroll: parentOnScroll,
      onLayout: parentOnLayout,
      SlideComponent = Slide,
      isAutoPlay,
      ...props
    },
    ref
  ) => {
    const [width, setWidth] = useState<number>();
    const scrollX = useRef(new Animated.Value(0)).current;
    const [index, setIndex, currentIndexRef] = useStateRef(0);

    const interval = useRef<NodeJS.Timer>();
    const { value: isPlaying, toTrue: play, toFalse: pause } = useBoolean(true);
    const { value: resetIntervalTrigger, toggle: resetInterval } = useBoolean();

    const onHandleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (!width) return;
      setIndex((prev) => {
        const next = Math.round(event.nativeEvent.contentOffset.x / width);
        if (prev !== next) {
          resetInterval();
        }
        return next;
      });

      Animated.event(
        [
          {
            nativeEvent: {
              contentOffset: { x: scrollX }
            }
          }
        ],
        {
          useNativeDriver: false
        }
      )(event);
      parentOnScroll?.(event);
    };

    const onHandleLayout = useRef((event: LayoutChangeEvent) => {
      setWidth(event.nativeEvent.layout.width);
      parentOnLayout?.(event);
    }).current;

    useFocusEffect(
      useCallback(() => {
        isDefined(resetIntervalTrigger);
        if (width && isPlaying && isAutoPlay) {
          const intervalVal: NodeJS.Timeout = setInterval(() => {
            const nextIndex =
              currentIndexRef.current + 1 >= data.length ? 0 : currentIndexRef.current + 1;
            flatListRef.current?.scrollToOffset({
              animated: true,
              offset: nextIndex * width
            });
          }, 10000);
          interval.current = intervalVal;
          return () => clearInterval(intervalVal);
        }
      }, [currentIndexRef, data.length, isAutoPlay, isPlaying, resetIntervalTrigger, width])
    );

    const flatListRef = useRef<FlatList<SlideItem> | null>(null);

    return (
      <>
        <FlatList
          {...props}
          CellRendererComponent={(props) => <FixCellOffsetOnWeb {...props} {...{ width }} />}
          ref={(r) => {
            flatListRef.current = r;
            if (typeof ref === 'function') {
              ref(r);
            } else if (ref) {
              ref.current = r;
            }
          }}
          data={data}
          renderItem={({ item, index }) => {
            if (!width) return null;
            return (
              <SlideComponent
                item={item}
                width={width}
                scrollX={scrollX}
                sliderWidth={width}
                index={index}
              />
            );
          }}
          onLayout={onHandleLayout}
          horizontal
          pagingEnabled
          snapToAlignment={'center'}
          showsHorizontalScrollIndicator={false}
          onScroll={onHandleScroll}
          scrollEventThrottle={1}
          viewabilityConfig={viewabilityConfig}
          onScrollBeginDrag={pause}
          onScrollEndDrag={play}
        />
        {!!width && (
          <Pagination
            slides={data}
            scrollX={scrollX}
            slideRef={flatListRef}
            index={index}
            sliderWidth={width}
          />
        )}
      </>
    );
  }
);

export default Slider;
