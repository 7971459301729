import { t } from 'i18next';
import { object, string } from 'yup';

export const clientSearchValidationSchema = object().shape({
  firstName: string().required(t('common:required')),
  lastName: string().required(t('common:required')),
  phoneNumber: string().length(10, t('common:invalid')).required(t('common:required'))
});

export const practiceSearchValidationSchema = object().shape(
  {
    name: string().required(t('common:required')),
    zip: string().when(['city', 'state'], {
      is: (city: string, state: string) => !city || !state,
      then: string().required(t('common:required'))
    }),
    city: string().when('zip', {
      is: (zip: string) => !zip,
      then: string().required(t('common:required'))
    }),
    state: string().when('zip', {
      is: (zip: string) => !zip,
      then: string().required(t('common:required'))
    })
  },
  [
    ['zip', 'city'],
    ['zip', 'state'],
    ['city', 'zip'],
    ['state', 'zip']
  ]
);
