import { useEffect } from 'react';
import useAppState from '../useAppState';
import { flushEvents } from 'src/utils/utmTracking';

/**
 * Flushes events when the app goes to the background or becomes inactive.
 */
const useFlushEvents = () => {
  const appState = useAppState();

  useEffect(() => {
    if (appState === 'background' || appState === 'inactive') {
      void flushEvents();
    }
  }, [appState]);
};

export default useFlushEvents;
