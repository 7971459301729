import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSize, Margin } from 'src/constants';
import { usePatient } from 'src/hooks';
import { Weight } from 'src/theme';
import styled from 'styled-components/native';
import { Row } from '../common';
import PatientAvatar from '../PatientAvatar';
import { Body } from '../Text';
import { HoverButton } from '../kit';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Patient } from 'src/interfaces';

interface Props {
  sourceId: number;
  patientId: string;
  description: string;
  dateLabel: string;
  date: Date;
  overdue?: boolean;
  actionCondition?: (patient?: Patient) => boolean;
  action?:
    | {
        label: string;
        onPress: () => void;
      }
    | false;
}

const PatientRowItem: React.FC<Props> = ({
  sourceId,
  patientId,
  description,
  dateLabel,
  date,
  overdue,
  action,
  actionCondition = () => true
}) => {
  const { colors } = useAppTheme();
  const { t, i18n } = useTranslation('home');

  const { data: patient } = usePatient(patientId);

  const dateText = useMemo(() => {
    return moment.utc(date).locale(i18n.language).format('MM/DD/YYYY');
  }, [date, i18n.language]);

  const daysPastDue = useMemo(() => {
    const numberOfDaysOverdue = overdue ? moment().diff(moment(date), 'days') : null;
    if (!numberOfDaysOverdue) return null;

    return `${numberOfDaysOverdue} ${numberOfDaysOverdue > 1 ? t('days') : t('day')}`;
  }, [date, overdue, t]);

  return (
    <Container key={sourceId}>
      <Avatar patient={patient} size={IconSize.L} />
      <DataContainer>
        <Body color={colors.onSurface}>{patient?.name}</Body>
        <Body color={colors.onSurface} fontWeight={Weight.MEDIUM} numberOfLines={1}>
          {description}
        </Body>
        <Row justify='flex-start'>
          <Body color={overdue ? colors.error : colors.onSurface}>{`${dateLabel}: `}</Body>
          <Body color={overdue ? colors.error : colors.onSurface}>{dateText}</Body>
          {overdue && (
            <Body color={colors.error}>{t('overdue', { ns: 'common', daysPastDue })}</Body>
          )}
        </Row>
      </DataContainer>
      {action && actionCondition(patient) && (
        <HoverButton uppercase={false} onPress={action.onPress}>
          {action.label}
        </HoverButton>
      )}
    </Container>
  );
};
export default PatientRowItem;

const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.surface}
  flex-direction: row;
  padding-vertical: ${Margin.Medium}px;
  padding-right: ${Margin.Small}px;
  border-bottom-width: 0.5px;
  border-bottom-color: ${({ theme }) => theme.colors.disabled};
`;

const Avatar = styled(PatientAvatar)`
  margin-horizontal: ${Margin.Small}px;
  align-self: center;
`;

const DataContainer = styled.View`
  flex: 1;
`;
