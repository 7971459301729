import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Patient, Todo } from 'src/interfaces';
import { useBoolean, useMutateTodos, useTodos } from 'src/hooks';
import moment from 'moment-timezone';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import { useTranslation } from 'react-i18next';
import { CallToAction, CaughtUpText, FullActivityIndicator, ListContainer } from './shared';
import HeaderToggle from './HeaderToggle';
import { LayoutAnimation } from 'react-native';
import { Divider } from 'react-native-paper';
import _ from 'lodash';
import { HoverCard, Subheading } from 'src/components';
import styled from 'styled-components/native';
import { Margin } from 'src/constants';
import BottomTabParamsList from 'src/routes/stacks/BottomTabNavigator/ParamsList';
import { Navigators } from 'src/routes/stacks/navigators';
import TodoCardItem from './TodoCardItem';
import { AlarmClock } from 'src/components/kit/ABIcons';

const MAX_ITEMS = 3;

interface Props {
  patientId?: Patient['patientId'];
  maxItems?: number;
}
const ToDosCard: React.FC<Props> = ({ patientId, maxItems = MAX_ITEMS }) => {
  const { t } = useTranslation('home');
  const { value: isOpen, toggle } = useBoolean(true);
  const { navigate } = useNavigation<NavigationProp<BottomTabParamsList>>();
  const [count, setCount] = useState(0);
  const [today, setToday] = useState<Todo[]>();
  const [scheduled, setScheduled] = useState<Todo[]>();
  const { todos, isLoading } = useTodos({
    isComplete: false,
    patientId,
    queryOptions: {
      suspense: false
    }
  });

  useEffect(() => {
    const d = (todos ?? [])
      .filter((todo) => !todo.isComplete && (!patientId || todo.patientId === patientId))
      .slice(0, maxItems);
    setCount(d.length);
    setToday(d.filter((v) => !v.dueDate || moment(v.dueDate).isSameOrBefore(moment(), 'day')));
    setScheduled(d.filter((v) => moment(v.dueDate).isAfter(moment(), 'day')));
  }, [todos, maxItems, patientId]);

  const { toggleCompleteTodo } = useMutateTodos();

  const debouncedToggle = useMemo(() => _.debounce(toggleCompleteTodo), [toggleCompleteTodo]);
  const hasTodos = !!today?.length || !!scheduled?.length;

  const navToEditToDo = (id: Todo['id']) =>
    navigate(Navigators.TODO_STACK, {
      screen: Screens.ADD_TO_DO,
      params: { todoId: id },
      initial: false
    });

  return (
    <>
      <HoverCard>
        <HeaderToggle
          icon={({ size, color }) => <AlarmClock color={color} width={size} height={size} />}
          label={t('toDos')}
          toggle={() => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            toggle();
          }}
          count={count}
        />
        {isOpen && !isLoading && (
          <Suspense fallback={<FullActivityIndicator />}>
            <ListContainer>
              {!!today?.length && (
                <>
                  <StyledSubheading>Today</StyledSubheading>
                  <SpacedDivider />
                  {today.map((todo) => (
                    <TodoCardItem
                      key={todo.id}
                      todo={todo}
                      navToEditToDo={() => navToEditToDo(todo.id)}
                      onComplete={async () => debouncedToggle(todo)}
                    />
                  ))}
                </>
              )}
              {!!scheduled?.length && (
                <>
                  <StyledSubheading topSpacer>Scheduled</StyledSubheading>
                  <SpacedDivider />
                  {scheduled.map((todo) => (
                    <TodoCardItem
                      key={todo.id}
                      todo={todo}
                      navToEditToDo={() => navToEditToDo(todo.id)}
                      onComplete={async () => toggleCompleteTodo(todo)}
                    />
                  ))}
                </>
              )}
            </ListContainer>
          </Suspense>
        )}
        {isOpen && !isLoading && !hasTodos && (
          <CaughtUpText>{t('noItems', { itemType: t('toDos').toLowerCase() })}</CaughtUpText>
        )}
        {isOpen && isLoading && <FullActivityIndicator />}
        {isOpen && (
          <CallToAction onPress={() => navigate(Navigators.TODO_STACK, { screen: Screens.TO_DOS })}>
            {t('viewAll')}
          </CallToAction>
        )}
      </HoverCard>
    </>
  );
};

export default ToDosCard;

const SpacedDivider = styled(Divider)`
  margin-bottom: ${Margin.Large}px;
`;

const StyledSubheading = styled(Subheading)<{ topSpacer?: boolean }>`
  margin-top: ${({ topSpacer }) => (topSpacer ? Margin.ExtraLarge : 0)}px;
  margin-bottom: ${Margin.Small}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;
