import React from 'react';
import { Patient } from 'src/interfaces';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import { CallToAction, CardContent, FilledIconContainer } from './shared';
import BottomTabParamsList from 'src/routes/stacks/BottomTabNavigator/ParamsList';
import { Navigators } from 'src/routes/stacks/navigators';
import { Trans, useTranslation } from 'react-i18next';
import { Body, HoverCard } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import Icon from 'react-native-vector-icons/FontAwesome';
import { IconSize } from 'src/constants';

interface Props {
  patientId?: Patient['patientId'];
  maxItems?: number;
}
const HelpCenterCard: React.FC<Props> = () => {
  const { t } = useTranslation('helpCenter');
  const { colors } = useAppTheme();
  const { navigate } = useNavigation<NavigationProp<BottomTabParamsList>>();

  return (
    <>
      <HoverCard>
        <CardContent>
          <FilledIconContainer>
            <Icon name='support' color={colors.primary} size={IconSize.L} />
          </FilledIconContainer>
          <Body textAlign='center' alignSelf='center' color={colors.onSurface}>
            <Trans
              i18nKey={t('needHelpHoverCardText')}
              ns={'helpCenter'}
              values={{ helpCenter: t('helpCenter') }}
              components={{
                bold: <Body fontWeight={Weight.BOLD} color={colors.onSurface} />
              }}
            />
          </Body>
        </CardContent>
        <CallToAction
          onPress={() =>
            navigate(Navigators.HOME_STACK, {
              screen: Screens.HELP_CENTER
            })
          }
        >
          {t('viewHelpCenter')}
        </CallToAction>
      </HoverCard>
    </>
  );
};

export default HelpCenterCard;
