// TODO: Remove this when Nova updates appointments api to include isVirtual
// Followup ticket: https://allydvm.atlassian.net/browse/AST-126

enum AppointmentType {
  'DEN', // Dental cleaning
  'RBS', // Rabies vaccine
  'TEL', // Telemedicine
  'VID', // Virtual
  'DEN1', // Dental cleaning 1
  'RBS1', // Rabies vaccine 1
  'TEL1', // Telemedicine 1
  'VID1' // Virtual 1
}

export default AppointmentType;
