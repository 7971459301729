import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getReminders, QueryKeys } from 'src/api';
import { Reminder, Patient } from 'src/interfaces';

const PLACEHOLDER: Reminder[] = [];
interface Options extends UseQueryOptions<Reminder[], unknown, Reminder[], QueryKeys[]> {
  patientId?: Patient['patientId'];
}

export const remindersOptions: UseQueryOptions<Reminder[], unknown, Reminder[], QueryKeys[]> = {
  queryKey: [QueryKeys.REMINDERS],
  queryFn: getReminders,
  select: (reminders) => _.sortBy(reminders, 'pastDueDate'),
  suspense: true
};

const useReminders = ({ patientId: selectedPatientId, ...options }: Options = {}) => {
  const { data = PLACEHOLDER, ...rest } = useQuery({
    ...remindersOptions,
    ...options
  });

  const reminders = useMemo(() => {
    return selectedPatientId
      ? data.filter(({ patientId }) => selectedPatientId === patientId)
      : data;
  }, [data, selectedPatientId]);

  return {
    data: reminders,
    ...rest
  };
};

export default useReminders;
