import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, SectionList } from 'react-native';
import { Margin } from 'src/constants';
import { useViewMode } from 'src/hooks';
import FaqCard from './components/FaqCard';
import Header from './components/Header';
import TourGuideCard from './components/TourGuideCard';
import useSearchResults from './components/useSearchResults';
import { SectionTitle } from './style';

const HelpCenter: React.FC = () => {
  const { horizontalInset } = useViewMode();
  const { t } = useTranslation('helpCenter');
  const [searchText, setSearchText] = useState<string>('');
  const sections = useSearchResults(searchText);

  const setSearchValue = useMemo(
    () =>
      _.debounce((searchText: string) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setSearchText(searchText);
      }),
    [setSearchText]
  );

  return (
    <SectionList
      contentContainerStyle={{
        gap: Margin.Large,
        marginHorizontal: horizontalInset,
        padding: Margin.Medium
      }}
      ListHeaderComponent={
        <Header
          faqHasResults={!!sections[0].data.length}
          tourGuideHasResults={!!sections[1].data.length}
          searchText={searchText}
          setSearchText={setSearchValue}
        />
      }
      sections={sections}
      renderSectionHeader={({ section }) =>
        section.data.length ? <SectionTitle>{t(section.title)}</SectionTitle> : <></>
      }
      renderItem={({ item }) =>
        item.type === 'faq' ? (
          <FaqCard question={item.question} answer={item.answer} />
        ) : (
          <TourGuideCard
            label={item.label}
            stack={item.stack}
            tours={item.tours}
            screenName={item.screen}
          />
        )
      }
      stickySectionHeadersEnabled={false}
    />
  );
};

export default HelpCenter;
