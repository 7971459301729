import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const Pill: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg fill={color} width={size} height={size} viewBox='0 0 24 24'>
      <Path d='M4.22003 11.29L11.29 4.22C13.64 1.88 17.43 1.88 19.78 4.22C22.12 6.56 22.12 10.36 19.78 12.71L12.71 19.78C10.36 22.12 6.56003 22.12 4.22003 19.78C1.88003 17.43 1.88003 13.64 4.22003 11.29ZM5.64003 12.71C4.59003 13.75 4.24003 15.24 4.60003 16.57L10.59 10.59L14.83 14.83L18.36 11.29C19.93 9.73 19.93 7.2 18.36 5.64C16.8 4.07 14.27 4.07 12.71 5.64L5.64003 12.71Z' />
    </Svg>
  );
};

export default Pill;
