import { PathConfig } from 'src/routes/types';

const appLinkingPaths = <T extends {}, Y extends {}>(
  config: PathConfig<T>,
  tree: string[] = []
): string[] => {
  const newPaths = [...tree];
  if (config.path) {
    newPaths.push(config.path);
  }
  const childPaths: string[] = [];
  const screens: Array<PathConfig<Y>> = Object.values(config.screens);
  for (const screen of screens) {
    if (screen === null) {
      return [];
    } else if (typeof screen === 'string') {
      childPaths.push([...newPaths, screen].filter((v) => v.length > 0).join('/'));
    } else if (typeof screen === 'object') {
      childPaths.push(...appLinkingPaths(screen, newPaths));
    }
  }
  return childPaths;
};

export default appLinkingPaths;
