import { PATIENT_STATUSES, endpoint } from 'src/constants';
import { Patient } from 'src/interfaces';
import { isDefinedOrThrowServerError, hasPhoto, PaginationRS } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export interface PaginatedPatientsParams {
  length?: number;
  start?: number;
  order?: 'asc' | 'desc';
  orderBy?: keyof Pick<Patient, 'name' | 'birthdate' | 'deceasedDate'>;
  search?: string;
  status?: PATIENT_STATUSES;
}

export const getPaginatedPatients = async ({
  status,
  order,
  orderBy,
  ...params
}: PaginatedPatientsParams): Promise<PaginationRS<Patient>> => {
  const response = await apiClient.get<PaginationRS<Patient>>(endpoint('PATIENTS'), {
    params: {
      ...params,
      status: status ?? Object.values(PATIENT_STATUSES),
      order: {
        column: orderBy,
        dir: order
      },
      server_managed: true
    }
  });
  const responseData = isDefinedOrThrowServerError(response.data);
  return {
    ...responseData,
    data: responseData.data.map((p) => ({ ...p, hasPhoto: hasPhoto(p) }))
  };
};
