import { endpoint } from 'src/constants';
import { LifelearnArticlesResponse } from 'src/interfaces';
import { LifelearnFeedback } from 'src/interfaces/api/Lifelearn';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getLifelearnArticles = async (): Promise<LifelearnArticlesResponse> => {
  const response = await apiClient.get(endpoint('GET_LIFELEARN_ARTICLES'));
  return isDefinedOrThrowServerError(response.data);
};

export const postLifelearnFeedback = async (
  feedback: LifelearnFeedback
): Promise<{ message: string }> => {
  const response = await apiClient.post(endpoint('POST_LIFELEARN_FEEDBACK'), {
    article_id: feedback.articleId,
    rating: feedback.rating,
    time_spent_in_sec: feedback.timeSpentInSec,
    feedback: feedback.feedback
  });
  return isDefinedOrThrowServerError(response.data);
};
