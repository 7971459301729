import { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import AdminLogin from './AdminLogin';
import _ from 'lodash';
import UserSearch from './UserSearch';
import { AsyncStorageKeys } from 'src/api';
import { queryClient, storage } from 'src/utils';
import { ActivityIndicator } from 'react-native-paper';
import { layout } from 'src/theme/globalStyles';
import { IS_WEB } from 'src/constants';
import useStateRef from 'react-usestateref';
import { AppState } from 'react-native';

export enum AppUserState {
  CLIENT_MODE,
  ADMIN_LOGIN,
  USER_SEARCH,
  IMPERSONATE,
  ENDING_SESSION
}

interface ProviderState {
  appUserState: AppUserState;
  adminToken: string | undefined;
}

interface Context {
  providerState?: ProviderState;
  openAdminLogin: () => void;
  endAdminSession: () => void;
  findUser: () => void;
}

const defaultContext: Context = {
  providerState: undefined,
  openAdminLogin: _.noop,
  endAdminSession: _.noop,
  findUser: _.noop
};

export const ImpersonationContext = createContext(defaultContext);

export const useImpersonation = (): Context => useContext(ImpersonationContext);

interface Props extends PropsWithChildren {}

export const ImpersonationProvider: React.FC<Props> = ({ children }) => {
  const [state, setState, stateRef] = useStateRef<ProviderState>({
    appUserState: AppUserState.CLIENT_MODE,
    adminToken: undefined
  });

  const goToAdminLogin = useCallback(() => {
    setState((state) => ({ ...state, appUserState: AppUserState.ADMIN_LOGIN }));
  }, []);

  const goToUserSearch = useCallback(async (token?: string) => {
    await setState((state) => ({
      ...state,
      adminToken: token ?? state.adminToken,
      appUserState: AppUserState.USER_SEARCH
    }));
    await storage.removeItem(AsyncStorageKeys.TOKEN);
    await queryClient.clear();
  }, []);

  const goToEndSession = useCallback(async () => {
    await setState((state) => ({
      ...state,
      adminToken: undefined,
      appUserState: AppUserState.ENDING_SESSION
    }));
    await storage.removeItem(AsyncStorageKeys.TOKEN);
    await queryClient.clear();
    setState((state) => ({ ...state, appUserState: AppUserState.CLIENT_MODE }));
  }, []);

  const goToImpersonateUser = useCallback(async (userToken: string) => {
    await storage.setTypedItem<string>(AsyncStorageKeys.TOKEN, userToken);
    await queryClient.clear();
    setState((state) => ({ ...state, appUserState: AppUserState.IMPERSONATE }));
  }, []);

  useEffect(() => {
    if (IS_WEB) {
      const endSession = async () => {
        if (stateRef.current.appUserState === AppUserState.IMPERSONATE) {
          await storage.removeItem(AsyncStorageKeys.TOKEN);
        }
      };
      window.addEventListener('beforeunload', endSession);
      return () => window.removeEventListener('beforeunload', endSession);
    } else {
      const listener = AppState.addEventListener('change', async (state) => {
        if (state === 'inactive' && stateRef.current.appUserState === AppUserState.IMPERSONATE) {
          await storage.removeItem(AsyncStorageKeys.TOKEN);
        }
      });
      return listener.remove;
    }
  }, []);

  return (
    <ImpersonationContext.Provider
      value={{
        openAdminLogin: goToAdminLogin,
        providerState: state,
        endAdminSession: goToEndSession,
        findUser: goToUserSearch
      }}
    >
      {(state.appUserState === AppUserState.CLIENT_MODE ||
        state.appUserState === AppUserState.IMPERSONATE) &&
        children}
      {state.appUserState === AppUserState.ADMIN_LOGIN && (
        <AdminLogin openUserSearch={goToUserSearch} endSession={goToEndSession} />
      )}
      {state.appUserState === AppUserState.USER_SEARCH && state.adminToken && (
        <UserSearch
          endSession={goToEndSession}
          token={state.adminToken}
          startImpersonateSession={goToImpersonateUser}
        />
      )}
      {state.appUserState === AppUserState.ENDING_SESSION && (
        <ActivityIndicator style={layout.flex1} size='large' />
      )}
    </ImpersonationContext.Provider>
  );
};
