import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Calendar: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M3.564 10.313h16.875M8.064 7.5V3.562M15.939 7.5V3.562m-11.25 2.25h14.625c.621 0 1.125.504 1.125 1.125v12.375c0 .622-.504 1.125-1.125 1.125H4.689a1.125 1.125 0 0 1-1.125-1.125V6.938c0-.62.504-1.125 1.125-1.125Z'
    />
  </Svg>
);
export default Calendar;
