import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import CalendarHero from './AppointmentsRoot/components/CalendarHero';

export enum STEPS {
  DRAWER,
  REQUEST,
  NEXT,
  UPCOMING,
  PAST
}

const AppointmentsTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: { id: STEPS.DRAWER, priority: 0 },
  [STEPS.REQUEST]: { id: STEPS.REQUEST, priority: 1 },
  [STEPS.NEXT]: { id: STEPS.NEXT, priority: 2 },
  [STEPS.UPCOMING]: { id: STEPS.UPCOMING, priority: 3 },
  [STEPS.PAST]: { id: STEPS.PAST, priority: 4 }
};

export const SLIDES: SlideItem[] = [
  {
    title: 'appointments:list-tour.drawer.title',
    body: 'appointments:list-tour.drawer.body',
    image: <CalendarHero />
  }
];

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.APPOINTMENTS,
  AppointmentsTourMap
);
