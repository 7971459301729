import { useMemo } from 'react';
import { FAQS, TOUR_GUIDES } from '../shared';
import { useTranslation } from 'react-i18next';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import { TOUR } from 'src/module/TourGuide/model';
import { PRACTICE_FEATURES } from 'src/constants';
import { usePractice } from 'src/hooks';

interface SearchResult {
  type: 'faq' | 'tour';
  question: string | undefined;
  answer: string | undefined;
  label: string | undefined;
  stack: Navigators | undefined;
  screen: Screens | undefined;
  tours: TOUR[] | undefined;
}

const useSearchResults = (searchText: string) => {
  const { t } = useTranslation('helpCenter');
  const { data: practice } = usePractice();

  const activeTourGuides = useMemo(() => {
    const hasLoyaltyProgram = !!practice?.selectedFeatures?.find(
      (feature) => feature === PRACTICE_FEATURES.LOYALTY_PROGRAM
    );
    return hasLoyaltyProgram
      ? TOUR_GUIDES
      : TOUR_GUIDES.filter((tourGuide) => tourGuide.stack !== Navigators.LOYALTY_STACK);
  }, [practice?.selectedFeatures]);

  return useMemo(() => {
    const tourGuideResults = activeTourGuides
      .filter(
        (tourGuide) =>
          !searchText || t(tourGuide.label).toLowerCase().includes(searchText.toLowerCase())
      )
      .map(
        (searchResult): SearchResult => ({
          type: 'tour',
          label: t(searchResult.label),
          stack: searchResult.stack,
          screen: searchResult.screen,
          tours: searchResult.tours,
          question: undefined,
          answer: undefined
        })
      );

    const faqResults = FAQS.filter(
      (faq) =>
        !searchText ||
        t(faq.question).toLowerCase().includes(searchText.toLowerCase()) ||
        t(faq.answer).toLowerCase().includes(searchText.toLowerCase())
    ).map(
      (searchResult): SearchResult => ({
        type: 'faq',
        question: t(searchResult.question),
        answer: t(searchResult.answer),
        label: undefined,
        stack: undefined,
        screen: undefined,
        tours: undefined
      })
    );

    return [
      {
        title: 'tourGuides',
        data: tourGuideResults
      },
      {
        title: 'faqTitle',
        data: faqResults
      }
    ];
  }, [activeTourGuides, searchText, t]);
};

export default useSearchResults;
