import { PropsWithChildren } from 'react';
import { Portal } from 'react-native-paper';
import { Client } from 'src/interfaces';
import { createGenericContext } from 'src/utils/createGenericContext';
import NotificationProvider from './NotificationProvider';

interface Context {
  user: Client;
}

const UserContext = createGenericContext<Context>();

export const [useUser] = UserContext;
const [, TrueUserProvider] = UserContext;

interface Props extends PropsWithChildren {
  user: Client;
}
export const UserProvider: React.FC<Props> = ({ children, user }) => {
  return (
    <TrueUserProvider
      value={{
        user
      }}
    >
      <NotificationProvider user={user}>
        <Portal.Host>{children}</Portal.Host>
      </NotificationProvider>
    </TrueUserProvider>
  );
};
