import { useNavigation } from '@react-navigation/native';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { mask } from 'react-native-mask-text';
import { ActivityIndicator } from 'react-native-paper';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { clientSearch, forgotPassword, MutationKeys } from 'src/api';
import { BottomButton, CPrimaryButton } from 'src/components';
import { ALLY_SUPPORT_EMAIL, IS_WEB, PHONE_MASK, Size } from 'src/constants';
import { EmailVerificationStatus } from 'src/interfaces';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import { Screens } from 'src/routes/stacks/screens';
import { layout } from 'src/theme/globalStyles';
import { Actions, ReducerState } from '../helpers/model';
import { ContentContainer, StyledTextInput } from '../style';
import { GuideElement, STEPS, useTourGuide } from '../tour';
import { clientSearchValidationSchema } from '../validation';

interface Props {
  state: ReducerState;
  setModalTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalText: React.Dispatch<React.SetStateAction<string>>;
  showAlert: () => void;
  moveNext: () => void;
  updateData: (data: Actions['data']) => void;
}

const ClientSearch: React.FC<Props> = ({
  state,
  setModalTitle,
  setModalText,
  showAlert,
  moveNext,
  updateData
}) => {
  const { t } = useTranslation('practiceClientSearch');
  const { viewMode } = useAppTheme();
  const { navigate } = useNavigation();

  const maskedPracticePhoneNumber = useMemo(() => {
    return state.data.selectedPractice?.phoneNumber
      ? mask(state.data.selectedPractice?.phoneNumber, PHONE_MASK)
      : undefined;
  }, [state.data.selectedPractice?.phoneNumber]);

  const { mutate: sendVerificationEmail, isLoading: sendVerificationEmailLoading } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: moveNext,
    onError: () =>
      Toast.show({
        text1: t('uhOh', { ns: 'common' }),
        text2: t('error', { ns: 'common' }),
        type: 'error'
      })
  });

  const { mutate: performClientSearch, isLoading: performClientSearchLoading } = useMutation({
    mutationKey: [MutationKeys.CLIENT_SEARCH],
    mutationFn: clientSearch,
    onSuccess: (client, vars) => {
      switch (client.emailVerificationStatus) {
        case EmailVerificationStatus.VERIFIED:
        case EmailVerificationStatus.UNKNOWN:
        case EmailVerificationStatus.IN_PROGRESS:
          updateData({ email: client.email });
          sendVerificationEmail({ practiceId: vars.practiceId, clientId: client.clientId });
          break;
        case EmailVerificationStatus.INVALID:
        case EmailVerificationStatus.BAD:
        case EmailVerificationStatus.DECLINED:
          setModalTitle(
            t('emailContactPracticeTitle', { name: state.data.selectedPractice?.name })
          );
          setModalText(
            t('emailContactPracticeText', {
              name: state.data.selectedPractice?.name,
              email: state.data.selectedPractice?.email,
              phone: maskedPracticePhoneNumber
            })
          );
          showAlert();
          break;
        case EmailVerificationStatus.MISSING:
          setModalTitle(t('emailMissingTitle'));
          setModalText(t('emailMissingText', { phone: maskedPracticePhoneNumber }));
          showAlert();
          break;
        default:
          setModalTitle(t('otherStatusTitle'));
          setModalText(t('otherStatusText', { email: ALLY_SUPPORT_EMAIL }));
          showAlert();
      }
    },
    onError: () => {
      setModalTitle(t('noClientFoundTitle'));
      setModalText(t('noClientFoundText', { phone: maskedPracticePhoneNumber }));
      showAlert();
    }
  });

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      practiceId: state.data.selectedPractice?.id ?? NaN
    },
    validateOnChange: false,
    validationSchema: clientSearchValidationSchema,
    onSubmit: async (values) => {
      await performClientSearch(values);
    }
  });

  const debouncedSubmit = useMemo(() => _.debounce(() => handleSubmit()), [handleSubmit]);

  const lastNameRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const { enabled, exitTour } = useTourGuide();

  const scrollRef = useRef<ScrollView>(null);

  return (
    <>
      <ContentContainer ref={scrollRef}>
        <ScrollRefProvider scrollRef={scrollRef}>
          <GuideElement
            id={STEPS.CLIENT_SEARCH}
            body={t('tour.clientSearch')}
            onContinue={() => {
              exitTour();
              navigate(Screens.LANDING);
            }}
          >
            <>
              <StyledTextInput
                addBottomGap={enabled}
                nextRef={lastNameRef}
                value={enabled ? 'John' : values.firstName}
                onChangeText={async (firstName) => await setFieldValue('firstName', firstName)}
                label={t('firstName')}
                error={!!errors.firstName}
                msg={errors.firstName}
              />
              <StyledTextInput
                addBottomGap={enabled}
                thisRef={lastNameRef}
                nextRef={phoneNumberRef}
                value={enabled ? 'Doe' : values.lastName}
                onChangeText={async (lastName) => await setFieldValue('lastName', lastName)}
                label={t('lastName')}
                error={!!errors.lastName}
                msg={errors.lastName}
              />
              <StyledTextInput
                thisRef={phoneNumberRef}
                value={enabled ? '2128675309' : values.phoneNumber}
                onChangeText={async (phoneNumber) =>
                  await setFieldValue('phoneNumber', phoneNumber)
                }
                mask={PHONE_MASK}
                label={t('phoneNumber')}
                error={!!errors.phoneNumber}
                msg={errors.phoneNumber}
                onSubmitEditing={() => handleSubmit()}
              />
            </>
          </GuideElement>
        </ScrollRefProvider>
      </ContentContainer>
      {(sendVerificationEmailLoading || performClientSearchLoading) && (
        <ActivityIndicator size='large' style={layout.flex1} />
      )}
      {IS_WEB && (
        <View
          style={{
            marginHorizontal: viewMode.horizontalInset,
            paddingBottom: Size.X3_S
          }}
        >
          <CPrimaryButton onPress={debouncedSubmit}>{t('next')}</CPrimaryButton>
        </View>
      )}
      {!IS_WEB && (
        <BottomButton onPress={debouncedSubmit} shade={0.65}>
          {t('next')}
        </BottomButton>
      )}
    </>
  );
};

export default ClientSearch;
