import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/native';
import { BOOKING_STEPS, STEP_INFO, STEP_STATUS } from '../BookingProvider/helpers';
import { getStepState } from './helper';
import { useBookingState } from '../BookingProvider';
import { Size } from 'src/constants';
import { AppColors } from 'src/types/paper';
import { colorShade, transparent } from 'src/utils';
import { Animated, View } from 'react-native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { omit } from 'lodash';

interface Props {
  disableNav: boolean;
}

const TopNavBar: React.FC<Props> = ({ disableNav }) => {
  const { values, bookingState, goToStep } = useBookingState();
  const {
    viewMode: { windowWidth }
  } = useAppTheme();
  const indicatorPosition = useRef(new Animated.Value(0));

  useLayoutEffect(() => {
    Animated.timing(indicatorPosition.current, {
      toValue: bookingState.current * stepWidth(windowWidth, 0.25),
      duration: 300,
      useNativeDriver: false
    }).start();
  }, [bookingState, windowWidth]);

  return (
    <TopBarContainer>
      {Object.values(omit(STEP_INFO, BOOKING_STEPS.STATUS)).map((step) => {
        const status = getStepState(values, bookingState, step.key);
        return (
          <TabBarIndicator
            key={step.key}
            hitSlop={Size.XS}
            disabled={
              bookingState.current === BOOKING_STEPS.STATUS ||
              status === STEP_STATUS.INCOMPLETE ||
              disableNav
            }
            disableNav={disableNav}
            status={status}
            step={step.key}
            onPress={() => goToStep(step.key)}
          >
            {<View></View>}
          </TabBarIndicator>
        );
      })}
      <TabBarHighlight style={{ left: indicatorPosition.current }} />
    </TopBarContainer>
  );
};

export default TopNavBar;

const TopBarContainer = styled.View`
  flex-direction: row;
  align-items: stretch;
  height: ${Size.XS}px;
`;

const TabBarIndicator = styled.Pressable<{
  status: STEP_STATUS;
  step: BOOKING_STEPS;
  disableNav: boolean;
}>`
  flex: 1;
  background-color: ${({ theme, status }) => barIndicatorColor(status, theme.colors)};
  border-right-width: ${({ step }) => (step === BOOKING_STEPS.CONFIRMATION ? 0 : 0.5)}px;
  border-left-width: ${({ step }) => (step === BOOKING_STEPS.CLIENT_INFO ? 0 : 0.5)}px;
  border-right-color: ${({ theme }) => theme.colors.surface};
  border-left-color: ${({ theme }) => theme.colors.surface};
  opacity: ${({ disableNav }) => (disableNav ? 0.5 : 1)};
`;

const TabBarHighlight = styled(Animated.View)`
  width: ${({ theme }) => stepWidth(theme.viewMode.windowWidth, -2)}px;
  height: 100%;
  background-color: ${({ theme }) => colorShade(theme.colors.primary, 0.7)};
  position: absolute;
  bottom: 0;
  pointer-events: none;
`;

const barIndicatorColor = (status: STEP_STATUS, colors: AppColors) => {
  switch (status) {
    case STEP_STATUS.INCOMPLETE:
      return transparent(colors.primary, 0.2);
    case STEP_STATUS.ERROR:
      return transparent(colors.error, 0.5);
    default:
      return colorShade(colors.primary, 0.8);
  }
};

const stepWidth = (windowWidth: number, compensation: number) =>
  windowWidth / (BOOKING_STEPS.CONFIRMATION + 1) + compensation;
