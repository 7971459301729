import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactNativeModal from 'react-native-modal';
import { Card, useTheme } from 'react-native-paper';
import { Body, Subheading } from 'src/components';
import { HoverButton, TextInput } from 'src/components/kit';
import { Margin, MOBILE_WIDTH_MAX, Size } from 'src/constants';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import { ControlConfirmationModalHook } from './useControlConfirmationModal';

const MAX_CONTENT_WIDTH = 300;

const AccountDeleteConfirmationModal: React.FC<ControlConfirmationModalHook> = ({
  isVisible,
  close,
  doDelete
}) => {
  const { t } = useTranslation('common');
  const { colors } = useTheme();

  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
  const [typedText, setTypedText] = useState<string>('');

  const onCancel = () => {
    setTypedText('');
    close();
  };

  const onDelete = () => {
    setTypedText('');
    doDelete();
  };

  useEffect(() => {
    setConfirmDisabled(typedText?.toLowerCase() !== t('delete').toLowerCase());
  }, [t, typedText]);

  return (
    <ReactNativeModal isVisible={isVisible}>
      <StyledCard style={{ maxWidth: MAX_CONTENT_WIDTH }}>
        <Content>
          <Subheading color={colors.onSurface} textAlign='center'>
            {t('areYouSure')}
          </Subheading>
          <Body style={layout.alignSelfCenter}>
            {t('manageAccount.deletingIrreversible', { ns: 'settings' })}
          </Body>
          <Body style={layout.alignSelfCenter}>
            {t('manageAccount.typeDelete', { ns: 'settings' })}
          </Body>
          <DeleteAccountInput
            mode='outlined'
            numberOfLines={1}
            autoCapitalize='none'
            value={typedText}
            onChangeText={(typedText) => setTypedText(typedText)}
          />
        </Content>
        <ButtonsContainer>
          <AlertButton
            onPress={() => {
              onCancel();
            }}
            isFirst={true}
            color={colors.primary}
            mode={'outlined'}
            labelStyle={{ color: colors.primary }}
          >
            {t('cancel')}
          </AlertButton>
          <AlertButton
            onPress={() => {
              onDelete();
            }}
            disabled={confirmDisabled}
            isLast={true}
            color={colors.primary}
            mode={'contained'}
            labelStyle={{ color: colors.onPrimary }}
          >
            {t('delete')}
          </AlertButton>
        </ButtonsContainer>
      </StyledCard>
    </ReactNativeModal>
  );
};

export default AccountDeleteConfirmationModal;

const DeleteAccountInput = styled(TextInput)`
  height: ${Size.X2_L}px;
  align-self: stretch
  margin-top: ${Margin.ExtraSmall}px;
  margin-bottom: ${Margin.Large}px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  padding-top: ${Size.X2_S}px;
  align-self: center;
  max-width: ${({ theme }) => (theme.viewMode.isMobile ? 'auto' : `${MOBILE_WIDTH_MAX}px`)};
`;

const ButtonsContainer = styled.View`
  flex-grow: 1;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
`;

const AlertButton = styled(HoverButton)<{ isFirst?: boolean; isLast?: boolean }>`
  flex: 1;
  padding: ${Margin.Small}px;
  height: ${Size.X2_L}px;
  border-radius: 0px;
  border-width: 0px;
  border-top-width: 0.5px;
  border-bottom-left-radius: ${({ theme, isFirst }) => (isFirst ? `${theme.roundness}px` : '0px')};
  border-bottom-right-radius: ${({ theme, isLast }) => (isLast ? `${theme.roundness}px` : '0px')};
`;

const Content = styled.View`
  padding: ${Margin.Medium}px;
  padding-bottom: ${Margin.Large}px;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;
