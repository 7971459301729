import { useFocusEffect } from '@react-navigation/native';
import { DependencyList, EffectCallback, useCallback } from 'react';
import { InteractionManager } from 'react-native';
import { IS_WEB } from 'src/constants';

const MIN_INTERACTION_TIME = 300;

/**
 * Runs a focus effect after all interactions are done. Note, this does not run a clean up function.
 * @param callback
 * @param deps
 */
const useInteractionFocusEffect = (callback: EffectCallback, deps: DependencyList) => {
  useFocusEffect(
    useCallback(() => {
      let cleanup: ReturnType<EffectCallback>;
      if (IS_WEB) {
        const timeout = setTimeout(() => {
          cleanup = callback();
        }, MIN_INTERACTION_TIME);
        return () => {
          clearTimeout(timeout);
          cleanup?.();
        };
      } else {
        const interactions = InteractionManager.runAfterInteractions(() => {
          cleanup = callback();
        });
        return () => {
          interactions.cancel();
          cleanup?.();
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps])
  );
};

export default useInteractionFocusEffect;
