import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const AutoRenew: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg fill={color} width={size} height={size} viewBox='0 0 24 24'>
      <Path d='M12 6V9L16 5L12 1V4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 13.57 4.46 15.03 5.24 16.26L6.7 14.8C6.25 13.97 6 13 6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6ZM18.76 7.74L17.3 9.2C17.74 10.04 18 11 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18V15L8 19L12 23V20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 10.43 19.54 8.97 18.76 7.74Z' />
    </Svg>
  );
};

export default AutoRenew;
