import * as React from 'react';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { Navigators } from '../navigators';
import { Screens } from '../screens';
import { useAuth } from 'src/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-native-paper';
import { UserProvider } from 'src/providers/ClientProvider';
import MainStackParamsList from './ParamsList';
import BottomTabNavigator from '../BottomTabNavigator';
import Reminders from 'src/scenes/Reminders';
import Prescriptions from 'src/scenes/Prescriptions';
import ContactUs from 'src/scenes/ContactUs';
import AfterHours from 'src/scenes/AfterHours';
import { fontStyles } from 'src/theme/globalStyles';
import PatientID from 'src/scenes/PatientID';
import SelectPractice from 'src/scenes/SelectPractice';
import AddToCalendar from 'src/scenes/AddToCalendar';
import useDefaultModalScreenOptions from '../useDefaultModalScreenOptions';
import LoyaltyReward from 'src/scenes/LoyaltyReward';
import TransactionDetails from 'src/scenes/TransactionDetails';
import { IS_WEB } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useMemo } from 'react';
import LoyaltyTransactionProvider from 'src/providers/LoyaltyTransactionProvider';
import BiometricLoginOptIn from 'src/scenes/BiometricLoginOptIn';
import BiometricsPromptProvider from 'src/providers/BiometricsPromptProvider';
import PatientProfile from 'src/scenes/PatientProfile';
import ProductRequest from 'src/scenes/ProductRequest';
import ProfileInformationUpdate from 'src/scenes/ProfileInformationUpdate';
import { PatientUpdateStackNavigator } from '../PatientUpdateStackNavigator';
import LoginLoader from 'src/components/LoginLoader';

const MainStack = createStackNavigator<MainStackParamsList>();

export const MainStackScreen: React.FC = () => {
  const { _user, isLoggingOut } = useAuth();
  const { colors } = useAppTheme();
  const { t } = useTranslation();

  const modalScreenOptions = useDefaultModalScreenOptions();

  const STACK_SCREEN_OPTIONS = {
    headerShown: false,
    headerTitleStyle: fontStyles.headline
  };

  const transitionAnimation = useMemo(
    () => (IS_WEB ? TransitionPresets.ModalFadeTransition : undefined),
    []
  );

  if (!_user || isLoggingOut) {
    return <LoginLoader />;
  }

  return (
    <BiometricsPromptProvider>
      <UserProvider user={_user}>
        <Portal.Host>
          <LoyaltyTransactionProvider>
            <MainStack.Navigator
              screenOptions={STACK_SCREEN_OPTIONS}
              initialRouteName={Navigators.BOTTOM_TAB_STACK}
            >
              <MainStack.Screen name={Navigators.BOTTOM_TAB_STACK} component={BottomTabNavigator} />
              <MainStack.Group
                screenOptions={{
                  ...modalScreenOptions,
                  headerShown: false,
                  cardStyle: { backgroundColor: IS_WEB ? colors.backdrop : 'transparent' },
                  presentation: 'transparentModal',
                  ...transitionAnimation
                }}
              >
                <MainStack.Screen
                  name={Screens.LOYALTY_REWARD}
                  component={LoyaltyReward}
                  options={{
                    title: t('loyalty:rewardScreenTitle')
                  }}
                />
                <MainStack.Screen
                  name={Screens.TRANSACTION_DETAILS}
                  component={TransactionDetails}
                  options={{
                    title: t('loyalty:transactionDetailsTitle')
                  }}
                />
              </MainStack.Group>
              <MainStack.Group screenOptions={modalScreenOptions}>
                <MainStack.Screen
                  name={Screens.REMINDERS}
                  component={Reminders}
                  options={{ title: t('patientProfile:reminders') }}
                />
                <MainStack.Screen
                  name={Screens.PRESCRIPTIONS}
                  component={Prescriptions}
                  options={{ title: t('patientProfile:prescriptions') }}
                />
                <MainStack.Screen
                  name={Screens.CONTACT_US}
                  component={ContactUs}
                  options={{ title: t('home:contactUs') }}
                />
                <MainStack.Screen
                  name={Screens.AFTER_HOURS}
                  component={AfterHours}
                  options={{ title: t('afterHours:title') }}
                />
                <MainStack.Screen
                  name={Screens.SELECT_PRACTICE}
                  component={SelectPractice}
                  options={{ title: t('selectPractice:title') }}
                />
                <MainStack.Screen
                  name={Screens.PATIENT_ID}
                  component={PatientID}
                  options={{ title: t('petID:header') }}
                />
                <MainStack.Screen
                  name={Screens.ADD_TO_CALENDAR}
                  component={AddToCalendar}
                  options={{ title: t('addToCalendar:title') }}
                />
                <MainStack.Screen
                  name={Screens.BIOMETRIC_LOGIN_OPT_IN}
                  component={BiometricLoginOptIn}
                  options={{
                    ...modalScreenOptions,
                    headerLeft: () => null,
                    headerShadowVisible: false,
                    title: t('biometricLogin:biometricLogin')
                  }}
                />
                <MainStack.Screen
                  name={Screens.PATIENT_PROFILE}
                  component={PatientProfile}
                  options={{
                    ...modalScreenOptions,
                    headerShown: false,
                    title: t('patientProfile:title')
                  }}
                />
                <MainStack.Screen
                  name={Screens.REQUESTS_PRODUCT}
                  component={ProductRequest}
                  options={{
                    ...modalScreenOptions,
                    title: t('requests:wizard.productRequest')
                  }}
                />
                <MainStack.Screen
                  name={Screens.PROFILE_INFORMATION_UPDATE}
                  component={ProfileInformationUpdate}
                  options={{
                    ...modalScreenOptions,
                    title: t('informationUpdate:title')
                  }}
                />
                <MainStack.Screen
                  name={Navigators.PATIENT_UPDATE_STACK_NAVIGATOR}
                  component={PatientUpdateStackNavigator}
                  options={{
                    ...modalScreenOptions,
                    headerShown: false
                  }}
                />
              </MainStack.Group>
            </MainStack.Navigator>
          </LoyaltyTransactionProvider>
        </Portal.Host>
      </UserProvider>
    </BiometricsPromptProvider>
  );
};
