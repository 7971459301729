import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native-paper';
import { FocusAwareStatusBar } from 'src/components';
import PatientSelector from 'src/components/PatientSelector';
import { Margin } from 'src/constants';
import { SelectedPatientProvider } from 'src/providers/SelectedPatientProvider';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import FilteredPrescriptions from './FilteredPrescriptions';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { SafeAreaView } from 'react-native-safe-area-context';

type Props = NativeStackScreenProps<MainStackParamsList, Screens.PRESCRIPTIONS>;

const Prescriptions: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation('patientProfile');
  const { colors } = useAppTheme();
  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <SelectedPatientProvider selectedPatientId={route.params?.id}>
        <StatusbarOffset>
          <PatientSelector typeName={t('prescriptions')} />
        </StatusbarOffset>
        <SafeAreaView
          style={[layout.flex1, { backgroundColor: colors.surface }]}
          edges={['bottom', 'left', 'right']}
        >
          <Suspense fallback={<ActivityIndicator size='large' style={layout.flex1} />}>
            <FilteredPrescriptions />
          </Suspense>
        </SafeAreaView>
      </SelectedPatientProvider>
    </>
  );
};

export default Prescriptions;

const StatusbarOffset = styled.View`
  background-color: ${({ theme }) => theme.colors.primary};
  padding-bottom: ${Margin.Small}px;
`;
