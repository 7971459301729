import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import EventEmitter from 'events';
import { AsyncStorageKeys, SwitchPracticeResponse, switchPractice, QueryKeys } from 'src/api';
import { Client } from 'src/interfaces';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';
import { ROOT_STACK_PATHS } from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { storage, isDefined, queryClient } from 'src/utils';
import { AuthStatus } from '../../providers/AuthProvider/model';
import { TokenPayload } from '../../providers/AuthProvider/token/type';
import { UrlInfo } from './model';

export const pathAndParams = (url: URL) =>
  [url.pathname, url.searchParams.toString()].filter((s) => !!s.trim().length).join('?');

export const shouldSwitchUsers = async (urlInfo: UrlInfo): Promise<boolean> => {
  const token = await storage.getTypedItem<string>(AsyncStorageKeys.TOKEN);
  if (!token) {
    return false;
  }
  const { user_id: currentUserId } = jwtDecode<TokenPayload>(token);
  const targetUserId = urlInfo?.userId;
  return isDefined(targetUserId) && targetUserId !== currentUserId;
};

export const handlePracticeSwitch = async (
  urlInfo: UrlInfo,
  applyPractice: (np: SwitchPracticeResponse) => Promise<void>
) => {
  const token = await storage.getTypedItem<string>(AsyncStorageKeys.TOKEN);
  if (!token) return;
  const user = await queryClient.getQueryData<Client>([QueryKeys.CLIENT, token]);
  if (!user) return;
  const availablePractices = await queryClient.getQueryData<AvailablePractice[]>([
    QueryKeys.CHECK_EMAIL,
    user.email
  ]);
  if (!availablePractices) return;
  const practice = availablePractices?.find((p) => p.userId === urlInfo.userId);
  if (!practice) return;
  const nextPractice = await switchPractice(practice).catch(() => undefined);
  if (!nextPractice) return;
  await applyPractice(nextPractice);
};

export const qualifiedAuthStatus = (authStatus: AuthStatus | undefined) => {
  const allowedStatuses = [AuthStatus.AUTHENTICATED, AuthStatus.LOGGED_OUT];
  return isDefined(authStatus) && allowedStatuses.includes(authStatus);
};

export const urlAllowsNoAuth = (url: URL) => {
  const noAuthRequiredUris = _(ROOT_STACK_PATHS.screens)
    .pick([
      Screens.PRACTICE_CLIENT_SEARCH,
      Screens.EMAIL_CONFIRMATION,
      Screens.TOKEN_PASSWORD_RESET,
      Screens.ONLINE_BOOKING
    ])
    .values();
  return noAuthRequiredUris.includes(url.pathname.replace(/\//, ''));
};

export const isLoginPath = (url: URL) => url.pathname.replace(/^\//, '') === '';

export const urlEmitter = new EventEmitter();

export let urlInfo: UrlInfo | undefined;
