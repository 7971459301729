import { LifelearnArticle } from 'src/interfaces';
import { Screens } from '../screens';
import { PathConfig } from 'src/routes/types';

type HomeStackParamsList = {
  [Screens.ARTICLE]: {
    id: LifelearnArticle['id'];
  };
  [Screens.ARTICLES_HUB]?: undefined;
  [Screens.HOME]?: undefined;
  [Screens.HELP_CENTER]?: undefined;
  [Screens.PROFILE_ROOT]?: undefined;
  [Screens.PROFILE_USERINFO]?: undefined;
  [Screens.PROFILE_APPSETTINGS]?: undefined;
  [Screens.PROFILE_CLIENT_PRACTICE_CONFIGURATION]?: undefined;
};

export const HOME_STACK_PATHS: PathConfig<HomeStackParamsList> = {
  initialRouteName: Screens.HOME,
  screens: {
    [Screens.ARTICLE]: 'article',
    [Screens.ARTICLES_HUB]: 'articles',
    [Screens.HOME]: 'home',
    [Screens.HELP_CENTER]: 'help',
    [Screens.PROFILE_ROOT]: 'profile',
    [Screens.PROFILE_USERINFO]: 'profile/info',
    [Screens.PROFILE_APPSETTINGS]: 'profile/app-settings',
    [Screens.PROFILE_CLIENT_PRACTICE_CONFIGURATION]: 'profile/communication'
  }
};

export default HomeStackParamsList;
