import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const MedicalBag: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg fill={color} width={size} height={size} viewBox='0 0 24 24'>
      <Path d='M9.99998 3L7.99998 5V7H4.99998C3.84998 7 3.11998 8 2.99998 9L1.99998 19C1.87998 20 2.53998 21 3.99998 21H20C21.46 21 22.12 20 22 19L21 9C20.88 8 20.06 7 19 7H16V5L14 3H9.99998ZM9.99998 5H14V7H9.99998V5ZM11 10H13V13H16V15H13V18H11V15H7.99998V13H11V10Z' />
    </Svg>
  );
};

export default MedicalBag;
