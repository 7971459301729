import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Animated, ImageSourcePropType, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { IconSize, Margin, Size } from 'src/constants';
import styled from 'styled-components/native';
import useHoverEffect from 'src/hooks/useHoverEffect';
import { containers, fontStyles } from 'src/theme/globalStyles';
import { isFunction } from 'lodash';
import { fonts, Weight } from 'src/theme';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import sentry, { interpolateName } from 'src/utils/sentry';

const SMALL_FAB_SIZE = 40;
const LARGE_FAB_SIZE = 64;
const PROFILE_IMAGE_ALIGNMENT_OFFSET = -8;

interface Props {
  icon:
    | React.ReactElement
    | ((props: { color: string; size: number }) => React.ReactElement)
    | ImageSourcePropType;
  label: string;
  buttonColor?: string;
  labelColor?: string;
  onPress: () => void;
  labelStyle?: StyleProp<TextStyle>;
  iconAnchor?: 'left' | 'right';
  small?: boolean;
  style?: StyleProp<ViewStyle>;
}
const AnimatedFAB: React.FC<Props> = ({
  buttonColor: parentButtonColor,
  labelColor: parentLabelColor,
  onPress: parentOnPress,
  label,
  labelStyle,
  icon: Icon,
  iconAnchor = 'left',
  style,
  small = false
}) => {
  const theme = useAppTheme();
  const buttonColor = parentButtonColor ?? theme.colors.primary;
  const labelColor = parentLabelColor ?? theme.colors.onPrimary;
  const size = small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE;
  const [width, setWidth] = useState(size);
  const [fabWidth, setFabWidth] = useState(size);
  const [ref, hoverAnimationValue] = useHoverEffect<View>();
  useEffect(() => {
    const listener = hoverAnimationValue.addListener(({ value }) => {
      setFabWidth(value * width + size);
    });
    return () => hoverAnimationValue.removeListener(listener);
  }, [hoverAnimationValue, size, small, width]);

  const renderIcon = useMemo(() => {
    if (isFunction(Icon)) {
      return <Icon color={labelColor} size={small ? IconSize.XS : IconSize.M} />;
    } else if (React.isValidElement<any>(Icon)) {
      return Icon;
    } else {
      return <StyledImage source={Icon} small={small} />;
    }
  }, [Icon, labelColor, small]);

  const sentryLabel = useMemo(() => interpolateName('AnimatedFab', label), [label]);
  const onPress = useCallback(() => {
    sentry.addBreadcrumb({ type: 'Interaction', message: `${sentryLabel} was pressed` });
    parentOnPress?.();
  }, [parentOnPress, sentryLabel]);

  return (
    <Container
      sentry-label={sentryLabel}
      onPress={onPress}
      style={[containers.shadow, style]}
      small={small}
    >
      <AnimatedContainer ref={ref} width={fabWidth} iconAnchor={iconAnchor}>
        <StyledButton buttonColor={buttonColor} iconAnchor={iconAnchor}>
          <IconContainer small={small}>{renderIcon}</IconContainer>
          {
            <AnimatedLabel
              small={small}
              numberOfLines={1}
              onLayout={({ nativeEvent }) => setWidth(nativeEvent.layout.width)}
              style={[
                {
                  color: labelColor,
                  opacity: hoverAnimationValue
                },
                labelStyle
              ]}
            >
              {label}
            </AnimatedLabel>
          }
        </StyledButton>
      </AnimatedContainer>
    </Container>
  );
};

export default AnimatedFAB;

const IconContainer = styled.View<{ small?: boolean }>`
  width: ${({ small }) => (small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE)}px;
  height: ${({ small }) => (small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE)}px;
  margin: ${PROFILE_IMAGE_ALIGNMENT_OFFSET}px;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.Image<{ small?: boolean }>`
  width: ${({ small }) => (small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE)}px;
  height: ${({ small }) => (small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE)}px;
  border-radius: ${({ small }) => (small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE)}px;
`;
const Container = styled(TouchableRipple)<{ small?: boolean }>`
  border-radius: ${({ small }) => (small ? SMALL_FAB_SIZE : LARGE_FAB_SIZE)}px;
  overflow: hidden;
`;

const StyledButton = styled.View<{ buttonColor: string; iconAnchor: 'left' | 'right' }>`
  flex-direction: ${({ iconAnchor }) => (iconAnchor === 'left' ? 'row' : 'row-reverse')};
  flex-shrink: 0;
  padding-vertical: ${Margin.Medium}px;
  padding-horizontal: ${Margin.Medium}px;
  align-items: center;
  background-color: ${({ buttonColor }) => buttonColor};
`;

// @types/styled-components View ref has a conflicting type with @types/react-native View ref
const AnimatedContainer = styled(View)<{ width: number; iconAnchor: 'left' | 'right' }>`
  width: ${({ width }) => width}px;
  flex-shrink: 0;
  align-items: ${({ iconAnchor }) => (iconAnchor === 'left' ? 'flex-start' : 'flex-end')};
`;

const AnimatedLabel = styled(Animated.Text)<{ small?: boolean }>`
  padding-right: ${Size.XS}px;
  padding-left: ${Size.S}px;
  letter-spacing: ${fontStyles.body.letterSpacing}px;
  font-size: ${({ small }) =>
    small ? fontStyles.body.fontSize : fontStyles.subheading.fontSize}px;
  font-weight: ${Weight.REGULAR};
  font-family: ${fonts.roboto.medium};
`;
