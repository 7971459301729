import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const Needle: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg fill={color} width={size} height={size} viewBox='0 0 24 24'>
      <Path d='M11.15 15.18L9.73002 13.77L11.15 12.35L12.56 13.77L13.97 12.35L12.56 10.94L13.97 9.53L15.39 10.94L16.8 9.53L13.97 6.7L6.90002 13.77L9.73002 16.6L11.15 15.18ZM3.08002 19L6.20002 15.89L4.08002 13.77L13.97 3.87L16.1 6L17.5 4.58L16.1 3.16L17.5 1.75L21.75 6L20.34 7.4L18.92 6L17.5 7.4L19.63 9.53L9.73002 19.42L7.61002 17.3L3.08002 21.84V19Z' />
    </Svg>
  );
};

export default Needle;
