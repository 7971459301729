import { Caption, Row, Title } from 'src/components';
import { colorShade } from 'src/utils/colorValues';
import styled, { css } from 'styled-components/native';
import { MOBILE_WIDTH_MAX, MOBILE_WIDTH_SMALL, Size } from 'src/constants';
import { ActivityIndicator } from 'react-native-paper';
import { palette, Weight } from 'src/theme';
import reloadApp from 'src/utils/reloadApp';
import IconTextLink from './IconTextLink';
import { RefreshCcw } from 'react-native-feather';

const MOBILE_HERO_HEIGHT = 290;
export const NOT_MOBILE_WIDTH = 850;
const NOT_MOBILE_HEIGHT = 700;

export const SectionTitle = styled(Title)`
  text-align: center;
`;

export const LoadingContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  gap: ${Size.S}px;
`;

export const CenteredIndicator = styled(ActivityIndicator).attrs({ size: 'large' })`
  flex: 1;
`;

export const FormContainer = styled.View<{ isNarrow: boolean }>`
  padding: ${Size.M}px;
  align-self: center;
  ${(isNarrow) => (!isNarrow ? `padding-top: ${Size.S}px` : '')};
  margin-bottom: ${Size.X4_S}px;
  width: 100%;
  max-width: ${MOBILE_WIDTH_MAX}px;
  margin-horizontal: auto;
  flex-grow: 1;
`;

export const ContentContainer = styled.SafeAreaView<{ isImageShowing: boolean; isNarrow: boolean }>`
  ${({ isNarrow }) => (isNarrow ? 'flex-grow: 1;' : 'flex: 1')}
  justify-content: space-between;
`;

export const HeroImageContainer = styled.Image.attrs({ resizeMode: 'cover' })<{
  isNarrow: boolean;
}>`
  opacity: ${({ theme }) => (theme.dark ? 0.75 : 1)};
  ${({ isNarrow, theme }) =>
    isNarrow
      ? css`
          width: ${theme.viewMode.windowWidth}px;
          height: ${MOBILE_HERO_HEIGHT}px;
        `
      : css`
          width: ${MOBILE_WIDTH_SMALL}px;
          height: 100%;
        `}
`;

export const FootNoteContainer = styled.View`
  padding-horizontal: ${Size.M}px;
  padding-bottom: ${Size.M}px;
`;

export const InnerContainer = styled.View<{ isNarrow: boolean }>`
  background-color: ${({ theme }) => colorShade(theme.colors.primary, 0.8)};
  ${({ isNarrow }) =>
    isNarrow
      ? css`
          flex-direction: column;
          flex: 1;
        `
      : css`
          flex-direction: row-reverse;
          align-self: center;
          width: ${NOT_MOBILE_WIDTH}px;
          height: ${NOT_MOBILE_HEIGHT}px;
          overflow: hidden;
          border-radius: ${Size.M}px;
        `}
`;

export const Container = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'center'
  }
}))`
  flex: 1;
`;

export const PrimaryColorBackground = styled.View`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  opacity: 0.4;
`;

export const Link = styled(Caption)`
  color: ${({ theme }) => theme.colors.onPrimary};
  text-decoration-line: underline;
  font-weight: ${Weight.MEDIUM};
  letter-spacing: ${Size.X5_S}px;
`;

export const DownloadOurAppContainer = styled.View`
  margin-top: ${Size.S}px;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
  padding: ${Size.X2_S}px;
  gap: ${Size.XS}px;
  max-width: ${({ theme }) => theme.viewMode.maxContentWidth}px;
`;

export const StyledRow = styled(Row)`
  gap: ${Size.X4_L}px;
`;

export const AppStoreBadgeContainer = styled.View`
  padding: 9px;
`;

export const AppStoreContentInnerContainer = styled.View`
  align-items: center;
`;

export const AppStoreContentContainer = styled(Row)<{ isNarrow: boolean }>`
  gap: ${({ isNarrow }) => (isNarrow ? Size.M : Size.X4_L)}px;
`;

export const ErrorCaption = styled(Caption)`
  color: ${palette.AB_BRIGHT_WHITE};
  font-weight: ${Weight.MEDIUM};
`;

export const DebugContainer = styled.View`
  padding-horizontal: ${Size.M}px;
  padding-bottom: ${Size.M}px;
  flex-direction: row;
  justify-content: space-between;
`;

export const RefreshTextLink = styled(IconTextLink).attrs({
  onPress: reloadApp,
  icon: RefreshCcw
})`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.onPrimary};
  font-weight: ${Weight.MEDIUM};
  letter-spacing: 0.5px;
  align-self: flex-end;
`;
