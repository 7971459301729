import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { layout } from 'src/theme/globalStyles';
import ChangePassword from '../ChangePassword/ChangePassword';
import CChangeLanguage from './CChangeLanguage';
import { ActivityIndicator } from 'react-native-paper';
import { style, StyledSettingsView } from './style';
import {
  ManageAccountSection,
  DeleteAccountConfirmationModal,
  useControlConfirmationModal
} from './ManageAccount';
import { StyledSectionHeader, Subheading, TextInput } from 'src/components';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useIsMutating } from 'react-query';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Margin, VERSION_INFO } from 'src/constants';
import BiometricAuth from './BiometricAuth';
import useBiometrics from 'src/hooks/useBiometrics';

const ProfileAppSettings: React.FC = () => {
  const { t, i18n } = useTranslation('settings');
  const [language, setLanguage] = useState(i18n.language);
  const { colors } = useAppTheme();

  const { canUseBiometrics } = useBiometrics();

  const isMutating = useIsMutating();

  useEffect(() => {
    if (i18n.language !== language) {
      void i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  const controlConfirmationModal = useControlConfirmationModal();

  return (
    <StyledSettingsView>
      <KeyboardAwareScrollView>
        {isMutating > 0 && (
          <ActivityIndicator
            style={[layout.activityIndicator, style.z99]}
            animating={isMutating > 0}
            size='large'
            color={colors.primary}
          />
        )}
        <StyledSectionHeader>
          <Subheading>{t('changePassword')}</Subheading>
        </StyledSectionHeader>

        <DeleteAccountConfirmationModal {...controlConfirmationModal} />
        <ChangePassword />
        {canUseBiometrics && <BiometricAuth />}
        <CChangeLanguage language={language} changeLanguage={setLanguage} />
        <StyledSectionHeader>
          <Subheading>{t('appVersion')}</Subheading>
        </StyledSectionHeader>
        <TextInput
          style={{ marginHorizontal: Margin.Small }}
          mode='outlined'
          disabled
          value={VERSION_INFO}
        />
        <ManageAccountSection {...controlConfirmationModal} />
      </KeyboardAwareScrollView>
    </StyledSettingsView>
  );
};

export default ProfileAppSettings;
