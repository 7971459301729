import React from 'react';
import { Row } from 'src/components';
import { Printer, ThumbsDown, ThumbsUp } from 'react-native-feather';
import { StyledFAB } from './shared';
import styled from 'styled-components/native';
import { Size } from 'src/constants';
import { StyleProp, ViewStyle } from 'react-native';

interface Props {
  print: () => void;
  rate: (isThumbsUp: boolean) => Promise<void>;
  ratingButtonsDisabled: boolean;
  style?: StyleProp<ViewStyle>;
}

const ActionButtons: React.FC<Props> = ({ print, rate, ratingButtonsDisabled, style }) => {
  return (
    <StyledRow style={style}>
      <StyledFAB
        icon={({ color, size }) => <Printer color={color} width={size} height={size} />}
        onPress={print}
      />
      <StyledFAB
        icon={({ color, size }) => <ThumbsUp color={color} width={size} height={size} />}
        onPress={async () => rate(true)}
        disabled={ratingButtonsDisabled}
      />
      <StyledFAB
        icon={({ color, size }) => <ThumbsDown color={color} width={size} height={size} />}
        onPress={async () => rate(false)}
        disabled={ratingButtonsDisabled}
      />
    </StyledRow>
  );
};

export default ActionButtons;

const StyledRow = styled(Row)`
  gap: ${Size.X2_S}px;
  justify-content: flex-end;
`;
