import React from 'react';
import { Text } from 'react-native-paper';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

interface Props {
  label: string;
}

const RouteLabel: React.FC<Props> = ({ label }) => {
  return <StyledTabLabel>{label}</StyledTabLabel>;
};

export default RouteLabel;

const StyledTabLabel = styled(Text)`
  margin-horizontal: -${Size.X2_S}px;
  color: ${({ theme }) => theme.colors.onPrimary};
  text-align: center;
  font-size: ${Size.XS};
`;
