import React from 'react';
import { ActivityIndicator } from 'react-native-paper';

import styled from 'styled-components/native';
import AnimalIcon from './Icons/Animal';

interface Props {
  color: string;
  size: number;
  species?: string;
}

const AnimalLoader: React.FC<Props> = ({ color, size, species }) => {
  return (
    <AnimalContainer>
      <ActivityIndicator color={color} size={size} />
      <IconContainer>
        <AnimalIcon species={species} color={color} size={size * 0.6} />
      </IconContainer>
    </AnimalContainer>
  );
};

export default AnimalLoader;

const AnimalContainer = styled.View`
  align-items: center;
  align-self: center;
`;

const IconContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
