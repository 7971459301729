import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import navLocations from './navLocations';
import IonIcon from 'react-native-vector-icons/Ionicons';
import { Menu } from 'react-native-paper';
import { IS_WEB, IconSize, Margin, STROKE_WIDTH_DEFAULT, Size } from 'src/constants/general';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/providers/AuthProvider';
import style from './style';
import { isDefined, openWebBrowser } from 'src/utils';
import { layout } from 'src/theme/globalStyles';
import { Body, HoverCard, StyledKeyboardAwareScrollView } from 'src/components';
import CPrimaryButton from 'src/components/CPrimaryButton';
import styled from 'styled-components/native';
import { useUser } from 'src/providers/ClientProvider';
import { useNotification } from 'src/providers/NotificationProvider';
import { Edit } from 'react-native-feather';
import { useUploadProfilePhoto } from 'src/hooks/react-query';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Screens } from 'src/routes/stacks/screens';
import _ from 'lodash';
import { useBoolean } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, SliderElement, SLIDES, STEPS, TourGuide } from './tour';
import ClientAvatar from 'src/components/ClientAvatar';

const PROFILE_IMAGE_PREVIEW_SIZE = 150;

const ProfileScreen: React.FC<
  NativeStackScreenProps<HomeStackParamsList, Screens.PROFILE_ROOT>
> = ({ navigation }) => {
  const { t } = useTranslation('profileRoot');
  const { logOut } = useAuth();
  const { user } = useUser();
  const { colors } = useAppTheme();
  const { openNotificationSettings: openNotificationsSettings } = useNotification();

  useEffect(() => {
    navigation.setOptions({
      title: t('clientProfile'),
      headerTitle: t('greeting', {
        firstName: user.firstName
      })
    });
  }, [navigation, t, user.firstName]);

  const { uploadPhoto, deletePhoto } = useUploadProfilePhoto({
    manipulate: [{ resize: { width: PROFILE_IMAGE_PREVIEW_SIZE } }]
  });

  const { value: isOpen, toTrue: openMenu, toFalse: closeMenu } = useBoolean();

  const onPressDeletePhoto = useMemo(
    () =>
      _.debounce(() => {
        closeMenu();
        deletePhoto();
      }),
    [closeMenu, deletePhoto]
  );

  const onPressUploadPhoto = useMemo(
    () =>
      _.debounce((camera: boolean) => {
        closeMenu();
        void uploadPhoto(camera);
      }),
    [closeMenu, uploadPhoto]
  );

  return (
    <TourGuide.Provider>
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
      <TourGuide.Consumer>
        {({ exitTour }) => (
          <>
            <StyledKeyboardAwareScrollView>
              <ProfileImageContainer>
                <StyledUserAvatar />
                <GuideElement id={STEPS.PHOTO} body={t('profileRoot:tour.photo')} autoStart>
                  <StyledMenuContainer>
                    <Menu
                      visible={isOpen}
                      onDismiss={closeMenu}
                      anchor={
                        <FilledIconContainer onPress={openMenu}>
                          <Edit
                            width={IconSize.M}
                            color={colors.onPrimary}
                            strokeWidth={STROKE_WIDTH_DEFAULT}
                          />
                        </FilledIconContainer>
                      }
                    >
                      <Menu.Item
                        leadingIcon='image'
                        title={t('photos', { ns: 'common' })}
                        onPress={() => onPressUploadPhoto(false)}
                      />
                      {!IS_WEB && (
                        <Menu.Item
                          leadingIcon='camera'
                          title={t('camera', { ns: 'common' })}
                          onPress={() => onPressUploadPhoto(true)}
                        />
                      )}
                      {user?.hasPhoto && (
                        <Menu.Item
                          leadingIcon='delete'
                          title={t('delete', { ns: 'common' })}
                          onPress={() => onPressDeletePhoto()}
                        />
                      )}
                    </Menu>
                  </StyledMenuContainer>
                </GuideElement>
              </ProfileImageContainer>
              {navLocations(user).map(
                ({
                  label,
                  icon,
                  location,
                  requiresAttention,
                  step,
                  tourDescription,
                  isLastStep
                }) => (
                  <GuideElement
                    id={step}
                    body={t(tourDescription)}
                    key={label}
                    onContinue={isLastStep ? exitTour : undefined}
                  >
                    <StyledHoverCard
                      hideShadow
                      onPress={() => {
                        if (isDefined(location.match('^http(s)?://'))) {
                          void openWebBrowser(location);
                        } else if (isDefined(location.match('^notifications$'))) {
                          openNotificationsSettings();
                        } else {
                          navigation.getParent()?.navigate(location);
                        }
                      }}
                    >
                      <View style={style.navIcon}>{icon(colors.onSurface)}</View>
                      <Body style={layout.flex1} numberOfLines={1} color={colors.onSurface}>
                        {t(label)}
                      </Body>
                      {!!requiresAttention && (
                        <IonIcon name='warning' color={colors.error} style={[style.warnIcon]} />
                      )}
                      <View style={style.navIcon}>
                        <FeatherIcon
                          name={'chevron-right'}
                          size={Size.L}
                          color={colors.onSurface}
                        />
                      </View>
                    </StyledHoverCard>
                  </GuideElement>
                )
              )}
              <FlexedView>
                <CPrimaryButton onPress={async () => logOut(undefined)}>
                  {t('common:logout')}
                </CPrimaryButton>
              </FlexedView>
            </StyledKeyboardAwareScrollView>
          </>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
};

export default ProfileScreen;

const FlexedView = styled.View`
  flex-grow: 1;
  justify-content: flex-end;
  padding-vertical: ${Margin.Large}px;
  padding-horizontal: ${Size.X2_S}px;
`;

const ProfileImageContainer = styled.View`
  align-self: center;
  margin-bottom: ${Margin.Medium}px;
`;

const StyledHoverCard = styled(HoverCard)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Margin.Small}px;
  margin-horizontal: ${Margin.Small}px;
`;

const FilledIconContainer = styled(HoverCard)`
  width: ${Size.X2_L}px;
  height: ${Size.X2_L}px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: ${Margin.Small}px;
`;

export const StyledMenuContainer = styled.View`
  position: absolute;
  bottom: ${Margin.Medium}px;
  right: ${Margin.Medium}px;
`;

const StyledUserAvatar = styled(ClientAvatar).attrs({ size: PROFILE_IMAGE_PREVIEW_SIZE })`
  border-radius: ${PROFILE_IMAGE_PREVIEW_SIZE / 2}px;
  margin: ${Margin.Large}px;
`;
