import { AxiosError } from 'axios';
import { t } from 'i18next';
import { isEqual } from 'lodash';
import React from 'react';
import { Trans } from 'react-i18next';
import { Mail, RefreshCcw } from 'react-native-feather';
import Toast from 'react-native-toast-message';
import { QueryKeys } from 'src/api';
import { ALLY_SUPPORT_EMAIL } from 'src/constants';
import { availablePracticesOptions } from 'src/hooks/react-query/useAvailablePractices';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import { openEmail, queryClient, transparent } from 'src/utils';
import openEmailToAlly from 'src/utils/openEmailToAlly';
import useResendConfirmationEmail from '../helpers/useResendConfirmationEmail';
import IconTextLink from './IconTextLink';
import { ErrorCaption } from './style';

interface Props {
  selectedPractice?: AvailablePractice;
  error: string;
  email: string;
}
const ErrorMessage: React.FC<Props> = ({ selectedPractice, error, email }) => {
  const { colors } = useAppTheme();
  const { sendConfirmationEmail, isSending } = useResendConfirmationEmail(email, selectedPractice);

  return (
    <Trans
      i18nKey={error}
      values={{
        ourEmail: ALLY_SUPPORT_EMAIL,
        ...selectedPractice
      }}
      components={{
        contactUs: (
          <ErrorCaption
            style={{ textDecorationLine: 'underline' }}
            onPress={async () => await openEmailToAlly()}
          />
        ),
        contactPractice: (
          <ErrorCaption
            style={{ textDecorationLine: 'underline' }}
            onPress={async () => await openEmail(selectedPractice?.practiceEmail)}
          />
        ),
        resendConfirmation: (
          <IconTextLink
            style={{
              textDecorationLine: 'underline',
              color: isSending ? transparent(colors.error, 0.7) : colors.error,
              fontWeight: Weight.MEDIUM,
              letterSpacing: 0.5
            }}
            disabled={isSending}
            icon={(props) => <Mail {...props} />}
            onPress={async () => sendConfirmationEmail()}
          />
        ),
        refresh: (
          <IconTextLink
            style={{
              textDecorationLine: 'underline',
              color: colors.error,
              fontWeight: Weight.MEDIUM,
              letterSpacing: 0.5
            }}
            icon={(props) => <RefreshCcw {...props} />}
            onPress={async () => refreshData(email)}
          />
        )
      }}
      ns='login'
    >
      {error}
    </Trans>
  );
};

export default ErrorMessage;

const refreshData = async (email: string) => {
  try {
    const prevData = await queryClient.getQueryData<AvailablePractice[]>([
      QueryKeys.CHECK_EMAIL,
      email.toLowerCase()
    ]);
    const nextData = await queryClient.fetchQuery(availablePracticesOptions(email.toLowerCase()));
    if (isEqual(prevData, nextData)) {
      Toast.show({
        text1: t('noNewDataTitle', { ns: 'login' }),
        text2: t('noNewData', { ns: 'login' }),
        type: 'error'
      });
    } else {
      Toast.show({
        text1: t('newDataTitle', { ns: 'login' }),
        text2: t('newData', { ns: 'login' })
      });
    }
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === 404) {
      Toast.show({
        text1: t('noAccountTitle', { ns: 'login' }),
        text2: t('noAccount', { email, ns: 'login' }),
        type: 'error'
      });
    }
  }
};
