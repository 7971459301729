import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const BottomClip: React.FC = () => {
  const { colors, viewMode } = useAppTheme();
  return (
    <Svg
      width={viewMode.windowWidth}
      height={24}
      color={colors.primary}
      viewBox={'0 0 390 24'}
      preserveAspectRatio='none'
    >
      <Path
        fill={colors.primary}
        fillRule='evenodd'
        d='M0 24V2.605C23.216 14.933 101.5 24 194.5 24H0Zm194.5 0c94.389 0 173.616-9.34 195.5-21.95V24H194.5Z'
      />
    </Svg>
  );
};
export default BottomClip;
