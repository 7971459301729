import moment from 'moment-timezone';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, StyleProp, ViewStyle } from 'react-native';
import { Map } from 'react-native-feather';
import { Alert, AlertHandle, Body, HoverButton } from 'src/components';
import { AlertOption } from 'src/components/Alert';
import { CalendarConfirmed, CalendarPending } from 'src/components/Icons';
import { IconSize, Margin } from 'src/constants';
import { useAppointmentConfirmation, usePractice } from 'src/hooks/react-query';
import { Appointment } from 'src/interfaces';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import { openMaps, simpleAddressFormatter } from 'src/utils';
import styled from 'styled-components/native';

interface Props {
  appointment?: Appointment;
  style?: StyleProp<ViewStyle>;
  isSoon: boolean;
  isExpired: boolean;
  canConfirm: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const AppointmentStatusCta: React.FC<Props> = ({
  appointment,
  style,
  isSoon,
  isExpired,
  canConfirm,
  onLayout
}) => {
  const { colors } = useAppTheme();
  const { t, i18n } = useTranslation('appointments');
  const { data: practice } = usePractice();

  const { confirmAppointment, isConfirming } = useAppointmentConfirmation(
    appointment?.appointmentId
  );

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        label: t('cancel'),
        type: 'neutral'
      },

      {
        action: confirmAppointment,
        label: t('common:confirm')
      }
    ],
    [confirmAppointment, t]
  );

  if (!appointment || isExpired) return null;

  if (isSoon) {
    return (
      <Row onLayout={onLayout}>
        <StyledButton
          onPress={() => {
            void openMaps(simpleAddressFormatter(practice), practice?.name ?? '');
          }}
          buttonColor={colors.confirmation}
          icon={(props) => <Map {...props} width={IconSize.XS} height={IconSize.XS} />}
          style={style}
        >
          {t('getDirections')}
        </StyledButton>
      </Row>
    );
  }

  const openModal = () => confirmModalRef.current?.alert();

  if (canConfirm) {
    return (
      <Row>
        <StyledButton
          onLayout={onLayout}
          disabled={isConfirming}
          onPress={openModal}
          buttonColor={colors.warn}
          icon={(props) => <CalendarPending {...props} size={IconSize.XS} />}
          style={style}
        >
          {t('confirm')}
        </StyledButton>
        <Alert
          title={t('confirmYourAppointment')}
          body={t('confirmAppointmentDatePatient', {
            patientName: appointment.patientName,
            date: moment(appointment.startsAt).toDate().toLocaleString(i18n.language, {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          })}
          ref={confirmModalRef}
          options={confirmOptions}
        />
      </Row>
    );
  }

  if (appointment.confirmed) {
    return (
      <Row style={style} onLayout={onLayout}>
        <StyledBody color={colors.confirmation}>{t('confirmed')}</StyledBody>
        <CalendarConfirmed color={colors.confirmation} size={IconSize.XS} />
      </Row>
    );
  }

  if (appointment.confirmedAt) {
    return (
      <Row style={style} onLayout={onLayout}>
        <StyledBody color={colors.warn}>{t('confirming')}</StyledBody>
        <CalendarConfirmed color={colors.warn} size={IconSize.XS} />
      </Row>
    );
  }

  return (
    <Row style={style} onLayout={onLayout}>
      <StyledBody color={colors.warn}>{t('scheduled')}</StyledBody>
      <CalendarPending color={colors.warn} size={IconSize.XS} />
    </Row>
  );
};

export default AppointmentStatusCta;

const StyledButton = styled(HoverButton).attrs({
  mode: 'contained',
  uppercase: false,
  numberOfLines: 1,
  compact: true
})`
  flex-direction: row-reverse;
  padding: ${Margin.Medium}px ${Margin.Large}px;
  height: auto;
  flex-shrink: 1;
`;

const StyledBody = styled(Body).attrs({ numberOfLines: 1 })`
  font-weight: ${Weight.MEDIUM};
`;

const Row = styled.View`
  flex-direction: row;
  gap: ${Margin.Medium}px;
`;
