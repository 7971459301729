import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { getPrescriptions, QueryKeys } from 'src/api';
import { Patient, Prescription } from 'src/interfaces';

const PLACEHOLDER: Prescription[] = [];
interface Options extends UseQueryOptions<Prescription[], unknown, Prescription[], QueryKeys[]> {
  selectedPatientId?: Patient['patientId'];
}

export const prescriptionsOptions: UseQueryOptions<
  Prescription[],
  unknown,
  Prescription[],
  QueryKeys[]
> = {
  queryKey: [QueryKeys.PRESCRIPTIONS],
  queryFn: getPrescriptions,
  select: (prescriptions) => _.orderBy(prescriptions, 'prescribedAt', 'desc'),
  suspense: true
};

const usePrescriptions = ({ selectedPatientId, ...options }: Options = {}) => {
  const { data = PLACEHOLDER, ...rest } = useQuery({
    ...prescriptionsOptions,
    ...options
  });

  const prescriptions = useMemo(() => {
    return selectedPatientId
      ? data.filter(({ patientId }) => patientId === selectedPatientId)
      : data;
  }, [data, selectedPatientId]);

  return {
    data: prescriptions,
    ...rest
  };
};

export default usePrescriptions;
