import React, { PropsWithChildren, ErrorInfo } from 'react';
import { APP_ENV } from 'src/constants';
import { Title } from './Text';
import Sentry from 'src/utils/sentry';

interface State {
  hasError: boolean;
  eventId?: string;
}

export interface FallBackProps {
  resetError: () => void;
  eventId: string;
}
interface Props extends PropsWithChildren {
  Fallback?: React.FC<FallBackProps>;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    if (error) {
      return { hasError: true };
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const eventId = Sentry.captureException(error);
    if (APP_ENV !== 'PROD') {
      console.error(error, errorInfo);
    }
    this.props.onError?.(error, errorInfo);
    this.setState({ hasError: true, eventId });
  }

  render() {
    if (this.state.hasError && this.state.eventId) {
      if (this.props.Fallback) {
        const Fallback = this.props.Fallback;
        return (
          <Fallback
            eventId={this.state.eventId}
            resetError={() => this.setState({ hasError: false })}
          />
        );
      }
      return <Title>Something went wrong.</Title>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
