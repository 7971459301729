import { endpoint } from 'src/constants';
import Reward from 'src/interfaces/api/Reward';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getRewards = async (): Promise<Reward[]> => {
  const response = await apiClient.get<Reward[]>(endpoint('REWARDS'));
  return isDefinedOrThrowServerError(response.data, Array.isArray);
};

export const getReward = async (id: number): Promise<Reward> => {
  const response = await apiClient.get<Reward>(endpoint('GET_REWARD', { id }));
  return isDefinedOrThrowServerError(response.data);
};
