import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

interface Props {
  size: number;
}

const QRPlayStore: React.FC<Props> = ({ size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 128 128' fill='none'>
      <G clipPath='url(#clip0_61_2)'>
        <Rect width='128' height='128' rx='16' fill='white' />
        <Path d='M128 0H0V128H128V0Z' fill='white' />
        <Path d='M11.027 8H8V11.027H11.027V8Z' fill='black' />
        <Path d='M14.0541 8H11.027V11.027H14.0541V8Z' fill='black' />
        <Path d='M17.0811 8H14.0541V11.027H17.0811V8Z' fill='black' />
        <Path d='M20.1081 8H17.0811V11.027H20.1081V8Z' fill='black' />
        <Path d='M23.1351 8H20.1081V11.027H23.1351V8Z' fill='black' />
        <Path d='M26.1622 8H23.1351V11.027H26.1622V8Z' fill='black' />
        <Path d='M29.1892 8H26.1622V11.027H29.1892V8Z' fill='black' />
        <Path d='M35.2432 8H32.2162V11.027H35.2432V8Z' fill='black' />
        <Path d='M41.2973 8H38.2703V11.027H41.2973V8Z' fill='black' />
        <Path d='M50.3784 8H47.3514V11.027H50.3784V8Z' fill='black' />
        <Path d='M56.4324 8H53.4054V11.027H56.4324V8Z' fill='black' />
        <Path d='M65.5135 8H62.4865V11.027H65.5135V8Z' fill='black' />
        <Path d='M74.5946 8H71.5676V11.027H74.5946V8Z' fill='black' />
        <Path d='M80.6486 8H77.6216V11.027H80.6486V8Z' fill='black' />
        <Path d='M86.7027 8H83.6757V11.027H86.7027V8Z' fill='black' />
        <Path d='M89.7297 8H86.7027V11.027H89.7297V8Z' fill='black' />
        <Path d='M92.7568 8H89.7297V11.027H92.7568V8Z' fill='black' />
        <Path d='M95.7838 8H92.7568V11.027H95.7838V8Z' fill='black' />
        <Path d='M101.838 8H98.8108V11.027H101.838V8Z' fill='black' />
        <Path d='M104.865 8H101.838V11.027H104.865V8Z' fill='black' />
        <Path d='M107.892 8H104.865V11.027H107.892V8Z' fill='black' />
        <Path d='M110.919 8H107.892V11.027H110.919V8Z' fill='black' />
        <Path d='M113.946 8H110.919V11.027H113.946V8Z' fill='black' />
        <Path d='M116.973 8H113.946V11.027H116.973V8Z' fill='black' />
        <Path d='M120 8H116.973V11.027H120V8Z' fill='black' />
        <Path d='M11.027 11.027H8V14.0541H11.027V11.027Z' fill='black' />
        <Path d='M29.1892 11.027H26.1622V14.0541H29.1892V11.027Z' fill='black' />
        <Path d='M41.2973 11.027H38.2703V14.0541H41.2973V11.027Z' fill='black' />
        <Path d='M47.3514 11.027H44.3243V14.0541H47.3514L47.3514 11.027Z' fill='black' />
        <Path d='M50.3784 11.027H47.3514L47.3514 14.0541H50.3784V11.027Z' fill='black' />
        <Path d='M65.5135 11.027H62.4865V14.0541H65.5135V11.027Z' fill='black' />
        <Path d='M68.5405 11.027H65.5135V14.0541H68.5405V11.027Z' fill='black' />
        <Path d='M71.5676 11.027H68.5405V14.0541H71.5676V11.027Z' fill='black' />
        <Path d='M74.5946 11.027H71.5676V14.0541H74.5946V11.027Z' fill='black' />
        <Path d='M77.6216 11.027H74.5946V14.0541H77.6216V11.027Z' fill='black' />
        <Path d='M80.6486 11.027H77.6216V14.0541H80.6486V11.027Z' fill='black' />
        <Path d='M83.6757 11.027H80.6486V14.0541H83.6757V11.027Z' fill='black' />
        <Path d='M86.7027 11.027H83.6757V14.0541H86.7027V11.027Z' fill='black' />
        <Path d='M89.7297 11.027H86.7027V14.0541H89.7297V11.027Z' fill='black' />
        <Path d='M101.838 11.027H98.8108V14.0541H101.838V11.027Z' fill='black' />
        <Path d='M120 11.027H116.973V14.0541H120V11.027Z' fill='black' />
        <Path d='M11.027 14.0541H8V17.0811H11.027V14.0541Z' fill='black' />
        <Path d='M17.0811 14.0541H14.0541V17.0811H17.0811V14.0541Z' fill='black' />
        <Path d='M20.1081 14.0541H17.0811V17.0811H20.1081V14.0541Z' fill='black' />
        <Path d='M23.1351 14.0541H20.1081V17.0811H23.1351V14.0541Z' fill='black' />
        <Path d='M29.1892 14.0541H26.1622V17.0811H29.1892V14.0541Z' fill='black' />
        <Path d='M38.2703 14.0541H35.2432V17.0811H38.2703V14.0541Z' fill='black' />
        <Path d='M50.3784 14.0541H47.3514L47.3514 17.0811H50.3784V14.0541Z' fill='black' />
        <Path d='M53.4054 14.0541H50.3784V17.0811H53.4054V14.0541Z' fill='black' />
        <Path d='M56.4324 14.0541H53.4054V17.0811H56.4324V14.0541Z' fill='black' />
        <Path d='M62.4865 14.0541H59.4595V17.0811H62.4865V14.0541Z' fill='black' />
        <Path d='M68.5405 14.0541H65.5135V17.0811H68.5405V14.0541Z' fill='black' />
        <Path d='M71.5676 14.0541H68.5405V17.0811H71.5676V14.0541Z' fill='black' />
        <Path d='M74.5946 14.0541H71.5676V17.0811H74.5946V14.0541Z' fill='black' />
        <Path d='M77.6216 14.0541H74.5946V17.0811H77.6216V14.0541Z' fill='black' />
        <Path d='M83.6757 14.0541H80.6486V17.0811H83.6757V14.0541Z' fill='black' />
        <Path d='M89.7297 14.0541H86.7027V17.0811H89.7297V14.0541Z' fill='black' />
        <Path d='M92.7568 14.0541H89.7297V17.0811H92.7568V14.0541Z' fill='black' />
        <Path d='M101.838 14.0541H98.8108V17.0811H101.838V14.0541Z' fill='black' />
        <Path d='M107.892 14.0541H104.865V17.0811H107.892V14.0541Z' fill='black' />
        <Path d='M110.919 14.0541H107.892V17.0811H110.919V14.0541Z' fill='black' />
        <Path d='M113.946 14.0541H110.919V17.0811H113.946V14.0541Z' fill='black' />
        <Path d='M120 14.0541H116.973V17.0811H120V14.0541Z' fill='black' />
        <Path d='M11.027 17.0811H8V20.1081H11.027V17.0811Z' fill='black' />
        <Path d='M17.0811 17.0811H14.0541V20.1081H17.0811V17.0811Z' fill='black' />
        <Path d='M20.1081 17.0811H17.0811V20.1081H20.1081V17.0811Z' fill='black' />
        <Path d='M23.1351 17.0811H20.1081V20.1081H23.1351V17.0811Z' fill='black' />
        <Path d='M29.1892 17.0811H26.1622V20.1081H29.1892V17.0811Z' fill='black' />
        <Path d='M35.2432 17.0811H32.2162V20.1081H35.2432V17.0811Z' fill='black' />
        <Path d='M38.2703 17.0811H35.2432V20.1081H38.2703V17.0811Z' fill='black' />
        <Path d='M50.3784 17.0811H47.3514V20.1081H50.3784V17.0811Z' fill='black' />
        <Path d='M59.4595 17.0811H56.4324L56.4324 20.1081H59.4595L59.4595 17.0811Z' fill='black' />
        <Path d='M62.4865 17.0811H59.4595L59.4595 20.1081H62.4865V17.0811Z' fill='black' />
        <Path d='M65.5135 17.0811H62.4865V20.1081H65.5135V17.0811Z' fill='black' />
        <Path d='M83.6757 17.0811H80.6486V20.1081H83.6757V17.0811Z' fill='black' />
        <Path d='M92.7568 17.0811H89.7297V20.1081H92.7568V17.0811Z' fill='black' />
        <Path d='M101.838 17.0811H98.8108V20.1081H101.838V17.0811Z' fill='black' />
        <Path d='M107.892 17.0811H104.865V20.1081H107.892V17.0811Z' fill='black' />
        <Path d='M110.919 17.0811H107.892V20.1081H110.919V17.0811Z' fill='black' />
        <Path d='M113.946 17.0811H110.919V20.1081H113.946V17.0811Z' fill='black' />
        <Path d='M120 17.0811H116.973V20.1081H120V17.0811Z' fill='black' />
        <Path d='M11.027 20.1081H8V23.1351H11.027V20.1081Z' fill='black' />
        <Path d='M17.0811 20.1081H14.0541V23.1351H17.0811V20.1081Z' fill='black' />
        <Path d='M20.1081 20.1081H17.0811V23.1351H20.1081V20.1081Z' fill='black' />
        <Path d='M23.1351 20.1081H20.1081V23.1351H23.1351V20.1081Z' fill='black' />
        <Path d='M29.1892 20.1081H26.1622V23.1351H29.1892V20.1081Z' fill='black' />
        <Path d='M35.2432 20.1081H32.2162V23.1351H35.2432V20.1081Z' fill='black' />
        <Path d='M41.2973 20.1081H38.2703V23.1351H41.2973V20.1081Z' fill='black' />
        <Path d='M47.3514 20.1081H44.3243V23.1351H47.3514L47.3514 20.1081Z' fill='black' />
        <Path d='M56.4324 20.1081H53.4054V23.1351H56.4324L56.4324 20.1081Z' fill='black' />
        <Path d='M59.4595 20.1081H56.4324L56.4324 23.1351H59.4595V20.1081Z' fill='black' />
        <Path d='M65.5135 20.1081H62.4865V23.1351H65.5135V20.1081Z' fill='black' />
        <Path d='M71.5676 20.1081H68.5405V23.1351H71.5676V20.1081Z' fill='black' />
        <Path d='M77.6216 20.1081H74.5946V23.1351H77.6216V20.1081Z' fill='black' />
        <Path d='M83.6757 20.1081H80.6486V23.1351H83.6757V20.1081Z' fill='black' />
        <Path d='M92.7568 20.1081H89.7297V23.1351H92.7568V20.1081Z' fill='black' />
        <Path d='M101.838 20.1081H98.8108V23.1351H101.838V20.1081Z' fill='black' />
        <Path d='M107.892 20.1081H104.865V23.1351H107.892V20.1081Z' fill='black' />
        <Path d='M110.919 20.1081H107.892V23.1351H110.919V20.1081Z' fill='black' />
        <Path d='M113.946 20.1081H110.919V23.1351H113.946V20.1081Z' fill='black' />
        <Path d='M120 20.1081H116.973V23.1351H120V20.1081Z' fill='black' />
        <Path d='M11.027 23.1351H8V26.1622H11.027V23.1351Z' fill='black' />
        <Path d='M29.1892 23.1351H26.1622V26.1622H29.1892V23.1351Z' fill='black' />
        <Path d='M35.2432 23.1351H32.2162V26.1622H35.2432V23.1351Z' fill='black' />
        <Path d='M62.4865 23.1351H59.4595L59.4595 26.1622H62.4865V23.1351Z' fill='black' />
        <Path d='M65.5135 23.1351H62.4865V26.1622H65.5135V23.1351Z' fill='black' />
        <Path d='M68.5405 23.1351H65.5135V26.1622H68.5405V23.1351Z' fill='black' />
        <Path d='M74.5946 23.1351H71.5676V26.1622H74.5946V23.1351Z' fill='black' />
        <Path d='M86.7027 23.1351H83.6757V26.1622H86.7027V23.1351Z' fill='black' />
        <Path d='M89.7297 23.1351H86.7027V26.1622H89.7297V23.1351Z' fill='black' />
        <Path d='M101.838 23.1351H98.8108V26.1622H101.838V23.1351Z' fill='black' />
        <Path d='M120 23.1351H116.973V26.1622H120V23.1351Z' fill='black' />
        <Path d='M11.027 26.1622H8V29.1892H11.027V26.1622Z' fill='black' />
        <Path d='M14.0541 26.1622H11.027V29.1892H14.0541V26.1622Z' fill='black' />
        <Path d='M17.0811 26.1622H14.0541V29.1892H17.0811V26.1622Z' fill='black' />
        <Path d='M20.1081 26.1622H17.0811V29.1892H20.1081V26.1622Z' fill='black' />
        <Path d='M23.1351 26.1622H20.1081V29.1892H23.1351V26.1622Z' fill='black' />
        <Path d='M26.1622 26.1622H23.1351V29.1892H26.1622V26.1622Z' fill='black' />
        <Path d='M29.1892 26.1622H26.1622V29.1892H29.1892V26.1622Z' fill='black' />
        <Path d='M35.2432 26.1622H32.2162V29.1892H35.2432V26.1622Z' fill='black' />
        <Path d='M41.2973 26.1622H38.2703V29.1892H41.2973V26.1622Z' fill='black' />
        <Path d='M47.3514 26.1622H44.3243V29.1892H47.3514V26.1622Z' fill='black' />
        <Path d='M53.4054 26.1622H50.3784V29.1892H53.4054V26.1622Z' fill='black' />
        <Path d='M59.4595 26.1622H56.4324V29.1892H59.4595L59.4595 26.1622Z' fill='black' />
        <Path d='M65.5135 26.1622H62.4865V29.1892H65.5135V26.1622Z' fill='black' />
        <Path d='M71.5676 26.1622H68.5405V29.1892H71.5676V26.1622Z' fill='black' />
        <Path d='M77.6216 26.1622H74.5946V29.1892H77.6216V26.1622Z' fill='black' />
        <Path d='M83.6757 26.1622H80.6486V29.1892H83.6757V26.1622Z' fill='black' />
        <Path d='M89.7297 26.1622H86.7027V29.1892H89.7297V26.1622Z' fill='black' />
        <Path d='M95.7838 26.1622H92.7568V29.1892H95.7838V26.1622Z' fill='black' />
        <Path d='M101.838 26.1622H98.8108V29.1892H101.838V26.1622Z' fill='black' />
        <Path d='M104.865 26.1622H101.838V29.1892H104.865V26.1622Z' fill='black' />
        <Path d='M107.892 26.1622H104.865V29.1892H107.892V26.1622Z' fill='black' />
        <Path d='M110.919 26.1622H107.892V29.1892H110.919V26.1622Z' fill='black' />
        <Path d='M113.946 26.1622H110.919V29.1892H113.946V26.1622Z' fill='black' />
        <Path d='M116.973 26.1622H113.946V29.1892H116.973V26.1622Z' fill='black' />
        <Path d='M120 26.1622H116.973V29.1892H120V26.1622Z' fill='black' />
        <Path d='M35.2432 29.1892H32.2162V32.2162H35.2432V29.1892Z' fill='black' />
        <Path d='M38.2703 29.1892H35.2432V32.2162H38.2703V29.1892Z' fill='black' />
        <Path d='M41.2973 29.1892H38.2703V32.2162H41.2973V29.1892Z' fill='black' />
        <Path d='M50.3784 29.1892H47.3514L47.3514 32.2162H50.3784V29.1892Z' fill='black' />
        <Path d='M53.4054 29.1892H50.3784V32.2162H53.4054V29.1892Z' fill='black' />
        <Path d='M68.5405 29.1892H65.5135V32.2162H68.5405V29.1892Z' fill='black' />
        <Path d='M71.5676 29.1892H68.5405V32.2162H71.5676V29.1892Z' fill='black' />
        <Path d='M74.5946 29.1892H71.5676V32.2162H74.5946V29.1892Z' fill='black' />
        <Path d='M77.6216 29.1892H74.5946V32.2162H77.6216V29.1892Z' fill='black' />
        <Path d='M86.7027 29.1892H83.6757V32.2162H86.7027V29.1892Z' fill='black' />
        <Path d='M92.7568 29.1892H89.7297V32.2162H92.7568V29.1892Z' fill='black' />
        <Path d='M11.027 32.2162H8V35.2432H11.027V32.2162Z' fill='black' />
        <Path d='M14.0541 32.2162H11.027V35.2432H14.0541V32.2162Z' fill='black' />
        <Path d='M17.0811 32.2162H14.0541V35.2432H17.0811V32.2162Z' fill='black' />
        <Path d='M23.1351 32.2162H20.1081V35.2432H23.1351V32.2162Z' fill='black' />
        <Path d='M29.1892 32.2162H26.1622V35.2432H29.1892V32.2162Z' fill='black' />
        <Path d='M35.2432 32.2162H32.2162V35.2432H35.2432V32.2162Z' fill='black' />
        <Path d='M38.2703 32.2162H35.2432V35.2432H38.2703V32.2162Z' fill='black' />
        <Path d='M47.3514 32.2162H44.3243V35.2432H47.3514L47.3514 32.2162Z' fill='black' />
        <Path d='M53.4054 32.2162H50.3784V35.2432H53.4054V32.2162Z' fill='black' />
        <Path d='M56.4324 32.2162H53.4054V35.2432H56.4324V32.2162Z' fill='black' />
        <Path d='M65.5135 32.2162H62.4865V35.2432H65.5135V32.2162Z' fill='black' />
        <Path d='M68.5405 32.2162H65.5135V35.2432H68.5405V32.2162Z' fill='black' />
        <Path d='M80.6486 32.2162H77.6216V35.2432H80.6486V32.2162Z' fill='black' />
        <Path d='M92.7568 32.2162H89.7297V35.2432H92.7568V32.2162Z' fill='black' />
        <Path d='M95.7838 32.2162H92.7568V35.2432H95.7838V32.2162Z' fill='black' />
        <Path d='M98.8108 32.2162H95.7838V35.2432H98.8108V32.2162Z' fill='black' />
        <Path d='M101.838 32.2162H98.8108V35.2432H101.838V32.2162Z' fill='black' />
        <Path d='M104.865 32.2162H101.838V35.2432H104.865V32.2162Z' fill='black' />
        <Path d='M107.892 32.2162H104.865V35.2432H107.892V32.2162Z' fill='black' />
        <Path d='M110.919 32.2162H107.892V35.2432H110.919V32.2162Z' fill='black' />
        <Path d='M120 32.2162H116.973V35.2432H120V32.2162Z' fill='black' />
        <Path d='M26.1622 35.2432H23.1351V38.2703H26.1622V35.2432Z' fill='black' />
        <Path d='M32.2162 35.2432H29.1892V38.2703H32.2162V35.2432Z' fill='black' />
        <Path d='M44.3243 35.2432H41.2973V38.2703H44.3243L44.3243 35.2432Z' fill='black' />
        <Path d='M50.3784 35.2432H47.3514L47.3514 38.2703H50.3784V35.2432Z' fill='black' />
        <Path d='M56.4324 35.2432H53.4054L53.4054 38.2703H56.4324V35.2432Z' fill='black' />
        <Path d='M65.5135 35.2432H62.4865V38.2703H65.5135V35.2432Z' fill='black' />
        <Path d='M71.5676 35.2432H68.5405V38.2703H71.5676V35.2432Z' fill='black' />
        <Path d='M74.5946 35.2432H71.5676V38.2703H74.5946V35.2432Z' fill='black' />
        <Path d='M77.6216 35.2432H74.5946V38.2703H77.6216V35.2432Z' fill='black' />
        <Path d='M86.7027 35.2432H83.6757V38.2703H86.7027V35.2432Z' fill='black' />
        <Path d='M89.7297 35.2432H86.7027V38.2703H89.7297V35.2432Z' fill='black' />
        <Path d='M95.7838 35.2432H92.7568V38.2703H95.7838V35.2432Z' fill='black' />
        <Path d='M104.865 35.2432H101.838V38.2703H104.865V35.2432Z' fill='black' />
        <Path d='M107.892 35.2432H104.865V38.2703H107.892V35.2432Z' fill='black' />
        <Path d='M110.919 35.2432H107.892V38.2703H110.919V35.2432Z' fill='black' />
        <Path d='M113.946 35.2432H110.919V38.2703H113.946V35.2432Z' fill='black' />
        <Path d='M120 35.2432H116.973V38.2703H120V35.2432Z' fill='black' />
        <Path d='M11.027 38.2703H8V41.2973H11.027V38.2703Z' fill='black' />
        <Path d='M20.1081 38.2703H17.0811V41.2973H20.1081V38.2703Z' fill='black' />
        <Path d='M23.1351 38.2703H20.1081V41.2973H23.1351V38.2703Z' fill='black' />
        <Path d='M29.1892 38.2703H26.1622V41.2973H29.1892V38.2703Z' fill='black' />
        <Path d='M32.2162 38.2703H29.1892V41.2973H32.2162V38.2703Z' fill='black' />
        <Path d='M35.2432 38.2703H32.2162V41.2973H35.2432V38.2703Z' fill='black' />
        <Path d='M53.4054 38.2703H50.3784V41.2973H53.4054L53.4054 38.2703Z' fill='black' />
        <Path d='M59.4595 38.2703H56.4324L56.4324 41.2973H59.4595V38.2703Z' fill='black' />
        <Path d='M77.6216 38.2703H74.5946V41.2973H77.6216V38.2703Z' fill='black' />
        <Path d='M80.6486 38.2703H77.6216V41.2973H80.6486V38.2703Z' fill='black' />
        <Path d='M89.7297 38.2703H86.7027V41.2973H89.7297V38.2703Z' fill='black' />
        <Path d='M98.8108 38.2703H95.7838V41.2973H98.8108V38.2703Z' fill='black' />
        <Path d='M101.838 38.2703H98.8108V41.2973H101.838V38.2703Z' fill='black' />
        <Path d='M107.892 38.2703H104.865V41.2973H107.892V38.2703Z' fill='black' />
        <Path d='M113.946 38.2703H110.919V41.2973H113.946V38.2703Z' fill='black' />
        <Path d='M11.027 41.2973H8V44.3243H11.027V41.2973Z' fill='black' />
        <Path d='M20.1081 41.2973H17.0811V44.3243H20.1081V41.2973Z' fill='black' />
        <Path d='M23.1351 41.2973H20.1081V44.3243H23.1351V41.2973Z' fill='black' />
        <Path d='M38.2703 41.2973H35.2432V44.3243H38.2703V41.2973Z' fill='black' />
        <Path d='M44.3243 41.2973H41.2973V44.3243H44.3243V41.2973Z' fill='black' />
        <Path d='M65.5135 41.2973H62.4865V44.3243H65.5135V41.2973Z' fill='black' />
        <Path d='M71.5676 41.2973H68.5405V44.3243H71.5676V41.2973Z' fill='black' />
        <Path d='M77.6216 41.2973H74.5946V44.3243H77.6216V41.2973Z' fill='black' />
        <Path d='M80.6486 41.2973H77.6216V44.3243H80.6486V41.2973Z' fill='black' />
        <Path d='M83.6757 41.2973H80.6486V44.3243H83.6757V41.2973Z' fill='black' />
        <Path d='M86.7027 41.2973H83.6757V44.3243H86.7027V41.2973Z' fill='black' />
        <Path d='M95.7838 41.2973H92.7568V44.3243H95.7838V41.2973Z' fill='black' />
        <Path d='M104.865 41.2973H101.838V44.3243H104.865V41.2973Z' fill='black' />
        <Path d='M107.892 41.2973H104.865V44.3243H107.892V41.2973Z' fill='black' />
        <Path d='M110.919 41.2973H107.892V44.3243H110.919V41.2973Z' fill='black' />
        <Path d='M26.1622 44.3243L23.1351 44.3243V47.3514H26.1622V44.3243Z' fill='black' />
        <Path d='M29.1892 44.3243H26.1622V47.3514H29.1892V44.3243Z' fill='black' />
        <Path d='M32.2162 44.3243H29.1892V47.3514H32.2162V44.3243Z' fill='black' />
        <Path d='M44.3243 44.3243H41.2973V47.3514H44.3243V44.3243Z' fill='black' />
        <Path d='M50.3784 44.3243H47.3514V47.3514H50.3784V44.3243Z' fill='black' />
        <Path d='M59.4595 44.3243H56.4324V47.3514H59.4595V44.3243Z' fill='black' />
        <Path d='M62.4865 44.3243L59.4595 44.3243V47.3514H62.4865V44.3243Z' fill='black' />
        <Path d='M65.5135 44.3243H62.4865V47.3514H65.5135V44.3243Z' fill='black' />
        <Path d='M68.5405 44.3243H65.5135V47.3514H68.5405V44.3243Z' fill='black' />
        <Path d='M71.5676 44.3243H68.5405V47.3514H71.5676V44.3243Z' fill='black' />
        <Path d='M77.6216 44.3243H74.5946V47.3514H77.6216V44.3243Z' fill='black' />
        <Path d='M80.6486 44.3243H77.6216V47.3514H80.6486V44.3243Z' fill='black' />
        <Path d='M86.7027 44.3243H83.6757V47.3514H86.7027V44.3243Z' fill='black' />
        <Path d='M98.8108 44.3243L95.7838 44.3243V47.3514H98.8108V44.3243Z' fill='black' />
        <Path d='M120 44.3243H116.973V47.3514H120V44.3243Z' fill='black' />
        <Path d='M14.0541 47.3514H11.027V50.3784H14.0541V47.3514Z' fill='black' />
        <Path d='M17.0811 47.3514H14.0541V50.3784H17.0811V47.3514Z' fill='black' />
        <Path d='M23.1351 47.3514L20.1081 47.3514V50.3784H23.1351V47.3514Z' fill='black' />
        <Path d='M44.3243 47.3514H41.2973V50.3784H44.3243V47.3514Z' fill='black' />
        <Path d='M47.3514 47.3514H44.3243V50.3784H47.3514L47.3514 47.3514Z' fill='black' />
        <Path d='M50.3784 47.3514H47.3514L47.3514 50.3784H50.3784V47.3514Z' fill='black' />
        <Path d='M56.4324 47.3514L53.4054 47.3514V50.3784H56.4324L56.4324 47.3514Z' fill='black' />
        <Path d='M59.4595 47.3514H56.4324L56.4324 50.3784H59.4595V47.3514Z' fill='black' />
        <Path d='M68.5405 47.3514H65.5135V50.3784H68.5405V47.3514Z' fill='black' />
        <Path d='M74.5946 47.3514H71.5676V50.3784H74.5946V47.3514Z' fill='black' />
        <Path d='M80.6486 47.3514H77.6216V50.3784H80.6486V47.3514Z' fill='black' />
        <Path d='M92.7568 47.3514H89.7297V50.3784H92.7568V47.3514Z' fill='black' />
        <Path d='M95.7838 47.3514L92.7568 47.3514V50.3784H95.7838V47.3514Z' fill='black' />
        <Path d='M104.865 47.3514H101.838V50.3784H104.865V47.3514Z' fill='black' />
        <Path d='M107.892 47.3514H104.865V50.3784H107.892V47.3514Z' fill='black' />
        <Path d='M110.919 47.3514H107.892V50.3784H110.919V47.3514Z' fill='black' />
        <Path d='M113.946 47.3514H110.919V50.3784H113.946V47.3514Z' fill='black' />
        <Path d='M14.0541 50.3784H11.027V53.4054H14.0541V50.3784Z' fill='black' />
        <Path d='M20.1081 50.3784H17.0811V53.4054H20.1081V50.3784Z' fill='black' />
        <Path d='M29.1892 50.3784H26.1622V53.4054H29.1892V50.3784Z' fill='black' />
        <Path d='M35.2432 50.3784H32.2162V53.4054H35.2432V50.3784Z' fill='black' />
        <Path d='M38.2703 50.3784H35.2432V53.4054H38.2703V50.3784Z' fill='black' />
        <Path d='M41.2973 50.3784H38.2703V53.4054H41.2973V50.3784Z' fill='black' />
        <Path d='M44.3243 50.3784H41.2973V53.4054H44.3243V50.3784Z' fill='black' />
        <Path d='M56.4324 50.3784H53.4054V53.4054H56.4324V50.3784Z' fill='black' />
        <Path d='M59.4595 50.3784H56.4324V53.4054H59.4595V50.3784Z' fill='black' />
        <Path d='M62.4865 50.3784H59.4595V53.4054H62.4865V50.3784Z' fill='black' />
        <Path d='M65.5135 50.3784H62.4865V53.4054H65.5135V50.3784Z' fill='black' />
        <Path d='M74.5946 50.3784H71.5676V53.4054H74.5946V50.3784Z' fill='black' />
        <Path d='M80.6486 50.3784H77.6216V53.4054H80.6486V50.3784Z' fill='black' />
        <Path d='M83.6757 50.3784H80.6486V53.4054H83.6757V50.3784Z' fill='black' />
        <Path d='M86.7027 50.3784H83.6757V53.4054H86.7027V50.3784Z' fill='black' />
        <Path d='M89.7297 50.3784H86.7027V53.4054H89.7297V50.3784Z' fill='black' />
        <Path d='M92.7568 50.3784H89.7297V53.4054H92.7568V50.3784Z' fill='black' />
        <Path d='M95.7838 50.3784H92.7568V53.4054H95.7838V50.3784Z' fill='black' />
        <Path d='M104.865 50.3784H101.838V53.4054H104.865V50.3784Z' fill='black' />
        <Path d='M110.919 50.3784H107.892V53.4054H110.919V50.3784Z' fill='black' />
        <Path d='M113.946 50.3784H110.919V53.4054H113.946V50.3784Z' fill='black' />
        <Path d='M120 50.3784H116.973V53.4054H120V50.3784Z' fill='black' />
        <Path d='M14.0541 53.4054H11.027V56.4324H14.0541V53.4054Z' fill='black' />
        <Path d='M20.1081 53.4054H17.0811V56.4324H20.1081V53.4054Z' fill='black' />
        <Path d='M26.1622 53.4054L23.1351 53.4054V56.4324H26.1622V53.4054Z' fill='black' />
        <Path d='M35.2432 53.4054H32.2162V56.4324H35.2432V53.4054Z' fill='black' />
        <Path d='M44.3243 53.4054H41.2973V56.4324H44.3243V53.4054Z' fill='black' />
        <Path d='M50.3784 53.4054H47.3514V56.4324H50.3784V53.4054Z' fill='black' />
        <Path d='M53.4054 53.4054L50.3784 53.4054V56.4324H53.4054L53.4054 53.4054Z' fill='black' />
        <Path d='M65.5135 53.4054H62.4865V56.4324H65.5135V53.4054Z' fill='black' />
        <Path d='M71.5676 53.4054L68.5405 53.4054V56.4324H71.5676V53.4054Z' fill='black' />
        <Path d='M74.5946 53.4054H71.5676V56.4324H74.5946V53.4054Z' fill='black' />
        <Path d='M77.6216 53.4054H74.5946V56.4324H77.6216V53.4054Z' fill='black' />
        <Path d='M92.7568 53.4054H89.7297V56.4324H92.7568V53.4054Z' fill='black' />
        <Path d='M98.8108 53.4054L95.7838 53.4054V56.4324H98.8108V53.4054Z' fill='black' />
        <Path d='M101.838 53.4054L98.8108 53.4054V56.4324H101.838V53.4054Z' fill='black' />
        <Path d='M107.892 53.4054H104.865V56.4324H107.892V53.4054Z' fill='black' />
        <Path d='M113.946 53.4054H110.919V56.4324H113.946V53.4054Z' fill='black' />
        <Path d='M120 53.4054H116.973V56.4324H120V53.4054Z' fill='black' />
        <Path d='M11.027 56.4324L8 56.4324V59.4595H11.027V56.4324Z' fill='black' />
        <Path d='M17.0811 56.4324H14.0541V59.4595H17.0811V56.4324Z' fill='black' />
        <Path d='M20.1081 56.4324H17.0811V59.4595H20.1081V56.4324Z' fill='black' />
        <Path d='M23.1351 56.4324H20.1081V59.4595H23.1351V56.4324Z' fill='black' />
        <Path d='M29.1892 56.4324L26.1622 56.4324V59.4595H29.1892V56.4324Z' fill='black' />
        <Path d='M35.2432 56.4324H32.2162V59.4595H35.2432V56.4324Z' fill='black' />
        <Path d='M50.3784 56.4324H47.3514V59.4595H50.3784V56.4324Z' fill='black' />
        <Path d='M53.4054 56.4324H50.3784V59.4595H53.4054V56.4324Z' fill='black' />
        <Path d='M59.4595 56.4324H56.4324V59.4595H59.4595V56.4324Z' fill='black' />
        <Path d='M62.4865 56.4324L59.4595 56.4324V59.4595H62.4865V56.4324Z' fill='black' />
        <Path d='M71.5676 56.4324H68.5405V59.4595H71.5676V56.4324Z' fill='black' />
        <Path d='M77.6216 56.4324H74.5946V59.4595H77.6216V56.4324Z' fill='black' />
        <Path d='M80.6486 56.4324L77.6216 56.4324V59.4595H80.6486V56.4324Z' fill='black' />
        <Path d='M83.6757 56.4324H80.6486V59.4595H83.6757V56.4324Z' fill='black' />
        <Path d='M86.7027 56.4324H83.6757V59.4595H86.7027V56.4324Z' fill='black' />
        <Path d='M89.7297 56.4324L86.7027 56.4324V59.4595H89.7297V56.4324Z' fill='black' />
        <Path d='M110.919 56.4324H107.892V59.4595H110.919V56.4324Z' fill='black' />
        <Path d='M113.946 56.4324H110.919V59.4595H113.946V56.4324Z' fill='black' />
        <Path d='M116.973 56.4324H113.946V59.4595H116.973V56.4324Z' fill='black' />
        <Path d='M120 56.4324H116.973V59.4595H120V56.4324Z' fill='black' />
        <Path d='M11.027 59.4595H8V62.4865H11.027V59.4595Z' fill='black' />
        <Path d='M14.0541 59.4595H11.027V62.4865H14.0541V59.4595Z' fill='black' />
        <Path d='M20.1081 59.4595H17.0811V62.4865H20.1081V59.4595Z' fill='black' />
        <Path d='M23.1351 59.4595H20.1081V62.4865H23.1351V59.4595Z' fill='black' />
        <Path d='M26.1622 59.4595H23.1351V62.4865H26.1622V59.4595Z' fill='black' />
        <Path d='M38.2703 59.4595L35.2432 59.4595V62.4865H38.2703V59.4595Z' fill='black' />
        <Path d='M44.3243 59.4595H41.2973V62.4865H44.3243V59.4595Z' fill='black' />
        <Path d='M50.3784 59.4595H47.3514V62.4865H50.3784V59.4595Z' fill='black' />
        <Path d='M53.4054 59.4595H50.3784V62.4865H53.4054V59.4595Z' fill='black' />
        <Path d='M56.4324 59.4595H53.4054V62.4865H56.4324L56.4324 59.4595Z' fill='black' />
        <Path d='M59.4595 59.4595H56.4324L56.4324 62.4865H59.4595V59.4595Z' fill='black' />
        <Path d='M62.4865 59.4595H59.4595V62.4865H62.4865V59.4595Z' fill='black' />
        <Path d='M65.5135 59.4595L62.4865 59.4595V62.4865H65.5135V59.4595Z' fill='black' />
        <Path d='M71.5676 59.4595H68.5405V62.4865H71.5676V59.4595Z' fill='black' />
        <Path d='M77.6216 59.4595H74.5946V62.4865H77.6216V59.4595Z' fill='black' />
        <Path d='M80.6486 59.4595H77.6216V62.4865H80.6486V59.4595Z' fill='black' />
        <Path d='M83.6757 59.4595H80.6486V62.4865H83.6757V59.4595Z' fill='black' />
        <Path d='M104.865 59.4595H101.838V62.4865H104.865V59.4595Z' fill='black' />
        <Path d='M113.946 59.4595H110.919V62.4865H113.946V59.4595Z' fill='black' />
        <Path d='M116.973 59.4595H113.946V62.4865H116.973V59.4595Z' fill='black' />
        <Path d='M11.027 62.4865H8V65.5135H11.027V62.4865Z' fill='black' />
        <Path d='M14.0541 62.4865H11.027V65.5135H14.0541V62.4865Z' fill='black' />
        <Path d='M20.1081 62.4865H17.0811V65.5135H20.1081V62.4865Z' fill='black' />
        <Path d='M23.1351 62.4865H20.1081V65.5135H23.1351V62.4865Z' fill='black' />
        <Path d='M26.1622 62.4865H23.1351V65.5135H26.1622V62.4865Z' fill='black' />
        <Path d='M29.1892 62.4865H26.1622V65.5135H29.1892V62.4865Z' fill='black' />
        <Path d='M35.2432 62.4865H32.2162V65.5135H35.2432V62.4865Z' fill='black' />
        <Path d='M50.3784 62.4865H47.3514V65.5135H50.3784V62.4865Z' fill='black' />
        <Path d='M56.4324 62.4865H53.4054L53.4054 65.5135H56.4324V62.4865Z' fill='black' />
        <Path d='M62.4865 62.4865H59.4595L59.4595 65.5135H62.4865V62.4865Z' fill='black' />
        <Path d='M68.5405 62.4865H65.5135V65.5135H68.5405V62.4865Z' fill='black' />
        <Path d='M74.5946 62.4865H71.5676V65.5135H74.5946V62.4865Z' fill='black' />
        <Path d='M77.6216 62.4865H74.5946V65.5135H77.6216V62.4865Z' fill='black' />
        <Path d='M83.6757 62.4865H80.6486V65.5135H83.6757V62.4865Z' fill='black' />
        <Path d='M104.865 62.4865H101.838V65.5135H104.865V62.4865Z' fill='black' />
        <Path d='M110.919 62.4865H107.892V65.5135H110.919V62.4865Z' fill='black' />
        <Path d='M116.973 62.4865H113.946V65.5135H116.973V62.4865Z' fill='black' />
        <Path d='M11.027 65.5135H8V68.5405H11.027V65.5135Z' fill='black' />
        <Path d='M20.1081 65.5135H17.0811V68.5405H20.1081V65.5135Z' fill='black' />
        <Path d='M23.1351 65.5135H20.1081V68.5405H23.1351V65.5135Z' fill='black' />
        <Path d='M32.2162 65.5135H29.1892V68.5405H32.2162V65.5135Z' fill='black' />
        <Path d='M35.2432 65.5135H32.2162V68.5405H35.2432V65.5135Z' fill='black' />
        <Path d='M47.3514 65.5135H44.3243V68.5405H47.3514L47.3514 65.5135Z' fill='black' />
        <Path d='M53.4054 65.5135H50.3784V68.5405H53.4054L53.4054 65.5135Z' fill='black' />
        <Path d='M74.5946 65.5135H71.5676V68.5405H74.5946V65.5135Z' fill='black' />
        <Path d='M83.6757 65.5135H80.6486V68.5405H83.6757V65.5135Z' fill='black' />
        <Path d='M86.7027 65.5135H83.6757V68.5405H86.7027V65.5135Z' fill='black' />
        <Path d='M89.7297 65.5135H86.7027V68.5405H89.7297V65.5135Z' fill='black' />
        <Path d='M98.8108 65.5135H95.7838V68.5405H98.8108V65.5135Z' fill='black' />
        <Path d='M101.838 65.5135H98.8108V68.5405H101.838V65.5135Z' fill='black' />
        <Path d='M113.946 65.5135H110.919V68.5405H113.946V65.5135Z' fill='black' />
        <Path d='M116.973 65.5135H113.946V68.5405H116.973V65.5135Z' fill='black' />
        <Path d='M14.0541 68.5405H11.027V71.5676H14.0541V68.5405Z' fill='black' />
        <Path d='M17.0811 68.5405H14.0541V71.5676H17.0811V68.5405Z' fill='black' />
        <Path d='M29.1892 68.5405H26.1622V71.5676H29.1892V68.5405Z' fill='black' />
        <Path d='M32.2162 68.5405H29.1892V71.5676H32.2162V68.5405Z' fill='black' />
        <Path d='M35.2432 68.5405H32.2162V71.5676H35.2432V68.5405Z' fill='black' />
        <Path d='M38.2703 68.5405H35.2432V71.5676H38.2703V68.5405Z' fill='black' />
        <Path d='M56.4324 68.5405H53.4054L53.4054 71.5676H56.4324V68.5405Z' fill='black' />
        <Path d='M59.4595 68.5405H56.4324V71.5676H59.4595V68.5405Z' fill='black' />
        <Path d='M62.4865 68.5405H59.4595V71.5676H62.4865V68.5405Z' fill='black' />
        <Path d='M65.5135 68.5405H62.4865V71.5676H65.5135V68.5405Z' fill='black' />
        <Path d='M71.5676 68.5405H68.5405V71.5676H71.5676V68.5405Z' fill='black' />
        <Path d='M86.7027 68.5405H83.6757V71.5676H86.7027V68.5405Z' fill='black' />
        <Path d='M89.7297 68.5405H86.7027V71.5676H89.7297V68.5405Z' fill='black' />
        <Path d='M92.7568 68.5405H89.7297V71.5676H92.7568V68.5405Z' fill='black' />
        <Path d='M95.7838 68.5405H92.7568V71.5676H95.7838V68.5405Z' fill='black' />
        <Path d='M98.8108 68.5405H95.7838V71.5676H98.8108V68.5405Z' fill='black' />
        <Path d='M113.946 68.5405H110.919V71.5676H113.946V68.5405Z' fill='black' />
        <Path d='M116.973 68.5405H113.946V71.5676H116.973V68.5405Z' fill='black' />
        <Path d='M120 68.5405H116.973V71.5676H120V68.5405Z' fill='black' />
        <Path d='M11.027 71.5676H8V74.5946H11.027V71.5676Z' fill='black' />
        <Path d='M14.0541 71.5676H11.027V74.5946H14.0541V71.5676Z' fill='black' />
        <Path d='M17.0811 71.5676H14.0541V74.5946H17.0811V71.5676Z' fill='black' />
        <Path d='M20.1081 71.5676H17.0811V74.5946H20.1081V71.5676Z' fill='black' />
        <Path d='M26.1622 71.5676H23.1351V74.5946H26.1622V71.5676Z' fill='black' />
        <Path d='M32.2162 71.5676H29.1892V74.5946H32.2162V71.5676Z' fill='black' />
        <Path d='M35.2432 71.5676H32.2162V74.5946H35.2432V71.5676Z' fill='black' />
        <Path d='M41.2973 71.5676H38.2703V74.5946H41.2973V71.5676Z' fill='black' />
        <Path d='M53.4054 71.5676H50.3784V74.5946H53.4054L53.4054 71.5676Z' fill='black' />
        <Path d='M59.4595 71.5676H56.4324L56.4324 74.5946H59.4595V71.5676Z' fill='black' />
        <Path d='M62.4865 71.5676H59.4595V74.5946H62.4865V71.5676Z' fill='black' />
        <Path d='M74.5946 71.5676H71.5676V74.5946H74.5946V71.5676Z' fill='black' />
        <Path d='M80.6486 71.5676H77.6216V74.5946H80.6486V71.5676Z' fill='black' />
        <Path d='M83.6757 71.5676H80.6486V74.5946H83.6757V71.5676Z' fill='black' />
        <Path d='M101.838 71.5676H98.8108V74.5946H101.838V71.5676Z' fill='black' />
        <Path d='M107.892 71.5676H104.865V74.5946H107.892V71.5676Z' fill='black' />
        <Path d='M113.946 71.5676H110.919V74.5946H113.946V71.5676Z' fill='black' />
        <Path d='M23.1351 74.5946H20.1081V77.6216H23.1351V74.5946Z' fill='black' />
        <Path d='M29.1892 74.5946H26.1622V77.6216H29.1892V74.5946Z' fill='black' />
        <Path d='M38.2703 74.5946H35.2432V77.6216H38.2703V74.5946Z' fill='black' />
        <Path d='M41.2973 74.5946H38.2703V77.6216H41.2973V74.5946Z' fill='black' />
        <Path d='M44.3243 74.5946H41.2973V77.6216H44.3243V74.5946Z' fill='black' />
        <Path d='M53.4054 74.5946H50.3784V77.6216H53.4054V74.5946Z' fill='black' />
        <Path d='M68.5405 74.5946H65.5135V77.6216H68.5405V74.5946Z' fill='black' />
        <Path d='M77.6216 74.5946H74.5946V77.6216H77.6216V74.5946Z' fill='black' />
        <Path d='M80.6486 74.5946H77.6216V77.6216H80.6486V74.5946Z' fill='black' />
        <Path d='M89.7297 74.5946H86.7027V77.6216H89.7297V74.5946Z' fill='black' />
        <Path d='M92.7568 74.5946H89.7297V77.6216H92.7568V74.5946Z' fill='black' />
        <Path d='M95.7838 74.5946H92.7568V77.6216H95.7838V74.5946Z' fill='black' />
        <Path d='M98.8108 74.5946H95.7838V77.6216H98.8108V74.5946Z' fill='black' />
        <Path d='M101.838 74.5946H98.8108V77.6216H101.838V74.5946Z' fill='black' />
        <Path d='M104.865 74.5946H101.838V77.6216H104.865V74.5946Z' fill='black' />
        <Path d='M107.892 74.5946H104.865V77.6216H107.892V74.5946Z' fill='black' />
        <Path d='M113.946 74.5946H110.919V77.6216H113.946V74.5946Z' fill='black' />
        <Path d='M120 74.5946H116.973V77.6216H120V74.5946Z' fill='black' />
        <Path d='M11.027 77.6216H8V80.6486H11.027V77.6216Z' fill='black' />
        <Path d='M26.1622 77.6216H23.1351V80.6486H26.1622V77.6216Z' fill='black' />
        <Path d='M32.2162 77.6216H29.1892V80.6486H32.2162V77.6216Z' fill='black' />
        <Path d='M35.2432 77.6216H32.2162V80.6486H35.2432V77.6216Z' fill='black' />
        <Path d='M50.3784 77.6216H47.3514V80.6486H50.3784V77.6216Z' fill='black' />
        <Path d='M62.4865 77.6216H59.4595V80.6486H62.4865V77.6216Z' fill='black' />
        <Path d='M65.5135 77.6216H62.4865V80.6486H65.5135V77.6216Z' fill='black' />
        <Path d='M74.5946 77.6216H71.5676V80.6486H74.5946V77.6216Z' fill='black' />
        <Path d='M77.6216 77.6216H74.5946V80.6486H77.6216V77.6216Z' fill='black' />
        <Path d='M89.7297 77.6216H86.7027V80.6486H89.7297V77.6216Z' fill='black' />
        <Path d='M92.7568 77.6216H89.7297V80.6486H92.7568V77.6216Z' fill='black' />
        <Path d='M101.838 77.6216H98.8108V80.6486H101.838V77.6216Z' fill='black' />
        <Path d='M104.865 77.6216H101.838V80.6486H104.865V77.6216Z' fill='black' />
        <Path d='M113.946 77.6216H110.919V80.6486H113.946V77.6216Z' fill='black' />
        <Path d='M116.973 77.6216H113.946V80.6486H116.973V77.6216Z' fill='black' />
        <Path d='M120 77.6216H116.973V80.6486H120V77.6216Z' fill='black' />
        <Path d='M11.027 80.6486H8V83.6757H11.027V80.6486Z' fill='black' />
        <Path d='M17.0811 80.6486H14.0541V83.6757H17.0811V80.6486Z' fill='black' />
        <Path d='M29.1892 80.6486H26.1622V83.6757H29.1892V80.6486Z' fill='black' />
        <Path d='M35.2432 80.6486H32.2162V83.6757H35.2432V80.6486Z' fill='black' />
        <Path d='M41.2973 80.6486H38.2703V83.6757H41.2973V80.6486Z' fill='black' />
        <Path d='M47.3514 80.6486H44.3243V83.6757H47.3514L47.3514 80.6486Z' fill='black' />
        <Path d='M50.3784 80.6486H47.3514L47.3514 83.6757H50.3784V80.6486Z' fill='black' />
        <Path d='M62.4865 80.6486H59.4595V83.6757H62.4865V80.6486Z' fill='black' />
        <Path d='M68.5405 80.6486H65.5135V83.6757H68.5405V80.6486Z' fill='black' />
        <Path d='M71.5676 80.6486H68.5405V83.6757H71.5676V80.6486Z' fill='black' />
        <Path d='M83.6757 80.6486H80.6486V83.6757H83.6757V80.6486Z' fill='black' />
        <Path d='M89.7297 80.6486H86.7027V83.6757H89.7297V80.6486Z' fill='black' />
        <Path d='M95.7838 80.6486H92.7568V83.6757H95.7838V80.6486Z' fill='black' />
        <Path d='M104.865 80.6486H101.838V83.6757H104.865V80.6486Z' fill='black' />
        <Path d='M110.919 80.6486H107.892V83.6757H110.919V80.6486Z' fill='black' />
        <Path d='M116.973 80.6486H113.946V83.6757H116.973V80.6486Z' fill='black' />
        <Path d='M120 80.6486H116.973V83.6757H120V80.6486Z' fill='black' />
        <Path d='M17.0811 83.6757H14.0541V86.7027H17.0811V83.6757Z' fill='black' />
        <Path d='M20.1081 83.6757H17.0811V86.7027H20.1081V83.6757Z' fill='black' />
        <Path d='M32.2162 83.6757H29.1892V86.7027H32.2162V83.6757Z' fill='black' />
        <Path d='M41.2973 83.6757H38.2703V86.7027H41.2973V83.6757Z' fill='black' />
        <Path d='M50.3784 83.6757H47.3514L47.3514 86.7027H50.3784V83.6757Z' fill='black' />
        <Path d='M59.4595 83.6757H56.4324V86.7027H59.4595L59.4595 83.6757Z' fill='black' />
        <Path d='M62.4865 83.6757H59.4595L59.4595 86.7027H62.4865V83.6757Z' fill='black' />
        <Path d='M65.5135 83.6757H62.4865V86.7027H65.5135V83.6757Z' fill='black' />
        <Path d='M68.5405 83.6757H65.5135V86.7027H68.5405V83.6757Z' fill='black' />
        <Path d='M83.6757 83.6757H80.6486V86.7027H83.6757V83.6757Z' fill='black' />
        <Path d='M86.7027 83.6757H83.6757V86.7027H86.7027V83.6757Z' fill='black' />
        <Path d='M89.7297 83.6757H86.7027V86.7027H89.7297V83.6757Z' fill='black' />
        <Path d='M95.7838 83.6757H92.7568V86.7027H95.7838V83.6757Z' fill='black' />
        <Path d='M98.8108 83.6757H95.7838V86.7027H98.8108V83.6757Z' fill='black' />
        <Path d='M107.892 83.6757H104.865V86.7027H107.892V83.6757Z' fill='black' />
        <Path d='M110.919 83.6757H107.892V86.7027H110.919V83.6757Z' fill='black' />
        <Path d='M113.946 83.6757H110.919V86.7027H113.946V83.6757Z' fill='black' />
        <Path d='M11.027 86.7027H8V89.7297H11.027V86.7027Z' fill='black' />
        <Path d='M17.0811 86.7027H14.0541V89.7297H17.0811V86.7027Z' fill='black' />
        <Path d='M23.1351 86.7027H20.1081V89.7297H23.1351V86.7027Z' fill='black' />
        <Path d='M29.1892 86.7027H26.1622V89.7297H29.1892V86.7027Z' fill='black' />
        <Path d='M35.2432 86.7027H32.2162V89.7297H35.2432V86.7027Z' fill='black' />
        <Path d='M44.3243 86.7027H41.2973V89.7297H44.3243V86.7027Z' fill='black' />
        <Path d='M50.3784 86.7027H47.3514V89.7297H50.3784V86.7027Z' fill='black' />
        <Path d='M62.4865 86.7027H59.4595L59.4595 89.7297H62.4865V86.7027Z' fill='black' />
        <Path d='M68.5405 86.7027H65.5135V89.7297H68.5405V86.7027Z' fill='black' />
        <Path d='M71.5676 86.7027H68.5405V89.7297H71.5676V86.7027Z' fill='black' />
        <Path d='M74.5946 86.7027H71.5676V89.7297H74.5946V86.7027Z' fill='black' />
        <Path d='M83.6757 86.7027H80.6486V89.7297H83.6757V86.7027Z' fill='black' />
        <Path d='M86.7027 86.7027H83.6757V89.7297H86.7027V86.7027Z' fill='black' />
        <Path d='M89.7297 86.7027H86.7027V89.7297H89.7297V86.7027Z' fill='black' />
        <Path d='M95.7838 86.7027H92.7568V89.7297H95.7838V86.7027Z' fill='black' />
        <Path d='M104.865 86.7027H101.838V89.7297H104.865V86.7027Z' fill='black' />
        <Path d='M107.892 86.7027H104.865V89.7297H107.892V86.7027Z' fill='black' />
        <Path d='M110.919 86.7027H107.892V89.7297H110.919V86.7027Z' fill='black' />
        <Path d='M20.1081 89.7297H17.0811V92.7568H20.1081V89.7297Z' fill='black' />
        <Path d='M32.2162 89.7297H29.1892V92.7568H32.2162V89.7297Z' fill='black' />
        <Path d='M38.2703 89.7297H35.2432V92.7568H38.2703V89.7297Z' fill='black' />
        <Path d='M41.2973 89.7297H38.2703V92.7568H41.2973V89.7297Z' fill='black' />
        <Path d='M50.3784 89.7297H47.3514V92.7568H50.3784V89.7297Z' fill='black' />
        <Path d='M53.4054 89.7297H50.3784V92.7568H53.4054V89.7297Z' fill='black' />
        <Path d='M56.4324 89.7297H53.4054V92.7568H56.4324V89.7297Z' fill='black' />
        <Path d='M59.4595 89.7297H56.4324V92.7568H59.4595L59.4595 89.7297Z' fill='black' />
        <Path d='M62.4865 89.7297H59.4595L59.4595 92.7568H62.4865V89.7297Z' fill='black' />
        <Path d='M65.5135 89.7297H62.4865V92.7568H65.5135V89.7297Z' fill='black' />
        <Path d='M68.5405 89.7297H65.5135V92.7568H68.5405V89.7297Z' fill='black' />
        <Path d='M71.5676 89.7297H68.5405V92.7568H71.5676V89.7297Z' fill='black' />
        <Path d='M74.5946 89.7297H71.5676V92.7568H74.5946V89.7297Z' fill='black' />
        <Path d='M77.6216 89.7297H74.5946V92.7568H77.6216V89.7297Z' fill='black' />
        <Path d='M80.6486 89.7297H77.6216V92.7568H80.6486V89.7297Z' fill='black' />
        <Path d='M86.7027 89.7297H83.6757V92.7568H86.7027V89.7297Z' fill='black' />
        <Path d='M89.7297 89.7297H86.7027V92.7568H89.7297V89.7297Z' fill='black' />
        <Path d='M95.7838 89.7297H92.7568V92.7568H95.7838V89.7297Z' fill='black' />
        <Path d='M107.892 89.7297H104.865V92.7568H107.892V89.7297Z' fill='black' />
        <Path d='M110.919 89.7297H107.892V92.7568H110.919V89.7297Z' fill='black' />
        <Path d='M113.946 89.7297H110.919V92.7568H113.946V89.7297Z' fill='black' />
        <Path d='M20.1081 92.7568H17.0811V95.7838H20.1081V92.7568Z' fill='black' />
        <Path d='M23.1351 92.7568H20.1081V95.7838H23.1351V92.7568Z' fill='black' />
        <Path d='M29.1892 92.7568H26.1622V95.7838H29.1892V92.7568Z' fill='black' />
        <Path d='M32.2162 92.7568H29.1892V95.7838H32.2162V92.7568Z' fill='black' />
        <Path d='M35.2432 92.7568H32.2162V95.7838H35.2432V92.7568Z' fill='black' />
        <Path d='M38.2703 92.7568H35.2432V95.7838H38.2703V92.7568Z' fill='black' />
        <Path d='M41.2973 92.7568H38.2703V95.7838H41.2973V92.7568Z' fill='black' />
        <Path d='M47.3514 92.7568H44.3243V95.7838H47.3514L47.3514 92.7568Z' fill='black' />
        <Path d='M50.3784 92.7568H47.3514L47.3514 95.7838H50.3784V92.7568Z' fill='black' />
        <Path d='M65.5135 92.7568H62.4865V95.7838H65.5135V92.7568Z' fill='black' />
        <Path d='M68.5405 92.7568H65.5135V95.7838H68.5405V92.7568Z' fill='black' />
        <Path d='M71.5676 92.7568H68.5405V95.7838H71.5676V92.7568Z' fill='black' />
        <Path d='M86.7027 92.7568H83.6757V95.7838H86.7027V92.7568Z' fill='black' />
        <Path d='M89.7297 92.7568H86.7027V95.7838H89.7297V92.7568Z' fill='black' />
        <Path d='M95.7838 92.7568H92.7568V95.7838H95.7838V92.7568Z' fill='black' />
        <Path d='M98.8108 92.7568H95.7838V95.7838H98.8108V92.7568Z' fill='black' />
        <Path d='M101.838 92.7568H98.8108V95.7838H101.838V92.7568Z' fill='black' />
        <Path d='M104.865 92.7568H101.838V95.7838H104.865V92.7568Z' fill='black' />
        <Path d='M107.892 92.7568H104.865V95.7838H107.892V92.7568Z' fill='black' />
        <Path d='M110.919 92.7568H107.892V95.7838H110.919V92.7568Z' fill='black' />
        <Path d='M113.946 92.7568H110.919V95.7838H113.946V92.7568Z' fill='black' />
        <Path d='M120 92.7568H116.973V95.7838H120V92.7568Z' fill='black' />
        <Path d='M35.2432 95.7838H32.2162V98.8108H35.2432V95.7838Z' fill='black' />
        <Path d='M41.2973 95.7838H38.2703V98.8108H41.2973V95.7838Z' fill='black' />
        <Path d='M44.3243 95.7838H41.2973V98.8108H44.3243L44.3243 95.7838Z' fill='black' />
        <Path d='M53.4054 95.7838H50.3784V98.8108H53.4054V95.7838Z' fill='black' />
        <Path d='M62.4865 95.7838H59.4595V98.8108H62.4865V95.7838Z' fill='black' />
        <Path d='M71.5676 95.7838H68.5405V98.8108H71.5676V95.7838Z' fill='black' />
        <Path d='M80.6486 95.7838H77.6216V98.8108H80.6486V95.7838Z' fill='black' />
        <Path d='M86.7027 95.7838H83.6757V98.8108H86.7027V95.7838Z' fill='black' />
        <Path d='M92.7568 95.7838H89.7297V98.8108H92.7568V95.7838Z' fill='black' />
        <Path d='M95.7838 95.7838H92.7568V98.8108H95.7838V95.7838Z' fill='black' />
        <Path d='M107.892 95.7838H104.865V98.8108H107.892V95.7838Z' fill='black' />
        <Path d='M110.919 95.7838H107.892V98.8108H110.919V95.7838Z' fill='black' />
        <Path d='M116.973 95.7838H113.946V98.8108H116.973V95.7838Z' fill='black' />
        <Path d='M11.027 98.8108H8V101.838H11.027V98.8108Z' fill='black' />
        <Path d='M14.0541 98.8108H11.027V101.838H14.0541V98.8108Z' fill='black' />
        <Path d='M17.0811 98.8108H14.0541V101.838H17.0811V98.8108Z' fill='black' />
        <Path d='M20.1081 98.8108H17.0811V101.838H20.1081V98.8108Z' fill='black' />
        <Path d='M23.1351 98.8108H20.1081V101.838H23.1351V98.8108Z' fill='black' />
        <Path d='M26.1622 98.8108H23.1351V101.838H26.1622V98.8108Z' fill='black' />
        <Path d='M29.1892 98.8108H26.1622V101.838H29.1892V98.8108Z' fill='black' />
        <Path d='M41.2973 98.8108H38.2703V101.838H41.2973V98.8108Z' fill='black' />
        <Path d='M44.3243 98.8108H41.2973V101.838H44.3243V98.8108Z' fill='black' />
        <Path d='M47.3514 98.8108H44.3243V101.838H47.3514V98.8108Z' fill='black' />
        <Path d='M56.4324 98.8108H53.4054L53.4054 101.838H56.4324V98.8108Z' fill='black' />
        <Path d='M59.4595 98.8108H56.4324V101.838H59.4595L59.4595 98.8108Z' fill='black' />
        <Path d='M68.5405 98.8108H65.5135V101.838H68.5405V98.8108Z' fill='black' />
        <Path d='M71.5676 98.8108H68.5405V101.838H71.5676V98.8108Z' fill='black' />
        <Path d='M80.6486 98.8108H77.6216V101.838H80.6486V98.8108Z' fill='black' />
        <Path d='M83.6757 98.8108H80.6486V101.838H83.6757V98.8108Z' fill='black' />
        <Path d='M89.7297 98.8108H86.7027V101.838H89.7297V98.8108Z' fill='black' />
        <Path d='M92.7568 98.8108H89.7297V101.838H92.7568V98.8108Z' fill='black' />
        <Path d='M95.7838 98.8108H92.7568V101.838H95.7838V98.8108Z' fill='black' />
        <Path d='M101.838 98.8108H98.8108V101.838H101.838V98.8108Z' fill='black' />
        <Path d='M107.892 98.8108H104.865V101.838H107.892V98.8108Z' fill='black' />
        <Path d='M110.919 98.8108H107.892V101.838H110.919V98.8108Z' fill='black' />
        <Path d='M120 98.8108H116.973V101.838H120V98.8108Z' fill='black' />
        <Path d='M11.027 101.838H8V104.865H11.027V101.838Z' fill='black' />
        <Path d='M29.1892 101.838H26.1622V104.865H29.1892V101.838Z' fill='black' />
        <Path d='M38.2703 101.838H35.2432V104.865H38.2703V101.838Z' fill='black' />
        <Path d='M41.2973 101.838H38.2703V104.865H41.2973V101.838Z' fill='black' />
        <Path d='M56.4324 101.838H53.4054V104.865H56.4324V101.838Z' fill='black' />
        <Path d='M62.4865 101.838H59.4595L59.4595 104.865H62.4865V101.838Z' fill='black' />
        <Path d='M65.5135 101.838H62.4865V104.865H65.5135V101.838Z' fill='black' />
        <Path d='M71.5676 101.838H68.5405V104.865H71.5676V101.838Z' fill='black' />
        <Path d='M77.6216 101.838H74.5946V104.865H77.6216V101.838Z' fill='black' />
        <Path d='M80.6486 101.838H77.6216V104.865H80.6486V101.838Z' fill='black' />
        <Path d='M83.6757 101.838H80.6486V104.865H83.6757V101.838Z' fill='black' />
        <Path d='M86.7027 101.838H83.6757V104.865H86.7027V101.838Z' fill='black' />
        <Path d='M92.7568 101.838H89.7297V104.865H92.7568V101.838Z' fill='black' />
        <Path d='M95.7838 101.838H92.7568V104.865H95.7838V101.838Z' fill='black' />
        <Path d='M107.892 101.838H104.865V104.865H107.892V101.838Z' fill='black' />
        <Path d='M120 101.838H116.973V104.865H120V101.838Z' fill='black' />
        <Path d='M11.027 104.865H8V107.892H11.027V104.865Z' fill='black' />
        <Path d='M17.0811 104.865H14.0541V107.892H17.0811V104.865Z' fill='black' />
        <Path d='M20.1081 104.865H17.0811V107.892H20.1081V104.865Z' fill='black' />
        <Path d='M23.1351 104.865H20.1081V107.892H23.1351V104.865Z' fill='black' />
        <Path d='M29.1892 104.865H26.1622V107.892H29.1892V104.865Z' fill='black' />
        <Path d='M35.2432 104.865H32.2162V107.892H35.2432V104.865Z' fill='black' />
        <Path d='M38.2703 104.865H35.2432V107.892H38.2703V104.865Z' fill='black' />
        <Path d='M41.2973 104.865H38.2703V107.892H41.2973V104.865Z' fill='black' />
        <Path d='M50.3784 104.865H47.3514V107.892H50.3784V104.865Z' fill='black' />
        <Path d='M53.4054 104.865H50.3784V107.892H53.4054L53.4054 104.865Z' fill='black' />
        <Path d='M71.5676 104.865H68.5405V107.892H71.5676V104.865Z' fill='black' />
        <Path d='M74.5946 104.865H71.5676V107.892H74.5946V104.865Z' fill='black' />
        <Path d='M80.6486 104.865H77.6216V107.892H80.6486V104.865Z' fill='black' />
        <Path d='M86.7027 104.865H83.6757V107.892H86.7027V104.865Z' fill='black' />
        <Path d='M95.7838 104.865H92.7568V107.892H95.7838V104.865Z' fill='black' />
        <Path d='M98.8108 104.865H95.7838V107.892H98.8108V104.865Z' fill='black' />
        <Path d='M101.838 104.865H98.8108V107.892H101.838V104.865Z' fill='black' />
        <Path d='M104.865 104.865H101.838V107.892H104.865V104.865Z' fill='black' />
        <Path d='M107.892 104.865H104.865V107.892H107.892V104.865Z' fill='black' />
        <Path d='M113.946 104.865H110.919V107.892H113.946V104.865Z' fill='black' />
        <Path d='M116.973 104.865H113.946V107.892H116.973V104.865Z' fill='black' />
        <Path d='M11.027 107.892H8V110.919H11.027V107.892Z' fill='black' />
        <Path d='M17.0811 107.892H14.0541V110.919H17.0811V107.892Z' fill='black' />
        <Path d='M20.1081 107.892H17.0811V110.919H20.1081V107.892Z' fill='black' />
        <Path d='M23.1351 107.892H20.1081V110.919H23.1351V107.892Z' fill='black' />
        <Path d='M29.1892 107.892H26.1622V110.919H29.1892V107.892Z' fill='black' />
        <Path d='M38.2703 107.892H35.2432V110.919H38.2703V107.892Z' fill='black' />
        <Path d='M41.2973 107.892H38.2703V110.919H41.2973V107.892Z' fill='black' />
        <Path d='M50.3784 107.892H47.3514V110.919H50.3784V107.892Z' fill='black' />
        <Path d='M53.4054 107.892H50.3784V110.919H53.4054V107.892Z' fill='black' />
        <Path d='M56.4324 107.892H53.4054V110.919H56.4324V107.892Z' fill='black' />
        <Path d='M62.4865 107.892H59.4595V110.919H62.4865V107.892Z' fill='black' />
        <Path d='M68.5405 107.892H65.5135V110.919H68.5405V107.892Z' fill='black' />
        <Path d='M71.5676 107.892H68.5405V110.919H71.5676V107.892Z' fill='black' />
        <Path d='M77.6216 107.892H74.5946V110.919H77.6216V107.892Z' fill='black' />
        <Path d='M80.6486 107.892H77.6216V110.919H80.6486V107.892Z' fill='black' />
        <Path d='M92.7568 107.892H89.7297V110.919H92.7568V107.892Z' fill='black' />
        <Path d='M95.7838 107.892H92.7568V110.919H95.7838V107.892Z' fill='black' />
        <Path d='M110.919 107.892H107.892V110.919H110.919V107.892Z' fill='black' />
        <Path d='M116.973 107.892H113.946V110.919H116.973V107.892Z' fill='black' />
        <Path d='M120 107.892H116.973V110.919H120V107.892Z' fill='black' />
        <Path d='M11.027 110.919H8V113.946H11.027V110.919Z' fill='black' />
        <Path d='M17.0811 110.919H14.0541V113.946H17.0811V110.919Z' fill='black' />
        <Path d='M20.1081 110.919H17.0811V113.946H20.1081V110.919Z' fill='black' />
        <Path d='M23.1351 110.919H20.1081V113.946H23.1351V110.919Z' fill='black' />
        <Path d='M29.1892 110.919H26.1622V113.946H29.1892V110.919Z' fill='black' />
        <Path d='M35.2432 110.919H32.2162V113.946H35.2432V110.919Z' fill='black' />
        <Path d='M38.2703 110.919H35.2432V113.946H38.2703V110.919Z' fill='black' />
        <Path d='M44.3243 110.919H41.2973V113.946H44.3243V110.919Z' fill='black' />
        <Path d='M53.4054 110.919H50.3784V113.946H53.4054V110.919Z' fill='black' />
        <Path d='M56.4324 110.919H53.4054V113.946H56.4324V110.919Z' fill='black' />
        <Path d='M59.4595 110.919H56.4324V113.946H59.4595L59.4595 110.919Z' fill='black' />
        <Path d='M65.5135 110.919H62.4865V113.946H65.5135V110.919Z' fill='black' />
        <Path d='M74.5946 110.919H71.5676V113.946H74.5946V110.919Z' fill='black' />
        <Path d='M80.6486 110.919H77.6216V113.946H80.6486V110.919Z' fill='black' />
        <Path d='M92.7568 110.919H89.7297V113.946H92.7568V110.919Z' fill='black' />
        <Path d='M95.7838 110.919H92.7568V113.946H95.7838V110.919Z' fill='black' />
        <Path d='M98.8108 110.919H95.7838V113.946H98.8108V110.919Z' fill='black' />
        <Path d='M113.946 110.919H110.919V113.946H113.946V110.919Z' fill='black' />
        <Path d='M116.973 110.919H113.946V113.946H116.973V110.919Z' fill='black' />
        <Path d='M11.027 113.946H8V116.973H11.027V113.946Z' fill='black' />
        <Path d='M29.1892 113.946H26.1622V116.973H29.1892V113.946Z' fill='black' />
        <Path d='M35.2432 113.946H32.2162V116.973H35.2432V113.946Z' fill='black' />
        <Path d='M38.2703 113.946H35.2432V116.973H38.2703V113.946Z' fill='black' />
        <Path d='M44.3243 113.946H41.2973V116.973H44.3243V113.946Z' fill='black' />
        <Path d='M53.4054 113.946H50.3784V116.973H53.4054V113.946Z' fill='black' />
        <Path d='M56.4324 113.946H53.4054V116.973H56.4324V113.946Z' fill='black' />
        <Path d='M59.4595 113.946H56.4324V116.973H59.4595V113.946Z' fill='black' />
        <Path d='M89.7297 113.946H86.7027V116.973H89.7297V113.946Z' fill='black' />
        <Path d='M92.7568 113.946H89.7297V116.973H92.7568V113.946Z' fill='black' />
        <Path d='M101.838 113.946H98.8108V116.973H101.838V113.946Z' fill='black' />
        <Path d='M104.865 113.946H101.838V116.973H104.865V113.946Z' fill='black' />
        <Path d='M110.919 113.946H107.892V116.973H110.919V113.946Z' fill='black' />
        <Path d='M113.946 113.946H110.919V116.973H113.946V113.946Z' fill='black' />
        <Path d='M116.973 113.946H113.946V116.973H116.973V113.946Z' fill='black' />
        <Path d='M11.027 116.973H8V120H11.027V116.973Z' fill='black' />
        <Path d='M14.0541 116.973H11.027V120H14.0541V116.973Z' fill='black' />
        <Path d='M17.0811 116.973H14.0541V120H17.0811V116.973Z' fill='black' />
        <Path d='M20.1081 116.973H17.0811V120H20.1081V116.973Z' fill='black' />
        <Path d='M23.1351 116.973H20.1081V120H23.1351V116.973Z' fill='black' />
        <Path d='M26.1622 116.973H23.1351V120H26.1622V116.973Z' fill='black' />
        <Path d='M29.1892 116.973H26.1622V120H29.1892V116.973Z' fill='black' />
        <Path d='M35.2432 116.973H32.2162V120H35.2432V116.973Z' fill='black' />
        <Path d='M41.2973 116.973H38.2703V120H41.2973V116.973Z' fill='black' />
        <Path d='M44.3243 116.973H41.2973V120H44.3243V116.973Z' fill='black' />
        <Path d='M47.3514 116.973H44.3243V120H47.3514V116.973Z' fill='black' />
        <Path d='M53.4054 116.973H50.3784V120H53.4054V116.973Z' fill='black' />
        <Path d='M56.4324 116.973H53.4054V120H56.4324V116.973Z' fill='black' />
        <Path d='M65.5135 116.973H62.4865V120H65.5135V116.973Z' fill='black' />
        <Path d='M68.5405 116.973H65.5135V120H68.5405V116.973Z' fill='black' />
        <Path d='M77.6216 116.973H74.5946V120H77.6216V116.973Z' fill='black' />
        <Path d='M89.7297 116.973H86.7027V120H89.7297V116.973Z' fill='black' />
        <Path d='M92.7568 116.973H89.7297V120H92.7568V116.973Z' fill='black' />
        <Path d='M101.838 116.973H98.8108V120H101.838V116.973Z' fill='black' />
        <Path d='M107.892 116.973H104.865V120H107.892V116.973Z' fill='black' />
        <Path d='M113.946 116.973H110.919V120H113.946V116.973Z' fill='black' />
        <Path d='M116.973 116.973H113.946V120H116.973V116.973Z' fill='black' />
        <Path d='M120 116.973H116.973V120H120V116.973Z' fill='black' />
      </G>
      <Defs>
        <ClipPath id='clip0_61_2'>
          <Rect width='128' height='128' rx='16' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default QRPlayStore;
