import { Schedule } from 'src/interfaces';

export interface AppointmentOption {
  label: string;
  value: number;
  length: number;
  categories?: string[];
}

const extractAppointmentOptions = (schedule: Schedule): AppointmentOption[] =>
  (schedule?.appointmentTypes ?? []).map((at) => ({
    label: at.description,
    value: at.id,
    length: at.length,
    categories: at.categories
  }));

export default extractAppointmentOptions;
