import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { SectionList } from 'react-native';
import { Headline, HoverButton } from 'src/components';
import { Margin } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { AppColors } from 'src/types/paper';
import styled from 'styled-components/native';

type Props = NativeStackScreenProps<RootStackParamList, Screens.SANDBOX>;

const Sandbox: React.FC<Props> = ({ navigation }) => {
  const { colors } = useAppTheme();
  return (
    <SectionList
      contentContainerStyle={{ padding: Margin.Large }}
      sections={Sections}
      renderSectionHeader={({ section: { title } }) => <StyledHeadline>{title}</StyledHeadline>}
      renderItem={({ item }) => (
        <NavButton
          buttonColor={item.color ? colors[item.color] : undefined}
          onPress={() => {
            // @ts-expect-error
            navigation.navigate(item.target, item.params);
          }}
        >
          {item.title}
        </NavButton>
      )}
    />
  );
};

const Sections: Section[] = [
  {
    title: 'Online Booking',
    description: 'Links to online booking for various practices',
    data: [
      {
        title: 'Ally Test',
        target: Screens.ONLINE_BOOKING,
        params: {
          practice: 'ally_test'
        }
      },
      {
        title: 'Evet Test',
        target: Screens.ONLINE_BOOKING,
        params: {
          practice: 'evet_test'
        }
      },
      {
        title: 'Unknown Practice',
        target: Screens.ONLINE_BOOKING,
        params: {
          practice: 'bad'
        },
        color: 'error'
      }
    ]
  }
];

export default Sandbox;

interface Section {
  title: string;
  data: NavLocation[];
  description: string;
}

interface NavLocation {
  title: string;
  target: Screens;
  params: Record<string, any>;
  custom?: React.ReactNode;
  color?: keyof AppColors;
}

const NavButton = styled(HoverButton)`
  align-self: flex-start;
`;

const StyledHeadline = styled(Headline)`
  background: ${({ theme }) => theme.colors.background};
`;
