import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const CalendarPending: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg fill={color} width={size} height={size} viewBox='0 0 20 20'>
      <Path d='M14.5556 1.25C14.5556 0.697715 14.1078 0.25 13.5556 0.25C13.0033 0.25 12.5556 0.697715 12.5556 1.25H14.5556ZM12.5556 4.782C12.5556 5.33428 13.0033 5.782 13.5556 5.782C14.1078 5.782 14.5556 5.33428 14.5556 4.782H12.5556ZM7.44444 1.25C7.44444 0.697715 6.99673 0.25 6.44444 0.25C5.89216 0.25 5.44444 0.697715 5.44444 1.25H7.44444ZM5.44444 4.782C5.44444 5.33428 5.89216 5.782 6.44444 5.782C6.99673 5.782 7.44444 5.33428 7.44444 4.782H5.44444ZM11 7.19018C11 6.6379 10.5523 6.19018 10 6.19018C9.44772 6.19018 9 6.6379 9 7.19018H11ZM10.4708 12.7688L10.9828 11.9098L10.4708 12.7688ZM11.9123 14.7921C12.3867 15.0748 13.0005 14.9195 13.2832 14.4451C13.566 13.9707 13.4106 13.3569 12.9362 13.0741L11.9123 14.7921ZM3.77778 4.016H16.2222V2.016H3.77778V4.016ZM16.2222 4.016C16.6581 4.016 17 4.36521 17 4.782H19C19 3.24812 17.7501 2.016 16.2222 2.016V4.016ZM17 4.782V17.144H19V4.782H17ZM17 17.144C17 17.5608 16.6581 17.91 16.2222 17.91V19.91C17.7501 19.91 19 18.6779 19 17.144H17ZM16.2222 17.91H3.77778V19.91H16.2222V17.91ZM3.77778 17.91C3.34195 17.91 3 17.5608 3 17.144H1C1 18.6779 2.24993 19.91 3.77778 19.91V17.91ZM3 17.144V4.782H1V17.144H3ZM3 4.782C3 4.36521 3.34195 4.016 3.77778 4.016V2.016C2.24993 2.016 1 3.24812 1 4.782H3ZM12.5556 1.25V4.782H14.5556V1.25H12.5556ZM5.44444 1.25V4.782H7.44444V1.25H5.44444ZM9 7.19018V11.9428H11V7.19018H9ZM9.95881 13.6278L11.9123 14.7921L12.9362 13.0741L10.9828 11.9098L9.95881 13.6278ZM9 11.9428C9 12.6356 9.36593 13.2744 9.95881 13.6278L10.9828 11.9098C10.9915 11.915 11 11.9267 11 11.9428H9Z' />
    </Svg>
  );
};

export default CalendarPending;
