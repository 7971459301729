import { RefObject, useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { IS_WEB } from 'src/constants';

interface Options {
  disabled?: boolean;
  onAnimationEnd?: (status: boolean) => void;
}
const useHoverEffect = <T = View>({ disabled, onAnimationEnd }: Options = {}): [
  RefObject<T>,
  Animated.Value
] => {
  const ref = useRef<T>(null);
  const isHovered = useHover(ref);
  const animatedValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (!disabled && IS_WEB) {
      const animation = Animated.timing(animatedValue, {
        toValue: isHovered ? 1 : 0,
        duration: 100,
        useNativeDriver: false
      });
      animation.start(() => onAnimationEnd?.(isHovered));
      return animation.stop;
    }
  }, [animatedValue, disabled, isHovered, onAnimationEnd]);

  return [ref, animatedValue];
};

export default useHoverEffect;
