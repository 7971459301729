import { endpoint } from 'src/constants/api';
import { IUserSignInResponse } from 'src/interfaces/IUserSignInResponse';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import {
  SignInRequest,
  SignUpRequest,
  ForgotPasswordRequest,
  ResendConfirmationRequest,
  SwitchPracticeRequest,
  SwitchPracticeResponse
} from './model';
import { mapKeys, snakeCase } from 'lodash';

export * from './model';

export const signIn = async (user: SignInRequest): Promise<IUserSignInResponse> => {
  const response = await apiClient.post<IUserSignInResponse>(endpoint('USERS_SIGN_IN'), {
    user: mapKeys(user, (_, key) => snakeCase(key))
  });
  return isDefinedOrThrowServerError(response.data);
};

export const renewSession = async (): Promise<IUserSignInResponse> => {
  const response = await apiClient.post<IUserSignInResponse>(endpoint('SESSION_RENEW'));
  return isDefinedOrThrowServerError(response.data);
};

export const register = async (user: SignUpRequest): Promise<void> => {
  const response = await apiClient.post(endpoint('PET_SIGN_UP'), {
    user: {
      email: user.email,
      password: user.password,
      password_confirmation: user.confirmPassword,
      practice_id: user.practices.map((p) => p.practiceId),
      patient_portal: true
    }
  });
  return response.data;
};

export const forgotPassword = async ({
  practiceId,
  clientId,
  email
}: ForgotPasswordRequest): Promise<void> => {
  await apiClient.post(endpoint('USERS_PASSWORD'), {
    user: {
      email,
      client_id: clientId,
      practice_id: practiceId
    }
  });
};

export const resendConfirmationEmail = async ({
  practiceId,
  practiceShortName,
  email
}: ResendConfirmationRequest): Promise<void> => {
  await apiClient.post(endpoint('CONFIRMATION', { practiceShortName }), {
    user: {
      email,
      practice_id: practiceId
    }
  });
};

export const switchPractice = async ({ userId }: SwitchPracticeRequest) => {
  const response = await apiClient.post<SwitchPracticeResponse>(endpoint('SWITCH_PRACTICE'), {
    user_id: userId.toString()
  });

  return isDefinedOrThrowServerError(response.data);
};

export interface PasswordResetError {
  errors: {
    resetPasswordToken?: string[];
    password?: string[];
    passwordConfirmation?: string[];
  };
}

export interface PasswordResetRQ {
  token?: string;
  password: string;
  passwordConfirmation: string;
}

export const resetPasswordWithToken = async ({
  token,
  password,
  passwordConfirmation
}: PasswordResetRQ): Promise<void> => {
  await apiClient.put(endpoint('USERS_PASSWORD'), {
    user: {
      resetPasswordToken: token,
      password,
      passwordConfirmation
    }
  });
};
