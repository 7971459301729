import _ from 'lodash';
import moment from 'moment-timezone';
import { useMutation } from 'react-query';
import { patchTodo, postTodo, deleteTodo as _deleteTodo, QueryKeys, MutationKeys } from 'src/api';
import { NOTIFICATIONS_SUPPORTED } from 'src/constants';
import { Client, Todo } from 'src/interfaces/api';
import { useNotification } from 'src/providers/NotificationProvider';
import NotificationCategory from 'src/providers/NotificationProvider/NotificationCategory';
import { todoAlertId } from 'src/providers/NotificationProvider/notificationIdGenerators';
import queryClient from 'src/utils/queryClient';
import rateUs from 'src/utils/rateUs';
import { useUser } from 'src/providers/ClientProvider';
import { postSelfPush } from 'src/api/push';
import { isDefined } from 'src/utils';

const debounceClearInvalidateQueries = _.debounce(
  async ({ sourceId, practiceId }: Client, todoId?: Todo['id']) => {
    const jobs = [];
    jobs.push(queryClient.invalidateQueries([QueryKeys.TODOS]));
    if (isDefined(todoId)) jobs.push(queryClient.invalidateQueries([QueryKeys.TODO, todoId]));
    jobs.push(queryClient.invalidateQueries([QueryKeys.PAGED_TODOS]));
    await Promise.all(jobs);
    void postSelfPush({
      type: NotificationCategory.TO_DO_UPDATE,
      sourceId,
      practiceId
    });
  },
  1000,
  {
    trailing: true,
    leading: false
  }
);

const useMutateTodos = () => {
  const { user } = useUser();
  const { clearNotification } = useNotification();
  const { mutateAsync: createTodo, isLoading: isCreating } = useMutation({
    mutationKey: [MutationKeys.EDITING_TODOS],
    mutationFn: postTodo,
    onSuccess: () => debounceClearInvalidateQueries(user)
  });

  const { mutateAsync: editTodo, isLoading: isEditing } = useMutation({
    mutationKey: [MutationKeys.EDITING_TODOS],
    mutationFn: patchTodo,
    onSuccess: ({ id }) => debounceClearInvalidateQueries(user, id)
  });

  const { mutateAsync: toggleCompleteTodo, isLoading: isCompleting } = useMutation({
    mutationKey: [MutationKeys.EDITING_TODOS],
    mutationFn: async (todo: Todo) => {
      const complete = !todo.isComplete;
      const jobs = [];
      const todoJob = patchTodo({ ...todo, isComplete: complete, period: null, periodUnit: null });
      jobs.push(todoJob);
      if (
        complete &&
        !!todo.period &&
        !!todo.periodUnit &&
        (!todo.repeatUntilDate || moment(todo.repeatUntilDate).isAfter())
      ) {
        const newTodo = _.cloneDeep(todo);
        newTodo.isComplete = false;
        newTodo.dueDate = moment(todo.dueDate)
          .add(moment.duration(todo.period, todo.periodUnit))
          .toISOString();
        jobs.push(postTodo(newTodo));
      }
      await Promise.all(jobs);
      if (complete) void rateUs();
      return todoJob;
    },
    onSuccess: ({ id }) => debounceClearInvalidateQueries(user, id)
  });

  const { mutateAsync: deleteTodo, isLoading: isDeleting } = useMutation({
    mutationKey: [MutationKeys.EDITING_TODOS],
    mutationFn: _deleteTodo,
    onSuccess: async (_, todo) => {
      void debounceClearInvalidateQueries(user);
      if (NOTIFICATIONS_SUPPORTED) {
        todo.todoAlertsAttributes.forEach((alert) => {
          clearNotification(todoAlertId(alert));
        });
      }
    }
  });

  const isMutating = isCreating || isEditing || isCompleting || isDeleting;

  return {
    isMutating,
    createTodo,
    editTodo,
    toggleCompleteTodo,
    deleteTodo
  };
};

export default useMutateTodos;
