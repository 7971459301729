import { AuthorizationStatus } from '@notifee/react-native';
import { createContext, useContext } from 'react';
import { Platform } from 'react-native';
import { NOTIFICATIONS_SUPPORTED } from 'src/constants';
import { NotificationCategory } from './NotificationCategory';

export interface GenericNotification {
  id?: string;
  title?: string | undefined;
  subtitle?: string | undefined;
  body?: string | undefined;
  data?: { [key: string]: string | object | number };
}

export interface NotificationContext {
  notify: AuthorizationStatus;
  hasPrompted: boolean;
  fireNotification: (v: GenericNotification, c: NotificationCategory) => void;
  openNotificationSettings: () => void;
  clearNotification: (notificationId: string) => void;
}

export const notificationWarning = () => {
  if (!NOTIFICATIONS_SUPPORTED) {
    console.warn(
      `Notifications not supported on ${Platform.select({
        ios: 'ios simulator',
        android: 'android emulator',
        web: 'web',
        default: 'this platform'
      })}.`,
      'To test with notifications, run `yarn prebuild && yarn [ios|android]`'
    );
  } else {
    console.warn('Notifications provider has not been initialized.');
  }
};

export const defaultContext: NotificationContext = {
  notify: -1,
  hasPrompted: true,
  fireNotification: notificationWarning,
  openNotificationSettings: notificationWarning,
  clearNotification: notificationWarning
};

export const Notification = createContext(defaultContext);

export const useNotification = () => useContext(Notification);
