import { useEffect, useRef, useState } from 'react';
import { Animated, InteractionManager } from 'react-native';
import { IS_WEB, Size, TOP_TAB_WIDTH } from 'src/constants';
import styled from 'styled-components/native';

const ANIMATED_DURATION = 300;

interface ITabBarIndicator {
  leftOffset: number;
  width: number;
}

const TabBarIndicator: React.FC<ITabBarIndicator> = ({ leftOffset, width }) => {
  const translateValue = useRef(new Animated.Value(TOP_TAB_WIDTH));
  const widthValue = useRef(new Animated.Value(TOP_TAB_WIDTH));

  const [derivedWidth, setDerivedWidth] = useState(TOP_TAB_WIDTH);

  useEffect(() => {
    const original = widthValue.current;
    const listener = original.addListener((w) => {
      setDerivedWidth(w.value);
    });
    return () => original.removeListener(listener);
  }, []);

  useEffect(() => {
    const handle = InteractionManager.createInteractionHandle();
    Animated.parallel([
      Animated.timing(translateValue.current, {
        toValue: leftOffset,
        duration: ANIMATED_DURATION,
        useNativeDriver: !IS_WEB
      }),
      Animated.timing(widthValue.current, {
        toValue: width,
        duration: ANIMATED_DURATION,
        useNativeDriver: !IS_WEB
      })
    ]).start(() => InteractionManager.clearInteractionHandle(handle));
  }, [leftOffset, width]);

  return (
    <StyledTabIndicatorAnimatedView
      style={{ transform: [{ translateX: translateValue.current }], width: derivedWidth }}
    />
  );
};

export default TabBarIndicator;

const StyledTabIndicatorAnimatedView = styled(Animated.View)`
  position: absolute;
  width: ${TOP_TAB_WIDTH}px;
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  border-bottom-width: ${Size.X4_S}px;
  bottom: 0px;
`;
