import { endpoint } from 'src/constants';
import { Client, Patient } from 'src/interfaces';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import { unauthenticatedApiClient } from 'src/utils/axios/unauthenticated';

interface Variables {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  practiceId: number;
}

export const clientSearch = async ({ firstName, lastName, phoneNumber, practiceId }: Variables) => {
  const response = await apiClient.get<Client>(
    endpoint('CLIENT_SEARCH', { firstName, lastName, phoneNumber, practiceId })
  );
  const data = isDefinedOrThrowServerError(response.data);
  return data;
};

export interface ClientPatientSearchParams {
  practiceId: number;
  email?: string;
  phoneNumber?: string;
}
export interface ClientPatientSearchResponse {
  clientId: string;
  patients: Array<Pick<Patient, 'patientId' | 'name' | 'photo' | 'species' | 'hasPhoto'>>;
}
export const clientUnauthenticatedSearch = async ({
  practiceId,
  email,
  phoneNumber
}: ClientPatientSearchParams) => {
  if (!email) {
    throw new Error('Email is required');
  }
  const response = await unauthenticatedApiClient.get<ClientPatientSearchResponse>(
    endpoint('CLIENT_UNAUTHENTICATED_SEARCH'),
    { params: { practiceId, email, phoneNumber } }
  );
  const data = isDefinedOrThrowServerError(response.data);
  return data;
};
