import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { getReward } from 'src/api/rewards';

const useReward = (rewardId?: number) => {
  return useQuery({
    queryKey: [QueryKeys.REWARD, rewardId],
    queryFn: () => {
      if (rewardId) {
        return getReward(rewardId);
      }
    }
  });
};

export default useReward;
