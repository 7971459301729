import { RequestStatus } from 'src/interfaces';
import { RequestSectionList, RequestWithPatient } from './model';
import _ from 'lodash';

const partitionRequests = (requests: RequestWithPatient[]) => {
  const sections: RequestSectionList[] = [];

  const [archived, nonArchived]: RequestWithPatient[][] = _(requests)
    .sortBy((request) => request.createdAt)
    .reverse()
    .partition((request) => request.status === RequestStatus.Archived)
    .value();
  if (nonArchived.length) {
    sections.push({ title: 'sectionTitles.newAndInProgress', data: nonArchived });
  }
  if (archived.length) {
    sections.push({ title: 'sectionTitles.archived', data: archived });
  }

  return sections;
};

export default partitionRequests;
