import React from 'react';
import { useBookingState } from '../BookingProvider';
import {
  ContentContainer,
  InputContainer,
  MainText,
  NoticeContainer,
  NoticeIcon,
  NoticeSection,
  NoticeText,
  NoticeTextContainer,
  StyledScrollView
} from './shared';
import { Caption, DropDown, Headline } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'react-native-paper';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { PreferredContactMethod } from 'src/interfaces';
const NotificationSettings: React.FC = () => {
  const { t } = useTranslation('onlineBooking');
  const { values, setFieldValue, practiceInfo, validateField } = useBookingState();
  const { colors, viewMode } = useAppTheme();

  return (
    <StyledScrollView>
      <ContentContainer>
        <MainText as={Headline}>{t('notificationSettings.title')}</MainText>
      </ContentContainer>
      <NoticeContainer>
        <NoticeSection>
          <NoticeIcon>
            <Checkbox.Android
              status={values.notificationSettings?.smsOptIn === true ? 'checked' : 'unchecked'}
              uncheckedColor={viewMode.isMobile ? colors.text : colors.onSurface}
              color={colors.primary}
              onPress={async () => {
                await setFieldValue('notificationSettings.smsOptIn', true, false);
                await validateField('notificationSettings.smsOptIn');
              }}
            />
          </NoticeIcon>
          <NoticeTextContainer>
            <NoticeText>{t('notificationSettings.optInTitle')}</NoticeText>
            <NoticeText as={Caption}>
              {t('notificationSettings.optInDescription', {
                practiceName: practiceInfo.name,
                website: practiceInfo.website
              })}
            </NoticeText>
          </NoticeTextContainer>
        </NoticeSection>
      </NoticeContainer>
      <NoticeContainer>
        <NoticeSection>
          <NoticeIcon>
            <Checkbox.Android
              status={values.notificationSettings?.smsOptIn === false ? 'checked' : 'unchecked'}
              uncheckedColor={viewMode.isMobile ? colors.text : colors.onSurface}
              color={colors.primary}
              onPress={async () => {
                await setFieldValue('notificationSettings.smsOptIn', false, false);
                await validateField('notificationSettings.smsOptIn');
              }}
            />
          </NoticeIcon>
          <NoticeTextContainer>
            <NoticeText>{t('notificationSettings.optOutTitle')}</NoticeText>
            <NoticeText as={Caption}>
              {t('notificationSettings.optOutDescription', { practiceName: practiceInfo.name })}
            </NoticeText>
          </NoticeTextContainer>
        </NoticeSection>
      </NoticeContainer>
      <InputContainer>
        <DropDown
          label={t('form.preferredContactMethod')}
          value={values.notificationSettings?.preferredContactMethod}
          options={[
            { label: t('form.text'), value: PreferredContactMethod.Text },
            { label: t('form.email'), value: PreferredContactMethod.Email },
            { label: t('form.phone'), value: PreferredContactMethod.Phone }
          ]}
          onChange={async (value) => {
            await setFieldValue('notificationSettings.preferredContactMethod', value);
            await validateField('notificationSettings.preferredContactMethod');
          }}
        />
      </InputContainer>
    </StyledScrollView>
  );
};

export default NotificationSettings;
