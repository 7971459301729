import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

interface AlertBadgeProps extends PropsWithChildren {
  hasAlert: boolean;
}
const AlertBadge: React.FC<AlertBadgeProps> = ({ children, hasAlert }) => (
  <View>
    {children}
    {hasAlert && <Badge />}
  </View>
);

export default AlertBadge;

const Badge = styled.View`
  position: absolute;
  top: ${-Size.X4_S}px;
  right: ${-Size.X4_S}px;
  background-color: ${({ theme }) => theme.colors.notification};
  border-radius: ${Size.X2_S}px;
  width: ${Size.X2_S}px;
  height: ${Size.X2_S}px;
`;
