import React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';
import { STROKE_WIDTH_DEFAULT } from 'src/constants';

const Trophy: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg strokeMiterlimit={10} viewBox='0 0 24 24' width={24} height={24} {...props}>
    <G
      fill={props.fill ?? 'none'}
      stroke={props.stroke ?? props.color ?? '#000'}
      strokeWidth={props.strokeWidth ?? STROKE_WIDTH_DEFAULT}
    >
      <Path d='M8.786 21A3.214 3.214 0 0 1 12 17.786h0A3.215 3.215 0 0 1 15.214 21M6.214 21h11.572M6.272 4.286H4.286A1.286 1.286 0 0 0 3 5.57v2.213c0 2.451 1.088 4.562 3.491 5.043l1.648.33M17.728 4.286h1.986A1.286 1.286 0 0 1 21 5.57v2.213c0 2.451-1.088 4.562-3.491 5.043l-1.648.33M12 14.571v3.215' />
      <Path d='M12 14.571c3.553 0 6.429-3.162 6.429-7.071A7.448 7.448 0 0 0 16.956 3H7.044a7.448 7.448 0 0 0-1.473 4.5c0 3.909 2.876 7.071 6.429 7.071Z' />
    </G>
  </Svg>
);
export default Trophy;
