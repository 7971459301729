import { Event, getEventAsync, useCalendarPermissions } from 'expo-calendar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncStorageKeys } from 'src/api';
import { HoverButton, Row } from 'src/components';
import { IS_WEB, Margin } from 'src/constants';
import { useStorage } from 'src/hooks';
import { Appointment } from 'src/interfaces';
import styled from 'styled-components/native';

interface Props {
  appointment: Appointment;
  isPast: boolean;
  width: number;
  addToCalendarPressed: () => void;
}

const AddToCalendar: React.FC<Props> = ({ appointment, isPast, width, addToCalendarPressed }) => {
  const { t } = useTranslation('addToCalendar');
  const [status] = useCalendarPermissions();

  const [calendarEventId, setCalendarEventId] = useStorage<string>([
    AsyncStorageKeys.CALENDAR_APPOINTMENT,
    appointment.appointmentId
  ]);

  const [calendarEvent, setCalendarEvent] = useState<Event>();

  useEffect(() => {
    if (calendarEventId)
      void getEventAsync(calendarEventId)
        .then((event) => {
          if (event) setCalendarEvent(event);
          else {
            setCalendarEventId(undefined);
          }
        })
        .catch((err) => console.warn(err));
  }, [calendarEventId, setCalendarEventId]);

  if (!appointment || IS_WEB || isPast || status?.status === 'denied' || !width) return null;

  return (
    <Row>
      <StyledButton
        uppercase={false}
        width={width}
        mode={'contained'}
        onPress={addToCalendarPressed}
        disabled={!!calendarEvent}
      >
        {calendarEvent ? t('addedToCalendar') : t('title')}
      </StyledButton>
    </Row>
  );
};

export default AddToCalendar;

const StyledButton = styled(HoverButton)<{ width: number }>`
  margin-top: ${Margin.Large}px;
  width: ${({ width }) => width}px;
`;
