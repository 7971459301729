import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { QueryKeys, getPaginatedPatients } from 'src/api';
import { PaginatedPatientsParams } from 'src/api/patient/getPaginatedPatients';
import { PAGE_LENGTH } from 'src/constants';
import { Patient } from 'src/interfaces';
import { getNextPageParam, PaginationRS, queryClient } from 'src/utils';

const usePaginatedPatients = ({
  search: userSearch,
  status,
  order = 'asc',
  orderBy = 'name',
  length = PAGE_LENGTH,
  ...options
}: Omit<PaginatedPatientsParams, 'start'> & UseInfiniteQueryOptions<PaginationRS<Patient>>) => {
  const [search, setSearch] = useState<string | undefined>();
  const setSearchTerm = useMemo(() => _.debounce(setSearch), [setSearch]);
  useEffect(() => {
    setSearchTerm(userSearch);
  }, [setSearchTerm, userSearch]);

  const { data: rawData, ...queryResult } = useInfiniteQuery({
    queryKey: [QueryKeys.PAGINATED_PATIENTS, search, status, order, orderBy, length],
    queryFn: async ({ pageParam = 0 }) =>
      getPaginatedPatients({ start: pageParam * length, length, search, status, order, orderBy }),
    getNextPageParam: (...options) => getNextPageParam(...options, length),
    onSuccess: (d) => {
      const lastPage = d.pages[d.pages.length - 1];
      lastPage.data.forEach((patient) => {
        queryClient.setQueryData([QueryKeys.PATIENT, patient.patientId], patient);
      });
    },
    ...options
  });

  const data = useMemo(
    () =>
      rawData?.pages?.reduce<Patient[]>((prev, current) => [...prev, ...current.data], []) ?? [],
    [rawData]
  );
  return {
    data,
    ...queryResult
  };
};

export default usePaginatedPatients;
