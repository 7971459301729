import React from 'react';
import { UseMutateFunction } from 'react-query';
import { Caption, Subheading } from 'src/components';
import { HoverCard } from 'src/components/kit';
import { Size } from 'src/constants';
import { IUserSignInResponse } from 'src/interfaces';
import UserSearchResult from 'src/interfaces/api/UserSearchResult';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

interface Props {
  searchResult: UserSearchResult;
  impersonate: UseMutateFunction<IUserSignInResponse, unknown, UserSearchResult, unknown>;
}

const UserSearchResultItem: React.FC<Props> = ({ searchResult, impersonate }) => {
  return (
    <StyledCard onPress={() => impersonate(searchResult)}>
      <Subheading>{searchResult.name}</Subheading>
      <Caption>{searchResult.resourceName}</Caption>
    </StyledCard>
  );
};

export default UserSearchResultItem;

const StyledCard = styled(HoverCard)`
  align-items: center;
  padding: ${Size.S}px;
  background-color: ${({ theme }) =>
    theme.dark ? palette.BLACK_OPACITY_70 : palette.WHITE_OPACITY_70};
  gap: ${Size.X2_S}px;
  margin-top: ${Size.X2_S}px;
`;
