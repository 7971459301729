import AppointmentType from './AppointmentType';

export interface Schedule {
  id: number;
  practiceId: number;
  type: string;
  createdAt?: Date;
  updatedAt?: Date;
  categoryType?: CategoryType;
  useAppointmentTypes?: boolean;
  allowCategorySelection?: boolean;
  daysInAdvanceMin?: number;
  daysInAdvanceMax?: number;
  allowNoPreference?: boolean;
  requireComments?: boolean;
  appointmentTypes?: ScheduleAppointmentType[];
  categories?: Record<string, Category>;
}

export enum CategoryType {
  RESOURCE = 'resource',
  TYPE = 'type',
  EMPLOYEE = 'employee'
}

export interface ScheduleAppointmentType {
  id: number;
  description: string;
  length: number;
  createdAt?: Date;
  updatedAt?: Date;
  appointmentType?: AppointmentType;
  categories?: string[];
  appointmentTypeEntries: ScheduleAppointmentTypeEntry[];
  appointmentTypeEntryIds: number[];
}

export interface Category {
  id: number;
  description: string;
  descriptionOverride?: string;
  duplicateOfAppointmentCategoryId?: string;
  appointmentLength?: number;
  updatedAt: Date;
  createdAt: Date;
  toggleEntryAppointmentType?: false;
  entries: ScheduleEntry[];
}

interface ScheduleEntry {
  id: number;
  startTime?: string;
  endTime?: string;
  updatedAt?: Date;
  createdAt?: Date;
  days?: string[]; // days of week
  breakTimes?: BreakTime[];
  appointmentTypeCount?: number;
}

// for some reason these don't come through as camelcase
// probably doesn't matter for us
interface ScheduleAppointmentTypeEntry {
  id: number;
  start_time?: string;
  end_time?: string;
  updated_at?: Date;
  created_at?: Date;
  days?: boolean[]; // length 7, true or false for days of week
  owner_id?: number;
  owner_type?: string;
  message: string;
  message_sends?: string;
  all_day?: boolean;
}

interface BreakTime {
  breakStartTime: string;
  breakEndTime: string;
}

export interface Openings {
  [key: string]: string[];
}
