import { Body, Headline, HoverButton, HoverCard, TextInput } from 'src/components';
import { IconSize, Margin, Size } from 'src/constants';
import { transparent } from 'src/utils';
import styled from 'styled-components/native';
import { Weight } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';

export const StyledScrollView = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    flexGrow: 1,
    marginHorizontal: theme.viewMode.horizontalInset,
    gap: Margin.Large,
    padding: Size.X2_S,
    paddingBottom: 50
  }
}))``;

export const StyledHoverCard = styled(HoverCard)`
  padding-vertical: ${Margin.Large}px;
  gap: ${Margin.Large}px;
  width: 100%;
`;

export const HelpCenterHoverCard = styled(HoverCard)`
  padding: ${Margin.ExtraLarge}px;
  gap: ${Margin.ExtraLarge}px;
  width: 100%;
`;

export const StyledHoverCardRow = styled(HoverCard)`
  padding: ${Margin.Large}px;
  flex-direction: row;
`;

export const StyledHeader = styled(HoverCard).attrs({ hideShadow: true })`
  padding-horizontal: ${Margin.Large}px;
  flex-direction: row;
  align-items: center;
`;

export const AnswerText = styled(Body)`
  padding-horizontal: ${Margin.Large}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const SectionTitle = styled(Headline)`
  padding: ${Margin.Medium}px;
`;

export const FilledIconContainer = styled.View`
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.15)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.X2_S}px;
`;

export const NeedHelpText = styled(Body)`
  align-self: center;
  text-align: center;
`;

export const StyledTitle = styled(Body)`
  align-self: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const Link = styled(Body)`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${Weight.MEDIUM};
`;

export const SearchInput = styled(TextInput)`
  background-color: ${({ theme }) => theme.colors.surface};
  margin-bottom: ${Margin.Large}px;
`;

export const ToggleIconContainer = styled.View`
  margin-left: ${Margin.Large}px;
`;

export const StyledHoverButton = styled(HoverButton).attrs({
  mode: 'contained',
  numberOfLines: 2,
  labelStyle: {
    textAlign: 'center',
    ...fontStyles.body,
    fontWeight: Weight.MEDIUM
  }
})`
  padding: ${Size.S}px;
  gap: ${Size.XS}px;
`;
