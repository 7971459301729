import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import { Headline, HoverButton, Row, Slider } from 'src/components';
import { Size } from 'src/constants';
import { useAnnouncements } from 'src/hooks/react-query';
import styled from 'styled-components/native';
import { useBoolean } from 'src/hooks';
import Icon from 'react-native-vector-icons/Fontisto';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from '../tour';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import AnnouncementSlide from './AnnouncementSlide';
import { containers } from 'src/theme/globalStyles';

const AnnouncementsCarousel: React.FC = () => {
  const { t } = useTranslation('home');
  const { data: announcements } = useAnnouncements();
  const { colors } = useAppTheme();
  const sliderRef = useRef<FlatList<SlideItem>>(null);
  const { value: isAutoPlay, toggle: toggleAuto } = useBoolean(true);

  const activeAnnouncements: SlideItem[] = useMemo(() => {
    if (!announcements) return [];
    return announcements
      .filter((a) => !a.archivedAt && !a.disabled)
      .map((announcement) => ({
        body: announcement.text
      }));
  }, [announcements]);

  const hasAnnouncements = !!activeAnnouncements?.length;

  if (!hasAnnouncements) {
    return null;
  }

  return (
    <GuideElement id={STEPS.ANNOUNCEMENTS} body={t('tour.announcements')}>
      <Container style={containers.shadow}>
        <Row style={{ justifyContent: 'space-between' }}>
          <StyledTitle>{t('announcements')}</StyledTitle>
          <HoverButton onPress={toggleAuto} style={{ marginHorizontal: Size.XS }}>
            <Icon name={isAutoPlay ? 'pause' : 'play'} color={colors.onSurface} />
          </HoverButton>
        </Row>
        <Slider
          data={activeAnnouncements}
          ref={sliderRef}
          SlideComponent={AnnouncementSlide}
          isAutoPlay={isAutoPlay}
        />
      </Container>
    </GuideElement>
  );
};

export default AnnouncementsCarousel;

const StyledTitle = styled(Headline)`
  color: ${({ theme }) => theme.colors.onSurface};
  padding: ${Size.S}px;
`;
const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.roundness}px;
  padding-bottom: ${Size.M}px;
`;
