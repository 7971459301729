import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import React from 'react';
import { Client } from 'src/interfaces/api';
import { emailBouncedOrMarkedSpam } from 'src/utils/validators/email';
import {
  Bell,
  Edit,
  HelpCircle,
  Info,
  MessageSquare,
  Settings,
  Shield
} from 'react-native-feather';
import { NOTIFICATIONS_SUPPORTED, STROKE_WIDTH_DEFAULT } from 'src/constants';
import { STEPS } from './tour';

interface NavItem {
  readonly icon: (color: string) => React.JSX.Element;
  readonly label: string;
  readonly location: Screens | Navigators | string;
  readonly requiresAttention?: boolean;
  readonly step: number;
  readonly tourDescription: string;
  readonly isLastStep?: boolean;
}

const navItems = (user: Client): NavItem[] => {
  const unfilteredItems: Array<false | NavItem> = [
    {
      icon: (color: string) => <Info color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'profileRoot:myInfo',
      location: Screens.PROFILE_USERINFO,
      requiresAttention:
        !user.mobilePhone ||
        user.badPhysicalAddress ||
        emailBouncedOrMarkedSpam(user.emailVerificationStatus),
      step: STEPS.INFO,
      tourDescription: 'profileRoot:tour.info'
    },
    {
      icon: (color: string) => <Settings color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'profileRoot:appSettings',
      location: Screens.PROFILE_APPSETTINGS,
      step: STEPS.APP,
      tourDescription: 'profileRoot:tour.appSettings'
    },
    {
      icon: (color: string) => <MessageSquare color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'profileRoot:clientPracticeConfiguration',
      location: Screens.PROFILE_CLIENT_PRACTICE_CONFIGURATION,
      step: STEPS.COMMUNICATION,
      tourDescription: 'profileRoot:tour.commSettings'
    },
    NOTIFICATIONS_SUPPORTED && {
      icon: (color: string) => <Bell color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'profileRoot:notifySettings',
      location: 'notifications',
      step: STEPS.NOTIFICATION,
      tourDescription: 'profileRoot:tour.notificationSettings'
    },
    {
      icon: (color: string) => <Shield color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'profileRoot:privacyPolicy',
      location: 'https://www.allydvm.com/privacy',
      step: STEPS.PRIVACY,
      tourDescription: 'profileRoot:tour.privacyPolicy'
    },
    {
      icon: (color: string) => <Edit color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'profileRoot:termsAndCond',
      location: 'https://www.allydvm.com/terms-and-conditions',
      step: STEPS.TERMS,
      tourDescription: 'profileRoot:tour.termsAndCond',
      isLastStep: true
    },
    {
      icon: (color: string) => <HelpCircle color={color} strokeWidth={STROKE_WIDTH_DEFAULT} />,
      label: 'helpCenter:title',
      location: Screens.HELP_CENTER,
      step: STEPS.HELP,
      tourDescription: 'profileRoot:tour.helpCenter'
    }
  ];
  return unfilteredItems.filter<NavItem>((item: false | NavItem): item is NavItem => !!item);
};

export default Object.freeze(navItems);
