import React from 'react';
import { CPrimaryButton, StyledSectionHeader, Subheading } from 'src/components';
import { Margin } from 'src/constants';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { ControlConfirmationModalHook } from './useControlConfirmationModal';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const ManageAccountSection: React.FC<ControlConfirmationModalHook> = ({ promptDelete }) => {
  const { t } = useTranslation('settings');
  const { colors } = useAppTheme();

  return (
    <>
      <StyledSectionHeader>
        <Subheading>{t('manageAccount.manageAccount')}</Subheading>
      </StyledSectionHeader>
      <StyledDeleteButton color={colors.error} onPress={promptDelete}>
        {t('manageAccount.deleteMyAccount')}
      </StyledDeleteButton>
    </>
  );
};

export default ManageAccountSection;

const StyledDeleteButton = styled(CPrimaryButton)`
  margin-horizontal: ${Margin.Small}px;
  margin-vertical: ${Margin.Medium}px;
`;
