import React, { useCallback, useMemo, useState } from 'react';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';
import { Checkbox } from 'react-native-paper';
import { SectionTitle } from './style';
import _ from 'lodash';
import { HoverButton, Headline } from 'src/components';
import styled from 'styled-components/native';
import { Margin, Size } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS, useTourGuide } from '../tour';
import { View } from 'react-native';

const isSamePractice = (a: AvailablePractice, b: AvailablePractice) =>
  a.sourceId === b.sourceId && a.practiceId === b.practiceId;
interface Props {
  availablePractices?: AvailablePractice[];
  selectedPractices: AvailablePractice[];
  onSubmit: (selectedPractices: AvailablePractice[]) => void;
}

const MultiplePractices: React.FC<Props> = ({ availablePractices, onSubmit }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('login');
  const { exitTour } = useTourGuide();

  const { registered, unregistered } = useMemo(() => {
    const u = _.sortBy(
      availablePractices?.filter((practice) => !practice.userRegistered),
      'practiceName'
    );
    const r = _.sortBy(
      availablePractices?.filter((practice) => practice.userRegistered),
      'practiceName'
    );

    return { unregistered: u, registered: r };
  }, [availablePractices]);

  const [selected, setSelected] = useState<AvailablePractice[]>(unregistered);

  const toggleSelect = useCallback(
    (practice: AvailablePractice) => {
      setSelected((sel) => {
        const selectedIndex = sel.findIndex((p) => isSamePractice(p, practice));
        if (selectedIndex !== -1) {
          return [...sel.slice(0, selectedIndex), ...sel.slice(selectedIndex + 1)];
        } else {
          return [...sel, practice];
        }
      });
    },
    [setSelected]
  );

  return (
    <Container>
      <SectionTitle color={colors.onPrimary}>{t('multiplePracticesFound')}</SectionTitle>

      <GuideElement
        semiTransparentBg
        id={STEPS.MULTIPLE_PRACTICES_AVAILABLE}
        body={t('registerForMultiple')}
      >
        <View>
          <Headline>{t('selectPractices')}</Headline>
          {unregistered.map((practice) => (
            <Selectable
              key={practice.practiceName}
              onPress={() => toggleSelect(practice)}
              icon={() => (
                <Checkbox.Android
                  color={colors.onPrimary}
                  uncheckedColor={colors.onPrimary}
                  status={
                    selected.find((p) => isSamePractice(p, practice)) ? 'checked' : 'unchecked'
                  }
                />
              )}
            >
              {practice.practiceName}
            </Selectable>
          ))}
        </View>
      </GuideElement>

      {!!registered.length && (
        <GuideElement
          semiTransparentBg
          id={STEPS.PRACTICES_REGISTERED}
          body={t('theseAreAvailable')}
        >
          <View>
            <Headline>{t('Already Registered')}</Headline>
            {registered.map((practice) => (
              <Selectable key={practice.practiceName}>{practice.practiceName}</Selectable>
            ))}
          </View>
        </GuideElement>
      )}
      <HoverButton
        mode={'contained'}
        onPress={() => {
          onSubmit(selected);
          exitTour();
        }}
        labelStyle={{ color: colors.onPrimary }}
        disabled={!selected.length}
        style={{ marginTop: Size.S }}
      >
        {t('signUp')}
      </HoverButton>
    </Container>
  );
};

export default MultiplePractices;

const Selectable = styled(HoverButton).attrs(({ theme, labelStyle }) => ({
  labelStyle: [
    {
      color: theme.colors.onPrimary,
      flexGrow: 1,
      textAlign: 'left',
      padding: Margin.Medium
    },
    labelStyle
  ],
  compact: true
}))`
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  flex-shrink: 0;
`;

const Container = styled.View`
  gap: ${Size.S}px;
`;
