import { InferType, array, number, object, string } from 'yup';
import { t } from 'i18next';
import { ImageUpload } from 'src/components/UploadFiles';
import _ from 'lodash';

export type FormikData = InferType<typeof validationSchema>;

export const isFieldDirty = (
  field: keyof FormikData,
  values: FormikData,
  initialValues: FormikData
): boolean => !_.isEqual(values[field], initialValues[field]);

export const validationSchema = object({
  patientId: string().min(1).required(t('common:required')),
  itemDetails: string()
    .max(80, t('requests:wizard.maxLengthItemDetails'))
    .required(t('common:required')),
  comments: string().max(255, t('requests:wizard.maxLengthComments')).optional(),
  quantity: string().optional(),
  petName: string().optional(),
  preferredContactMethod: string().required(t('common:required')),
  images: object()
    .test({
      test: (imagesDict: Record<string, ImageUpload>): boolean =>
        Object.values(imagesDict).every((image: ImageUpload) => !image?.error),
      message: t('patientProfile:deleteBeforeProceeding'),
      name: 'deleteBeforeProceeding'
    })
    .test({
      test: (imagesDict: Record<string, ImageUpload>): boolean =>
        Object.values(imagesDict).every((image: ImageUpload) => !!image?.id),
      message: t('common:imagesNotReady'),
      name: 'imagesNotReady'
    })
    .default({}),
  requestImageIds: array().of(number()).default([])
});

export const populateConfirmationArray = (
  values: FormikData,
  initialValues: FormikData,
  itemArray: Array<{ label: string; value?: string }>
) => {
  if (isFieldDirty('itemDetails', values, initialValues)) {
    itemArray.push({
      label: t('requests:wizard.itemDetails'),
      value: values.itemDetails ?? ''
    });
  }

  if (isFieldDirty('comments', values, initialValues)) {
    itemArray.push({
      label: t('requests:wizard.comments'),
      value: values.comments ?? ''
    });
  }

  if (isFieldDirty('quantity', values, initialValues)) {
    itemArray.push({
      label: t('requests:wizard.quantity'),
      value: values.quantity ?? ''
    });
  }

  return itemArray;
};

export const initialValues: FormikData = {
  patientId: '',
  preferredContactMethod: 'email',
  petName: '',
  itemDetails: '',
  comments: undefined,
  quantity: undefined,
  images: {},
  requestImageIds: undefined
};
