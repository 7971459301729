import DogAndCat from 'assets/customizations/onlineBooking/leftHero/dog_and_cat.png';
import CanineFelineEquineExotic from 'assets/customizations/onlineBooking/leftHero/canine_feline_equine_exotic.jpg';
import CanineFelineEquine from 'assets/customizations/onlineBooking/leftHero/canine_feline_equine.jpg';
import CanineFelineExotic2 from 'assets/customizations/onlineBooking/leftHero/canine_feline_exotic_2.jpg';
import CanineFelineExotic from 'assets/customizations/onlineBooking/leftHero/canine_feline_exotic.jpg';
import Equine from 'assets/customizations/onlineBooking/leftHero/equine.jpg';
import Exotic from 'assets/customizations/onlineBooking/leftHero/exotic.jpg';
import Feline from 'assets/customizations/onlineBooking/leftHero/feline.jpg';
import { LeftImageCustomization } from 'src/interfaces';

const getOnlineBookingHero = (leftImage?: LeftImageCustomization) => {
  switch (leftImage) {
    case LeftImageCustomization.DOG_AND_CAT:
      return DogAndCat;
    case LeftImageCustomization.CANINE_FELINE_EQUINE_EXOTIC:
      return CanineFelineEquineExotic;
    case LeftImageCustomization.CANINE_FELINE_EQUINE:
      return CanineFelineEquine;
    case LeftImageCustomization.CANINE_FELINE_EXOTIC_2:
      return CanineFelineExotic2;
    case LeftImageCustomization.CANINE_FELINE_EXOTIC:
      return CanineFelineExotic;
    case LeftImageCustomization.EQUINE:
      return Equine;
    case LeftImageCustomization.EXOTIC:
      return Exotic;
    case LeftImageCustomization.FELINE:
      return Feline;
    default:
      return DogAndCat;
  }
};

export default getOnlineBookingHero;
