import React from 'react';
import { Caption } from 'src/components';
import { Size } from 'src/constants';
import { useAuth } from 'src/providers/AuthProvider';
import { palette, Weight } from 'src/theme';
import styled from 'styled-components/native';

interface Props {
  isFetched: boolean;
}

const AuthErrorMessage: React.FC<Props> = ({ isFetched }) => {
  const { error } = useAuth();

  if (!isFetched) return null;

  return (
    <>
      {!!error?.message && (
        <ErrorMessageContainer>
          <ErrorCaption>{error?.message}</ErrorCaption>
        </ErrorMessageContainer>
      )}
      <FlexContainer />
    </>
  );
};

export default AuthErrorMessage;

const ErrorCaption = styled(Caption)`
  color: ${({ theme }) => theme.colors.error};
  font-weight: ${Weight.REGULAR};
  letter-spacing: 0.5px;
`;

const ErrorMessageContainer = styled.View`
  background-color: ${({ theme: { dark } }) =>
    dark ? palette.BLACK_OPACITY_70 : palette.WHITE_OPACITY_70};
  border-radius: ${Size.X3_S}px;
  padding: ${Size.XS}px ${Size.XS}px;
  align-items: flex-start;
`;

const FlexContainer = styled.View`
  flex: 1;
`;
