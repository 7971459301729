import _ from 'lodash';
import React from 'react';
import { Shimmer } from 'src/components';
import { IconSize, Margin } from 'src/constants';
import { fontStyles } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import { Container, AvatarContainer, RightIconContainer, PaperCard, DataContainer } from './shared';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  numRightIcons: number;
}
const AppointmentListItemLoading: React.FC<Props> = ({ numRightIcons }) => {
  const { roundness } = useAppTheme();
  return (
    <PaperCard>
      <Container>
        <AvatarContainer>
          <Shimmer width={IconSize.L} height={IconSize.L} borderRadius={roundness} />
        </AvatarContainer>
        <DataContainer>
          <StyledShimmer width={'50%'} height={fontStyles.caption.lineHeight}></StyledShimmer>
          <StyledShimmer width={'70%'} height={fontStyles.caption.lineHeight} />
        </DataContainer>

        {_.times(numRightIcons, (i) => (
          <RightIconContainer key={i.toString()}>
            <Shimmer size={IconSize.XS} />
          </RightIconContainer>
        ))}
      </Container>
    </PaperCard>
  );
};

export default AppointmentListItemLoading;

const StyledShimmer = styled(Shimmer)`
  margin: ${Margin.ExtraSmall}px;
`;
