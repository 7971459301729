import * as Linking from 'expo-linking';
import { t } from 'i18next';
import { Platform } from 'react-native';
import Toast from 'react-native-toast-message';

const openWebBrowser = (url: string) => {
  const cleanUrl = url.match('^http(s)?://') ? url : `https://${url}`;

  Linking.canOpenURL(cleanUrl)
    .then((supported) => {
      if (!supported) {
        Toast.show({
          type: 'error',
          text1: t('unableToOpen', { ns: 'common', url })
        });
      } else if (Platform.OS === 'web') {
        window.open(cleanUrl, '_blank');
      } else {
        return Linking.openURL(cleanUrl);
      }
    })
    .catch((err) => console.error(err));
};

export default openWebBrowser;
