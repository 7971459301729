import { EmailVerificationStatus } from 'src/interfaces';
import { AvailablePractice, UserConfirmationStatus } from 'src/interfaces/api/AvailablePractices';
import { isDefined } from 'src/utils';
import { array, boolean, InferType, object, string, StringSchema, TestContext } from 'yup';

export const emailSchema = string()
  .default('')
  .email('error.emailMustBeValid')
  .min(6, 'error.emailMustBeValid')
  .required();

export const validationSchema = object({
  email: emailSchema,
  rememberMe: boolean().default(true),
  password: string()
    .default('')
    .when(['selectedPractices'], {
      is: (selectedPractices: AvailablePractice[]) => !!selectedPractices.length,
      then: (schema: StringSchema) =>
        schema.required('error.passwordRequired').when(['selectedPractices'], {
          is: (selectedPractices: AvailablePractice[]) => !selectedPractices[0]?.userRegistered,
          then: (schema: StringSchema) => schema.min(8, 'error.passwordMinLength')
        })
    }),
  confirmPassword: string()
    .default('')
    .when(['selectedPractices'], {
      is: (selectedPractices: AvailablePractice[]) =>
        !!selectedPractices[0] && !selectedPractices[0]?.userRegistered,
      then: (schema: StringSchema) =>
        schema.required('error.confirmationPasswordRequired').when('password', (password, schema) =>
          schema.test({
            test: (newPassword: string) => !!password && newPassword === password,
            message: 'error.passwordsMustMatch'
          })
        )
    }),
  availablePractices: array<AvailablePractice>()
    .default(undefined)
    .when('email', {
      is: (email: string) => emailSchema.isValidSync(email),
      then: (schema) =>
        schema.test({
          message: 'error.emailNotFound',
          test: (v) => !isDefined(v) || !!v.length
        })
    }),
  selectedPractices: array<AvailablePractice>()
    .default([])
    .when('availablePractices', {
      is: (availablePractices: AvailablePractice[]) => !!availablePractices?.length,
      then: (schema) =>
        schema
          .min(1)
          .test({
            name: 'is-confirmed',
            test: (value: AvailablePractice[], ctx: TestContext<AvailablePractice>) => {
              for (let i = 0; i < value.length; i++) {
                switch (value[i].userConfirmationStatus) {
                  case UserConfirmationStatus.PENDING:
                    return ctx.createError({
                      message: 'error.pendingConfirmation'
                    });
                  default:
                    break;
                }
              }
              return true;
            }
          })
          .test({
            name: 'is-email-verified',
            test: (value: AvailablePractice[], ctx: TestContext<AvailablePractice>) => {
              if (value.length === 1 && value[0].userRegistered) {
                return true;
              }
              for (let i = 0; i < value.length; i++) {
                switch (value[i]?.emailVerificationStatus) {
                  case EmailVerificationStatus.INVALID:
                  case EmailVerificationStatus.BAD:
                  case EmailVerificationStatus.DECLINED:
                    return ctx.createError({
                      message: 'error.emailFoundContactPractice'
                    });
                  case EmailVerificationStatus.BOUNCED:
                  case EmailVerificationStatus.SPAM_REPORT:
                    return ctx.createError({
                      message: 'error.emailFoundContactUs'
                    });
                  default:
                    break;
                }
              }
              return true;
            }
          })
    })
});

export interface LoginForm extends InferType<typeof validationSchema> {}
