import React from 'react';
import { useBoolean } from 'src/hooks';
import HeaderToggle from './HeaderToggle';
import { LayoutAnimation } from 'react-native';
import { AnswerText, StyledHoverCard } from '../style';
import { Divider } from 'react-native-paper';

interface Props {
  question?: string;
  answer?: string;
}

const FaqCard: React.FC<Props> = ({ question, answer }) => {
  const { value: isOpen, toggle } = useBoolean();

  if (!question || !answer) return null;
  return (
    <StyledHoverCard>
      <HeaderToggle
        label={question}
        toggle={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          toggle();
        }}
        isOpen={isOpen}
      />
      {isOpen && <Divider />}
      {isOpen && <AnswerText>{answer}</AnswerText>}
    </StyledHoverCard>
  );
};

export default FaqCard;
