import React, { useMemo, useRef } from 'react';
import { HoverButton, Slider } from 'src/components';
import { BookOpen } from 'react-native-feather';
import { FlatList, LayoutAnimation, View } from 'react-native';
import { useBoolean } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import {
  CallToAction,
  HeadingIconContainer,
  StyledHeader,
  StyledHeading,
  StyledHoverView
} from './shared';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import useLifelearnArticles from 'src/hooks/react-query/useLifelearnArticles';
import { containers } from 'src/theme/globalStyles';
import LifelearnSlide, { LifelearnSlideItem } from './LifelearnSlide';
import Icon from 'react-native-vector-icons/Fontisto';
import { GuideElement, STEPS } from '../tour';
import { IconSize, Margin, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const ARTICLE_MAX_COUNT = 4;

const LifelearnCard: React.FC = () => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation<NavigationProp<HomeStackParamsList>>();
  const { colors } = useAppTheme();

  const { value: isOpen, toggle } = useBoolean(true);

  const { articles, isLoading } = useLifelearnArticles({});

  const sliderRef = useRef<FlatList<LifelearnSlideItem>>(null);

  const slideItems: LifelearnSlideItem[] = useMemo(() => {
    if (!articles) return [];
    return articles.slice(0, ARTICLE_MAX_COUNT).map((article) => ({
      id: article.id,
      imageUri: article.featured_image.image,
      title: article.title,
      body: article.summary,
      type: 'lifelearn'
    }));
  }, [articles]);

  const { value: isAutoPlay, toggle: toggleAuto } = useBoolean(true);

  if (isLoading || !articles?.length) return null;

  return (
    <GuideElement id={STEPS.LIFELEARN} body={t('tour.lifelearn')} disabled={!articles}>
      <StyledHoverView style={containers.shadow}>
        <StyledHeader
          onPress={() => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
            toggle();
          }}
        >
          <>
            <HeadingIconContainer>
              <BookOpen color={colors.onSurface} width={IconSize.S} height={IconSize.S} />
            </HeadingIconContainer>
            <StyledHeading>{t('careInsights')}</StyledHeading>
            <HoverButton onPress={toggleAuto} contentStyle={{ paddingHorizontal: Size.XS }}>
              <Icon name={isAutoPlay ? 'pause' : 'play'} color={colors.onSurface} />
            </HoverButton>
          </>
        </StyledHeader>
        {isOpen && (
          <>
            <View style={{ gap: Margin.Large }}>
              <Slider
                data={slideItems}
                ref={sliderRef}
                SlideComponent={LifelearnSlide}
                isAutoPlay={isAutoPlay}
              />
            </View>
            <CallToAction onPress={() => navigate(Screens.ARTICLES_HUB)}>
              {t('visitArticleHub')}
            </CallToAction>
          </>
        )}
      </StyledHoverView>
    </GuideElement>
  );
};

export default LifelearnCard;
