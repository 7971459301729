import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from '../IconProps';

const Bird: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 36 36' fill='none'>
      <Path
        d='M34.5 17.25L29.925 15.555C29.535 13.83 28.56 12.84 28.56 12.84C27.9764 12.2552 27.2831 11.7913 26.52 11.4747C25.7568 11.1581 24.9387 10.9952 24.1125 10.9952C23.2863 10.9952 22.4682 11.1581 21.705 11.4747C20.9419 11.7913 20.2486 12.2552 19.665 12.84L17.445 15.06L7.5 4.5C6 10.5 7.5 16.5 11.175 21.33L3 29.25C3 29.25 16.335 32.25 24.105 26.175C28.245 22.935 29.175 21.045 29.76 19.05L34.5 17.25ZM26.565 17.58C25.98 18.165 25.02 18.165 24.435 17.58C24.2959 17.4412 24.1856 17.2764 24.1104 17.0949C24.0351 16.9135 23.9963 16.719 23.9963 16.5225C23.9963 16.326 24.0351 16.1315 24.1104 15.9501C24.1856 15.7686 24.2959 15.6038 24.435 15.465C25.02 14.88 25.98 14.88 26.565 15.465C27.15 16.05 27.15 16.995 26.565 17.58Z'
        fill={color}
      />
    </Svg>
  );
};

export default Bird;
