import * as React from 'react';
import { View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Body, Caption } from 'src/components';
import { layout } from 'src/theme/globalStyles';
import { isDefined } from 'src/utils';
import CContactAlly from './CContactAlly';
import CHelperText from './CHelperText';
import { style } from './style';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  label: string;
  line1?: string | null;
  line2?: string;
  error?: string;
  contactUsVisible?: boolean;
}

const CUserInfo: React.FC<Props> = ({ label, line1, line2, error, contactUsVisible }) => {
  const { colors } = useAppTheme();

  return (
    <View>
      <View
        style={[
          style.fieldContainer,
          isDefined(error) && style.borderWidthHalf,
          { borderColor: colors.error }
        ]}
      >
        <Caption
          style={[
            style.fieldLabel,
            style.errorLabel,
            isDefined(error) && {
              color: colors.error
            }
          ]}
        >
          {label}
        </Caption>
        <View style={style.fieldValueContainer}>
          <View>
            <Body>{(isDefined(line1) && line1) ?? ''}</Body>
            {isDefined(line2) && <Body>{line2}</Body>}
          </View>
          {isDefined(error) && (
            <Ionicons
              name='warning'
              size={18}
              color={colors.error}
              style={layout.alignSelfCenter}
            />
          )}
        </View>
      </View>
      <View style={style.helperTextContainer}>
        {isDefined(contactUsVisible) && contactUsVisible && <CContactAlly />}
        {!isDefined(contactUsVisible) && isDefined(error) && <CHelperText text={error} />}
      </View>
    </View>
  );
};

export default CUserInfo;
