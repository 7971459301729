import { endpoint } from 'src/constants';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

interface OnDemandRequest {
  practiceId: number;
}
interface OnDemandResponse {
  status: OnDemandStatus;
}

export enum OnDemandStatus {
  SUCCESS = 'SUCCESS',
  RUNNING = 'RUNNING'
}

export const onDemandSync = async (request: OnDemandRequest): Promise<OnDemandResponse> => {
  const url = endpoint('ON_DEMAND_SYNC', {
    practiceId: request.practiceId
  });
  const response = await apiClient.post<OnDemandResponse>(url);
  return isDefinedOrThrowServerError(response.data);
};

export const onDemandStatus = async (request: OnDemandRequest): Promise<OnDemandResponse> => {
  if (!request.practiceId) {
    throw new Error('commandId is required');
  }
  const url = endpoint('ON_DEMAND_STATUS', {
    practiceId: request.practiceId
  });
  const response = await apiClient.get<OnDemandResponse>(url);
  return isDefinedOrThrowServerError(response.data);
};

interface GetWritebackStatusRequest {
  requestId: number;
}

interface RawWritebackStatusResponse {
  id: number;
  writeback_status: WritebackStatus;
}

interface GetWritebackStatusResponse {
  id: number;
  writeback_status: WritebackStatus;
}

enum WritebackStatus {
  MANUAL_ENTRY_REQUIRED = 'manual_entry_required',
  SUCCESS = 'success'
}

export const getWritebackStatus = async (
  request: GetWritebackStatusRequest
): Promise<GetWritebackStatusResponse> => {
  const url = endpoint('REQUEST_STATUS', {
    request_id: request.requestId
  });
  const response = await apiClient.get<RawWritebackStatusResponse>(url);
  const rawData = isDefinedOrThrowServerError(response.data);
  return {
    id: rawData.id,
    writeback_status: rawData.writeback_status
  };
};
