import React from 'react';
import { Screens } from 'src/routes/stacks/screens';
import { useTranslation } from 'react-i18next';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import LoyaltyActivity from 'src/scenes/LoyaltyActivity';
import LoyaltyRewards from 'src/scenes/LoyaltyRewards';
import LoyaltyTabBar from 'src/routes/stacks/LoyaltyTabNavigator/components/LoyaltyTabBar';
import { FocusAwareStatusBar } from 'src/components';
import LoyaltyTabParamsList from './ParamsList';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { SliderElement, SLIDES, STEPS, TourGuide } from './tour';
import { IS_WEB } from 'src/constants';
import useDefaultStackOptions from '../useDefaultStackOptions';

const Tab = createMaterialTopTabNavigator<LoyaltyTabParamsList>();

export const LoyaltyStackNavigator: React.FC = () => {
  const { t } = useTranslation('loyalty');
  const { colors } = useAppTheme();
  const title = (pageTitle: string) => `${t('rewardsScreenTitle')}: ${pageTitle}`;

  const themedHeader = useDefaultStackOptions();

  return (
    <TourGuide.Provider>
      <TourGuide.Consumer>
        {({ enabled }) => (
          <>
            <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
            <Tab.Navigator
              tabBar={(props) => <LoyaltyTabBar {...props} />}
              screenOptions={{ ...themedHeader, animationEnabled: !IS_WEB || !enabled }}
              sceneContainerStyle={{
                backgroundColor: colors.background
              }}
            >
              <Tab.Screen
                name={Screens.LOYALTY_REWARDS}
                component={LoyaltyRewards}
                options={{
                  title: title(t('title.loyalty-rewards'))
                }}
              />
              <Tab.Screen
                name={Screens.LOYALTY_ACTIVITY}
                component={LoyaltyActivity}
                options={{
                  title: title(t('title.loyalty-activity'))
                }}
              />
            </Tab.Navigator>
            <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
          </>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
};
