import moment from 'moment';
import { UseBooleanHook } from 'src/hooks/useBoolean';
import { exampleToDo, TodoData } from './model';
import TodayPlaceholder from './TodayPlaceholder';
import { STEPS } from './tour';

export const todoData = (
  tourEnabled: boolean,
  controlsPastDue: UseBooleanHook,
  controlsToday: UseBooleanHook,
  controlsScheduled: UseBooleanHook,
  controlsCompleted: UseBooleanHook,
  sections: TodoData
) => {
  const pastDue = {
    title: 'todos:sections.pastDue',
    data: sections.pastDue,
    expanded: controlsPastDue,
    step: STEPS.PASTDUE,
    guideText: 'todos:list.tour.pastDue'
  };

  const today = {
    title: 'todos:sections.today',
    data: sections.today,
    expanded: controlsToday,
    step: STEPS.TODAY,
    guideText: 'todos:list.tour.today',
    fallback: {
      shouldDisplayPlaceholder: () =>
        !usePlaceholders &&
        !sections.pastDue.length &&
        !!(sections.complete.length || sections.scheduled.length),
      emptyPlaceholder: <TodayPlaceholder />
    }
  };

  const scheduled = {
    title: 'todos:sections.scheduled',
    data: sections.scheduled,
    expanded: controlsScheduled,
    step: STEPS.SCHEDULED,
    guideText: 'todos:list.tour.scheduled'
  };

  const completed = {
    title: 'todos:sections.completed',
    data: sections.complete,
    expanded: controlsCompleted,
    step: STEPS.COMPLETED,
    guideText: 'todos:list.tour.completed',
    completed: true
  };

  const usePlaceholders = tourEnabled && Object.values(sections).some((section) => !section.length);

  if (usePlaceholders) {
    return [
      {
        ...pastDue,
        data: [{ ...exampleToDo, dueDate: moment().subtract(1, 'day').toISOString() }]
      },
      {
        ...today,
        data: [{ ...exampleToDo, dueDate: moment().toISOString() }]
      },
      {
        ...scheduled,
        data: [{ ...exampleToDo, dueDate: moment().add(1, 'day').toISOString() }]
      },
      {
        ...completed,
        data: [
          {
            ...exampleToDo,
            dueDate: moment().toISOString(),
            isComplete: true
          }
        ]
      }
    ];
  } else {
    return [pastDue, today, scheduled, completed];
  }
};
