import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MapPin, Phone } from 'react-native-feather';
import { mask } from 'react-native-mask-text';
import { Subheading } from 'react-native-paper';
import { Body } from 'src/components';
import { IconSize, PHONE_MASK, STROKE_WIDTH_DEFAULT } from 'src/constants';
import { Appointment } from 'src/interfaces';
import { makePhoneCall, openMaps, simpleAddressFormatter, toLocalDateTime } from 'src/utils';
import { IconContainer, StyledPressable, StyledRow } from '../styled';
import useAppointment from '../hooks/useAppointment';
import { usePractice } from 'src/hooks/react-query';
import AnimalIcon from 'src/components/Icons/Animal';
import { Calendar } from 'src/components/kit/ABIcons';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import { DisplayData } from '../model';
import GuideElementWrapper from './GuideElementWrapper';
import { STEPS } from '../tour';

interface Props {
  appointmentId: Appointment['appointmentId'];
  species?: string;
}

const isDisplayData = (data: DisplayData | boolean): data is DisplayData => !!data;

const Details: React.FC<Props> = ({ appointmentId, species }) => {
  const { t, i18n } = useTranslation('appointments');
  const { colors } = useAppTheme();
  const { data: practice } = usePractice({ suspense: true });
  const { data: appointment } = useAppointment(appointmentId, { suspense: true });
  const startTime = moment(appointment?.startsAt).locale(i18n.language);

  const displayData: DisplayData[] = useMemo(() => {
    const _displayData: Array<DisplayData | false> = [
      !!appointment?.patientDisplay && {
        icon: <AnimalIcon color={colors.text} size={IconSize.XS} species={species} />,
        value: appointment.patientDisplay,
        label: t('for')
      },
      {
        icon: <Calendar color={colors.text} width={IconSize.XS} height={IconSize.XS} />,
        value: toLocalDateTime(startTime, practice?.timeZone),
        label: t('when')
      },
      !!practice?.address && {
        icon: (
          <MapPin
            color={colors.primary}
            width={IconSize.XS}
            height={IconSize.XS}
            strokeWidth={STROKE_WIDTH_DEFAULT}
          />
        ),
        value: `${practice.address}\n${practice.city}, ${practice.state} ${practice.postalCode}`,
        label: t('address', { ns: 'common' }),
        onPress: async () => await openMaps(simpleAddressFormatter(practice), practice.name),
        guide: {
          id: STEPS.MAP,
          body: 'detail-tour.map'
        }
      },
      !!practice?.phoneNumber && {
        icon: (
          <Phone
            color={colors.primary}
            width={IconSize.XS}
            height={IconSize.XS}
            strokeWidth={STROKE_WIDTH_DEFAULT}
          />
        ),
        value: mask(practice?.phoneNumber, PHONE_MASK),
        label: t('phone', { ns: 'common' }),
        onPress: () => makePhoneCall(practice.phoneNumber),
        guide: {
          id: STEPS.CALL,
          body: 'detail-tour.call'
        }
      }
    ];
    return _displayData.filter(isDisplayData);
  }, [appointment, colors.text, colors.primary, species, t, startTime, practice]);

  return (
    <>
      {displayData.map(({ value, icon, label, onPress, guide }) => (
        <GuideElementWrapper guideData={guide} key={label}>
          <StyledPressable onPress={onPress}>
            <Subheading>{label}</Subheading>
            <StyledRow>
              <IconContainer>{icon}</IconContainer>
              <Body fontWeight={Weight.MEDIUM} color={onPress ? colors.primary : colors.text}>
                {value}
              </Body>
            </StyledRow>
          </StyledPressable>
        </GuideElementWrapper>
      ))}
    </>
  );
};

export default Details;
