import React, { memo, useMemo } from 'react';
import RenderHTML from 'react-native-render-html';
import { endpoint, Margin } from 'src/constants';
import config from 'src/environment';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useAuth } from 'src/providers/AuthProvider';
import { layout } from 'src/theme/globalStyles';

const termsUri = `${config.API_URL}/${endpoint('EULA')}`;

const TermsHtml: React.FC = () => {
  const { token } = useAuth();
  const { viewMode, colors } = useAppTheme();

  const bearerToken = `Bearer ${token ?? ''}`;

  const source = useMemo(() => {
    return { uri: termsUri, headers: { Authorization: bearerToken } };
  }, [bearerToken]);
  if (!token) return null;

  return (
    <RenderHTML
      source={source}
      enableCSSInlineProcessing={false}
      contentWidth={viewMode.maxContentWidth}
      baseStyle={{
        color: colors.text,
        backgroundColor: colors.background
      }}
      tagsStyles={{
        li: { marginBottom: Margin.Large },
        h1: layout.alignSelfCenter,
        h2: layout.alignSelfCenter,
        h3: layout.alignSelfCenter
      }}
      ignoredDomTags={['br']}
    />
  );
};

export default memo(TermsHtml);
