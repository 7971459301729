import React, { useEffect } from 'react';
import { RequestFilterCriteria, SortOrder } from '../helper/requestFilter';
import ModalDrawer, { ModalDrawerHandle } from 'src/components/ModalDrawer';
import { ForwardRefFC } from 'src/types';
import { useTranslation } from 'react-i18next';
import style from 'src/scenes/ToDos/ToDoList/components/style';
import { Body, ChipContainer, DatePicker, DropDown, Headline } from 'src/components';
import { QueryKeys, getPatients } from 'src/api';
import { isPatientActive } from 'src/utils';
import { useQuery } from 'react-query';
import Chip from 'src/components/Chip';
import PatientAvatar from 'src/components/PatientAvatar';
import styled from 'styled-components/native';
import { Margin, Size } from 'src/constants';
import { RequestStatus, RequestType, Request } from 'src/interfaces';
import moment from 'moment';

interface Props {
  filters: RequestFilterCriteria;
  setFilters: (filters: RequestFilterCriteria) => void;
}

const RequestsFilterDrawer: ForwardRefFC<ModalDrawerHandle, Props> = React.forwardRef<
  ModalDrawerHandle,
  Props
>(({ filters, setFilters }, ref) => {
  const { t } = useTranslation('requests');
  const [localFilters, setLocalFilters] = React.useState<RequestFilterCriteria>(filters);
  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const { data: patients = [] } = useQuery({
    queryFn: getPatients,
    queryKey: [QueryKeys.PATIENTS],
    select: (ps) => ps.filter(isPatientActive)
  });

  const [sortByOptions, sortOrderOptions] = React.useMemo(() => {
    return [
      SORT_BY_OPTIONS.map((option) => ({ ...option, label: t(option.label) })),
      SORT_ORDER_OPTIONS.map((option) => ({ ...option, label: t(option.label) }))
    ];
  }, [t]);

  return (
    <ModalDrawer
      ref={ref}
      onClose={() => {
        setLocalFilters(filters);
      }}
      bottomButton={{
        onPress: () => {
          setFilters(localFilters);
        },
        label: t('common:applyFilters')
      }}
      cardStyle={style.drawerCardStyle}
      contentContainerStyle={style.drawerContentContainerStyle}
    >
      <StyledHeadline>{t('common:filters')}</StyledHeadline>
      <StyledSubheading>{t('common:pet')}</StyledSubheading>
      <ChipContainer>
        {patients.map((patient) => (
          <Chip
            icon={(props) => <PatientAvatar patient={patient} {...props} />}
            key={patient.patientId}
            label={patient.name}
            isSelected={patient.patientId === localFilters.patientId}
            onClose={() => setLocalFilters((prev) => ({ ...prev, patientId: undefined }))}
            onPress={() => setLocalFilters((prev) => ({ ...prev, patientId: patient.patientId }))}
          />
        ))}
      </ChipContainer>
      <StyledSubheading>{t('filters.type')}</StyledSubheading>
      <ChipContainer>
        {Object.values(RequestType).map((type) => (
          <Chip
            key={type}
            label={t(`type.${type}`)}
            isSelected={type === localFilters.type}
            onClose={() => setLocalFilters((prev) => ({ ...prev, type: undefined }))}
            onPress={() => setLocalFilters((prev) => ({ ...prev, type }))}
          />
        ))}
      </ChipContainer>
      <StyledSubheading>{t('filters.status')}</StyledSubheading>
      <ChipContainer>
        {Object.values(RequestStatus).map((s) => (
          <Chip
            key={s}
            label={t(`status.${s}`)}
            isSelected={s === localFilters.status}
            onClose={() => setLocalFilters((prev) => ({ ...prev, status: undefined }))}
            onPress={() => setLocalFilters((prev) => ({ ...prev, status: s }))}
          />
        ))}
      </ChipContainer>
      <StyledSubheading>{t('filters.date')}</StyledSubheading>
      <Row>
        <StyledDatePicker
          label={t('filters.dateFrom')}
          value={localFilters.fromDate ? new Date(localFilters.fromDate) : undefined}
          onConfirm={(fromDate) =>
            setLocalFilters((prev) => ({
              ...prev,
              fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : undefined
            }))
          }
          validRange={{
            endDate: localFilters.toDate ? new Date(localFilters.toDate) : undefined
          }}
        />
        <StyledDatePicker
          label={t('filters.dateTo')}
          value={localFilters.toDate ? new Date(localFilters.toDate) : undefined}
          onConfirm={(toDate) =>
            setLocalFilters((prev) => ({
              ...prev,
              toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : undefined
            }))
          }
          validRange={{
            startDate: localFilters.fromDate ? new Date(localFilters.fromDate) : undefined
          }}
        />
      </Row>
      <StyledSubheading>{t('filters.sort')}</StyledSubheading>
      <Row>
        <StyledDropdownContainer>
          <DropDown
            label={t('filters.sortBy')}
            value={localFilters.sortBy}
            options={sortByOptions}
            onChange={(sortBy) => setLocalFilters((prev) => ({ ...prev, sortBy }))}
          />
        </StyledDropdownContainer>
        <StyledDropdownContainer>
          <DropDown
            label={t('filters.sortOrder')}
            value={localFilters.sortOrder}
            options={sortOrderOptions}
            onChange={(sortOrder) => setLocalFilters((prev) => ({ ...prev, sortOrder }))}
          />
        </StyledDropdownContainer>
      </Row>
    </ModalDrawer>
  );
});

export default RequestsFilterDrawer;

const StyledHeadline = styled(Headline)`
  padding-left: ${Size.L}px;
`;

const StyledSubheading = styled(Body)`
  padding-left: ${Size.L}px;
`;

const Row = styled.View`
  flex-direction: row;
  padding-horizontal: ${Size.L}px;
  gap: ${Margin.Small}px;
`;

const StyledDatePicker = styled(DatePicker)`
  flex: 1;
`;

interface SortByOption {
  label: string;
  value: keyof Request;
}
const SORT_BY_OPTIONS: SortByOption[] = [
  { label: 'filters.createdAt', value: 'createdAt' },
  { label: 'filters.updatedAt', value: 'updatedAt' },
  { label: 'filters.patient', value: 'patient' },
  { label: 'filters.type', value: 'type' },
  { label: 'filters.status', value: 'status' }
];

const SORT_ORDER_OPTIONS = [
  { label: 'filters.asc', value: SortOrder.ASC },
  { label: 'filters.desc', value: SortOrder.DESC }
];

const StyledDropdownContainer = styled.View`
  flex: 1;
`;
