import moment from 'moment-timezone';
import { Appointment, AppointmentType } from 'src/interfaces';

// TODO: Remove this when Nova updates appointments api to include isVirtual
// Followup ticket: https://allydvm.atlassian.net/browse/AST-126

const VIRTUAL_TYPES = [
  AppointmentType.VID,
  AppointmentType.VID1,
  AppointmentType.TEL,
  AppointmentType.TEL1
];

export const checkIsVirtual = (appointment?: Appointment): boolean =>
  !!appointment?.appointmentTypeIds.some((type) => VIRTUAL_TYPES.includes(type));

export const checkCanConfirm = (appointment?: Appointment) => {
  if (!appointment || appointment?.confirmed || appointment?.confirmedAt) {
    return false;
  }
  const appointmentDate = moment(appointment.startsAt);
  const now = moment();
  return now.isBefore(appointmentDate) && now.isAfter(appointmentDate.clone().subtract(3, 'days'));
};

export const checkIsSoon = (appointment?: Appointment) =>
  moment(appointment?.startsAt).isAfter(moment().subtract(1, 'hour')) &&
  moment(appointment?.startsAt).isBefore(moment().add(1, 'hour'));

export const checkIsExpired = (appointment?: Appointment) =>
  moment(appointment?.startsAt).isBefore(moment().subtract(1, 'hour'));

export const checkIsPast = (appointment?: Appointment) => moment(appointment?.startsAt).isBefore();
