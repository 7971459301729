import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';

export enum STEPS {
  DRAWER,
  HOSPITAL_SEARCH,
  SEARCH_BY,
  SEARCH_BY_CITY,
  HOSPITAL_SEARCH_RESULTS,
  CLIENT_SEARCH
}

const PracticeClientSearchTourMap: TourMap<STEPS> = {
  [STEPS.DRAWER]: {
    id: STEPS.DRAWER,
    priority: 0
  },
  [STEPS.HOSPITAL_SEARCH]: {
    id: STEPS.HOSPITAL_SEARCH,
    priority: 1
  },
  [STEPS.SEARCH_BY]: {
    id: STEPS.SEARCH_BY,
    priority: 2
  },
  [STEPS.SEARCH_BY_CITY]: {
    id: STEPS.SEARCH_BY_CITY,
    priority: 3
  },
  [STEPS.HOSPITAL_SEARCH_RESULTS]: {
    id: STEPS.HOSPITAL_SEARCH_RESULTS,
    priority: 4
  },
  [STEPS.CLIENT_SEARCH]: {
    id: STEPS.CLIENT_SEARCH,
    priority: 5
  }
};

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.PRACTICE_CLIENT_SEARCH,
  PracticeClientSearchTourMap
);
