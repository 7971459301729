import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  Facebook,
  Globe,
  Instagram,
  Mail,
  MapPin,
  MessageSquare,
  Phone
} from 'react-native-feather';
import { mask } from 'react-native-mask-text';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Body, FocusAwareStatusBar, HoverCard, Subheading } from 'src/components';
import { IconSize, Margin, PHONE_MASK, Size, TWO_WAY_TEXTING } from 'src/constants';
import { usePractice, usePracticeConfiguration } from 'src/hooks/react-query';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import {
  makePhoneCall,
  openEmail,
  openMaps,
  openSMS,
  openWebBrowser,
  simpleAddressFormatter
} from 'src/utils';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import X from 'src/components/Icons/X';

interface DisplayData {
  icon: React.ReactNode;
  label: string;
  value: string;
  onPress: () => void;
}

const ContactUs: React.FC = () => {
  const { t } = useTranslation('contactUs');
  const { colors } = useAppTheme();

  const { data: practiceConfiguration } = usePracticeConfiguration();
  const practice = practiceConfiguration?.practice;

  const { data: practiceInfo } = usePractice();

  const hasTwoWayTexting = useMemo(() => {
    return !!practice?.selectedFeatures?.find((feature) => feature === TWO_WAY_TEXTING);
  }, [practice?.selectedFeatures]);

  const displayData = useMemo(
    () =>
      [
        !!practice?.phoneNumber && {
          icon: <Phone color={colors.primary} width={IconSize.XS} />,
          value: mask(practice.phoneNumber, PHONE_MASK),
          label: t('phone', { ns: 'common' }),
          onPress: () => makePhoneCall(practice.phoneNumber)
        },
        !!practice?.smsNumber &&
          hasTwoWayTexting && {
            icon: <MessageSquare color={colors.primary} width={IconSize.XS} />,
            value: mask(practice?.smsNumber, PHONE_MASK),
            label: t('sms'),
            onPress: () => openSMS(practice.smsNumber)
          },
        !!practice?.email && {
          icon: <Mail color={colors.primary} />,
          value: practice.email,
          label: t('emailLink'),
          onPress: async () => openEmail(practice.email)
        },
        !!practice && {
          icon: <MapPin color={colors.primary} width={IconSize.XS} />,
          value: simpleAddressFormatter(practice),
          label: t('address', { ns: 'common' }),
          onPress: async () => await openMaps(simpleAddressFormatter(practice), practice.name)
        },
        !!practiceInfo?.website && {
          icon: <Globe color={colors.primary} width={IconSize.XS} />,
          value: practiceInfo.website,
          label: t('website'),
          onPress: () => openWebBrowser(practiceInfo.website)
        },
        !!practiceConfiguration?.onlinePharmacyLink && {
          icon: <Icon name='prescription' color={colors.primary} size={IconSize.XS} />,
          value: practiceConfiguration?.onlinePharmacyLink,
          label: t('onlinePharmacyLink'),
          onPress: () => openWebBrowser(practiceConfiguration?.onlinePharmacyLink)
        },
        !!practiceConfiguration?.facebookLink && {
          icon: <Facebook color={colors.primary} />,
          value: practiceConfiguration?.facebookLink,
          label: t('facebookLink'),
          onPress: () => openWebBrowser(practiceConfiguration?.facebookLink)
        },
        !!practiceConfiguration?.instagramLink && {
          icon: <Instagram color={colors.primary} />,
          value: practiceConfiguration?.instagramLink,
          label: t('instagramLink'),
          onPress: () => openWebBrowser(practiceConfiguration?.instagramLink)
        },
        !!practiceConfiguration?.twitterLink && {
          icon: <X color={colors.primary} size={IconSize.XS} />,
          value: practiceConfiguration?.twitterLink,
          label: t('twitterLink'),
          onPress: () => openWebBrowser(practiceConfiguration?.twitterLink)
        }
      ].filter((d) => d) as DisplayData[],
    [
      colors.primary,
      hasTwoWayTexting,
      practice,
      practiceConfiguration?.facebookLink,
      practiceConfiguration?.instagramLink,
      practiceConfiguration?.onlinePharmacyLink,
      practiceConfiguration?.twitterLink,
      practiceInfo?.website,
      t
    ]
  );
  return (
    <>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <SafeAreaView
        style={[layout.flex1, { backgroundColor: colors.surface }]}
        edges={['left', 'right']}
      >
        <Container>
          {displayData.map(({ value, icon, label, onPress }) => (
            <Fragment key={label}>
              <Label>{label}</Label>
              <PressableRow onPress={onPress}>
                <StyledRow>
                  <IconContainer>{icon}</IconContainer>
                  <Body fontWeight={Weight.MEDIUM} color={colors.primary}>
                    {value}
                  </Body>
                </StyledRow>
              </PressableRow>
            </Fragment>
          ))}
        </Container>
      </SafeAreaView>
    </>
  );
};

export default ContactUs;

const Container = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: { marginHorizontal: theme.viewMode.horizontalInset }
}))`
  flex: 1;
  padding: ${Margin.Medium}px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const IconContainer = styled.View`
  width: ${IconSize.XS}px
  height: ${IconSize.XS}px
  align-items: center;
  justify-content: center;
  margin-right: ${Size.XS}px;
`;

const Label = styled(Subheading)`
  margin-top: ${Size.S}px;
  margin-bottom: ${Size.X3_S}px;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const StyledRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

const PressableRow = styled(HoverCard).attrs({ hideShadow: true })`
  padding-vertical: ${Size.S}px;
`;
