import { NotificationPromptContextType } from './types';

const defaultContext: NotificationPromptContextType = {
  hasPrompted: true,
  setHasPrompted: () => {},
  requestNotificationPermission: () => {},
  setIsReady: () => {}
};

export default defaultContext;
