import {
  LayoutChangeEvent,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
  StyleSheet
} from 'react-native';
import { Checkbox, Divider, Menu, TextInput, TouchableRipple } from 'react-native-paper';
import React, {
  ReactNode,
  forwardRef,
  useState,
  useCallback,
  Fragment,
  useMemo,
  ReactElement
} from 'react';
import { useBoolean } from 'src/hooks';
import _ from 'lodash';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import sentry, { interpolateName } from 'src/utils/sentry';
import { ErrorContainer, ErrorText } from './TextInput';
import extractStyles from 'src/utils/extractStyles';
import { Body } from '../Text';
import { Margin } from 'src/constants';

const MAX_MENU_HEIGHT = 200;
export interface Option<T> {
  label: string;
  value: T;
  custom?: ReactNode;
  leadingIcon?: React.FC;
  trailingIcon?: React.FC;
}
export interface MultiSelect<T, O extends Option<T> = Option<T>> extends DropDownPropsInterface<T> {
  multiSelect: true;
  value: T[];
  onChange: (_value: T[]) => void;
  options: O[];
}
export interface SingleSelect<T, O extends Option<T> = Option<T>>
  extends DropDownPropsInterface<T> {
  multiSelect?: false;
  value: T | undefined;
  onChange: (_value: T) => void;
  options: O[];
}
export interface DropDownPropsInterface<T> {
  label?: string | undefined;
  labelTransform?: (option: Option<T>) => string;
  placeholder?: string | undefined;
  mode?: 'outlined' | 'flat' | undefined;
  dropDownContainerMaxHeight?: number;
  dropDownContainerHeight?: number;
  activeColor?: string;
  dropDownStyle?: StyleProp<ViewStyle>;
  accessibilityLabel?: string;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  error?: boolean;
  msg?: false | string | React.ReactNode;
}

export type DropDownForwardRef = <T = string, O extends Option<T> = Option<T>>(
  props: MultiSelect<T, O> | SingleSelect<T, O>,
  ref: React.ForwardedRef<View>
) => ReactElement;

const DropDownRender: DropDownForwardRef = <T, O extends Option<T> = Option<T>>(
  props: MultiSelect<T, O> | SingleSelect<T, O>,
  ref: React.ForwardedRef<View>
) => {
  const activeTheme = useAppTheme();
  const {
    labelTransform,
    multiSelect,
    value,
    onChange: onSelect,
    activeColor,
    mode,
    label,
    placeholder,
    options,
    dropDownContainerMaxHeight,
    dropDownContainerHeight,
    dropDownStyle,
    accessibilityLabel,
    style,
    disabled,
    msg,
    error
  } = props;

  const theme = useAppTheme();

  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });

  const onLayout = (event: LayoutChangeEvent) => {
    setInputLayout(event.nativeEvent.layout);
  };

  const displayValue = useMemo(() => {
    if (multiSelect) {
      const _labels = options
        .filter((option) => value.some((v) => _.isEqual(option.value, v)))
        .map((option) => labelTransform?.(option) ?? option.label)
        .join(', ');
      return _labels;
    } else {
      const _item = options.find((option) => _.isEqual(option.value, value));
      if (_item && labelTransform) {
        return labelTransform(_item);
      }
      return _item?.label ?? '';
    }
  }, [multiSelect, options, value, labelTransform]);

  const isActive = useCallback(
    (currentValue: T) => {
      if (multiSelect) {
        return value.some((v) => _.isEqual(v, currentValue));
      } else {
        return _.isEqual(value, currentValue);
      }
    },
    [multiSelect, value]
  );

  const setActive = useCallback(
    (currentValue: T) => {
      if (multiSelect) {
        const isSelected = value.some((v) => _.isEqual(v, currentValue));
        if (!isSelected) {
          onSelect([...value, currentValue]);
        } else {
          onSelect([...value].filter((v) => !_.isEqual(v, currentValue)));
        }
      } else {
        onSelect(currentValue);
      }
    },
    [multiSelect, onSelect, value]
  );

  const { value: visible, toTrue: showDropDown, toFalse: onDismiss } = useBoolean();

  const buttonSentryLabel = interpolateName('DropDownButton', label);
  const menuItemSentryLabel = (item: { label: string }) =>
    interpolateName('DropDownMenuItem', item.label);

  const onShowDropdown = useCallback(() => {
    sentry.addBreadcrumb({ type: 'Interaction', message: `${buttonSentryLabel} was opened` });
    showDropDown();
  }, [buttonSentryLabel, showDropDown]);

  const onChooseItem = useCallback(
    (item: T) => {
      sentry.addBreadcrumb({ type: 'Interaction', message: `${buttonSentryLabel} was chosen` });
      setActive(item);
      onDismiss?.();
    },
    [buttonSentryLabel, onDismiss, setActive]
  );

  const { innerStyle, outerStyle } = useMemo(() => {
    const flatStyle = StyleSheet.flatten(style);
    return extractStyles(flatStyle);
  }, [style]);

  return (
    <View style={outerStyle}>
      <Menu
        sentry-label={buttonSentryLabel}
        visible={visible}
        onDismiss={onDismiss}
        theme={theme}
        anchor={
          <TouchableRipple
            ref={ref}
            onPress={onShowDropdown}
            onLayout={onLayout}
            accessibilityLabel={accessibilityLabel}
            style={style}
            disabled={disabled}
          >
            <View pointerEvents={'none'} style={{ flexGrow: 1 }}>
              <TextInput
                disabled={disabled}
                style={innerStyle}
                editable={false}
                value={displayValue}
                mode={mode}
                label={label}
                placeholder={placeholder}
                pointerEvents={'none'}
                theme={theme}
                right={
                  <TextInput.Icon
                    color={theme?.colors.placeholder}
                    icon={visible ? 'menu-up' : 'menu-down'}
                  />
                }
                onFocus={() => {
                  onShowDropdown();
                }}
                onBlur={() => {
                  onDismiss();
                }}
                showSoftInputOnFocus={false}
                error={error}
              />
            </View>
          </TouchableRipple>
        }
        style={{
          width: inputLayout?.width,
          ...StyleSheet.flatten(dropDownStyle)
        }}
        contentStyle={{ marginVertical: inputLayout?.height }}
      >
        <ScrollView
          bounces={false}
          style={{
            ...(dropDownContainerHeight
              ? {
                  height: dropDownContainerHeight
                }
              : {
                  maxHeight: dropDownContainerMaxHeight ?? MAX_MENU_HEIGHT
                })
          }}
        >
          {options.map((_item, _index) => (
            <Fragment key={_item.label}>
              <TouchableRipple
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: Margin.Small,
                  paddingHorizontal: Margin.Large,
                  paddingVertical: Margin.Large
                }}
                onPress={() => onChooseItem(_item.value)}
              >
                <Fragment>
                  {_item.leadingIcon && <_item.leadingIcon />}
                  <Body
                    sentry-label={menuItemSentryLabel(_item)}
                    style={[
                      {
                        color: isActive(_item.value)
                          ? activeColor ?? (theme ?? activeTheme).colors.primary
                          : (theme ?? activeTheme).colors.onSurface
                      }
                    ]}
                  >
                    {labelTransform?.(_item) ?? _item.custom ?? _item.label}
                  </Body>
                  {!!_item.trailingIcon && <_item.trailingIcon />}
                  {multiSelect && (
                    <Checkbox.Android
                      color={(theme ?? activeTheme).colors.primary}
                      status={isActive(_item.value) ? 'checked' : 'unchecked'}
                      onPress={() => setActive(_item.value)}
                    />
                  )}
                </Fragment>
              </TouchableRipple>
              <Divider />
            </Fragment>
          ))}
        </ScrollView>
      </Menu>
      {!!msg && (
        <ErrorContainer style={[innerStyle]}>
          <ErrorText>{msg}</ErrorText>
        </ErrorContainer>
      )}
    </View>
  );
};

const DropDown = forwardRef(DropDownRender) as DropDownForwardRef;

export default DropDown;
