import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useMemo, useState } from 'react';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { useMutation } from 'react-query';
import { MutationKeys, PasswordResetError, PasswordResetRQ, resetPasswordWithToken } from 'src/api';
import { InteractionManager, LayoutAnimation } from 'react-native';
import { AllyBlueGradient } from '../SelectPractice/styled';
import { Body, Caption, HoverButton, SecureTextInput } from 'src/components';
import styled from 'styled-components/native';
import { IconSize, Margin, Size } from 'src/constants';
import { palette, Weight } from 'src/theme';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from 'react-native-feather';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import { isDarkColor } from 'src/utils';
import { ActivityIndicator } from 'react-native-paper';
import { SectionTitle } from '../HelpCenter/style';
import { FootNoteContainer } from '../Landing/components/style';
import moment from 'moment-timezone';
import useGoBack from 'src/hooks/useGoBack';

type Props = NativeStackScreenProps<RootStackParamList, Screens.TOKEN_PASSWORD_RESET>;

const TokenResetPassword: React.FC<Props> = ({ route, navigation }) => {
  const { t } = useTranslation('tokenPasswordReset');
  const { colors } = useAppTheme();
  const token = route.params?.token;
  const [state, setState] = useState({
    password: '',
    passwordConfirmation: ''
  });

  const goBack = useGoBack();

  useEffect(() => {
    if (token) return;
    const subscribe = InteractionManager.runAfterInteractions(goBack);
    return () => subscribe.cancel();
  }, [navigation, goBack, token]);

  const mutation = useMutation<unknown, AxiosError<PasswordResetError>, PasswordResetRQ, unknown>({
    mutationKey: MutationKeys.TOKEN_PASSWORD_RESET,
    mutationFn: async (args: PasswordResetRQ) => {
      try {
        if (!token) throw new Error('Token is required');
        await resetPasswordWithToken(args);
      } catch (e) {
        const error = e as AxiosError<PasswordResetError>;
        if (error.response?.data.errors.resetPasswordToken) {
          Toast.show({
            type: 'error',
            text1: t('errors.invalidToken'),
            text2: error.response?.data.errors.resetPasswordToken
              .map((error) => t(`errors.${error}`))
              .join('\n')
          });
        }
        throw error;
      } finally {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
    }
  });

  const handleResetPassword = useMemo(
    () =>
      debounce(() => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        mutation.mutate({ token, ...state });
      }, 500),
    [mutation, token, state]
  );

  useEffect(() => {
    if (mutation.error?.response?.data.errors.resetPasswordToken) {
      goBack();
    }
    if (mutation.isSuccess) {
      const timeout = setTimeout(goBack, moment.duration(5, 'seconds').asMilliseconds());
      return () => clearTimeout(timeout);
    }
  }, [mutation, goBack]);

  if (mutation.isSuccess) {
    return (
      <AllyBlueGradient>
        <SuccessContainer>
          <SectionTitle color={colors.onPrimary}>{t('success', { ns: 'common' })}</SectionTitle>
          <CheckCircle height={IconSize.L} width={IconSize.L} color={colors.onPrimary} />
          <FootNoteContainer>
            <Body color={colors.onPrimary} textAlign={'center'}>
              {t('passwordReset')}
            </Body>
          </FootNoteContainer>
        </SuccessContainer>
      </AllyBlueGradient>
    );
  } else if (mutation.isLoading) {
    return (
      <AllyBlueGradient>
        <ActivityIndicator size='large' color={colors.onPrimary} style={{ flex: 1 }} />
      </AllyBlueGradient>
    );
  }
  return (
    <AllyBlueGradient>
      <Container>
        <>
          <StyledSecureInput
            label={t('newPassword', { ns: 'login' })}
            value={state.password}
            onChangeText={(text) => {
              setState((prev) => ({ ...prev, password: text }));
            }}
            error={!!mutation.error?.response?.data.errors?.password}
          />
          {!!mutation.error?.response?.data.errors?.password && (
            <ErrorContainer>
              {mutation.error?.response?.data.errors?.password?.map((error) => (
                <ErrorText key={error}>{t(`errors.${error}`)}</ErrorText>
              ))}
            </ErrorContainer>
          )}
          <StyledSecureInput
            label={t('confirmPassword', { ns: 'login' })}
            value={state.passwordConfirmation}
            onChangeText={(text) => {
              setState((prev) => ({ ...prev, passwordConfirmation: text }));
            }}
            error={!!mutation.error?.response?.data.errors?.passwordConfirmation}
          />
          {!!mutation.error?.response?.data.errors?.passwordConfirmation && (
            <ErrorContainer>
              {mutation.error?.response?.data.errors?.passwordConfirmation?.map((error) => (
                <ErrorText key={error}>{t(`errors.${error}`)}</ErrorText>
              ))}
            </ErrorContainer>
          )}
          <HoverButton
            mode={'contained'}
            disabled={!state.password || !state.passwordConfirmation}
            onPress={handleResetPassword}
          >
            {t('submit', { ns: 'common' })}
          </HoverButton>
        </>
      </Container>
    </AllyBlueGradient>
  );
};

export default TokenResetPassword;

const inputStyle = css`
  background-color: ${({ theme: { colors } }) =>
    isDarkColor(colors.primary) ? palette.WHITE_OPACITY_70 : palette.BLACK_OPACITY_70};
`;

const StyledSecureInput = styled(SecureTextInput)`
  ${inputStyle}
`;

const Container = styled.View`
  gap: ${Size.S}px;
  margin-horizontal: ${({ theme: { viewMode } }) => viewMode.narrowHorizontalInset}px;
`;

const ErrorContainer = styled.View`
  background-color: ${({ theme: { colors } }) =>
    isDarkColor(colors.primary) ? palette.WHITE_OPACITY_70 : palette.BLACK_OPACITY_70};
  border-bottom-left-radius: ${Size.X3_S}px;
  border-bottom-right-radius: ${Size.X3_S}px;
  padding: ${Margin.Medium}px;
  margin-top: ${-Size.S}px;
`;
const ErrorText = styled(Caption)`
  color: ${({ theme: { colors } }) => colors.error};
  font-size: ${Size.XS}px;
  font-weight: ${Weight.MEDIUM};
`;

const SuccessContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: ${Size.S}px;
  padding-horizontal: ${({ theme: { viewMode } }) => viewMode.narrowHorizontalInset}px;
`;
