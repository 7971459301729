import { useEffect, useMemo } from 'react';
import { UseQueryOptions, useIsMutating, useQuery } from 'react-query';
import { getAllTodos, QueryKeys, MutationKeys } from 'src/api';
import { Patient, Todo, TodoReason } from 'src/interfaces/api';
import { PaginationRS } from 'src/utils';

interface Props {
  enabled?: boolean;
  onFetchSuccess?: (todos: Todo[]) => void | Promise<void>;
  isComplete?: boolean;
  patientId?: Patient['patientId'];
  reason?: TodoReason;
  start?: number;
  dir?: 'asc' | 'desc';
  column?: string;
  queryOptions?: UseQueryOptions<
    PaginationRS<Todo>,
    unknown,
    PaginationRS<Todo>,
    Array<string | number | boolean | undefined>
  >;
}

export const todoQueryProps = ({
  start,
  isComplete,
  dir,
  column
}: Pick<Props, 'start' | 'isComplete' | 'dir' | 'column'>) => ({
  queryKey: [QueryKeys.TODOS, isComplete, start, dir, column],
  queryFn: async () => await getAllTodos(start, undefined, isComplete, dir, column)
});

const useTodos = ({
  enabled = true,
  onFetchSuccess,
  isComplete,
  patientId,
  reason,
  start = 0,
  dir = 'asc',
  column = 'due_date',
  queryOptions
}: Props = {}) => {
  const isMutating = useIsMutating([MutationKeys.EDITING_TODOS]);

  const {
    data: pageRS,
    isLoading,
    isFetched: todosAreFetched,
    isError,
    isFetching
  } = useQuery({
    ...todoQueryProps({ start, isComplete, dir, column }),
    enabled: enabled && !isMutating,
    ...queryOptions
  });

  const todos = useMemo(
    () =>
      pageRS?.data?.filter(
        (todo) =>
          (isComplete === undefined || isComplete === todo.isComplete) &&
          (!patientId || patientId === todo.patientId) &&
          (!reason || reason === todo.reason)
      ),
    [isComplete, pageRS?.data, patientId, reason]
  );

  useEffect(() => {
    if (todosAreFetched && todos && !isError) {
      void onFetchSuccess?.(todos);
    }
  }, [isError, onFetchSuccess, todos, todosAreFetched]);

  return {
    isFetching,
    todosAreFetched,
    isLoading,
    isMutating,
    todos
  };
};

export default useTodos;
