const paramToBoolean = (param: null | string | boolean | undefined) => {
  if (param === null) {
    // occurs when query param is listed as a flag e.g. /?param === /?param=true
    return true;
  }
  if (typeof param === 'string') {
    return param === 'true';
  }
  if (typeof param === 'boolean') {
    return param;
  }
  return false;
};

export default paramToBoolean;
