import { endpoint, PATIENT_STATUSES } from 'src/constants';
import { Reminder } from 'src/interfaces';
import { isDefinedOrThrowServerError, PaginationRS } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export interface PaginatedRemindersParams {
  length?: number;
  start?: number;
  order?: 'asc' | 'desc';
  orderBy?: keyof Pick<Reminder, 'pastDueDate'>;
  status?: PATIENT_STATUSES;
}

export const getPaginatedReminders = async ({
  status,
  order,
  orderBy,
  ...params
}: PaginatedRemindersParams): Promise<PaginationRS<Reminder>> => {
  const response = await apiClient.get<PaginationRS<Reminder>>(endpoint('REMINDERS'), {
    params: {
      ...params,
      status_filter: status ?? Object.values(PATIENT_STATUSES),
      order: {
        column: orderBy,
        dir: order
      },
      server_managed: true
    }
  });
  return isDefinedOrThrowServerError(response.data);
};
