import React, { useEffect, useRef } from 'react';
import { HoverButton, ModalDrawer, Slider } from 'src/components';
import { ModalDrawerHandle } from 'src/components/ModalDrawer';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import styled from 'styled-components/native';
import { Margin } from 'src/constants';
import { FlatList, ViewToken } from 'react-native';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import { isDefined } from 'src/utils';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { TourGuideContext } from '../model';
import { useTourGuideMaster } from '../TourGuideMaster';

export interface SliderElementProps<T extends string | number> {
  id: T;
  slides: SlideItem[];
  disabled?: boolean;
  autoStart?: boolean;
  onContinue?: () => void;
}
function _SliderElement<T extends string | number = string>({
  toursEnabled,
  focusedId,
  exitTour,
  unregisterStep,
  onContinue,
  id,
  slides
}: SliderElementProps<T> & TourGuideContext<T>): React.ReactElement | null {
  const { stopTours } = useTourGuideMaster();
  const { t } = useTranslation('common');
  const { viewMode, colors } = useAppTheme();

  const modalRef = useRef<ModalDrawerHandle | null>(null);

  useEffect(() => {
    if (focusedId === id) {
      modalRef.current?.show();
    } else if (modalRef.current?.isVisible) {
      modalRef.current.dismiss();
    }
  }, [focusedId, id]);

  const sliderRef = useRef<FlatList<SlideItem>>(null);

  const index = useRef(0);

  const handleViewableItemsChanged = useRef(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      if (!isDefined(info.viewableItems[0]?.index)) return;
      index.current = info.viewableItems[0].index;
    }
  ).current;

  const next = useRef(
    debounce(() => {
      if (index.current + 1 < slides.length) {
        sliderRef.current?.scrollToIndex({
          animated: true,
          index: index.current + 1
        });
      } else {
        modalRef.current?.dismiss();
        unregisterStep(id);
        onContinue?.();
      }
    })
  ).current;

  const exit = useRef(() => {
    exitTour();
    modalRef.current?.dismiss();
  }).current;

  if (!toursEnabled) return null;

  return (
    <ModalDrawer
      ref={modalRef}
      disableBackgroundDismiss
      cardStyle={{ maxWidth: viewMode.narrowMaxContentWidth }}
      onClose={() => unregisterStep(id)}
      bottomButton={{
        onPress: next,
        label: t('continue'),
        dismiss: false
      }}
      onOpen={() => sliderRef.current?.scrollToIndex({ index: 0, animated: true })}
    >
      <Container>
        <Slider data={slides} ref={sliderRef} onViewableItemsChanged={handleViewableItemsChanged} />
        <StyledSkipButton buttonColor={colors.onSurface} onPress={exit} compact>
          {t('skip')}
        </StyledSkipButton>
        <StyledSkipAllButton
          buttonColor={colors.disabled}
          onPress={() => {
            exitTour();
            void stopTours();
          }}
          compact
        >
          {t('stopTours')}
        </StyledSkipAllButton>
      </Container>
    </ModalDrawer>
  );
}

export default _SliderElement;

const Container = styled.View`
  gap: ${Margin.ExtraLarge}px;
  margin-bottom: ${Margin.Large}px;
`;

const StyledSkipButton = styled(HoverButton)`
  position: absolute;
  top: ${Margin.ExtraLarge}px;
  left: ${Margin.ExtraLarge}px;
`;

const StyledSkipAllButton = styled(HoverButton)`
  position: absolute;
  top: ${Margin.ExtraLarge}px;
  right: ${Margin.ExtraLarge}px;
`;
