import * as Linking from 'expo-linking';
import Toast from 'react-native-toast-message';
import { t } from 'i18next';
import { unMask } from 'react-native-mask-text';

const makePhoneCall = (phone?: string) => {
  if (!phone)
    return Toast.show({
      type: 'error',
      text1: t('noPhoneProvided', { ns: 'common' })
    });
  const unmaskedPhone = unMask(phone);
  const phoneNumber = `tel:${unmaskedPhone}`;
  Linking.canOpenURL(phoneNumber)
    .then((supported) => {
      if (!supported) {
        Toast.show({
          type: 'error',
          text1: t('phoneUnavailable', { ns: 'common' })
        });
      } else {
        return Linking.openURL(phoneNumber);
      }
    })
    .catch((err) => console.error(err));
};

export default makePhoneCall;
