import _ from 'lodash';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import useGoBack from 'src/hooks/useGoBack';

export interface Context {
  closeModal: () => void;
}

const defaultContext: Context = {
  closeModal: _.noop
};

const ExitContext = createContext<Context>(defaultContext);

export const useExitProvider = () => useContext(ExitContext);

const ExitProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const goBack = useGoBack();
  return <ExitContext.Provider value={{ closeModal: goBack }}>{children}</ExitContext.Provider>;
};

export default ExitProvider;
