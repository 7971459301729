import createTourGuideContext from 'src/module/TourGuide/TourGuideFactory';
import { TOUR, TourMap } from 'src/module/TourGuide/model';

export enum STEPS {
  STATUS,
  CALENDAR,
  MAP,
  CALL
}

const AppointmentDetailsTourMap: TourMap<STEPS> = {
  [STEPS.STATUS]: { id: STEPS.STATUS, priority: 0 },
  [STEPS.CALENDAR]: { id: STEPS.CALENDAR, priority: 1 },
  [STEPS.MAP]: { id: STEPS.MAP, priority: 2 },
  [STEPS.CALL]: { id: STEPS.CALL, priority: 3 }
};

export const { useTourGuide, TourGuide, GuideElement, SliderElement } = createTourGuideContext(
  TOUR.APPOINTMENT_DETAILS,
  AppointmentDetailsTourMap
);
