import { Body } from 'src/components';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

export const StyledBody = styled(Body)`
  color: ${({ theme: { colors } }) => colors.onPrimary};
  text-align: center;
`;

export const Container = styled.View<{ bottom: number }>`
  align-self: stretch;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  gap: ${Size.S}px;
  padding-bottom: ${({ bottom }) => bottom}px;
`;
