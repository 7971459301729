import React, { createRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, Platform, TextInput as TI, View } from 'react-native';
import { useFormik } from 'formik';
import { PHONE_MASK } from 'src/constants';
import TextInput, { TextInputProps } from 'src/components/kit/TextInput';
import { HelperText } from 'react-native-paper';
import {
  FormData,
  fieldNames,
  validationSchema,
  isFieldDirty,
  addressFormatter,
  CompleteData,
  addressFields,
  initialValuesBuilder,
  createRequestItemArray
} from './helpers';
import { createNamedRefs, queryClient } from 'src/utils';
import _ from 'lodash';
import { useBoolean, useViewMode } from 'src/hooks';
import AddressChangeModal from './AddressChangeModal';
import {
  DiscardChangesModal,
  Column,
  StyledKeyboardAwareScrollView,
  BottomButton,
  HoverButton
} from 'src/components';
import { useUser } from 'src/providers/ClientProvider';
import Toast from 'react-native-toast-message';
import { InfoUpdateItem, InfoUpdateRequest } from 'src/interfaces/api/InfoUpdateRequest';
import { useMutation } from 'react-query';
import { QueryKeys, postRequest } from 'src/api';
import { StyledRow } from './shared';
import useGoBack from 'src/hooks/useGoBack';
import ConfirmChanges from './ConfirmChanges';
import { RequestType } from 'src/interfaces';

const ProfileInformationUpdate: React.FC = () => {
  const goBack = useGoBack();
  const { t } = useTranslation('informationUpdate');
  const { user } = useUser();
  const { horizontalInset } = useViewMode();

  const [infoUpdates, setInfoUpdates] = useState<InfoUpdateItem[]>([]);
  const [contactMethod, setContactMethod] = useState<string>('email');

  const formData: FormData = Object.assign(
    { addressLine1: '', addressLine2: '' },
    _.pick(user, fieldNames)
  ) as FormData;

  const [inputs] = useState(createNamedRefs<TI, FormData>(formData));

  const fauxAddressRef = useState(createRef<TI>())[0];

  const { mutate: submitRequest } = useMutation(
    async (request: InfoUpdateRequest) => await postRequest(request),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([QueryKeys.REQUESTS]);
        Toast.show({ text1: t('success', { ns: 'common' }), text2: t('requestedToast') });
        goBack();
      },
      onError: (error: Error) => {
        Toast.show({
          type: 'error',
          text1: t('errorToast', { ns: 'appointmentRequest' }),
          text2: error.name
        });
      }
    }
  );

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    dirty,
    isValid,
    initialValues,
    validateForm,
    handleBlur
  } = useFormik<CompleteData>({
    initialValues: validationSchema.cast(initialValuesBuilder(user)),
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const changeRequests = createRequestItemArray(values, initialValues);
      submitRequest({
        practiceId: user.practiceId,
        clientId: user.clientId,
        sourceId: user.sourceId,
        content: {
          informationUpdates: changeRequests,
          preferredContactMethod: contactMethod,
          origin: Platform.OS
        },
        type: RequestType.InfoUpdate
      });
      resetForm({ values });
      setContactMethod('');
    }
  });

  const {
    value: addressFormVisible,
    toTrue: showAddressForm,
    toFalse: hideAddressForm
  } = useBoolean();

  const {
    value: confirmationVisible,
    toTrue: showConfirmation,
    toFalse: hideConfirmation
  } = useBoolean();

  const propBuilder = useCallback(
    (field: keyof FormData, next?: keyof FormData): Omit<TextInputProps, 'mode' | 'theme'> => {
      const errorMsg = errors[field];
      return {
        label: t(field),
        value: values[field] ?? '',
        onChangeText: (text: string) => {
          void setFieldValue(field, text, false);
        },
        error: !!errors[field],
        msg: errorMsg && t(errorMsg),
        thisRef: inputs[field],
        nextRef: next && inputs[next],
        right: initialValues[field] !== values[field] && {
          name: 'undo',
          onPress: () => {
            void setFieldValue(field, initialValues[field], true);
          }
        },
        onBlur: handleBlur(field)
      };
    },
    [errors, t, values, inputs, initialValues, handleBlur, setFieldValue]
  );

  const resetAddress = async (): Promise<void> => {
    await Promise.all(addressFields.map(async (f) => await setFieldValue(f, initialValues[f])));
    void validateForm();
  };
  const fullAddressText = addressFormatter(values);
  const addressError =
    !!errors.addressLine1 || !!errors.city || !!errors.state || !!errors.postalCode;

  const disableSubmit = !dirty || !isValid;

  const bottomButtonPressed = async () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    if (!confirmationVisible) {
      const errors = await validateForm();
      if (Object.keys(errors).length) return;
      const changeRequests = createRequestItemArray(values, initialValues);
      changeRequests.forEach((change) => infoUpdates.push(change));
      showConfirmation();
    } else handleSubmit();
  };

  const cancelPressed = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setInfoUpdates([]);
    hideConfirmation();
  };

  if (confirmationVisible) {
    return (
      <>
        <ConfirmChanges
          infoUpdates={infoUpdates}
          preferredContactMethod={contactMethod}
          setPreferredContactMethod={setContactMethod}
        />
        <View style={{ marginHorizontal: horizontalInset }}>
          <HoverButton mode='text' onPress={() => cancelPressed()}>
            {t('cancel')}
          </HoverButton>
          <BottomButton onPress={bottomButtonPressed} disabled={!dirty || !isValid}>
            {t('requests:wizard.looksGood')}
          </BottomButton>
        </View>
      </>
    );
  }

  return (
    <>
      <>
        <StyledKeyboardAwareScrollView>
          <StyledRow>
            <Column>
              <TextInput {...propBuilder('firstName', 'lastName')} />
            </Column>
            <Column>
              <TextInput {...propBuilder('lastName', 'email')} />
            </Column>
          </StyledRow>
          <TextInput {...propBuilder('email')} nextRef={fauxAddressRef} />
          <TextInput
            label={t('address')}
            value={fullAddressText ?? ''}
            thisRef={fauxAddressRef}
            multiline
            numberOfLines={fullAddressText.split('\n').length}
            showSoftInputOnFocus={false}
            onFocus={() => {
              showAddressForm();
              fauxAddressRef.current?.blur();
            }}
            error={addressError}
            msg={addressError ? t('invalidAddress') : ''}
            caretHidden={true}
            right={
              addressFields.some((v) => isFieldDirty(v, values, initialValues)) && {
                name: 'undo',
                onPress: resetAddress
              }
            }
          />
          <TextInput
            {...propBuilder('mobilePhone', 'workPhone')}
            error={!!(errors.mobilePhone ?? errors.phone)}
            mask={PHONE_MASK}
          />
          <TextInput
            {...propBuilder('workPhone', 'homePhone')}
            error={!!(errors.workPhone ?? errors.phone)}
            mask={PHONE_MASK}
          />
          <TextInput
            {...propBuilder('homePhone')}
            error={!!(errors.homePhone ?? errors.phone)}
            mask={PHONE_MASK}
            onSubmitEditing={disableSubmit ? undefined : showConfirmation}
          />

          {errors.phone && <HelperText type='error'>{t(errors.phone)}</HelperText>}
        </StyledKeyboardAwareScrollView>
        <AddressChangeModal
          visible={addressFormVisible}
          hide={hideAddressForm}
          propBuilder={propBuilder}
          onContinue={inputs.homePhone.current?.focus}
        />
      </>
      <View style={{ marginHorizontal: horizontalInset }}>
        <BottomButton onPress={bottomButtonPressed} disabled={!dirty || !isValid}>
          {t('requests:wizard.submit')}
        </BottomButton>
      </View>
      <DiscardChangesModal isDirty={dirty} />
    </>
  );
};

export default ProfileInformationUpdate;
