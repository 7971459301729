/**
 * Exports the correct local storage option for native and web apps.
 * Includes a typed setter/getter to automatically parse storage content.
 */

import AsyncStorage from '@react-native-async-storage/async-storage';
import isDefined from 'src/utils/isDefined';

const getItem = async (key: string): Promise<string | null> => await AsyncStorage.getItem(key);

const setItem = async (key: string, value: string): Promise<void> =>
  await AsyncStorage.setItem(key, value);

async function getTypedItem<T>(key: string): Promise<T | undefined> {
  const raw = await getItem(key);
  if (isDefined(raw)) {
    return JSON.parse(raw) as T;
  }
  return undefined;
}
async function setTypedItem<T>(key: string, value: T): Promise<void> {
  await setItem(key, JSON.stringify(value));
}

const storage = {
  ...AsyncStorage,
  getTypedItem,
  setTypedItem
};

export default storage;
