import {
  getCameraPermissionsAsync,
  launchCameraAsync,
  MediaTypeOptions,
  requestCameraPermissionsAsync
} from 'expo-image-picker';
import { t } from 'i18next';
import Toast from 'react-native-toast-message';

const pickImage = async (allowsMultipleSelection = false) => {
  try {
    const permissions = await getCameraPermissionsAsync();
    if (!(permissions.granted ?? false)) {
      const permissionResponse = await requestCameraPermissionsAsync();

      if (!permissionResponse.granted) {
        Toast.show({
          text1: t('camera.accessRequired', { ns: 'permissions' }),
          text2: t('camera.request', { ns: 'permissions' }),
          type: 'error'
        });
        return null;
      }
    }

    return await launchCameraAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: !allowsMultipleSelection,
      allowsMultipleSelection
    });
  } catch (e: any) {
    Toast.show({
      text1: e.name,
      text2: e.message,
      type: 'error'
    });
  }
};

export default pickImage;
