import { useEffect } from 'react';
import { flushEvents } from 'src/utils/utmTracking';
import useNativeFlushEvents from './index.native';

/**
 * Flushes events when the app goes to the background or becomes inactive.
 * On web, this will also flush events when the page is closed.
 */
const useFlushEvents = () => {
  useNativeFlushEvents();
  useEffect(() => {
    window.addEventListener('beforeunload', flushEvents);
    return () => {
      window.removeEventListener('beforeunload', flushEvents);
    };
  }, []);
};

export default useFlushEvents;
