import { useLinkTo } from '@react-navigation/native';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Linking } from 'react-native';
import { SwitchPracticeResponse } from 'src/api';
import resolveSendGridLink from 'src/utils/resolveSendGridLink';
import sentry from 'src/utils/sentry';
import { AuthStatus } from '../../providers/AuthProvider/model';
import {
  qualifiedAuthStatus,
  shouldSwitchUsers,
  handlePracticeSwitch,
  isLoginPath,
  urlAllowsNoAuth,
  pathAndParams,
  urlEmitter,
  urlInfo
} from './helper';
import { UrlInfo } from './model';

interface InitialDeeplinkProps {
  /**
   * Has the client profile been fetched from the api.
   */
  ready: boolean;
  logOut: (switchPractice?: SwitchPracticeResponse | undefined) => Promise<void>;
  authStatus: AuthStatus | undefined;
}

/**
 * If the user is not authenticated, useDeeplink will capture the deeplink.
 * Once the user is authenticated, it will navigate to the stored deeplink.
 */
const useDeeplink = ({ ready, authStatus, logOut: applyPractice }: InitialDeeplinkProps) => {
  const [urlToHandle, setUrlToHandle] = useState<UrlInfo | undefined>();
  const linkTo = useRef(useLinkTo());

  useEffect(() => {
    if (urlInfo) {
      setUrlToHandle(urlInfo);
    }
    const callback = (urlInfo: UrlInfo) => {
      setUrlToHandle(urlInfo);
    };
    urlEmitter.on('url', callback);
    return () => {
      urlEmitter.removeListener('url', callback);
    };
  }, []);

  /**
   *Parse and store a deeplink
   */
  useEffect(() => {
    if (Linking) {
      void Linking.getInitialURL().then(async (url) => {
        if (url) {
          setUrlToHandle({ url: await resolveSendGridLink(url) });
        }
      });
      const sub = Linking.addEventListener('url', async ({ url }) => {
        setUrlToHandle({ url: await resolveSendGridLink(url) });
      });
      return () => {
        try {
          if (sub.remove) {
            sub.remove();
          } else {
            Linking.removeAllListeners('url');
          }
        } catch {
          sentry.addBreadcrumb({
            message: 'Failed to remove deeplink listener',
            type: 'error'
          });
        }
      };
    }
  }, []);

  /**
   * Once the user is logged in, navigate to the deeplink.
   */
  const handleDeeplink = useCallback(async () => {
    if (!ready || !urlToHandle || !qualifiedAuthStatus(authStatus)) return;
    const isLoggedIn = authStatus === AuthStatus.AUTHENTICATED;
    if (isLoggedIn) {
      if (await shouldSwitchUsers(urlToHandle)) {
        return handlePracticeSwitch(urlToHandle, applyPractice);
      }
    }
    const url = new URL(urlToHandle.url);
    if (isLoginPath(url)) return;
    if (!isLoggedIn && !urlAllowsNoAuth(url)) return;
    try {
      linkTo.current(pathAndParams(url));
    } catch (e) {
      console.warn(`Deeplink ${urlToHandle.url} is invalid`);
    } finally {
      setUrlToHandle(undefined);
    }
  }, [ready, urlToHandle, applyPractice, authStatus]);

  useEffect(() => {
    void handleDeeplink();
  }, [handleDeeplink]);
};

export default useDeeplink;
