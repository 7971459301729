import { useQuery, UseQueryResult } from 'react-query';
import { getPracticeConfiguration, QueryKeys } from 'src/api';
import Practice from 'src/interfaces/api/Practice';
import PracticeConfiguration from 'src/interfaces/api/PracticeConfiguration';
import { useUser } from 'src/providers/ClientProvider';

export const practiceConfigurationOptions = (practiceId: Practice['id']) => ({
  queryKey: [QueryKeys.PRACTICE_CONFIGURATION, practiceId],
  queryFn: async () => await getPracticeConfiguration(practiceId),
  retryDelay: 5000
});

export type PracticeConfigurationHook = UseQueryResult<PracticeConfiguration, unknown>;
const usePracticeConfiguration = () => {
  const { user } = useUser();
  return useQuery(practiceConfigurationOptions(user.practiceId));
};

export default usePracticeConfiguration;
