import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextInputProps as PaperInputProps, TextInput as PaperInput } from 'react-native-paper';
import { unMask } from 'react-native-mask-text';
import { extractStyles, isDefined, multiMask } from 'src/utils';
import {
  TextInput as TI,
  NativeSyntheticEvent,
  TextInputSubmitEditingEventData,
  TextInputFocusEventData,
  View,
  StyleProp,
  TextStyle,
  StyleSheet,
  ColorValue
} from 'react-native';
import { palette, Weight } from 'src/theme';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import sentry, { interpolateName } from 'src/utils/sentry';
import styled from 'styled-components/native';
import { Size } from 'src/constants';
import { Caption } from '../Text';
import { omit } from 'lodash';

export type TextInputType = TI;

export interface TextInputProps extends Omit<PaperInputProps, 'ref' | 'right' | 'theme'> {
  mask?: string | string[];
  msg?: string | false | React.ReactNode;
  nextRef?: React.MutableRefObject<TI | null>;
  thisRef?: React.MutableRefObject<TI | null>;
  right?:
    | {
        name: React.FunctionComponent | string;
        onPress?: (() => Promise<void>) | (() => void);
      }
    | false;
}

const TextInput: React.FC<TextInputProps> = ({
  value: parentValue = '',
  onChangeText: parentOnChangeText,
  mask: maskArray,
  msg,
  error = false,
  nextRef,
  onSubmitEditing,
  thisRef,
  right,
  style,
  onFocus: parentOnFocus,
  ...props
}) => {
  const theme = useAppTheme();
  const [masked, setMasked] = useState<string>(parentValue);

  useEffect(() => {
    const masked = isDefined(maskArray) ? multiMask(parentValue, maskArray) : parentValue;
    setMasked(masked);
  }, [maskArray, parentValue]);

  const onChangeText = (text: string): void => {
    if (isDefined(maskArray)) {
      const maskedText = multiMask(text, maskArray);
      parentOnChangeText?.(unMask(maskedText));
      setMasked(maskedText);
    } else {
      parentOnChangeText?.(text);
      setMasked(text);
    }
  };

  const nextRefAndSubmit = (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>): void => {
    onSubmitEditing?.(e);
    nextRef?.current?.focus();
  };

  const sentryLabel = interpolateName('TextInput', props.label);

  const onFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      sentry.addBreadcrumb({ type: 'Interaction', message: `${sentryLabel} was focused` });
      parentOnFocus?.(e);
    },
    [parentOnFocus, sentryLabel]
  );

  const { innerStyle, outerStyle } = useMemo(() => {
    const flatStyle = StyleSheet.flatten(style);
    return extractStyles(flatStyle);
  }, [style]);

  return (
    <View style={outerStyle}>
      <PaperInput
        sentry-label={sentryLabel}
        style={innerStyle}
        ref={thisRef}
        value={masked}
        theme={theme}
        onChangeText={onChangeText}
        onSubmitEditing={nextRefAndSubmit}
        error={error}
        clearButtonMode='while-editing'
        right={
          right && (
            <PaperInput.Icon
              icon={right.name}
              color={theme.dark ? palette.WHITE_OPACITY_30 : palette.BLACK_OPACITY_30}
              onPress={right.onPress}
            />
          )
        }
        onFocus={onFocus}
        {...props}
      />
      {!!msg && (
        <ErrorContainer style={[omit(innerStyle, ['minHeight', 'height', 'flex'])]}>
          <ErrorText>{msg}</ErrorText>
        </ErrorContainer>
      )}
    </View>
  );
};

export default TextInput;

export const getRadiusFromStyle = (style: StyleProp<TextStyle>) => {
  const flatStyle = StyleSheet.flatten(style);
  const leftRadius = flatStyle?.borderBottomLeftRadius ?? flatStyle?.borderRadius;
  const rightRadius = flatStyle?.borderBottomRightRadius ?? flatStyle?.borderRadius;
  return { leftRadius, rightRadius };
};

export const getBackgroundFromStyle = (style: StyleProp<TextStyle>): ColorValue | undefined => {
  const flatStyle = StyleSheet.flatten(style);
  return flatStyle?.backgroundColor;
};

export const ErrorContainer = styled(View).attrs((props) => ({
  style: [
    {
      borderBottomLeftRadius: getRadiusFromStyle(props.style).rightRadius,
      borderBottomRightRadius: getRadiusFromStyle(props.style).leftRadius,
      padding: Size.XS,
      backgroundColor: getBackgroundFromStyle(props.style)?.toString() ?? 'transparent'
    },
    props.style
  ]
}))`
  padding: ${Size.XS}px;
`;

export const ErrorText = styled(Caption)`
  color: ${({ theme: { colors } }) => colors.error};
  font-weight: ${Weight.REGULAR};
  letter-spacing: 0.5px;
`;
