import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import {
  AsyncStorageKeys,
  QueryKeys,
  resendConfirmationEmail,
  ResendConfirmationRequest
} from 'src/api';
import { useBoolean, useStorage } from 'src/hooks';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';
import { queryClient } from 'src/utils';

const MINIMUM_RESEND_DURATION = moment.duration(1, 'minutes');

const useResendConfirmationEmail = (email?: string, practice?: AvailablePractice) => {
  const { t } = useTranslation('login');
  const [lastResend, setLastResend] = useStorage<Moment>([
    AsyncStorageKeys.EMAIL_CONFIRMATION_TIMESTAMP,
    email ? email.toLowerCase() : '',
    practice?.practiceId ?? ''
  ]);
  const { value: hasSentRecently, toTrue: sent, toFalse: unsent } = useBoolean(false);
  useEffect(() => {
    if (lastResend) {
      const timeSinceLastSent = moment().diff(moment(lastResend), 'milliseconds', true);
      const wait = MINIMUM_RESEND_DURATION.asMilliseconds() - timeSinceLastSent;
      if (wait > 0) {
        sent();
        const timeout = setTimeout(async () => {
          await queryClient.invalidateQueries([QueryKeys.CHECK_EMAIL]);
          unsent();
        }, wait);
        return () => clearTimeout(timeout);
      }
    }
    unsent();
  }, [lastResend, sent, unsent]);

  const { mutate, isLoading: isSending } = useMutation({
    mutationFn: async (request: ResendConfirmationRequest) =>
      await resendConfirmationEmail(request),
    onSuccess: () => {
      setLastResend(moment());
      Toast.show({
        text1: t('success', { ns: 'common' }),
        text2: t('confirmationEmailSent', { email })
      });
    },
    onError: () =>
      Toast.show({
        text1: t('uhOh', { ns: 'common' }),
        text2: t('error', { ns: 'common' }),
        type: 'error'
      })
  });

  const sendConfirmationEmail = useMemo(
    () =>
      _.debounce(async () => {
        if (!email || !practice) return;
        if (!lastResend || moment(lastResend).clone().add(MINIMUM_RESEND_DURATION).isBefore()) {
          await mutate({
            email,
            practiceId: practice.practiceId,
            practiceShortName: practice.shortName
          });
        } else {
          const timeSinceLastSent = moment().diff(moment(lastResend), 'milliseconds', true);
          const wait = MINIMUM_RESEND_DURATION.asMilliseconds() - timeSinceLastSent;
          Toast.show({
            text1: t('confirmationAlreadySent'),
            text2: t('pleaseWait', {
              humanizedTime: moment.duration(wait, 'milliseconds').humanize()
            }),
            type: 'error'
          });
        }
      }),
    [email, practice, lastResend, mutate, t]
  );

  return { sendConfirmationEmail, isSending, hasSentRecently };
};

export default useResendConfirmationEmail;
