import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { getAppointments } from 'src/api/appointments';
import { Appointment, Patient } from 'src/interfaces';
import { extractAppointments, filterAppointments } from './helpers';
import { useFocusEffect } from '@react-navigation/native';
import { checkIsPast } from '../../helper';
import { PAGE_LENGTH } from 'src/constants';
import { PaginationRS, getNextPageParam, queryClient } from 'src/utils';

interface Options
  extends Omit<
    UseInfiniteQueryOptions<
      PaginationRS<Appointment>,
      unknown,
      PaginationRS<Appointment>,
      PaginationRS<Appointment>,
      QueryKeys[]
    >,
    'queryFn' | 'queryKey'
  > {
  limit?: number;
  selectedPatient?: Patient['patientId'];
}

export const appointmentsOptions: (
  limit?: number
) => UseInfiniteQueryOptions<
  PaginationRS<Appointment>,
  unknown,
  PaginationRS<Appointment>,
  PaginationRS<Appointment>,
  QueryKeys[]
> = (limit = PAGE_LENGTH) => ({
  queryKey: [QueryKeys.APPOINTMENTS],
  queryFn: async ({ pageParam = 0 }) => {
    return getAppointments(pageParam * limit, limit);
  },
  getNextPageParam: (...options) => getNextPageParam(...options, limit),
  suspense: true,
  onSuccess: (d) => {
    const lastPage = d.pages[d.pages.length - 1];
    lastPage.data.forEach((appointment) => {
      queryClient.setQueryData([QueryKeys.APPOINTMENT, appointment.appointmentId], appointment);
    });
  }
});

const useAppointments = ({ selectedPatient, limit = PAGE_LENGTH, ...options }: Options) => {
  const {
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    ...rest
  } = useInfiniteQuery({
    ...appointmentsOptions(limit),
    ...options
  });

  const appointments = useMemo(() => {
    const appts = extractAppointments(data);
    const filtered = filterAppointments(appts, selectedPatient);

    const firstPastAppt = filtered.findIndex(({ startsAt }) => moment(startsAt).isBefore());
    const hasPastAppt = firstPastAppt > -1;
    const future = (hasPastAppt ? filtered.slice(0, firstPastAppt) : filtered).reverse();
    const past = hasPastAppt ? filtered.slice(firstPastAppt) : [];
    const next = future[0];

    return { future, past, next, hasPastAppt };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedPatient, isFetching]);

  useFocusEffect(
    useCallback(() => {
      if (isLoading || isFetchingNextPage) return;
      if (!appointments.hasPastAppt && hasNextPage) {
        void fetchNextPage();
      } else if (appointments.next && checkIsPast(appointments.next)) {
        void queryClient.invalidateQueries([QueryKeys.APPOINTMENTS]);
      }
    }, [
      isLoading,
      isFetchingNextPage,
      appointments.hasPastAppt,
      hasNextPage,
      fetchNextPage,
      appointments.next
    ])
  );

  return {
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    data: appointments,
    fetchNextPage,
    refetch,
    ...rest
  };
};

export default useAppointments;
