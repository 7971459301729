import { partition, cloneDeep } from 'lodash';
import moment from 'moment';

interface TransactionLike {
  amount: number;
  expiresAt: Date | null;
}

class AwardQueue<T extends TransactionLike> {
  private awards: T[] = [];
  private leftoverBalance = 0;

  private top() {
    return this.awards[0];
  }

  constructor(initial: T[] = [], initialLeftoverBalance = 0) {
    this.awards = initial;
    this.leftoverBalance = initialLeftoverBalance;
  }

  public addAward = (...transactions: T[]) => {
    this.awards.push(...cloneDeep(transactions.filter((txn) => txn.amount > 0)));
  };

  public getBalance = () => {
    return this.awards.reduce((acc, txn) => acc + txn.amount, this.leftoverBalance);
  };

  public removeExpiring = (date?: Date) => {
    if (!date) return [];
    const [expiring, remaining] = partition(this.awards, (txn) =>
      moment(txn.expiresAt).isBefore(date, 'day')
    );
    this.awards = remaining;
    return expiring.map((txn) => txn);
  };

  public adjustBalance = (amount: number) => {
    if (amount > 0) return;
    if (!this.top()) {
      this.leftoverBalance += amount;
    } else {
      this.top().amount += amount;
      while (this.top() && this.top().amount <= 0) {
        const previous = this.awards.shift()!;
        if (!this.awards.length) {
          this.leftoverBalance += previous.amount;
          return;
        } else {
          this.top().amount += previous.amount;
        }
      }
    }
  };

  public getQueue = () => cloneDeep(this.awards);
}

export default AwardQueue;
