import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { fieldNames, validationSchema } from './validation';
import PasswordInput from 'src/components/PasswordInput';
import { StyledPrimaryButton } from '../ProfileAppSettings/style';
import Toast from 'react-native-toast-message';
import { useMutation } from 'react-query';
import { changePassword } from 'src/api';
import { ChangePasswordPayload, ChangePasswordError } from 'src/interfaces';
import { isDefined } from 'src/utils';
import styled from 'styled-components/native';
import { Margin } from 'src/constants';
import { useAuth } from 'src/providers/AuthProvider';

const ChangePassword: React.FC = () => {
  const { logIn } = useAuth();
  const { t } = useTranslation('settings');

  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const clearErrors = (): void => {
    setCurrentPasswordError('');
    setNewPasswordError('');
  };

  const { mutate } = useMutation(
    async (user: ChangePasswordPayload) => await changePassword(user),
    {
      onSuccess: async (data, vars) => {
        void logIn({
          id: data.id,
          email: data.email,
          password: values.newPassword,
          practiceId: data.practiceId
        });
      },
      onError: (response: ChangePasswordError) => {
        try {
          const error: ChangePasswordError = response;
          const invalidPasswordEntered =
            isDefined(error.errors.current_password) &&
            isDefined(error.errors.current_password.find((message) => message.includes('invalid')));

          const newPasswordSameAsCurrent =
            isDefined(error.errors.password) &&
            isDefined(error.errors.password.find((message) => message.includes('different')));

          if (invalidPasswordEntered) {
            setCurrentPasswordError(t('invalidPassword'));
          } else if (newPasswordSameAsCurrent) {
            setNewPasswordError(t('shouldBeDifferent'));
          }
        } catch {
          Toast.show({ text1: response.message, type: 'error' });
        }
      }
    }
  );

  const onPressChangePassword = (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  ): void => {
    clearErrors();

    mutate({
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword
    });
  };

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      onPressChangePassword(values.currentPassword, values.newPassword, values.newPassword);
    }
  });

  return (
    <>
      <PasswordInput
        label={t('currentPassword')}
        value={values.currentPassword}
        onChangeText={handleChange(fieldNames[0])}
        error={!!errors.currentPassword || !!currentPasswordError}
        msg={errors.currentPassword ? t(errors.currentPassword) : currentPasswordError}
      />
      <PasswordInput
        label={t('newPassword')}
        value={values.newPassword}
        onChangeText={handleChange(fieldNames[1])}
        error={!!errors.newPassword || !!newPasswordError}
        msg={errors.newPassword ? t(errors.newPassword) : newPasswordError}
      />
      <PasswordInput
        label={t('confirmNewPassword')}
        value={values.confirmPassword}
        onChangeText={handleChange(fieldNames[2])}
        error={!!errors.confirmPassword}
        msg={!!errors.confirmPassword && t(errors.confirmPassword)}
      />
      <ButtonView>
        <StyledPrimaryButton onPress={handleSubmit}>{t('changePassword')}</StyledPrimaryButton>
      </ButtonView>
    </>
  );
};

export default ChangePassword;

export const ButtonView = styled(View)`
  padding-horizontal: ${Margin.Small}px;
`;
