import { BookingFormData } from '../model';
import { BOOKING_STEPS } from './enums';

const CONFIRMATION_SECTIONS: {
  [key in keyof BookingFormData]: {
    title: `section.${key}`;
    sectionKey: key;
    fields: Array<keyof BookingFormData[key]>;
    section: key;
    bookingStateKey: BOOKING_STEPS;
    fieldDisplay: 'row' | 'column';
  };
} = {
  appointmentInfo: {
    title: 'section.appointmentInfo',
    sectionKey: 'appointmentInfo',
    fields: ['appointmentOption', 'columnOption', 'preferredDate', 'preferredTime'],
    section: 'appointmentInfo',
    bookingStateKey: BOOKING_STEPS.SELECT_APPOINTMENT,
    fieldDisplay: 'row'
  },
  clientInfo: {
    title: 'section.clientInfo',
    sectionKey: 'clientInfo',
    fields: ['fullName', 'emailAddress', 'phoneNumber'],
    section: 'clientInfo',
    bookingStateKey: BOOKING_STEPS.CLIENT_INFO,
    fieldDisplay: 'row'
  },
  patientInfo: {
    title: 'section.patientInfo',
    sectionKey: 'patientInfo',
    fields: ['name', 'breed', 'sex', 'age'],
    section: 'patientInfo',
    bookingStateKey: BOOKING_STEPS.PATIENT_INFO,
    fieldDisplay: 'row'
  },
  additionalInfo: {
    title: 'section.additionalInfo',
    sectionKey: 'additionalInfo',
    fields: ['comments', 'images'],
    section: 'additionalInfo',
    bookingStateKey: BOOKING_STEPS.ADDITIONAL_INFO,
    fieldDisplay: 'column'
  },
  notificationSettings: {
    title: 'section.notificationSettings',
    sectionKey: 'notificationSettings',
    fields: ['smsOptIn', 'preferredContactMethod'],
    section: 'notificationSettings',
    bookingStateKey: BOOKING_STEPS.NOTIFICATION_SETTINGS,
    fieldDisplay: 'row'
  }
};

export default CONFIRMATION_SECTIONS;
