import _ from 'lodash';
import React from 'react';
import AppointmentsListItemLoading from './AppointmentListItemLoading';
import styled from 'styled-components/native';
import { Size } from 'src/constants';

interface Props {
  numItems?: number;
  numRightIcons?: number;
}

const PLACEHOLDER_NUM_ITEMS = 6;
const PLACEHOLDER_NUM_RIGHT_ICONS = 2;

const AllAppointmentsLoading: React.FC<Props> = ({
  numItems = PLACEHOLDER_NUM_ITEMS,
  numRightIcons = PLACEHOLDER_NUM_RIGHT_ICONS
}) => (
  <Container>
    {_.times(numItems, (i) => (
      <AppointmentsListItemLoading numRightIcons={numRightIcons} key={`Dummy-${i.toString()}`} />
    ))}
  </Container>
);

export default AllAppointmentsLoading;

const Container = styled.View`
  margin-top: ${Size.M}px;
`;
