import { HeaderBackButton, HeaderBackButtonProps } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LayoutAnimation } from 'react-native';
import {
  Alert,
  AlertHandle,
  Body,
  FocusAwareStatusBar,
  PrimaryGradientBackground
} from 'src/components';
import { AlertOption } from 'src/components/Alert';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Screens } from 'src/routes/stacks/screens';
import AccountLocated from './components/AccountLocated';
import ClientSearch from './components/ClientSearch';
import PracticeSearch from './components/PracticeSearch';
import PracticeSearchResults from './components/PracticeSearchResults';
import SearchHero from './components/SearchHero';
import { FormState, STATES, Actions, ReducerState, ACTIONS } from './helpers/model';
import { SliderElement, STEPS, TourGuide } from './tour';
import styled from 'styled-components/native';
import { Weight } from 'src/theme';
import openEmailToAlly from 'src/utils/openEmailToAlly';
import { makePhoneCall, openEmail } from 'src/utils';
import useGoBack from 'src/hooks/useGoBack';

const SLIDES: SlideItem[] = [
  {
    title: 'practiceClientSearch:tour.drawerTitle',
    body: 'practiceClientSearch:tour.drawerBody',
    image: <SearchHero />
  }
];

const PracticeClientSearch: React.FC = () => {
  const { t } = useTranslation('practiceClientSearch');
  const { colors } = useAppTheme();
  const { navigate, setOptions } = useNavigation();
  const goBack = useGoBack();

  const modalRef = useRef<AlertHandle>(null);

  const [state, dispatch] = useReducer(
    (state: ReducerState, { action, data }: Actions) => {
      const nextState = (state.state + 1) as FormState;
      const prevState = (state.state - 1) as FormState;
      const combinedData = { ...state.data, ...data };
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

      switch (action) {
        case ACTIONS.SHOW_MODAL:
          modalRef.current?.alert();
          return state;
        case ACTIONS.ADVANCE:
          if (state.state === FormState.ACCOUNT_LOCATED || !(nextState in FormState)) {
            return {
              ...state,
              exit: true
            };
          }
          return { ...STATES[nextState], data: state.data };
        case ACTIONS.GO_BACK:
          if (state.state === FormState.PRACTICE_SEARCH || !(prevState in FormState)) {
            return {
              ...state,
              exit: true
            };
          }
          return { ...STATES[prevState], data: state.data };
        case ACTIONS.UPDATE_DATA:
          return { ...state, data: combinedData };
      }
    },
    { ...STATES[FormState.PRACTICE_SEARCH], data: {} }
  );

  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    setOptions({
      title: t(state.title),
      headerLeft: (props: HeaderBackButtonProps) => (
        <HeaderBackButton
          {...props}
          onPress={() => {
            dispatch({ action: ACTIONS.GO_BACK });
          }}
        />
      )
    });
  }, [setOptions, t, state]);

  useEffect(() => {
    if (state.exit) {
      goBack();
    }
  }, [goBack, state.exit]);

  const options: AlertOption[] = useMemo(
    () =>
      state.state === FormState.CLIENT_SEARCH
        ? [
            {
              label: t('exit'),
              type: 'neutral',
              action: () => navigate(Screens.LANDING)
            },
            {
              label: t('practiceClientSearch:tryAgain')
            }
          ]
        : [
            {
              label: t('OK'),
              type: 'neutral'
            }
          ],
    [navigate, state.state, t]
  );

  const showModal = () => dispatch({ action: ACTIONS.SHOW_MODAL });
  const advance = () => dispatch({ action: ACTIONS.ADVANCE });
  const updateData = (data: Actions['data']) => dispatch({ action: ACTIONS.UPDATE_DATA, data });

  return (
    <TourGuide.Provider>
      <FocusAwareStatusBar barStyle={'light-content'} backgroundColor={colors.primary} />
      <PrimaryGradientBackground />
      {state.state === FormState.PRACTICE_SEARCH && (
        <PracticeSearch
          setModalTitle={setModalTitle}
          setModalText={setModalText}
          showAlert={showModal}
          moveNext={advance}
          updateData={updateData}
        />
      )}
      {state.state === FormState.PRACTICE_SEARCH_RESULTS && (
        <PracticeSearchResults moveNext={advance} updateData={updateData} state={state} />
      )}
      {state.state === FormState.CLIENT_SEARCH && (
        <ClientSearch
          setModalTitle={setModalTitle}
          setModalText={setModalText}
          showAlert={showModal}
          moveNext={advance}
          state={state}
          updateData={updateData}
        />
      )}
      {state.state === FormState.ACCOUNT_LOCATED && (
        <AccountLocated state={state} moveNext={advance} />
      )}

      <Alert
        title={modalTitle}
        body={
          <Trans
            t={t}
            components={{
              allyEmail: <Link onPress={openEmailToAlly} />,
              phone: (
                <Link onPress={() => makePhoneCall(state.data.selectedPractice?.phoneNumber)} />
              ),
              email: <Link onPress={async () => openEmail(state.data.selectedPractice?.email)} />
            }}
          >
            {modalText}
          </Trans>
        }
        ref={modalRef}
        options={options}
      />
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
    </TourGuide.Provider>
  );
};

export default PracticeClientSearch;

const Link = styled(Body)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary};
  font-weight: ${Weight.BOLD};
`;
