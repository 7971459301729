import moment from 'moment-timezone';
import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import { User } from 'react-native-feather';
import { IS_WEB, Margin } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

const ProfileHero: React.FC = () => {
  const { colors } = useAppTheme();
  const scale = useRef(new Animated.Value(0.5));
  useEffect(() => {
    const animation = Animated.timing(scale.current, {
      toValue: 1,
      duration: moment.duration(0.75, 'seconds').asMilliseconds(),
      useNativeDriver: !IS_WEB,
      easing: Easing.bounce
    });
    animation.start();
  }, [scale]);
  return (
    <PositionContainer style={{ transform: [{ scale: scale.current }] }}>
      <User width={300} height={300} stroke={colors.primary} strokeWidth={0.1} />
    </PositionContainer>
  );
};

export default ProfileHero;

const PositionContainer = styled(Animated.View)`
  padding: ${Margin.ExtraLarge}px;
  opacity: 0.5;
  align-items: center;
`;
