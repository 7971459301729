import React from 'react';
import { ChevronDown, ChevronUp } from 'react-native-feather';
import { Body } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { layout } from 'src/theme/globalStyles';
import { StyledHeader, ToggleIconContainer } from '../style';

interface Props {
  toggle?: () => void;
  isOpen: boolean;
  label: string | undefined;
}
const HeaderToggle: React.FC<Props> = ({ toggle, isOpen, label }) => {
  const { colors } = useAppTheme();
  return (
    <StyledHeader onPress={toggle}>
      <Body style={layout.flex1} color={colors.onSurface}>
        {label}
      </Body>
      <ToggleIconContainer>
        {!isOpen && <ChevronDown color={colors.onSurface} />}
        {isOpen && <ChevronUp color={colors.onSurface} />}
      </ToggleIconContainer>
    </StyledHeader>
  );
};

export default HeaderToggle;
