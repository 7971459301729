import { Request, RequestStatus } from 'src/interfaces';
import { DetailItem } from './model';
import moment from 'moment';
import { toLocalDateTime } from 'src/utils';
import Entypo from 'react-native-vector-icons/Entypo';
import { getRequestTargetName } from 'src/utils/requests';

const generateStandardData = (data: Request): DetailItem[] => [
  {
    title: 'requestLabels.createdAt',
    value: toLocalDateTime(moment(data.createdAt)),
    icon: 'calendar'
  },
  {
    title: 'requestLabels.type',
    value: `type.${data.type}`,
    icon: 'clipboard-list'
  },
  {
    title: 'requestLabels.for',
    value: getRequestTargetName(data),
    icon: data.patient ? 'paw' : 'user'
  },
  {
    title: 'requestLabels.status',
    value: `status.${data.status}`,
    ...statusName(data.status)
  }
];

export default generateStandardData;

const statusName = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.New:
      return {
        altIcon: Entypo,
        icon: 'new'
      };
    case RequestStatus.InProgress:
      return {
        altIcon: Entypo,
        icon: 'progress-two'
      };

    case RequestStatus.Archived:
      return {
        altIcon: Entypo,
        icon: 'archive'
      };
  }
};
