import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, Clock } from 'react-native-feather';
import { Row, FlexColumn, Caption } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import Shimmer from 'src/components/Shimmer';
import { Size, Margin, IconSize } from 'src/constants';
import { usePatient, usePractice } from 'src/hooks';
import { Appointment } from 'src/interfaces';
import { checkCanConfirm, checkIsExpired, checkIsSoon } from 'src/scenes/Appointments/helper';
import styled from 'styled-components/native';
import AppointmentStatusCta from '../AppointmentStatusCta';
import AnimalIcon from 'src/components/Icons/Animal';
import { toLocalDate, toLocalTime } from 'src/utils';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  appointment: Appointment;
}

interface IconDescription {
  icon: React.ReactNode;
  description: string;
}

const AppointmentCardNext: React.FC<Props> = ({ appointment }) => {
  const { colors } = useAppTheme();
  const { i18n } = useTranslation('appointments');

  const startTime = moment(appointment.startsAt).locale(i18n.language);
  const { data: patient, isLoading: patientIsLoading } = usePatient(appointment.patientId);
  const { data: practice } = usePractice();

  const data: Array<IconDescription | false> = useMemo(
    () => [
      !!patient && {
        icon: <AnimalIcon color={colors.onSurface} size={IconSize.XS} species={patient.species} />,
        description: appointment.patientDisplay
      },
      {
        icon: <Calendar color={colors.onSurface} width={IconSize.XS} height={IconSize.XS} />,
        description: toLocalDate(startTime, practice?.timeZone)
      },
      {
        icon: <Clock color={colors.onSurface} width={IconSize.XS} height={IconSize.XS} />,
        description: toLocalTime(startTime, practice?.timeZone)
      }
    ],
    [patient, colors.onSurface, appointment.patientDisplay, startTime, practice?.timeZone]
  );

  const isLoading = patientIsLoading;

  const [isSoon, setIsSoon] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [canConfirm, setCanConfirm] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setIsSoon(checkIsSoon(appointment));
      setIsExpired(checkIsExpired(appointment));
      setCanConfirm(checkCanConfirm(appointment));
    }, [appointment])
  );

  return (
    <Container>
      <PatientAvatar patient={patient} size={IconSize.XXL} />
      <Content>
        {data.map(
          (detail) =>
            detail && (
              <Row key={detail.description}>
                <StyledIcon>
                  <Shimmer isLoading={isLoading} size={Size.M}>
                    {detail.icon}
                  </Shimmer>
                </StyledIcon>
                <FlexColumn align={'flex-start'}>
                  <Shimmer isLoading={isLoading}>
                    <Caption color={colors.onSurface}>{detail.description}</Caption>
                  </Shimmer>
                </FlexColumn>
              </Row>
            )
        )}
        <StyledCtaContainer>
          <Shimmer isLoading={isLoading}>
            <StyledAppointmentStatusCta {...{ appointment, isSoon, isExpired, canConfirm }} />
          </Shimmer>
        </StyledCtaContainer>
      </Content>
    </Container>
  );
};

export default AppointmentCardNext;

const Container = styled.View`
  flex-direction: row;
  gap: ${Margin.Medium}px;
`;

const Content = styled.View`
  flex: 1;
  gap: ${Margin.Medium}px;
  height: auto;
`;

const StyledIcon = styled.View`
  width: ${Size.M}px;
  margin-right: ${Margin.Medium}px;
  align-content: center;
  align-items: center;
`;

const StyledCtaContainer = styled.View`
  flex-direction: row;
  flex-grow: 1;
  justify-content: ${({ theme }) => (theme.viewMode.isMobile ? 'center' : 'flex-end')};
  align-items: flex-end;
`;

const StyledAppointmentStatusCta = styled(AppointmentStatusCta)`
  margin-top: ${Margin.Medium}px;
  martin-bottom: ${Margin.Small}px;
  justify-content: center;
  width: ${({ theme }) => (theme.viewMode.isMobile ? '100%' : 'auto')};
`;
