import React from 'react';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { Body } from 'src/components';
import { Margin } from 'src/constants';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

interface Props {
  remove: () => void;
  dragAnimatedValue: Animated.AnimatedInterpolation<number>;
}

const DeleteButton: React.FC<Props> = ({ remove, dragAnimatedValue }) => {
  const { t } = useTranslation('common');
  const opacity = dragAnimatedValue.interpolate({
    inputRange: [-50, 0],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  });

  return (
    <TouchableRipple onPress={remove}>
      <Button style={{ opacity }}>
        <Body color={palette.AB_BRIGHT_WHITE}>{t('delete')}</Body>
      </Button>
    </TouchableRipple>
  );
};

export default DeleteButton;

const Button = styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.error}
  padding: ${Margin.Large}px;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
