import { useQuery, UseQueryOptions } from 'react-query';
import { getPractice, QueryKeys } from 'src/api';
import Practice from 'src/interfaces/api/Practice';
import { useUser } from 'src/providers/ClientProvider';

type Options = Omit<UseQueryOptions<Practice, unknown, Practice, any>, 'queryKey' | 'queryFn'>;

export const practiceOptions = (
  practiceId?: Practice['id']
): UseQueryOptions<Practice, unknown, Practice, any> => ({
  queryKey: [QueryKeys.PRACTICE, practiceId],
  queryFn: async () => getPractice(practiceId)
});

const usePractice = (options?: Options) => {
  const { user } = useUser();
  return useQuery({
    ...practiceOptions(user?.practiceId),
    ...options
  });
};

export default usePractice;
