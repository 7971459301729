export enum ALLY_ERROR_CODE {
  NEED_TO_ACCEPT_TERMS = 1001,
  PASSWORD_RESET_REQUIRED = 1002,
  CLIENT_NEEDS_UPGRADE = 1003
}

export interface AllyError {
  allyErrorCode?: ALLY_ERROR_CODE;
  error: string;
}
