import React from 'react';
import { LifelearnArticle } from 'src/interfaces';
import styled from 'styled-components/native';
import {
  IMG_ASPECT_RATIO_MOBILE,
  IMG_ASPECT_RATIO_STANDARD,
  MOBILE_WIDTH_MAX,
  Size
} from 'src/constants';
import { Body, HoverCard, Subheading } from 'src/components';
import { Screens } from 'src/routes/stacks/screens';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { cleanUrl } from 'src/utils';

interface Props {
  article: LifelearnArticle;
  numCols: number;
}

const TEXT_CONTAINER_HEIGHT = 164;

const ArticleCard: React.FC<Props> = ({ article, numCols }) => {
  const { navigate } = useNavigation<NavigationProp<HomeStackParamsList>>();

  return (
    <Holder numCols={numCols}>
      <Container onPress={() => navigate(Screens.ARTICLE, { id: article.id })}>
        <StyledImage source={{ uri: cleanUrl(article.featured_image.image) }} />
        <TextContainer numCols={numCols}>
          <Subheading numberOfLines={2}>{article.title}</Subheading>
          <Body numberOfLines={4}>{article.summary}</Body>
        </TextContainer>
      </Container>
    </Holder>
  );
};

export default ArticleCard;

const Holder = styled.View<{ numCols: number }>`
  width: ${({ numCols, theme }) =>
    numCols > 1 ? Math.floor((1 / numCols) * theme.viewMode.maxContentWidth) : MOBILE_WIDTH_MAX}px;
  max-width: 100%;
`;

const Container = styled(HoverCard)`
  border-radius: ${({ theme }) => theme.roundness}px;
  align-items: center;
  margin: ${Size.X2_S}px;
  cursor: pointer;
`;

const TextContainer = styled.View<{ numCols: number }>`
  height: ${TEXT_CONTAINER_HEIGHT}px;
  gap: ${Size.S}px;
  padding: ${Size.S}px;
`;

const StyledImage = styled.Image.attrs({ resizeMode: 'cover' })`
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
  width: 100%;
  aspect-ratio: ${({ theme }) =>
    theme.viewMode.isMobile ? IMG_ASPECT_RATIO_MOBILE : IMG_ASPECT_RATIO_STANDARD};
`;
