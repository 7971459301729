import { throttle, uniqBy } from 'lodash';
import moment from 'moment';
import { endpoint, TRACKING_EVENTS } from 'src/constants';
import { apiClient } from '../axios';
import isDefined from '../isDefined';
import { TrackingEvent } from './model';
import { getVisitToken, getVisitorToken } from './tokens';

const queuedEvents: TrackingEvent[] = [];

/**
 * Submits queued events to the server at most once every minute.
 */
const submitEvents = throttle(
  async () => {
    const events = queuedEvents.splice(0, queuedEvents.length);
    const visitToken = await getVisitToken();
    const visitorToken = await getVisitorToken();
    void apiClient.post(endpoint('AHOY_EVENTS'), {
      visit_token: visitToken.uuid,
      visitor_token: visitorToken.uuid,
      events_json: JSON.stringify(uniqBy(events, 'name'))
    });
  },
  moment.duration(1, 'minutes').asMilliseconds(),
  {
    leading: false,
    trailing: true
  }
);

/**
 * Queues an event to be sent to the server, attaching the current time.
 * @param {TRACKING_EVENTS} name The name of the event.
 * @param {unknown} properties The properties of the event. These will be stringified.
 */
export const trackEvent = (name: TRACKING_EVENTS, properties?: unknown) => {
  queuedEvents.push({
    name,
    properties: isDefined(properties) ? JSON.stringify(properties) : undefined,
    time: Date.now() / 1000
  });
  void submitEvents();
};

/**
 * Flushes all queued events to the server.
 */
export const flushEvents = submitEvents.flush;
