import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const HairDryer: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg fill={color} width={size} height={size} viewBox='0 0 24 24'>
      <Path d='M22 9C21.224 9.01944 20.4571 8.82945 19.78 8.45C19.2391 8.13961 18.6233 7.98393 18 8V7C18.7759 6.98056 19.5428 7.17055 20.22 7.55C20.7608 7.86039 21.3766 8.01607 22 8M22 6C21.3766 6.01607 20.7608 5.86039 20.22 5.55C19.5428 5.17055 18.7759 4.98056 18 5V6C18.6233 5.98393 19.2391 6.13961 19.78 6.45C20.4571 6.82945 21.224 7.01944 22 7M22 10C21.3766 10.0161 20.7608 9.86039 20.22 9.55C19.5428 9.17055 18.7759 8.98056 18 9V10C18.6233 9.98393 19.2391 10.1396 19.78 10.45C20.4571 10.8294 21.224 11.0194 22 11M9.99997 12.73C12.3613 12.2733 14.6977 11.6959 17 11V4C17 4 10.5 2 7.49997 2C6.16447 2.00376 4.87596 2.49332 3.8749 3.37732C2.87383 4.26132 2.22861 5.47936 2.05965 6.80414C1.89069 8.12891 2.20952 9.46991 2.95665 10.5769C3.70378 11.6838 4.82817 12.4811 6.11997 12.82L6.99997 19H7.99997C8.03996 19.4766 8.19334 19.9368 8.44732 20.3421C8.70129 20.7474 9.0485 21.0861 9.45997 21.33C9.92391 21.6058 10.3092 21.9962 10.5789 22.4638C10.8485 22.9313 10.9935 23.4603 11 24H12C11.9954 23.3111 11.8182 22.6343 11.4845 22.0316C11.1509 21.4289 10.6714 20.9195 10.09 20.55C9.38997 20 8.99997 19.63 8.99997 19H9.99997M7.49997 10C7.00552 10 6.52217 9.85338 6.11105 9.57867C5.69993 9.30397 5.37949 8.91352 5.19027 8.45671C5.00106 7.99989 4.95155 7.49723 5.04801 7.01227C5.14447 6.52732 5.38258 6.08186 5.73221 5.73223C6.08184 5.3826 6.5273 5.1445 7.01225 5.04804C7.4972 4.95157 7.99987 5.00108 8.45668 5.1903C8.9135 5.37952 9.30394 5.69995 9.57865 6.11107C9.85335 6.5222 9.99997 7.00555 9.99997 7.5C9.99997 8.16304 9.73658 8.79893 9.26774 9.26777C8.7989 9.73661 8.16301 10 7.49997 10Z' />
    </Svg>
  );
};

export default HairDryer;
