import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Prescriptions: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M10.125 19.313a1.125 1.125 0 0 0 1.125 1.125H18a1.125 1.125 0 0 0 1.125-1.125V6.938h-9v4.5m-3.744 4.13 2.425 2.426M19.688 6.938H9.562A.562.562 0 0 1 9 6.375V4.687a1.125 1.125 0 0 1 1.125-1.125h9a1.125 1.125 0 0 1 1.125 1.126v1.687a.563.563 0 0 1-.563.563ZM6.866 19.934a1.716 1.716 0 0 1-2.426-2.426l3.885-3.882a1.715 1.715 0 1 1 2.426 2.427l-3.885 3.881Zm6.634-8.497h5.625v5.624H13.5v-5.625Z'
    />
  </Svg>
);
export default Prescriptions;
