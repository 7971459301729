import React from 'react';
import { useBookingState } from '../BookingProvider';
import { BOOKING_STEPS } from '../BookingProvider/helpers';
import AdditionalInfo from './AdditionalInfo';
import ClientInformation from './ClientInformation';
import Confirmation from './Confirmation';
import PatientInfo from './PatientInfo';
import SelectAppointment from './SelectAppointment';
import NotificationSettings from './NotificationSettings';
import BookingStatus from './BookingStatus';

const Content: React.FC = () => {
  const {
    bookingState: { current }
  } = useBookingState();

  if (current === BOOKING_STEPS.CLIENT_INFO) {
    return <ClientInformation />;
  }

  if (current === BOOKING_STEPS.PATIENT_INFO) {
    return <PatientInfo />;
  }

  if (current === BOOKING_STEPS.NOTIFICATION_SETTINGS) {
    return <NotificationSettings />;
  }

  if (current === BOOKING_STEPS.ADDITIONAL_INFO) {
    return <AdditionalInfo />;
  }

  if (current === BOOKING_STEPS.SELECT_APPOINTMENT) {
    return <SelectAppointment />;
  }

  if (current === BOOKING_STEPS.CONFIRMATION) {
    return <Confirmation />;
  }

  if (current === BOOKING_STEPS.STATUS) {
    return <BookingStatus />;
  }
};

export default Content;
