import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from '../../tour';

interface Props extends PropsWithChildren {
  initialIndex: number;
  thisIndex: number;
  focusNext: () => void;
}

const GuideWrapper: React.FC<Props> = ({ initialIndex, thisIndex, children, focusNext }) => {
  const { t } = useTranslation('loyalty');
  const { colors } = useAppTheme();

  return (
    <GuideElement
      backgroundColor={colors.surface}
      id={STEPS.BAR}
      body={t(initialIndex ? 'tour.tab_rewards' : 'tour.tab_activity')}
      onContinue={focusNext}
      disabled={initialIndex === thisIndex}
    >
      {children}
    </GuideElement>
  );
};

export default GuideWrapper;
