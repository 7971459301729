import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit2 } from 'react-native-feather';
import { FAB } from 'react-native-paper';
import { Row, Title } from 'src/components';
import AnimalIcon from 'src/components/Icons/Animal';
import PatientID from 'src/components/Icons/PatientID';
import { IS_WEB, IconSize, Size } from 'src/constants';
import { Patient } from 'src/interfaces';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { GuideElement, STEPS } from 'src/scenes/PatientList/tour';
import styled from 'styled-components/native';

interface Props {
  patient: Patient;
  advance: () => void;
  navigation: NativeStackNavigationProp<MainStackParamsList, Screens.PATIENT_PROFILE, undefined>;
}

const Header: React.FC<Props> = ({ patient, advance, navigation }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('patientProfile');

  return (
    <StyledRow justify='flex-start'>
      <AnimalIcon color={colors.onSurface} size={IconSize.M} species={patient.species} />
      <Title>{patient.name}</Title>
      <StyledRow justify='flex-end'>
        <GuideElement body={t('patientProfile:tour.petId')} id={STEPS.PET_ID} autoStart>
          <StyledFAB
            size='small'
            icon={() => <PatientID color={colors.onPrimary} />}
            onPress={() =>
              navigation.getParent()?.navigate(Screens.PATIENT_ID, { id: patient.patientId })
            }
          />
        </GuideElement>
        <GuideElement
          body={t('patientProfile:tour.edit')}
          id={STEPS.EDIT_INFO}
          onContinue={() => advance()}
        >
          <StyledFAB
            size={'small'}
            icon={() => <Edit2 color={colors.onPrimary} />}
            onPress={() => advance()}
          />
        </GuideElement>
      </StyledRow>
    </StyledRow>
  );
};

export default Header;

const StyledRow = styled(Row)`
  flex: 1;
  gap: ${Size.S}px;
  margin-vertical: ${IS_WEB ? Size.S : 0}px;
`;

const StyledFAB = styled(FAB)`
  background-color: ${({ theme }) => theme.colors.primary};
`;
