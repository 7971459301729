import React from 'react';
import { useTranslation } from 'react-i18next';
import { Subheading } from 'src/components/Text';
import { Size } from 'src/constants';
import styled from 'styled-components/native';
import Confetti from './Confetti';

const TodayPlaceholder: React.FC = () => {
  const { t } = useTranslation();
  return (
    <CompleteContainer>
      <ConfettiBackground>
        <Confetti width={'100%'} height={'100%'} preserveAspectRatio={'xMidYMid slice'} />
      </ConfettiBackground>
      <StyledHeading>{t('sections.allCaughtUp', { ns: 'todos' })}</StyledHeading>
    </CompleteContainer>
  );
};

export default TodayPlaceholder;

const CompleteContainer = styled.View`
  align-items: center;
  justify-content: center;
`;

const ConfettiBackground = styled.View`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const StyledHeading = styled(Subheading)`
  margin-top: ${Size.XL}px;
  margin-bottom: ${Size.X3_L}px;
`;
