import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Pill: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M14.45 15.12 8.88 9.55m5.97-2.784a1.688 1.688 0 0 1 2.387 0m-3.583-1.989-8.75 8.752a3.937 3.937 0 0 0 5.568 5.567l8.75-8.746a3.94 3.94 0 0 0-5.568-5.573Z'
    />
  </Svg>
);
export default Pill;
