import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import ReactNativeModal from 'react-native-modal';
import { Size } from 'src/constants';
import { useBoolean } from 'src/hooks';
import { ForwardRefFC } from 'src/types';
import styled from 'styled-components/native';
import BottomButton from './BottomButton';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { pick } from 'lodash';
import { Portal } from 'react-native-paper';

interface ModalDrawerProps extends PropsWithChildren {
  startOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  bottomButton?: {
    onPress: () => void;
    label: React.ReactNode;
    dismiss?: boolean;
  };
  cardStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  disableBackgroundDismiss?: boolean;
}

export interface ModalDrawerHandle {
  dismiss: () => void;
  show: () => void;
  isVisible: boolean;
}

const ModalDrawer: ForwardRefFC<ModalDrawerHandle, ModalDrawerProps> = React.forwardRef<
  ModalDrawerHandle,
  ModalDrawerProps
>(
  (
    {
      startOpen,
      onClose,
      children,
      bottomButton,
      cardStyle,
      onOpen,
      disableBackgroundDismiss,
      contentContainerStyle
    },
    ref
  ) => {
    const { value: isVisible, toTrue: open, toFalse: close } = useBoolean(!!startOpen);
    const [visible, setVisible] = useState(isVisible);

    const onAction = useCallback(() => {
      if (bottomButton?.dismiss ?? true) {
        close();
      }
      bottomButton?.onPress();
    }, [bottomButton, close]);

    useImperativeHandle(
      ref,
      () => ({
        dismiss: close,
        show: open,
        isVisible
      }),
      [close, isVisible, open]
    );

    const { top } = useSafeAreaInsets();

    useEffect(() => close, [close]);

    useEffect(() => {
      if (isVisible) {
        setVisible(true);
      }
    }, [isVisible]);

    if (!visible) return null;

    return (
      <Modal
        onBackdropPress={!disableBackgroundDismiss ? close : undefined}
        isVisible={isVisible}
        onDismiss={close}
        onBackButtonPress={!disableBackgroundDismiss ? close : undefined}
        onModalShow={onOpen}
        statusBarTranslucent
        onModalHide={() => {
          onClose?.();
          setVisible(false);
        }}
      >
        <Portal.Host>
          <ModalContent paddingTop={top}>
            <Container style={pick(StyleSheet.flatten(cardStyle), 'width', 'maxWidth', 'minWidth')}>
              <Card style={cardStyle} contentContainerStyle={contentContainerStyle}>
                {children}
              </Card>
              {!!bottomButton && (
                <StyledBottomButtonContainer>
                  <BottomButton onPress={onAction}>{bottomButton.label}</BottomButton>
                </StyledBottomButtonContainer>
              )}
            </Container>
          </ModalContent>
        </Portal.Host>
      </Modal>
    );
  }
);

export default ModalDrawer;

const Modal = styled(ReactNativeModal)`
  margin-bottom: 0px;
  flex: 1;
`;

const Card = styled.ScrollView.attrs({
  alwaysBounceHorizontal: false,
  alwaysBounceVertical: false
})`
  border-top-left-radius: ${({ theme }) => (theme.viewMode.isMobile ? Size.M : theme.roundness)}px;
  border-top-right-radius: ${({ theme }) => (theme.viewMode.isMobile ? Size.M : theme.roundness)}px;
  background-color: ${({ theme }) => theme.colors.surface};
  overflow: hidden;
`;

const Container = styled.View`
  width: ${({ theme }) => theme.viewMode.maxContentWidth}px;
`;

const StyledBottomButtonContainer = styled.View`
  border-bottom-left-radius: ${({ theme }) => (theme.viewMode.isMobile ? 0 : theme.roundness)}px;
  border-bottom-right-radius: ${({ theme }) => (theme.viewMode.isMobile ? 0 : theme.roundness)}px;
  overflow: hidden;
`;

const ModalContent = styled.View.attrs({
  pointerEvents: 'box-none'
})<{ paddingTop: number }>`
  margin: ${({ theme }) => (theme.viewMode.isMobile ? '0px' : 'auto')};
  padding-top: ${({ paddingTop }) => paddingTop}px;
  flex: 1;
  justify-content: ${({ theme }) => (theme.viewMode.isMobile ? 'flex-end' : 'center')};
  align-items: center;
`;
