enum EmailVerificationStatus {
  UNKNOWN = 'unknown',
  IN_PROGRESS = 'in_progress',
  VERIFIED = 'verified',
  BAD = 'bad',
  INVALID = 'invalid',
  DECLINED = 'declined',
  BOUNCED = 'bounced',
  SPAM_REPORT = 'spam_report',
  MISSING = 'missing'
}

export default EmailVerificationStatus;
