import { createRef, RefObject } from 'react';

/**
 * Maps the first order properties of an existing object to a ref
 * @param input
 * @returns object refs
 */
const createNamedRefs = <T, V extends Object>(
  input: V
): {
  [key in keyof V]: RefObject<T>;
} => {
  const object: Partial<{ [key in keyof V]: RefObject<T | null> }> = {};
  Object.keys(input).forEach((key) => {
    Object.assign(object, { [key]: createRef<T>() });
  });
  return object as { [key in keyof V]: RefObject<T> };
};

export default createNamedRefs;
