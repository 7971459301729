import { Fonts } from 'react-native-paper/lib/typescript/src/types';

export enum FontSize {
  CAPTION = 14,
  BODY = 16,
  SUBHEADING = 18,
  HEADLINE = 20,
  TITLE = 24
}

export const fonts = {
  roboto: {
    thin: 'Roboto_100Light',
    light: 'Roboto_300Light',
    regular: 'Roboto_400Regular',
    medium: 'Roboto_500Medium',
    bold: 'Roboto_700Bold',
    black: 'Roboto_900Black',
    thinItalic: 'Roboto_100Light_Italic',
    lightItalic: 'Roboto_300Light_Italic',
    regularItalic: 'Roboto_400Regular_Italic',
    mediumItalic: 'Roboto_500Medium_Italic',
    semiboldItalic: 'Roboto_600Semibold_Italic',
    boldItalic: 'Roboto_700Bold_Italic',
    blackItalic: 'Roboto_900Black_Italic'
  }
};

export enum Weight {
  THIN = '100',
  LIGHT = '300',
  REGULAR = '400',
  MEDIUM = '500',
  BOLD = '700',
  BLACK = '900'
}

export const WeightNames: { [w in Weight]: string } = {
  [Weight.THIN]: 'Thin',
  [Weight.LIGHT]: 'Light',
  [Weight.REGULAR]: 'Regular',
  [Weight.MEDIUM]: 'Medium',
  [Weight.BOLD]: 'Bold',
  [Weight.BLACK]: 'Black'
};

export const themeFontsConfig: Fonts = {
  light: {
    fontFamily: fonts.roboto.light,
    fontWeight: Weight.LIGHT
  },
  regular: {
    fontFamily: fonts.roboto.regular,
    fontWeight: Weight.REGULAR
  },
  medium: {
    fontFamily: fonts.roboto.medium,
    fontWeight: Weight.MEDIUM
  },
  thin: {
    fontFamily: fonts.roboto.thin,
    fontWeight: Weight.THIN
  }
};
