import _ from 'lodash';
import { PropsWithChildren, useMemo } from 'react';
import { StyleSheet, TextProps as RNTextProps, TextStyle, StyleProp } from 'react-native';
import { Text } from 'react-native-paper';
import { ThemeProp } from 'react-native-paper/lib/typescript/src/types';
import { IS_WEB } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { FontSize, WeightNames, Weight } from 'src/theme';
import { containers, fontStyles } from 'src/theme/globalStyles';

export interface TextProps extends Omit<RNTextProps & TextStyle, 'theme' | 'fontFamily'> {
  shadow?: boolean;
  fontWeight?: Weight;
  children?: React.ReactNode;
  theme?: ThemeProp | undefined;
  style?: StyleProp<TextStyle>;
}

export const Body: React.FC<PropsWithChildren<TextProps>> = ({
  children,
  shadow,
  style,
  color,
  fontSize = FontSize.BODY,
  fontStyle,
  fontWeight = Weight.REGULAR,
  letterSpacing,
  textAlign,
  textDecorationLine,
  textDecorationStyle,
  textDecorationColor,
  textShadowColor,
  textShadowOffset,
  textShadowRadius,
  textTransform,
  numberOfLines,
  lineHeight,
  ...props
}) => {
  const { colors } = useAppTheme();
  const flatStyle = StyleSheet.flatten([style]);

  const fontFamily = useMemo(() => {
    const finalWeight = (flatStyle?.fontWeight ?? fontWeight) as Weight;
    let font = `Roboto_${finalWeight}${WeightNames[finalWeight]}`;
    font = fontStyle === 'italic' ? `${font}_Italic` : font;
    return font;
  }, [flatStyle?.fontWeight, fontStyle, fontWeight]);

  const flattenedStyle = StyleSheet.flatten([
    fontStyles.body,
    shadow && !IS_WEB && containers.shadow,
    {
      flexShrink: 1,
      color: color ?? colors.text,
      fontFamily,
      fontSize,
      fontStyle,
      letterSpacing,
      textAlign,
      textDecorationLine,
      textDecorationStyle,
      textDecorationColor,
      textShadowColor,
      textShadowOffset,
      textShadowRadius,
      textTransform,
      lineHeight
    },
    _.omit(flatStyle, 'fontFamily', 'fontWeight')
  ]);

  return (
    <Text numberOfLines={numberOfLines} style={flattenedStyle} {...props}>
      {children}
    </Text>
  );
};

export const Caption: React.FC<PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Body {...fontStyles.caption} {...props}>
      {children}
    </Body>
  );
};

export const Subheading: React.FC<PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Body {...fontStyles.subheading} {...props}>
      {children}
    </Body>
  );
};

export const Headline: React.FC<PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Body {...fontStyles.headline} {...props}>
      {children}
    </Body>
  );
};

export const Title: React.FC<PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Body {...fontStyles.title} {...props}>
      {children}
    </Body>
  );
};
