import { Alarm } from 'expo-calendar';
import { t } from 'i18next';
import { AnySchema, mixed, object, string } from 'yup';

export interface CalendarEntry {
  title: string;
  calendarId: string;
  notes?: string;
  alerts: Alarm[];
}

export const fieldNames = ['title', 'calendarId', 'alerts', 'notes'] as const;
export type FieldNames = (typeof fieldNames)[number];

export const validationSchema = object<Record<FieldNames, AnySchema>>({
  title: string().required(t('common:required')),
  calendarId: string().required(),
  notes: string()
    .optional()
    .max(240, t('maxLengthOther', { ns: 'petInfoUpdate' })),
  alerts: mixed<number[]>()
});
