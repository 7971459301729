import React from 'react';
import { HoverCard, Subheading } from 'src/components';
import { IconProps } from 'src/components/Icons';
import { IconSize, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

interface Props {
  toggle?: () => void;
  isOpen: boolean;
  label: string | undefined;
  icon: (props: IconProps) => React.ReactElement;
}
const HeaderToggle: React.FC<Props> = ({ toggle, isOpen, label, icon: Icon }) => {
  const { colors } = useAppTheme();
  return (
    <StyledHeader onPress={toggle}>
      <HeadingIconContainer>
        <Icon color={colors.onSurface} size={IconSize.S} />
      </HeadingIconContainer>
      <Subheading style={layout.flex1} color={colors.onSurface}>
        {label}
      </Subheading>
    </StyledHeader>
  );
};

export default HeaderToggle;

const StyledHeader = styled(HoverCard).attrs({ hideShadow: true })`
  padding: ${Size.S}px;
  flex-direction: row;
`;

const HeadingIconContainer = styled.View`
  margin-right: ${Size.X2_S}px;
`;
