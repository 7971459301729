import React from 'react';
import { STEPS, SliderElement } from '../../tour';
import { SlideItem } from 'src/components/kit/Slider/Slide';
import PawPathSuper from './assets/PawPathSuper';
import MailHero from './assets/MailSuper';
import LogoSuper from './assets/LogoSuper';

const slides: SlideItem[] = [
  {
    title: 'login:tour.slide0.title',
    body: 'login:tour.slide0.body',
    image: <LogoSuper />
  },
  {
    title: 'login:tour.slide1.title',
    body: 'login:tour.slide1.body',
    image: <PawPathSuper />
  },
  {
    title: 'login:tour.slide2.title',
    image: <MailHero />,
    body: 'login:tour.slide2.body'
  }
];

const IntroDrawer: React.FC = () => <SliderElement slides={slides} id={STEPS.DRAWER} />;

export default IntroDrawer;
