import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Plus } from 'react-native-feather';
import { FAB } from 'react-native-paper';
import { IconSize } from 'src/constants';
import { useRequestSetting, useViewMode } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { palette } from 'src/theme';
import { styles } from './styles';
import { layout } from 'src/theme/globalStyles';
import { requestActions } from './helper';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestWizardFAB: React.FC<Props> = ({ open, setOpen }) => {
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();
  const { navigate } = useNavigation();
  const { requestAppointment } = useRequestSetting();

  const style = useMemo(() => {
    return styles(colors, horizontalInset);
  }, [colors, horizontalInset]);

  const { data: requestSettings } = useRequestSetting();

  const actions = useMemo(
    () => requestActions(requestSettings, navigate, requestAppointment, style, colors.onPrimary),
    [colors.onPrimary, navigate, requestAppointment, requestSettings, style]
  );

  return (
    <FAB.Group
      color={colors.onPrimary}
      backdropColor={palette.BLACK_OPACITY_54}
      fabStyle={style.fabStyle}
      open={open}
      visible={!open}
      icon={() => (
        <Plus
          style={layout.justifyCenter}
          color={colors.onPrimary}
          height={IconSize.M}
          width={IconSize.M}
        />
      )}
      actions={actions}
      onStateChange={() => setOpen(!open)}
    />
  );
};

export default RequestWizardFAB;
