import { endpoint } from 'src/constants/api';
import { ClientPracticeConfiguration } from 'src/interfaces/api';
import { isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';

export const getClientPracticeConfiguration = async (
  sourceId: number,
  clientId: string
): Promise<ClientPracticeConfiguration> => {
  const response = await apiClient.get<ClientPracticeConfiguration>(
    endpoint('GET_CLIENT_PRACTICE_CONFIG', { sourceId, clientId })
  );
  return isDefinedOrThrowServerError(response.data);
};

export const patchClientPracticeConfiguration = async (
  clientPracticeConfiguration: ClientPracticeConfiguration
): Promise<ClientPracticeConfiguration> => {
  const response = await apiClient.patch<ClientPracticeConfiguration>(
    endpoint('PATCH_CLIENT_PRACTICE_CONFIG', { id: clientPracticeConfiguration.id }),
    { clientPracticeConfiguration }
  );
  return isDefinedOrThrowServerError(response.data);
};
