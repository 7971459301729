import { Schedule } from 'src/interfaces';

export const NO_PREFERENCE = 'No Preference';
export interface ColumnOption {
  label: string;
  value: string;
  categoryId?: number;
  appointmentLength?: number;
  colKey?: string;
  duplicateOfAppointmentCategoryId?: string | undefined;
}

const extractColumnOptions = (schedule: Schedule): ColumnOption[] => {
  const categories = [];
  if (schedule?.allowNoPreference) {
    categories.push({
      label: 'common:noPreference',
      value: NO_PREFERENCE
    });
  }
  if (schedule.categories) {
    for (const [colKey, value] of Object.entries(schedule.categories)) {
      const key = value.duplicateOfAppointmentCategoryId ?? value.id;

      const appointmentLength = schedule.useAppointmentTypes
        ? schedule.appointmentTypes?.find((v) => v.id === key)?.length
        : schedule.categories[key]?.appointmentLength;

      categories.push({
        label: value.descriptionOverride ? value.descriptionOverride : value.description,
        value: value.descriptionOverride ? value.descriptionOverride : value.description,
        categoryId: value.id,
        appointmentLength: appointmentLength ?? schedule.categories[key]?.appointmentLength,
        colKey,
        duplicateOfAppointmentCategoryId: value.duplicateOfAppointmentCategoryId
      });
    }
  }
  return categories;
};

export default extractColumnOptions;
