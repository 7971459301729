import { Patient } from 'src/interfaces';
import { Request } from 'src/interfaces/api/Request';
import useUploadPatientPhoto from 'src/hooks/react-query/useUploadPatientPhoto';
import { InfoUpdateRequest } from 'src/interfaces/api/InfoUpdateRequest';
import { QueryKeys, patchPatient, postRequest } from 'src/api';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  useMutation,
  useQueryClient
} from 'react-query';

export interface UsePatientDataHook {
  submitAdditionalInfoUpdate: UseMutateAsyncFunction<Patient, unknown, Patient, unknown>;
  submitInfoUpdateRequest: UseMutateAsyncFunction<Request, Error, InfoUpdateRequest, unknown>;
  uploadPhoto: UseMutateFunction<
    Patient,
    Error,
    {
      patient: Patient;
      uri: string;
    },
    unknown
  >;
  deletePhoto: () => void;
}
const usePatientData = (patient: Patient | undefined): UsePatientDataHook => {
  const { t } = useTranslation('patientProfile');
  const queryClient = useQueryClient();
  const { uploadPhoto, deletePhoto } = useUploadPatientPhoto(patient);
  const { mutateAsync: submitAdditionalInfoUpdate } = useMutation(
    async (patient: Patient) => await patchPatient(patient),
    {
      onSuccess: async () => queryClient.refetchQueries([QueryKeys.PATIENT, patient?.patientId]),
      onError: () => {
        Toast.show({
          type: 'error',
          text1: t('appointmentRequest:errorToast')
        });
      }
    }
  );

  const { mutateAsync: submitInfoUpdateRequest } = useMutation(
    async (request: InfoUpdateRequest) => await postRequest(request),
    {
      onError: (error: Error) => {
        Toast.show({
          type: 'error',
          text1: t('appointmentRequest:errorToast'),
          text2: error.name
        });
      }
    }
  );

  return {
    submitAdditionalInfoUpdate,
    submitInfoUpdateRequest,
    uploadPhoto,
    deletePhoto
  };
};

export default usePatientData;
