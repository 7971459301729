import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export interface UseBooleanHook {
  value: boolean;
  toTrue: () => void;
  toFalse: () => void;
  setValue: Dispatch<SetStateAction<boolean>>;
  toggle: () => void;
}
const useBoolean = (initial = false): UseBooleanHook => {
  const [value, setValue] = useState(initial);

  const toTrue = useCallback((): void => setValue(true), []);
  const toFalse = useCallback((): void => setValue(false), []);
  const toggle = useCallback((): void => setValue((prev) => !prev), []);

  return {
    value,
    toTrue,
    toFalse,
    setValue,
    toggle
  };
};

export default useBoolean;
