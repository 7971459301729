import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { Subheading } from 'src/components';
import PatientRowItem from 'src/components/PatientRowItem';
import { IS_WEB, Margin } from 'src/constants';
import { useViewMode } from 'src/hooks';
import usePaginatedReminders from 'src/hooks/react-query/usePaginatedReminders';
import useFetchNextPage from 'src/hooks/useFetchNextPage';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useSelectedPatient } from 'src/providers/SelectedPatientProvider';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

const FilteredReminders: React.FC = () => {
  const { t } = useTranslation('patientProfile');
  const { windowHeight, horizontalInset } = useViewMode();
  const { colors } = useAppTheme();

  const { selectedPatient } = useSelectedPatient();

  const {
    data: reminders,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = usePaginatedReminders({
    selectedPatientId: selectedPatient?.patientId,
    suspense: false
  });

  const { setToggle } = useFetchNextPage(hasNextPage, isFetched, fetchNextPage);

  if (!isRefetching && isFetched && !reminders.length) {
    return (
      <View style={[layout.flex1, layout.alignItemsCenter, layout.justifyCenter]}>
        <Subheading>{t('noReminders')}</Subheading>
      </View>
    );
  }

  return (
    <FlatList
      style={{ maxHeight: IS_WEB ? windowHeight - 100 : undefined }}
      contentContainerStyle={{ marginHorizontal: horizontalInset }}
      data={reminders}
      refreshControl={
        <RefreshControl refreshing={isRefetching} onRefresh={refetch} tintColor={colors.primary} />
      }
      ListEmptyComponent={<ActivityIndicator size='large' style={layout.flex1} />}
      renderItem={({ item }) => (
        <PatientRowItem
          key={item.reminderId}
          patientId={item.patientId}
          date={item.pastDueDate}
          overdue={moment(item.pastDueDate).isBefore(moment(), 'days')}
          sourceId={item.reminderId}
          description={item.description}
          dateLabel={t('dueDate')}
        />
      )}
      onEndReached={() => setToggle((prev) => !prev)}
      ListFooterComponent={
        <ListFooterContainer>
          {isFetchingNextPage && <ActivityIndicator />}
          {!isLoading && !hasNextPage && !isFetchingNextPage && (
            <Subheading textAlign='center' color={colors.onSurface}>
              {selectedPatient
                ? t('noRemindersFor', { petName: selectedPatient?.name })
                : t('noMoreReminders')}
            </Subheading>
          )}
        </ListFooterContainer>
      }
    />
  );
};

export default FilteredReminders;

const ListFooterContainer = styled.View`
  margin: ${Margin.Large}px;
`;
