import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Paw: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={props.strokeWidth ?? 1.5}
      d='M3.563 12c0 .37.043.735.128 1.076.085.341.21.652.366.913.156.26.342.468.547.61.205.14.424.213.646.213.222 0 .441-.072.646-.214.205-.14.39-.348.547-.61a3.35 3.35 0 0 0 .366-.912A4.49 4.49 0 0 0 6.937 12c0-.37-.043-.735-.128-1.076a3.35 3.35 0 0 0-.366-.913 1.949 1.949 0 0 0-.547-.61 1.131 1.131 0 0 0-.646-.213c-.222 0-.441.072-.646.214-.205.14-.39.348-.547.61a3.35 3.35 0 0 0-.366.912A4.49 4.49 0 0 0 3.563 12ZM7.5 6.375c0 .37.044.735.128 1.076.085.342.21.652.366.913.157.26.343.468.548.61.204.14.424.213.646.213.221 0 .44-.072.645-.214a1.95 1.95 0 0 0 .548-.61 3.35 3.35 0 0 0 .365-.912c.085-.34.129-.707.129-1.076 0-.37-.044-.735-.129-1.076a3.35 3.35 0 0 0-.365-.913 1.95 1.95 0 0 0-.548-.61 1.131 1.131 0 0 0-.646-.213c-.221 0-.44.072-.645.214a1.95 1.95 0 0 0-.548.61 3.351 3.351 0 0 0-.366.912A4.49 4.49 0 0 0 7.5 6.375ZM17.063 11.438c0 .745.177 1.46.494 1.988.316.528.745.824 1.193.824.448 0 .877-.296 1.193-.824.317-.527.494-1.243.494-1.989 0-.745-.177-1.46-.494-1.988-.316-.528-.745-.824-1.193-.824-.448 0-.877.296-1.193.824-.317.527-.494 1.243-.494 1.989ZM13.125 6.375c0 .746.178 1.461.494 1.989.317.527.746.823 1.194.823.447 0 .876-.296 1.193-.823.316-.528.494-1.243.494-1.989s-.178-1.461-.494-1.989c-.317-.527-.746-.824-1.194-.824-.447 0-.876.297-1.193.824-.316.528-.494 1.243-.494 1.989ZM15.243 14.74a1.13 1.13 0 0 1-.434-.943v-.11a2.812 2.812 0 1 0-5.624 0v.11a1.125 1.125 0 0 1-.434.943A2.973 2.973 0 0 0 7.5 17.062c0 1.864 2.014 3.375 4.5 3.375s4.5-1.51 4.5-3.375a2.973 2.973 0 0 0-1.257-2.322Z'
    />
  </Svg>
);
export default Paw;
