import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from '../IconProps';

const Bird: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 36 36' fill='none'>
      <Path
        d='M25.5 21C25.14 20.64 24.84 20.25 24.525 19.875C26.25 17.25 28.5 12.84 28.5 7.5C28.5 4.575 27.39 3 25.5 3C23.19 3 19.56 6.09 18 11.955C16.44 6.09 12.81 3 10.5 3C8.61 3 7.5 4.575 7.5 7.5C7.5 12.84 9.75 17.25 11.475 19.875C11.16 20.25 10.86 20.64 10.5 21C10.125 21.375 7.5 23.085 7.5 26.25C7.5 30 10.5 33 14.25 33C16.5 33 18 32.25 18 32.25C18 32.25 19.5 33 21.75 33C25.5 33 28.5 30 28.5 26.25C28.5 23.085 25.875 21.375 25.5 21ZM25.32 6.045C25.41 6.3 25.5 6.75 25.5 7.5C25.5 11.76 23.835 15.36 22.395 17.67C21.825 17.25 21.15 16.95 20.295 16.74C20.655 9.96 23.955 6.495 25.32 6.045ZM10.5 7.5C10.5 6.75 10.59 6.3 10.68 6.045C12.045 6.495 15.345 9.96 15.75 16.74C14.85 16.95 14.175 17.25 13.62 17.67C12.165 15.36 10.5 11.76 10.5 7.5ZM21.75 30C20.25 30 19.05 29.505 18.42 29.16C19.05 28.89 19.5 28.095 19.5 27.75C19.5 27.33 18.825 27 18 27C17.175 27 16.5 27.33 16.5 27.75C16.5 28.095 16.95 28.89 17.58 29.16C16.95 29.505 15.75 30 14.25 30C13.2554 30 12.3016 29.6049 11.5983 28.9017C10.8951 28.1984 10.5 27.2446 10.5 26.25C10.5 25.2 11.145 24.39 12 23.655C12.66 23.115 12.915 22.875 13.95 21.6C15.09 20.175 15.585 19.5 18 19.5C20.415 19.5 20.91 20.175 22.05 21.6C23.085 22.875 23.34 23.115 24 23.655C24.855 24.39 25.5 25.2 25.5 26.25C25.5 27.2446 25.1049 28.1984 24.4017 28.9017C23.6984 29.6049 22.7446 30 21.75 30ZM21 24C21 24.615 20.67 25.125 20.25 25.125C19.83 25.125 19.5 24.615 19.5 24C19.5 23.385 19.83 22.875 20.25 22.875C20.67 22.875 21 23.385 21 24ZM16.5 24C16.5 24.615 16.17 25.125 15.75 25.125C15.33 25.125 15 24.615 15 24C15 23.385 15.33 22.875 15.75 22.875C16.17 22.875 16.5 23.385 16.5 24Z'
        fill={color}
      />
    </Svg>
  );
};

export default Bird;
