import { BiometricsPromptContextType } from './types';

const defaultContext: BiometricsPromptContextType = {
  needsPrompt: false,
  setNeedsPrompt: () => {},
  setBiometricAuthAppSetting: () => {},
  hasCredentials: false,
  biometricsAuthAppSetting: undefined
};

export default defaultContext;
