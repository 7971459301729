import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import Alert, { AlertHandle, AlertOption } from './Alert';

interface Props {
  isDirty: boolean;
}

const DiscardChangesModal: React.FC<Props> = ({ isDirty }) => {
  const { t } = useTranslation('discardChanges');
  const { dispatch, addListener } = useNavigation();

  const discardCallback = useRef<() => void>();
  useEffect(
    () =>
      addListener('beforeRemove', (e) => {
        if (!isDirty) {
          return;
        }
        e.preventDefault();
        discardCallback.current = () => {
          dispatch(e.data.action);
          discardCallback.current = undefined;
        };
        confirmModalRef.current?.alert();
      }),
    [addListener, isDirty, dispatch]
  );

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        action: () => discardCallback.current?.(),
        label: t('goBack'),
        type: 'neutral'
      },

      {
        label: t('keepEditing')
      }
    ],
    [t]
  );

  return (
    <Alert ref={confirmModalRef} title={t('title')} body={t('body')} options={confirmOptions} />
  );
};

export default DiscardChangesModal;
