import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AsyncStorageKeys } from 'src/api';
import { isDefined, storage } from 'src/utils';

/**
 * Hook used to persist data in local/async storage. If used consistently, allows components to always have the most recent version of the stored data.
 *
 * @param key AsyncStorageKey. Required
 * @param placeholderData data use before the requested data has been pulled from storage. Optional.
 * @returns [data, setData]
 */
function useStorage<T = string>(
  key: AsyncStorageKeys | [AsyncStorageKeys, ...Array<string | number>]
): [T | undefined, (value: T | undefined) => void, boolean];
function useStorage<T = string>(
  key: AsyncStorageKeys | [AsyncStorageKeys, ...Array<string | number>],
  placeholderData: T
): [T, (value: T | undefined) => void, boolean];
function useStorage<T = string>(
  key: AsyncStorageKeys | [AsyncStorageKeys, ...Array<string | number>],
  placeholderData?: T
): [T | undefined, (value: T | undefined) => void, boolean] {
  const storageKey = useMemo(() => (Array.isArray(key) ? key.join(',') : key), [key]);

  const { data, remove, isFetched } = useQuery<T | undefined>({
    queryKey: [key, 'AsyncStorage', 'read'],
    queryFn: async () => await storage.getTypedItem(storageKey)
  });

  const { mutate } = useMutation({
    mutationKey: [key, 'AsyncStorage', 'write'],
    mutationFn: async (value: T | undefined) => {
      if (isDefined(value)) {
        await storage.setTypedItem(storageKey, value);
      } else {
        await storage.removeItem(storageKey);
      }
    },
    onSuccess: () => {
      remove();
    }
  });

  return [data ?? placeholderData, mutate, isFetched];
}

export default useStorage;
