import React from 'react';
import { ChevronDown, Slash } from 'react-native-feather';
import { Menu } from 'react-native-paper';
import styled from 'styled-components/native';
import { Body, HoverCard } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import { IconSize, Margin } from 'src/constants';
import { useBoolean } from 'src/hooks';
import { palette, Weight } from 'src/theme';
import { useSelectedPatient } from '../providers/SelectedPatientProvider';
import { Patient } from 'src/interfaces';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  typeName: string;
}

const PatientSelector: React.FC<Props> = ({ typeName }) => {
  const { selectedPatient, setSelectedPatient, patients } = useSelectedPatient();
  const { colors } = useAppTheme();
  const { toTrue: openMenu, toFalse: closeMenu, value: menuOpen } = useBoolean();
  const { t } = useTranslation();

  const selectAndClose = (p?: Patient) => {
    setSelectedPatient(p);
    closeMenu();
  };

  return (
    <SelectorCta onPress={openMenu}>
      <Body color={colors.onPrimary}>{t('viewingFor', { typeName })}</Body>
      <Menu
        visible={menuOpen}
        onDismiss={closeMenu}
        anchor={
          <Body color={colors.onPrimary} fontWeight={Weight.BOLD} numberOfLines={1}>
            {selectedPatient?.name ?? t('all')}
          </Body>
        }
      >
        {!!selectedPatient && (
          <PatientOption onPress={() => selectAndClose()}>
            <StyledSlash color={colors.opaquePlaceholder} />
            <Body>{t('clearSelection')}</Body>
          </PatientOption>
        )}
        {patients?.map((p) => (
          <PatientOption
            key={`patient-${p.patientId}`}
            onPress={() => selectAndClose(p)}
            backgroundColor={
              p.patientId === selectedPatient?.patientId ? colors.primary : palette.TRANSPARENT
            }
          >
            <StyledPatientAvatar patient={p} size={IconSize.XS} />
            <Body
              color={p.patientId === selectedPatient?.patientId ? colors.background : colors.text}
            >
              {p.name ?? p.patientId}
            </Body>
          </PatientOption>
        ))}
      </Menu>
      <ChevronDown color={colors.onPrimary} />
    </SelectorCta>
  );
};

export default PatientSelector;

const SelectorCta = styled(HoverCard).attrs({
  hideShadow: true,
  hoverColor: palette.BLACK_OPACITY_20
})`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: ${palette.TRANSPARENT};
  padding-horizontal: ${Margin.Medium}px;
`;

const StyledSlash = styled(Slash).attrs({
  width: IconSize.XS
})`
  margin-right: ${Margin.Medium}px;
`;

const StyledPatientAvatar = styled(PatientAvatar)`
  margin-right: ${Margin.Medium}px;
`;
const PatientOption = styled.Pressable<{ backgroundColor?: string }>`
  ${({ backgroundColor }) =>
    `
  flex-direction: row;
  align-items: center;
  padding-vertical: ${Margin.Medium}px;
  padding-left: ${Margin.Large}px;
  padding-right: ${Margin.ExtraLarge}px;
  background-color: ${backgroundColor ?? palette.TRANSPARENT};
  `}
`;
