import { StackNavigationOptions } from '@react-navigation/stack';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { fontStyles } from 'src/theme/globalStyles';

const useDefaultStackOptions = (): StackNavigationOptions => {
  const { colors, viewMode } = useAppTheme();

  return {
    headerShown: true,
    headerTitleAlign: 'center',
    headerTitleStyle: fontStyles.headline,
    headerStyle: {
      backgroundColor: colors.primary
    },
    headerRightContainerStyle: {
      paddingRight: viewMode.horizontalInset
    },
    headerLeftContainerStyle: {
      paddingLeft: viewMode.horizontalInset
    },
    headerLeftLabelVisible: false,
    headerTintColor: colors.onPrimary,
    headerShadowVisible: false,
    cardStyle: {
      backgroundColor: colors.background
    }
  };
};

export default useDefaultStackOptions;
