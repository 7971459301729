import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from 'src/api';
import { endpoint } from 'src/constants';
import { PracticeTheme } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';
import { apiClient } from 'src/utils/axios';

type Options = Omit<
  UseQueryOptions<
    PracticeTheme | undefined,
    unknown,
    PracticeTheme | undefined,
    Array<number | QueryKeys | undefined>
  >,
  'queryFn' | 'queryKey'
>;

const usePracticeTheme = (practiceId?: Practice['id'], options?: Options) => {
  return useQuery({
    queryKey: [QueryKeys.CUSTOM_THEME, practiceId],
    queryFn: async () => {
      if (!practiceId) {
        return;
      }
      return (
        await apiClient.get<PracticeTheme>(
          endpoint('CUSTOM_THEME', {
            practiceId
          }),
          {
            params: {
              mobile: true
            }
          }
        )
      ).data;
    },
    ...options
  });
};

export default usePracticeTheme;
