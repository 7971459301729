import { useRef, useState, useEffect } from 'react';
import { Animated, Easing } from 'react-native';
import { IS_WEB } from 'src/constants';
import { useBoolean, useHoverEffect } from 'src/hooks';
import { colorShade, transparent } from 'src/utils';
import { ImageUpload } from './model';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const useFileItemAnimation = (item: ImageUpload, progress: number) => {
  const animatedProgress = useRef(new Animated.Value(0)).current;
  const [width, setWidth] = useState(0);
  const { value: hidden, toTrue: hide } = useBoolean();
  const [ref, hoverEffect] = useHoverEffect();
  const { colors } = useAppTheme();
  const buttonColor = hoverEffect.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.error, colorShade(colors.error, 0.8)]
  });

  const backgroundColor = hoverEffect.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.disabled, transparent(colorShade(colors.error, 0.8), 0.5)]
  });

  const opacity = hoverEffect.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.5]
  });

  useEffect(() => {
    const listener = animatedProgress.addListener(({ value }) => {
      setWidth(value);
    });
    return () => animatedProgress.removeListener(listener);
  }, [animatedProgress]);

  useEffect(() => {
    const _progress = progress ?? 0;
    const toValue = item.id ? _progress : _progress / 1.1;
    const animation = Animated.timing(animatedProgress, {
      toValue,
      useNativeDriver: !IS_WEB,
      easing: Easing.ease,
      duration: 500
    });
    animation.start(({ finished }) => {
      if (finished && toValue >= 1) {
        hide();
      }
    });
    return animation.stop;
  }, [animatedProgress, hide, item.id, progress]);

  return { backgroundColor, opacity, buttonColor, width, hidden, ref };
};

export default useFileItemAnimation;
