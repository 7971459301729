import { AxiosRequestConfig, GenericAbortSignal } from 'axios';
import { endpoint, ENDPOINTS } from 'src/constants';
import { Client } from 'src/interfaces/api';
import { AvailablePractice } from 'src/interfaces/api/AvailablePractices';
import { hasPhoto, isDefinedOrThrowServerError } from 'src/utils';
import { apiClient } from 'src/utils/axios';
import createImageFormData, { PictureType } from 'src/utils/createImageFormData';

export const getClient = async (): Promise<Client> => {
  const response = await apiClient.get<Client>(ENDPOINTS.CLIENTS);
  const user = isDefinedOrThrowServerError(response.data);
  return { ...user, hasPhoto: hasPhoto(user) };
};

export const postClientPhoto = async (
  sourceId: number,
  clientId: string,
  uri: string
): Promise<Client> => {
  const config: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  const formData = await createImageFormData(uri, PictureType.CLIENT);

  const response = await apiClient.post(
    endpoint('POST_CLIENT_PHOTO', { sourceId, clientId }),
    formData,
    config
  );

  const user = isDefinedOrThrowServerError(response.data);
  return { ...user, hasPhoto: hasPhoto(user) };
};

export const deleteClientPhoto = async (sourceId: number, clientId: string): Promise<void> => {
  await apiClient.delete<undefined>(endpoint('CLIENT_PHOTO_DELETE', { sourceId, clientId }));
};

interface GetClientListRQ {
  email: string;
  signal?: GenericAbortSignal;
}
export const getActiveClientList = async ({
  email,
  signal
}: GetClientListRQ): Promise<AvailablePractice[]> => {
  const response = await apiClient.get<AvailablePractice[]>(endpoint('ACTIVE_CLIENT_LIST'), {
    params: {
      email
    },
    signal
  });
  return isDefinedOrThrowServerError(response.data);
};
