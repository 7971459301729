import React, { useCallback } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { MOBILE_WIDTH_MAX, PATIENT_STATUSES, Size } from 'src/constants';
import { useViewMode } from 'src/hooks';
import PatientCard from './PatientCard';
import { FlatList, RefreshControl } from 'react-native';
import usePaginatedPatients from 'src/hooks/react-query/usePaginatedPatients';
import styled from 'styled-components/native';
import PatientIconMessage from './PatientIconMessage';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { GuideElement, STEPS } from './tour';
import { Screens } from 'src/routes/stacks/screens';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';

interface Props {
  patientStatus?: PATIENT_STATUSES;
  isFromRequestWizard?: boolean;
}

const PatientListContent: React.FC<Props> = ({ patientStatus, isFromRequestWizard }) => {
  const { t } = useTranslation('petList');
  const { colors } = useAppTheme();
  const { horizontalInset, isMobile } = useViewMode();

  const { navigate } = useNavigation<NavigationProp<MainStackParamsList>>();

  const {
    data: patientList = [],
    isFetched,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = usePaginatedPatients({
    status: patientStatus,
    suspense: false
  });

  const tourFirstPatientProfile = useCallback(() => {
    const firstActivePatient = patientList.find((p) => p.status === PATIENT_STATUSES.ACTIVE);
    if (firstActivePatient) {
      navigate(Screens.PATIENT_PROFILE, { id: firstActivePatient?.patientId });
    }
  }, [navigate, patientList]);

  const numCols = (patientList?.length ?? 0) > 1 ? 2 : 1;
  const shouldNarrowDisplay = !isMobile && numCols === 1;

  return (
    <FlatList
      key={numCols}
      data={patientList}
      refreshing={isRefetching}
      refreshControl={
        <RefreshControl refreshing={isRefetching} onRefresh={refetch} tintColor={colors.primary} />
      }
      ListEmptyComponent={
        isFetched ? (
          <PatientIconMessage message={t('noPetsFound')} />
        ) : (
          <ActivityIndicator size='large' color={colors.primary} style={{ flex: 1 }} />
        )
      }
      contentContainerStyle={[
        {
          marginHorizontal: horizontalInset,
          flexGrow: 1
        },
        shouldNarrowDisplay && {
          width: MOBILE_WIDTH_MAX,
          alignSelf: 'center'
        }
      ]}
      numColumns={numCols}
      renderItem={({ item, index }) => (
        <GuideElement
          body={t('petList:tour.view')}
          id={STEPS.VIEW}
          autoStart
          disabled={index !== 0}
          onContinue={() => tourFirstPatientProfile()}
        >
          <PatientCard
            key={item.patientId}
            patientId={item.patientId}
            numCols={numCols}
            isFromRequestWizard={isFromRequestWizard}
          />
        </GuideElement>
      )}
      onEndReached={() => {
        if (hasNextPage && isFetched) {
          void fetchNextPage();
        }
      }}
      ListFooterComponent={
        <>
          {isFetchingNextPage && <LoadingNextIndicator />}
          {!hasNextPage && !isFetchingNextPage && !!patientList.length && (
            <PatientIconMessage message={t('noMorePets')} />
          )}
        </>
      }
    />
  );
};

export default PatientListContent;

const LoadingNextIndicator = styled(ActivityIndicator)`
  margin: ${Size.M}px;
  margin-bottom: 200px;
`;
