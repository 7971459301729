import React from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

import ChangePassword from './ChangePassword';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useIsMutating } from 'react-query';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const ChangePasswordModal: React.FC = () => {
  const { colors } = useAppTheme();

  const isMutating = useIsMutating();

  return (
    <Container>
      <ChangePassword />
      {isMutating > 0 && (
        <ActivityIndicator
          style={layout.activityIndicator}
          animating={isMutating > 0}
          size='large'
          color={colors.primary}
        />
      )}
    </Container>
  );
};

export default ChangePasswordModal;

const Container = styled(SafeAreaView)`
  flex: 1;
  margin-horizontal: ${({ theme }) => theme.viewMode.horizontalInset}px;
`;
