import React from 'react';
import { LayoutAnimation, Pressable } from 'react-native';
import { ChevronDown, ChevronUp } from 'react-native-feather';
import { Subheading } from 'src/components';
import { IS_IOS, Margin, Size } from 'src/constants';
import { useBoolean } from 'src/hooks';
import EmergencyContact from 'src/interfaces/api/EmergencyContact';
import { containers } from 'src/theme/globalStyles';
import styled from 'styled-components/native';
import AfterHoursContactCard from './AfterHoursContactCard';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  practice: EmergencyContact;
}

const CollapsibleSection: React.FC<Props> = ({ practice }) => {
  const { value: isOpen, toggle } = useBoolean(false);
  const { colors } = useAppTheme();

  return (
    <Container style={containers.shadow}>
      <Pressable
        onPress={() => {
          if (IS_IOS) LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          toggle();
        }}
      >
        <CollapsibleSectionHeader>
          <Subheading color={colors.onSurface}>{practice.name}</Subheading>
          {!isOpen ? (
            <ChevronDown color={colors.onSurface} />
          ) : (
            <ChevronUp color={colors.onSurface} />
          )}
        </CollapsibleSectionHeader>
      </Pressable>

      {isOpen && <AfterHoursContactCard practice={practice} />}
    </Container>
  );
};

export default CollapsibleSection;

const Container = styled.View`
  margin-horizontal: ${Margin.Large}px;
  margin-bottom: ${Margin.ExtraLarge}px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.roundness}px;
`;

const CollapsibleSectionHeader = styled.View`
  padding: ${Size.S}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
