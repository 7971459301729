import React from 'react';
import { Shimmer } from 'src/components';
import {
  DataContainer,
  CaptionContainer
} from 'src/scenes/Appointments/AppointmentsRoot/components';
import { StatusContainer, Container } from './style';
import { fontStyles } from 'src/theme/globalStyles';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { ICON_SIZE } from '../constants';

const ARBITRARY_STATUS_WIDTH = 50;
const SuspenseRequestItem: React.FC = () => {
  const { roundness } = useAppTheme();
  return (
    <Container>
      <Shimmer width={ICON_SIZE} height={ICON_SIZE} borderRadius={roundness} />
      <DataContainer>
        <CaptionContainer>
          <Shimmer height={fontStyles.body.lineHeight} isLoading width={'50%'} />
        </CaptionContainer>
        <CaptionContainer>
          <Shimmer isLoading height={fontStyles.caption.lineHeight} width={'30%'} />
        </CaptionContainer>
        <CaptionContainer>
          <Shimmer isLoading height={fontStyles.caption.lineHeight} width={'20%'} />
        </CaptionContainer>
      </DataContainer>
      <StatusContainer>
        <Shimmer isLoading height={fontStyles.caption.lineHeight} width={ARBITRARY_STATUS_WIDTH} />
      </StatusContainer>
    </Container>
  );
};
export default SuspenseRequestItem;
