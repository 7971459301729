import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, TouchableRipple } from 'react-native-paper';
import { Caption, HoverButton, MiniHeroIcon, Shimmer } from 'src/components';
import { IconSize, Margin, Size } from 'src/constants';
import { usePracticeConfiguration } from 'src/hooks';
import useRequestSetting from 'src/hooks/react-query/useRequestSetting';
import styled from 'styled-components/native';
import { quickLinks } from '../generateDisplayData';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { colorShade, transparent } from 'src/utils';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { QueryKeys, getEmergencyContacts } from 'src/api';
import { useQuery } from 'react-query';

const QuickAccess: React.FC = () => {
  const { t } = useTranslation('home');
  const navigation = useNavigation<NavigationProp<HomeStackParamsList>>();
  const requestSettings = useRequestSetting();
  const { colors, dark } = useAppTheme();
  const practiceConfiguration = usePracticeConfiguration();

  const emergencyContacts = useQuery([QueryKeys.EMERGENCY_CONTACTS], {
    queryFn: getEmergencyContacts
  });

  const quickLinkData = useMemo(
    () =>
      quickLinks({
        navigation,
        practiceConfiguration,
        requestSettings,
        emergencyContacts
      }),
    [emergencyContacts, navigation, practiceConfiguration, requestSettings]
  );

  if (quickLinkData.length === 0) {
    return null;
  }

  if (quickLinkData.length === 1) {
    return (
      <QuickLinksHolder>
        {quickLinkData.map(({ title, isDisabled, isLoading, onPress, child: icon }) => (
          <SingleContainer
            icon={(props) =>
              isLoading ? (
                <ActivityIndicator
                  style={{ width: IconSize.S, height: IconSize.S }}
                  color={props.color as string}
                />
              ) : (
                <MiniHeroIcon color={props.color} icon={icon} />
              )
            }
            key={title}
            onPress={onPress}
            disabled={isDisabled}
          >
            {t(title)}
          </SingleContainer>
        ))}
      </QuickLinksHolder>
    );
  }

  return (
    <QuickLinksHolder>
      {quickLinkData.map(({ title, isDisabled, isLoading, onPress, child: Child }) => (
        <LinkContainer key={title}>
          <Container disabled={isDisabled || isLoading} onPress={onPress}>
            <>
              <OpaqueContainer>
                <IconContainer>
                  {isLoading ? (
                    <ActivityIndicator
                      color={dark ? colors.primary : colorShade(colors.primary, 0.8)}
                      style={{ width: IconSize.S, height: IconSize.S }}
                    />
                  ) : (
                    <InnerContainer>
                      <Child
                        color={dark ? colors.primary : colorShade(colors.primary, 0.8)}
                        width={IconSize.S}
                        height={IconSize.S}
                      />
                    </InnerContainer>
                  )}
                </IconContainer>
              </OpaqueContainer>

              <Shimmer isLoading={isLoading}>
                <Label>{t(title)}</Label>
              </Shimmer>
            </>
          </Container>
        </LinkContainer>
      ))}
    </QuickLinksHolder>
  );
};

export default QuickAccess;

const InnerContainer = styled.View`
  width: ${IconSize.S}px;
  height: ${IconSize.S}px;
  align-items: center;
`;

const Label = styled(Caption)`
  margin-top: ${Margin.Medium}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

const QuickLinksHolder = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const IconContainer = styled.View`
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.2)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.XS}px;
`;

const OpaqueContainer = styled.View`
  border-radius: ${IconSize.L}px;
`;

const Container = styled(TouchableRipple)`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const LinkContainer = styled.View`
  flex: 0.25;
`;

const SingleContainer = styled(HoverButton).attrs(({ theme, ...rest }) => ({
  ...rest,
  mode: 'contained',
  buttonColor: transparent(theme.colors.primary, 0.2),
  labelStyle: {
    color: colorShade(theme.colors.primary, 0.8)
  },
  uppercase: false
}))`
  flex: 1;
`;
