import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, TouchableRipple } from 'react-native-paper';
import { usePatient, usePracticeConfiguration, useViewMode } from 'src/hooks';
import { Screens } from 'src/routes/stacks/screens';
import * as Sharing from 'expo-sharing';
import { captureRef } from 'react-native-view-shot';
import {
  IS_ANDROID,
  IS_IOS,
  IS_WEB,
  Margin,
  PHONE_MASK,
  Size,
  TABLET_WIDTH_PORTRAIT_MIN
} from 'src/constants';
import { useNavigation } from '@react-navigation/native';
import { Printer, Share, X } from 'react-native-feather';
import { View, Share as RNShare, ScrollView } from 'react-native';
import { cleanUrl } from 'src/utils';
import { fontStyles, layout as globalLayout } from 'src/theme/globalStyles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { defaultLightTheme, palette, Weight } from 'src/theme';
import {
  FieldValueContainer,
  ImageContainer,
  LogoContainer,
  PageHeader,
  PatientImage,
  PracticeLogo,
  SectionOne,
  SectionTwo
} from './style';
import { useUser } from 'src/providers/ClientProvider';
import { Body, Caption, Subheading, Title } from 'src/components';
import moment from 'moment-timezone';
import { mask } from 'react-native-mask-text';
import MedicalReminders from './MedicalReminders';
import Toast from 'react-native-toast-message';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import * as FileSystem from 'expo-file-system';
import CurrentPrescriptions from './CurrentPrescriptions';
import { AppColors } from 'src/types/paper';
import { Context, ThemeContext, defaultContext } from 'src/providers/AppThemeProvider';
import rateUs from 'src/utils/rateUs';

const MIN_SHEET_HEIGHT = (TABLET_WIDTH_PORTRAIT_MIN * 11) / 8;

type Props = NativeStackScreenProps<MainStackParamsList, Screens.PATIENT_ID>;
const PatientID: React.FC<Props> = ({ route, navigation }) => {
  const { t, i18n } = useTranslation();
  const viewMode = useViewMode();
  const colors: AppColors = useMemo(
    () => ({
      ...defaultLightTheme.colors,
      background: palette.AB_BRIGHT_WHITE,
      text: palette.BLACK
    }),
    []
  );
  const { setOptions, goBack } = useNavigation();

  const patientId = route.params?.id;
  const { data: practiceConfiguration, isLoading: configLoading } = usePracticeConfiguration();
  const { data: patient, isFetched } = usePatient(patientId);

  const { user } = useUser();
  const fullName = `${user.firstName} ${user.lastName}`;
  const addressLine2 = `${user.city}, ${user.state} ${user.postalCode}`;

  const [isPrintingWeb, SetIsPrintingWeb] = useState<boolean>(false);

  const contentRef = useRef<View>(null);

  useEffect(() => {
    if (!patientId || (isFetched && !patient)) {
      Toast.show({
        text1: t('petNotFound', { ns: 'petList' }),
        type: 'error'
      });
      const timeout = setTimeout(navigation.goBack, 500);
      return () => clearTimeout(timeout);
    }
  }, [patientId, patient, navigation, isFetched, t]);

  const onPressSendPrint = useCallback(async () => {
    if (patient && practiceConfiguration && contentRef.current) {
      if (IS_WEB) {
        SetIsPrintingWeb(true);
        const promise = new Promise<void>((resolve) => {
          setTimeout(() => {
            window.print();
            resolve();
          }, 100);
        });
        await promise;
        SetIsPrintingWeb(false);
      } else if ((await Sharing.isAvailableAsync()) && !!FileSystem.cacheDirectory) {
        try {
          const fileName = `${patient.name} Pet ID Card ${moment().format(
            'YYYY-MM-DD'
          )}.jpg`.replace(/\s/g, '_');
          const filePath = `${FileSystem.cacheDirectory}/${fileName}`;
          const image: string = await captureRef(contentRef, {
            result: 'base64',
            fileName
          });
          await FileSystem.writeAsStringAsync(filePath, image, { encoding: 'base64' });
          if (IS_IOS) {
            await RNShare.share({
              url: filePath
            });
          } else if (IS_ANDROID) {
            await Sharing.shareAsync(filePath);
          }
          void rateUs();
        } catch {
          Toast.show({
            text1: `Something went wrong`
          });
        }
      }
    }
  }, [patient, practiceConfiguration]);

  useEffect(() => {
    setOptions({
      cardStyle: {
        backgroundColor: colors.background
      },
      headerStyle: {
        backgroundColor: colors.background
      },
      headerTitleStyle: {
        color: colors.text,
        ...fontStyles.title
      },
      headerTitle: !isPrintingWeb ? t('title', { ns: 'petID' }) : '',
      headerLeft: !isPrintingWeb
        ? () => (
            <TouchableRipple onPress={goBack} style={{ marginLeft: Margin.Medium }}>
              <X width={Size.M} color={colors.text} />
            </TouchableRipple>
          )
        : null,
      headerRight: !isPrintingWeb
        ? () => (
            <TouchableRipple onPress={onPressSendPrint} style={{ marginRight: Margin.Medium }}>
              {IS_WEB ? (
                <Printer width={Size.M} color={colors.text} />
              ) : (
                <Share width={Size.M} color={colors.text} />
              )}
            </TouchableRipple>
          )
        : null
    });
  }, [goBack, setOptions, colors, onPressSendPrint, isPrintingWeb, t]);

  const [contentLayout, setContentLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const { scale, translateY } = useMemo(() => {
    const _scale = viewMode.windowWidth / TABLET_WIDTH_PORTRAIT_MIN;
    const lostHeight = (1 - _scale) * contentLayout.height;
    const _translateY = -lostHeight / 2;
    return { scale: _scale, translateY: _translateY };
  }, [contentLayout, viewMode]);

  const { bottom } = useSafeAreaInsets();

  const themeContext: Context = useMemo(
    () => ({ ...defaultContext, ...defaultLightTheme, viewMode }),
    [viewMode]
  );

  const contentWidth = useMemo(() => {
    return !IS_WEB ? TABLET_WIDTH_PORTRAIT_MIN : TABLET_WIDTH_PORTRAIT_MIN - Size.M;
  }, []);

  if (!patient || !practiceConfiguration || !isFetched || configLoading || !patientId) {
    return <ActivityIndicator size='large' style={{ flex: 1 }} />;
  }
  return (
    <ThemeContext.Provider value={themeContext}>
      <ScrollView
        style={{ marginBottom: bottom }}
        contentContainerStyle={{ height: contentLayout.height * scale }}
        scrollEnabled={!isPrintingWeb}
      >
        <View
          style={[
            {
              backgroundColor: palette.AB_BRIGHT_WHITE,
              width: contentWidth,
              alignSelf: 'center',
              padding: Size.X2_S,
              flexGrow: 1
            },
            scale < 1 && {
              transform: [{ translateY }, { scale }],
              minHeight: MIN_SHEET_HEIGHT
            }
          ]}
          onLayout={({ nativeEvent: { layout } }) => {
            setContentLayout(layout);
          }}
          ref={contentRef}
        >
          <PageHeader>
            <LogoContainer>
              <PracticeLogo
                resizeMode='contain'
                source={{
                  uri: cleanUrl(practiceConfiguration.petRecordsLogoImage?.urls?.original)
                }}
              />
            </LogoContainer>
            <View style={[globalLayout.alignItemsCenter]}>
              <Title>{t('header', { ns: 'petID' })}</Title>
              <Body>{t('generated', { ns: 'petID' })}</Body>
              <Body>{moment().locale(i18n.language).format('on M/DD/YYYY')}</Body>
            </View>
            <View style={globalLayout.flex1}>
              <View style={globalLayout.alignSelfEnd}>
                <Body textAlign='right'>
                  {!!practiceConfiguration?.practice?.name && practiceConfiguration.practice.name}
                </Body>
                <Body textAlign='right'>
                  {!!practiceConfiguration?.practice?.phoneNumber &&
                    mask(practiceConfiguration.practice.phoneNumber, PHONE_MASK)}
                </Body>
                <Body textAlign='right'>
                  {!!practiceConfiguration?.practice?.email && practiceConfiguration.practice.email}
                </Body>
              </View>
            </View>
          </PageHeader>

          <SectionOne>
            <ImageContainer>
              {!patient.hasPhoto && <Caption>{t('noPhoto', { ns: 'petID' })}</Caption>}
              {patient.hasPhoto && (
                <PatientImage
                  source={{ uri: cleanUrl(patient.photo?.urls?.original) }}
                  resizeMode='cover'
                />
              )}
            </ImageContainer>
            <View style={globalLayout.justifyBetween}>
              <Body fontWeight={Weight.MEDIUM}>{t('name', { ns: 'userInfo' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('dob', { ns: 'petID' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('species', { ns: 'petID' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('breed', { ns: 'petID' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('sex', { ns: 'petID' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('weight', { ns: 'petID' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('microchipTag', { ns: 'patientProfile' })}</Body>
              <Body fontWeight={Weight.MEDIUM}>{t('rabiesTag', { ns: 'patientProfile' })}</Body>
            </View>
            <FieldValueContainer>
              <Body>{patient.name ?? t('dash', { ns: 'common' })}</Body>
              <Body>
                {moment(patient.birthdate).locale(i18n.language).format('M/DD/YYYY') ??
                  t('dash', { ns: 'common' })}
              </Body>
              <Body>{patient.species ?? t('dash', { ns: 'common' })}</Body>
              <Body>{patient.breed ?? t('dash', { ns: 'common' })}</Body>
              <Body>{patient.sex ?? t('dash', { ns: 'common' })}</Body>
              <Body>
                {patient.weight ?? t('dash', { ns: 'common' })}
                {!!patient.weight && !!patient.weightUnit && ` ${patient.weightUnit}`}
              </Body>
              <Body>
                {!patient.microchipNumber ? t('dash', { ns: 'common' }) : patient.microchipNumber}
              </Body>
              <Body>{!patient.rabiesTag ? t('dash', { ns: 'common' }) : patient.rabiesTag}</Body>
            </FieldValueContainer>
            <View>
              <Body fontWeight={Weight.MEDIUM}>{t('owner', { ns: 'petID' })}</Body>
              <Body>{fullName}</Body>
              <Body>{user.address}</Body>
              <Body>{addressLine2}</Body>
              {user.mobilePhone && <Body>{mask(user.mobilePhone, PHONE_MASK)}</Body>}
              {!user.mobilePhone && <Body>{t('dash', { ns: 'common' })}</Body>}
            </View>
          </SectionOne>
          <SectionTwo>
            <MedicalReminders patientId={patientId} />
          </SectionTwo>
          <SectionTwo>
            <CurrentPrescriptions patientId={patientId} />
          </SectionTwo>
          <SectionTwo>
            <Subheading>{t('ownerProvidedInfo', { ns: 'petID' })}</Subheading>
            <View>
              <View style={[globalLayout.flexRow, { marginTop: Size.X2_S }]}>
                <Body fontWeight={Weight.MEDIUM}>{t('insuranceHeader', { ns: 'petID' })}</Body>
                {!patient.insuranceProvider && !patient.policyNumber && (
                  <Body>{t('dash', { ns: 'common' })}</Body>
                )}
                {(!!patient.insuranceProvider || !!patient.policyNumber) && (
                  <Body>
                    {patient.insuranceProvider ?? ''}
                    {patient.insuranceProvider && patient.policyNumber ? '/' : ''}
                    {patient.policyNumber ?? ''}
                  </Body>
                )}
              </View>
              <View style={[globalLayout.flexRow, { marginTop: Size.X2_S }]}>
                <Body fontWeight={Weight.MEDIUM}>{t('medicalConditions', { ns: 'petID' })}</Body>
                <Body numberOfLines={2}>
                  {!patient.medicalConditions
                    ? t('dash', { ns: 'common' })
                    : patient.medicalConditions}
                </Body>
              </View>
              <View style={[globalLayout.flexRow, { marginTop: Size.X2_S }]}>
                <Body fontWeight={Weight.MEDIUM}>{t('diet', { ns: 'petID' })}</Body>
                <Body>{!patient.diet ? t('dash', { ns: 'common' }) : patient.diet}</Body>
              </View>
            </View>
          </SectionTwo>
        </View>
      </ScrollView>
    </ThemeContext.Provider>
  );
};

export default PatientID;
