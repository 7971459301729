import React, { useMemo } from 'react';
import { Patient } from 'src/interfaces';
import AppointmentsCard from 'src/scenes/Home/components/AppointmentsCard';
import PrescriptionsCard from 'src/scenes/Home/components/PrescriptionsCard';
import RemindersCard from 'src/scenes/Home/components/RemindersCard';
import Header from './Header';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Screens } from 'src/routes/stacks/screens';
import InfoCard from './InfoCard';
import { useTranslation } from 'react-i18next';
import { FolderPlus, Info } from 'react-native-feather';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { additionalInformation, basicInformation } from './helpers';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { GuideElement, STEPS } from 'src/scenes/PatientList/tour';
import ToDosCard from 'src/scenes/Home/components/ToDosCard';
import { useNavigation } from '@react-navigation/native';

interface Props {
  patient: Patient;
  advance: () => void;
}

const ProfileView: React.FC<Props> = ({ patient, advance }) => {
  const { t } = useTranslation('patientProfile');
  const { colors } = useAppTheme();

  const navigation =
    useNavigation<
      NativeStackNavigationProp<MainStackParamsList, Screens.PATIENT_PROFILE, undefined>
    >();

  const patientInfo = useMemo(() => {
    const basicInfo = basicInformation(patient);
    const additionalInfo = additionalInformation(patient);
    return { basicInfo, additionalInfo };
  }, [patient]);

  return (
    <>
      <Header patient={patient} advance={advance} navigation={navigation} />
      <InfoCard
        title={t('basicInfo')}
        icon={() => <Info color={colors.onSurface} />}
        items={patientInfo.basicInfo}
      />
      <InfoCard
        title={t('additionalInfo')}
        icon={() => <FolderPlus color={colors.onSurface} />}
        items={patientInfo.additionalInfo}
      />
      <GuideElement body={t('tour.appointments')} id={STEPS.APPOINTMENTS}>
        <AppointmentsCard patientId={patient.patientId} />
      </GuideElement>
      <GuideElement body={t('tour.reminders')} id={STEPS.REMINDERS}>
        <RemindersCard patientId={patient.patientId} />
      </GuideElement>
      <GuideElement body={t('tour.prescriptions')} id={STEPS.PRESCRIPTIONS}>
        <PrescriptionsCard patientId={patient.patientId} />
      </GuideElement>
      <GuideElement body={t('tour.todos')} id={STEPS.TODOS}>
        <ToDosCard patientId={patient.patientId} />
      </GuideElement>
    </>
  );
};

export default ProfileView;
