import { hasAction, requestReview } from 'expo-store-review';
import storage from './storage';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { AsyncStorageKeys } from 'src/api';
import { getFirstInstallTime } from 'react-native-device-info';

const INSTALL_DAYS_REVIEW_THRESHOLD = 30;
const DAYS_AGO_SHOWED_REVIEW_THRESHOLD = 30;

const rateUs = async () => {
  const reviewActionIsAvailable = await hasAction();
  const lastReviewRequestDate = await storage.getTypedItem<Moment>(
    AsyncStorageKeys.LAST_REVIEW_REQUEST_DATE
  );
  const lastReviewDateIsUndefinedOrPastThreshold =
    !lastReviewRequestDate ||
    moment().diff(lastReviewRequestDate, 'days') > DAYS_AGO_SHOWED_REVIEW_THRESHOLD;

  if (reviewActionIsAvailable && lastReviewDateIsUndefinedOrPastThreshold) {
    await getFirstInstallTime()
      .then(async (value) => {
        if (moment().diff(value, 'days') >= INSTALL_DAYS_REVIEW_THRESHOLD) {
          await requestReview();
          await storage.setTypedItem(AsyncStorageKeys.LAST_REVIEW_REQUEST_DATE, moment());
        }
      })
      .catch((error) => console.error(error));
  }
};

export default rateUs;
