import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Navigators } from '../navigators';
import { HomeStackNavigator } from '../HomeStackNavigator';
import { AppointmentsStackNavigator } from '../AppointmentStackNavigator';
import { LoyaltyStackNavigator } from '../LoyaltyTabNavigator';
import { useAbility } from 'src/hooks/react-query';
import { transparent } from 'src/utils';
import { Size } from 'src/constants';
import { PatientStackNavigator } from '../PatientStackNavigator';
import ToDoStackNavigator from '../ToDoStackNavigator';
import useTodos from 'src/hooks/react-query/todos/useTodos';
import { useState } from 'react';
import moment from 'moment-timezone';
import { AlertBadge } from 'src/components';
import BottomTabParamsList from './ParamsList';
import { Calendar, House, Trophy, Paw, AlarmClock } from 'src/components/kit/ABIcons';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Inbox } from 'react-native-feather';
import RequestStackNavigator from '../RequestStackNavigator';
import { BottomNavigation } from 'react-native-paper';
import { CommonActions } from '@react-navigation/native';
import RouteLabel from './RouteLabel';

const TabNavigator = createBottomTabNavigator<BottomTabParamsList>();

export const BottomTabNavigator: React.FC = () => {
  const { userCanViewLoyalty } = useAbility();
  const { colors } = useAppTheme();

  const [todoAlert, setTodoAlert] = useState(false);
  useTodos({
    isComplete: false,
    onFetchSuccess: (todos) => {
      const dueNow = todos?.some((todo) => moment(todo.dueDate).isBefore() || !todo.dueDate);
      setTodoAlert(dueNow);
    }
  });

  return (
    <TabNavigator.Navigator
      backBehavior='history'
      screenOptions={({ route }) => ({
        headerShown: false,
        cardStyle: { backgroundColor: colors.background },
        tabBarIcon: ({ color }) => {
          switch (route.name) {
            case Navigators.HOME_STACK:
              return <House width={Size.M} height={Size.M} color={color} />;
            case Navigators.APPOINTMENTS_STACK:
              return <Calendar width={Size.M} height={Size.M} color={color} />;
            case Navigators.LOYALTY_STACK:
              return <Trophy width={Size.M} height={Size.M} color={color} />;
            case Navigators.PET_STACK:
              return <Paw width={Size.M} height={Size.M} color={color} />;
            case Navigators.TODO_STACK:
              return (
                <AlertBadge hasAlert={todoAlert}>
                  <AlarmClock width={Size.M} height={Size.M} color={color} />
                </AlertBadge>
              );
            case Navigators.REQUEST_STACK:
              return <Inbox width={Size.M} height={Size.M} color={color} />;
            default:
              return null;
          }
        }
      })}
      tabBar={({ navigation, state, descriptors, insets }) => (
        <BottomNavigation.Bar
          navigationState={state}
          safeAreaInsets={insets}
          activeColor={colors.onPrimary}
          inactiveColor={transparent(colors.onPrimary, 0.5)}
          onTabPress={({ route, preventDefault }) => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true
            });

            if (event.defaultPrevented) {
              preventDefault();
            } else {
              navigation.dispatch({
                ...CommonActions.navigate(route.name, route.params),
                target: state.key
              });
            }
          }}
          renderIcon={({ route, focused, color }) => {
            const { options } = descriptors[route.key];
            if (options.tabBarIcon) {
              return options.tabBarIcon({ focused, color, size: Size.M });
            }

            return null;
          }}
          renderLabel={({ route }) => <RouteLabel label={route.name} />}
        />
      )}
    >
      <TabNavigator.Screen name={Navigators.HOME_STACK} component={HomeStackNavigator} />
      <TabNavigator.Screen name={Navigators.PET_STACK} component={PatientStackNavigator} />
      <TabNavigator.Screen
        name={Navigators.APPOINTMENTS_STACK}
        component={AppointmentsStackNavigator}
      />
      {userCanViewLoyalty && (
        <TabNavigator.Screen name={Navigators.LOYALTY_STACK} component={LoyaltyStackNavigator} />
      )}
      <TabNavigator.Screen name={Navigators.TODO_STACK} component={ToDoStackNavigator} />
      <TabNavigator.Screen name={Navigators.REQUEST_STACK} component={RequestStackNavigator} />
    </TabNavigator.Navigator>
  );
};

export default BottomTabNavigator;
