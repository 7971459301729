export interface PaginationRS<T> {
  draw: number;
  recordsTotal: number;
  recordsFiltered: number;
  data: T[];
}

export const getNextPageParam = <T>(
  lastPage: PaginationRS<T>,
  allPages: Array<PaginationRS<T>>,
  length: number
) => {
  const availablePages = Math.ceil(lastPage.recordsFiltered / length);
  if (availablePages > allPages.length) {
    return allPages.length;
  }
};
