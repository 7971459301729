import jwtDecode from 'jwt-decode';
import moment from 'moment';
import isTokenValid from './isTokenValid';
import { TokenPayload } from './type';

const MINIMUM_VALIDATION_PERIOD = 29;

const shouldRefreshToken = (token?: string): token is string => {
  try {
    const isValid = isTokenValid(token);
    if (!isValid) return false;

    const decoded = jwtDecode<TokenPayload>(token);
    const minimumDaysFromNow = moment().add(MINIMUM_VALIDATION_PERIOD, 'days');
    return moment.utc(decoded.expires_at, 'YYYY-MM-DD HH:mm:ss').isSameOrBefore(minimumDaysFromNow);
  } catch {
    return false;
  }
};

export default shouldRefreshToken;
