import { EmailVerificationStatus } from 'src/interfaces/api';

export const emailHasError = (status: string): boolean =>
  ![
    EmailVerificationStatus.VERIFIED.valueOf(),
    EmailVerificationStatus.UNKNOWN.valueOf(),
    EmailVerificationStatus.IN_PROGRESS.valueOf()
  ].includes(status);

export const emailBouncedOrMarkedSpam = (status: string): boolean =>
  [
    EmailVerificationStatus.BOUNCED.valueOf(),
    EmailVerificationStatus.SPAM_REPORT.valueOf()
  ].includes(status);

export const emailIssuesInvalid = (status: string): boolean =>
  [
    EmailVerificationStatus.BAD.valueOf(),
    EmailVerificationStatus.INVALID.valueOf(),
    EmailVerificationStatus.DECLINED.valueOf()
  ].includes(status);

export const isErroredEmail = (status: string): boolean =>
  emailIssuesInvalid(status) || emailBouncedOrMarkedSpam(status);
