import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation } from 'react-native';
import { List } from 'react-native-paper';
import { Body, Row, Subheading } from 'src/components';
import { IS_WEB, Margin, Size } from 'src/constants';
import { UseBooleanHook } from 'src/hooks/useBoolean';
import { palette } from 'src/theme';
import styled from 'styled-components/native';

interface Props {
  title: string;
  length: number;
  boolHook: UseBooleanHook;
  fallback?: {
    shouldDisplayPlaceholder: () => boolean;
    emptyPlaceholder: ReactNode;
  };
  completed?: boolean;
}

const AccordionSectionHeader: React.FC<Props> = ({
  title,
  length,
  boolHook,
  fallback,
  completed = false
}) => {
  const { t } = useTranslation();

  if (!length && !fallback?.shouldDisplayPlaceholder()) return null;

  return (
    <StyledAccordion
      title={
        <StyledAccordionTitle justify='flex-start'>
          <Subheading numberOfLines={1}>{t(title)}</Subheading>
          {!completed && (
            <Badge>
              <Body>{length}</Body>
            </Badge>
          )}
        </StyledAccordionTitle>
      }
      onPress={() => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        boolHook.toggle();
      }}
      expanded={boolHook.value}
    >
      {(!length && fallback?.emptyPlaceholder) ?? <></>}
    </StyledAccordion>
  );
};

export default AccordionSectionHeader;

const StyledAccordion = styled(List.Accordion)`
  margin-left: 0;
  padding-left: 0;
`;

const StyledAccordionTitle = styled(Row)`
  margin-bottom: ${IS_WEB ? 0 : -Size.X3_S}px;
`;

const Badge = styled.View`
  margin-left: ${Margin.Medium}px;
  background-color: ${({ theme }) =>
    theme.dark ? palette.WHITE_OPACITY_10 : palette.BLACK_OPACITY_10};
  border-radius: ${Size.S}px;
  padding-horizontal: ${Margin.Large}px;
  padding-vertical: ${Margin.Small}px;
`;
