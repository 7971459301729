import { PropsWithChildren } from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { Body } from 'src/components/Text';
import styled from 'styled-components/native';

const BULLET = '\u2022';
const TAB = '\t';

interface Props {
  style?: StyleProp<TextStyle>;
  bulletStyle?: StyleProp<TextStyle>;
}
const BulletText: React.FC<PropsWithChildren<Props>> = ({ children, style, bulletStyle }) => {
  return (
    <Container>
      <BulletContainer>
        <Body style={bulletStyle}>{BULLET}</Body>
        <BulletSpacer lineHeight={0} height={0}>
          {TAB}
        </BulletSpacer>
      </BulletContainer>
      <TextContainer>
        <Body style={style}>{children}</Body>
      </TextContainer>
    </Container>
  );
};

const BulletContainer = styled.View`
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
`;

const BulletSpacer = styled(Body)`
  line-height: 0;
  height: 0;
  user-select: none;
`;
const Container = styled.View`
  flex-direction: row;
`;

const TextContainer = styled.View`
  flex: 1;
`;
export default BulletText;
