import React, { useEffect } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { Size } from 'src/constants';

import styled from 'styled-components/native';
import { HoverButton } from './kit';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/providers/AuthProvider';

const SPINNER_MAX_TIME_ALLOWED = 10000;

const LoginLoader: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useAppTheme();
  const { logOut, token } = useAuth();
  const [showLoadingLogout, setShowLoadingLogout] = React.useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShowLoadingLogout(true), SPINNER_MAX_TIME_ALLOWED);
    return () => clearTimeout(timer);
  });
  return (
    <ActivityIndicatorView>
      <ActivityIndicator size={'large'} color={colors.onPrimary} />
      {showLoadingLogout && !!token && (
        <StyledHoverButton mode='outlined' onPress={async () => logOut()}>
          {t('login:error.tooLong')}
        </StyledHoverButton>
      )}
    </ActivityIndicatorView>
  );
};

export default LoginLoader;

const ActivityIndicatorView = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
`;

const StyledHoverButton = styled(HoverButton).attrs(({ theme }) => ({
  labelStyle: { color: theme.colors.onPrimary }
}))`
  margin-top: ${Size.L}px;
  border-color: ${({ theme }) => theme.colors.onPrimary};
`;
