import { Client } from 'src/interfaces';
import Practice from 'src/interfaces/api/Practice';
import { NotificationCategory } from 'src/providers/NotificationProvider/NotificationCategory';

export interface NotificationEntry {
  id: string;
  status: NotificationStatus;
  sourceId: Practice['sourceId'];
  practiceId: Client['practiceId'];
  deliveredAt?: string;
  openedAt?: string;
  dismissedAt?: string;
  createdAt?: string;
  deletedAt?: string;
  data: Record<string, string>;
}

export enum NotificationStatus {
  DELIVERED = 'DELIVERED',
  OPENED = 'OPENED',
  DISMISSED = 'DISMISSED',
  QUEUED = 'QUEUED',
  SENT = 'SENT',
  FAILED = 'FAILED',
  UNDELIVERABLE = 'UNDELIVERABLE'
}

export interface NotifeeOptions {
  image: string;
  ios: {
    attachments: Array<{ url: string; thumbnailHidden: boolean }>;
    foregroundPresentationOptions: {
      alert: boolean;
      badge: boolean;
      sound: boolean;
      list: boolean;
    };
  };
}

export interface Metadata {
  id: string;
  userId: number;
  practiceId: Practice['id'];
  sourceId: Practice['sourceId'];
  deviceToken: string;
}

export interface RemoteMessageData {
  $metadata: string;
  data: string;
  fcm_options: {
    image: string;
  };
  headers: { [key: string]: string };
  notifee_options: NotifeeOptions;
  notification: string;
  url?: string;
}

export interface NotificationData {
  type: NotificationCategory;
  [key: string]: string;
}
