import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, Switch } from 'react-native-paper';
import { AsyncStorageKeys } from 'src/api';
import { StyledResponsiveView, StyledSectionHeader, Subheading } from 'src/components';
import { useStorage } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useNavigation } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import { Navigators } from 'src/routes/stacks/navigators';
import { useBiometricsPrompt } from 'src/providers/BiometricsPromptProvider';

const BiometricAuth: React.FC = () => {
  const { navigate } = useNavigation();
  const { t } = useTranslation('settings');
  const { colors } = useAppTheme();
  const [biometricsEnabled, setBiometricEnabled] = useStorage<boolean>(
    AsyncStorageKeys.BIOMETRIC_AUTH_APP_SETTING
  );
  const { hasCredentials } = useBiometricsPrompt();
  const enableBiometrics = async (enable: boolean) => {
    if (enable) {
      navigate(Navigators.MAIN_STACK, { screen: Screens.BIOMETRIC_LOGIN_OPT_IN });
    } else {
      setBiometricEnabled(false);
    }
  };

  return (
    <>
      <StyledSectionHeader>
        <Subheading>{t('biometricSectionHeader')}</Subheading>
      </StyledSectionHeader>
      <StyledResponsiveView>
        <List.Item
          title={t('biometricSettingTitle')}
          description={!hasCredentials && t('logoutRequired', { ns: 'biometricLogin' })}
          titleNumberOfLines={2}
          right={() => (
            <Switch
              color={colors.primary}
              value={biometricsEnabled}
              onValueChange={enableBiometrics}
            />
          )}
        />
      </StyledResponsiveView>
    </>
  );
};

export default BiometricAuth;
