import React from 'react';
import { Caption, Row } from 'src/components';
import { Size } from 'src/constants';
import { InfoUpdateItem } from 'src/interfaces/api/InfoUpdateRequest';
import { Weight } from 'src/theme';
import { layout } from 'src/theme/globalStyles';
import styled from 'styled-components/native';

interface Props {
  infoUpdateItems: InfoUpdateItem[];
}

const InfoUpdateItems: React.FC<Props> = ({ infoUpdateItems }) => {
  return (
    <Container>
      {infoUpdateItems.map((infoUpdateItem, i) => (
        <Row key={i} justify='space-between'>
          <Caption style={layout.flex2}>{infoUpdateItem.dataType}</Caption>
          <Caption style={layout.flex3} fontWeight={Weight.MEDIUM} textAlign='right'>
            {infoUpdateItem.newValue}
          </Caption>
        </Row>
      ))}
    </Container>
  );
};

export default InfoUpdateItems;

const Container = styled.View`
  gap: ${Size.X3_S}px;
  padding-horizontal: ${Size.S}px;
`;
